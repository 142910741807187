import moment from 'moment';

import { AdminUserHistoryOption, ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking, useGlobalAdmin } from '../../../../../..';
import { Row, SpinachAutocomplete } from '../../../../common';

export function AdminUserHistorySelection(): JSX.Element {
    const { state, setState } = useGlobalAdmin();
    const { searchedUser } = state;
    const track = useExperienceTracking();

    if (!searchedUser) {
        return <></>;
    }

    return (
        <Row style={{ marginLeft: '20px', width: 'calc(100% - 20px)' }}>
            <SpinachAutocomplete
                isLoading={state.isLoadingHistoryOptions}
                style={{ width: '400px', transform: 'scale(0.8)' }}
                options={state.userHistoryOptions}
                value={state.searchedHistory}
                inputPlaceholder="Find a meeting"
                getOptionLabel={(historyOption: AdminUserHistoryOption) => {
                    return `${historyOption.meetingTitle} (${moment(historyOption.meetingDate).format(
                        'MMM Do, YYYY'
                    )})`;
                }}
                onChange={async (event, historyOption) => {
                    if (!historyOption) {
                        setState((updatedState) => ({
                            ...updatedState,
                            searchedHistory: null,
                        }));
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Cleared User History Selection in Admin Search`,
                        });
                        return;
                    }
                    setState((updatedState) => ({
                        ...updatedState,
                        searchedHistory: historyOption,
                    }));

                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: `Selected User History in Admin Search`,
                        BotId: historyOption.id,
                        MeetingTitle: historyOption.meetingTitle,
                    });
                }}
            />
        </Row>
    );
}
