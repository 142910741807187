import { atom } from 'recoil';

export const atomBrandedImageUri = atom<string | null>({
    key: 'brandedImageUri',
    default: null,
});

export const atomSpinachVideoBackgroundImageUri = atom<string | null>({
    key: 'spinachVideoBackgroundImageUri',
    default: null,
});

export const atomSpinachCombinedVideoBackgroundImageUri = atom<string | null>({
    key: 'spinachCombinedVideoBackgroundImageUri',
    default: null,
});
