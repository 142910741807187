import * as FullStory from '@fullstory/browser';

import { ClientExperienceEvent, SpinachAPIPath } from '@spinach-shared/types';
import { getStage } from '@spinach-shared/utils';

import { getClientPlatform } from '../utils';
import { postSpinachAPI } from './postSpinachAPI';

export async function postExperienceEvent(request: Omit<ClientExperienceEvent, 'Platform'>): Promise<void> {
    try {
        await postSpinachAPI(SpinachAPIPath.ExperienceEvent, {
            ...request,
            payload: { ...request.payload, Platform: getClientPlatform() },
        });
        try {
            FullStory.event(request.eventType, {
                ...request.payload,
                Platform: getClientPlatform(),
                Stage: getStage(),
            });
        } catch (error) {}
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
}
