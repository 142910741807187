import { useRecoilState } from 'recoil';

import { getUniques } from '@spinach-shared/utils';

import { atomAiMeetingDetailsSection } from '../atoms';
import { useGlobalStoredSeriesList } from './useGlobalStoredSeriesList';

export const useGlobalAiMeetingSelection = () => {
    const [openedMeetingsState, setMeetingState] = useRecoilState(atomAiMeetingDetailsSection);
    const {
        storedSeriesListState: { storedSeriesList },
    } = useGlobalStoredSeriesList();

    function openMeetingByCalEventId(eventId: string) {
        setMeetingState({
            ...openedMeetingsState,
            openMeetingDetails: [...getUniques([...openedMeetingsState.openMeetingDetails, eventId])],
        });
    }

    function closeMeetingByCalEventId(eventId: string) {
        setMeetingState({
            ...openedMeetingsState,
            openMeetingDetails: [
                ...getUniques([...openedMeetingsState.openMeetingDetails.filter((d) => d !== eventId)]),
            ],
        });
    }

    function highlightMeetingByICalUid(iCalUid: string) {
        const storedSeries = storedSeriesList.find((s) => s.scribeMetadata?.iCalUid === iCalUid);

        setMeetingState({
            ...openedMeetingsState,
            highlightedMeetingDetails: { seriesId: storedSeries?.id, iCalUid },
        });
    }

    function clearHighlightedMeeting() {
        setMeetingState({
            ...openedMeetingsState,
            highlightedMeetingDetails: null,
        });
    }

    function openMeetingBySeriesId(seriesId: string) {
        const storedSeries = storedSeriesList.find((s) => s.id === seriesId);
        if (storedSeries) {
            setMeetingState({
                ...openedMeetingsState,
                openMeetingDetails: [...new Set([...openedMeetingsState.openMeetingDetails, storedSeries.id])],
            });
        }
    }

    function closeMeetingBySeriesId(seriesId: string) {
        const storedSeries = storedSeriesList.find((s) => s.id === seriesId);
        if (storedSeries) {
            setMeetingState({
                ...openedMeetingsState,
                openMeetingDetails: [
                    ...getUniques(openedMeetingsState.openMeetingDetails.filter((id) => id !== seriesId)),
                ],
            });
        }
    }

    function setShowInviteErrorToast(showInviteError: boolean) {
        setMeetingState({
            ...openedMeetingsState,
            showInviteErrorToast: showInviteError,
        });
    }

    function setIsAddingAdditionalMeetings(isAddingAdditionalMeetings: boolean) {
        setMeetingState({
            ...openedMeetingsState,
            isAddingAdditionalMeetings,
        });
    }

    return {
        openedMeetingsState,
        clearHighlightedMeeting,
        setShowInviteErrorToast,
        setMeetingState,
        setIsAddingAdditionalMeetings,
        highlightMeetingByICalUid,
        openMeetingByCalEventId,
        closeMeetingByCalEventId,
        openMeetingBySeriesId,
        closeMeetingBySeriesId,
    };
};
