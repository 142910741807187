import { atom } from 'recoil';

import { ElementId } from '../constants';

export enum DemoModal {
    PrepareCheckIn,
    JoinStandup,
    TransitionToOnboarding,
    TimeForStandup,
    JoiningStandup,
    EnteringPersonaDemoFromOnboarding,
}

export enum DemoPrefaceView {
    PersonaIntro = 'persona-intro',
    SlackAlert = 'slack-alert',
}

export enum SpinachWindowOverlay {
    LoadingSpinachBrowser = 'loading-spinach-browser',
    GeneratingCheckIn = 'generating-check-in',
}

export type GlobalDemoState = {
    focusedFeature: ElementId | null;
    hasClickedPreviousCheckIn: boolean;
    hasClickedImReady: boolean;
    hasOpenedSummariesTabInDemo: boolean;
    demoModal: DemoModal | null;
    isGuideOpen: boolean;

    /** @NOTE these state fields are new with demo persona experimenet */
    demoPrefaceView: DemoPrefaceView | null;
    spinachWindowOverlay: SpinachWindowOverlay | null;
    haveAutoTopicNotesFired: boolean;
};

export const atomDemoState = atom<GlobalDemoState>({
    key: 'demoState',
    default: {
        focusedFeature: null,
        hasClickedPreviousCheckIn: false,
        hasClickedImReady: false,
        hasOpenedSummariesTabInDemo: false,
        demoModal: DemoModal.PrepareCheckIn,
        isGuideOpen: true,
        demoPrefaceView: DemoPrefaceView.PersonaIntro,
        spinachWindowOverlay: SpinachWindowOverlay.LoadingSpinachBrowser,
        haveAutoTopicNotesFired: false,
    },
});
