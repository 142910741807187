export enum UserTypeAffix {
    Personal = 'pers',
    Business = 'biz',
}

export const CUSTOMER_SUCCESS_DEPARTMENT_CODE = 'customer-success';

export enum CompanySize {
    OneToTen = '1-10',
    ElevenToFifty = '11-50',
    FiftyOneToTwoHundred = '51-200',
    TwoHundredOneToFiveHundred = '201-500',
    FiveHundredOneToThousand = '501-1000',
    ThousandToTenThousand = '1000-10000',
    TenThousandPlus = '10000+',
}

// Common large company sizes that may need special handling
export const LARGE_COMPANY_SIZES = [CompanySize.ThousandToTenThousand, CompanySize.TenThousandPlus];
