import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { fetchTicketProjects } from '../apis';
import { GlobalTicketProjectState, globalTicketProjects } from '../atoms/atomTicketProject';
import { SetValue } from '../types';
import { useGlobalAuthedUser } from './useGlobalUser';

export const useGlobalTicketProjectsState = (): [
    globalTicketProjects: GlobalTicketProjectState | null,
    setGlobalTicketProjectsState: SetValue<GlobalTicketProjectState | null>
] => {
    return useRecoilState(globalTicketProjects);
};

export const useTicketProjects = () => {
    const [globalTicketProjects, setGlobalTicketProjects] = useGlobalTicketProjectsState();
    const [hasFetched, setHasFetched] = useState(false);
    const [user] = useGlobalAuthedUser();

    useEffect(() => {
        const getTicketProjects = async () => {
            const ticketSourcesToFetchFor = user.authedTicketSources.filter((ticketSource) =>
                user.actionItemCreationEnabledTicketSources.includes(ticketSource)
            );
            const ticketProjects = await Promise.all(
                ticketSourcesToFetchFor.map(async (ticketSource) => {
                    const ticketProjects = await fetchTicketProjects(ticketSource);
                    return {
                        ticketSource,
                        ticketProjects,
                    };
                })
            );

            const ticketProjectMap = ticketProjects.reduce((acc, { ticketSource, ticketProjects }) => {
                acc[ticketSource] = ticketProjects;
                return acc;
            }, {} as GlobalTicketProjectState);

            setHasFetched(true);
            setGlobalTicketProjects(ticketProjectMap);
        };

        if (
            user.authedTicketSources.length &&
            user.authedTicketSources.some((ticketSource) =>
                user.actionItemCreationEnabledTicketSources.includes(ticketSource)
            ) &&
            !hasFetched
        ) {
            getTicketProjects();
        }
    }, [
        user.isAuthedForAsana,
        user.isAuthedForJira,
        user.isAuthedForLinear,
        user.isAuthedForClickUp,
        user.isAuthedForTrello,
        user.authedTicketSources,
        user.actionItemCreationEnabledTicketSources,
        setGlobalTicketProjects,
        hasFetched,
    ]);

    return [globalTicketProjects];
};
