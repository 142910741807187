/**
 * This class is meant to be kept as minimal as possible.
 *
 * @clientServerTimeOffset seems justified as a global variable because it's get/set
 * across the app yet isn't valid state, nor should it be passed around as such.
 * It's updated upon series update, and the latest value is always acceptable; therefore,
 * there's no risk of race condition or being out of sync.
 *
 * @zoomInAppInstallOAuthVerifier is leveraged during the in-app-oauth zoom install flow.
 * It's shared between multiple files and doesn't need to be stateful.
 */

export class Globals {
    public static clientServerTimeOffset = 0;
    public static zoomInAppInstallOAuthVerifier = '';
}
