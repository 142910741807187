import { InfoOutlined } from '@material-ui/icons';
import { Lock } from '@material-ui/icons';
import { useState } from 'react';

import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import {
    Anchor,
    useExperienceTracking,
    useGlobalAuthedUser,
    useIntegrationDetection,
    useLandingAnalytic,
    useWindowSize,
} from '../../../../..';
import CalendarWhySrc from '../../../../assets/calendar-why.png';
import { ReactComponent as SpinachLogo } from '../../../../assets/logo-spinachio.svg';
import SecurityBadges from '../../../../assets/security-badges.png';
import { BodyBigOnboard, BodyRegularOnboard, HeaderThreeOnboard, lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Column, Row, Spacing } from '../../../common';
import { GoogleLoginComponent } from '../../../common/GoogleLoginComponent';
import { MicrosoftLoginComponent } from '../../../common/MicrosoftLoginComponent';
import { ScrollView } from '../../facilitation/common';
import { StepProps } from '../common';
import { StepTracker } from './StepTracker';
import { onboardingSteps, templateVariable } from './constants';
import { Container, ContentContainer, CustomTooltip, Divider, OutlineButton, TooltipContainer } from './shared';

export type GoogleCalendarPermissionStepTrackerStepProps = StepProps & {
    onBack: () => void;
    onScheduleOnboardingSession: () => void;
    onScheduleDemoSessionMidTrial: () => void;
    onProgressToAddEventToMeeting: (user: ClientUser) => void;
    onInviteSpinachManuallyClick: () => void;
    isAgentOnboarding: boolean;
};

export function SecurityCalendarPermissionWithStepTrackerStep({
    onScheduleOnboardingSession,
    onProgressToAddEventToMeeting,
    isAgentOnboarding,
    onInviteSpinachManuallyClick,
}: GoogleCalendarPermissionStepTrackerStepProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedCalendarPermissionsStep);

    const track = useExperienceTracking();
    const { width, isDesktopView } = useWindowSize();
    const [user] = useGlobalAuthedUser();
    const startDetection = useIntegrationDetection(onProgressToAddEventToMeeting);
    const [securityTooltipOpen, setSecurityTooltipOpen] = useState(false);
    const titleTemplate = `Hey${templateVariable}! What meetings can I help you on?`;
    const title = titleTemplate.replace(templateVariable, user.firstName ? `, ${user.firstName}` : '');

    return (
        <Container>
            <Row>
                <SpinachLogo
                    style={{
                        height: isDesktopView ? '55px' : '25px',
                        marginBottom: isDesktopView ? 'initial' : '20px',
                        marginLeft: isDesktopView ? 'initial' : '-20px',
                    }}
                />
            </Row>
            <ContentContainer>
                <Row centered>
                    <StepTracker steps={onboardingSteps} currentStep={1} />
                </Row>
                <Spacing factor={isDesktopView ? 2.5 : 1} />
                <HeaderThreeOnboard>{title}</HeaderThreeOnboard>
                <Spacing factor={1} />
                <Row style={{ justifyContent: 'center', alignItems: 'flex-start', margin: '0 auto' }}>
                    <Column
                        style={{
                            flex: '0',
                            marginRight: isDesktopView ? '40px' : '15px',
                            marginLeft: isDesktopView ? '-40px' : '0px',
                        }}
                    >
                        <BodyBigOnboard style={{ fontSize: isDesktopView ? '32px' : '22px' }}>
                            <b>1.</b>
                        </BodyBigOnboard>
                    </Column>
                    <Column style={{ maxWidth: '400px', minWidth: '240px' }}>
                        <Row vCenter>
                            <BodyRegularOnboard style={{ fontSize: isDesktopView ? '18px' : '16px' }}>
                                Connect Calendar <b>(required)</b>
                            </BodyRegularOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row>
                            <GoogleLoginComponent
                                text="Connect Google"
                                onClick={() => {
                                    track(ClientEventType.UserClickedIntegrateWithGoogleCalendar);
                                    startDetection();
                                    URLUtil.openURL(
                                        `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleCalendarAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                                    );
                                }}
                                style={{
                                    width: isDesktopView ? '400px' : '240px',
                                    justifyContent: 'center',
                                    fontSize: width < 470 ? '16px' : '21px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    height: '30px',
                                    padding: '16px 29px',
                                }}
                            />
                        </Row>
                        {/* we only show the microsoft connect if it's not a google user */}
                        {!user.isAuthedForGoogle ? (
                            <>
                                <Spacing factor={1 / 2} />
                                <Row>
                                    <MicrosoftLoginComponent
                                        text="Connect Microsoft"
                                        style={{
                                            width: isDesktopView ? '400px' : '240px',
                                            padding: '16px 29px',
                                        }}
                                        textStyle={{
                                            fontSize: width < 470 ? '16px' : '21px',
                                        }}
                                        onClick={() => {
                                            track(ClientEventType.UserClickedIntegrateWithMicrosoftCalendar);
                                            startDetection();
                                            URLUtil.openURL(
                                                `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.MicrosoftAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                                            );
                                        }}
                                    />
                                </Row>
                            </>
                        ) : null}
                        <Spacing factor={1 / 2} />
                        <Row vCenter>
                            <Column style={{ flex: 0, marginRight: '12px' }}>
                                <Lock style={{ color: 'black', fontSize: '30px' }} htmlColor={'black'} />
                            </Column>
                            <Column>
                                <Row>
                                    <BodyRegularOnboard style={{ fontSize: '16px' }}>
                                        All your data is encrypted and <b>never</b> used
                                        <br />
                                        <span style={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}>
                                            for AI model training
                                            <CustomTooltip
                                                enterTouchDelay={0}
                                                leaveTouchDelay={15000}
                                                onOpen={() => {
                                                    if (!securityTooltipOpen) {
                                                        setSecurityTooltipOpen(true);
                                                        track(
                                                            ClientEventType.UserOpenedCalendarConnectSecurityTooltip,
                                                            {
                                                                IsAgentOnboarding: isAgentOnboarding,
                                                            }
                                                        );
                                                    }
                                                }}
                                                onClose={() => {
                                                    setSecurityTooltipOpen(false);
                                                }}
                                                style={{ marginLeft: '5px' }}
                                                interactive={true}
                                                title={
                                                    <TooltipContainer
                                                        style={{
                                                            width: isDesktopView ? '350px' : '300px',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        <Row>
                                                            <Column style={{ flex: 0, marginRight: '17px' }}>
                                                                <Lock
                                                                    style={{ color: 'black', fontSize: '30px' }}
                                                                    htmlColor={'black'}
                                                                />
                                                            </Column>
                                                            <Column>
                                                                <BodyRegularOnboard
                                                                    style={{
                                                                        fontSize: '16px',
                                                                        textAlign: 'left',
                                                                    }}
                                                                >
                                                                    All systems are audited by EY on annual basis to
                                                                    confirm compliance and security practices with SOC 2
                                                                    standards.
                                                                </BodyRegularOnboard>
                                                                <Spacing factor={1 / 2} />
                                                            </Column>
                                                        </Row>
                                                        <Row centered>
                                                            <img src={SecurityBadges} style={{ width: '250px' }} />
                                                        </Row>
                                                    </TooltipContainer>
                                                }
                                                placement="top"
                                            >
                                                <InfoOutlined
                                                    htmlColor={lightTheme.primary.greenLight}
                                                    fontSize="small"
                                                    style={{ fontSize: '24px' }}
                                                />
                                            </CustomTooltip>
                                        </span>
                                    </BodyRegularOnboard>
                                </Row>
                            </Column>
                        </Row>
                    </Column>
                </Row>
                {user.shouldShowManualInviteInAgentOnboarding ? (
                    <>
                        <Spacing factor={1} />
                        <Row vCenter>
                            <Divider style={{ flex: '1' }} />
                            <span style={{ margin: '0 15px' }}>Or</span>
                            <Divider style={{ flex: '1' }} />
                        </Row>
                        <Spacing factor={1 / 2} />
                        <OutlineButton
                            style={{ width: '250px', fontSize: '18px', padding: '14px 92px', margin: '0 auto' }}
                            isSelected={false}
                            onClick={onInviteSpinachManuallyClick}
                        >
                            Invite Spinach Manually
                        </OutlineButton>
                    </>
                ) : null}
                <Spacing factor={1} />
                <Divider />
                <Spacing factor={1} />
                <ScrollView sidePadding={0} stretch={true} style={{ height: 'calc(100vh - 705px)' }}>
                    <Row centered={true}>
                        <img
                            src={CalendarWhySrc}
                            style={{ maxWidth: '500px', minWidth: '100px' }}
                            alt="Following steps give you control of meetings that spinach can join"
                        />
                    </Row>
                    <Spacing factor={1 / 2} />
                    <Row centered>
                        <BodyRegularOnboard>
                            <b>Can&apos;t connect?</b>
                        </BodyRegularOnboard>
                    </Row>
                    <Row style={{ display: 'inline', textAlign: 'center' }} centered={true}>
                        <BodyRegularOnboard>
                            <Anchor
                                style={{
                                    textDecoration: 'none',
                                }}
                                onClick={() => {
                                    onScheduleOnboardingSession();
                                }}
                            >
                                Schedule 15 minutes with us
                            </Anchor>{' '}
                            and we&apos;ll help you get set up!
                        </BodyRegularOnboard>
                    </Row>
                </ScrollView>
            </ContentContainer>
        </Container>
    );
}
