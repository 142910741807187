import { Box, Button, Typography } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import moment from 'moment';
import React, { useState } from 'react';
import { useSessionStorage } from 'react-use';

import { CalendarEvent } from '@spinach-shared/types';

import { patchUser } from '../../../../..';
import confettiGif from '../../../../assets/confetti-meme 1.gif';
import { ReactComponent as GreenCheckmark } from '../../../../assets/round-checkmark.svg';
import waitingRoom from '../../../../assets/waiting-room.png';
import { useGlobalAuthedUser } from '../../../../hooks';
import { lightTheme } from '../../../../styles';
import { Row } from '../../../common';
import { PrimaryButton } from '../../../stand-up';

export const PrepHostForFirstMeeting = ({ onClose, event }: { onClose: () => void; event: CalendarEvent }) => {
    const [step, setStep] = useState<number>(0);
    const [isClosed, setIsClosed] = useSessionStorage<boolean>('PrepHostForFirstMeeting', false);
    const [user, setUser] = useGlobalAuthedUser();

    React.useEffect(() => {
        if (!user.metadata?.hasBeenPreppedForSpinachToJoin) {
            (async () => {
                const response = await patchUser({ metadata: { hasBeenPreppedForSpinachToJoin: true } });
                if (response.user) {
                    setUser(response.user);
                }
            })();
        }
        // only need to run once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContinue = () => {
        setStep(1);
    };

    const handleClose = () => {
        onClose();
        setIsClosed(true);
    };

    if (isClosed) {
        return <></>;
    }

    return (
        <Box
            position="fixed"
            display="flex"
            flexDirection="column"
            alignItems="center"
            top="50%"
            left="50%"
            style={{
                transform: 'translate(-50%, -50%)',
                background: lightTheme.neutrals.grayLight,
                border: `1px solid ${lightTheme.primary.orangeLight}`,
                boxShadow: '0px 0px 300px rgba(0, 0, 0, 0.8)',
                borderRadius: '30px',
            }}
            justifyContent="space-between"
            p={3}
            maxWidth={400}
            minHeight={480}
            zIndex={1000}
        >
            {step === 0 ? (
                <>
                    <img
                        src={confettiGif}
                        alt="Meeting setup"
                        style={{ width: '100%', marginBottom: 16, borderRadius: '3px' }}
                    />
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mb={'20px'}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
                            You're all set!
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We'll automatically join:
                        </Typography>
                        <Box
                            mt={'10px'}
                            minWidth="300px"
                            maxWidth="350px"
                            display="flex"
                            flexDirection="row"
                            style={{
                                background: lightTheme.neutrals.white,
                                borderRadius: '8px',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                            }}
                            p={2}
                            px={'20px'}
                            py={'5px'}
                            borderRadius={2}
                            mb={2}
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                flex={1}
                                alignItems="flex-start"
                                flexDirection="column"
                                minWidth={0} // Add this to allow child content to shrink
                            >
                                <Box display="flex" position="relative" width="100%" alignItems="end">
                                    <Box
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                            marginRight: '15px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            flexGrow: 1,
                                            flexShrink: 1,
                                            minWidth: 0, // Allow text to shrink below its content size
                                        }}
                                    >
                                        {event.summary}
                                    </Box>
                                    {event.start && event.end ? (
                                        <Box
                                            style={{
                                                fontSize: '14px',
                                                flexShrink: 0,
                                                whiteSpace: 'nowrap',
                                                marginBottom: '3px',
                                            }}
                                        >
                                            {moment(event.start.dateTime).format('h:mmA')}
                                            {' - '}
                                            {moment(event.end.dateTime).format('h:mmA')}
                                        </Box>
                                    ) : null}
                                </Box>
                                {event.attendees ? (
                                    <Box style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                        {event.attendees.length} Participants
                                    </Box>
                                ) : null}
                            </Box>

                            <Box flexShrink={0} display="flex" alignItems="center" ml="20px">
                                <GreenCheckmark style={{ height: '48px' }} />
                            </Box>
                        </Box>
                    </Box>
                    <PrimaryButton onClick={handleContinue} title="Continue" />
                </>
            ) : (
                <>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
                        Before your first meeting:
                    </Typography>
                    <img src={waitingRoom} alt="Waiting room" style={{ width: '300px', marginBottom: 16 }} />
                    <Row>
                        <WarningIcon style={{ color: lightTheme.primary.orangeLight, marginRight: 8 }} />
                        <Typography variant="body1" gutterBottom>
                            <b>Important:</b> Check that Spinach isn't in the waiting room.
                        </Typography>
                    </Row>

                    <PrimaryButton onClick={handleClose} title="Got it" />
                </>
            )}
        </Box>
    );
};
