import { Warning } from '@material-ui/icons';

import { ConfluenceSpaceType, SpinachIntegration } from '@spinach-shared/types';

import { BodyRegularOnboard, Spacing, lightTheme } from '../../../..';
import { useAtlassianSites } from '../../../hooks/useAtlassianSites';
import { useConfluenceSpaces } from '../../../hooks/useConfluenceSpaces';
import { Row } from '../framing';

export function AtlassianTopLevelWarning({
    atlassianSource,
    isFirstTimeAuth,
}: {
    atlassianSource: SpinachIntegration.Jira | SpinachIntegration.Confluence;
    isFirstTimeAuth: boolean;
}) {
    const { atlassianSites } = useAtlassianSites();
    const [confluenceSpaces] = useConfluenceSpaces();
    const confluenceSpacesToRender = confluenceSpaces?.filter(
        (space) => space.type === ConfluenceSpaceType.Global || space.isSelfSpace
    );
    if (!isFirstTimeAuth) {
        return <></>;
    }
    const specificAtlassianSites = atlassianSites?.[atlassianSource];
    if (!specificAtlassianSites?.length && !confluenceSpacesToRender?.length) {
        return <></>;
    }

    const doesHaveConfluenceSpaces = confluenceSpacesToRender?.length;
    const doesHaveAtlassianSites = specificAtlassianSites?.length;
    const doesHaveAtlassianSitesAndConfluenceSpaces = doesHaveAtlassianSites && doesHaveConfluenceSpaces;
    let instructionalSubCopy = '';
    if (doesHaveAtlassianSitesAndConfluenceSpaces) {
        instructionalSubCopy = 'site and space';
    } else if (doesHaveAtlassianSites) {
        instructionalSubCopy = 'site';
    } else if (doesHaveConfluenceSpaces) {
        instructionalSubCopy = 'space';
    }

    const instructionalCopy = `Please confirm your ${instructionalSubCopy} selection below`;

    return (
        <>
            <Row
                style={{
                    backgroundColor: lightTheme.secondary.orangeLight,
                    padding: '5px',
                }}
            >
                <Warning fontSize="small" style={{ marginRight: '5px' }} htmlColor={lightTheme.primary.orangeDark} />
                <BodyRegularOnboard>{instructionalCopy}</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 3} />
        </>
    );
}
