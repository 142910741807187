import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { VideoAgentSession } from '@spinach-shared/models';
import { ClientVideoAgentSessionJSON } from '@spinach-shared/types';

import { GlobalNullableVideoAgentState, GlobalVideoAgentState, atomVideoAgent } from '../../atoms';
import { SetValue } from '../../types';

export type UseGlobalNullableVideoAgentState = {
    state: GlobalNullableVideoAgentState;
    session: GlobalNullableVideoAgentState['session'];
    setState: SetValue<GlobalNullableVideoAgentState>;
    setSession: (updated: ClientVideoAgentSessionJSON | VideoAgentSession) => void;
};

export type UseGlobalVideoAgentState = {
    state: GlobalVideoAgentState;
    session: GlobalVideoAgentState['session'];
    setState: SetValue<GlobalVideoAgentState>;
    setSession: (updated: ClientVideoAgentSessionJSON | VideoAgentSession) => void;
};

export function useGlobalNullableVideoAgent(): UseGlobalNullableVideoAgentState {
    const [state, setState] = useRecoilState(atomVideoAgent);

    const setSession = useCallback(
        function setSession(updated: ClientVideoAgentSessionJSON | VideoAgentSession) {
            if (updated instanceof VideoAgentSession) {
                setState((prev) => ({
                    ...prev,
                    session: updated,
                }));
            } else {
                setState((prev) => ({
                    ...prev,
                    session: new VideoAgentSession(updated),
                }));
            }
        },
        [setState]
    );

    return {
        state,
        session: state.session,
        setState,
        setSession,
    };
}

/**
 *
 * @returns global agent state cast to not have nullables. Should only be used where its guaranteed to have these values
 */
export function useGlobalVideoAgent(): UseGlobalVideoAgentState {
    return useGlobalNullableVideoAgent() as UseGlobalVideoAgentState;
}
