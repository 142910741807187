import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { ClientUser } from '@spinach-shared/models';
import { IClientUser } from '@spinach-shared/types';

import { atomUser } from '../atoms/user';
import { SetValue } from '../types';

/**
 *
 * @returns a global user ref, which may or may not be fetched yet
 *
 * Should this simply be renamed to useGlobalUserFetching and support undefined as well?
 */
export function useGlobalUser(): [ClientUser | null | undefined, SetValue<IClientUser | null | undefined>] {
    const [user, setUser] = useRecoilState(atomUser);

    const setNewUser = (user: IClientUser | null) => {
        if (user) {
            setUser(new ClientUser(user));
        } else {
            setUser(null);
        }
    };

    return [user, setNewUser as SetValue<IClientUser | null | undefined>];
}

/**
 *
 * @returns a defined global user ref.
 * NOTE: only use this in parts of the app where the user would be guaranteed
 */
export function useGlobalAuthedUser(): [ClientUser, SetValue<IClientUser>] {
    const [user, setUser] = useRecoilState(atomUser);

    const setNewUser = useCallback(
        (user: IClientUser) => {
            if (user) {
                setUser(new ClientUser(user));
            } else {
                setUser(null);
            }
        },
        [setUser]
    );

    return [user as ClientUser, setNewUser as SetValue<IClientUser>];
}
