import { Tooltip, makeStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import styled from 'styled-components';

import { BaseIDRequest, ClientEventType, ClientSocketEvent } from '@spinach-shared/types';

import { useExperienceTracking, useGlobalLiveSeries, useMeditationEnablement, useWindowSize } from '../../hooks';
import { useGlobalMeetingSocket } from '../../hooks/useGlobalSocket';
import { BodyLarge, lightTheme } from '../../styles';
import { createWebsocketPayload } from '../../utils';
import { BorderedSpacing, Row } from '../common';
import { SpinachSwitch } from '../common/SpinachSwitch';

enum MeditationButton {
    Add = 'Meditation Toggled On',
    Remove = 'Meditation Toggled Off',
}

const MeditationContainer = styled(Row)`
    justify-content: space-between;
    align-items: center;
`;

const MeditationRevealContainer = styled.div`
    display: flex;
    align-items: center;
`;

const useTooltipStyles = makeStyles(() => ({
    tooltip: {
        width: '130px',
        fontSize: '12px',
    },
    tooltipPlacementTop: {
        marginBottom: 5,
    },
}));

export function MeditationSection(): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();
    const isDemo = liveSeries.isDemo;
    const { isMeditationActivated, isAsyncMeeting } = liveSeries.currentMeeting;
    const track = useExperienceTracking();
    const [socket] = useGlobalMeetingSocket();
    const { spinachUserId } = liveSeries.spinachParticipant;
    const isMeditationEnabled = useMeditationEnablement();
    const tooltipClasses = useTooltipStyles();
    const { width } = useWindowSize();
    const canShowMeditation = !isDemo && !isAsyncMeeting && isMeditationEnabled;

    const onMeditationToggle = (buttonClicked: MeditationButton) => {
        const eventTypes = {
            [MeditationButton.Add]: {
                telemetry: ClientEventType.MeditationAdded,
                socket: ClientSocketEvent.MeditationAdded,
            },
            [MeditationButton.Remove]: {
                telemetry: ClientEventType.MeditationRemoved,
                socket: ClientSocketEvent.MeditationRemoved,
            },
        };

        track(eventTypes[buttonClicked].telemetry, { ButtonClicked: buttonClicked });

        const request = createWebsocketPayload<BaseIDRequest>({
            spinachUserId: spinachUserId,
            seriesSlug: liveSeries.slug,
        });

        socket.emit(eventTypes[buttonClicked].socket, request);
    };

    if (!canShowMeditation) {
        return <></>;
    }

    return (
        <>
            <BorderedSpacing factor={0.5} />
            <MeditationContainer>
                <BodyLarge style={{ display: 'flex', justifyContent: 'left' }}>
                    Meditation
                    <Tooltip
                        classes={tooltipClasses}
                        title="Team meditation promotes a more peaceful, collaborative work environment. Try a 2-minute guided meditation before starting your Roundtable"
                        arrow={true}
                        placement="right"
                        onClose={() => {
                            track(ClientEventType.MeditationTooltipHovered, {
                                Location: 'Lobby',
                            });
                        }}
                    >
                        <InfoOutlined
                            style={{
                                marginLeft: '5px',
                                marginBottom: '2px',
                                fontSize: width > 400 ? '22px' : '16px',
                            }}
                            htmlColor={lightTheme.tertiary.midnight}
                        />
                    </Tooltip>
                </BodyLarge>

                <MeditationRevealContainer>
                    <SpinachSwitch
                        onClick={() =>
                            onMeditationToggle(isMeditationActivated ? MeditationButton.Remove : MeditationButton.Add)
                        }
                        checked={isMeditationActivated}
                    />
                </MeditationRevealContainer>
            </MeditationContainer>
        </>
    );
}
