import { Checkbox } from '@material-ui/core';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import {
    DateTimeMetadata,
    Day,
    HHMMTimeString,
    MeetingFormat,
    ORDERED_DAYS,
    ORDERED_DAYS_MAP,
    ReminderSettings,
} from '@spinach-shared/types';
import { ScheduleDay, SeriesSchedule, TimeUtils, getSelectedDateTimeInformation } from '@spinach-shared/utils';

import { DEFAULT_END_TIME, DEFAULT_START_TIME } from '../../constants';
import {
    useCustomSlackRemindersEnablement,
    useGlobalLiveSeries,
    useIsChangedForThisWeekOnly,
    useIsChangedForThisWeekOnlyValidation,
    useStartEndTimeValidation,
} from '../../hooks';
import { BodyRegular, BodySubtitle, lightTheme, responsiveness } from '../../styles';
import { ListItemValue } from '../../types';
import { getDefaultDateTime } from '../../utils';
import { PrimaryButton } from '../stand-up';
import { BootstrapTooltip } from './BootstrapTooltip';
import { DropDown } from './DropDown';
import { InMeetingStandupTimesSelectionHeader } from './InMeetingStandupTimeSelectionHeader';
import { Column, Row, Spacing } from './framing';

const timeValueHours = Array.from(Array(12).keys());
const timeValueMinutesFifteenMinuteStep = Array.from(Array(60).keys()).filter((number) => number % 15 === 0);

const timeValuesAm: ListItemValue<string>[] = timeValueHours
    .map((hour) => {
        return timeValueMinutesFifteenMinuteStep.map((minute) => {
            const label = `${hour === 0 ? 12 : hour}:${minute < 10 ? `0${minute}` : minute}am`;
            const code = `${hour}:${minute < 10 ? `0${minute}` : minute}`;
            return { code, label };
        });
    })
    .flat();

const timeValuesPm: ListItemValue<string>[] = timeValueHours
    .map((hour) => {
        return timeValueMinutesFifteenMinuteStep.map((minute) => {
            const label = `${hour === 0 ? 12 : hour}:${minute < 10 ? `0${minute}` : minute}pm`;
            const code = `${hour + 12}:${minute < 10 ? `0${minute}` : minute}`;
            return { code, label };
        });
    })
    .flat();

const CircleButton = styled.button<{ chosen: boolean; meetingFormat: MeetingFormat | null; selected: boolean }>`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin: 5px;
    background-color: ${(props) =>
        props.chosen
            ? props.meetingFormat === MeetingFormat.Live
                ? props.theme.secondary.green
                : props.theme.primary.orangeLight
            : props.theme.neutrals.white};
    font-weight: 600;
    color: ${(props) => (props.chosen ? 'white' : props.theme.primary.midnight)};
    border-color: ${(props) =>
        props.selected
            ? props.theme.primary.midnight
            : props.chosen
            ? props.theme.secondary.green
            : props.theme.neutrals.grayDark};
    border-style: solid;
    flex-shrink: 0;

    &:hover {
        cursor: pointer;
        border-style: solid;
        border-color: ${(props) => props.theme.primary.midnight};
    }

    @media ${responsiveness.thinnerThanXS} {
        transform: scale(0.8);
        margin: 0px;
    }
`;

const ResponsiveRow = styled(Row)`
    align-items: center;

    @media ${responsiveness.thinnerThanXS} {
        max-width: 90%;
    }
`;

const SelectedDateTimeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const emphasizeText = keyframes`
    0% {
        font-weight: 700;
    }
    100% {
        font-weight: 400;
    }
`;

const listItemValues: ListItemValue<MeetingFormat | null>[] = [
    {
        code: null,
        label: 'Off',
    },
    {
        code: MeetingFormat.Live,
        label: 'Live',
    },
    {
        code: MeetingFormat.Async,
        label: 'Async',
    },
];

const durationValues: ListItemValue<number>[] = [
    { code: 5 * 60 * 1000, label: '5 min' },
    { code: 15 * 60 * 1000, label: '15 min' },
    { code: 30 * 60 * 1000, label: '30 min' },
    { code: 45 * 60 * 1000, label: '45 min' },
    { code: 60 * 60 * 1000, label: '1 hour' },
    { code: 120 * 60 * 1000, label: '2 hour' },
    { code: 240 * 60 * 1000, label: '4 hours' },
];

const emphasizeTextAnimation = () => css`
    animation: ${emphasizeText};
`;

const getDateTimeStringWithTimezone = (dateTime: DateTimeMetadata | null) => {
    if (!dateTime) {
        return {};
    }
    const scheduleDay = new ScheduleDay(dateTime);
    const dateTimeDate = scheduleDay.getNextStartDate();
    const fullTimeText = dateTimeDate.toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ')[0];
    const amPmText = dateTimeDate.toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ')[1].toLowerCase();
    const timeZoneValueText = dateTimeDate?.toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ')[2];
    const timeValueText = fullTimeText?.split(':').slice(0, -1).join(':');
    const textTimeString = `${timeValueText} ${amPmText}`;
    return { fullTimeTextWithTimezone: `${timeValueText} ${amPmText} ${timeZoneValueText}`, textTimeString };
};

const ErrorMessage = styled(BodyRegular)`
    margin-top: 5px;
    height: 17.5px;
    color: ${(props) => props.theme.status.negative};

    ${emphasizeTextAnimation()};
    animation-duration: 1000ms;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
`;

type InMeetingStandupCustomSlackRemindersProps = {
    reminderSettings: ReminderSettings;
    setReminderSettings: (reminderSettings: ReminderSettings) => void;
};

function InMeetingStandupCustomSlackReminders({
    reminderSettings,
    setReminderSettings,
}: InMeetingStandupCustomSlackRemindersProps) {
    const [isReminderEnabledChecked, setIsReminderEnabledChecked] = useState(reminderSettings.isReminderEnabled);
    const [selectedDurationCode, setSelectedDurationCode] = useState(
        reminderSettings.durationBeforeMeetingInMilliseconds
    );
    return (
        <Column>
            <Row style={{ justifyContent: 'center' }}>
                <BodyRegular style={{ textAlign: 'center', alignSelf: 'center' }}>
                    <b>Slack Notifications</b>
                </BodyRegular>
            </Row>
            <Spacing factor={1 / 3} />
            <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Checkbox
                    checked={isReminderEnabledChecked}
                    onChange={() => {
                        setIsReminderEnabledChecked(!isReminderEnabledChecked);
                        setReminderSettings({ ...reminderSettings, isReminderEnabled: !isReminderEnabledChecked });
                    }}
                    inputProps={{
                        'aria-label': 'same time for all standup selection',
                    }}
                />
                <BodyRegular style={{ marginRight: '9px' }}>Send a reminder</BodyRegular>
                <DropDown
                    title={'Duration before standup'}
                    values={durationValues}
                    selected={durationValues.find((val) => val.code === selectedDurationCode)?.label}
                    defaultValue={durationValues[0].label}
                    handleSelection={(code) => {
                        setSelectedDurationCode(code as number);
                        setReminderSettings({
                            ...reminderSettings,
                            durationBeforeMeetingInMilliseconds: code as number,
                        });
                    }}
                    buttonProps={{
                        style: { padding: '4px 12px' },
                        labelStyles: { fontSize: '15px' },
                        disabled: !isReminderEnabledChecked,
                    }}
                />
                <BodyRegular style={{ marginLeft: '9px' }}>before standup</BodyRegular>
            </Row>
        </Column>
    );
}

const TimeSelection = ({
    onSubmit,
    isLoading,
    dateTimes,
    isCustomSlackRemindersEnabled,
    reminderSettings,
}: {
    onSubmit: (
        standupDateTimeSelected: SeriesSchedule,
        shouldSendSlackSummary: boolean,
        reminderSettings?: ReminderSettings
    ) => void;
    isLoading: boolean;
    dateTimes?: DateTimeMetadata[];
    isCustomSlackRemindersEnabled: boolean;
    reminderSettings: ReminderSettings;
}) => {
    const [liveSeries] = useGlobalLiveSeries();
    const [standupDayTimesSelected, setStandupDayTimesSelected] = useState<DateTimeMetadata[]>(dateTimes ?? []);
    const defaultSelectedDateTime: DateTimeMetadata = standupDayTimesSelected.find(
        (selectedDateTime) => selectedDateTime.day === ORDERED_DAYS[new Date().getDay()]
    ) ??
        standupDayTimesSelected[0] ?? {
            day: ORDERED_DAYS[new Date().getDay()],
            startTime: DEFAULT_START_TIME,
            endTime: DEFAULT_END_TIME,
            meetingFormat: null,
            isChangedForThisWeekOnly: false,
            timezoneRegion: TimeUtils.getTimezoneRegion(),
            timezoneOffset: new Date().getTimezoneOffset(),
        };

    const [lastTimestampSelected, setLastTimestampSelected] = useState<HHMMTimeString>(
        defaultSelectedDateTime
            ? getSelectedDateTimeInformation<HHMMTimeString>(defaultSelectedDateTime, 'startTime') || DEFAULT_START_TIME
            : DEFAULT_START_TIME
    );

    const [selectedDateTime, setSelectedDateTime] = useState<null | DateTimeMetadata>(defaultSelectedDateTime);

    const [dropdownMeetingFormat, setDropdownMeetingFormat] = useState(
        listItemValues.find((val) => val.code === getSelectedDateTimeInformation(selectedDateTime, 'meetingFormat'))
            ?.label ?? undefined
    );

    const [updatedErrorNotice, setUpdatedErrorNotice] = useState(new Date().toISOString());
    const [hasBeenCreated, setHasBeenCreated] = useState(false);

    const startTimeValue = selectedDateTime
        ? getSelectedDateTimeInformation<HHMMTimeString>(selectedDateTime, 'startTime')
        : DEFAULT_START_TIME;
    const endTimeValue = selectedDateTime
        ? getSelectedDateTimeInformation<HHMMTimeString>(selectedDateTime, 'endTime')
        : DEFAULT_END_TIME;

    const isDayChosen = (day: Day) => {
        return standupDayTimesSelected.some(
            (dayTime) => dayTime.day === day && (dayTime.meetingFormat !== null || !!dayTime.isChangedForThisWeekOnly)
        );
    };

    const setStandupDayTimes = (standupDayTimes: DateTimeMetadata[]) => {
        setStandupDayTimesSelected(
            uniqBy(standupDayTimes.reverse(), 'day').sort((a, b) => ORDERED_DAYS_MAP[a.day] - ORDERED_DAYS_MAP[b.day])
        );
    };

    useStartEndTimeValidation(selectedDateTime, setSelectedDateTime, standupDayTimesSelected, setStandupDayTimes);

    // Should never get displayed
    const invalidStartTimeMessage =
        selectedDateTime && TimeUtils.isStartTimeInvalid(startTimeValue, endTimeValue)
            ? 'Your start time must be before your end time.'
            : '';

    useIsChangedForThisWeekOnly(
        selectedDateTime,
        setSelectedDateTime,
        standupDayTimesSelected,
        setStandupDayTimes,
        liveSeries.schedule?.toJSON(),
        lastTimestampSelected
    );

    const scheduleHasBeenEdited =
        JSON.stringify(
            [...standupDayTimesSelected]
                .filter((dayTime) => !!dayTime.meetingFormat || dayTime.isChangedForThisWeekOnly)
                .sort((a, b) => (a.day < b.day ? 1 : -1))
        ) !== JSON.stringify([...(liveSeries.schedule?.toJSON() ?? [])].sort((a, b) => (a.day < b.day ? 1 : -1)));

    const [editableReminderSettings, setEditableReminderSettings] = useState<ReminderSettings>({ ...reminderSettings });

    const reminderSettingsHaveBeenEdited =
        JSON.stringify(editableReminderSettings) !== JSON.stringify(reminderSettings);

    const hasBeenEdited = scheduleHasBeenEdited || reminderSettingsHaveBeenEdited;

    useEffect(() => {
        setHasBeenCreated(!!liveSeries.schedule?.days?.length);
    }, [liveSeries.schedule?.days]);

    const setMeetingFormat = (day: Day, meetingFormat: MeetingFormat | null) => {
        setUpdatedErrorNotice(new Date().toISOString());
        if (selectedDateTime?.day === day) {
            if (!standupDayTimesSelected.find((dayTime) => dayTime.day === day) && meetingFormat) {
                setSelectedDateTime({ ...selectedDateTime, meetingFormat });
                setStandupDayTimes([...standupDayTimesSelected, { ...selectedDateTime, meetingFormat }]);
            } else {
                const dateTime = standupDayTimesSelected.find((dateTime) => dateTime.day === day)!;
                const updatedDateTime = {
                    ...dateTime,
                    ...(dateTime.isChangedForThisWeekOnly
                        ? {
                              oneOffDateTimeMetadata: {
                                  startTime: selectedDateTime.startTime,
                                  endTime: selectedDateTime.endTime,
                                  day: selectedDateTime.day,
                                  timezoneRegion: TimeUtils.getTimezoneRegion(),
                                  timezoneOffset: new Date().getTimezoneOffset(),
                                  ...dateTime.oneOffDateTimeMetadata,
                                  meetingFormat,
                              },
                          }
                        : { meetingFormat }),
                    dayOfYearToSkipAfterSameDayAdHoc: undefined,
                };

                setSelectedDateTime(updatedDateTime);
                setStandupDayTimes([...standupDayTimesSelected, updatedDateTime]);
            }
        } else {
            if (isDayChosen(day)) {
                setSelectedDateTime(standupDayTimesSelected.find((dateTime) => dateTime.day === day)!);
            } else {
                const defaultDateTime = getDefaultDateTime(standupDayTimesSelected, lastTimestampSelected, day);

                setSelectedDateTime(defaultDateTime);
                setStandupDayTimes([...standupDayTimesSelected, defaultDateTime]);
            }
        }
    };

    const onDateSelection = (day: Day) => {
        setUpdatedErrorNotice(new Date().toISOString());
        if (selectedDateTime?.day === day) {
            if (!standupDayTimesSelected.find((dayTime) => dayTime.day === day)) {
                setStandupDayTimes([...standupDayTimesSelected, selectedDateTime]);
            } else if (
                getSelectedDateTimeInformation<MeetingFormat | null>(selectedDateTime, 'meetingFormat') === null
            ) {
                const dateTime = standupDayTimesSelected.find((dateTime) => dateTime.day === day)!;
                const updatedDateTime = {
                    ...dateTime,
                    ...(dateTime.isChangedForThisWeekOnly
                        ? {
                              oneOffDateTimeMetadata: {
                                  startTime: selectedDateTime.startTime,
                                  endTime: selectedDateTime.endTime,
                                  day: selectedDateTime.day,
                                  timezoneRegion: TimeUtils.getTimezoneRegion(),
                                  timezoneOffset: new Date().getTimezoneOffset(),
                                  ...dateTime.oneOffDateTimeMetadata,
                                  meetingFormat: MeetingFormat.Live,
                              },
                          }
                        : { meetingFormat: MeetingFormat.Live }),
                };

                setSelectedDateTime(updatedDateTime);
                setStandupDayTimes([...standupDayTimesSelected, updatedDateTime]);
            } else if (
                getSelectedDateTimeInformation<MeetingFormat | null>(selectedDateTime, 'meetingFormat') ===
                MeetingFormat.Live
            ) {
                const dateTime = standupDayTimesSelected.find((dateTime) => dateTime.day === day)!;
                const updatedDateTime = {
                    ...dateTime,
                    ...(dateTime.isChangedForThisWeekOnly
                        ? {
                              oneOffDateTimeMetadata: {
                                  startTime: selectedDateTime.startTime,
                                  endTime: selectedDateTime.endTime,
                                  day: selectedDateTime.day,
                                  timezoneRegion: TimeUtils.getTimezoneRegion(),
                                  timezoneOffset: new Date().getTimezoneOffset(),
                                  ...dateTime.oneOffDateTimeMetadata,
                                  meetingFormat: MeetingFormat.Async,
                              },
                          }
                        : { meetingFormat: MeetingFormat.Async }),
                };

                setSelectedDateTime(updatedDateTime);
                setStandupDayTimes([...standupDayTimesSelected, updatedDateTime]);
            } else if (
                getSelectedDateTimeInformation<MeetingFormat | null>(selectedDateTime, 'meetingFormat') ===
                MeetingFormat.Async
            ) {
                // if it's the selected dateTime and we're clicking it, we want to remove
                const dateTime = standupDayTimesSelected.find((dateTime) => dateTime.day === day)!;
                const updatedDateTime = {
                    ...dateTime,
                    ...(dateTime.isChangedForThisWeekOnly
                        ? {
                              oneOffDateTimeMetadata: {
                                  startTime: dateTime.startTime,
                                  endTime: dateTime.endTime,
                                  day: dateTime.day,
                                  timezoneRegion: TimeUtils.getTimezoneRegion(),
                                  timezoneOffset: new Date().getTimezoneOffset(),
                                  ...dateTime.oneOffDateTimeMetadata,
                                  meetingFormat: MeetingFormat.Live,
                              },
                          }
                        : { meetingFormat: null }),
                };

                setSelectedDateTime(updatedDateTime);
                setStandupDayTimes([...standupDayTimesSelected, updatedDateTime]);
            }
        } else {
            if (isDayChosen(day)) {
                setSelectedDateTime(standupDayTimesSelected.find((dateTime) => dateTime.day === day)!);
            } else {
                const defaultDateTime = getDefaultDateTime(standupDayTimesSelected, lastTimestampSelected, day);

                setSelectedDateTime(defaultDateTime);
                setStandupDayTimes([...standupDayTimesSelected, defaultDateTime]);
            }
        }
    };

    useEffect(() => {
        const meetingFormatLabel =
            listItemValues.find((val) => val.code === getSelectedDateTimeInformation(selectedDateTime, 'meetingFormat'))
                ?.label ?? undefined;
        setDropdownMeetingFormat(meetingFormatLabel);
    }, [selectedDateTime, setDropdownMeetingFormat]);

    const { textTimeString } = getDateTimeStringWithTimezone(selectedDateTime);
    const { isValid, validationMessage } = useIsChangedForThisWeekOnlyValidation(selectedDateTime);

    return (
        <>
            <ResponsiveRow id={`in-meeting-standup-time-selection`} centered>
                {ORDERED_DAYS.map((day) => {
                    const internalDateTime = standupDayTimesSelected.find((dateTime) => dateTime.day === day);
                    const { fullTimeTextWithTimezone: hoveredTextTimeStringWithTimeZone } =
                        getDateTimeStringWithTimezone(internalDateTime ?? null);
                    return (
                        <BootstrapTooltip
                            title={
                                internalDateTime && internalDateTime.meetingFormat ? (
                                    <>
                                        <Column centered style={{ backgroundColor: '#424242' }}>
                                            <BodySubtitle style={{ color: 'white' }}>
                                                {internalDateTime.meetingFormat &&
                                                    `${
                                                        internalDateTime.meetingFormat.charAt(0).toUpperCase() +
                                                        internalDateTime.meetingFormat.slice(1)
                                                    } meeting`}
                                            </BodySubtitle>
                                            {internalDateTime.meetingFormat && (
                                                <BodySubtitle style={{ color: 'white' }}>
                                                    Starts at {hoveredTextTimeStringWithTimeZone}
                                                </BodySubtitle>
                                            )}
                                        </Column>
                                    </>
                                ) : (
                                    ''
                                )
                            }
                            placement={'bottom'}
                            arrow
                        >
                            <CircleButton
                                id={`${day}-circle-button`}
                                key={`${day}-circle-button`}
                                chosen={
                                    isDayChosen(day) &&
                                    !!getSelectedDateTimeInformation<MeetingFormat | null>(
                                        standupDayTimesSelected.find((dayTime) => dayTime.day === day)!,
                                        'meetingFormat'
                                    )
                                }
                                meetingFormat={
                                    isDayChosen(day) && standupDayTimesSelected.find((dayTime) => dayTime.day === day)
                                        ? getSelectedDateTimeInformation<MeetingFormat | null>(
                                              standupDayTimesSelected.find((dayTime) => dayTime.day === day)!,
                                              'meetingFormat'
                                          )
                                        : null
                                }
                                selected={selectedDateTime?.day === day}
                                onClick={() => onDateSelection(day)}
                            >
                                {day.substring(0, 2)}
                            </CircleButton>
                        </BootstrapTooltip>
                    );
                })}
            </ResponsiveRow>

            <Spacing factor={1 / 3} />

            <Column
                style={
                    selectedDateTime
                        ? {
                              border: `0.5px solid ${lightTheme.primary.midnight}`,
                              paddingTop: '10px',
                              paddingBottom: '10px',
                          }
                        : {}
                }
            >
                {selectedDateTime && (
                    <SelectedDateTimeContainer id={`selected-date-time-container`}>
                        <BodyRegular style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <b>{selectedDateTime.day} details</b>
                        </BodyRegular>
                        <Spacing factor={1 / 3} />
                        <Row style={{ justifyContent: 'space-evenly' }}>
                            <Column style={{ width: 'unset' }}>
                                <BodyRegular>
                                    <BootstrapTooltip
                                        title={
                                            getSelectedDateTimeInformation<MeetingFormat | null>(
                                                selectedDateTime,
                                                'meetingFormat'
                                            ) === null
                                                ? 'This is a day you do not have standup'
                                                : ''
                                        }
                                        arrow
                                        placement={'top'}
                                        PopperProps={{ style: { display: 'flex', justifyContent: 'center' } }}
                                    >
                                        <div>
                                            <DropDown
                                                title={'Meeting Format'}
                                                values={listItemValues.filter((meetingFormatListItemValue) =>
                                                    selectedDateTime.isChangedForThisWeekOnly
                                                        ? meetingFormatListItemValue.code !== null
                                                        : true
                                                )}
                                                selected={dropdownMeetingFormat}
                                                defaultValue={
                                                    listItemValues.find(
                                                        (val) =>
                                                            val.code ===
                                                            getSelectedDateTimeInformation(
                                                                defaultSelectedDateTime,
                                                                'meetingFormat'
                                                            )
                                                    )?.label ?? listItemValues[0].label
                                                }
                                                handleSelection={(code) => {
                                                    setMeetingFormat(selectedDateTime.day, code);
                                                }}
                                                buttonProps={{
                                                    style: { padding: '4px 12px' },
                                                    labelStyles: { fontSize: '15px' },
                                                }}
                                            />
                                        </div>
                                    </BootstrapTooltip>
                                </BodyRegular>
                            </Column>
                            <Column style={{ width: 'unset', alignItems: 'center' }}>
                                <DropDown
                                    title={'Time'}
                                    values={[...timeValuesAm, ...timeValuesPm]}
                                    selected={textTimeString}
                                    handleSelection={(code) => {
                                        const minutes = code.split(':')[1];
                                        const hours = code.split(':')[0];

                                        const startTime = TimeUtils.getHHMMFromHoursMinutes(hours, minutes);

                                        let updatedDateTime: DateTimeMetadata = {
                                            ...selectedDateTime,
                                        } as DateTimeMetadata;

                                        const startTimeValue = startTime;
                                        let endTimeValue = updatedDateTime.endTime;
                                        if (selectedDateTime.isChangedForThisWeekOnly) {
                                            updatedDateTime = {
                                                ...selectedDateTime,
                                                oneOffDateTimeMetadata: {
                                                    ...selectedDateTime.oneOffDateTimeMetadata,
                                                    timezoneRegion: TimeUtils.getTimezoneRegion(),
                                                    timezoneOffset: new Date().getTimezoneOffset(),
                                                    startTime,
                                                },
                                            } as DateTimeMetadata;
                                            endTimeValue =
                                                updatedDateTime.oneOffDateTimeMetadata?.endTime ||
                                                updatedDateTime.endTime ||
                                                DEFAULT_END_TIME;
                                        } else {
                                            updatedDateTime = {
                                                ...selectedDateTime,
                                                timezoneRegion: TimeUtils.getTimezoneRegion(),
                                                timezoneOffset: new Date().getTimezoneOffset(),
                                                startTime,
                                            } as DateTimeMetadata;
                                        }

                                        const MAX_TIME_TO_INCREMENT_PAST = 1410; // 11:30
                                        if (
                                            TimeUtils.isStartTimeInvalid(startTimeValue, endTimeValue) &&
                                            TimeUtils.getMinutesFromHHMM(startTimeValue) < MAX_TIME_TO_INCREMENT_PAST
                                        ) {
                                            // set endTime to 30m after start
                                            const minutesOfNewEndTime =
                                                TimeUtils.getMinutesFromHHMM(startTimeValue) + 30;
                                            const hours = Math.floor(minutesOfNewEndTime / 60);
                                            const minutes = minutesOfNewEndTime % 60;
                                            const newEndTime = TimeUtils.getHHMMFromHoursMinutes(
                                                hours.toString(),
                                                minutes.toString()
                                            );
                                            if (selectedDateTime.isChangedForThisWeekOnly) {
                                                updatedDateTime = {
                                                    ...updatedDateTime,
                                                    oneOffDateTimeMetadata: {
                                                        ...updatedDateTime.oneOffDateTimeMetadata,
                                                        timezoneRegion: TimeUtils.getTimezoneRegion(),
                                                        timezoneOffset: new Date().getTimezoneOffset(),
                                                        endTime: newEndTime, // start time + 30
                                                    },
                                                } as DateTimeMetadata;
                                            } else {
                                                updatedDateTime = {
                                                    ...updatedDateTime,
                                                    timezoneRegion: TimeUtils.getTimezoneRegion(),
                                                    timezoneOffset: new Date().getTimezoneOffset(),
                                                    endTime: newEndTime, // start time + 30
                                                };
                                            }
                                        }

                                        setLastTimestampSelected(
                                            getSelectedDateTimeInformation<HHMMTimeString>(updatedDateTime, 'startTime')
                                        );

                                        updatedDateTime = {
                                            ...updatedDateTime,
                                            dayOfYearToSkipAfterSameDayAdHoc: undefined,
                                        };

                                        setStandupDayTimes([...standupDayTimesSelected, updatedDateTime]);
                                        setSelectedDateTime(updatedDateTime);
                                    }}
                                    buttonProps={{ style: { padding: '4px 12px' }, labelStyles: { fontSize: '15px' } }}
                                />
                            </Column>
                        </Row>
                    </SelectedDateTimeContainer>
                )}

                {invalidStartTimeMessage && (
                    <ErrorMessage key={updatedErrorNotice + invalidStartTimeMessage}>
                        {invalidStartTimeMessage}
                    </ErrorMessage>
                )}
                {selectedDateTime && (
                    <BootstrapTooltip title={isValid ? '' : validationMessage ?? ''}>
                        <Row
                            id={`change-it-for-this-${selectedDateTime.day}-only-row`}
                            style={{ alignItems: 'center', justifyContent: 'center' }}
                        >
                            <Checkbox
                                id={`change-it-for-this-${selectedDateTime.day}-only-checkbox`}
                                checked={!!selectedDateTime.isChangedForThisWeekOnly}
                                disabled={!isValid}
                                onChange={() => {
                                    const updatedCheckValue = !selectedDateTime.isChangedForThisWeekOnly;

                                    const meetingFormat =
                                        !liveSeries.schedule?.hasDayInSchedule(selectedDateTime.day) &&
                                        updatedCheckValue
                                            ? null
                                            : selectedDateTime.meetingFormat;

                                    const mergedValue: DateTimeMetadata = {
                                        ...selectedDateTime,
                                        oneOffDateTimeMetadata: {
                                            ...selectedDateTime.oneOffDateTimeMetadata,
                                            meetingFormat: selectedDateTime.meetingFormat,
                                            startTime: selectedDateTime.startTime,
                                            endTime: selectedDateTime.endTime,
                                            day: selectedDateTime.day,
                                            timezoneRegion: TimeUtils.getTimezoneRegion(),
                                            timezoneOffset: new Date().getTimezoneOffset(),
                                        },
                                        isChangedForThisWeekOnly: updatedCheckValue,
                                        meetingFormat,
                                    };

                                    setStandupDayTimes([...standupDayTimesSelected, mergedValue]);
                                    setSelectedDateTime(mergedValue);
                                }}
                                style={{
                                    color: isValid ? lightTheme.secondary.green : lightTheme.tertiary.midnight,
                                }}
                                inputProps={{
                                    'aria-label': 'same time for all standup selection',
                                }}
                            />
                            <BodyRegular
                                style={{
                                    color: isValid ? lightTheme.primary.midnight : lightTheme.tertiary.midnight,
                                }}
                                id={`change-it-for-this-week-only-text`}
                            >
                                Change it for this {selectedDateTime.day} only.
                            </BodyRegular>
                        </Row>
                    </BootstrapTooltip>
                )}
            </Column>
            <Spacing />
            {isCustomSlackRemindersEnabled ? (
                <>
                    <InMeetingStandupCustomSlackReminders
                        reminderSettings={editableReminderSettings}
                        setReminderSettings={setEditableReminderSettings}
                    />
                    <Spacing />
                </>
            ) : null}
            <PrimaryButton
                id={`meeting-schedule-primary-button-${hasBeenCreated ? 'update' : 'create'}`}
                title={hasBeenCreated ? 'Update schedule' : 'Create schedule'}
                isLoading={isLoading}
                onClick={() => {
                    const invalidDayTime = standupDayTimesSelected
                        .filter((dayTime) => !!dayTime.meetingFormat)
                        .find((dayTime) =>
                            TimeUtils.isStartTimeInvalid(
                                getSelectedDateTimeInformation(dayTime, 'startTime'),
                                getSelectedDateTimeInformation(dayTime, 'endTime')
                            )
                        );

                    if (invalidDayTime) {
                        // pop toast
                        setSelectedDateTime(invalidDayTime);
                        setUpdatedErrorNotice(new Date().toISOString());
                    } else {
                        onSubmit(new SeriesSchedule(standupDayTimesSelected), false, editableReminderSettings);
                    }
                }}
                disabled={
                    (standupDayTimesSelected.filter(
                        (dayTime) => !!getSelectedDateTimeInformation(dayTime, 'meetingFormat')
                    ).length === 0 &&
                        !hasBeenCreated) ||
                    !hasBeenEdited
                }
            />
        </>
    );
};

export function InMeetingStandupTimeSelection({
    onSubmit,
    isLoading,
    dateTimes,
    reminderSettings,
}: {
    onSubmit: (
        standupDateTimeSelected: SeriesSchedule,
        shouldSendSlackSummary: boolean,
        reminderSettings?: ReminderSettings
    ) => void;
    isLoading: boolean;
    dateTimes?: DateTimeMetadata[];
    reminderSettings: ReminderSettings;
}): JSX.Element {
    const isCustomSlackRemindersEnabled = useCustomSlackRemindersEnablement();

    return (
        <>
            <InMeetingStandupTimesSelectionHeader showReminderMessage={!isCustomSlackRemindersEnabled} />
            <Spacing factor={1 / 3} />
            <TimeSelection
                dateTimes={dateTimes}
                onSubmit={onSubmit}
                isLoading={isLoading}
                isCustomSlackRemindersEnabled={isCustomSlackRemindersEnabled}
                reminderSettings={reminderSettings}
            />
        </>
    );
}
