import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { WebUrlQuery } from '@spinach-shared/types';

export function useEmailSearchRemoval() {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const searchEmail = searchParams.get(WebUrlQuery.Email);
        if (searchEmail) {
            searchParams.delete(WebUrlQuery.Email);
            setSearchParams(searchParams, { replace: true });
        }
    }, [searchParams.get(WebUrlQuery.Email)]);
}
