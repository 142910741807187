import { AsyncVideoDTO, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function getAsyncVideo({ botId }: { botId: string }): Promise<AsyncVideoDTO> {
    const response = await getSpinachAPI<AsyncVideoDTO>(SpinachAPIPath.AsyncVideo, {
        params: {
            botId,
        },
        throwOnError: true,
    });
    return response!; // we are throwing on error so we can safely assert that response is not undefined
}
