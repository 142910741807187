import { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';

import { NotificationCode, NotificationPayload, ServerSocketEvent } from '@spinach-shared/types';

import { SetValue } from '..';

export type LiveNotificationControls = {
    isNotificationToastOpen: boolean;
    notificationMessage: string;
    notificationCode: NotificationCode | null;
    setIsNotificationToastOpen: SetValue<boolean>;
    triggeringSpinachUserId?: string;
};

export function useLiveNotifications(
    socket: Socket,
    scope: 'SLACK' | 'INACTIVITY' | 'REACTION'
): LiveNotificationControls {
    const [isNotificationToastOpen, setIsNotificationToastOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationCode, setNotificationCode] = useState<NotificationCode | null>(null);
    const [triggeringSpinachUserId, setTriggeringSpinachUserId] = useState<string | undefined>(undefined);

    useEffect(() => {
        socket.on(ServerSocketEvent.Notification, (response: NotificationPayload) => {
            const { message, code } = response;
            // allow us to reuse useLiveNotification while sharing it for different use cases
            if (code.includes(scope)) {
                setNotificationMessage(message);
                setNotificationCode(code);
                setIsNotificationToastOpen(true);
                setTriggeringSpinachUserId(response.triggeringSpinachUserId);
            }
        });
    }, [socket]);

    return {
        isNotificationToastOpen,
        notificationMessage,
        notificationCode,
        setIsNotificationToastOpen,
        triggeringSpinachUserId,
    };
}
