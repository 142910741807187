import { useState } from 'react';
import styled from 'styled-components';

import { UserSeriesMetadata } from '@spinach-shared/types';
import { isDemoSeries, isProductionStage } from '@spinach-shared/utils';

import { GlobalModal, atomDashboardSocket } from '../../atoms';
import { ElementId } from '../../constants';
import {
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNullableStoredSeries,
    useGlobalRouting,
    useTagManagerDestruction,
    useUserIdentification,
    useWebsocket,
} from '../../hooks';
import { useClearSeriesOnMount, useRedirectionWithSeriesLoaded } from '../../hooks/useDashboard';
import { useGlobalStoredSeriesList } from '../../hooks/useGlobalStoredSeriesList';
import { HeaderThree } from '../../styles';
import { FYI, FYIState, IntercomCustom, SpinachStationary, UserAccountButton, ViewContainer } from '../common';
import { AIDashboardCard } from './AIDashboardCard';
import { CreateSeriesCard } from './CreateSeriesCard';
import { SeriesDashboardItem } from './SeriesDashboardItem';
import { ShareSeriesModal } from './ShareSeriesModal';
import { ScrollView } from './common';

const Stationary = styled(SpinachStationary)`
    min-height: 65%;
`;

const showDemoSeriesToo = true;

export function SeriesDashboard(): JSX.Element {
    const { socket } = useWebsocket(atomDashboardSocket);
    const {
        storedSeriesListState: { storedSeriesList, lastFetched },
    } = useGlobalStoredSeriesList();
    const [series, setGlobalSeries] = useGlobalNullableStoredSeries();
    const [user] = useGlobalAuthedUser();
    const [shouldShowInviteModal, setShouldShowInviteModal] = useState(false);
    const [globalModal, setGlobalModal] = useGlobalModal();
    useUserIdentification(user);
    useRedirectionWithSeriesLoaded();
    useClearSeriesOnMount();
    useTagManagerDestruction();

    const { routeToAIDashboard } = useGlobalRouting();

    const [hoveredSeries, setHoveredSeries] = useState<UserSeriesMetadata | null>(null);
    const [selectedSeries, setSelectedSeries] = useState<UserSeriesMetadata | null>(null);
    const [areOptionsOpen, setOptionsOpen] = useState<boolean>(false);

    const hasAtLeastOneScribeSeries = storedSeriesList.some((series) => !!series.metadata?.scribeMetadata);

    if (!lastFetched) {
        return <FYI state={FYIState.Loading} />;
    }

    return (
        <ViewContainer>
            <UserAccountButton />
            <Stationary id={ElementId.SeriesDashboardContainer}>
                <HeaderThree>{user.isScribeOnlyUser ? 'Your Meetings' : 'Your Standups'}</HeaderThree>
                <ScrollView stretch={true} sidePadding={0}>
                    {user.seriesMetadataList
                        .filter((series) => (!isProductionStage() ? showDemoSeriesToo : !isDemoSeries(series.id)))
                        .filter((series) => !!storedSeriesList.find((s) => s.id === series.id))
                        .filter((series) => !storedSeriesList.find((s) => s.id === series.id)?.scribeMetadata)
                        .map((series) => {
                            const storedSeriesForCard = storedSeriesList.find((s) => s.id === series.id);
                            const noElementsOpened = !(
                                areOptionsOpen ||
                                globalModal === GlobalModal.RenameSeries ||
                                globalModal === GlobalModal.RemoveSeries
                            );

                            return (
                                <SeriesDashboardItem
                                    key={series.id}
                                    series={series}
                                    storedSeries={storedSeriesForCard?.toJSON()}
                                    setOptionsOpen={setOptionsOpen}
                                    noElementsOpened={noElementsOpened}
                                    areOptionsOpen={areOptionsOpen}
                                    setHoveredSeries={setHoveredSeries}
                                    hoveredSeries={hoveredSeries}
                                    onClickRemove={() => {
                                        setGlobalSeries(storedSeriesForCard?.toJSON());
                                        setGlobalModal(GlobalModal.RemoveSeries);
                                    }}
                                    onClickRename={() => {
                                        setGlobalSeries(storedSeriesForCard?.toJSON());
                                        setGlobalModal(GlobalModal.RenameSeries);
                                    }}
                                />
                            );
                        })}

                    {hasAtLeastOneScribeSeries || user.isScribeOnlyUser ? (
                        <AIDashboardCard
                            onClick={() => {
                                routeToAIDashboard();
                            }}
                        />
                    ) : null}

                    {user.isScribeOnlyUser ? (
                        <></>
                    ) : (
                        <CreateSeriesCard
                            onOpenCreateSeriesModal={() => {
                                setGlobalModal(GlobalModal.CreateSeries);
                            }}
                        />
                    )}
                </ScrollView>
            </Stationary>

            {shouldShowInviteModal && series ? (
                <ShareSeriesModal
                    userId={user.spinachUserId}
                    isOpen={!!shouldShowInviteModal}
                    onClose={() => {}}
                    series={series}
                    redirectOnClose={true}
                />
            ) : null}
            <IntercomCustom />
        </ViewContainer>
    );
}
