import { SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function getZoomCollab(
    meetingUUID: string
): Promise<{ meetingUUID: string; seriesId: string } | undefined> {
    const response = await getSpinachAPI<{ meetingUUID: string; seriesId: string }>(
        SpinachAPIPath.ZoomCollaborateMeetingUUID.replace(':meetingUUID', encodeURIComponent(meetingUUID)),
        { params: { ts: new Date().getTime() } }
    );
    return response;
}
