export enum Titles {
    TeamTopics = 'Team Topics',
    Send = 'Send',
    ExploreStandup = 'Explore Standup',
    Next = 'Next',
    SelectAnAnswer = 'Select an answer',
    SetMeUp = 'Set Me Up',
    Refresh = 'Refresh',
    EnableJira = 'Enable Jira tickets to be attached to your updates.',
    EnableAsana = 'Enable Asana tasks to be attached to your updates.',
    EnableGoogle = 'Integrate with Google calendar to sync your check-in with items in your calendar.',
    NotYetConnectedToSlackChannel = 'This meeting is not yet connected to a Slack channel',
    ConnectSpinachToSlack = 'Connect Spinach to Slack to allow summaries to be outputed automatically to a channel of your choice.',
    ReinstallSlackApp = 'Reinstall the Spinach Slack App to get the most up-to-date functionality!',
    CopyToYesterday = 'Copy to Yesterday',
    CopyToToday = 'Copy to Today',
    CopyToBlockers = 'Copy to Blockers',
    CopyToSharedTopics = 'Copy to Shared Topics',
    SelectASeriesType = 'Select a series type',
    Cancel = 'Cancel',
    NoThanks = 'No Thanks',
    NoThanksIllRemindTeam = "No thanks, I'll remind the team",
    ConnectToSlack = 'Connect to Slack',
    SlackTheTeam = 'Slack the Team',
    IInstalledIt = 'I installed it',
    SaveStandup = 'Save Standup',
    YesLetsGo = "Yes, let's go!",
    NoImJoiningMyTeam = "No, I'm joining my team",
    Back = 'Back',
    LaunchStandup = 'Launch Standup',
    GotItLetsGo = "Got it, let's go",
    Done = 'Done',
    Edit = 'Edit',
    InviteMyTeam = 'Invite My Team',
    FinishSetup = 'Finish Setup',
    JiraTicket = 'Jira Ticket:',
    AsanaTicket = 'Asana Task:',
    Create = 'Create',
}
