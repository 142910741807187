import { EmailVerificationResponse, SpinachAPIPath, VerifyEmailRequest } from '@spinach-shared/types';

import { postSpinachAPI } from '..';

export async function postEmailVerify(request: VerifyEmailRequest): Promise<EmailVerificationResponse> {
    try {
        const response = await postSpinachAPI<EmailVerificationResponse>(SpinachAPIPath.VerifyEmail, request);
        if (!response) {
            return { success: false };
        }
        return response;
    } catch {
        return { success: false };
    }
}
