import { Modal } from '@material-ui/core';
import { useEffect } from 'react';

import { teamTopicsUpdateSectionTypeProps } from '@spinach-shared/constants';
import {
    AgendaItemBaseRequest,
    BaseIDRequest,
    ClientEventType,
    ClientSocketEvent,
    MeetingStatus,
    SpinachUpdateType,
} from '@spinach-shared/types';

import { GlobalModal } from '../../../atoms';
import { ElementId } from '../../../constants';
import {
    useActivityTracking,
    useGlobalAuthedUser,
    useGlobalLiveSeries,
    useGlobalModal,
    usePrevious,
} from '../../../hooks';
import { useGlobalMeetingSocket } from '../../../hooks/useGlobalSocket';
import { useLiveUpdateCheckInSection } from '../../../hooks/useLiveUpdateCheckInSection';
import { useUpdateCounters } from '../../../hooks/useUpdateCounters';
import { ResponsiveModalTitle } from '../../../styles';
import { createWebsocketPayload } from '../../../utils';
import { LiveUpdateViewProps, ScrollArea } from '../../stand-up';
import { TeamTopicsUpdateSection } from '../../stand-up/TeamTopicsUpdateSection';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

export function AgentAgendaModal() {
    const [globalModal, setGlobalModal] = useGlobalModal();
    const [liveSeries] = useGlobalLiveSeries();
    const [socket] = useGlobalMeetingSocket();
    const [user] = useGlobalAuthedUser();

    const trackActivity = useActivityTracking();

    const closeModal = () => {
        trackActivity(ClientEventType.AgentUxActivity, 'Agenda Modal Closed');
        setGlobalModal(null);
    };

    const userItem = liveSeries.thisParticipantsYTBAgendaItem;

    const { incrementUpdateCount } = useUpdateCounters();

    const { createUpdateEmitter, createFullUpdateEmitter, createReorderEmitter, typedUpdatesMap, setTypedUpdatesMap } =
        useLiveUpdateCheckInSection(
            userItem,
            socket,
            liveSeries.slug,
            liveSeries.currentMeeting.agenda,
            incrementUpdateCount
        );

    const liveUpdateViewProps: LiveUpdateViewProps = {
        standUpUpdate: userItem.standUpUpdate,
        createUpdateEmitter,
        createFullUpdateEmitter,
        createReorderEmitter,
    };

    // trigger start agenda when the first topic is added and it hasn't already started and is creator of topic item
    const previousTopicItemLength = usePrevious(liveSeries.agenda.topicItems.length);
    useEffect(() => {
        const wasFirstTopicAdded = previousTopicItemLength === 0 && liveSeries.agenda.topicItems.length === 1;

        if (wasFirstTopicAdded && liveSeries.meetingStatus === MeetingStatus.Initialized) {
            trackActivity(ClientEventType.AgentUxActivity, 'Auto-started Agenda Upon First Topic Added');
            const payload = createWebsocketPayload<BaseIDRequest>({
                spinachUserId: user.spinachUserId,
                seriesSlug: liveSeries.slug,
                meetingId: liveSeries.currentMeeting.id,
            });
            socket.emit(ClientSocketEvent.AgendaStarting, payload);
        }
    }, [
        liveSeries.agenda.topicItems.length,
        liveSeries.currentMeeting.id,
        liveSeries.meetingStatus,
        liveSeries.slug,
        previousTopicItemLength,
        socket,
        trackActivity,
        user.spinachUserId,
    ]);

    const previousMeetingStatus = usePrevious(liveSeries.meetingStatus);

    // if agenda was jsut started and we have 1+ topic, navigate directly to topic 1
    useEffect(() => {
        const wasAgendaJustStarted =
            previousMeetingStatus === MeetingStatus.Initialized &&
            liveSeries.meetingStatus === MeetingStatus.AgendaStarted;

        if (wasAgendaJustStarted && liveSeries.agenda.topicItems.length > 0) {
            trackActivity(ClientEventType.AgentUxActivity, 'Auto-progressed Agenda to First Topic');

            const itemId = liveSeries.agenda.topicItems[0].id;
            const agendaNavigationRequest = createWebsocketPayload<AgendaItemBaseRequest>({
                spinachUserId: user.spinachUserId,
                meetingId: liveSeries?.currentMeeting.id,
                seriesSlug: liveSeries.slug,
                itemId,
            });
            socket.emit(ClientSocketEvent.AgendaNavigating, agendaNavigationRequest);
        }
    }, [
        liveSeries.agenda.topicItems,
        liveSeries.currentAgendaItem,
        liveSeries?.currentMeeting.id,
        liveSeries.meetingStatus,
        liveSeries.slug,
        previousMeetingStatus,
        socket,
        trackActivity,
        user.spinachUserId,
    ]);

    // as a part of this mvp, we still have participant items in the table were just not using them
    // if we somehow landed on one, go to the first topic on the list instead.
    // in the future, we should auto-turn off roundtable for series if avatar series
    useEffect(() => {
        if (
            liveSeries.meetingStatus === MeetingStatus.AgendaStarted &&
            liveSeries.currentAgendaItem?.isParticipantAgendaItem === true &&
            !!liveSeries.agenda.topicItems.length
        ) {
            trackActivity(ClientEventType.AgentUxActivity, 'Adjusted Current Agenda Item from Participant to Topic');
            const itemId = liveSeries.agenda.topicItems[0].id;
            const agendaNavigationRequest = createWebsocketPayload<AgendaItemBaseRequest>({
                spinachUserId: user.spinachUserId,
                meetingId: liveSeries?.currentMeeting.id,
                seriesSlug: liveSeries.slug,
                itemId,
            });
            socket.emit(ClientSocketEvent.AgendaNavigating, agendaNavigationRequest);
        }
    }, [
        liveSeries.agenda.topicItems,
        liveSeries.currentAgendaItem?.isParticipantAgendaItem,
        liveSeries?.currentMeeting.id,
        liveSeries.meetingStatus,
        liveSeries.slug,
        socket,
        trackActivity,
        user.spinachUserId,
    ]);

    return (
        <Modal open={globalModal === GlobalModal.AgentAgenda} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden', maxHeight: '70%' }}>
                <Column centered>
                    <Row centered>
                        <ResponsiveModalTitle>Agenda</ResponsiveModalTitle>
                    </Row>
                    <Spacing factor={1 / 3} />
                </Column>

                <ScrollArea stretch={true} balancePadding={true} style={{ width: 'calc(100% - 20px)' }}>
                    <TeamTopicsUpdateSection
                        id={ElementId.TeamTopicsDrawerContainer}
                        typeProps={teamTopicsUpdateSectionTypeProps}
                        typedUpdates={typedUpdatesMap[SpinachUpdateType.Topic]}
                        currentAgendaItem={liveSeries.currentAgendaItem}
                        agenda={liveSeries.agenda}
                        setTypedUpdates={setTypedUpdatesMap[SpinachUpdateType.Topic]}
                        updateViewProps={liveUpdateViewProps}
                        isInNavDrawer={true}
                    />
                </ScrollArea>
            </SpinachModalContent>
        </Modal>
    );
}
