import { Agenda } from '@spinach-shared/types';

import { ActiveAgendaProps } from './ActiveAgendaProps';
import { BaseAgendaProps } from './BaseAgendaProps';

export class AgendaPropsFactory {
    static createAgendaProps(agenda: Agenda): BaseAgendaProps {
        if (agenda.currentIndex !== undefined && agenda.startedAt) {
            return new ActiveAgendaProps({
                startedAt: agenda.startedAt,
                currentIndex: agenda.currentIndex,
                items: agenda.items,
            });
        } else {
            return new BaseAgendaProps({ startedAt: agenda.startedAt, items: agenda.items });
        }
    }
}
