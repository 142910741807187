import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';

import { ClientEventType, MinimalAtlassianGetAvailableResourceItem, SpinachIntegration } from '@spinach-shared/types';

import { Column, getUser, useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser } from '../../../..';
import { patchAtlassianSite } from '../../../apis/patchAtlassianSite';
import { useAtlassianSites } from '../../../hooks/useAtlassianSites';
import { SpinachAutocomplete } from '../SpinachAutocomplete';
import { Row } from '../framing';

export function AtlassianSiteSelectionContent({
    atlassianSource,
}: {
    atlassianSource: SpinachIntegration.Jira | SpinachIntegration.Confluence;
    isFirstTimeAuth: boolean;
}) {
    const [user, setUser] = useGlobalAuthedUser();
    const { atlassianSites } = useAtlassianSites();
    const { setToastText } = useGlobalAiDashboard();

    const track = useExperienceTracking();
    const jiraAccountId = user.integrationSettings?.jiraSettings?.jiraAccountId;
    const confluenceAccountId = user.integrationSettings?.confluenceSettings?.confluenceAccountId;

    const jiraAccountUrl = user.integrationSettings?.jiraSettings?.jiraAccountUrl;
    const confluenceAccountUrl = user.integrationSettings?.confluenceSettings?.confluenceAccountUrl;
    const specificAtlassianSites = atlassianSites?.[atlassianSource];

    const [selectedAtlassianSite, setSelectedAtlassianSite] = useState<
        MinimalAtlassianGetAvailableResourceItem | undefined
    >(
        atlassianSource === SpinachIntegration.Jira && jiraAccountId && jiraAccountUrl
            ? {
                  id: jiraAccountId,
                  url: jiraAccountUrl,
              }
            : atlassianSource === SpinachIntegration.Confluence && confluenceAccountId && confluenceAccountUrl
            ? {
                  id: confluenceAccountId,
                  url: confluenceAccountUrl,
              }
            : specificAtlassianSites?.length === 1
            ? specificAtlassianSites[0]
            : undefined
    );
    const [isAtlassianSiteSelectionLoading, setIsAtlassianSiteSelectionLoading] = useState(false);

    useEffect(() => {
        if (atlassianSource === SpinachIntegration.Jira && jiraAccountId && jiraAccountUrl) {
            setSelectedAtlassianSite({
                id: jiraAccountId,
                url: jiraAccountUrl,
            });
        } else if (atlassianSource === SpinachIntegration.Confluence && confluenceAccountId && confluenceAccountUrl) {
            setSelectedAtlassianSite({
                id: confluenceAccountId,
                url: confluenceAccountUrl,
            });
        } else if (specificAtlassianSites?.length === 1) {
            setSelectedAtlassianSite(specificAtlassianSites[0]);
        }
    }, [
        specificAtlassianSites,
        atlassianSource,
        jiraAccountId,
        jiraAccountUrl,
        confluenceAccountId,
        confluenceAccountUrl,
    ]);

    return specificAtlassianSites?.length ? (
        <>
            <Row>
                <Column>
                    <Box
                        style={{
                            fontWeight: 500,
                            fontSize: '17px',
                            textTransform: 'uppercase',
                            letterSpacing: '1.5px',
                        }}
                    >{`Confirm Site Selection`}</Box>
                    <Row>
                        <SpinachAutocomplete
                            id="atlassian-site-selection"
                            options={specificAtlassianSites ?? []}
                            value={selectedAtlassianSite}
                            isLoading={isAtlassianSiteSelectionLoading}
                            defaultValue={specificAtlassianSites[0]}
                            getOptionSelected={(option, value) => option.id === value.id}
                            style={{
                                width: '300px',
                                marginBottom: '2px',
                                transform: 'scale(0.8)',
                                transformOrigin: 'center left',
                                marginRight: '-50px',
                            }}
                            ListboxProps={{ style: { transform: 'scale(0.8)', transformOrigin: 'center left' } }}
                            getOptionLabel={(option) => option.url.replaceAll('https://', '')}
                            inputPlaceholder="Select an Atlassian site"
                            onChange={async (event, atlassianSiteSelected) => {
                                try {
                                    setIsAtlassianSiteSelectionLoading(true);

                                    if (!atlassianSiteSelected) {
                                        return;
                                    }

                                    setSelectedAtlassianSite(atlassianSiteSelected);
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Atlassian Site Selected`,
                                        AtlassianSite: atlassianSiteSelected.name,
                                        AtlassianSource: atlassianSource,
                                        IsJiraSite: atlassianSource === SpinachIntegration.Jira,
                                        IsConfluenceSite: atlassianSource === SpinachIntegration.Confluence,
                                    });

                                    await patchAtlassianSite(atlassianSource, atlassianSiteSelected);
                                    const updatedUser = await getUser();
                                    if (updatedUser.user) {
                                        setUser(updatedUser.user);
                                    }
                                } catch (e) {
                                    setToastText('Error updating Atlassian site setting');
                                } finally {
                                    setIsAtlassianSiteSelectionLoading(false);
                                }
                            }}
                        />
                    </Row>
                </Column>
            </Row>
        </>
    ) : (
        <></>
    );
}
