import { v4 } from 'uuid';

import {
    CURRENT_ITEMS_CUSTOM_LIST_ID,
    FINISHED_ITEMS_CUSTOM_LIST_ID,
    PRESENTING_WORKING_ITEMS_CUSTOM_LIST_ID,
} from '@spinach-shared/constants';
import {
    GoalStatus,
    IYTBUpdateProps,
    Sentiment,
    SpinachUpdateType,
    StandUpUpdate,
    TypedUpdate,
    UUID,
    UpdatesByReservedType,
} from '@spinach-shared/types';

export class YTBUpdateProps {
    id: UUID;
    spinachUserId?: UUID;
    private _updates: TypedUpdate[];

    constructor({ id, spinachUserId, updates }: IYTBUpdateProps) {
        this.id = id ?? v4();
        this.spinachUserId = spinachUserId;
        this._updates = updates ?? [];
    }

    get updates(): TypedUpdate[] {
        return this._updates;
    }

    set updates(updates: TypedUpdate[]) {
        this._updates = this.omitEmpty(updates);
    }

    get hasCustomItems(): boolean {
        return this.updates.some((u) => u.updateType === SpinachUpdateType.Custom);
    }

    get hasParkingLotItems(): boolean {
        return this.updates.some((u) => u.updateType === SpinachUpdateType.ParkingLot && u.text !== '');
    }

    get hasTeamTopicItems(): boolean {
        return this.updates.some((u) => u.updateType === SpinachUpdateType.Topic);
    }

    get hasChallengeItems(): boolean {
        return this.updates.some((u) => u.updateType === SpinachUpdateType.Challenge && u.text !== '');
    }

    get hasYesterdayItems(): boolean {
        return this.updates.some((u) => u.updateType === SpinachUpdateType.Yesterday && u.text !== '');
    }

    get hasTodayItems(): boolean {
        return this.updates.some((u) => u.updateType === SpinachUpdateType.Today && u.text !== '');
    }

    get hasSentimentBlockerItems(): boolean {
        return this.updates.some((u) => u.sentiment && (u.sentiment === Sentiment.Bad || u.sentiment === Sentiment.Ok));
    }

    get updatesByReservedTypes(): UpdatesByReservedType {
        return Object.values(SpinachUpdateType).reduce<UpdatesByReservedType>((map, type) => {
            map[type] = this.getUpdatesForType(type);
            return map;
        }, {} as UpdatesByReservedType);
    }

    get hasNoUpdates(): boolean {
        return this.updates.filter((u) => u.updateType !== SpinachUpdateType.Topic).length === 0;
    }

    get itemsWithSentiment(): TypedUpdate[] {
        return this.updates.filter((u) => !!u.sentiment);
    }

    get itemsWithSentimentDetails(): TypedUpdate[] {
        return this.updates.filter((u) => !!u.sentimentDetails);
    }

    get itemsWithTicket(): TypedUpdate[] {
        return this.updates.filter((u) => !!u.ticketData?.ticket.id || !!u.asanaData?.id || !!u.jiraData?.id);
    }

    get hasNoIssueBasedUpdates(): boolean {
        const currentItemsUpdates = this.updates.filter(
            (update) =>
                update.customListId === CURRENT_ITEMS_CUSTOM_LIST_ID && update.goalMetadata?.status !== GoalStatus.Unset
        );
        const otherCustomUpdates = this.updates
            .filter((u) => u.updateType !== SpinachUpdateType.Topic)
            .filter((update) => update.customListId !== CURRENT_ITEMS_CUSTOM_LIST_ID);

        const totalLength = currentItemsUpdates.length + otherCustomUpdates.length;

        return totalLength === 0;
    }

    /**
     * Useful for getting the list of updates displayed in check-in, excluding team topics from user
     */
    get presentingUpdates(): TypedUpdate[] {
        const presentableTypes = [
            SpinachUpdateType.Yesterday,
            SpinachUpdateType.Today,
            SpinachUpdateType.Challenge,
            SpinachUpdateType.Custom,
        ];
        return this.updates.filter((u) => presentableTypes.includes(u.updateType));
    }

    getUpdateByID(id: string, goalId?: string): TypedUpdate | undefined {
        return this.updates?.find((u) => u.id === id || (!!goalId && u.goalMetadata?.goalId === goalId));
    }

    getUpdateOrSubItemByID(id: string): TypedUpdate | undefined {
        return this.updates?.find((u) => u.id === id || u.subItems?.find((su) => su.id === id));
    }

    getUpdatesForType(updateType: SpinachUpdateType): TypedUpdate[] {
        return this.updates.filter((update) => update.updateType === updateType);
    }

    getCustomUpdatesForList(customListId: string): TypedUpdate[] {
        return this.updates
            .filter((update) => update.updateType === SpinachUpdateType.Custom)
            .filter((update) => {
                if (
                    update.goalMetadata?.status === GoalStatus.Complete &&
                    customListId === FINISHED_ITEMS_CUSTOM_LIST_ID
                ) {
                    return true;
                }
                if (
                    update.goalMetadata?.status === GoalStatus.InProgress &&
                    customListId === PRESENTING_WORKING_ITEMS_CUSTOM_LIST_ID
                ) {
                    return true;
                }
                if (update.customListId === customListId) {
                    return true;
                }
                return false;
            });
    }

    hasUpdatesForType(updateType: SpinachUpdateType): boolean {
        return this.getUpdatesForType(updateType).length > 0;
    }

    private omitEmpty(updates: TypedUpdate[]): TypedUpdate[] {
        return updates.filter((u) => u.text !== '');
    }

    toJSON(): StandUpUpdate {
        return {
            id: this.id,
            spinachUserId: this.spinachUserId,
            updates: this.updates,
        };
    }
}
