import { CircularProgress, TextField } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { createFilterOptions } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import React from 'react';
import styled from 'styled-components';

import { INTERCOM_SUPPORT_URL } from '@spinach-shared/constants';
import {
    ClientEventType,
    ClientSocketEvent,
    SendChannelSelectionPayload,
    SendScheduleSummaryPayload,
    SlackTeamType,
    SpinachIntegration,
} from '@spinach-shared/types';
import { TimeUtils } from '@spinach-shared/utils';

import { deleteSlackUserDefaultChannel, deleteUserSlackSettings } from '../../../apis';
import { deleteSlackSeriesSettings } from '../../../apis/deleteSlackSeriesSettings';
import { fetchSlackChannels } from '../../../apis/fetchSlackChannels';
import { postSlackChannel } from '../../../apis/postSlackChannel';
import { postSlackDefaultUserChannel } from '../../../apis/postSlackDefaultUserChannel';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalLiveSeries,
    useGlobalNullableStoredSeries,
    useInviteViaSlackEnablement,
} from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { useGlobalMeetingSocket } from '../../../hooks/useGlobalSocket';
import { useGlobalSlack } from '../../../hooks/useSlack';
import { BodyLarge, BodyRegular, ResponsiveModalTitle, lightTheme, responsiveness } from '../../../styles';
import { ListItemValue } from '../../../types';
import { URLUtil, createWebsocketPayload } from '../../../utils';
import { PrimaryButton, ScrollArea } from '../../stand-up';
import { OutlinedButton } from '../../stand-up/OutlinedButton';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BootstrapTooltip } from '../BootstrapTooltip';
import { DropDown } from '../DropDown';
import { Direction, SlidingColumn } from '../SlidingColumn';
import { SpinachAutocomplete } from '../SpinachAutocomplete';
import { SpinachModalContent } from '../SpinachModalContent';
import { Anchor, Column, Hairline, Row, Spacing } from '../framing';

const ResponsiveRow = styled(Row)`
    @media ${responsiveness.thinnerThanSM} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export function SlackIntegrationSettingsModal(): JSX.Element {
    const [direction, setDirection] = useState(Direction.Forward);
    const { setSubview } = useGlobalMeetingSettings();

    return (
        <SpinachModalContent
            onBack={() => {
                setDirection(Direction.Forward);
                setSubview(null);
            }}
            style={{
                maxWidth: '80%',
                padding: '20px',
                minHeight: 'unset',
                overflowY: 'unset',
            }}
        >
            <SlidingColumn centered direction={direction}>
                <SlackIntegrationSettings />
            </SlidingColumn>
        </SpinachModalContent>
    );
}

export function SlackIntegrationSettings({
    showSlackRemindersSection = true,
    isUserDefaultChannel = false,
    title = 'Slack Settings',
    channelSelectionHeader = <BodyLarge>Send Summaries to #channel</BodyLarge>,
}) {
    const [liveSeries] = useGlobalLiveSeries();
    const [socket] = useGlobalMeetingSocket();
    const [storedSeries, setStoredSeries] = useGlobalNullableStoredSeries();
    const { setSubview, openScheduleSettings } = useGlobalMeetingSettings();
    const [isLoadingDisconnect, setIsLoadingDisconnect] = useState(false);
    const isSendChannelSelectionEnabled = useInviteViaSlackEnablement();
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const {
        slackState: { shouldSendSummaryOnChannelConnect },
        setShouldSendSummaryOnChannelSelect,
    } = useGlobalSlack();

    const [isConfirmedToDelete, setIsConfirmedToDelete] = useState(false);

    const [fetchedSlackChannels, setFetchedSlackChannels] = useState<ListItemValue<string>[]>();

    const { setToastText } = useGlobalAiDashboard();

    const storedIntegration = storedSeries?.getIntegrationSettings(SpinachIntegration.Slack);
    const userSlackSettings = user.integrationSettings?.slackSettings;
    const storedChannel = isUserDefaultChannel
        ? {
              channelName: userSlackSettings?.defaultChannelName,
              channelId: userSlackSettings?.defaultChannelId,
          }
        : storedIntegration?.destinationSettings?.[0];

    const teamId = storedIntegration?.teamId ?? userSlackSettings?.teamId;
    const teamType = (storedIntegration?.teamType ?? userSlackSettings?.teamType) as SlackTeamType | undefined;

    useEffect(() => {
        async function fetchChannels() {
            setIsLoading(true);
            const channels = await fetchSlackChannels(teamId, teamType, user.spinachUserId);
            setFetchedSlackChannels(
                channels.map((c) => ({
                    code: c.id,
                    label: `#${c.name}`,
                }))
            );
            setIsLoading(false);
        }

        fetchChannels();
    }, [teamId, teamType, user.spinachUserId]);

    return (
        <Column>
            <Row centered>
                <ResponsiveModalTitle>
                    <b>{title}</b>
                </ResponsiveModalTitle>
            </Row>

            <Hairline />

            <Spacing factor={1 / 2} />

            <ScrollArea style={{ height: '45vh' }} sidePadding={10}>
                <Row>
                    <Column>
                        {channelSelectionHeader}
                        <Spacing factor={1 / 3} />

                        <SpinachAutocomplete
                            id="slack-channel-id-selection"
                            options={fetchedSlackChannels ?? []}
                            isLoading={isLoading}
                            getOptionLabel={(option: { code: string; label: string }) => {
                                return `#${option.label.replaceAll('#', '')}`;
                            }}
                            value={
                                storedChannel?.channelId && storedChannel?.channelName
                                    ? { code: storedChannel.channelId, label: storedChannel.channelName }
                                    : null
                            }
                            inputPlaceholder="Choose a channel"
                            filterOptions={(options, params) => {
                                const filter = createFilterOptions<{ code: string; label: string }>();
                                return filter(options, params);
                            }}
                            onChange={async (event, channel) => {
                                try {
                                    setIsLoading(true);

                                    if (!teamId || !teamType) {
                                        return;
                                    }

                                    const channelName = channel?.label.replace(/#/g, '');
                                    const channelId = channel?.code;
                                    if (!channelId || !channelName) {
                                        track(ClientEventType.ClearSlackChannelInSettingsClick, {
                                            isUserDefaultChannel,
                                        });
                                        if (isUserDefaultChannel) {
                                            const result = await deleteSlackUserDefaultChannel();

                                            if (result?.user) {
                                                setUser(result.user);
                                            }
                                        } else {
                                            const updatedStoredSeries = await deleteSlackSeriesSettings(
                                                storedSeries?.slug
                                            );

                                            if (updatedStoredSeries) {
                                                setStoredSeries(updatedStoredSeries);
                                            }
                                        }
                                    } else {
                                        track(ClientEventType.SelectSlackChannelInSettingsClick, {
                                            isUserDefaultChannel,
                                        });

                                        if (isUserDefaultChannel) {
                                            const result = await postSlackDefaultUserChannel(
                                                teamId,
                                                teamType,
                                                channelId,
                                                channelName
                                            );
                                            if (result?.user) {
                                                setUser(result.user);
                                            }
                                        } else {
                                            const updatedSeries = await postSlackChannel(
                                                teamId,
                                                teamType,
                                                channelId,
                                                channelName,
                                                storedSeries!.slug
                                            );

                                            if (updatedSeries) {
                                                setStoredSeries(updatedSeries);
                                            }
                                        }
                                    }

                                    if (shouldSendSummaryOnChannelConnect) {
                                        socket.emit(
                                            ClientSocketEvent.SendScheduleSummary,
                                            createWebsocketPayload<SendScheduleSummaryPayload>({
                                                seriesSlug: liveSeries.slug,
                                                spinachUserId: user.spinachUserId,
                                                meetingId: liveSeries.currentMeeting.id,
                                                timeZoneRegion: TimeUtils.getTimezoneRegion(),
                                            })
                                        );
                                        setShouldSendSummaryOnChannelSelect(false);
                                    }

                                    if (isSendChannelSelectionEnabled) {
                                        socket.emit(
                                            ClientSocketEvent.SendChannelSelection,
                                            createWebsocketPayload<SendChannelSelectionPayload>({
                                                seriesSlug: liveSeries.slug,
                                                spinachUserId: user.spinachUserId,
                                                meetingId: liveSeries.currentMeeting.id,
                                            })
                                        );
                                    }
                                } catch (e) {
                                    setToastText('Error updating in meeting setting');
                                } finally {
                                    setIsLoading(false);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Choose a channel"
                                    placeholder="Choose a channel"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <Spacing factor={1 / 3} />
                        <BodyRegular>
                            Don’t see your channel? To connect a private channel refer to{' '}
                            <Anchor
                                onClick={() => {
                                    track(ClientEventType.HowToConnectSlackPrivateChannelClick);
                                    URLUtil.openURL(`${INTERCOM_SUPPORT_URL}/articles/7967741-slack-integration`, {
                                        redirectThroughBackendWhenOnZoom: true,
                                    });
                                }}
                            >
                                these instructions
                            </Anchor>
                        </BodyRegular>
                    </Column>
                </Row>

                <Hairline />

                {showSlackRemindersSection ? (
                    <>
                        <Row>
                            <Column>
                                <BodyLarge>Send Slack Reminders</BodyLarge>
                                <BodyRegular>
                                    Reminders can be setup in your{' '}
                                    <Anchor
                                        onClick={() => {
                                            track(ClientEventType.OpenScheduleModalFromSlackSettings);
                                            openScheduleSettings();
                                        }}
                                    >
                                        Schedule
                                    </Anchor>{' '}
                                    settings.
                                </BodyRegular>
                            </Column>
                        </Row>
                        <Hairline />
                    </>
                ) : (
                    <> </>
                )}

                <Row>
                    <Column>
                        <BodyLarge>Disconnect Slack</BodyLarge>
                        <BodyRegular>After disconnecting, we'll no longer send summaries to Slack.</BodyRegular>
                        <Spacing factor={1 / 3} />
                        <OutlinedButton
                            title="Remove Slack Connection"
                            style={{
                                width: '250px',
                            }}
                            disabled={isConfirmedToDelete}
                            onClick={() => {
                                setIsConfirmedToDelete(true);
                                track(ClientEventType.RemoveSlackIntegrationClick);
                            }}
                        />
                        {isConfirmedToDelete ? (
                            <Row style={{ opacity: 1, transition: '500ms', marginTop: '10px' }}>
                                <PrimaryButton
                                    title={'Yes, Remove it'}
                                    isLoading={isLoadingDisconnect}
                                    color={lightTheme.status.negative}
                                    style={{ opacity: 1, transition: '500ms' }}
                                    onClick={async () => {
                                        setIsLoadingDisconnect(true);

                                        track(ClientEventType.RemoveSlackIntegrationConfirmClick);

                                        const updatedUser = await deleteUserSlackSettings();

                                        if (updatedUser) {
                                            setUser(updatedUser);
                                        }

                                        setIsLoadingDisconnect(false);
                                        setSubview(null);
                                    }}
                                />

                                <span style={{ display: 'flex', width: '10px' }} />

                                <OutlinedButton
                                    title={'Cancel'}
                                    disabled={isLoadingDisconnect}
                                    style={{ opacity: 1, transition: '500ms' }}
                                    onClick={() => {
                                        track(ClientEventType.CancelDeleteSlackIntegrationClick);
                                        setIsConfirmedToDelete(false);
                                    }}
                                />
                            </Row>
                        ) : null}
                    </Column>
                </Row>
                <Spacing />
            </ScrollArea>
        </Column>
    );
}
