import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { fetchSlackHuddleConfiguration } from '../../apis/slack-huddles/fetchSlackHuddleConfiguration';
import { atomSlackHuddleConfiguration } from '../../atoms/slack-huddles';
import { useGlobalAuthedUser } from '../useGlobalUser';

export const useGlobalSlackHuddleConfiguration = () => {
    return useRecoilState(atomSlackHuddleConfiguration);
};

export const useSlackHuddleConfiguration = () => {
    const [user] = useGlobalAuthedUser();
    const [globalSlackHuddleConfiguration, setGlobalSlackHuddleConfiguration] = useGlobalSlackHuddleConfiguration();
    useEffect(() => {
        const getSlackHuddleConfiguration = async () => {
            const huddleConfiguration = await fetchSlackHuddleConfiguration();
            if (huddleConfiguration) {
                setGlobalSlackHuddleConfiguration(huddleConfiguration);
            }
        };
        if (user.isAuthedForSlack && user.isSlackHuddleInAppConnectionEnabled) {
            getSlackHuddleConfiguration();
        }
    }, [user?.isAuthedForSlack, user?.isSlackHuddleInAppConnectionEnabled, setGlobalSlackHuddleConfiguration]);

    return [globalSlackHuddleConfiguration, setGlobalSlackHuddleConfiguration];
};
