import { GetStoredAllSeriesResponse, SpinachAPIPath, StoredSeries } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export type GetAllStoredSeriesOpts = {
    recurringOnly?: boolean;
};

export async function getAllStoredSeries({ recurringOnly }: GetAllStoredSeriesOpts = {}): Promise<StoredSeries[]> {
    try {
        const response = await getSpinachAPI<GetStoredAllSeriesResponse>(`${SpinachAPIPath.AllSeries}`, {
            params: {
                recurringOnly,
            },
        });
        return response?.storedSeriesList ?? [];
    } catch (err) {
        return [];
    }
}
