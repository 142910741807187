import * as FullStory from '@fullstory/browser';

import { isLocalStage } from '@spinach-shared/utils';

export function initFullstory() {
    const SCREEN_HEIGHT_OF_LINKEDIN_BOT = 960;
    const shouldNotRecord = window.innerHeight === SCREEN_HEIGHT_OF_LINKEDIN_BOT;

    if (shouldNotRecord) {
        return;
    }

    try {
        FullStory.init({
            orgId: '135H89',
            devMode: isLocalStage(),
            recordCrossDomainIFrames: true,
            recordOnlyThisIFrame: true, // will set _fs_is_outer_script
        });
    } catch (error) {}
}
