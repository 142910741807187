import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import moment from 'moment-timezone';
import { forwardRef } from 'react';
import styled from 'styled-components';

import { TimeUtils } from '@spinach-shared/utils';

import { BodyRegular, BodySubtitle, lightTheme } from '../../styles';
import { Column, Row } from './framing';

const SpinachArrowRight = styled(ArrowRight)<{ disabled?: boolean }>`
    margin-left: 30px;
    color: ${(props) => (props.disabled ? lightTheme.neutrals.midnight : lightTheme.primary.greenLight)};
    cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
`;

const SpinachArrowLeft = styled(ArrowLeft)<{ disabled?: boolean }>`
    margin-right: 30px;
    color: ${(props) => (props.disabled ? lightTheme.neutrals.midnight : lightTheme.primary.greenLight)};
    cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
`;

type SpinachDaySelectorProps = {
    isLeftDisabled?: boolean;
    isRightDisabled?: boolean;
    dayOffsetFromToday: number;
    onLeftClick: () => void;
    onRightClick: () => void;
};

export const SpinachDaySelector = forwardRef<HTMLDivElement, SpinachDaySelectorProps>(
    (
        { isLeftDisabled, isRightDisabled, dayOffsetFromToday, onLeftClick, onRightClick }: SpinachDaySelectorProps,
        ref
    ) => {
        return (
            <Column style={{ width: 'unset' }} ref={ref}>
                <Row style={{ justifyContent: 'center' }}>
                    <BodySubtitle style={{ visibility: dayOffsetFromToday === 0 ? 'visible' : 'hidden' }}>
                        Today
                    </BodySubtitle>
                </Row>
                <Row style={{ width: 'unset', justifyContent: 'center', alignItems: 'center' }}>
                    <SpinachArrowLeft disabled={isLeftDisabled} onClick={isLeftDisabled ? undefined : onLeftClick} />
                    <Column>
                        <BodyRegular style={{ fontWeight: 'bold' }}>
                            {moment(new Date())
                                .tz(TimeUtils.getTimezoneRegion())
                                .add(dayOffsetFromToday, 'days')
                                .format('MMM')}{' '}
                            {moment(new Date())
                                .tz(TimeUtils.getTimezoneRegion())
                                .add(dayOffsetFromToday, 'days')
                                .format('D')}
                        </BodyRegular>
                    </Column>
                    <SpinachArrowRight
                        disabled={isRightDisabled}
                        onClick={isRightDisabled ? undefined : onRightClick}
                    />
                </Row>
            </Column>
        );
    }
);
