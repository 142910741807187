import { ISpinachParticipantProps, Participant } from '@spinach-shared/types';

import { BaseParticipantProps } from './BaseParticipantProps';
import { SpinachParticipantProps } from './SpinachParticipantProps';

export class ParticipantPropsFactory {
    static createParticipant(participant: Participant): BaseParticipantProps {
        if (participant.spinachUserId && participant.connectionIds?.length) {
            // TODO: add type guarding
            return new SpinachParticipantProps(participant as ISpinachParticipantProps);
        } else {
            return new BaseParticipantProps(participant);
        }
    }
}
