import { Box } from '@material-ui/core';
import React from 'react';
import { v4 as uuid } from 'uuid';

import { ClientEventType } from '@spinach-shared/types';

import {
    deleteCustomVocabulary,
    deleteCustomVocabularyMisspellings,
    insertCustomVocabularyMisspellings,
} from '../../../../apis/customVocabulary';
import { useExperienceTracking, useGlobalAiDashboard } from '../../../../hooks';
import {
    DeletableTag,
    InlineTitle,
    MainWordContainer,
    NewTag,
    WordRow,
    toastErrorMessage,
} from './CustomVocabularySection';

export const RowOfMainAndMisspelling = ({
    id,
    text,
    misspellings,
    vocabs,
    setVocabs,
}: {
    id: string;
    text: string;
    misspellings: { text: string; id: string }[];
    vocabs: {
        id: string;
        text: string;
        misspellings: { id: string; text: string }[];
    }[];
    setVocabs: React.Dispatch<
        React.SetStateAction<
            {
                id: string;
                text: string;
                misspellings: { id: string; text: string }[];
            }[]
        >
    >;
}) => {
    const track = useExperienceTracking();
    const { setToastText } = useGlobalAiDashboard();
    // we use it inside a catch which can be triggered after the component is remounted
    const setErrorRef = React.useRef<() => void>();
    setErrorRef.current = () => {
        setToastText(toastErrorMessage);
    };

    const inputRefMisspellings = React.useRef<HTMLInputElement>(null);
    const [inputActiveMisspellings, setInputActiveMisspellings] = React.useState(false);
    const [pendingTextMisspellings, setPendingTextMisspellings] = React.useState('');

    const saveMisspelling = async () => {
        try {
            if (pendingTextMisspellings.trim().length === 0) {
                return;
            }
            const misspellingId = uuid();
            await insertCustomVocabularyMisspellings(id, misspellingId, pendingTextMisspellings);
            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Custom Vocab Add Misspelling',
                text: vocabs.find((vocab) => vocab.id === id)?.text,
                misspelling: pendingTextMisspellings.trim(),
            });
            setVocabs((vocabs) =>
                vocabs.map((vocab) => {
                    if (vocab.id === id) {
                        return {
                            ...vocab,
                            misspellings: [...vocab.misspellings, { id: misspellingId, text: pendingTextMisspellings }],
                        };
                    }
                    return vocab;
                })
            );
            setPendingTextMisspellings('');
            setInputActiveMisspellings(false);
        } catch (error) {
            setErrorRef.current?.();
        }
    };

    const onBlur = () => {
        saveMisspelling();
    };

    const editNewMisspeling = () => {
        setPendingTextMisspellings('');
        setInputActiveMisspellings(true);
        setTimeout(() => {
            inputRefMisspellings.current?.focus();
        }, 100);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            saveMisspelling();
            editNewMisspeling();
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            saveMisspelling();
        }
    };

    const deleteItem = async (id: string) => {
        try {
            track(ClientEventType.AIDashboardClick, {
                OnClick: 'Custom Vocab Remove',
                text: vocabs.find((vocab) => vocab.id === id)?.text,
            });
            setVocabs((vocabs) => vocabs.filter(({ id: idInQuestion }) => idInQuestion !== id));
            await deleteCustomVocabulary(id);
        } catch {
            setErrorRef.current?.();
        }
    };

    const deleteMisspelling = async (id: string) => {
        try {
            const parentId = vocabs.find((vocab) =>
                vocab.misspellings.some((misspelling) => misspelling.id === id)
            )?.id;
            if (!parentId) {
                throw new Error('Custom Vocab Parent id not found');
            }
            track(ClientEventType.AIDashboardActivity, {
                ClickedOn: 'Custom Vocab Remove Misspelling',
                text: vocabs.find((vocab) => vocab.id === id)?.text,
                misspelling: vocabs
                    .find((vocab) => vocab.id === id)
                    ?.misspellings.find((misspelling) => misspelling.id === id)?.text,
            });
            setVocabs((vocabs) =>
                vocabs.map((vocab) => ({
                    ...vocab,
                    misspellings: vocab.misspellings.filter((misspelling) => misspelling.id !== id),
                }))
            );
            await deleteCustomVocabularyMisspellings(parentId, id);
        } catch {
            setErrorRef.current?.();
        }
    };

    return (
        <WordRow key={id}>
            <MainWordContainer>
                <InlineTitle>Word, phrases, or name:</InlineTitle>
                <DeletableTag id={id} text={text} deleteItem={deleteItem} />
            </MainWordContainer>
            <Box display="flex" flexWrap="wrap">
                <InlineTitle>Misspellings:</InlineTitle>
                {misspellings.map(({ text, id }) => (
                    <DeletableTag key={id} id={id} text={text} deleteItem={deleteMisspelling} />
                ))}
                {misspellings.length > 20 ? (
                    <></>
                ) : (
                    <NewTag
                        inputRef={inputRefMisspellings}
                        onBlur={onBlur}
                        handleKeyDown={handleKeyDown}
                        pendingText={pendingTextMisspellings}
                        setPendingText={setPendingTextMisspellings}
                        inputActive={inputActiveMisspellings}
                        editNew={editNewMisspeling}
                        placeholder="Add misspelling"
                    />
                )}
            </Box>
        </WordRow>
    );
};
