import { AvatarDialogueState, AvatarSharedState } from '@spinach-shared/types';

export const DEFAULT_AVATAR_STATE: AvatarSharedState = {
    botId: '',
    seriesId: '',
    meetingId: '',
    avatarTasks: [],
    dialogueState: AvatarDialogueState.IdleWithSuggestions,
    transcriptLines: [],
    liveNotes: [],
    topicObjectives: [],
};
