import { v4 as uuidV4 } from 'uuid';

import { AgendaItem, AgendaItemSource, AgendaItemStatus, ReservedAgendaTitle } from '@spinach-shared/types';

//** @deprecated */
export function createParkingLotAgendaItem(): AgendaItem {
    const now = new Date().toISOString();

    return {
        id: uuidV4(),
        title: ReservedAgendaTitle.TeamTopics,
        totalTime: 0,
        talkTimes: [],
        isMutable: true,
        createdAt: now,
        updatedAt: now,
        isParticipantAgendaItem: false,
        source: AgendaItemSource.ParkingLot,
        status: AgendaItemStatus.Incomplete,
        standUpUpdate: {
            updates: [],
            id: uuidV4(),
        },
    };
}
