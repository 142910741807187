import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { ClientEventType, MinimalConfluenceSpace } from '@spinach-shared/types';

import { fetchConfluenceSpaces } from '../apis/confluence';
import { globalConfluenceSpaces } from '../atoms';
import { SetValue } from '../types';
import { useExperienceTracking } from './useExperienceTracking';
import { useGlobalAuthedUser } from './useGlobalUser';

export const useGlobalConfluenceSpaces = (): [
    globalConfluenceSpaces: MinimalConfluenceSpace[] | null,
    setGlobalConfluenceSpaces: SetValue<MinimalConfluenceSpace[] | null>
] => {
    return useRecoilState(globalConfluenceSpaces);
};

export const useConfluenceSpaces = () => {
    const [globalConfluenceSpaces, setGlobalConfluenceSpaces] = useGlobalConfluenceSpaces();
    const track = useExperienceTracking();

    const [user] = useGlobalAuthedUser();

    useEffect(() => {
        const getConfluenceSpaces = async () => {
            const confluenceSpaces = await fetchConfluenceSpaces();
            track(ClientEventType.AIDashboardActivity, {
                Action: 'Confluence Spaces fetched',
                From: 'Page Load',
                NumberOfConfluenceSpaces: confluenceSpaces.length,
            });
            setGlobalConfluenceSpaces(confluenceSpaces);
        };

        if (user.isAuthedForConfluence && user.isConfluenceSpaceSelectionEnabled) {
            getConfluenceSpaces();
        }
    }, [user.isAuthedForConfluence, user.isConfluenceSpaceSelectionEnabled, track, setGlobalConfluenceSpaces]);

    return [globalConfluenceSpaces];
};
