import { SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../getSpinachAPI';

export async function fetchRemoteControlDetails(): Promise<
    {
        botId: string;
        videoToken: string;
        seriesId: string;
        meetingTitle: string;
    }[]
> {
    try {
        const response = await getSpinachAPI<
            {
                botId: string;
                videoToken: string;
                seriesId: string;
                meetingTitle: string;
            }[]
        >(SpinachAPIPath.RemoteControl, {
            throwOnError: true,
        });
        if (!response) {
            throw new Error('No response from remote control API');
        }
        return response;
    } catch (error) {
        console.error('Error fetching in-call recording bot ID:', error);
        throw error;
    }
}
