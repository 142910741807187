import styled from 'styled-components';

import { HeaderThree } from '../../../../styles';

export const Label = styled(HeaderThree)`
    font-weight: 700;
    margin-right: 15px;
    font-family: Gilroy;
    width: 175px;
`;
