import { atom } from 'recoil';

import { MeetingSettingsSubview } from '@spinach-shared/types';

export type MeetingSettingsState = {
    isRootSettingsOpen: boolean;
    subview: null | MeetingSettingsSubview;
};

export const atomMeetingSettingsState = atom<MeetingSettingsState>({
    key: 'meetingSettings',
    default: {
        isRootSettingsOpen: false,
        subview: null,
    },
});
