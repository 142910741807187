import { Modal } from '@material-ui/core';
import React from 'react';

import { ReactComponent as NotionCopyTemplateExample } from '../../../../assets/integrations/notion-copy-template-example.svg';
import { BodyRegularOnboard, ResponsiveModalTitle } from '../../../../styles';
import { SetValue } from '../../../../types';
import { Column, Hairline, Row, Spacing, SpinachModalContent } from '../../../common';
import { PrimaryButton, ScrollArea } from '../../../stand-up';

export function NotionPreAuthInstructionalModal({
    isOpen,
    setIsOpen,
    onConfirm,
}: {
    isOpen: boolean;
    setIsOpen: SetValue<boolean>;
    onConfirm: () => void;
}): JSX.Element {
    function onClose() {
        setIsOpen(false);
    }

    return (
        <Modal open={isOpen} onClose={onClose}>
            <SpinachModalContent
                onClose={onClose}
                style={{
                    maxWidth: '80%',
                    padding: '20px',
                    minHeight: 'unset',
                    overflowY: 'hidden',
                }}
            >
                <NotionPreAuthInstructions onConfirm={onConfirm} />
            </SpinachModalContent>
        </Modal>
    );
}

function NotionPreAuthInstructions({ onConfirm }: { onConfirm: () => void }) {
    return (
        <Column>
            <Row centered>
                <ResponsiveModalTitle>
                    <b>Before you connect</b>
                </ResponsiveModalTitle>
            </Row>

            <Hairline />

            <Spacing factor={1 / 2} />

            <ScrollArea sidePadding={10}>
                <BodyRegularOnboard>
                    Please select <span style={{ fontWeight: 'bold' }}>Use a template provided by the developer</span>,
                    when allowing access for Notion.
                </BodyRegularOnboard>
                <Spacing factor={1 / 2} />
                <Row style={{ justifyContent: 'center' }}>
                    <NotionCopyTemplateExample />
                </Row>
                <Spacing factor={1 / 2} />
                <BodyRegularOnboard>
                    This will allow Spinach to create a private page to send meeting summaries to.
                </BodyRegularOnboard>
                <Spacing factor={1 / 3} />
            </ScrollArea>
            <Spacing factor={1 / 3} />
            <span style={{ display: 'flex', flexGrow: '1' }} />
            <Row style={{ justifyContent: 'center', paddingBottom: '8px' }}>
                <PrimaryButton style={{ maxWidth: '40%' }} title={'Connect to Notion'} onClick={onConfirm} />
            </Row>
        </Column>
    );
}
