import {
    SpinachIntegration,
    TICKET_SOURCE_MAP,
    TicketIntegrationWithSubProject,
    TicketSource,
} from '@spinach-shared/types';

export const isTicketSource = (source: unknown): source is TicketSource =>
    Boolean(source && typeof source === 'string' && Object.values(TICKET_SOURCE_MAP).includes(source as TicketSource));

export const isTicketSourceWithSubProject = (source: TicketSource): source is TicketIntegrationWithSubProject =>
    Boolean(
        source &&
            typeof source === 'string' &&
            [SpinachIntegration.Clickup, SpinachIntegration.Trello, SpinachIntegration.MondayDotCom].includes(
                source as TicketIntegrationWithSubProject
            )
    );
