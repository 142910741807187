import { MeetingStatus, Series } from '@spinach-shared/types';

import { BaseAgendaItemProps, BaseAgendaProps, BaseMeetingProps, BaseParticipantListProps } from '..';
import { BaseSeriesProps } from './BaseSeriesProps';

export class ActiveSeriesProps extends BaseSeriesProps {
    currentMeeting: BaseMeetingProps;

    constructor(series: Series) {
        super(series);
        this.currentMeeting = new BaseMeetingProps(series.currentMeeting);
    }

    get currentAgendaItem(): BaseAgendaItemProps | undefined {
        return this.currentMeeting.currentAgendaItem;
    }

    get meetingStatus(): MeetingStatus {
        return this.currentMeeting.status;
    }

    get participantsReady(): number {
        return this.currentMeeting.participantsReady;
    }

    get agenda(): BaseAgendaProps {
        return this.currentMeeting.agenda;
    }

    get participants(): BaseParticipantListProps {
        return this.currentMeeting.participants;
    }

    serialize(): Series {
        return JSON.parse(JSON.stringify(this));
    }
}
