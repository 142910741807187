import { v4 } from 'uuid';

import { CURRENT_ITEMS_CUSTOM_LIST_ID } from '@spinach-shared/constants';
import { Goal, SpinachUpdateType, TypedUpdate } from '@spinach-shared/types';

export function getTypedUpdateFromGoal(goal: Goal): TypedUpdate {
    const {
        status,
        sharedInSeries,
        isArchived,
        isDeleted,
        creatorId,
        kind,
        progress,
        id,
        createdAt,
        updatedAt,
        ...typedUpdate
    } = goal;
    return {
        ...typedUpdate,
        id: v4(),
        creatorId,
        updateType: SpinachUpdateType.Custom,
        customListId: CURRENT_ITEMS_CUSTOM_LIST_ID,
        goalMetadata: {
            goalId: id,
            status,
            sharedInSeries,
            isArchived,
            isDeleted,
            creatorId,
            kind,
            progress,
            createdAt,
            updatedAt,
        },
    };
}
