import styled from 'styled-components';

import { ClientEventType, SpinachInsightKind } from '@spinach-shared/types';

import { ReactComponent as InsightIcon } from '../../../assets/spinach-insight-icon.svg';
import { useFacilitationTracking, useGlobalFacilitation } from '../../../hooks';
import { lightTheme } from '../../../styles';
import { GenericSpinachInsight, useSpinachBlockerOnClick } from './SpinachInsight';
import { Header, Padding, ScrollView } from './common';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #f9f9f9;
    min-width: 350px;
`;

function getColorFromKind(kind: string): string {
    switch (kind) {
        case SpinachInsightKind.Blocker:
            return lightTheme.status.negative;
        case SpinachInsightKind.TimeCheck:
        case SpinachInsightKind.MeetingTip:
            return lightTheme.tertiary.orangeDark;
        case SpinachInsightKind.HeySpinach:
            return lightTheme.tertiary.green;
        default:
            return lightTheme.tertiary.green;
    }
}

export function InsightsSidebar(): JSX.Element {
    const [facilitation] = useGlobalFacilitation();
    const getOnClick = useSpinachBlockerOnClick();
    const track = useFacilitationTracking();

    return (
        <Container>
            <Header style={{ marginTop: '20px', marginLeft: '20px' }}>Insights</Header>
            <ScrollView sidePadding={0} stretch>
                <Padding style={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'start' }}>
                    {facilitation.inMeetingInsights.map((insight) => (
                        <GenericSpinachInsight
                            className="sliding-insight"
                            key={insight.id}
                            title={insight.title}
                            kind={insight.kind}
                            icon={<InsightIcon />}
                            highlightColor={getColorFromKind(insight.kind)}
                            context={insight.context}
                            onClick={
                                insight.kind === SpinachInsightKind.Blocker
                                    ? () => {
                                          track(ClientEventType.AIFacilitationClick, {
                                              ClickedOn: 'Add Blocker to Topics',
                                              Location: 'Insights Sidebar',
                                          });
                                          return getOnClick(insight.title, insight.context);
                                      }
                                    : undefined
                            }
                        />
                    ))}
                </Padding>
            </ScrollView>
        </Container>
    );
}
