import { Snackbar, SnackbarContent, makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import React, { ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { BodyLarge, lightTheme } from '../..';

const useStyles = makeStyles<
    DefaultTheme,
    {
        containerStyle?: React.CSSProperties;
        contentStyle?: CSSProperties;
        messageStyle?: CSSProperties;
    }
>({
    snackbarContent: (props) => ({
        backgroundColor: lightTheme.tertiary.greenDark,
        color: lightTheme.neutrals.white,
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'none',
        border: `0.5px solid ${lightTheme.neutrals.gray}`,
        width: '85%',
        flexGrow: 0,
        paddingTop: '0px',
        paddingBottom: '0px',
        ...props.contentStyle,
        '& .MuiSnackbarContent-message': {
            ...props.messageStyle,
            color: lightTheme.neutrals.white,
        },
    }),
});

type ToastProps = {
    isOpen: boolean;
    message: string | ReactNode;
    transitionComponent?: React.ComponentType<
        TransitionProps & {
            children?: React.ReactElement<any, any>;
        }
    >;
    id?: string;
    containerStyle?: React.CSSProperties;
    contentStyle?: CSSProperties;
    messageStyle?: CSSProperties;
    onClose?: () => void;
    icon?: ReactNode;
    duration?: number | null;
};

const ToastText = styled(BodyLarge)`
    color: ${(props) => props.theme.neutrals.white};
    font-size: 10px;
    text-align: left;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

const RowSpacer = styled.span`
    width: 10px;
`;

export function Notification({
    isOpen,
    onClose,
    icon,
    message,
    containerStyle,
    contentStyle,
    messageStyle,
    transitionComponent,
    id,
    duration = 3000,
}: ToastProps): JSX.Element {
    const styles = useStyles({ messageStyle, contentStyle, containerStyle });
    return (
        <Snackbar
            id={id}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={isOpen}
            onClose={() => {
                onClose?.();
            }}
            style={{
                bottom: '130px',
                width: '100%',
                position: 'absolute',
                transform: 'unset',
                right: 'unset',
                minWidth: '300px',
                maxWidth: '650px',
                pointerEvents: 'none',
                left: 'unset',
                ...containerStyle,
            }}
            transitionDuration={{
                enter: 500,
                exit: 195,
            }}
            TransitionComponent={transitionComponent}
            autoHideDuration={duration}
        >
            <SnackbarContent
                className={styles.snackbarContent}
                message={
                    <Row>
                        {icon ?? null}
                        <RowSpacer />
                        <ToastText style={{ ...messageStyle }}>{message}</ToastText>
                    </Row>
                }
            />
        </Snackbar>
    );
}
