import { Socket } from 'socket.io-client';

import { AgendaReorderRequest, ClientSocketEvent } from '@spinach-shared/types';

import { createWebsocketPayload } from './analytics';

export const createAgendaReorderEmitter =
    (seriesSlug: string, spinachUserId: string, socket: Socket) => (agendaItemId: string) => (moveToIndex: number) => {
        const payload = createWebsocketPayload<AgendaReorderRequest>({
            seriesSlug,
            spinachUserId,
            agendaItemId,
            moveToIndex,
        });
        socket.emit(ClientSocketEvent.AgendaReorder, payload);
    };
