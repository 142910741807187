import { CSSProperties, ReactNode, useState } from 'react';
import styled from 'styled-components';

import { CheckInFeatureDiscoveryKey, FeatureDiscoveryKey, TooltipFeatureDiscoveryKey } from '@spinach-shared/types';

import { ReactComponent as CloseSvg } from '../../../assets/hint-close-button.svg';
import { BodyRegular, ButtonSize, Hyperlink } from '../../../styles';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { Column, Row } from '../framing';

const Container = styled(Row)`
    border-radius: 6px;
    min-height: 32px;
    background: linear-gradient(91.6deg, #e9f5ff 10.55%, #cbe5fb 99.96%);
    padding: 11px;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const CheckInContainer = styled(Container)`
    min-height: 20px;
    width: calc(100% - 22px);
`;

export type FeatureCalloutClickProps = {
    onEngageClick: () => Promise<void>;
    onCloseClick: () => Promise<void>;
};

export type FeatureTooltipCalloutClickProps = {
    onEngageClick: () => Promise<void>;
};

export type UseCustomTooltipCalloutResults = FeatureTooltipCalloutClickProps & {
    isVisible: boolean;
    feature: TooltipFeatureDiscoveryKey;
};

export type UseCustomCalloutResult = FeatureCalloutClickProps & {
    isVisible: boolean;
    feature: FeatureDiscoveryKey;
};

export type UseCheckInCustomCalloutResult = FeatureCalloutClickProps & {
    isVisible: boolean;
    feature: CheckInFeatureDiscoveryKey;
};

export type FeatureCalloutProps = FeatureCalloutClickProps & {
    icon: ReactNode;
    title: ReactNode;
    subtitle: ReactNode;
    ctaIcon: ReactNode;
    isCloseable?: boolean;
    containerStyle?: CSSProperties;
};

export type CheckInFeatureCalloutProps = FeatureCalloutClickProps & {
    icon: ReactNode;
    title: ReactNode;
    containerStyle?: CSSProperties;
};

const IconContainer = styled.div`
    width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
`;

const CheckInIconContainer = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
`;

const TitleContainer = styled(Column)`
    align-items: flex-start;
    margin-right: 10px;
    margin-left: 10px;
    text-align: left;
`;

const Title = styled(BodyRegular)`
    font-weight: bold;
`;

const CheckInCTA = styled(Hyperlink)`
    font-weight: 600;
    :hover {
        font-weight: 700;
    }
`;

const Subtitle = styled(BodyRegular)``;

const EngageButtonContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const EngageButton = styled.button`
    border-radius: 20px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border: none;
    background-color: white;
    display: flex;
    padding: 0;
`;

const CloseIconContainer = styled.div`
    display: flex;
    position: relative;
    width: 0px;
`;

const CloseButton = styled(CloseSvg)`
    position: absolute;
    top: -18px;
    left: -16px;
    cursor: pointer;
`;

export function FeatureCallout({
    icon,
    title,
    subtitle,
    ctaIcon,
    onEngageClick: onClick,
    onCloseClick: onClose,
    isCloseable = true,
    containerStyle,
}: FeatureCalloutProps): JSX.Element {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <Container
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={containerStyle}
        >
            <CloseIconContainer>
                {isHovered && isCloseable ? <CloseButton onClick={onClose} /> : null}
            </CloseIconContainer>
            <IconContainer>{icon}</IconContainer>
            <TitleContainer>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </TitleContainer>
            <EngageButtonContainer>
                <EngageButton onClick={() => onClick()}>{ctaIcon}</EngageButton>
            </EngageButtonContainer>
        </Container>
    );
}

export function CheckInFeatureCallout({
    icon,
    title,
    onEngageClick: onClick,
    onCloseClick: onClose,
    containerStyle,
}: CheckInFeatureCalloutProps): JSX.Element {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <CheckInContainer
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={containerStyle}
        >
            <CheckInIconContainer>{icon}</CheckInIconContainer>
            <TitleContainer>
                <Row>
                    <CheckInCTA onClick={onClick}>{title}</CheckInCTA>
                    <div style={{ flexGrow: 1 }} />
                    {isHovered ? (
                        <SecondaryButton
                            containerStyles={{ maxHeight: '20px' }}
                            title="Dismiss"
                            onClick={onClose}
                            size={ButtonSize.Small}
                            color={'rgba(0,0,0,0.35)'}
                        />
                    ) : null}
                </Row>
            </TitleContainer>
        </CheckInContainer>
    );
}
