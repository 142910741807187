import { Modal, TextField } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ClientEventType, StoredSeries } from '@spinach-shared/types';
import { StoredSpinachSeries, isDemoSeries, isEmailValid } from '@spinach-shared/utils';

import { getStoredSeries, patchSeries } from '../../../apis';
import { GlobalModal } from '../../../atoms';
import { ElementId } from '../../../constants';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalModalState,
    useGlobalNullableStoredSeries,
    useGlobalStoredSeries,
} from '../../../hooks';
import { useGlobalStoredSeriesList } from '../../../hooks/useGlobalStoredSeriesList';
import { BodyLarge, BodyRegular, HeaderThree } from '../../../styles';
import { ListItemValue } from '../../../types';
import { withAccessibleSubmitProps, withContentMasking } from '../../../utils';
import { useSpinachInputStyles } from '../../input';
import { ErrorBodySubtitle, ModalContent } from '../../series/common';
import { PrimaryButton, ScrollArea } from '../../stand-up';
import { ClientPath } from '../ClientPaths';
import { ItemList } from '../ItemList';
import { Column, Row, Spacing } from '../framing';

const Content = styled(ModalContent)`
    max-width: 450px;
`;

export function ManageAdditionalEditors({ autoFocusDelay = 10 }: { autoFocusDelay?: number }): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [storedSeries, setStoredSeries] = useGlobalStoredSeries();
    const registeredEmails = storedSeries.scribeMetadata?.additionalEditorEmails || [];
    const track = useExperienceTracking();
    const modalMeta = useGlobalModalState();
    const { storedSeriesListState, setStoredSeriesListState } = useGlobalStoredSeriesList();

    const [emailInput, setEmailInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const classes = useSpinachInputStyles({ value: emailInput });

    const emailsFromInput = emailInput
        .split(',')
        .map((email) => email.trim())
        .filter((email) => !!email);

    const seriesHasUserWithSameEmail = !!registeredEmails.find((email) => emailsFromInput.includes(email));

    const lengthError = emailsFromInput.length + registeredEmails.length > 50 ? 'You can add up to 50 emails' : '';

    const error = seriesHasUserWithSameEmail
        ? 'Email is already added'
        : emailsFromInput.some((newEmail) => !isEmailValid(newEmail)) && !!emailInput
        ? 'Invalid email'
        : '';

    function updateGlobalSeriesList(updatedSeries: StoredSeries) {
        const index = storedSeriesListState.storedSeriesList.findIndex((series) => series.id === updatedSeries.id);
        if (index !== -1) {
            const updatedList = [...storedSeriesListState.storedSeriesList];
            updatedList[index] = new StoredSpinachSeries(updatedSeries);
            setStoredSeriesListState({
                ...storedSeriesListState,
                storedSeriesList: updatedList,
            });
        }
    }

    const onRemoveEmailClick = async (email: string) => {
        setIsLoading(true);

        const seriesId = storedSeries.slug;
        const latestStoredSeries = await getStoredSeries(seriesId);

        if (!latestStoredSeries) {
            return;
        }

        const updatedEditorEmails = latestStoredSeries.metadata?.scribeMetadata?.additionalEditorEmails?.filter(
            (e) => e !== email
        );

        track(ClientEventType.AIDashboardClick, {
            RemovingEmail: email,
            Location: modalMeta.location,
            ClickedOn: 'Remove Additional Editor',
        });

        const updatedSeries = await patchSeries(seriesId, {
            metadata: {
                scribeMetadata: {
                    additionalEditorEmails: updatedEditorEmails,
                },
            },
        });

        if (updatedSeries) {
            setStoredSeries(updatedSeries);
            updateGlobalSeriesList(updatedSeries);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        setTimeout(() => {
            const inputElement = document.getElementById(ElementId.AddEditorEmailInput);
            if (inputElement) {
                inputElement.focus();
            }
        }, autoFocusDelay);
    }, []);

    const listItemValues: ListItemValue<string>[] = registeredEmails.map((email) => ({
        code: email,
        label: email,
        postContent: (
            <span
                style={{
                    cursor: 'pointer',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                }}
                {...withAccessibleSubmitProps(() => {
                    onRemoveEmailClick(email);
                })}
            >
                <HighlightOffIcon htmlColor="gray" />
            </span>
        ),
    }));

    const onSubmitAddEmail = async () => {
        const seriesId = storedSeries.slug;

        if (isDemoSeries(seriesId)) {
            return;
        }

        setIsLoading(true);

        const freshSeries = await getStoredSeries(storedSeries.slug);

        if (!freshSeries) {
            return;
        }

        const updatedEditorEmails = [
            ...(freshSeries.metadata?.scribeMetadata?.additionalEditorEmails || []),
            ...emailsFromInput,
        ];

        emailsFromInput.forEach((email) => {
            track(ClientEventType.AIDashboardClick, {
                AddingEmail: email,
                Location: modalMeta.location,
                ClickedOn: 'Add Additional Editor',
            });
        });

        const updatedSeries = await patchSeries(seriesId, {
            metadata: {
                scribeMetadata: {
                    additionalEditorEmails: updatedEditorEmails,
                },
            },
        });

        if (updatedSeries) {
            setStoredSeries(updatedSeries);
            updateGlobalSeriesList(updatedSeries);
        }

        setEmailInput('');
        setIsLoading(false);
    };

    return (
        <>
            <BodyRegular>
                Additional editors can edit meeting summaries. Add multiple emails by using a comma (,)
            </BodyRegular>

            <Spacing factor={1 / 2} />

            <Row style={{ flex: 'unset' }}>
                <TextField
                    id={ElementId.AddEditorEmailInput}
                    InputProps={{ classes: { root: classes.base } }}
                    {...withContentMasking(classes.root)}
                    fullWidth
                    placeholder="Add by email"
                    value={emailInput}
                    onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                            await onSubmitAddEmail();
                        }
                    }}
                    onChange={(e) => {
                        setEmailInput(e.target.value.toLowerCase());
                    }}
                />

                <PrimaryButton
                    title="Add"
                    isLoading={isLoading}
                    loadingText={''}
                    onClick={onSubmitAddEmail}
                    disabled={!!error || !!lengthError || !emailInput.trim()}
                />
            </Row>

            <ErrorBodySubtitle>{lengthError || error}</ErrorBodySubtitle>

            <Spacing factor={1 / 2} />

            <BodyLarge>Additional Editors</BodyLarge>

            <Spacing factor={1 / 2.5} />

            <ScrollArea style={{ height: '30vh' }} sidePadding={0}>
                <ItemList style={{ borderBottom: '1px solid lightgray' }} values={listItemValues} />
            </ScrollArea>
        </>
    );
}

export function ManageSeriesAdditionalEditorsModal({
    isOpen,
    onClose,
    series,
}: {
    isOpen: boolean;
    onClose: () => void;
    series: StoredSpinachSeries;
}): JSX.Element {
    const [, setStoredSeries] = useGlobalNullableStoredSeries();
    const [globalModal] = useGlobalModal();
    const modalMeta = useGlobalModalState();
    const track = useExperienceTracking();
    const location = useLocation();

    const onModalClose = async () => {
        track(ClientEventType.AIDashboardClick, {
            Location: modalMeta.location,
            ClickedOn: 'Manage Additional Editors',
        });
        onClose();
        if (location.pathname === ClientPath.AIHome) {
            setStoredSeries(null);
        }
    };

    useEffect(() => {
        async function fetchLatestStoredSeries() {
            const latestStoredSeries = await getStoredSeries(series.slug);
            if (latestStoredSeries && globalModal === GlobalModal.ManageSeriesAdditionalEditors) {
                setStoredSeries(latestStoredSeries);
            }
        }
        if (isOpen) {
            fetchLatestStoredSeries();
        }
    }, [isOpen]);

    if (isDemoSeries(series.slug)) {
        return <></>;
    }

    return (
        <Modal open={isOpen} onClose={onModalClose}>
            <Content id={ElementId.ManageAdditionalEditorsModalContent}>
                <Column>
                    <HeaderThree>Manage additional editors</HeaderThree>
                    <ManageAdditionalEditors />

                    <Spacing factor={1 / 3} />

                    <PrimaryButton title="Done" onClick={onModalClose} />
                </Column>
            </Content>
        </Modal>
    );
}

export function GlobalManageSeriesAdditionalEditorsModal(): JSX.Element {
    const [series] = useGlobalNullableStoredSeries();
    const [openModal, setOpenModal] = useGlobalModal();

    if (!series) {
        return <></>;
    }

    return (
        <ManageSeriesAdditionalEditorsModal
            isOpen={openModal === GlobalModal.ManageSeriesAdditionalEditors}
            onClose={() => {
                setOpenModal(null);
            }}
            series={series}
        />
    );
}
