import { useEffect } from 'react';

import { AgentCommandType, SayFunction, SendFunction } from '../../../types/agent';
import { usePrevious } from '../../usePrevious';
import { useGlobalVideoAgent } from '../useGlobalVideoAgent';
import { getTopicToAdd, useAddTopic } from './useAddTopic';
import { getAskCommand, useAsk } from './useAsk';
import { isCommandViewerCommand, useCommandViewer } from './useCommandViewer';
import { getDebugCommand, useDebugCommand } from './useDebugCommand';
import { isCommandPreviousContext, useDemoPreviousContext } from './useDemoPreviousContext';
import { getIntroCommand, useIntroCommand } from './useIntro';
import { getValidatedJumpToTopic, useJumpToTopicNumber } from './useJumpToTopicNumber';
import { isNextAgendaTopicCommand, useNextAgendaTopic } from './useNextAgendaTopic';
import { isPreviousTopicCommand, usePreviousTopic } from './usePreviousTopic';
import { isStartAgendaCommand, useStartAgenda } from './useStartAgenda';
import { isToggleRecordingCommand, useToggleRecording } from './useToggleRecording';
import { getVideoCommandParameter, useVideoCommand } from './useVideoCommand';

// TODO - start integrating this with voice and chat triggers
export function useVideoAgentTextCommandProcessing(say: SayFunction, send: SendFunction) {
    const { session } = useGlobalVideoAgent();

    const mostRecentMessage = session?.chatMessages[session.chatMessages.length - 1];
    const previousMessageLength = usePrevious(session?.chatMessages.length);
    const currentMessageLength = session?.chatMessages.length;

    const jumpToTopicNumber = useJumpToTopicNumber(say);
    const startAgenda = useStartAgenda(say);
    const nextTopic = useNextAgendaTopic(say);
    const previousTopic = usePreviousTopic(say);
    const addTopic = useAddTopic(say);
    const debugCommand = useDebugCommand(send);
    const toggleCommandView = useCommandViewer(say);
    const intro = useIntroCommand(send);
    const demoPreviousContext = useDemoPreviousContext(say);
    const toggleRecording = useToggleRecording();
    const ask = useAsk(say, send);
    const videoCommand = useVideoCommand(send);

    useEffect(() => {
        async function processNewChatMessage() {
            if (!mostRecentMessage) {
                return;
            }

            if (currentMessageLength === previousMessageLength) {
                return;
            }
            const validatedJumpTopicNumber = getValidatedJumpToTopic(mostRecentMessage.text);
            const shouldStartAgenda = isStartAgendaCommand(mostRecentMessage.text);
            const shouldCallNextTopic = isNextAgendaTopicCommand(mostRecentMessage.text);
            const shouldCallPreviousTopic = isPreviousTopicCommand(mostRecentMessage.text);
            const validatedTopicToAdd = getTopicToAdd(mostRecentMessage.text);
            const debugCommandType = getDebugCommand(mostRecentMessage.text);
            const introCommand = getIntroCommand(mostRecentMessage.text);
            const shouldToggleCommandViewer = isCommandViewerCommand(mostRecentMessage.text);
            const isDemoPreviousContextCommand = isCommandPreviousContext(mostRecentMessage.text);
            const toggleRecordingCommand = isToggleRecordingCommand(mostRecentMessage.text);
            const askCommand = getAskCommand(mostRecentMessage.text);
            const videoCommandParameter = getVideoCommandParameter(mostRecentMessage.text);

            if (validatedJumpTopicNumber !== -1) {
                jumpToTopicNumber(validatedJumpTopicNumber, AgentCommandType.ChatMessage);
            } else if (shouldStartAgenda) {
                startAgenda(AgentCommandType.ChatMessage);
            } else if (shouldCallNextTopic) {
                nextTopic(AgentCommandType.ChatMessage);
            } else if (shouldCallPreviousTopic) {
                previousTopic(AgentCommandType.ChatMessage);
            } else if (validatedTopicToAdd) {
                addTopic(validatedTopicToAdd, AgentCommandType.ChatMessage);
            } else if (debugCommandType) {
                debugCommand(debugCommandType);
            } else if (shouldToggleCommandViewer) {
                toggleCommandView(AgentCommandType.ChatMessage);
            } else if (introCommand) {
                intro();
            } else if (isDemoPreviousContextCommand) {
                demoPreviousContext(AgentCommandType.ChatMessage);
            } else if (toggleRecordingCommand) {
                toggleRecording(toggleRecordingCommand);
            } else if (askCommand) {
                ask(askCommand, AgentCommandType.ChatMessage);
            } else if (!!videoCommandParameter) {
                videoCommand(videoCommandParameter);
            }
        }

        processNewChatMessage();
    }, [
        addTopic,
        currentMessageLength,
        debugCommand,
        demoPreviousContext,
        jumpToTopicNumber,
        mostRecentMessage,
        nextTopic,
        previousMessageLength,
        previousTopic,
        startAgenda,
        toggleCommandView,
        intro,
        toggleRecording,
        ask,
        videoCommand,
    ]);
}
