import { useCallback } from 'react';

import { getCommandContents, getNewAgentTopic } from '@spinach-shared/models';
import { ClientEventType, PostVideoAgentChatMessageRequest } from '@spinach-shared/types';

import { AGENT_SAYINGS, patchVideoAgentSession, postVideoAgentChatMessage } from '../../../..';
import { postVideoAgentAskSpinach } from '../../../apis/video-agent/postVideoAgentAskSpinach';
import { AgentCommandType, SayFunction, SendFunction } from '../../../types/agent';
import { useActivityTracking } from '../../useExperienceTracking';
import { useGlobalVideoAgent } from '../useGlobalVideoAgent';

export function getAskCommand(message: string): string {
    const supportedCommandTriggers = ['/spinach ask ', '/ask ', 'ask'];

    const { remainingCommand: topicTextToAdd } = getCommandContents(message, supportedCommandTriggers);

    return topicTextToAdd;
}

export function useAsk(say: SayFunction, send: SendFunction) {
    const {
        state: { session },
        setSession,
    } = useGlobalVideoAgent();
    const trackActivity = useActivityTracking();

    const botId = session?.botId;
    return useCallback(
        async (input: string, triggerType = AgentCommandType.ChatMessage) => {
            if (!botId) {
                return;
            }
            try {
                send('ASK_SPINACH_TRIGGERED');
                trackActivity(ClientEventType.VideoAgentActivity, 'Ask Spinach', {
                    TriggeredBy: triggerType,
                    Question: input,
                    ...session?.analyticsPayload,
                });
                const response = await postVideoAgentAskSpinach(input);
                trackActivity(ClientEventType.VideoAgentActivity, 'Ask Spinach Response', {
                    TriggeredBy: triggerType,
                    Question: input,
                    ...session?.analyticsPayload,
                });
                send('ANSWER_READY');
                say(response.output);
            } catch (error) {
                trackActivity(ClientEventType.VideoAgentActivity, 'Ask Spinach Error', {
                    TriggeredBy: triggerType,
                    Question: input,
                    ...session?.analyticsPayload,
                });
                send('ASK_SPINACH_FAILED');
                postVideoAgentChatMessage({
                    message: 'Sorry, I could not find an answer to your question.',
                    botId: botId,
                });
            }
        },
        [say, botId]
    );
}
