import { sortBy } from 'lodash';

import {
    Agenda,
    AgendaItem,
    AgendaItemSource,
    AuthoredUpdate,
    GoalStatus,
    ISOString,
    MeditationItem,
    Sentiment,
    SpinachUpdateType,
    TypedUpdate,
    UUID,
} from '@spinach-shared/types';
import { createParkingLotAgendaItem } from '@spinach-shared/utils';

import {
    AgendaItemPropsFactory,
    BaseAgendaItemProps,
    MeditationAgendaItemProps,
    ParkingLotAgendaItemProps,
    YTBAgendaItemProps,
} from '..';

export type IBaseAgendaProps = {
    startedAt?: ISOString;
    items: (AgendaItem | BaseAgendaItemProps | MeditationItem)[];
};

export class BaseAgendaProps {
    startedAt?: ISOString;
    private _items: BaseAgendaItemProps[];
    private _parkingLotItem: ParkingLotAgendaItemProps;

    constructor({ startedAt, items }: IBaseAgendaProps) {
        this.startedAt = startedAt;

        const agendaItems = items.map((i) => {
            if (i instanceof ParkingLotAgendaItemProps) {
                return i;
            }
            return AgendaItemPropsFactory.createAgendaItem(i);
        });

        const existingParkingLotItem = agendaItems.find(
            (i: AgendaItem | BaseAgendaItemProps) => i.source === AgendaItemSource.ParkingLot
        );

        if (existingParkingLotItem instanceof BaseAgendaItemProps) {
            this._parkingLotItem = existingParkingLotItem;
        } else if (existingParkingLotItem) {
            this._parkingLotItem = new ParkingLotAgendaItemProps(existingParkingLotItem);
        } else {
            this._parkingLotItem = new ParkingLotAgendaItemProps(createParkingLotAgendaItem());
        }

        const participantList = agendaItems.filter(
            (item) =>
                item.isParticipantAgendaItem !== false &&
                ![AgendaItemSource.ParkingLot, AgendaItemSource.Meditation].includes(item.source)
        );

        const nonParticipantList = agendaItems.filter(
            (item) =>
                item.isParticipantAgendaItem === false &&
                ![AgendaItemSource.ParkingLot, AgendaItemSource.Meditation].includes(item.source)
        );

        const meditation = agendaItems.filter(
            (item) => item.isParticipantAgendaItem === false && item.source === AgendaItemSource.Meditation
        );

        this._items = [...meditation, ...participantList, this._parkingLotItem, ...nonParticipantList];
    }

    get lastItemIndex(): number {
        return this.items.length - 1;
    }

    set items(items: BaseAgendaItemProps[]) {
        this._items = items;
    }

    get items(): BaseAgendaItemProps[] {
        return this._items;
    }

    get participantItems(): YTBAgendaItemProps[] {
        return this.items.filter((i): i is YTBAgendaItemProps => !!i.isParticipantAgendaItem);
    }

    get topicItems(): BaseAgendaItemProps[] {
        return this.items.filter(
            (i) =>
                !i.isParticipantAgendaItem &&
                ![AgendaItemSource.ParkingLot, AgendaItemSource.Meditation].includes(i.source)
        );
    }

    get YTBItems(): YTBAgendaItemProps[] {
        return this._items.filter((i): i is YTBAgendaItemProps => i instanceof YTBAgendaItemProps);
    }

    get icebreakerUpdates(): TypedUpdate[] {
        return this.YTBItems.flatMap((u) => u.standUpUpdate.updates).filter(
            (u) => u.updateType === SpinachUpdateType.Icebreaker
        );
    }

    get parkingLotItem(): ParkingLotAgendaItemProps | undefined {
        return this.items.find((i) => i instanceof ParkingLotAgendaItemProps);
    }

    get YTBItemsWithParkingLotUpdates(): YTBAgendaItemProps[] {
        return this.YTBItems.filter((i) => i.standUpUpdate.hasParkingLotItems);
    }

    get YTBItemsWithTeamTopicUpdates(): YTBAgendaItemProps[] {
        return this.YTBItems.filter((i) => i.standUpUpdate.hasTeamTopicItems);
    }

    get parkingLotUpdates(): AuthoredUpdate[] {
        const accumulatedParkingLotItems: AuthoredUpdate[] = this.YTBItems.reduce<AuthoredUpdate[]>(
            (accumulator, item) => {
                const parkingLotItemsOfParticipant =
                    item.standUpUpdate.updates.filter((u) => u.updateType === SpinachUpdateType.ParkingLot) ?? [];
                const parkingLotItemsWithAuthor: AuthoredUpdate[] = parkingLotItemsOfParticipant.map((topic) => ({
                    ...topic,
                    author: item.title,
                }));
                return [...accumulator, ...parkingLotItemsWithAuthor];
            },
            []
        );

        return accumulatedParkingLotItems;
    }

    get topicUpdates(): AuthoredUpdate[] {
        const ytbUpdateIds = this.YTBItems.map((i) => i.id);
        const ytbUpdatesWithAuthor: (AuthoredUpdate | undefined)[] = ytbUpdateIds.map((id) => {
            const ytbItems = this.YTBItems.map((i) => i.standUpUpdate.updates)
                .flat()
                .filter((item) => item.updateType === SpinachUpdateType.Topic && item.id === id);
            if (ytbItems.length) {
                const mostRecentYTBItem = sortBy(ytbItems, (i) => new Date(i.updatedOn!)).reverse()[0];
                // The author should always be the first person who created the item
                const authoredItem = sortBy(ytbItems, (i) => new Date(i.createdOn!))[0];
                const author = this.YTBItems.find((i) =>
                    i.standUpUpdate.updates.some((u) => JSON.stringify(u) === JSON.stringify(authoredItem))
                )?.title;
                if (mostRecentYTBItem && author) {
                    return {
                        ...mostRecentYTBItem,
                        author,
                    };
                }
            }
        });
        return ytbUpdatesWithAuthor.filter((y): y is AuthoredUpdate => !!y);
    }

    get challengeUpdates(): TypedUpdate[] {
        return this.YTBItems.map((ytbItem) =>
            ytbItem.standUpUpdate.updates.filter((u) => u.updateType === SpinachUpdateType.Challenge)
        ).flat();
    }

    get blockerSentimentUpdates(): AuthoredUpdate[] {
        const blockers = this.YTBItems.filter((u) => u.standUpUpdate.hasSentimentBlockerItems);
        const blockerTypedUpdatesWithAuthor: AuthoredUpdate[] = blockers
            .map((b) =>
                b.standUpUpdate.updates
                    .filter((u) => (u.sentiment && u.sentiment === Sentiment.Bad) || u.sentiment === Sentiment.Ok)
                    .filter((u) => u.goalMetadata?.status !== GoalStatus.Unset)
                    .map((u) => ({ ...u, author: b.title }))
            )
            .flat();

        const sortedBlockerTypedUpdates = blockerTypedUpdatesWithAuthor.sort((u) =>
            u.sentiment === Sentiment.Bad ? -1 : 1
        );

        return sortedBlockerTypedUpdates;
    }

    get meditationItem(): MeditationAgendaItemProps | undefined {
        const [meditation] = this.items.filter(
            (i): i is MeditationAgendaItemProps => i.source === AgendaItemSource.Meditation
        );

        return meditation;
    }

    findParticipantsYTBAgendaItemProps(participantId: string, userId?: UUID): YTBAgendaItemProps | undefined {
        return this.YTBItems.find(
            (item) => item.id === participantId || (userId !== undefined && item.standUpUpdate.spinachUserId === userId)
        );
    }

    toJSON(): Agenda {
        return {
            startedAt: this.startedAt,
            items: this.items.map((i) => i.toJSON()),
        };
    }
}
