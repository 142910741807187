import { Box } from '@material-ui/core';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { ReactComponent as Ornament } from '../../assets/promo-container-ornament.svg';

const Container = styled.div`
    border-radius: 6px;
    border: 1px solid #c3e0d7;
    background: #f4fdfb;
    padding: 23px 23px;
    position: relative;
    min-height: 171px;
    box-sizing: border-box;
    flex-shrink: 0;
`;

export function PromoContainer({
    children,
    id,
    className,
}: {
    children?: ReactNode;
    id?: string;
    className?: string;
}): JSX.Element {
    return (
        <Container id={id} className={className}>
            <Box position="relative">{children}</Box>
        </Container>
    );
}
