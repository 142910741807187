import { atom } from 'recoil';

import { DetailedTicket } from '@spinach-shared/types';

export const recentAsanaIssuesAtom = atom<DetailedTicket[] | null>({
    key: 'recentAsanaIssuesAtom',
    default: null,
});

export const detailedAsanaIssuesAtom = atom<DetailedTicket[] | null>({
    key: 'detailedAsanaIssuesAtom',
    default: null,
});
