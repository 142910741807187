import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';

import { useExperienceTracking, useGlobalRouting } from '../../hooks';
import { BodyRegularOnboard, lightTheme } from '../../styles';

export const LoginWithSSO = () => {
    const track = useExperienceTracking();
    const { routeToVerify } = useGlobalRouting();
    return (
        <BodyRegularOnboard
            style={{ color: lightTheme.primary.greenLight, cursor: 'pointer' }}
            onClick={() => {
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: 'Login with SSO',
                });
                routeToVerify(
                    undefined,
                    new URLSearchParams({
                        [WebUrlQuery.SSO]: 'true',
                    })
                );
            }}
        >
            Sign in with SSO
        </BodyRegularOnboard>
    );
};
