import { Modal } from '@material-ui/core';

// no typsecript support

/* tslint:enable:no-var-requires */
import { Ticket } from '@spinach-shared/types';

import { HeaderThree } from '../../../styles';
import { SpinachModalContent } from '../../common';
import { JiraDetails } from './JiraPreviewContainer';

interface JiraPreviewContainerProps {
    issueData: Ticket;
    isOpen: boolean;
    onClose: () => void;
}

export const JiraDetailsModal = ({ issueData, isOpen, onClose }: JiraPreviewContainerProps) => {
    return (
        <Modal open={isOpen} onClose={onClose}>
            <SpinachModalContent
                style={{ borderRadius: '30px', boxShadow: '0px 0px 0px 8px rgba(0, 0, 0, .25' }}
                onClose={onClose}
            >
                <HeaderThree>Ticket details</HeaderThree>
                <JiraDetails issueData={issueData} />
            </SpinachModalContent>
        </Modal>
    );
};
