import { TICKET_SOURCE_MAP, TicketSource, TypedUpdate } from '@spinach-shared/types';

export function getTicketSource(update: TypedUpdate): TicketSource | undefined {
    if (update.asanaData) {
        return TICKET_SOURCE_MAP.Asana;
    }

    if (update.jiraData) {
        return TICKET_SOURCE_MAP.Jira;
    }

    if (update.ticketData) {
        return update.ticketData.source;
    }

    return undefined;
}
