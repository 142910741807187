interface AudioMonitor {
    isPlaying: boolean;
    hasEnded: boolean;
    monitorInterval: number | null;
}

const activeAudioElements: Set<HTMLAudioElement> = new Set();

export function stopAllAudio(): void {
    activeAudioElements.forEach((audio) => {
        audio.pause();
        audio.currentTime = 0;
    });
    activeAudioElements.clear();
}

export async function playAudioWithMonitoring(audioUrl: string, toSay: string, onEnd: () => void) {
    const audioElement: HTMLAudioElement = new Audio(audioUrl);
    activeAudioElements.add(audioElement);

    const monitor: AudioMonitor = {
        isPlaying: false,
        hasEnded: false,
        monitorInterval: null,
    };

    function monitorPlayback(): void {
        if (!monitor.isPlaying && audioElement.currentTime > 0) {
            monitor.isPlaying = true;
        }

        if (
            monitor.isPlaying &&
            (audioElement.ended || audioElement.paused || audioElement.currentTime === audioElement.duration)
        ) {
            if (!monitor.hasEnded) {
                sendAudioEndEvent();
            }
        }
    }

    function sendAudioEndEvent(): void {
        if (!monitor.hasEnded) {
            onEnd();
            monitor.hasEnded = true;
            if (monitor.monitorInterval !== null) {
                clearInterval(monitor.monitorInterval);
            }
            activeAudioElements.delete(audioElement);
        }
    }

    audioElement.onplay = (): void => {
        monitor.isPlaying = true;
        monitor.monitorInterval = window.setInterval(monitorPlayback, 200);
    };

    audioElement.onended = sendAudioEndEvent;

    await audioElement.play();
}
