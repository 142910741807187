import { JiraPickerOpts, JiraSearchOpts } from '@spinach-shared/types';

import { fetchJiraIssues, searchJiraIssues } from '../apis/jira';

export const useFetchJiraIssues = () => {
    return { fetchJiraIssues: (opts?: Partial<JiraPickerOpts>) => fetchJiraIssues(opts) };
};

export const useSearchJiraIssues = () => {
    return { searchJiraIssues: (opts?: JiraSearchOpts) => searchJiraIssues(opts) };
};
