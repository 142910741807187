import { useLocation, useMatch, useParams, useSearchParams } from 'react-router-dom';

import { WebUrlQuery } from '@spinach-shared/types';

import { ClientPath } from '../components';

/**
 *
 * @returns a seriesId extracted from the URL in some way
 * whether it's from the location path, a routing param,
 * a legacy secret search param, or a new meeting search param
 */
export function useLocationalSeriesId(): string {
    const [searchParams] = useSearchParams();
    const params = useParams();
    const match = useMatch({ path: `${ClientPath.Meeting}/${ClientPath.SeriesIdParam}`, end: false });

    const seriesId =
        match?.params.seriesId ??
        params.seriesId ??
        searchParams.get(WebUrlQuery.Secret) ??
        searchParams.get(WebUrlQuery.Meeting) ??
        '';

    return seriesId;
}

export function useWindowSearchParam(): string {
    const [searchParams] = useSearchParams();

    return searchParams.get(WebUrlQuery.Window) ?? '';
}

/**
 *
 * @returns props for whether, based on the URL, if the current experience is on a real series or demo series
 *
 * This is helpful for when you don't have access to a SpinachSeriesProps object, and when global series might
 * be undefined or null
 */
export function useSeriesReality(): { isDemoSeries: boolean; isRealSeries: boolean } {
    const location = useLocation();
    const result = { isDemoSeries: false, isRealSeries: false };

    if (location.pathname.includes(ClientPath.Demo)) {
        return { ...result, isDemoSeries: true };
    } else if (location.pathname.includes(ClientPath.Meeting)) {
        return { ...result, isRealSeries: true };
    } else {
        return result;
    }
}
