import { Box } from '@material-ui/core';
import { CSSProperties, ReactNode } from 'react';

import { DiagonalBox } from './DiagonalBox';

export function PreviousPersonsContext({
    title,
    items,
    opacity,
}: {
    title: string;
    items?: ReactNode[];
    opacity: number;
}): JSX.Element {
    if (!items?.length) {
        return <></>;
    }

    const agendaListStyles: CSSProperties = {
        marginLeft: '10px',
        width: 'calc(100% - 10px)',
        fontSize: '35px',
        color: 'white',
        marginTop: '10px',
        lineHeight: '1.3',
        opacity: 1,
        transition: 'opacity 0.5s',
    };

    return (
        <Box
            style={{
                width: '100%',
                transition: 'opacity 0.5s ease',
                opacity,
            }}
        >
            <Box
                style={{
                    textAlign: 'left',
                    fontSize: '35px',
                    transform: 'scale(1.1)',
                    color: 'white',
                    position: 'relative', // Added to position the boxes relatively
                }}
            >
                {/* <b>{title}</b> */}
                <DiagonalBox color={title.includes('ACTION') ? 'rgba(7, 78, 124)' : 'rgba(204,110,13)'} text={title} />
            </Box>
            <Box>
                <ul style={agendaListStyles}>
                    {items.map((item, i) => (
                        <li key={i}>
                            <div
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {item}
                            </div>
                        </li>
                    ))}
                </ul>
            </Box>
        </Box>
    );
}
