import { DateTimeMetadata, Day, HHMMTimeString, MeetingFormat, ORDERED_DAYS_MAP } from '@spinach-shared/types';
import { TimeUtils } from '@spinach-shared/utils';

import { DEFAULT_END_TIME, DEFAULT_START_TIME } from '../constants/time';

export const getDefaultDateTime = (
    standupDayTimesSelected: DateTimeMetadata[],
    lastTimestampSelected: HHMMTimeString,
    day: Day
) => {
    // when selecting a new day, let's copy the time over from the first
    // day in the list as that may be a better indication of what the
    // rest of the days' times should be.
    const firstDayTimeSelected: DateTimeMetadata = standupDayTimesSelected
        .filter((dateTime) => !!dateTime.meetingFormat)
        .sort((a, b) => ORDERED_DAYS_MAP[a.day] - ORDERED_DAYS_MAP[b.day])[0];

    if (lastTimestampSelected) {
        const minutesOfNewEndTime = TimeUtils.getMinutesFromHHMM(lastTimestampSelected) + 30;
        const hours = Math.floor(minutesOfNewEndTime / 60);
        const minutes = minutesOfNewEndTime % 60;
        const newEndTime = TimeUtils.getHHMMFromHoursMinutes(hours.toString(), minutes.toString());

        const updatedDateTime: DateTimeMetadata = {
            day,
            startTime: lastTimestampSelected,
            endTime: newEndTime,
            meetingFormat: MeetingFormat.Live,
            timezoneRegion: TimeUtils.getTimezoneRegion(),
            timezoneOffset: new Date().getTimezoneOffset(),
        };
        return updatedDateTime;
    } else if (firstDayTimeSelected) {
        const updatedDateTime: DateTimeMetadata = {
            day,
            startTime: firstDayTimeSelected.startTime,
            endTime: firstDayTimeSelected.endTime,
            meetingFormat: MeetingFormat.Live,
            timezoneRegion: TimeUtils.getTimezoneRegion(),
            timezoneOffset: new Date().getTimezoneOffset(),
        };
        return updatedDateTime;
    } else {
        // otherwise, if they have no days selected and click one,
        // we default to 9 - 9:30
        const updatedDateTime: DateTimeMetadata = {
            day,
            startTime: DEFAULT_START_TIME,
            endTime: DEFAULT_END_TIME,
            meetingFormat: MeetingFormat.Live,
            timezoneOffset: new Date().getTimezoneOffset(),
            timezoneRegion: TimeUtils.getTimezoneRegion(),
        };
        return updatedDateTime;
    }
};
