import { SlackHuddleClientConfiguration, SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '../';

export const createSlackHuddle = async (slackHuddleClientConfig: SlackHuddleClientConfiguration) => {
    const response = await postSpinachAPI<
        { huddleConfiguration: SlackHuddleClientConfiguration },
        SlackHuddleClientConfiguration
    >(SpinachAPIPath.SlackHuddleBot, slackHuddleClientConfig, { throwOnError: true });

    return response?.huddleConfiguration;
};
