import { Box } from '@material-ui/core';
import moment from 'moment';

import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking, useGlobalAdmin, useGlobalModal } from '../../../../../..';
import { GlobalModal } from '../../../../../atoms';
import { Column, Row, Spacing } from '../../../../common';
import SecondaryButton from '../../../../stand-up/SecondaryButton';

export function AdminSelectedHistoryInfo(): JSX.Element {
    const [, setGlobalModal] = useGlobalModal();
    const { state } = useGlobalAdmin();
    const { searchedHistory, searchedUser } = state;
    const track = useExperienceTracking();

    if (!searchedHistory) {
        return <></>;
    }

    return (
        <Column style={{ marginLeft: '70px', width: 'calc(100% - 70px)', marginTop: '15px' }}>
            <Row>
                <Box style={{ width: '200px' }}>
                    <b>Meeting</b>
                </Box>
                <Box>
                    <b>Date</b>
                </Box>
            </Row>
            <Row>
                <Box style={{ width: '200px' }}>{searchedHistory.meetingTitle}</Box>
                <Box>{moment(searchedHistory.meetingDate).utc().format('h:mmA MMMM Do YYYY [UTC]')}</Box>
                <Box width="200px" justifyContent={'center'} display={'flex'}>
                    <SecondaryButton
                        title="View"
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'View User Meeting Notes as Admin',
                                BotId: searchedHistory.id,
                                SelectedUserId: searchedUser?.spinachUserId,
                                MeetingTitle: searchedHistory.meetingTitle,
                            });
                            setGlobalModal(GlobalModal.ConfirmAccessMeetingNotesAsAdmin);
                        }}
                    />
                </Box>
                <Box width="200px" justifyContent={'center'} display={'flex'}>
                    <SecondaryButton
                        title="Delete"
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Delete User Meeting Notes as Admin',
                                BotId: searchedHistory.id,
                                SelectedUserId: searchedUser?.spinachUserId,
                                MeetingTitle: searchedHistory.meetingTitle,
                            });
                            setGlobalModal(GlobalModal.ConfirmDeleteMeetingAsAdmin);
                        }}
                    />
                </Box>
            </Row>
            <Spacing factor={1 / 2} />
        </Column>
    );
}
