import { ISOString, TimeInMillis, TypedUpdate, YTBArchiveJSON } from '@spinach-shared/types';
import { getFormattedDate } from '@spinach-shared/utils';

export class YTBArchiveProps {
    seriesSlug: string;
    meetingId: string;
    deliveryDate: ISOString;
    updates: TypedUpdate[];

    constructor(archive: YTBArchiveJSON) {
        this.seriesSlug = archive.seriesSlug;
        this.meetingId = archive.meetingId;
        this.deliveryDate = archive.deliveryDate;
        this.updates = archive.updates;
    }

    get formattedDeliveryDate(): string {
        return getFormattedDate(new Date(this.deliveryDate).toDateString());
    }

    get comparableTime(): TimeInMillis {
        const dateString = new Date(this.deliveryDate).toDateString();
        return new Date(dateString).getTime();
    }
}
