import { AddUsersToSeriesRequest, SpinachAPIPath, StoredSeries } from '@spinach-shared/types';

import { postSpinachAPI } from '..';

export async function postLegacySeriesUsersAssociation(
    seriesId: string,
    request: AddUsersToSeriesRequest
): Promise<StoredSeries | undefined> {
    const data = await postSpinachAPI<{ series?: StoredSeries }>(
        SpinachAPIPath.AddUsersToLegacySeries.replace(':seriesId', seriesId),
        request
    );

    return data?.series;
}
