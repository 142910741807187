import { Modal } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { REMINDER_PRIOR_TO_STANDUP_IN_MILLIS } from '@spinach-shared/constants';
import { ClientUser } from '@spinach-shared/models';
import {
    BaseIDRequest,
    ClientEventType,
    ClientSocketEvent,
    DateTimeMetadata,
    Day,
    FeatureToggle,
    MeetingFormat,
    ORDERED_DAYS,
    ReminderSettings,
    SendScheduleSummaryPayload,
    SeriesMetadata,
    SpinachIntegration,
    StoredSeries,
} from '@spinach-shared/types';
import { SeriesSchedule, TimeUtils } from '@spinach-shared/utils';

import { patchUser, postExperienceEvent } from '../../apis';
import { patchSeries } from '../../apis/patchSeries';
import { GlobalModal } from '../../atoms';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalLiveSeries,
    useGlobalModal,
    useGlobalNullableLiveSeries,
    useGlobalStoredSeries,
} from '../../hooks';
import { useGlobalMeetingSocket } from '../../hooks/useGlobalSocket';
import { lightTheme, responsiveness } from '../../styles';
import { createWebsocketPayload, isFeatureEnabled } from '../../utils';
import { ModalContent } from '../series/common';
import SecondaryButton from '../stand-up/SecondaryButton';
import { CloseButton } from './CloseButton';
import { InMeetingStandupTimeSelection } from './InMeetingStandupTimesSelection';
import { NoSlackSummaryModalContent } from './NoSlackSummaryModalContent';
import { ScheduleConfirmationContent } from './ScheduleConfirmationContent';
import { Row, Spacing } from './framing';
import { Titles } from '@spinach-clients/constants';

const ResponsiveModalContent = styled(ModalContent)`
    max-width: 450px;
    min-width: 450px;
    min-height: 400px;

    @media ${responsiveness.thinnerThanSM} {
        max-width: unset;
        min-width: unset;
        width: 90%;
    }
`;
function postStandupTimeEvent(
    standupDayTimesSelected: DateTimeMetadata[],
    user: ClientUser,
    series: StoredSeries,
    hasBeenCreated: boolean
) {
    postExperienceEvent({
        eventType: !hasBeenCreated
            ? ClientEventType.UserCreatedStandupSchedule
            : ClientEventType.UserEditedStandupSchedule,
        payload: {
            ...user.toUserIdentityPayload(),
            IsReminderEnabled: series.metadata?.reminderSettings?.isReminderEnabled,
            ReminderDuration: series.metadata?.reminderSettings?.durationBeforeMeetingInMilliseconds,
            NumberOfDaysSubmitted: standupDayTimesSelected.length,
            ...ORDERED_DAYS.reduce((acc, curr) => {
                acc[curr] = standupDayTimesSelected.find((dayTime) => dayTime.day === curr) ? true : false;
                return acc;
            }, {} as Record<Day, boolean>),
            ...ORDERED_DAYS.reduce((acc, curr) => {
                const standupDateTime: DateTimeMetadata | undefined = standupDayTimesSelected.find(
                    (standupDateTime) => standupDateTime.day === curr
                );
                return {
                    ...acc,
                    [`${curr}Timezone`]: standupDateTime ? standupDateTime.timezoneRegion : '',
                    [`${curr}Start`]: standupDateTime ? standupDateTime.startTime : '',
                    [`${curr}End`]: standupDateTime ? standupDateTime.endTime : '',
                    [`${curr}IsChangedForThisWeekOnly`]: standupDateTime
                        ? standupDateTime.isChangedForThisWeekOnly
                        : false,
                    [`${curr}MeetingFormat`]: standupDateTime ? standupDateTime.meetingFormat : '',
                    [`${curr}OneOffStartTime`]:
                        standupDateTime && standupDateTime.oneOffDateTimeMetadata
                            ? standupDateTime.oneOffDateTimeMetadata.startTime
                            : '',
                    [`${curr}OneOffEndTime`]:
                        standupDateTime && standupDateTime.oneOffDateTimeMetadata
                            ? standupDateTime.oneOffDateTimeMetadata.endTime
                            : '',
                    [`${curr}OneOffMeetingFormat`]:
                        standupDateTime && standupDateTime.oneOffDateTimeMetadata
                            ? standupDateTime.oneOffDateTimeMetadata.meetingFormat
                            : '',
                };
            }, {}),
            SeriesId: series.id,
            timezone: TimeUtils.getTimezoneRegion(),
        },
    });
}

const AsyncHeader = ({ onClose }: { onClose: (dateValue?: Date | null, meetingFormat?: MeetingFormat) => void }) => {
    const [arrowBackgroundColor, setArrowBackgroundColor] = useState<string | null>(null);
    return (
        <Row style={{ alignItems: 'center' }}>
            <CloseButton
                style={{
                    fontSize: '17px',
                    cursor: 'pointer',
                    backgroundColor: arrowBackgroundColor ?? 'unset',
                    marginRight: '5px',
                    left: '3px',
                    top: '9px',
                }}
                onMouseEnter={() => setArrowBackgroundColor(lightTheme.neutrals.grayLight)}
                onMouseLeave={() => setArrowBackgroundColor(null)}
                onClick={() => onClose()}
            />
        </Row>
    );
};

enum ScheduleModalView {
    ScheduleConfirmationContent = 'schedule-confirmation-content',
    ConfigureScheduleContent = 'configure-schedule-content',
    NoSlackConnectionContent = 'no-slack-summary-modal',
}

export function ScheduleModalContainer(): JSX.Element {
    const [liveSeries] = useGlobalNullableLiveSeries();

    if (!liveSeries) {
        return <></>;
    }

    return <ScheduleModal />;
}

export function ScheduleModal() {
    const [globalModal, setGlobalModal] = useGlobalModal();
    const [socket] = useGlobalMeetingSocket();
    const isOpen = globalModal === GlobalModal.Schedule;
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const [series, setSeries] = useGlobalStoredSeries();
    const [liveSeries] = useGlobalLiveSeries();
    const seriesSlug = liveSeries.slug;
    const [dateTimes, setDateTimes] = useState(liveSeries.schedule);
    const defaultReminderSettings = {
        isReminderEnabled: true,
        durationBeforeMeetingInMilliseconds: REMINDER_PRIOR_TO_STANDUP_IN_MILLIS,
    };
    const [reminderSettings, setReminderSettings] = useState<ReminderSettings>(
        liveSeries.metadata?.reminderSettings ?? defaultReminderSettings
    );
    const tourHasBeenTriggered = useRef(false);
    const numberOfTimesUserCanViewAoATour = isFeatureEnabled<number>(
        user.featureToggles,
        FeatureToggle.NumberOfTimesUserCanViewAoATour,
        0
    );

    const slackChannel = series.slackChannel;

    const [view, setView] = useState<ScheduleModalView>(ScheduleModalView.ConfigureScheduleContent);

    useEffect(() => {
        if (liveSeries?.metadata?.dateTimes) {
            setDateTimes(liveSeries.schedule);
        }
    }, [liveSeries?.metadata?.dateTimes]);
    useEffect(() => {
        setReminderSettings(liveSeries.metadata?.reminderSettings ?? defaultReminderSettings);
    }, [liveSeries?.metadata?.reminderSettings]);
    const [isLoading, setIsLoading] = useState(false);

    const slackIntegration = series.getIntegrationSettings(SpinachIntegration.Slack);

    const scheduleSetupModalText = !slackIntegration ? Titles.NoThanksIllRemindTeam : Titles.NoThanks;

    useEffect(() => {
        setView(ScheduleModalView.ConfigureScheduleContent);
    }, []);

    useEffect(() => {
        const showTour = async () => {
            await patchUser({
                metadata: {
                    numberOfTimesViewingAllOutAsyncTour: (user.metadata.numberOfTimesViewingAllOutAsyncTour || 0) + 1,
                },
            });
            window.Intercom?.('startTour', 364797);
            tourHasBeenTriggered.current = true;
            track(ClientEventType.DisplayAllOutAsyncBanner);
        };

        /**
         *  - Target users who have AoA enabled
         *  - Target series creator *to start*
         */
        if (user.spinachUserId === series?.createdBy) {
            // When to show the tour
            // If all four conditions are true

            // 1. The modal is open
            const isModalOpen = isOpen;

            // 2. the tour has not yet been triggered for this app load
            const userHasNotTriggeredTour = !tourHasBeenTriggered.current;

            // 3. The feature key is NOT set to 0 (which is the "off state")
            const isTourEnabld = numberOfTimesUserCanViewAoATour !== 0;

            // 4. The feature key is less than 0 (which is the infinitely many show state)
            const isTourInfinitelyViewable = numberOfTimesUserCanViewAoATour < 0;

            // 4.a OR the user's number of times viewing the tour is not yet set or the number of times viewing the tour is set, and it is less than the feature key value
            const userHasNotViewedTourTooManyTimes =
                user.metadata.numberOfTimesViewingAllOutAsyncTour === undefined ||
                (user.metadata.numberOfTimesViewingAllOutAsyncTour !== undefined &&
                    user.metadata.numberOfTimesViewingAllOutAsyncTour < numberOfTimesUserCanViewAoATour);
            if (
                isModalOpen &&
                userHasNotTriggeredTour &&
                isTourEnabld &&
                (isTourInfinitelyViewable || userHasNotViewedTourTooManyTimes)
            ) {
                showTour();
            }
        }
    }, [isOpen]);

    const onClose = () => {
        setGlobalModal(null);
    };

    const closeModal = () => {
        setView(ScheduleModalView.ConfigureScheduleContent);
        onClose();
    };

    const scheduleAgenda = () => {
        const payload = createWebsocketPayload<BaseIDRequest>({
            seriesSlug: liveSeries.slug,
            spinachUserId: user.spinachUserId,
            meetingId: liveSeries.currentMeeting.id,
        });
        socket.emit(ClientSocketEvent.SheduleAgenda, payload);
    };

    const onSubmit = async (
        schedule: SeriesSchedule,
        shouldSendSlackSummary: boolean = false,
        reminderSettings?: ReminderSettings
    ) => {
        const hasBeenCreated = !!series.schedule?.days.length;

        const isScheduleFromScratch =
            !hasBeenCreated && schedule.nonFilteredDays.length && view === ScheduleModalView.ConfigureScheduleContent;

        if (isScheduleFromScratch) {
            setDateTimes(schedule);
            setView(ScheduleModalView.ScheduleConfirmationContent);
            return;
        }

        setIsLoading(true);

        const metadata: SeriesMetadata = { dateTimes: schedule.nonFilteredDays.map((day) => day.toJSON()) };

        if (reminderSettings) {
            metadata.reminderSettings = reminderSettings;
        }
        const updatedSeries = await patchSeries(seriesSlug, { metadata });

        if (shouldSendSlackSummary) {
            socket.emit(
                ClientSocketEvent.SendScheduleSummary,
                createWebsocketPayload<SendScheduleSummaryPayload>({
                    seriesSlug,
                    spinachUserId: user.spinachUserId,
                    meetingId: liveSeries.currentMeeting.id,
                    timeZoneRegion: TimeUtils.getTimezoneRegion(),
                })
            );
        }

        const updatedSchedule = updatedSeries?.metadata?.dateTimes
            ? new SeriesSchedule(updatedSeries.metadata.dateTimes)
            : undefined;
        setDateTimes(updatedSchedule);
        postStandupTimeEvent(schedule.toJSON(), user, updatedSeries!, hasBeenCreated);
        if (updatedSeries) {
            setSeries(updatedSeries);
        }

        closeModal();
        scheduleAgenda();
        setIsLoading(false);
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                if (view === ScheduleModalView.ScheduleConfirmationContent && dateTimes?.days.length) {
                    onSubmit(dateTimes);
                } else {
                    setDateTimes(liveSeries.schedule);
                    closeModal();
                }
            }}
        >
            <ResponsiveModalContent>
                {view === ScheduleModalView.ConfigureScheduleContent && (
                    <AsyncHeader
                        onClose={() => {
                            setDateTimes(liveSeries.schedule);
                            closeModal();
                        }}
                    />
                )}
                {view === ScheduleModalView.ScheduleConfirmationContent ? (
                    <ScheduleConfirmationContent
                        onBackClick={() => setView(ScheduleModalView.ConfigureScheduleContent)}
                        onSubmit={onSubmit}
                        dateTimes={dateTimes}
                        isLoading={isLoading}
                    />
                ) : view === ScheduleModalView.ConfigureScheduleContent ? (
                    <InMeetingStandupTimeSelection
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                        dateTimes={dateTimes?.toJSON()}
                        reminderSettings={reminderSettings}
                    />
                ) : (
                    // Unsure if this is used anymore
                    <NoSlackSummaryModalContent
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                        dateTimes={dateTimes?.toJSON()}
                    />
                )}

                <Spacing factor={1 / 3} />

                {view === ScheduleModalView.ScheduleConfirmationContent && dateTimes?.days.length && !slackChannel ? (
                    <SecondaryButton
                        title={scheduleSetupModalText}
                        onClick={() => {
                            if (!slackIntegration) {
                                setView(ScheduleModalView.NoSlackConnectionContent);
                            } else {
                                track(ClientEventType.TeamDoesNotUseSlackClick, {
                                    SeriesId: series?.id,
                                    timezone: TimeUtils.getTimezoneRegion(),
                                });
                                onSubmit(dateTimes);
                            }
                        }}
                    />
                ) : view === ScheduleModalView.ScheduleConfirmationContent && slackChannel ? null : (
                    <SecondaryButton
                        title={Titles.Cancel}
                        onClick={() => {
                            setDateTimes(liveSeries.schedule);
                            closeModal();
                        }}
                    />
                )}
            </ResponsiveModalContent>
        </Modal>
    );
}
