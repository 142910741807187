import { useEffect } from 'react';
import styled from 'styled-components';

import { FacilitatedViewId } from '@spinach-shared/constants';

import { useGlobalFacilitation } from '../../../hooks';
import { Banner } from './Banner';
import { BriefingContent } from './BriefingContent';
import { DiscussionTopicContent } from './DiscussionTopicContent';
import { FinishFacilitation } from './FacilitationEndContent';
import { IcebreakerContent } from './IcebreakerContent';
import { GapAnalysisContent } from './RoundtableWrapUp';
import { UserUpdateContent } from './UserUpdateContent';
import { getNextIcebreakerIndex } from './common';

const Container = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    flex: 2.8;
    background-color: #fff;
`;

function useInitializeIcebreaker() {
    const [facilitation, setFacilitation] = useGlobalFacilitation();

    useEffect(() => {
        const { currentIndex, usedIndexes } = getNextIcebreakerIndex(facilitation.icebreaker);

        setFacilitation((facilitation) => ({
            ...facilitation,
            icebreaker: {
                ...facilitation.icebreaker,
                currentIndex,
                usedIndexes,
            },
        }));
    }, []);
}

export function Spotlight(): JSX.Element {
    const [facilitation] = useGlobalFacilitation();
    const { currentViewId, participants, discussionTopics } = facilitation;

    useInitializeIcebreaker();

    return (
        <Container>
            <Banner />

            {(() => {
                if (currentViewId === FacilitatedViewId.MeetingPrep) {
                    return <BriefingContent />;
                }

                if (currentViewId === FacilitatedViewId.Icebreaker) {
                    return <IcebreakerContent />;
                }

                if (participants.some((p) => p.viewId === currentViewId)) {
                    return <UserUpdateContent key={currentViewId} />;
                }

                if (currentViewId === FacilitatedViewId.GapAnalysis) {
                    return <GapAnalysisContent />;
                }

                if (discussionTopics.some((p) => p.viewId === currentViewId)) {
                    return <DiscussionTopicContent key={currentViewId} />;
                }

                if (currentViewId === FacilitatedViewId.Finale) {
                    return <FinishFacilitation />;
                }
            })()}
        </Container>
    );
}
