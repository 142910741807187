import { ArrowBack } from '@material-ui/icons';
import { useState } from 'react';

import { DateTimeMetadata } from '@spinach-shared/types';
import { SeriesSchedule, TimeUtils } from '@spinach-shared/utils';

import { BodyRegular, HeaderThree, lightTheme } from '../../styles';
import { PrimaryButton } from '../stand-up';
import { Column, Row, Spacing } from './framing';

export function NoSlackSummaryModalContent({
    onSubmit,
    dateTimes,
    isLoading,
}: {
    onSubmit: (dateTimes: SeriesSchedule, shouldSendSlackMessage: boolean) => void;
    dateTimes?: DateTimeMetadata[];
    isLoading: boolean;
}) {
    const [arrowBackgroundColor, setArrowBackgroundColor] = useState<string | null>(null);

    return dateTimes ? (
        <>
            <Row style={{ alignItems: 'center' }}>
                <ArrowBack
                    style={{
                        position: 'absolute',
                        cursor: 'pointer',
                        border: 'none',
                        left: '8px',
                        top: '20px',
                        fontSize: '17px',
                        backgroundColor: arrowBackgroundColor ?? 'unset',
                        marginRight: '5px',
                    }}
                    onMouseEnter={() => setArrowBackgroundColor(lightTheme.neutrals.grayLight)}
                    onMouseLeave={() => setArrowBackgroundColor(null)}
                    onClick={() => onSubmit(new SeriesSchedule(dateTimes), false)}
                />
            </Row>
            <Column>
                <HeaderThree>Summary</HeaderThree>
                <Spacing factor={1 / 2} />
                <BodyRegular>Here's a summary of your weekly schedule:</BodyRegular>
                <Spacing factor={1 / 2} />
                <Row
                    style={{
                        backgroundColor: '#FDDEB030',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '80%',
                    }}
                >
                    <Column>
                        <BodyRegular style={{ fontWeight: 'bold' }}>Day</BodyRegular>
                        {dateTimes.map((dateTime) => (
                            <BodyRegular style={{ paddingBottom: '5px' }} key={dateTime.day}>
                                {dateTime.day}
                            </BodyRegular>
                        ))}
                    </Column>
                    <Column>
                        <BodyRegular style={{ fontWeight: 'bold' }}>Format</BodyRegular>
                        {dateTimes.map(
                            (dateTime) =>
                                dateTime.meetingFormat && (
                                    <BodyRegular
                                        style={{ paddingBottom: '5px' }}
                                        key={`${dateTime.day}-${dateTime.meetingFormat}`}
                                    >
                                        {dateTime.meetingFormat.charAt(0).toUpperCase() +
                                            dateTime.meetingFormat.slice(1)}
                                    </BodyRegular>
                                )
                        )}
                    </Column>
                    <Column>
                        <BodyRegular style={{ fontWeight: 'bold' }}>Time</BodyRegular>
                        {dateTimes
                            .filter((dateTime) => !!dateTime.meetingFormat)
                            .map((dateTime) => {
                                const time = TimeUtils.getDateFromHHMM(dateTime.startTime);
                                const fullTimeText = time
                                    .toLocaleTimeString(undefined, { timeZoneName: 'short' })
                                    .split(' ')[0];
                                const amPmText = time
                                    .toLocaleTimeString(undefined, { timeZoneName: 'short' })
                                    .split(' ')[1]
                                    .toLowerCase();
                                const timeZoneValueText = time
                                    ?.toLocaleTimeString(undefined, { timeZoneName: 'short' })
                                    .split(' ')[2];
                                const timeValueText = fullTimeText?.split(':').slice(0, -1).join(':');

                                return (
                                    <BodyRegular
                                        style={{ paddingBottom: '5px' }}
                                        key={`${dateTime.day}-${timeValueText}-${amPmText}-${timeZoneValueText}`}
                                    >
                                        {timeValueText}
                                        {amPmText} {timeZoneValueText}
                                    </BodyRegular>
                                );
                            })}
                    </Column>
                </Row>
                <Spacing factor={1 / 2} />
                <Column style={{ alignItems: 'start', width: '80%' }}>
                    <Row>
                        <Column style={{ width: 'unset' }}>
                            <BodyRegular>
                                To make changes to your schedule or integrate Slack, just go to the settings panel.
                            </BodyRegular>
                        </Column>
                    </Row>
                </Column>
            </Column>
            <Spacing />
            <PrimaryButton
                id={'schedule-summary-no-slack-button'}
                title={'Ok'}
                onClick={() => {
                    onSubmit(new SeriesSchedule(dateTimes), false);
                }}
                isLoading={isLoading}
            />
        </>
    ) : (
        <></>
    );
}
