import { CSSProperties } from 'react';
import styled from 'styled-components';

import { IntegrationCode, StandUpStatus } from '@spinach-shared/types';

import ManGIF from '../../assets/demo/GlassesMan-clipped-gif.gif';
import WomanGIF from '../../assets/demo/GlassesWoman-clipped-gif.gif';
import ManStill from '../../assets/demo/man-still.png';
import SpinachLogo from '../../assets/demo/s-logo.svg';
import SpinachNameArrow from '../../assets/demo/spinach-logo-arrow.svg';
import WomanStill from '../../assets/demo/woman-still.png';
import ZoomLogo from '../../assets/demo/zoom-logo.svg';
import GoogleMeet from '../../assets/integrations/google-meet.png';
import Teams from '../../assets/integrations/microsoft-teams.png';
import {
    useGlobalAuthedUser,
    useGlobalDemoState,
    useGlobalLiveSeries,
    usePersonaDemo,
    useSeriesReality,
    useWindowSize,
} from '../../hooks';
import { responsiveness } from '../../styles';
import { getDemoBannerHeight } from '../../utils';
import { Column, Row } from '../common';
import { MacOSButtons } from '../demo';

const DemoVideoFrame = styled(Column)`
    background-color: #333333;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;
`;

const Box = styled.div<{ isPresenter: boolean }>`
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: ${(props) => (props.isPresenter ? '3px solid green' : `3px solid black`)};
    width: 48%;
    height: 48%;

    @media ${responsiveness.thinnerThan1300} {
        width: 90%;
        height: 30%;
    }
`;

const Portrait = styled.img`
    object-fit: cover;
`;

function VideoFrame({ name, isPresenter, src }: { name: string; isPresenter: boolean; src?: string }): JSX.Element {
    const { width, height } = useWindowSize();

    return (
        <Box isPresenter={isPresenter}>
            {src ? <Portrait src={src} style={{ width: '95%', height: '95%' }} /> : null}
            <b
                style={
                    src
                        ? {
                              position: 'absolute',
                              bottom: `${height / 100}px`,
                              left: `${width / 100}px`,
                              color: 'white',
                              lineHeight: 2,
                              backgroundColor: 'rgba(0,0,0,0.5)',
                              paddingLeft: '5px',
                              paddingRight: '5px',
                          }
                        : { color: 'white' }
                }
            >
                {name}
            </b>
        </Box>
    );
}

const MAX_WIDTH_VIDEO_IS_VISIBLE = 550;

const PortraitRow = styled(Row)`
    justify-content: space-around;
    height: 95%;
    flex-wrap: wrap;
    align-items: center;

    @media ${responsiveness.thinnerThan1300} {
        flex-direction: column;
    }
`;

const VIDEO_LOGO_HEIGHT = 50;
const SPINACH_LOGO_HEIGHT = 30;

const VideoToolLabel = styled.div`
    position: absolute;
    bottom: -${VIDEO_LOGO_HEIGHT}px;
    left: 0px;
`;

const SpinachLabel = styled.div`
    position: absolute;
    bottom: -${SPINACH_LOGO_HEIGHT + 20}px;
    right: -20px;
`;

function SpinachAnnotation(): JSX.Element {
    return (
        <SpinachLabel>
            <Row style={{ alignItems: 'center' }}>
                <img style={{ height: `${SPINACH_LOGO_HEIGHT}px` }} src={SpinachLogo} />
                <img style={{ height: `${SPINACH_LOGO_HEIGHT - 10}px` }} src={SpinachNameArrow} />
            </Row>
        </SpinachLabel>
    );
}

function VideoToolAnnotation(): JSX.Element {
    const [user] = useGlobalAuthedUser();

    const logoSrc =
        user.metadata.integrationsVideo === IntegrationCode.Zoom
            ? ZoomLogo
            : user.metadata.integrationsVideo === IntegrationCode.GoogleMeet
            ? GoogleMeet
            : user.metadata.integrationsVideo === IntegrationCode.MicrosoftTeams
            ? Teams
            : '';

    return (
        <VideoToolLabel>
            <Row style={{ alignItems: 'center' }}>
                <img style={{ height: '20px', transform: 'scaleX(-1)' }} src={SpinachNameArrow} />

                {logoSrc ? (
                    <img style={{ height: `${VIDEO_LOGO_HEIGHT}px` }} src={logoSrc} />
                ) : (
                    <b
                        style={{
                            color: 'white',
                            lineHeight: 2,
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            marginRight: '5px',
                            minWidth: '130px',
                        }}
                    >
                        {user.videoTool}
                    </b>
                )}
            </Row>
        </VideoToolLabel>
    );
}

export function DemoVideo(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { width, height } = useWindowSize();
    const [liveSeries] = useGlobalLiveSeries();
    const { isRealSeries } = useSeriesReality();
    const isPersonaDemoEnabled = usePersonaDemo();
    const { spinachWindowOverlay } = useGlobalDemoState();
    let extraVideoStyles: CSSProperties = {};
    const videoWidth = width / 1.1 - 650;

    if (width < 900 && width > 550) {
        extraVideoStyles = {
            width: '250px',
            marginRight: '10px',
            marginLeft: '10px',
        };
    }

    let videoHeight = height / 1.2 - getDemoBannerHeight();
    if (isPersonaDemoEnabled) {
        videoHeight = height / 1.05 - getDemoBannerHeight();
    }

    const shouldHideVideoDuringDemo =
        !isPersonaDemoEnabled && liveSeries.spinachParticipant.standUpStatus !== StandUpStatus.Ready;
    if (isRealSeries || !liveSeries || width < MAX_WIDTH_VIDEO_IS_VISIBLE || shouldHideVideoDuringDemo) {
        return <></>;
    }

    const username = user.isDemoing
        ? `${liveSeries.spinachParticipant.displayName} (You)`
        : liveSeries.spinachParticipant.displayName;

    const [maleBot, femaleBot] = liveSeries.participants.participants
        .filter((p) => p.metadata?.isBot)
        // TODO: better bot metadata for stuff like this
        .sort((a) => (a.displayName === 'Alina' ? 1 : -1));

    const agendaItem = liveSeries.currentAgendaItem;
    return (
        <DemoVideoFrame
            style={{
                width: videoWidth,
                height: videoHeight - 30,
                paddingTop: '30px',
                ...extraVideoStyles,
            }}
        >
            <MacOSButtons style={{ width: '100%' }} rowStyle={{ top: '-10px', left: '15px' }} />

            <Column style={{ height: '100%', justifyContent: 'space-around' }}>
                <PortraitRow>
                    {isPersonaDemoEnabled && spinachWindowOverlay ? (
                        <img style={{ height: '50px' }} src={ZoomLogo} />
                    ) : (
                        <>
                            <VideoFrame
                                src={agendaItem?.id === maleBot.spinachUserId ? ManGIF : ManStill}
                                name={maleBot.displayName}
                                isPresenter={agendaItem?.id === maleBot.spinachUserId}
                            />
                            <VideoFrame
                                src={
                                    agendaItem?.id === femaleBot.spinachUserId ||
                                    agendaItem?.isParticipantAgendaItem === false
                                        ? WomanGIF
                                        : WomanStill
                                }
                                name={femaleBot.displayName}
                                isPresenter={agendaItem?.id === femaleBot.spinachUserId}
                            />
                            <VideoFrame
                                name={username}
                                isPresenter={agendaItem?.id === liveSeries.spinachParticipant.spinachUserId}
                            />
                        </>
                    )}
                </PortraitRow>
            </Column>
            {!isPersonaDemoEnabled ? (
                <>
                    <VideoToolAnnotation />

                    <SpinachAnnotation />
                </>
            ) : null}
        </DemoVideoFrame>
    );
}
