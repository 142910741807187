import { CircularProgress } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { createFilterOptions } from '@material-ui/lab';
import { useState } from 'react';

import { ClientEventType, SlackUserSettings } from '@spinach-shared/types';

import { getUser } from '../../../../apis';
import { GlobalModal } from '../../../../atoms';
import { ElementId } from '../../../../constants';
import { useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser, useGlobalModal } from '../../../../hooks';
import { useGlobalSlack } from '../../../../hooks/useSlack';
import { lightTheme } from '../../../../styles';
import { SetValue } from '../../../../types';
import { ClientLogger } from '../../../../utils';
import { Row, SpinachAutocomplete } from '../../../common';

const ADD_CHANNEL_OPTION = { code: 'add-channel' as const, label: 'Don’t see your channel?' };

export const SlackChannelSelection = ({
    from,
    selectedChannel,
    setSelectedChannel,
    updateChannel,
}: {
    from: string;
    selectedChannel: { code: string; label: string; isPrivate?: boolean } | null;
    setSelectedChannel: SetValue<{ code: string; label: string; isPrivate?: boolean } | null>;
    updateChannel: (
        channel: { code: string; label: string; isPrivate?: boolean } | null,
        slackUserSettings: SlackUserSettings
    ) => Promise<void>;
}) => {
    const track = useExperienceTracking();

    const { fetchSlackChannels, isFetchingChannels } = useGlobalSlack();
    const { setToastText } = useGlobalAiDashboard();
    const [user, setUser] = useGlobalAuthedUser();
    const { slackState } = useGlobalSlack();
    const { slackChannels } = slackState;
    const [, setGlobalModal] = useGlobalModal();
    const [isLoading, setIsLoading] = useState(false);

    const channelOptions: { code: string; label: string; isPrivate?: boolean }[] = slackChannels;

    const [isHoveringRefresh, setIsHoveringRefresh] = useState(false);
    return (
        <>
            {isLoading ? (
                <CircularProgress
                    size={'30px'}
                    style={{ marginLeft: '10px', marginBottom: '15px', color: lightTheme.primary.orangeDark }}
                />
            ) : (
                <>
                    <SpinachAutocomplete
                        id={ElementId.SlackChannelSelection}
                        options={channelOptions}
                        isLoading={isLoading}
                        getOptionLabel={(option: { code: string; label: string; isPrivate?: boolean }) => {
                            if (option.code === 'add-channel' || !option) {
                                return option.label;
                            }

                            return `${option.isPrivate ? '🔒' : '#'}${option.label
                                .replaceAll('#', '')
                                .replaceAll('🔒', '')}`;
                        }}
                        value={selectedChannel}
                        inputPlaceholder="Pick a channel"
                        filterOptions={(options, params) => {
                            const filter = createFilterOptions<{ code: string; label: string; isPrivate?: boolean }>();
                            const filteredOptions = filter(options, params);
                            // always ensures "dont see your channel" is present, even if no options are found
                            return [...filteredOptions, ADD_CHANNEL_OPTION];
                        }}
                        onChange={async (event, channel) => {
                            try {
                                if (typeof channel === 'string') {
                                    return;
                                }
                                if (channel?.code === 'add-channel') {
                                    setGlobalModal(GlobalModal.AddPrivateChannelInstructions);
                                    return;
                                }
                                setIsLoading(true);
                                setSelectedChannel(channel);

                                let slackSettings = user.slackSettings;

                                if (!slackSettings) {
                                    const freshUserResponse = await getUser();
                                    if (freshUserResponse.user?.integrationSettings?.slackSettings) {
                                        slackSettings = freshUserResponse.user?.integrationSettings?.slackSettings;
                                    } else {
                                        return;
                                    }
                                }
                                updateChannel(channel, slackSettings);

                                const updatedUser = await getUser();

                                if (updatedUser?.user) {
                                    setUser(updatedUser.user);
                                }

                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: `Select Slack Channel`,
                                    From: from,
                                    IsChannelPrivate: channel?.isPrivate,
                                });
                            } finally {
                                setIsLoading(false);
                            }
                        }}
                    />

                    <Row
                        onMouseEnter={() => setIsHoveringRefresh(true)}
                        onMouseLeave={() => setIsHoveringRefresh(false)}
                        style={{
                            alignItems: 'center',
                            width: 'unset',
                            height: '30px',
                            justifyContent: 'start',
                        }}
                    >
                        {isFetchingChannels ? (
                            <CircularProgress size={'30px'} style={{ color: lightTheme.primary.orangeDark }} />
                        ) : (
                            <Refresh
                                style={{
                                    justifySelf: 'start',
                                    cursor: 'pointer',
                                    backgroundColor: isHoveringRefresh ? lightTheme.neutrals.grayLight : 'unset',
                                    borderRadius: '100%',
                                }}
                                onClick={async () => {
                                    try {
                                        await fetchSlackChannels(true);
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Refresh Slack Channel Selection',
                                            From: from,
                                        });
                                    } catch (e) {
                                        ClientLogger.error('Error refreshing slack channels', {
                                            error: e,
                                            from,
                                            spinachUserId: user.spinachUserId,
                                        });
                                        setToastText('Try again later');
                                    }
                                }}
                            />
                        )}
                    </Row>
                </>
            )}
        </>
    );
};
