// @TODO remove this import from shared-packages
import type { CreateCompletionRequest } from 'openai';

import { AgentTopicObjective, AvatarLiveNote, AvatarTask, AvatarTaskRequest, LiveTranscript } from './Avatar';
import { SpinachComponents } from './Components';
import { JiraIssueChangeLogField, MappedIssueChangeLogHistoryItem, MinimalTicket } from './Jira';
import { ClientPlatform } from './Platform';
import { MeetingActionItem } from './Scribe';
import {
    Goal,
    JoiningParticipant,
    MeetingFormat,
    Module,
    SeriesOutputOptions,
    StoredSeries,
    TypedUpdate,
    UUID,
    UserMood,
    UserSeriesMetadata,
    YTBHistoryJSON,
} from './Series';
import { SlackTeamType } from './Slack';
import { ISOString } from './Time';
import { UserIdentity, UserMetadata } from './User';

export interface BaseWebSocketPayload {
    platform?: ClientPlatform;
    seriesSlug: string;
    meetingId?: string;
    // TODO: mark as required after this has been deployed for some time
    isDemoMode?: boolean;
    appVersion: string;
}

export interface VideoAgentConnectingPayload extends BaseWebSocketPayload {
    botId: string;
    token: string;
}

export interface AvatarTaskSocketRequest extends BaseWebSocketPayload {
    task: AvatarTask;
}

export interface AvatarHeySpinachDetectedSocketRequest extends BaseWebSocketPayload {
    spinachUserId: string;
    timestamp: string;
}

export interface AvatarClassifyTaskSocketRequest extends BaseWebSocketPayload {
    taskRequest: AvatarTaskRequest;
}

export interface AvatarTranscriptChunkCollectedEvent extends BaseWebSocketPayload {
    lines: LiveTranscript[];
    meetingId: string;
    userId: string;
    botId: string;
    currentAgendaItemId?: string;
    skipGeneration?: boolean;
}
export interface ParticipantJoiningRequest extends BaseWebSocketPayload {
    participant: JoiningParticipant;
    meetingPlatform: ClientPlatform;
    modules: Module[];
    seriesMetadata: UserSeriesMetadata;
    metadata?: UserMetadata;
    // TODO: deploying 3/16/2022. After being in prod for some time, this can be always defined
    reconnecting: boolean;
    meetingId?: string;
    botId?: string;
}

export interface AgendaRestartingRequest extends AgendaItemBaseRequest {
    forceEnd?: boolean;
}

export interface AgendaItemBaseRequest extends BaseIDRequest {
    itemId: UUID;
}

export type GetYTBHistoryResponse = {
    history?: YTBHistoryJSON;
};

export type GetStoredSeriesResponse = {
    storedSeries: StoredSeries;
};

export type GetStoredAllSeriesResponse = {
    storedSeriesList: StoredSeries[];
};

export interface YTBHistoryUpdateRequest extends BaseWebSocketPayload {
    update: TypedUpdate;
    spinachUserId: UUID;
    seriesId: UUID;
}

export interface AvatarUpsertNoteRequest extends BaseIDRequest {
    note: AvatarLiveNote;
    botId: string;
    currentAgendaItemId: string;
    isNewNote: boolean;
}

export interface AvatarUpsertTopicObjective extends BaseIDRequest {
    updatedObjective: AgentTopicObjective;
    botId: string;
    currentAgendaItemId: string;
    isNew: boolean;
}

export interface YTBUpdateUpdatedRequest extends BaseIDRequest, UserIdentity {
    update: TypedUpdate;
    updates?: TypedUpdate[];
    participantId: string;
    moveToIndex?: number;
    platform: ClientPlatform;
}

export interface ParticipantMoodUpdatingRequest extends BaseIDRequest {
    userMood: Omit<UserMood, 'spinachUserId'>;
}

export interface UpdatingGoalsRequest extends BaseIDRequest {
    goal: Goal;
}

export interface UpdateSeriesComponentsRequest extends BaseIDRequest, UserIdentity {
    components: SpinachComponents[];
}

export type SendScheduleSummaryPayload = BaseIDRequest & {
    timeZoneRegion: string;
};

export type SendChannelSelectionPayload = BaseIDRequest;

export type DelayedStateMachinePayload = Record<string, any>;

export type FinishAsyncAgendaPayload = DelayedStateMachinePayload &
    BaseAgendaAsyncPayload & {
        meetingFormat: MeetingFormat;
        meetingId: string;
    };

export type SchedulePreMeetingNotificationPayload = {
    iCalUID: string;
    eventId: string;
    meetingTimeZone: string;
    meetingTitle: string;
    scribeEmail: string;
    kind: NotificationKind;
};

export enum NotificationKind {
    PreMeeting = 'pre-meeting',
}

export type SSOLoginAttemptPayload = {
    email: string;
};

export type SendPreMeetingNotificationPayload = SchedulePreMeetingNotificationPayload & { meetingStartTime: string };

export interface BaseIDRequest extends BaseWebSocketPayload {
    seriesSlug: string;
    spinachUserId: UUID;
}

export enum WholeTeamReactionCode {
    Question = 'question',
    Celebrate = 'celebrate',
    Time = 'time',
    Bye = 'bye',
}

export interface WholeTeamReactionRequest extends BaseIDRequest {
    reactionCode: WholeTeamReactionCode;
}

type BaseAgendaAsyncPayload = BaseIDRequest & { asyncEndsAt?: string };

export interface NewEmailSummarySubscribingRequest extends BaseIDRequest {
    outputOptions: SeriesOutputOptions[];
}

export interface MeetingInactiveRequest extends BaseIDRequest {
    // TODO: deploying 3/22/2022. After being in prod for some time, this can be always defined
    meetingId?: string;
}

export interface AgendaReorderRequest extends BaseIDRequest {
    agendaItemId: UUID;
    moveToIndex: number;
}

export interface YTBParticipantReadyRequest extends BaseWebSocketPayload {
    seriesSlug: string;
    spinachUserId: UUID;
}

export type ClientLoggingRequest = {
    message: string;
    metadata: any;
};

export interface UpdateSeriesNameRequest {
    seriesSlug: string;
    newSeriesName: string;
    spinachUserId: UUID;
}

export type EmailReferralRequest = {
    email: string;
    referrerName: string;
};

export type UpsertSlackChannelRequest = {
    seriesId?: string;
    channelName: string;
    channelId: string;
    teamId: string;
    teamType: SlackTeamType;
    isPrivate?: boolean;
};

export type JiraChangelogPayload = {
    fieldFilters?: JiraIssueChangeLogField[];
    jql: string;
};

export type JiraFacilitationChangelogResponse = Record<string, (MappedIssueChangeLogHistoryItem | MinimalTicket)[]>;

export type LegacyJiraChangelogPayload = {
    withTeam?: boolean;
    fieldFilters?: JiraIssueChangeLogField[];
    emails?: string[];
    userIds?: string[];
    fromDate?: ISOString;
    toDate?: ISOString;
    timezone?: string;
    withComments?: boolean;
    projectId?: string;
    projectName?: string;

    /** @NOTE internal value to be used with secret key only */
    email?: string;
};

export type ChangelogRequestPayload = LegacyJiraChangelogPayload & {
    gptInput?: Partial<CreateCompletionRequest>;
    runOpenAi?: boolean;
};

export type UpsertNotionPageRequest = {
    pageId: string;
    pageName: string;
};

export type EditSummaryActionItemRequest = {
    actionItem: Omit<MeetingActionItem, 'id'> & { id: string };
    botId: string;
};
