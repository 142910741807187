import { useDisabledKnowledgeBaseForFreeUsers, useProFeatures } from '../../../../../hooks';
import { BodyRegularOnboard, HeaderThree } from '../../../../../styles';
import { ProBadge, Row, Spacing } from '../../../../common';
import { IntegrationSettingsModalKind } from '../../types';
import { useHoverRow } from '../IntegrationSection';
import { ConfluenceIntegrationSection, GoogleDriveIntegrationSection } from '../KnowledgeBaseIntegrationSection';

export function SpinachAppKnowledgeBaseIntegrationSection({
    setModalKind,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
}): JSX.Element {
    const hoverRowProps = useHoverRow();
    const hasProFeatures = useProFeatures();
    const disabledKnowledgeBaseForFreeUsers = useDisabledKnowledgeBaseForFreeUsers();
    const locked = disabledKnowledgeBaseForFreeUsers && !hasProFeatures;

    return (
        <>
            <Row>
                <HeaderThree>Knowledge Base</HeaderThree>
                {disabledKnowledgeBaseForFreeUsers ? <ProBadge /> : <></>}
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Keep your summaries in a spot</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />

            <ConfluenceIntegrationSection
                locked={locked}
                isComingSoon={true}
                setModalMetadata={() => undefined}
                setModalKind={setModalKind}
                hoverRowProps={hoverRowProps}
            />
            <Spacing factor={1 / 2} />
            <GoogleDriveIntegrationSection
                locked={locked}
                isComingSoon={true}
                setModalKind={setModalKind}
                hoverRowProps={hoverRowProps}
            />
        </>
    );
}
