import { Modal } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { ClientEventType, DEMO_SANDBOX_SENDER_MAP, UTMSource, WebUrlQuery } from '@spinach-shared/types';
import { isDemoSeries } from '@spinach-shared/utils';

import DemoTransition from '../../assets/demo/demo-transition.png';
import ReadyForStandup from '../../assets/demo/ready-for-standup.png';
import ReadyToPlay from '../../assets/demo/ready-to-play.png';
import SpinachAndVideoTool from '../../assets/demo/spinach-and-video-tool.png';
import Demo90DayTransition from '../../assets/demo/transition-demo-90d.png';
import { ReactComponent as SLogo } from '../../assets/s-logo.svg';
import { DemoModal, GlobalModal } from '../../atoms';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalDemoState,
    useGlobalModal,
    useGlobalRouting,
    useGlobalStoredSeries,
} from '../../hooks';
import { BodyBig, BodyRegular, ResponsiveModalTitle, responsiveness } from '../../styles';
import { Column, LoadingSquares, Row, Spacing } from '../common';
import { ResponsiveModalContent } from '../series';
import { PrimaryButton } from '../stand-up';

const ModalTitle = styled(ResponsiveModalTitle)`
    font-weight: bold;
`;

const ModalContent = styled(ResponsiveModalContent)`
    overflow-y: unset;
    max-width: 650px;
    min-width: 600px;
    min-height: 500px;
    max-height: 600px;

    @media ${responsiveness.thinnerThanSM} {
        min-width: unset;
        width: 85%;
    }
`;

export function DemoModalContainer(): JSX.Element {
    const [storedSeries] = useGlobalStoredSeries();
    const { demoModal } = useGlobalDemoState();

    if (!isDemoSeries(storedSeries.slug)) {
        return <></>;
    }

    switch (demoModal) {
        case DemoModal.PrepareCheckIn:
            return <PrepareCheckInDemoModal />;
        case DemoModal.JoinStandup:
            return <JoinStandupDemoModal />;
        case DemoModal.TransitionToOnboarding:
            return <OnboardingTransitionDemoModal />;
        case DemoModal.TimeForStandup:
            return <TimeForStandupModal />;
        case DemoModal.JoiningStandup:
            return <JoiningStandupModal />;
        case DemoModal.EnteringPersonaDemoFromOnboarding:
            return <EnteringPersonaDemoFromOnboardingModal />;
        case null:
            return <></>;
    }
}

export function DemoContextModal({
    open,
    children,
    onClose,
    containerStyle,
}: {
    open: boolean;
    children: ReactNode;
    onClose?: () => void;
    containerStyle?: CSSProperties;
}): JSX.Element {
    return (
        <Modal
            onClose={onClose}
            open={open}
            BackdropProps={{
                style: {
                    backdropFilter: 'blur(3px)',
                },
            }}
        >
            <ModalContent>
                <Column style={containerStyle}>
                    <Row style={{ justifyContent: 'flex-start' }}>
                        <SLogo />
                    </Row>
                    {children}
                </Column>
            </ModalContent>
        </Modal>
    );
}

export function PrepareCheckInDemoModal(): JSX.Element {
    const { demoModal, setDemoModal } = useGlobalDemoState();
    const track = useExperienceTracking();
    const [params] = useSearchParams();
    const demoForName = params.get(WebUrlQuery.DemoSandboxRecipient);
    const demoFromKey = params.get(WebUrlQuery.DemoSandboxSender);
    const demoFromName = demoFromKey ? DEMO_SANDBOX_SENDER_MAP[demoFromKey] ?? '' : '';
    const utmSource = params.get(WebUrlQuery.UTMSource);
    const isProductHuntLaunch = utmSource === UTMSource.ProductHunt;

    const welcomeMessage = demoForName ? `Welcome ${demoForName}!` : 'Welcome!';
    const header = isProductHuntLaunch ? 'Welcome Product Hunt!' : `${welcomeMessage} Ready for better standups?`;

    let subtitle = "Spinach runs standup so you don't have to!";
    if (isProductHuntLaunch) {
        subtitle = 'A new daily standup experience.';
    } else if (demoFromName) {
        subtitle = `Really excited for you to try out our brand new sandbox experience. This should only take about 2 minutes to run through. Would love any feedback you have -${demoFromName}`;
    }

    return (
        <DemoContextModal open={demoModal === DemoModal.PrepareCheckIn}>
            <ModalTitle>{header}</ModalTitle>
            <Spacing factor={1 / 2} />
            <i>
                <BodyRegular>{subtitle}</BodyRegular>
            </i>
            <Spacing />

            <img src={ReadyToPlay} style={{ width: '100%' }} />

            <Spacing />

            <PrimaryButton
                title="Got it! Let's go"
                onClick={() => {
                    track(ClientEventType.ClickDemoContextModal, { Modal: 'Prepare Check-in' });
                    setDemoModal(null);
                }}
            />
        </DemoContextModal>
    );
}

export function JoinStandupDemoModal(): JSX.Element {
    const { demoModal, setDemoModal } = useGlobalDemoState();
    const track = useExperienceTracking();
    return (
        <DemoContextModal open={demoModal === DemoModal.JoinStandup}>
            <ModalTitle>You're ready for standup!</ModalTitle>
            <Spacing />
            <i>
                <BodyRegular>Join standup with your team, and Spinach facilitates.</BodyRegular>
            </i>
            <Spacing />

            <img src={ReadyForStandup} style={{ width: '100%' }} />

            <Spacing />

            <PrimaryButton
                title="Join a (totally simulated) standup!"
                onClick={() => {
                    track(ClientEventType.ClickDemoContextModal, { Modal: 'Join Standup' });

                    setDemoModal(null);
                }}
            />
        </DemoContextModal>
    );
}

export function OnboardingTransitionDemoModal(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { demoModal, setDemoModal } = useGlobalDemoState();
    const track = useExperienceTracking();
    const [, setGlobalModal] = useGlobalModal();
    const { routeToOnboarding } = useGlobalRouting();
    const [params] = useSearchParams();
    const isProductHuntLaunch = params.get(WebUrlQuery.UTMSource) === UTMSource.ProductHunt;

    const header = isProductHuntLaunch ? 'Try Spinach free for 90 days' : 'Try Spinach with your team';
    const subtitle = isProductHuntLaunch
        ? 'Special Product Hunt offer. (Sign up by Feb 24)'
        : 'No credit cards. No obligations.';

    return (
        <DemoContextModal
            open={demoModal === DemoModal.TransitionToOnboarding}
            onClose={() => {
                setDemoModal(null);
            }}
        >
            <ModalTitle>{header}</ModalTitle>
            <Spacing factor={1 / 2} />
            <BodyRegular>{subtitle}</BodyRegular>

            <Spacing />

            <img
                src={isProductHuntLaunch ? Demo90DayTransition : DemoTransition}
                style={{ maxHeight: '90%', maxWidth: '100%' }}
            />

            <Spacing />

            <PrimaryButton
                title="Let's go! Finish setting up"
                onClick={() => {
                    track(ClientEventType.ClickDemoContextModal, { Modal: 'Transition to onboarding' });

                    if (user.shouldAuthBeforeDemo) {
                        routeToOnboarding({ replace: true });
                    } else {
                        setGlobalModal(GlobalModal.VerifyEmail);
                    }

                    setDemoModal(null);
                }}
            />
        </DemoContextModal>
    );
}

export function TimeForStandupModal(): JSX.Element {
    const { demoModal, setDemoState, demoState } = useGlobalDemoState();

    useEffect(() => {
        if (demoModal === DemoModal.TimeForStandup) {
            const HIDE_AFTER_4_SECONDS = 4000;
            setTimeout(() => {
                setDemoState({
                    ...demoState,
                    demoModal: null,
                });
            }, HIDE_AFTER_4_SECONDS);
        }
    }, [demoModal]);

    return (
        <DemoContextModal open={demoModal === DemoModal.TimeForStandup} containerStyle={{ flexGrow: 1 }}>
            <Column style={{ height: '100%', flexGrow: 1, justifyContent: 'center', marginTop: '-50px' }}>
                <Column style={{ transform: 'scale(1.3)' }}>
                    <AccessTimeIcon style={{ fontSize: '60px' }} />
                    <Spacing factor={1 / 3} />
                    <ModalTitle>Time for Standup</ModalTitle>
                    <LoadingSquares />
                </Column>
            </Column>
        </DemoContextModal>
    );
}

export function JoiningStandupModal(): JSX.Element {
    const { demoModal, setDemoState, demoState } = useGlobalDemoState();

    useEffect(() => {
        if (demoModal === DemoModal.JoiningStandup) {
            const HIDE_AFTER_4_SECONDS = 4000;
            setTimeout(() => {
                setDemoState({
                    ...demoState,
                    demoModal: null,
                });
            }, HIDE_AFTER_4_SECONDS);
        }
    }, [demoModal]);

    return (
        <DemoContextModal open={demoModal === DemoModal.JoiningStandup}>
            <img src={SpinachAndVideoTool} style={{ maxHeight: '300px' }} />
            <Spacing factor={1 / 2} />
            <Column style={{ height: '100%', flexGrow: 1, justifyContent: 'center' }}>
                <ModalTitle>Joining simulated Standup</ModalTitle>
                <Spacing factor={1 / 2} />
                <LoadingSquares />
            </Column>
        </DemoContextModal>
    );
}

export function EnteringPersonaDemoFromOnboardingModal(): JSX.Element {
    const { demoModal, setDemoState, demoState } = useGlobalDemoState();

    useEffect(() => {
        if (demoModal === DemoModal.EnteringPersonaDemoFromOnboarding) {
            const HIDE_AFTER_4_SECONDS = 4000;
            setTimeout(() => {
                setDemoState({
                    ...demoState,
                    demoModal: null,
                });
            }, HIDE_AFTER_4_SECONDS);
        }
    }, [demoModal]);

    return (
        <DemoContextModal
            open={demoModal === DemoModal.EnteringPersonaDemoFromOnboarding}
            containerStyle={{ flexGrow: 1 }}
        >
            <Column style={{ height: '100%', flexGrow: 1, justifyContent: 'center' }}>
                <ModalTitle>Let's start a Practice Round</ModalTitle>
                <Spacing factor={1 / 2} />
                <BodyBig>You're about to enter a simulated standup meeting to show you how Spinach works</BodyBig>
                <Spacing factor={1 / 2} />
                <LoadingSquares />
            </Column>
        </DemoContextModal>
    );
}
