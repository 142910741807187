import styled from 'styled-components';

import { BodyRegular, HeaderThree, responsiveness } from '../../styles';
import { Column, Row, Spacing } from './framing';

const ResponsiveColumn = styled(Column)`
    align-items: center;

    @media ${responsiveness.thinnerThanXS} {
        max-width: 90%;
    }
`;

export const InMeetingStandupTimesSelectionHeader = ({
    showReminderMessage,
}: {
    showReminderMessage: boolean;
}): JSX.Element => (
    <ResponsiveColumn>
        <HeaderThree>Customize your standup</HeaderThree>
        <Spacing factor={1 / 3} />
        <Row style={{ justifyContent: 'center' }}>
            <Column style={{ width: 'unset' }}>
                {showReminderMessage ? (
                    <>
                        <Row style={{ width: 'unset' }}>
                            <BodyRegular>💡</BodyRegular>
                            <BodyRegular>Get a Slack reminder 15 minutes ahead of standup</BodyRegular>
                        </Row>
                        <Spacing factor={1 / 3} />
                    </>
                ) : null}

                <BodyRegular style={{ paddingLeft: '16px' }}>
                    Choose which days are live and which are async.
                </BodyRegular>
                <Spacing factor={1 / 3} />
                <BodyRegular style={{ justifyContent: 'start', paddingLeft: '16px' }}>
                    When does your team do standup?
                </BodyRegular>
            </Column>
        </Row>
    </ResponsiveColumn>
);
