import { StoredSpinachSeries } from '@spinach-shared/utils';

import { useWindowSize } from '../../../hooks';
import { Column } from '../../common';
import { SpinachAppIntegrationsSection } from './integrations/standup-app/SpinachAppIntegrationsSection';
import { StandupSection } from './standups/StandupSection';
import { StandupAppSummarySection } from './summaries/standup-app/StandupAppSummarySection';
import { ActiveSectionComponent } from './types';
import { DashboardSection } from '@spinach-shared/constants';

export function StandupAppActiveSection({
    activeSection,
    setModalKind,
}: ActiveSectionComponent & {
    setStoredSeriesList: (value: StoredSpinachSeries[]) => void;
}): JSX.Element {
    let component: JSX.Element;
    const { width } = useWindowSize();
    const isDesktopView = width > 800;
    switch (activeSection) {
        case DashboardSection.Meetings:
            component = <StandupSection />;
            break;
        case DashboardSection.Integrations:
            component = <SpinachAppIntegrationsSection setModalKind={setModalKind} />;
            break;
        case DashboardSection.Summaries:
            component = <StandupAppSummarySection />;
            break;
        default:
            component = <StandupSection />;
            break;
    }
    return (
        <Column style={{ height: '100%', width: isDesktopView ? 'calc(100% - 260px)' : '100%' }}>{component}</Column>
    );
}
