import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useState } from 'react';
import styled from 'styled-components';

import { SetValue } from '../..';
import { SeriesCardOptionsMenu } from './SeriesCardOptionsMenu';

const SeriesOptionsButtonContainer = styled.span<{ isHovered: boolean }>`
    height: 100%;
    z-index: -1;
    align-items: flex-start;
    width: 0px;
    background-color: white;
    border-radius: 40px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
`;

const SeriesOptionsButton = styled(MoreVertIcon)`
    z-index: 1000;
    margin-top: 5px;
    margin-right: -30px;
    color: gray;
    background-color: white;
    border-radius: 5px;

    &:hover {
        background-color: ${(props) => props.theme.neutrals.gray};
    }
`;

export function SeriesCardOptions({
    areOptionsOpen,
    setOptionsOpen,
    isHovered,
    onClickRemove,
    onClickRename,
    seriesId,
    hideCopyLink,
}: {
    seriesId: string;
    isHovered: boolean;
    areOptionsOpen: boolean;
    setOptionsOpen: SetValue<boolean>;
    onClickRemove: () => void;
    onClickRename: () => void;
    hideCopyLink: boolean;
}): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    return (
        <SeriesOptionsButtonContainer
            isHovered={isHovered}
            onClick={async (e) => {
                e.stopPropagation();
                setOptionsOpen(!areOptionsOpen);
                setAnchorEl(document.getElementById(`series-options-${seriesId}`));
            }}
        >
            <>
                <SeriesOptionsButton id={`series-options-${seriesId}`} />
                {!!areOptionsOpen ? (
                    <SeriesCardOptionsMenu
                        anchorEl={anchorEl}
                        isOpen={areOptionsOpen}
                        onClickRemove={onClickRemove}
                        onClickRename={onClickRename}
                        onClose={() => setOptionsOpen(false)}
                        seriesId={seriesId}
                        hideCopyLink={hideCopyLink}
                    />
                ) : null}
            </>
        </SeriesOptionsButtonContainer>
    );
}
