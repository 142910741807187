import {
    APIPathParam,
    ConfluenceUserSettings,
    IClientUser,
    JiraUserSettings,
    MinimalAtlassianGetAvailableResourceItem,
    SpinachAPIPath,
    SpinachIntegration,
} from '@spinach-shared/types';

import { patchSpinachAPI } from './patchSpinachAPI';

export async function patchAtlassianSite(
    atlassianSource: SpinachIntegration.Jira | SpinachIntegration.Confluence,
    updatedSelection: MinimalAtlassianGetAvailableResourceItem
): Promise<{ settings: JiraUserSettings | ConfluenceUserSettings; user: IClientUser }> {
    return await patchSpinachAPI(
        SpinachAPIPath.PatchAtlassianSite.replaceAll(APIPathParam.AtlassianSource, atlassianSource),
        updatedSelection
    );
}
