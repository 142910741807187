import { Backdrop, Fab, Tooltip, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EcoIcon from '@material-ui/icons/Eco';
import { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';
import styled, { css, keyframes } from 'styled-components';

import { MILLIS_IN_SECONDS } from '@spinach-shared/constants';
import {
    ClientEventType,
    ClientSocketEvent,
    NotificationCode,
    WholeTeamReactionCode,
    WholeTeamReactionRequest,
} from '@spinach-shared/types';

import CelebrateFaceSrc from '../../assets/celebrate-face.png';
import PeaceHandSrc from '../../assets/peace-hand.png';
import RaisedHangSrc from '../../assets/raise-hand.png';
import TimeCheckSrc from '../../assets/time-check.png';
import {
    useDiscussionReactionEnablement,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalLiveSeries,
    useGlobalNavDrawer,
    useLiveNotifications,
} from '../../hooks';
import { BodyHuge, BodyRegular, lightTheme } from '../../styles';
import { createWebsocketPayload } from '../../utils';
import { Column, Row } from '../common';
import { Notification } from './Notification';

const WHOLE_TEAM_REACTION_DURATION_MS = 3.5 * MILLIS_IN_SECONDS;

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    75% {
        opacity: 0.75;
    }
    87% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
`;

const fadeInAnimation = () => css`
    animation: ${fadeIn};
    animation-duration: ${WHOLE_TEAM_REACTION_DURATION_MS}ms;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: both;
`;

const WholeTeamReaction = styled.div`
    position: absolute;
    z-index: 100000;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff57;
    top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${fadeInAnimation()};
    pointer-events: none;
`;

const ReactionContent = styled(Column)`
    font-size: 80px;
    user-select: none;
    pointer-events: none;
`;

const useStyles = makeStyles((theme) => ({
    backdrop: {
        color: '#fff',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
}));

function SimpleBackdrop({ handleClose, isOpen }: { isOpen: boolean; handleClose: () => void }): JSX.Element {
    const classes = useStyles();

    return <Backdrop className={classes.backdrop} open={isOpen} onClick={handleClose}></Backdrop>;
}

const REACTION_CONTENT_MAP: Record<string, { label: string; src: string }> = {
    [WholeTeamReactionCode.Question]: { label: 'I have a question', src: RaisedHangSrc },
    [WholeTeamReactionCode.Celebrate]: { label: 'Woohoo!', src: CelebrateFaceSrc },
    [WholeTeamReactionCode.Time]: { label: 'Time check', src: TimeCheckSrc },
    [WholeTeamReactionCode.Bye]: { label: "Cheers, I'm out!", src: PeaceHandSrc },
};

export function DiscussionReaction({
    isDiscussionReactionVisible,
    triggeringSpinachUserId,
    reaction,
}: {
    isDiscussionReactionVisible: boolean;
    triggeringSpinachUserId: string | undefined;
    reaction: string;
}): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();

    if (!(isDiscussionReactionVisible && triggeringSpinachUserId && reaction)) {
        return <></>;
    }

    return (
        <WholeTeamReaction>
            <Column>
                <ReactionContent>
                    <img style={{ height: '80px', marginBottom: '10px' }} src={REACTION_CONTENT_MAP[reaction].src} />
                </ReactionContent>
                <Notification
                    isOpen={isDiscussionReactionVisible}
                    containerStyle={{
                        bottom: '-3px',
                        position: 'relative',
                        height: '0px',
                        width: 'unset',
                        minWidth: 'unset',
                        maxWidth: 'unset',
                    }}
                    contentStyle={{ width: '100%', justifyContent: 'center', minWidth: 'unset' }}
                    message={
                        <Column>
                            <BodyRegular style={{ color: 'white', textTransform: 'initial' }}>
                                <b>{liveSeries.participants.getParticipantName(triggeringSpinachUserId)}</b>:{' '}
                                {REACTION_CONTENT_MAP[reaction].label}
                            </BodyRegular>
                        </Column>
                    }
                />
            </Column>
        </WholeTeamReaction>
    );
}

export function useDiscussionReactions(socket: Socket): {
    isDiscussionReactionVisible: boolean;
    triggeringSpinachUserId: string | undefined;
    reaction: string;
} {
    const {
        isNotificationToastOpen,
        notificationMessage,
        setIsNotificationToastOpen,
        triggeringSpinachUserId,
        notificationCode,
    } = useLiveNotifications(socket, 'REACTION');

    useEffect(() => {
        let timeout: ReturnType<typeof window.setTimeout>;

        if (isNotificationToastOpen) {
            timeout = setTimeout(() => {
                setIsNotificationToastOpen(false);
            }, WHOLE_TEAM_REACTION_DURATION_MS);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isNotificationToastOpen, setIsNotificationToastOpen, notificationCode]);

    return {
        isDiscussionReactionVisible: isNotificationToastOpen && notificationCode === NotificationCode.WholeTeamReaction,
        triggeringSpinachUserId,
        reaction: notificationMessage,
    };
}

export function DiscussionReactionButtons({
    socket,
    seriesSlug,
    isDiscussionReactionVisible,
}: {
    seriesSlug: string;
    socket: Socket;
    isDiscussionReactionVisible: boolean;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useGlobalLiveSeries();
    const [areReactionsOpen, setAreReactionsOpen] = useState(false);
    const isFeatureEnabled = useDiscussionReactionEnablement();
    const { drawer } = useGlobalNavDrawer();
    const track = useExperienceTracking();

    const handleQuestionClick = () => {
        socket.emit(
            ClientSocketEvent.WholeTeamReaction,
            createWebsocketPayload<WholeTeamReactionRequest>({
                seriesSlug,
                meetingId: liveSeries.currentMeeting.id,
                spinachUserId: user.spinachUserId,
                reactionCode: WholeTeamReactionCode.Question,
            })
        );
        track(ClientEventType.DiscussionReaction, { Reaction: WholeTeamReactionCode.Question });
        setAreReactionsOpen(false);
    };

    const handleTimeClick = () => {
        socket.emit(
            ClientSocketEvent.WholeTeamReaction,
            createWebsocketPayload<WholeTeamReactionRequest>({
                seriesSlug,
                meetingId: liveSeries.currentMeeting.id,
                spinachUserId: user.spinachUserId,
                reactionCode: WholeTeamReactionCode.Time,
            })
        );
        track(ClientEventType.DiscussionReaction, { Reaction: WholeTeamReactionCode.Time });
        setAreReactionsOpen(false);
    };

    const handleCelebrateClick = () => {
        socket.emit(
            ClientSocketEvent.WholeTeamReaction,
            createWebsocketPayload<WholeTeamReactionRequest>({
                seriesSlug,
                meetingId: liveSeries.currentMeeting.id,
                spinachUserId: user.spinachUserId,
                reactionCode: WholeTeamReactionCode.Celebrate,
            })
        );
        track(ClientEventType.DiscussionReaction, { Reaction: WholeTeamReactionCode.Celebrate });
        setAreReactionsOpen(false);
    };

    const handleCheersClick = () => {
        socket.emit(
            ClientSocketEvent.WholeTeamReaction,
            createWebsocketPayload<WholeTeamReactionRequest>({
                seriesSlug,
                meetingId: liveSeries.currentMeeting.id,
                spinachUserId: user.spinachUserId,
                reactionCode: WholeTeamReactionCode.Bye,
            })
        );
        track(ClientEventType.DiscussionReaction, { Reaction: WholeTeamReactionCode.Bye });
        setAreReactionsOpen(false);
    };

    useEffect(() => {
        function handleKeydown(event: KeyboardEvent) {
            const isNotUsingMetaKey = !event.metaKey;
            const isNotTyping =
                (event.target as HTMLElement).tagName !== 'INPUT' &&
                (event.target as HTMLElement).tagName !== 'TEXTAREA';

            if (isNotTyping && !isDiscussionReactionVisible && isNotUsingMetaKey && !drawer) {
                if (event.key === 'r') {
                    event.preventDefault();
                    setAreReactionsOpen(!areReactionsOpen);
                }

                if (event.key === '1') {
                    event.preventDefault();
                    handleQuestionClick();
                }

                if (event.key === '2') {
                    event.preventDefault();
                    handleTimeClick();
                }

                if (event.key === '3') {
                    event.preventDefault();
                    handleCelebrateClick();
                }

                if (event.key === '4') {
                    event.preventDefault();
                    handleCheersClick();
                }
            }
        }
        if (isFeatureEnabled) {
            window.addEventListener('keydown', handleKeydown);
        }
        return () => {
            if (isFeatureEnabled) {
                window.removeEventListener('keydown', handleKeydown);
            }
        };
    }, [
        setAreReactionsOpen,
        areReactionsOpen,
        handleQuestionClick,
        isDiscussionReactionVisible,
        isFeatureEnabled,
        drawer,
    ]);

    if (!isFeatureEnabled) {
        return <></>;
    }

    return (
        <Row style={{ height: '0px', zIndex: 1000 }}>
            <Column style={{ position: 'absolute', left: '0px' }}>
                {areReactionsOpen ? (
                    <>
                        <SimpleBackdrop
                            isOpen={areReactionsOpen}
                            handleClose={() => {
                                if (!isDiscussionReactionVisible) {
                                    setAreReactionsOpen(false);
                                }
                            }}
                        />
                        <Tooltip title="Cheers, I'm out! (4)" arrow={true} placement="top">
                            <Fab
                                disabled={isDiscussionReactionVisible}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    backgroundColor: 'white',
                                    bottom: '265px',
                                    zIndex: 1000,
                                }}
                                aria-label="add"
                                onClick={() => {
                                    handleCheersClick();
                                }}
                            >
                                <BodyHuge>
                                    <img style={{ height: '24px', marginTop: '7px' }} src={PeaceHandSrc} />
                                </BodyHuge>
                            </Fab>
                        </Tooltip>

                        <Tooltip title="Celebrate (3)" arrow={true} placement="top">
                            <Fab
                                disabled={isDiscussionReactionVisible}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    backgroundColor: 'white',
                                    bottom: '200px',
                                    zIndex: 1000,
                                }}
                                aria-label="add"
                                onClick={() => {
                                    handleCelebrateClick();
                                }}
                            >
                                <BodyHuge>
                                    <img style={{ height: '24px', marginTop: '7px' }} src={CelebrateFaceSrc} />
                                </BodyHuge>
                            </Fab>
                        </Tooltip>

                        <Tooltip title="Time check (2)" arrow={true} placement="top">
                            <Fab
                                disabled={isDiscussionReactionVisible}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    backgroundColor: 'white',
                                    bottom: '135px',
                                    zIndex: 1000,
                                }}
                                aria-label="add"
                                onClick={() => {
                                    handleTimeClick();
                                }}
                            >
                                <BodyHuge>
                                    <img style={{ height: '24px', marginTop: '7px' }} src={TimeCheckSrc} />
                                </BodyHuge>
                            </Fab>
                        </Tooltip>

                        <Tooltip title="Ask a question (1)" arrow={true} placement="top">
                            <Fab
                                disabled={isDiscussionReactionVisible}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    backgroundColor: 'white',
                                    bottom: '65px',
                                    zIndex: 1000,
                                }}
                                aria-label="add"
                                onClick={() => {
                                    handleQuestionClick();
                                }}
                            >
                                <BodyHuge>
                                    <img style={{ height: '24px', marginTop: '7px' }} src={RaisedHangSrc} />
                                </BodyHuge>
                            </Fab>
                        </Tooltip>
                    </>
                ) : null}
                <Tooltip
                    title={areReactionsOpen ? 'Close reactions (R)' : 'React to the presenter (R)'}
                    arrow={true}
                    placement="top"
                >
                    <Fab
                        disabled={isDiscussionReactionVisible}
                        style={{
                            position: 'absolute',
                            right: '10px',
                            backgroundColor: 'white',
                            bottom: '0px',
                            zIndex: 1000,
                        }}
                        aria-label={areReactionsOpen ? 'Close reactions' : 'React to the presenter'}
                        onClick={() => {
                            track(ClientEventType.DiscussionReactionMenuClick, {
                                Action: areReactionsOpen ? 'Close' : 'Open',
                            });
                            setAreReactionsOpen(!areReactionsOpen);
                        }}
                    >
                        {areReactionsOpen ? <CloseIcon /> : <EcoIcon htmlColor={lightTheme.primary.greenLight} />}
                    </Fab>
                </Tooltip>
            </Column>
        </Row>
    );
}
