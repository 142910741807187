import { v4 } from 'uuid';

import { TypedUpdate } from '@spinach-shared/types';

// TODO: convert this type into a class
export function getNewTypedUpdate(
    typedUpdate: Partial<Omit<TypedUpdate, 'id' | 'createdOn' | 'updatedOn'>> &
        Pick<TypedUpdate, 'text' | 'updateType' | 'creatorId'>
): TypedUpdate {
    return {
        ...typedUpdate,
        id: v4(),
        createdOn: new Date().toISOString(),
        updatedOn: new Date().toISOString(),
    };
}
