import { SpinachAPIPath } from '@spinach-shared/types';

import { ClientLogger } from '../utils';
import { postSpinachAPI } from './postSpinachAPI';

export async function postAvatarWelcomeSpeechMessage(botId: string): Promise<void> {
    try {
        await postSpinachAPI(SpinachAPIPath.AvatarWelcomeSpeechMessage, { botId });
    } catch (error) {
        ClientLogger.error('Failed to post avatar welcome speech message', error);
    }
}
