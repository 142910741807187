import { Box, CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BaseMeetingProps } from '@spinach-shared/models';
import { ClientEventType, MeetingHistoryJSON, WebUrlQuery } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import { useExperienceTracking, useGlobalAuthedUser, useGlobalNullableStoredSeries } from '../../../../../hooks';
import { useGlobalStoredSeriesList } from '../../../../../hooks/useGlobalStoredSeriesList';
import { BodyRegular, HeaderThreeOnboard, lightTheme } from '../../../../../styles';
import { fetchSeriesHistories, withContentMasking } from '../../../../../utils';
import { Column, Row, Spacing } from '../../../../common';
import { StandupAppSummaries } from './StandupAppSummaries';

type SeriesSelection = {
    seriesId: string;
    code: string;
    label: string;
    title: string;
    verbalDate: string;
    createdAt?: Date;
};

type MeetingSelection = {
    code: string;
    meetingId: string;
    label: string;
    verbalDate: string;
    createdAt: Date;
};

function transformStoredSeriesToSeriesSelection(series: StoredSpinachSeries): SeriesSelection {
    return {
        code: series.id,
        seriesId: series.id,
        title: series.name,
        label: series.name,
        verbalDate: moment(series.createdAt).format('dddd, MMM Do YYYY'),
        createdAt: series.createdAt,
    };
}

export function StandupAppSummarySection() {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const [openedSummary, setOpenedSummary] = useState<number | null>(null);
    const [, setGlobalStoredSeries] = useGlobalNullableStoredSeries();

    const [isLoading, setIsLoading] = useState(false);

    const { storedSeriesListState } = useGlobalStoredSeriesList();
    const legacySeries = storedSeriesListState.storedSeriesList.filter((s) => !s.metadata.scribeMetadata);

    const [meetingHistories, setMeetingHistories] = useState<MeetingHistoryJSON[] | null>(null);
    const [seriesOptions, setSeriesOptions] = useState<SeriesSelection[]>([]);

    const [seriesSelection, setSeriesSelection] = useState<SeriesSelection | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        async function exec() {
            setIsLoading(true);
            const mappedSeries = legacySeries.map(transformStoredSeriesToSeriesSelection);
            const deepSeriesId = searchParams.get(WebUrlQuery.SeriesId);
            const deepMeetingId = searchParams.get(WebUrlQuery.Meeting);

            let deepSeriesSelection: SeriesSelection | null = null;
            let histories: MeetingHistoryJSON[] | null = null;

            if (!!deepSeriesId) {
                const series = legacySeries.find((series) => series.id === deepSeriesId);

                if (series) {
                    deepSeriesSelection = transformStoredSeriesToSeriesSelection(series);
                    const fetchedHistories = await fetchSeriesHistories(deepSeriesId);
                    // we only want to check for a non-null value here
                    if (!!fetchedHistories) {
                        histories = fetchedHistories;
                        setMeetingHistories(histories);
                        if (!!deepMeetingId) {
                            const deepLinkedHistoryIndex = histories.findIndex((h) => h.meeting.id === deepMeetingId);
                            if (deepLinkedHistoryIndex !== -1) {
                                setOpenedSummary(deepLinkedHistoryIndex);
                            }
                        }
                    }
                }
            } else if (legacySeries[0]) {
                setSelectedSeriesAndHistories(transformStoredSeriesToSeriesSelection(legacySeries[0]));
                const fetchedHistories = await fetchSeriesHistories(legacySeries[0].id);
                if (fetchedHistories) {
                    histories = fetchedHistories;
                    setMeetingHistories(histories);
                }
            }

            setSeriesOptions(mappedSeries);

            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Meeting Notes Series Loaded',
                SeriesLength: mappedSeries.length,
            });
            if (deepSeriesSelection && deepSeriesId && !!user.getSeriesById(deepSeriesId)) {
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Loaded Deep-Linked Legacy Meeting Notes',
                });
                setSeriesSelection(deepSeriesSelection);
            } else if (!!histories?.[0]) {
                await setSelectedSeriesAndHistories(mappedSeries[0]);
            }

            setIsLoading(false);
        }
        exec();
    }, []);

    // Clear the navigation state once used.
    useEffect(() => {
        if (seriesSelection && searchParams.get(WebUrlQuery.SeriesId)) {
            setSearchParams((searchParams) => {
                searchParams.delete(WebUrlQuery.SeriesId);
                searchParams.delete(WebUrlQuery.BotId);
                searchParams.delete(WebUrlQuery.EditSuccess);
                return searchParams;
            });
        }
    }, [searchParams, setSearchParams, seriesSelection]);

    async function setSelectedSeriesAndHistories(selection?: SeriesSelection | null) {
        if (!selection) {
            setGlobalStoredSeries(null);
            setSeriesSelection(null);
            return;
        }

        if (selection.seriesId === seriesSelection?.seriesId) {
            return;
        }

        setSeriesSelection(selection);
        setMeetingHistories(null);
        setOpenedSummary(null);
        const series = legacySeries.find((s) => s.id === selection.seriesId);
        setGlobalStoredSeries(series ?? null);

        const seriesHistories = await fetchSeriesHistories(selection.code);
        setMeetingHistories(seriesHistories ?? null);
    }

    const selectedStoredSeries = legacySeries.find((s) => s.id === seriesSelection?.seriesId);
    return (
        <Box height="100vh" display="flex" flexDirection="column">
            <Spacing factor={1 / 2} />
            <Row vCenter>
                <HeaderThreeOnboard>Summaries</HeaderThreeOnboard>
            </Row>

            <Spacing />
            <Row vCenter>
                <Autocomplete
                    id="legacy-summmary-series-selection"
                    getOptionLabel={(option) => option.label}
                    options={seriesOptions}
                    value={seriesSelection}
                    size="small"
                    style={{
                        minWidth: '60%',
                        marginBottom: '10px',
                        width: '100%',
                        maxWidth: '600px',
                    }}
                    autoComplete
                    onChange={async (_, selection) => {
                        setIsLoading(true);
                        await setSelectedSeriesAndHistories(selection);
                        setIsLoading(false);
                        if (!selection) {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Cleared Legacy Series Selection',
                            });
                            return;
                        }
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Selected Summary to Open',
                        });
                    }}
                    {...withContentMasking()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Choose a standup"
                            placeholder="Choose a standup"
                        />
                    )}
                />
            </Row>
            <Box display="flex" flex={1} maxWidth={'900px'} flexDirection={'row'} overflow="clip">
                {!!selectedStoredSeries && !!meetingHistories?.length ? (
                    <>
                        <Column style={{ padding: '8px', background: `${lightTheme.neutrals.offWhite} 30%` }}>
                            <StandupAppSummaries
                                openedSummary={openedSummary}
                                setOpenedSummary={setOpenedSummary}
                                series={selectedStoredSeries}
                                seriesHistories={meetingHistories?.map((h) => new BaseMeetingProps(h.meeting)) ?? []}
                            />
                        </Column>
                    </>
                ) : isLoading ? (
                    <CircularProgress
                        size={'30px'}
                        style={{ marginLeft: '10px', marginBottom: '15px', color: lightTheme.primary.orangeDark }}
                    />
                ) : !meetingHistories?.length ? (
                    <Column>
                        <Spacing factor={1 / 2} />
                        <Row>
                            <BodyRegular>No summary found for this standup.</BodyRegular>
                        </Row>
                    </Column>
                ) : null}
            </Box>
        </Box>
    );
}
