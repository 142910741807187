import { Modal } from '@material-ui/core';
import styled from 'styled-components';

import { GlobalModal } from '../../../atoms';
import { useGlobalModal, useGlobalUser } from '../../../hooks';
import { responsiveness } from '../../../styles';
import { WebAuthRouter } from '../../auth';
import { ModalContent } from '../../series/common';

const ResponsiveModalContent = styled(ModalContent)`
    max-width: 450px;
    min-width: 450px;

    @media ${responsiveness.thinnerThanSM} {
        max-width: 450px;
        min-width: 80%;
    }

    @media ${responsiveness.thinnerThanXS} {
        min-width: 90%;
    }
`;

export function WebAuthRouterModal(): JSX.Element {
    const [globalModal, setGlobalModal] = useGlobalModal();
    const [user] = useGlobalUser();

    const shouldRequireSignup = !!user?.shouldAuthBeforeDemo;

    return (
        <Modal
            open={globalModal === GlobalModal.VerifyEmail}
            onClose={
                shouldRequireSignup
                    ? undefined
                    : () => {
                          setGlobalModal(null);
                      }
            }
        >
            <ResponsiveModalContent
                style={{
                    backgroundColor: 'rgba(0,0,0,0)',
                    maxWidth: 'unset',
                    width: '100%',
                    backdropFilter: 'blur(3px)',
                }}
            >
                <WebAuthRouter />
            </ResponsiveModalContent>
        </Modal>
    );
}
