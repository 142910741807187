import { Participant } from '@spinach-shared/types';

import { SpinachParticipantProps } from '..';
import { BaseParticipantListProps } from './BaseParticipantListProps';

export class SpinachParticipantListProps extends BaseParticipantListProps {
    constructor(participants: Participant[], readonly spinachUserId: string) {
        super(participants);
    }

    get spinachParticipant(): SpinachParticipantProps {
        const spinachParticipant = this.participants
            .filter((p): p is SpinachParticipantProps => p instanceof SpinachParticipantProps)
            .find((p) => p.spinachUserId === this.spinachUserId);

        if (!spinachParticipant) {
            throw new Error('Unable to find spinach participant in SpinachParticipantList');
        }

        return spinachParticipant;
    }
}
