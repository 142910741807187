export const icebreakers = [
    'Are bacon bits a seasoning?',
    "Are dogs' front limbs arms or legs?",
    'Are noodles pasta?',
    'Are tomatoes a fruit?',
    'Are you a morning person or a night person?',
    // 'Are you team Android or team Apple?',
    // 'Can you play any instruments? If not, what do you wish you could play?',
    // 'Did you ever have an imaginary friend? If so, what was its name?',
    // 'Do you collect anything?',
    // 'Do you eat rice with a spoon, fork, chopsticks, or some other way?',
    // 'Do you have a favorite song to listen to when you need your spirits lifted?',
    // 'Do you have any family traditions that you look forward to each year?',
    // 'Do you have any pets as in-house colleagues?',
    // 'Do you have houseplants in your office?',
    // 'Do you have siblings? How many? Where do you fit in the mix?',
    // 'Do you listen to the news?',
    // 'Does the person flying in the middle seat get both armrests?',
    // 'How deep does a pan have to be before it is considered a pot?',
    // 'How do you like your eggs?',
    // 'How many holes does a straw have?',
    // 'How would you describe your sense of humor?',
    // 'If a tree falls in the woods and nothing is there to hear it, did it make a sound?',
    // 'If someone offered you a million dollars to give up your smartphone forever, would you do it?',
    // 'If you could either live where it only snows or the temperature never falls below 100 degrees, which would it be?',
    // 'If you could go back in time and pay more attention in one class, what would it be?',
    // 'If you could have an unlimited supply of one thing for the rest of your life, what would it be any why?',
    // 'If you could live in the 60s, 70s, 80s, or 90s, which would you choose and why?',
    // 'If you could magically become fluent in any language, what would it be?',
    // 'If you could say one thing to the entire world right now, what would you say?',
    // 'If you had a time machine, would go back in time or into the future?',
    // 'If you had an entrance song play every time you entered a room, what would it be?',
    // 'If you had to eat a crayon, what color would you choose? Why?',
    // 'If you had to eat one meal every day for the rest of your life what would it be and why?',
    // 'If you mix dirt and water, are you making water dirty or dirt wet?',
    // "If you ran the world, what's one thing you'd change?",
    // 'If you were a vegetable, what vegetable would you be and why?',
    // "If you're not from this city, why did you end up here?",
    // 'Is GIF pronounced gift (with a silent “t”) or jif (like the peanut butter)?',
    // 'Is cereal a soup?',
    // 'Is cheesecake a cake or a pie?',
    // 'Is soap clean or dirty?',
    // 'Is water wet?',
    // 'Road trip or plane trip?',
    // 'Snow, sand, or other?',
    // 'Spotify or Apple Music?',
    // 'Sweet or savory?',
    // 'Teleportation or flying?',
    // 'What album could you listen to over and over again and not get sick of?',
    // 'Which clubs were you a member of in high school?',
    // 'What color are mirrors?',
    // 'What does your morning routine look like when working from home?',
    // 'What emoji best describes you?',
    // 'What is one thing on the very top of your bucket list?',
    // 'What is your favorite sport or physical activity?',
    // 'What is your favorite thing to do by yourself?',
    // 'What is your favorite thing to do locally?',
    // 'What is your favorite type of cuisine?',
    // 'What type of music do you prefer?',
    // 'What was your favorite band ten years ago?',
    // 'What was your favorite cartoon show growing up?',
    // 'What was your favorite movie as a kid and why?',
    // 'What would be the most surprising scientific discovery imaginable?',
    // 'What would be your campaign slogan if you were running for office?',
    // 'What would your dream house be like?',
    // "What's something interesting about your family history?",
    // "What's something you simply will not eat?",
    // "What's the weirdest food you've ever eaten?",
    // "What's your dream car?",
    // "What's a hobby you've always wanted to take up?",
    // "What's your favorite hobby?",
    // "What's your favorite holiday?",
    // "What's your favorite plant?",
    // "What's your favorite quote?",
    // "What's your favorite, or least favorite, holiday song?",
    // "When's the last time you went somewhere for the first time?",
    // 'Where do you work most frequently from at home? Your office? Your kitchen table? The backyard? Your bed?',
    // 'Who in your life has inspired you most?',
    // "Who's someone you really admire?",
    // "Who's the toughest person you have to buy for during the holidays?",
    // "Who's your favorite superhero?",
    // 'Would you like to be famous? In what way?',
    // 'Would you rather be able to run at 100 miles per hour or fly at 10 miles per hour?',
    // 'Would you rather be able to swim underwater limitlessly or fly?',
    // 'Would you rather be in a zombie apocalypse or a robot apocalypse?',
    // 'Would you rather be too hot or too cold?',
    // 'Would you rather give up your cellphone for a month or bathing for a month?',
    // 'Would you rather go deep-sea diving or bungee jumping?',
    // 'Would you rather go for a run or go for a hike?',
    // 'Would you rather go to a house party or a bar?',
    // 'Would you rather go to the movie theaters or watch a movie from home?',
    // 'Would you rather have a pause or a rewind button in your life?',
    // 'Would you rather have telekinesis (the ability to move things with your mind) or telepathy (the ability to read minds)?',
    // 'Would you rather have the ability to see 10 minutes into the future or 150 years into the future?',
    // 'Would you rather live in a remote destination or the heart of a metropolitan city?',
    // 'Would you rather live without heat and AC or live without social media?',
    // 'Would you rather lose the ability to read or lose the ability to speak?',
    // 'Would you rather never be able to go out during the day or never be able to go out at night?',
    // 'Would you rather smell with your hands or talk with your feet?',
    // 'Would you rather travel by train or travel by plane?',
    // 'Would you rather travel the world for free for a year or have $50,000 to spend however you please?',
    // 'Would you rather travel to a beach destination or ski resort?',
    // 'Would you rather travel to space or travel to the past?',
    // "You're going sail around the world. What's the name of your boat?",
    // 'Are you a cat person or a dog person?',
    // 'Are you a good dancer?',
    // 'Are you a morning person or a night person?',
    // 'Are you a traveler or a homebody?',
    // 'Are you an early bird or night owl?',
    // 'Are you sunrise, daylight, twilight, or nighttime? Why?',
    'As a child, what did you want to be when you grew up?',
    'Be honest, how often do you work from bed?',
    "Best book you've ever read?",
    "Best professional development book you've ever read?",
    'Coffee or tea?',
    'Do you have a dedicated office space at home?',
    'Do you love working from home or would you rather be in the office? Is there a balance of both that you like best?',
    'Do you play any sports?',
    'Does your current car have a name? What is it?',
    'Have you ever been told you look like someone famous, who was it?',
    'Have you ever met your idol or someone you revere greatly?',
    'How do you stay productive and motivated working virtually?',
    'How many cups of coffee, tea, or beverage-of-choice do you have each morning?',
    'If aliens landed on earth tomorrow and offered to take you home with them, would you go?',
    'If you could add anyone to Mount Rushmore who would it be; why?',
    'If you could be any animal in the world, what animal would you choose to be?',
    'If you could be any supernatural creature, what would you be and why?',
    'If you could be guaranteed one thing in life (besides money), what would it be?',
    'If you could be immortal, what age would you choose to stop aging at and why?',
    'If you could be immortal, would you choose to be?',
    'If you could bring back any fashion trend what would it be?',
    'If you could choose any two famous people to have dinner with who would they be?',
    'If you could commit any crime and get away with it what would you choose and why?',
    'If you could do anything in the world as your career, what would you do?',
    'If you could donate a million dollars to any charity, what cause would you choose?',
    'If you could eliminate one thing from your daily routine, what would it be and why?',
    'If you could go to Mars, would you? Why or why not?',
    'If you could hang out with any cartoon character, who would you choose and why?',
    'If you could have any animal for a pet, what would it be?',
    'If you could have any superpower, what would it be?',
    'If you could have a personal assistant, what would you have them do?',
    'If you could have the power of teleportation right now, where would you go and why?',
    'If you could have your own TV show, what would it be about?',
    'If you could instantly become an expert in something, what would it be?',
    'If you could learn one new personal skill, what would it be?',
    'If you could learn one new professional skill, what would it be?',
    'If you could live anywhere in the world for a year, where would it be?',
    'If you could live in one fictional universe, which one would you choose?',
    'If you could magically become fluent in any language, what would it be?',
    'If you could only eat one dessert for the rest of your life, what would it be?',
    'If you could rename yourself, what name would you pick?',
    'If you could see one movie again for the first time, what would it be and why?',
    'If you could switch lives with anyone for a day, who would it be?',
    'If you could write a book that was guaranteed to be a best seller, what would you write?',
    'If you could write a book, what genre would you write it in?',
    'If you had a million dollars, what would you buy first?',
    'If you had a time machine, would go back in time or into the future?',
    'If you had to delete all but 3 apps from your smartphone, which ones would you keep?',
    'If you had to eat one meal everyday for the rest of your life what would it be?',
    'If you had to teach a class on one thing, what would you teach?',
    'If you were a wrestler what would be your entrance theme song?',
    'If you were famous, what would you be famous for?',
    'If you were invisible for a day, what would you do?',
    'If your pet could talk, what do you think it would say?',
    'Name an imaginary college class you wish you had taken.',
    'Name one cool feature you would add to your dream house.',
    'Popcorn or M&Ms?',
    "Say you're independently wealthy and don't have to work, what would you do with your time?",
    'Show us your office space!',
    'Teleportation or flying?',
    'The zombie apocalypse is coming, who are 3 people you want on your team?',
    // 'What are you most excited about this year?',
    // 'What book, movie read/seen recently you would recommend and why?',
    // 'What breed of dog would you be?',
    // 'What current fact about your life would most impress your five year old self?',
    // 'What did you eat for breakfast?',
    // 'What did you have for breakfast this morning?',
    // 'What did you name your first car?',
    // 'What does your favorite shirt look like?',
    // 'What does your typical work from home uniform look like?',
    // 'What fictional family would you be a member of?',
    // 'What fictional world or place would you like to visit?',
    // 'What fruit or vegetable would you most want to be?',
    // 'What is one article of clothing that someone could wear that would make you walk out on a date with them?',
    // 'What is one thing we would never guess about you?',
    // 'What is one thing you want older generations to know about teenagers today?',
    // 'What is or was your major?',
    // 'What is the best dish you can cook?',
    // 'What is the last TV show that you binge-watched?',
    // 'What is the most amazing story about your family?',
    // 'What is the strangest habit you have?',
    // 'What is your absolute dream job?',
    // 'What is your best idea for a new invention?',
    // 'What is your cellphone wallpaper?',
    // 'What is your favorite TV show?',
    // 'What is your favorite breakfast food?',
    // 'What is your favorite color?',
    // 'What is your favorite dessert?',
    "What is your favorite item you've bought this year?",
    'What is your favorite magical or mythological animal?',
    'What is something on your bucket list?',
    'What was your first job?',
    'If you could instantly be an expert in a subject, what would it be?',
    'What do the first 30 minutes of your typical day look like?',
    "What's your most used emoji?",
    'If you could pick up a skill instantly, what would it be?',
    'Which season fits your personality?',
    'If you could compete in the Olympics, which sport would you choose?',
    // "You're at a cafe. What do you order to drink?",
    // 'If you could have any fictional character as your friend, who would you choose and why?',
    // 'If you could meet any historical figure, who would you choose and why?',
    // 'If you could live in a TV show, which one would it be and why?',
    // 'If you could visit any place in the world, where would it be and why?',
    // 'If you could visit any time in the past, when and where would it be?',
    'What is something you think everyone should do at least once in their lives?',
    'When you leave a room, what do you want people to remember you for?',
    'What is your comfort meal?',
    'What movie can you rewatch over and over and it never gets old?',
    'Zombie Apocalypse: choose your weapon',
    'What was your favorite toy as a kid?',
    'What song is stuck in your head this week?',
    'What was the first concert you went to?',
    'What helps you decompress and relax at the end of the day or week?',
    'What is your morning routine?',
    'What is your favorite scent?',
    'If you hosted a podcast, who would you want as your first guest? It can be anyone',
    'What is the biggest compliment someone could give you?',
    "What's your favorite room in your house?",
    'When you get good news, who is the first person you call to tell?',
    'What was your favorite book as a child?',
    'What book do you think everyone should read?',
    'What does your perfect day off look like?',
    'What app on your phone do you highly recommend?',
    'Latest Amazon purchase?',
    'What was your first car?',
    'As a child, what did you want to be when you grew up?',
    "What was your favorite class you've ever taken?",
    'If you can take a class on any subject right now, what would it be?',
    'What did you have for dinner last night?',
    'Build the perfect sandwich',
    "What is the most meaningful gift you've ever been given?",
    'What gift were you really proud of giving someone?',
    'Where could someone find you at a party?',
    "We're having a potluck, what would you bring?",
    'What is the background on your phone?',
    'What was your first cell phone?',
    'What are your favorite movie snacks?',
    'What is a surprising fact about you?',
    'What tourist attraction in your city is actually worth the hype?',
    'A friend is visiting your city for the first time, where do you take them?',
    'What is your favorite dessert?',
    'What is your go to ice cream flavor?',
    'What type of cake do you want on your birthday?',
    'Are avocados overrated?',
    'Are you a dog person or a cat person and why?',
    'Corner, edge, or middle brownie?',
    'Describe a distinct smell from your childhood/home',
    'Describe the bite of your ideal apple',
    'Describe the taste of the water from your hometown',
    'Do you eat food off of the floor and do you have rules about when?',
    'Do you fold or bunch toilet paper?',
    'Do you have a mentor?',
    'Do you have any weird sleeping habits?',
    'Drop a beat',
    'Favorite appetizer',
    'Favorite expression/one that would sound really weird to foreigners?',
    'Favorite herb?',
    'Favorite inside joke, with anyone?',
    'Favorite kind of cheese?',
    'Favorite kind of cookie?',
    'Favorite kind of tree?',
    'Favorite non-mammal animal?',
    'Favorite quote?',
    'Favorite/most attractive accent?',
    'Have you ever made a decision that changed your life?',
    'How do you like to spend your free time?',
    'How many close friends is ideal?',
    'How many times do you usually sneeze?',
    'If you could be any form of potato, what would you be?',
    'If you could control an element, which one would you choose?',
    // 'If you could eliminate one part of your regular washing-up routine and it would just happen automatically (hair always brushed, face always washed), what would it be?',
    // 'If you could live in the world of a picture book from your childhood, which one?',
    // 'If you could only bring three things with you on a deserted island, what would you pick?',
    // 'If you could only eat one food for the rest of your life, what would it be?',
    // 'If you could save one material thing from a fire, what would you save?',
    // 'If you could suddenly be really good at a skill, what would you choose?',
    // 'Is a hot dog a sandwich?',
    // 'Is a taco a sandwich? A hot dog?',
    // 'Is it ever OK to lie?',
    // 'Least favorite number?',
    // 'Rank flavors of sour patch kids from best to worst',
    // 'Second favorite fruit?',
    // 'Tell a fun fact',
    // 'Tell a time you got REALLY lucky after messing up such that nobody ever noticed or found out',
    'What age were you when you lost your first and last tooth?',
    'What are the most important qualities in a leader?',
    'What are you most afraid of?',
    'What are you most thankful for?',
    'What did teachers used to say about you on your report card—and is it still true?',
    'What did your parents call you as a kid?',
    'What do you feel most passionate about?',
    'What do you sing in the shower?',
    'What do you want people to say about you at your funeral?',
    'What does your dream life look like?',
    'What Greek god or goddess would you want to be best friends with?',
    'What is on your bucket list?',
    'What is something people would never guess just by looking at you?',
    "What is the best compliment that you've ever received?",
    'What is the best concert you have ever been to?',
    'What is your favorite emoji?',
    'What is your favorite movie of all time?',
    'What is your favorite urban legend that you believe is true?',
    'What kind of old person would you be?',
    'What kitchen utensil would you be?',
    'What lettuce do you use for your salads?',
    'What makes you laugh the most?',
    'What movie did you watch the most as a kid?',
    'What petty crime would you want to commit?',
    'What petty superpower would you choose?',
    'What position do you fall asleep in?',
    'What song has memories permanently attached?',
    'What song will you probably always have memorized?',
    'What sound would your favorite color make?',
    'What superstitions do you hold?',
    'What type of bagel do you prefer?',
    // 'What was something you thought was a career setback that actually turned out to be an opportunity?',
    'What was the best decade for music?',
    'What was the happiest day of your life?',
    'What was the name of your favorite childhood stuffed animal?',
    'What word did you mispronounce as a child?',
    'What would you do tomorrow if you won a million dollars today?',
    'What would you want people to say about you at your retirement party?',
    'What would your perfect day be like?',
    "What's a food you used to hate but now like?",
    "What's a skill you definitely should have but don't?",
    "What's one thing you enjoy eating but would be embarrassed to eat in public?",
    "What's one word you always spell wrong?",
    "What's something that makes you much angrier than it should?",
    "What's the best pun you've ever made?",
    "What's the weirdest dream you can remember?",
    "What's your cool body fact?",
    "What's your guilty pleasure TV show?",
    "What's your hidden talent?",
    "What's one place you've visited that you never want to return?",
    "What's the best piece of career advice you ever got?",
    "What's the best show on TV right now?",
    "What's the best thing you've read in the last five years?",
    "What's the most annoying thing people do in public?",
    "What's the most exciting part of your job?",
    "What's the most important thing you've ever done at work?",
    "What's the most recent picture on your phone and why is it there?",
    "What's the one item of clothing you couldn't live without?",
    "What's the worst movie you ever saw?",
    "What's the worst seat on a plane?",
    "What's your biggest pet peeve?",
    "What's your earliest memory?",
    "What's your favorite karaoke song?",
    "What's your favorite place on earth?",
    "What's your favorite sandwich and where did you eat it?",
    "What's your favorite t-shirt that you own and why is it your favorite?",
    "What's your favorite thing about yourself?",
    "What's your go-to doodle when you're bored/thinking?",
    "What's your ideal work-life balance?",
    'When you were little, what was your dream job?',
    'Where in your mouth do you start brushing your teeth?',
    'Which aisle in a grocery store would you be?',
    'Which divergent faction would you choose?',
    'Who in your life most makes you feel a sense of home?',
    'Who is the most important person in your life and why?',
    'Who was your childhood imaginary friend?',
    'Who was your first celebrity crush?',
    "Who's the most overrated celebrity?",
    "Who's your dream dinner party guest, living or dead?",
    'Who or what were you in a past life?',
    'Why did your parents give you your name?',
    'Would you rather be hot or cold?',
    'Would you rather fight forty duck-sized horses or one horse-sized duck?',
    'Would you rather live without the internet, or without bathing?',
    // Seasonals
    // "What's the best Halloween costume you've worn?",
    // 'What is your favorite costume from childhood?',
    // 'What is your favorite costume you wore as an adult?',
    // 'What is your favorite candy?',
    // 'What is your favorite Halloween/scary movie?',
    'What is your favorite fall baked good?',
    'What is your favorite fall drink?',
    'What is your favorite fall scent?',
    'What is your favorite fall tradition?',
    // 'Which traditional Halloween monster scares you the most?',
    // 'Trick or treat?',
    // 'Would you rather meet a vampire or werewolf?',
    // 'What superhero would you dress up as?',
    // 'Would you rather go trick or treating or stay home and watch a scary movie?',
    // 'Would you rather eat candy apples or candy corn?',
    // 'Would you rather have a creative costume or a scary costume?',
    // 'Most disappointing Halloween candy',
    // 'Who is scarier, Freddie or Jason?',
    // 'What is scarier, Friday the 13th or Nightmare on Elm Street?',
    // 'The Shining or Carrie?',
    // 'Are the Gremlins still scary?',
    // 'Which girl is scarier, the one from The Ring or the one from Poltergeist?',
    // 'Chocolate candy or fruity candy?',
    // 'Best chocolate brand in the world?',
    // 'Skittles or Starburst?',
    // 'Reeses or KitKat?',
    // 'How many sweaters, hoodies or jumpers do you own?',
    // 'Pumpkin patches or haunted houses?',

    /** 3/16/23 */
    'What’s your favorite scent?',
    'What would your job be if you were born in the medieval times?',
    'What book do you recommend everyone read?',
    'Where would you take us if we all visited your hometown?',
    'Show us your Youtube feed',
    'Show and tell us something nearby',
    'What totally different career would you want to have?',
    'What are you looking forward to this week?',
    'What was the highlight of your weekend?',
    'Favorite cereal?',
    'Are you a good dancer?',
    'Which band/artist – dead or alive would play at your funeral (or other celebration)?',
    'What was the last movie you watched that made you cry?',
    'If you could see one movie again for the first time, what would it be and why?',
    'What current fact about your life would most impress your five-year-old self?',
    'What’s one of the most delicious things you’ve eaten recently?',
    'You have to sing karaoke. What song do you pick?',
    'What’s something that a teammate did this week that you admire or are proud of?',
    'What’s a food you hate that most people love?',
    'What’s the weirdest food combination you enjoy?',
    'Share your biggest home cooking success and/or failure!',
    'Go to food-spot growing up',
    'Current or aspirational tattoos?',
    'What’s your favorite weather?',
    'What movie scarred you as a child?',
    'First CD/tape you ever bought?',
    'What was your first job ever?',
    'Where do you want to travel?',
    'What’s your favorite piece of furniture you’ve ever owned?',
    'What’s expensive but totally worth it to you?',
    'What’s the longest you’ve gone without sleep (and why)?',
    'Do you collect anything?',
    'What’s your worst bug-related story?',
    'How often do you experience deja vu?',
    'What’s your most unusual item of clothing?',
];
