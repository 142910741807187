import { AgendaItem, AgendaItemSource, MeditationItem } from '@spinach-shared/types';

import { YTBUpdateProps } from '../..';
import { BaseAgendaItemProps } from './BaseAgendaItemProps';
import { LegacyAgendaItemProps } from './LegacyAgendaItemProps';
import { MeditationAgendaItemProps } from './MeditationAgendaItemProps';
import { ParkingLotAgendaItemProps } from './ParkingLotAgendaItemProps';
import { YTBAgendaItemProps } from './YTBAgendaItemProps';

export class AgendaItemPropsFactory {
    static createAgendaItem(item: AgendaItem | BaseAgendaItemProps | MeditationItem): BaseAgendaItemProps {
        if (item instanceof BaseAgendaItemProps) {
            return item;
        } else if (item.source === AgendaItemSource.YTBStandUpUpdate && item.standUpUpdate) {
            return new YTBAgendaItemProps({
                title: item.title,
                source: item.source,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                projectManagement: item.projectManagement,
                isParticipantAgendaItem: item.isParticipantAgendaItem,
                standUpUpdate: new YTBUpdateProps(item.standUpUpdate),
                id: item.id,
                talkTimes: item.talkTimes,
                totalTime: item.totalTime,
            });
        } else if (item.status && item.isMutable !== undefined) {
            return new LegacyAgendaItemProps({
                title: item.title,
                source: item.source,
                updatedAt: item.updatedAt,
                id: item.id,
                talkTimes: item.talkTimes,
                isParticipantAgendaItem: item.isParticipantAgendaItem,
                intendedDuration: item.intendedDuration,
                status: item.status,
                projectManagement: item.projectManagement,
                isMutable: item.isMutable,
                totalTime: item.totalTime,
                createdAt: item.createdAt,
            });
        } else if (item.source === AgendaItemSource.ParkingLot) {
            return new ParkingLotAgendaItemProps({
                title: item.title,
                source: item.source,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                isParticipantAgendaItem: item.isParticipantAgendaItem,
                id: item.id,
                talkTimes: item.talkTimes,
                totalTime: item.totalTime,
            });
        } else if (item.source === AgendaItemSource.Meditation) {
            return new MeditationAgendaItemProps({
                ...item,
                title: 'Meditation',
                source: item.source,
                isParticipantAgendaItem: false,
                intendedDuration: item.intendedDuration,
            });
        } else {
            return new BaseAgendaItemProps({
                title: item.title,
                source: item.source,
                createdAt: item.createdAt,
                updatedAt: item.updatedAt,
                projectManagement: item.projectManagement,
                isParticipantAgendaItem: item.isParticipantAgendaItem,
                id: item.id,
                talkTimes: item.talkTimes,
                totalTime: item.totalTime,
            });
        }
    }
}
