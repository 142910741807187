import { ISOString } from '@spinach-shared/types';

export const getSecondsElapsedSince = (since: ISOString, offset: number = 0) => {
    if (!since) {
        return 0;
    }
    const startedAtDate = new Date(since).getTime();
    const currentTimeInSameZone = new Date(new Date().toISOString()).getTime();
    const timeDifferenceInSeconds = (currentTimeInSameZone - startedAtDate + offset) / 1000;

    return timeDifferenceInSeconds;
};
