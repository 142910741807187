export const capitalizeString = <T extends string>(str: string): Capitalize<T> => {
    return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>;
};

export function convertHyphenCaseToTitleCase(hyphenated: string): string {
    const titleCase = hyphenated
        .split('-')
        .map((part) => capitalizeString(part))
        .join('');

    return titleCase;
}
