import { DateTimeMetadata, ValueOf } from '@spinach-shared/types';

/**
 *
 * @param selectedDateTime
 * @param key
 * @returns The proper value of selectedDateTime depending on whether the selectedDateTime is changed for this week only or not
 */
export function getSelectedDateTimeInformation<
    T extends ValueOf<Omit<DateTimeMetadata, 'oneOffDateTimeMetadata' | 'isChangedForThisWeekOnly'>>
>(
    selectedDateTime: DateTimeMetadata | null | undefined,
    key: keyof Omit<DateTimeMetadata, 'oneOffDateTimeMetadata' | 'isChangedForThisWeekOnly'>
): T {
    return (
        selectedDateTime &&
        selectedDateTime.isChangedForThisWeekOnly &&
        selectedDateTime.oneOffDateTimeMetadata &&
        selectedDateTime.oneOffDateTimeMetadata[key] !== undefined
            ? selectedDateTime.oneOffDateTimeMetadata[key]
            : selectedDateTime?.[key]
    ) as T;
}
