import { useEffect, useState } from 'react';

import { ClientUser } from '@spinach-shared/models';
import { SpinachAPIPath, UserIntegrations, WebUrlQuery } from '@spinach-shared/types';

import { jiraAuth } from '../apis/jira';
import { SetValue } from '../types';
import { URLUtil } from '../utils';
import { useSeriesReality } from './useGlobalSearchParams';
import { useGlobalAuthedUser } from './useGlobalUser';
import { useGlobalSlack } from './useSlack';

type UserAuthMap = Record<
    UserIntegrations,
    {
        authed: boolean;
        isLoading: boolean;
        setIsLoading: SetValue<boolean>;
        authorize: () => void;
    }
>;

type GenerateAuthUserMapInput = {
    isJiraPending: boolean;
    setIsJiraPending: SetValue<boolean>;
    isSlackPending: boolean;
    setIsSlackPending: SetValue<boolean>;
    slackUrl: string;
    isGoogleCalendarPending: boolean;
    setIsGoogleCalendarPending: SetValue<boolean>;
    isDemoSeries: boolean;
};

const generateUserAuthMap = (
    user: ClientUser,
    {
        isJiraPending,
        setIsJiraPending,
        isSlackPending,
        setIsSlackPending,
        slackUrl,
        isGoogleCalendarPending,
        setIsGoogleCalendarPending,
        isDemoSeries,
    }: GenerateAuthUserMapInput
) => {
    return {
        [UserIntegrations.Jira]: {
            authed: user.isAuthedForJira || isDemoSeries,
            isLoading: isJiraPending,
            setIsLoading: setIsJiraPending,
            authorize: () => {
                setIsJiraPending(true);
                /**
                 * We could check for user.isScribeUser here
                 * But, it hasn't been that way in the past, and we haven't
                 * had any issues with it so far. So, I'm leaving it as is.
                 *
                 */
                jiraAuth(user.spinachUserId, false);
            },
        },
        [UserIntegrations.Slack]: {
            authed: user.isAuthedForSlack,
            isLoading: isSlackPending,
            setIsLoading: setIsSlackPending,
            authorize: () => {
                setIsSlackPending(true);
                URLUtil.openURL(slackUrl);
            },
        },
        [UserIntegrations.GoogleCalendar]: {
            authed: user.isAuthedForGoogleCalendar,
            isLoading: isGoogleCalendarPending,
            setIsLoading: setIsGoogleCalendarPending,
            authorize: () => {
                setIsGoogleCalendarPending(true);
                URLUtil.openURL(
                    `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleCalendarAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                );
            },
        },
    };
};

export const useUserAuthMap = (): [UserAuthMap] => {
    const [user] = useGlobalAuthedUser();
    const [isJiraPending, setIsJiraPending] = useState(false);
    const [isSlackPending, setIsSlackPending] = useState(false);
    const [isGoogleCalendarPending, setIsGoogleCalendarPending] = useState(false);
    const { isDemoSeries } = useSeriesReality();

    const { slackState } = useGlobalSlack();
    const slackUrl = slackState.installUrl;

    const authMap: UserAuthMap = generateUserAuthMap(user, {
        isJiraPending,
        setIsJiraPending,
        isSlackPending,
        setIsSlackPending,
        isGoogleCalendarPending,
        setIsGoogleCalendarPending,
        slackUrl,
        isDemoSeries,
    });

    const [userAuthMap, setUserAuthMap] = useState<UserAuthMap>(authMap);

    useEffect(() => {
        setUserAuthMap(
            generateUserAuthMap(user, {
                isJiraPending,
                setIsJiraPending,
                isSlackPending,
                setIsSlackPending,
                isGoogleCalendarPending,
                setIsGoogleCalendarPending,
                slackUrl,
                isDemoSeries,
            })
        );
    }, [slackUrl, isJiraPending, isSlackPending, isGoogleCalendarPending, user.spinachUserId]);

    return [userAuthMap];
};
