import { LinearProgress, TextField } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

import {
    AvatarDialogueState,
    AvatarProposedTicket,
    AvatarTask,
    AvatarTaskKind,
    AvatarTaskSocketRequest,
    ClientEventType,
    ClientSocketEvent,
    LiveTranscript,
    TICKET_SOURCE_MAP,
} from '@spinach-shared/types';
import { isLocalStage } from '@spinach-shared/utils';

import {
    BodyBig,
    ButtonSize,
    Column,
    FYI,
    FYIState,
    PrimaryButton,
    ScrollArea,
    Spacing,
    SpinachContainer,
    URLUtil,
    createWebsocketPayload,
    lightTheme,
    useGlobalAuthedUser,
    useGlobalLiveSeries,
    useGlobalNullableLiveSeries,
    useGlobalNullableStoredSeries,
    useSpinachNewInputStyles,
    withContentMasking,
} from '../../..';
import { postCreateTicket } from '../../../apis/postCreateTicket';
import { ReactComponent as GreenCheckIcon } from '../../../assets/green-circle-check.svg';
import DialogBackgroundSrc from '../../../assets/spinach-dialogue-background.svg';
import {
    useActivityTracking,
    useAvatarClassifierListener,
    useClickTracking,
    useDisableRoundtableForAgentSeries,
    useGlobalLiveAvatar,
    useGlobalLocalAgent,
} from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { useGlobalMeetingSocket, useGlobalNullableMeetingSocket } from '../../../hooks/useGlobalSocket';
import { useLoadSeriesOnMount } from '../../spinach-ai/hooks/useLoadSeriesOnMount';
import { OutlinedButton } from '../../stand-up/OutlinedButton';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { Row } from '../framing';
import { AgentAgendaModal } from '../modals/AgentAgendaModal';
import { LiveNotesContainer, LiveNotesHeader } from './LiveNotesContainer';

export const AvatarContainer = (): JSX.Element => {
    const [series] = useGlobalNullableStoredSeries();

    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useGlobalNullableLiveSeries();
    const [isFollowing, setIsFollowing] = useState<boolean>(false);
    const [socket] = useGlobalNullableMeetingSocket();

    const { onCreatedTicket, onTestHeySpinachCreateTicket } = useAvatarClassifierListener();
    useDisableRoundtableForAgentSeries();

    useLoadSeriesOnMount();

    useEffect(() => {
        window.parent.postMessage({ type: 'avatar-container-loaded' }, 'https://meet.google.com');
    }, []);

    if (!series || !liveSeries || !user || !socket) {
        return <FYI state={FYIState.Loading} />;
    }

    return (
        <SpinachContainer isOnDemo={false} style={{ overflowY: 'hidden' }}>
            <LiveNotesHeader isFollowing={isFollowing} setIsFollowing={setIsFollowing} />
            <LiveNotesContainer isFollowing={isFollowing} />

            <AgentAgendaModal />
            <AvatarDialogue
                onCreatedTicket={onCreatedTicket}
                onTestHeySpinachCreateTicket={onTestHeySpinachCreateTicket}
            />
        </SpinachContainer>
    );
};

const Background = styled(Row)`
    background-image: url(${DialogBackgroundSrc});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: 100ms;
`;

export function AvatarDialogue({
    onCreatedTicket,
    onTestHeySpinachCreateTicket,
}: {
    onCreatedTicket: () => void;
    onTestHeySpinachCreateTicket: () => void;
}): JSX.Element {
    const [state] = useGlobalLiveAvatar();
    const { avatarTasks } = state;

    return (
        <AvatarDialogueBackground>
            <Column>
                <AvatarFocusedNotification
                    onCreatedTicket={onCreatedTicket}
                    onTestHeySpinachCreateTicket={onTestHeySpinachCreateTicket}
                />
                {avatarTasks.length ? <AvatarNotificationHistory onCreatedTicket={onCreatedTicket} /> : null}
            </Column>
        </AvatarDialogueBackground>
    );
}

export function AvatarNotificationHistory({ onCreatedTicket }: { onCreatedTicket: () => void }): JSX.Element {
    const [state] = useGlobalLocalAgent();
    const [liveAgent] = useGlobalLiveAvatar();
    const [localAgent] = useGlobalLocalAgent();

    /**
     * if we only have one ticket in history,
     * we dont want to show the that ticket in minimized state
     * while we're proposing or confirming creatino for that ticket
     */
    const statesToHideFirstTicketWhenMinimized: AvatarDialogueState[] = [
        AvatarDialogueState.ProposingTask,
        AvatarDialogueState.TaskAccepted,
    ];
    const shouldHideMinimizedState =
        statesToHideFirstTicketWhenMinimized.includes(localAgent.dialogueState) && liveAgent.avatarTasks.length === 1;

    const { isNotificationCenterExpanded } = state;

    if (isNotificationCenterExpanded) {
        return <AvatarExpandedNotificationHistory onCreatedTicket={onCreatedTicket} />;
    } else if (shouldHideMinimizedState) {
        return <></>;
    } else {
        return <AvatarMinimizedNotificationHistory onCreatedTicket={onCreatedTicket} />;
    }
}

export function AvatarFocusedNotification({
    onCreatedTicket,
    onTestHeySpinachCreateTicket,
}: {
    onCreatedTicket: () => void;
    onTestHeySpinachCreateTicket: () => void;
}): JSX.Element {
    const [localAgentState] = useGlobalLocalAgent();
    const focusedContent = localAgentState.dialogueState;

    switch (focusedContent) {
        case AvatarDialogueState.HeySpinachHeard:
            return <AvatarListeningContent />;
        case AvatarDialogueState.ProcessingTask:
            return <AvatarLoadingTaskContent />;
        case AvatarDialogueState.ProposingTask:
            return <AvatarProposeTicketContent onCreatedTicket={onCreatedTicket} />;
        case AvatarDialogueState.TaskAccepted:
            return <AvatarTicketCreatedContent />;
        case AvatarDialogueState.TaskUnknown:
            return <AvatarUnknownRequestContent />;
        default:
            return <AvatarCommandSuggestionContent onTestHeySpinachCreateTicket={onTestHeySpinachCreateTicket} />;
    }
}

export function AvatarDialogueBackground({ children }: { children: ReactNode }): JSX.Element {
    return <Background>{children}</Background>;
}

export const getFakeCreateTicketCommand = (userId: string): LiveTranscript => ({
    final: true,
    id: v4(),
    speaker: userId,
    text: 'create a ticket to fix the realtime avatar state',
    timestamp: new Date().toISOString(),
});

export function AvatarCommandSuggestionContent({
    onTestHeySpinachCreateTicket,
}: {
    onTestHeySpinachCreateTicket: () => void;
}): JSX.Element {
    return (
        <Column style={{ height: '110px' }} vCentered={true}>
            <Row centered={true} vCenter={true}>
                <BodyBig>"Hey Spinach, create a ticket"</BodyBig>
            </Row>
            {isLocalStage() ? (
                <AvatarTestCreateTicketRow onTestHeySpinachCreateTicket={onTestHeySpinachCreateTicket} />
            ) : null}
        </Column>
    );
}

export function AvatarTestCreateTicketRow({
    onTestHeySpinachCreateTicket,
}: {
    onTestHeySpinachCreateTicket: () => void;
}): JSX.Element {
    return (
        <Row centered={true} vCenter={true} style={{ marginTop: '10px' }}>
            <OutlinedButton
                title="Test Create Ticket"
                onClick={() => {
                    onTestHeySpinachCreateTicket();
                }}
                size={ButtonSize.Small}
            />
        </Row>
    );
}

export function AvatarUnknownRequestContent(): JSX.Element {
    return (
        <Column style={{ height: '110px' }} vCentered={true}>
            <Row centered={true} vCenter={true}>
                <BodyBig>Sorry, I didn't get that.</BodyBig>
            </Row>
        </Column>
    );
}

export function AvatarMinimizedNotificationHistory({ onCreatedTicket }: { onCreatedTicket: () => void }): JSX.Element {
    const [, setLocalState] = useGlobalLocalAgent();
    const [state] = useGlobalLiveAvatar();
    const firstTaskInHistory = state.avatarTasks[0];
    const firstTaskTitle =
        firstTaskInHistory.createdTicket?.title || firstTaskInHistory.proposedTicket?.ticketTitle || '';
    const firstTaskContext =
        firstTaskInHistory.createdTicket?.description || firstTaskInHistory.proposedTicket?.ticketDescription || '';
    const firstTaskUrl = firstTaskInHistory.createdTicket?.ticketUrl || '';
    const trackClick = useClickTracking();
    const onConfirmCreateTicket = useAgentTicketCreation(onCreatedTicket);

    return (
        <Column centered={true} style={{ overflow: 'hidden', position: 'relative' }}>
            <div style={{ height: '1px', borderTop: '1px solid white', width: '90%' }}></div>

            <Spacing />

            <OutlinedButton
                title="View history"
                endIcon={
                    <ChevronLeft style={{ transform: 'rotate(90deg)' }} htmlColor={lightTheme.primary.orangeDark} />
                }
                onClick={() => {
                    trackClick(ClientEventType.AgentUxClick, 'Expanded History');
                    setLocalState((s) => ({
                        ...s,
                        isNotificationCenterExpanded: true,
                    }));
                }}
                containerStyles={{ paddingLeft: '15px', paddingRight: '15px' }}
                size={ButtonSize.Small}
                fontColor={lightTheme.primary.orangeDark}
                color={lightTheme.primary.orangeDark}
                labelStyles={{ alignItems: 'center' }}
            />

            <Spacing factor={2} />

            <Row centered={true} style={{ position: 'absolute', transform: 'translateY(70px)' }}>
                {firstTaskInHistory.createdTicket?.title ? (
                    <AvatarCreatedTicket title={firstTaskTitle} context={firstTaskContext} url={firstTaskUrl} />
                ) : firstTaskInHistory.proposedTicket?.ticketTitle ? (
                    <AvatarProposedTicketRow
                        task={firstTaskInHistory}
                        buttonLabel={'Create Ticket'}
                        proposedTicket={firstTaskInHistory.proposedTicket}
                        // this is unclickable and out of view
                        onCreateTicket={onConfirmCreateTicket(firstTaskInHistory)}
                    />
                ) : null}
            </Row>
        </Column>
    );
}

export function AvatarExpandedNotificationHistory({ onCreatedTicket }: { onCreatedTicket: () => void }): JSX.Element {
    const [, setLocalAgent] = useGlobalLocalAgent();
    const [state] = useGlobalLiveAvatar();
    const trackClick = useClickTracking();
    const tasks = state.avatarTasks;
    const tasksWithCreatedTickets = tasks.filter((t) => !!t.createdTicket?.title).filter((t) => !!t);
    const tasksWithUncreatedTickets = tasks.filter((t) => !t.createdTicket && !!t.proposedTicket).filter((t) => !!t);
    const onTicketCreate = useAgentTicketCreation(onCreatedTicket);
    return (
        <Column centered={true} style={{ overflowY: 'auto', maxHeight: '420px' }}>
            <div style={{ width: '80%', height: '1px', borderTop: '1px solid white' }}></div>

            <Spacing />

            <OutlinedButton
                title="Minimize"
                endIcon={
                    <ChevronLeft style={{ transform: 'rotate(-90deg)' }} htmlColor={lightTheme.primary.orangeDark} />
                }
                onClick={() => {
                    trackClick(ClientEventType.AgentUxClick, 'Collapse History');
                    setLocalAgent((s) => ({
                        ...s,
                        isNotificationCenterExpanded: false,
                    }));
                }}
                containerStyles={{ paddingLeft: '15px', paddingRight: '15px' }}
                size={ButtonSize.Small}
                fontColor={lightTheme.primary.orangeDark}
                color={lightTheme.primary.orangeDark}
                labelStyles={{ alignItems: 'center' }}
            />

            <Spacing factor={1 / 2} />

            <ScrollArea sidePadding={0} style={{ background: 'unset' }} stretch={true}>
                {tasksWithCreatedTickets.length ? (
                    <>
                        <BodyBig>
                            <b>Tickets created</b>
                        </BodyBig>
                        <Spacing factor={1 / 2} />
                        {tasksWithCreatedTickets.map((ticket, i) => (
                            <Row centered={true} key={i} style={{ marginBottom: '15px' }}>
                                <AvatarCreatedTicket
                                    title={ticket.createdTicket?.title || ''}
                                    context={ticket.createdTicket?.description || ''}
                                    url={ticket.createdTicket?.ticketUrl || ''}
                                />
                            </Row>
                        ))}
                    </>
                ) : null}

                {tasksWithUncreatedTickets.length ? (
                    <>
                        <BodyBig>
                            <b>Pending creation</b>
                        </BodyBig>
                        <Spacing factor={1 / 2} />
                        {tasksWithUncreatedTickets.map((ticket, i) => (
                            <Row centered={true} key={i} style={{ marginBottom: '15px' }}>
                                <AvatarProposedTicketRow
                                    task={ticket}
                                    proposedTicket={ticket.proposedTicket!}
                                    onCreateTicket={onTicketCreate(ticket)}
                                    buttonLabel={'Create ticket'}
                                />
                            </Row>
                        ))}
                    </>
                ) : null}
            </ScrollArea>
        </Column>
    );
}

export function AvatarListeningContent(): JSX.Element {
    return (
        <Column style={{ height: '220px', flexShrink: 0 }} vCentered={true}>
            <Row centered={true}>
                <div style={{ width: '20%' }}>
                    <LinearProgress variant={'indeterminate'} />
                    <Spacing factor={1 / 2} />
                </div>
            </Row>
            <Row centered={true}>
                <BodyBig>
                    <b>I'm listening...</b>
                </BodyBig>
            </Row>
        </Column>
    );
}

export function AvatarLoadingTaskContent(): JSX.Element {
    return (
        <Column vCentered={true} style={{ height: '220px ' }} centered={true}>
            <Row centered={true}>
                <b>Working on it...</b>
            </Row>
            <Spacing factor={1 / 2} />
            <Row centered={true}>
                <AvatarPlaceholderTask />
            </Row>
        </Column>
    );
}

const Task = styled.div`
    display: flex;
    padding: 14px 68px 13.444px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;

    border-radius: 5.877px;
    border: 1.204px solid var(--White, #fff);
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4.408px 16.162px 2.204px rgba(0, 0, 0, 0.1);
`;

const LoadingTextPlaceholder = styled.div<{ delayFactor?: number }>`
    border-radius: 4px;
    background: var(--White, #fff);
    width: 214px;
    height: 14px;

    @keyframes dimming {
        0%,
        100% {
            opacity: 1;
        }
        50% {
            opacity: 0.3; /* The component dims to 30% of its opacity at the midpoint */
        }
    }

    animation dimming 2s infinite;
    animation-delay: ${(props) => (props.delayFactor ? `${props.delayFactor * 500}ms` : undefined)};
`;

export function AvatarPlaceholderTask(): JSX.Element {
    return (
        <Task>
            <LoadingTextPlaceholder />
            <LoadingTextPlaceholder style={{ width: '175px' }} delayFactor={1} />
        </Task>
    );
}

const ProposedTicketContainer = styled.div`
    display: flex;
    width: 334px;
    padding: 11.754px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    border-radius: 5.877px;
    border: 1.204px solid var(--White, #fff);
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4.408px 16.162px 2.204px rgba(0, 0, 0, 0.1);
`;

export function AvatarProposeTicketContent({ onCreatedTicket }: { onCreatedTicket: () => void }): JSX.Element {
    const [state] = useGlobalLiveAvatar();
    const [user] = useGlobalAuthedUser();
    const { openIntegrationsSettings } = useGlobalMeetingSettings();
    const ticketTasks = state.avatarTasks.filter(
        (at) => at.taskKind === AvatarTaskKind.CreateTicket && !!at.proposedTicket
    );
    const mostRecentTicket = ticketTasks[ticketTasks.length - 1];

    const proposedTicket = mostRecentTicket?.proposedTicket;
    const onClickCreateTicket = useAgentTicketCreation(onCreatedTicket);

    let title = 'Would you like me to create this ticket?';
    let onClick = onClickCreateTicket(mostRecentTicket);
    let buttonLabel = 'Create Ticket';

    if (!user.isAuthedForJira) {
        title = 'Connect to Jira to create this ticket';
        buttonLabel = 'Connect to Jira';
        onClick = async function () {
            openIntegrationsSettings();
        };
    }

    return (
        <Column vCentered={true} style={{ height: '250px' }}>
            <Row centered={true}>
                <BodyBig>{title}</BodyBig>
            </Row>
            <Spacing factor={1 / 2} />
            <Row centered={true}>
                {proposedTicket ? (
                    <AvatarProposedTicketRow
                        proposedTicket={proposedTicket}
                        task={mostRecentTicket}
                        buttonLabel={buttonLabel}
                        onCreateTicket={onClick}
                    />
                ) : null}
            </Row>
        </Column>
    );
}

export function useAgentTicketCreation(onCreatedTicket: () => void) {
    const [state] = useGlobalLiveAvatar();
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useGlobalLiveSeries();
    const [socket] = useGlobalMeetingSocket();
    const trackClick = useClickTracking();
    const trackActivity = useActivityTracking();

    return (taskWithProposedTicket: AvatarTask) => async (updatedTitle: string, updatedContext: string) => {
        const proposedTicket = taskWithProposedTicket.proposedTicket;
        if (!proposedTicket) {
            return;
        }

        trackClick(ClientEventType.AgentUxClick, 'Accepted Create Ticket');

        const createdTicket = await postCreateTicket({
            title: updatedTitle,
            description: updatedContext,
        });

        if (!createdTicket) {
            return;
        }

        const createdTaskPayload = createWebsocketPayload<AvatarTaskSocketRequest>({
            seriesSlug: liveSeries.slug,
            meetingId: liveSeries.currentMeeting.id,
            task: {
                id: taskWithProposedTicket.id,
                taskKind: AvatarTaskKind.CreateTicket,
                commanderUserId: user.spinachUserId,
                meetingId: liveSeries.currentMeeting.id || '',
                proposedTicket: {
                    ...proposedTicket,
                    ticketSource: TICKET_SOURCE_MAP.Jira,
                },
                createdTicket: {
                    id: createdTicket.id,
                    jiraAccountUrl: createdTicket.jiraAccountUrl,
                    ticketUrl: createdTicket.ticketUrl,
                    displayName: proposedTicket.ticketTitle,
                    title: proposedTicket.ticketTitle,
                    description: proposedTicket.ticketDescription ?? '',
                    ticketSource: TICKET_SOURCE_MAP.Jira,
                },
                seriesId: liveSeries.slug,
                botId: state.botId,
                transcriptChunkBeforeCommand: [],
                transcriptChunkAfterCommand: [],
            },
        });

        socket?.emit(ClientSocketEvent.AvatarCreateTicket, createdTaskPayload);
        onCreatedTicket();
        trackActivity(ClientEventType.AgentUxActivity, 'Ticket Created Successfully');
    };
}

export type AvatarProposedTicketRowProps = {
    task: AvatarTask;
    proposedTicket: AvatarProposedTicket;
    buttonLabel: string;
    onCreateTicket: ((updatedTitle: string, updatedContext: string) => Promise<void>) | null;
};

export function AvatarProposedTicketRow({
    task,
    proposedTicket,
    buttonLabel,
    onCreateTicket,
}: AvatarProposedTicketRowProps): JSX.Element {
    const [avatar, setAvatar] = useGlobalLiveAvatar();
    const trackClick = useClickTracking();
    const [isLoading, setIsLoading] = useState(false);
    const [isEditingContext, setIsEditingContext] = useState(false);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const classes = useSpinachNewInputStyles({
        value: '',
    });
    const editedContext = avatar.avatarTasks.find((t) => t.id === task.id)?.proposedTicket?.ticketDescription || '';
    const editedTitle = avatar.avatarTasks.find((t) => t.id === task.id)?.proposedTicket?.ticketTitle || '';

    const [proposingTicketTitle, setProposingTicketTitle] = useState(editedTitle);
    const [proposingTicketContext, setProposingTicketContext] = useState(editedContext);
    // should be editing the proposed ticket task in global avatar state
    return (
        <ProposedTicketContainer>
            {isEditingTitle ? (
                <TextField
                    InputProps={{
                        classes: { root: classes.base },
                        style: { fontWeight: 'bold', letterSpacing: '0.3px' },
                    }}
                    inputProps={{ style: { textAlign: 'center' } }}
                    multiline={false}
                    style={{ marginTop: '-8px', marginBottom: '-8px' }}
                    {...withContentMasking(classes.root)}
                    fullWidth
                    value={proposingTicketTitle}
                    autoFocus={true}
                    onChange={(e) => {
                        if (e.target.value.includes('\n')) {
                            setIsEditingContext(false);
                            return;
                        }

                        setProposingTicketTitle(e.target.value);
                    }}
                    onBlur={() => {
                        setIsEditingTitle(false);
                    }}
                    placeholder={'Add a ticket title'}
                />
            ) : (
                <BodyBig
                    style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        width: '100%',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        trackClick(ClientEventType.AgentUxClick, 'Edit Proposed Ticket Title');
                        setIsEditingTitle(!isEditingTitle);
                    }}
                >
                    <b>{proposingTicketTitle}</b>
                </BodyBig>
            )}
            {isEditingContext ? (
                <TextField
                    InputProps={{ classes: { root: classes.base }, style: { letterSpacing: '0.3px' } }}
                    style={{ maxHeight: '60px', overflowY: 'scroll' }}
                    multiline={true}
                    {...withContentMasking(classes.root)}
                    fullWidth
                    value={proposingTicketContext}
                    autoFocus={true}
                    onChange={(e) => {
                        if (e.target.value.includes('\n')) {
                            // TODO - tab from here?
                            setIsEditingContext(false);
                            return;
                        }

                        setProposingTicketContext(e.target.value);
                    }}
                    onBlur={(e) => {
                        setIsEditingContext(false);
                    }}
                    placeholder={'Add some context'}
                />
            ) : (
                <BodyBig
                    onClick={() => {
                        trackClick(ClientEventType.AgentUxClick, 'Edit Proposed Ticket Description');
                        setIsEditingContext(!isEditingContext);
                    }}
                    style={{
                        maxHeight: '62px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        width: '100%',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        cursor: 'pointer',
                    }}
                >
                    {proposingTicketContext}
                </BodyBig>
            )}
            <div style={{ width: '100%', height: '1px', borderTop: '1px solid white' }}></div>
            <Row centered={true}>
                <PrimaryButton
                    title={buttonLabel}
                    disabled={onCreateTicket === null}
                    onClick={async () => {
                        if (!onCreateTicket) {
                            return;
                        }

                        setIsLoading(true);
                        await onCreateTicket(proposingTicketTitle, proposingTicketContext);
                        setIsLoading(false);
                    }}
                    size={ButtonSize.Small}
                    buttonStyles={{ width: '70px' }}
                    isLoading={isLoading}
                />
            </Row>
        </ProposedTicketContainer>
    );
}

const CreatedTicketContainer = styled.div`
    display: flex;
    width: 324px;
    padding: 11.754px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4.817px;
    border-radius: 5.877px;
    border: 1.204px solid var(--White, #fff);
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4.408px 16.162px 2.204px rgba(0, 0, 0, 0.1);
`;

export function AvatarTicketCreatedContent(): JSX.Element {
    const [state] = useGlobalLiveAvatar();
    const ticketTasks = state.avatarTasks.filter(
        (at) => at.taskKind === AvatarTaskKind.CreateTicket && !!at.createdTicket
    );
    const mostRecentTicket = ticketTasks[ticketTasks.length - 1];

    return (
        <Column vCentered={true} style={{ height: '220px' }}>
            <Row centered={true}>
                <GreenCheckIcon style={{ width: '18px', height: '18px', marginRight: '16px' }} />

                <BodyBig>
                    <b>Ticket Created</b>
                </BodyBig>
            </Row>
            <Spacing factor={1 / 2} />
            <Row centered={true}>
                {mostRecentTicket?.createdTicket ? (
                    <AvatarCreatedTicket
                        title={mostRecentTicket.createdTicket.title}
                        context={mostRecentTicket.createdTicket.description}
                        url={mostRecentTicket.createdTicket.ticketUrl}
                    />
                ) : (
                    <BodyBig>Something went wrong</BodyBig>
                )}
            </Row>
        </Column>
    );
}

export type CreatedTicketProps = {
    title: string;
    context: string;
    url: string;
};

export function AvatarCreatedTicket({ title, context, url }: CreatedTicketProps): JSX.Element {
    const trackClick = useClickTracking();
    return (
        <CreatedTicketContainer>
            <SecondaryButton
                title={title}
                onClick={() => {
                    trackClick(ClientEventType.AgentUxClick, 'Created Ticket URL');
                    URLUtil.openURL(url);
                }}
            />
            <BodyBig
                style={{
                    maxHeight: '62px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '100%',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                }}
            >
                {context}
            </BodyBig>
        </CreatedTicketContainer>
    );
}
