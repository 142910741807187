import { DemoVersion, GoogleVerificationResponse, SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '../apis';

export async function postVerifyGoogle(
    tokenId: string | undefined,
    experimentCode: string | null,
    deepLinkedSeriesId: string,
    demoVersion?: DemoVersion
): Promise<GoogleVerificationResponse> {
    try {
        const response = await postSpinachAPI<GoogleVerificationResponse>(
            SpinachAPIPath.VerifyGoogle,
            {
                tokenId,
                experimentCode,
                demoVersion,
                deepLinkedSeriesId,
            },
            { throwOnError: true }
        );
        if (!response) {
            return { user: null };
        }
        return response;
    } catch (e: any) {
        return { errorCode: e.response?.data?.code, user: null };
    }
}

export async function postVerifyGoogleWithCode(
    code: string | undefined,
    stateString?: string
): Promise<GoogleVerificationResponse> {
    try {
        const response = await postSpinachAPI<GoogleVerificationResponse>(
            SpinachAPIPath.VerifyGoogleWithCode,
            {
                code,
            },
            {
                throwOnError: true,
                params: { state: stateString },
            }
        );
        if (!response) {
            return { user: null };
        }
        return response;
    } catch (e: any) {
        return { errorCode: e.response?.data?.code, user: null };
    }
}
