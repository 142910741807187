import { useEffect } from 'react';

import { patchUser } from '../..';
import { TagManager } from '../utils/TagManager';
import { useGlobalAuthedUser, useGlobalUser } from './useGlobalUser';

// fun and accurate
export function useTagManagerDestruction(): void {
    const [user] = useGlobalUser();
    useEffect(() => {
        if (user?.metadata.isOnboarded) {
            setTimeout(() => {
                TagManager.destroy();
            }, 5000);
        }
    }, []);
}

export function useTagManager(): void {
    useEffect(() => {
        TagManager.init();
    }, []);
}

export function useTagManagerAuthedTracking(): void {
    const [user] = useGlobalAuthedUser();
    useEffect(() => {
        setTimeout(() => {
            TagManager.trackAuthedUser(user);
        }, 2000);
    }, []);

    useEffect(() => {
        async function exec() {
            if (TagManager.getClientId()) {
                patchUser({
                    metadata: {
                        mostRecentGtmId: TagManager.getClientId(),
                    },
                });
            }
        }
        exec();
    }, []);
}
