import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SearchIcon from '@material-ui/icons/Search';
import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { z } from 'zod';

import { BillingPlanSchema } from '@spinach-shared/schemas';
import { ClientEventType, PaidUserResponse, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../../../../apis';
import { GlobalModal } from '../../../../atoms/atomModal';
import { useExperienceTracking, useGlobalAiDashboard, useGlobalModal } from '../../../../hooks';
import { useGlobalAuthedUser } from '../../../../hooks';
import { ButtonSize } from '../../../../styles';
import { Row } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';

// Add shimmer animation styles
const shimmer = keyframes`
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
`;

const ShimmerBox = styled.div<{ height?: string; margin?: string }>`
    width: 100%;
    height: ${(props) => props.height || '24px'};
    margin: ${(props) => props.margin || '8px 0'};
    background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
    background-size: 2000px 100%;
    animation: ${shimmer} 2s linear infinite;
    border-radius: 4px;
`;

const ShimmerContainer = styled.div`
    width: 100%;
    padding: 16px;
`;

const ShimmerLoadingState = () => (
    <ShimmerContainer>
        <ShimmerBox height="68px" margin="0 0 24px 0" />
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
            <ShimmerBox style={{ width: '30%' }} />
            <ShimmerBox style={{ width: '30%' }} />
            <ShimmerBox style={{ width: '30%' }} />
        </div>
        <ShimmerBox height="48px" margin="0 0 16px 0" />
        <ShimmerBox height="48px" margin="0 0 16px 0" />
        <ShimmerBox height="48px" margin="0 0 16px 0" />
        <ShimmerBox height="48px" margin="0 0 16px 0" />
        <ShimmerBox height="48px" margin="0 0 16px 0" />
        <ShimmerBox height="48px" margin="0 0 16px 0" />
        <ShimmerBox height="48px" margin="0 0 16px 0" />
        <ShimmerBox height="48px" margin="0 0 16px 0" />
        <ShimmerBox height="48px" />
    </ShimmerContainer>
);

// Add styles for the table
const useStyles = makeStyles({
    tableContainer: {
        marginTop: '16px',
        border: '1px solid #E0E0E0',
        borderRadius: '4px',
    },
    headerCell: {
        fontWeight: 600,
        padding: '8px 16px',
    },
    cell: {
        padding: '8px 16px',
    },
    badge: {
        backgroundColor: '#C3E0D7',
        padding: '2px 8px',
        borderRadius: '4px',
        fontSize: '12px',
        marginLeft: '8px',
    },
    usageStats: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '600px',
        marginTop: '24px',
        marginBottom: '24px',
    },
    statBox: {
        textAlign: 'center',
    },
    statNumber: {
        fontSize: '32px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    statLabel: {
        color: '#666',
    },
    sortableHeader: {
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8,
        },
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& svg': {
            marginLeft: '4px',
            fontSize: '18px',
        },
    },
    rightAlignedHeader: {
        justifyContent: 'flex-end',
    },
    userEmail: {
        color: '#666',
        display: 'block',
        fontSize: '14px',
        marginTop: '2px',
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        marginTop: '16px',
        marginBottom: '24px',
    },
    paginationButton: {
        padding: '8px 16px',
        borderRadius: '4px',
        border: '1px solid #E0E0E0',
        backgroundColor: 'white',
        cursor: 'pointer',
        '&:disabled': {
            opacity: 0.5,
            cursor: 'not-allowed',
        },
        '&:hover:not(:disabled)': {
            backgroundColor: '#f5f5f5',
        },
    },
    userName: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
    },
    tableRow: {
        '& td': {
            borderBottom: 'none',
        },
    },
    headerRow: {
        '& th': {
            borderBottom: 'none',
        },
    },
    searchWrapper: {
        marginBottom: '4px',
    },
    searchField: {
        width: '250px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            height: '32px',
            '& input': {
                padding: '4px 8px',
                fontSize: '13px',
            },
            '& .MuiInputAdornment-root': {
                height: '32px',
                '& svg': {
                    fontSize: '16px',
                },
            },
        },
    },
    noResults: {
        padding: '16px',
        textAlign: 'center',
        color: '#666',
    },
});

type SortField = 'name' | 'usage';
type SortDirection = 'asc' | 'desc';

export const PaidUsersTable = () => {
    const [paidUsers, setPaidUsers] = useState<PaidUserResponse>([]);
    const [billingData, setBillingData] = useState<z.infer<typeof BillingPlanSchema> | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { setToastText } = useGlobalAiDashboard();
    const classes = useStyles();
    const [user] = useGlobalAuthedUser();
    const [sortField, setSortField] = useState<SortField>('usage');
    const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
    const [currentPage, setCurrentPage] = useState(0);
    const USERS_PER_PAGE = 10;
    const [searchQuery, setSearchQuery] = useState('');
    const track = useExperienceTracking();
    const handleSort = (field: SortField) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('desc');
        }
        setCurrentPage(0); // Reset to first page when sorting
    };

    const filteredAndSortedUsers = [...paidUsers]
        .filter((user) => {
            if (!searchQuery) {
                return true;
            }
            const searchLower = searchQuery.toLowerCase();
            return user.name.toLowerCase().includes(searchLower) || user.email.toLowerCase().includes(searchLower);
        })
        .sort((a, b) => {
            const multiplier = sortDirection === 'asc' ? 1 : -1;

            if (sortField === 'name') {
                return multiplier * a.name.localeCompare(b.name);
            } else {
                const usageA = a.usageInSeconds || 0;
                const usageB = b.usageInSeconds || 0;
                return multiplier * (usageA - usageB);
            }
        });

    const paginatedUsers = filteredAndSortedUsers.slice(
        currentPage * USERS_PER_PAGE,
        (currentPage + 1) * USERS_PER_PAGE
    );

    const totalPages = Math.ceil(filteredAndSortedUsers.length / USERS_PER_PAGE);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setCurrentPage(0); // Reset to first page when searching
    };

    const [modal, setGlobalModal] = useGlobalModal();
    const fetchData = async () => {
        try {
            const [usersData, billingResponse] = await Promise.all([
                getSpinachAPI<PaidUserResponse>(SpinachAPIPath.GetPaidUsers, { throwOnError: true }),
                getSpinachAPI(SpinachAPIPath.UserBilling, { throwOnError: true }),
            ]);

            setPaidUsers(usersData!);
            setBillingData(BillingPlanSchema.parse(billingResponse));
        } catch (e) {
            Sentry.captureException(e);
            setToastText('Error fetching data. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!modal) {
            fetchData();
        }
    }, [modal]);

    if (isLoading || !billingData) {
        return <ShimmerLoadingState />;
    }

    return (
        <>
            <div className={classes.usageStats}>
                <div className={classes.statBox}>
                    <div className={classes.statNumber}>{paidUsers.length}</div>
                    <div className={classes.statLabel}>Team size</div>
                </div>
                <div className={classes.statBox}>
                    <div className={classes.statNumber}>
                        {(billingData.latestUsageForPeriodInMinutes / 60).toFixed(2)}
                    </div>
                    <div className={classes.statLabel}>
                        {user.metadata.teamSubscriptionPayerId ? 'Plan' : 'Account'} usage (hrs)
                    </div>
                </div>
                <div className={classes.statBox}>
                    <div className={classes.statNumber}>
                        {(billingData.latestUsageForPeriodInMinutesForUser / 60).toFixed(2)}
                    </div>
                    <div className={classes.statLabel}>Your usage (hrs)</div>
                </div>
            </div>

            <div className={classes.searchWrapper}>
                <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <TextField
                        placeholder="Search by name or email..."
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className={classes.searchField}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <OutlinedButton
                        title="Add team member"
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Add Team Member',
                            });
                            setGlobalModal(GlobalModal.AddTeamMember);
                        }}
                        preIcon={<AddIcon style={{ marginRight: '4px' }} />}
                        size={ButtonSize.Mini}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '4px',
                        }}
                    />
                </Row>
            </div>

            <div className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            <TableCell
                                className={`${classes.headerCell} ${classes.sortableHeader}`}
                                onClick={() => handleSort('name')}
                            >
                                <div className={classes.headerContent}>
                                    Name
                                    {sortField === 'name' ? (
                                        sortDirection === 'asc' ? (
                                            <ArrowUpwardIcon />
                                        ) : (
                                            <ArrowDownwardIcon />
                                        )
                                    ) : null}
                                </div>
                            </TableCell>
                            <TableCell
                                className={`${classes.headerCell} ${classes.sortableHeader}`}
                                align="right"
                                onClick={() => handleSort('usage')}
                            >
                                <div className={`${classes.headerContent} ${classes.rightAlignedHeader}`}>
                                    Usage
                                    {sortField === 'usage' ? (
                                        sortDirection === 'asc' ? (
                                            <ArrowUpwardIcon />
                                        ) : (
                                            <ArrowDownwardIcon />
                                        )
                                    ) : null}
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedUsers.length > 0 ? (
                            paginatedUsers.map((user) => (
                                <TableRow key={user.email} className={classes.tableRow}>
                                    <TableCell className={classes.cell}>
                                        <div className={classes.userName}>
                                            {user.name}
                                            {user.isPayer ? <span className={classes.badge}>Payer</span> : null}
                                            {user.isPending ? <span className={classes.badge}>Pending</span> : null}
                                        </div>
                                        <span className={classes.userEmail}>({user.email})</span>
                                    </TableCell>
                                    <TableCell className={classes.cell} align="right">
                                        {user.usageInSeconds ? `${(user.usageInSeconds / 3600).toFixed(1)} hrs` : '-'}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={2} className={classes.noResults}>
                                    No users found matching your search
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>

            {totalPages > 1 ? (
                <div className={classes.paginationContainer}>
                    <OutlinedButton
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 0}
                        title="Previous"
                    />
                    <OutlinedButton
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages - 1}
                        title="Next"
                    />
                </div>
            ) : null}
        </>
    );
};
