import React, { useCallback } from 'react';

import { getCommandContents, getMockPreviousContext } from '@spinach-shared/models';

import { patchVideoAgentSession } from '../../../..';
import { AgentCommandType, SayFunction } from '../../../types/agent';
import { useGlobalVideoAgent } from '../useGlobalVideoAgent';

export function isCommandPreviousContext(message: string): boolean {
    const validCommands = ['context'];
    const { commandMatched: isContext } = getCommandContents(message, validCommands);

    return !!isContext;
}

export function useDemoPreviousContext(say: SayFunction) {
    const { session, setSession } = useGlobalVideoAgent();

    /**
     * take name of current title and spoof context into session
     */
    return useCallback(
        async (triggerMedium = AgentCommandType.Voice) => {
            const topicTitle = session?.currentTopic?.title;
            if (topicTitle) {
                const previousContext = getMockPreviousContext(topicTitle);
                const updatedSession = session.withAddedContext(previousContext);
                setSession(updatedSession);
                await patchVideoAgentSession(updatedSession.toJSON());
            }
        },
        [session, setSession]
    );
}
