import { useState } from 'react';
import { Socket } from 'socket.io-client';
import styled from 'styled-components';

import { YTBAgendaItemProps } from '@spinach-shared/models';
import { SpinachUpdateType, TimeInSeconds, UpdateSectionTypeProps } from '@spinach-shared/types';

import { ElementId } from '../../constants';
import {
    useGlobalDemoState,
    useGlobalLiveSeries,
    useLiveReservedUpdates,
    useLiveSyncedFocus,
    useSeriesReality,
} from '../../hooks';
import { OverlayHint } from '../common';
import { LiveUpdateViewProps, TypedUpdates, UpdateSectionTitle } from './LiveUpdateSection';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 30px;
    flex-shrink: 0;
`;

export type LiveReservedUpdateProps = {
    typeProps: UpdateSectionTypeProps;
    autoFocusNewInputOnMountAfter?: TimeInSeconds;
    id?: ElementId;
    isInNavDrawer?: boolean;
    socket: Socket;
    agendaItem: YTBAgendaItemProps;
    isPresentingSection?: boolean;
};

export function LiveReservedUpdateSection({
    typeProps,
    autoFocusNewInputOnMountAfter,
    isInNavDrawer,
    id,
    socket,
    agendaItem,
    isPresentingSection = false,
}: LiveReservedUpdateProps): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();
    const { focusedFeature } = useGlobalDemoState();
    const { isDemoSeries } = useSeriesReality();
    const [focusedInputIndex, setFocusedInputIndex] = useState<number | null>(null);
    const { typedUpdates, setTypedUpdates, createFullUpdateEmitter, createReorderEmitter, createUpdateEmitter } =
        useLiveReservedUpdates(socket, agendaItem.id, typeProps.spinachUpdateType);

    const updateViewProps: LiveUpdateViewProps = {
        createFullUpdateEmitter,
        createUpdateEmitter,
        createReorderEmitter,
        standUpUpdate: agendaItem.standUpUpdate,
    };

    useLiveSyncedFocus(
        typedUpdates,
        focusedInputIndex,
        setFocusedInputIndex,
        updateViewProps.standUpUpdate.getUpdatesForType(typeProps.spinachUpdateType)
    );

    const isSectionEmpty = agendaItem.standUpUpdate.getUpdatesForType(typeProps.spinachUpdateType).length === 0;
    const shouldHideEmptyPresentingSection = isPresentingSection && isSectionEmpty;

    if (
        !liveSeries.isComponentEnabled(typeProps.spinachUpdateType) &&
        typeProps.spinachUpdateType !== SpinachUpdateType.Icebreaker
    ) {
        return <></>;
    }

    const isSectionHighlightedForDemo =
        typeProps.spinachUpdateType === SpinachUpdateType.Today &&
        focusedFeature === ElementId.TodaySection &&
        isDemoSeries &&
        typedUpdates.length <= 1 &&
        typedUpdates?.[0]?.text?.length === 0;

    return (
        <Container id={id}>
            {isSectionHighlightedForDemo ? (
                <OverlayHint
                    style={{ marginTop: '-10px', marginLeft: '-5px' }}
                    extraWidth={15}
                    idOfElement={ElementId.TodaySection}
                />
            ) : null}

            <UpdateSectionTitle typeProps={typeProps} isPresentingAndEmpty={shouldHideEmptyPresentingSection} />

            {shouldHideEmptyPresentingSection ? null : (
                <TypedUpdates
                    typedUpdates={typedUpdates}
                    setTypedUpdates={setTypedUpdates}
                    typeProps={typeProps}
                    isInNavDrawer={isInNavDrawer}
                    updateViewProps={updateViewProps}
                    focusedInputIndex={focusedInputIndex}
                    setFocusedInputIndex={setFocusedInputIndex}
                    autoFocusNewInputOnMountAfter={autoFocusNewInputOnMountAfter}
                />
            )}
        </Container>
    );
}
