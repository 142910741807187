import { atom } from 'recoil';

import { DetailedTicket, Ticket } from '@spinach-shared/types';

export const recentJiraIssuesAtom = atom<Ticket[] | null>({
    key: 'recentJiraIssuesAtom', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

export const detailedJiraIssuesAtom = atom<DetailedTicket[] | null>({
    key: 'detailedJiraIssuesAtom', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});
