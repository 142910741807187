import { MeetingActionItem, SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '..';

export async function postEditSummaryActionItem(request: {
    actionItem: MeetingActionItem;
    botId: string;
}): Promise<{ success: boolean }> {
    try {
        const response = await postSpinachAPI<{ success: boolean }>(SpinachAPIPath.EditSummaryActionItem, request);
        if (!response) {
            return { success: false };
        }
        return response;
    } catch {
        return { success: false };
    }
}
