import { MeetingSettingsSubview } from '@spinach-shared/types';

import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { AsanaIntegrationSettings } from './AsanaIntegrationSettings';
import { JiraIntegrationSettingsModal } from './JiraIntegrationSettings';
import { SlackIntegrationSettingsModal } from './SlackIntegrationSettings';

export function IntegrationSettingsSubview(): JSX.Element {
    const { subview } = useGlobalMeetingSettings();

    switch (subview) {
        case MeetingSettingsSubview.JiraSettings:
            return <JiraIntegrationSettingsModal />;
        case MeetingSettingsSubview.AsanaSettings:
            return <AsanaIntegrationSettings />;
        case MeetingSettingsSubview.SlackSettings:
            return <SlackIntegrationSettingsModal />;
        default:
            return <></>;
    }
}
