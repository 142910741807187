import React from 'react';
import styled from 'styled-components';

import { sectionTypeList } from '@spinach-shared/constants';
import { BaseMeetingProps, SpinachSeriesProps, YTBAgendaItemProps } from '@spinach-shared/models';
import {
    AuthoredUpdate,
    FeatureToggle,
    MeetingStatus,
    SentimentPickerLocation,
    TICKET_SOURCE_MAP,
    Ticket,
    TicketSource,
    TypedUpdate,
    UserMood,
} from '@spinach-shared/types';
import {
    doesUpdateSectionHaveTypedUpdates,
    formatTime,
    getFormattedDate,
    getTicketSource,
} from '@spinach-shared/utils';

import { ElementId } from '../../../../../constants';
import { useGlobalAuthedUser, useTypedUpdateWrapper } from '../../../../../hooks';
import { BodyLarge, BodyRegular, BodySubtitle, withSelectionPrevention } from '../../../../../styles';
import { AttachmentProps } from '../../../../../types/StandUp';
import { withContentMasking } from '../../../../../utils/withContentMasking';
import { BootstrapTooltip, Bullet, BulletMark, Column, Row, Spacing } from '../../../../common';
import { AsanaPreviewContainer } from '../../../../input';
import { SentimentBullet } from '../../../../input/LiveItemBullet';
import { Reactions } from '../../../../input/Reaction';
import { SentimentComponent } from '../../../../input/SentimentPicker';
import { ParkingLotItemsByParticipant } from '../../../../stand-up';
import { BlurredText } from '../../../../stand-up/BlurredText';
import { LiveItemSentimentDetails } from '../../../../stand-up/LiveItemSentimentDetails';

const SummarySubtitle = styled(BodySubtitle)`
    ${withSelectionPrevention()}
`;

const TeamSentimentContainer = styled.div`
    flex-direction: column;
    background-color: #cde7fc1a;
    text-align: start;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const SentimentContainer = styled.div`
    padding: 3px;
    margin-bottom: 8px;
    border: 1px solid ${(props) => props.theme.neutrals.grayDark};
`;

const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    background-color: ${(props) => props.theme.neutrals.offWhite};
    width: 100%;
    flex-shrink: 0;
`;

const TeammateNameRow = styled.div`
    ${withSelectionPrevention()};
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

const TeammatesTotalTalkTime = styled.div`
    ${withSelectionPrevention()};
    font-size: 14px;
    font-weight: normal;
    padding-top: 3px;
`;

const UPDATE_OFFSET = 0;

const UpdateRowContainer = styled.div`
    background-color: #fafafa;
    padding-top: 2px;
    margin-top: 5px;
    margin-bottom: 8px;
    padding-bottom: 8px;
`;
const UpdateRow = styled.div<{ isTabbedLine?: boolean }>`
    display: flex;
    flex-direction: row;
    margin-left: ${UPDATE_OFFSET}px;
    font-size: 14px;
    margin-bottom: 2px;
    margin-top: 5px;
    align-items: center;
    width: ${(props) => (props.isTabbedLine ? `calc(100% - ${UPDATE_OFFSET}px)` : '100%')};
    padding-left: 0px;
`;

const CONTENT_OFFSET_FROM_BULLET = 4;
const UpdateRowContent = styled.div`
    margin-left: ${CONTENT_OFFSET_FROM_BULLET}px;
    width: calc(100% - ${CONTENT_OFFSET_FROM_BULLET}px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
`;

const Author = styled(BodyRegular)`
    font-weight: 700;
    font-size: 12px;
    line-height: 115%;
    flex-direction: row;
    align-items: center;
    color: ${(props) => props.theme.secondary.midnight};
    border: 1px solid #ebf4ff;
    border-radius: 2px;
    margin-top: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 2px;
    padding-right: 8px;
    background-color: #e0eeff;
`;

export function ReadOnlyUpdateRow({
    text,
    isTabbedLine,
    isAsanaEnabled,
    isJiraEnabled,
    update,
    ticket,
    ticketSource,
    isSentimentDetails,
}: {
    text: string | JSX.Element;
    isTabbedLine?: boolean;
    update?: TypedUpdate;
    isAsanaEnabled: boolean;
    isJiraEnabled: boolean;
    ticket?: Ticket;
    ticketSource?: TicketSource;
    isSentimentDetails?: boolean;
}): JSX.Element {
    const typedUpdateWrapper = update ? useTypedUpdateWrapper(update) : null;

    const isAsanaTask = ticketSource === TICKET_SOURCE_MAP.Asana;

    const shouldShowAsanaPreviewContainer = isAsanaEnabled && ticket && typedUpdateWrapper && isAsanaTask;

    const isTicketItem = Boolean(isJiraEnabled && ticket && typedUpdateWrapper);
    // Ticket should never be undefined if `isTicketItem` is true
    const transformedText =
        isTicketItem && ticket ? `${isAsanaTask ? ticket?.projectName || '' : ticket.id} ${ticket.title}` : text;

    return (
        <>
            <UpdateRow isTabbedLine={isTabbedLine}>
                {(!isTabbedLine && !!typedUpdateWrapper?.sentiment) || isSentimentDetails ? (
                    <></>
                ) : (
                    <BulletMark
                        style={{ marginTop: 'unset', paddingLeft: !isTabbedLine ? '6px' : '0px', alignItems: 'center' }}
                    >
                        <Bullet />
                    </BulletMark>
                )}
                {typedUpdateWrapper?.sentiment && !isTabbedLine && !isSentimentDetails ? (
                    <SentimentBullet sentiment={typedUpdateWrapper.sentiment} />
                ) : null}
                <Row
                    style={{
                        alignContent: 'center',
                    }}
                >
                    {isSentimentDetails && update ? (
                        <LiveItemSentimentDetails typedUpdate={update} labelStyle={{ padding: '0px 3px' }} />
                    ) : (
                        <UpdateRowContent {...withContentMasking()}>{transformedText}</UpdateRowContent>
                    )}
                </Row>
            </UpdateRow>
            {shouldShowAsanaPreviewContainer ? (
                <AsanaPreviewContainer
                    update={typedUpdateWrapper?.typedUpdate}
                    issueData={ticket}
                    interactive={false}
                />
            ) : null}{' '}
        </>
    );
}

function SummaryUpdate({
    update,
    showAuthor,
    isAsanaEnabled,
    isJiraEnabled,
}: {
    update: TypedUpdate;
    showAuthor?: boolean;
    isAsanaEnabled: boolean;
    isJiraEnabled: boolean;
}) {
    const attachmentProps: AttachmentProps = {
        typedUpdate: update,
        saveFullTypedUpdate: () => {},
        isDisabled: true,
    };

    const ticketSource = getTicketSource(update);

    const isAuthoredUpdate = (typedUpdate: TypedUpdate): typedUpdate is AuthoredUpdate => 'author' in typedUpdate;

    return (
        <UpdateRowContainer>
            <ReadOnlyUpdateRow
                isAsanaEnabled={isAsanaEnabled}
                isJiraEnabled={isJiraEnabled}
                key={update.id}
                update={update}
                text={update.text}
                ticket={update.jiraData || update.asanaData || update.ticketData?.ticket}
                ticketSource={ticketSource}
            />
            <Column style={{ paddingLeft: '5px' }}>
                {update.subItems
                    ?.filter((su) => !!su.text)
                    .map((subItem) => (
                        <ReadOnlyUpdateRow
                            isAsanaEnabled={isAsanaEnabled}
                            isJiraEnabled={isJiraEnabled}
                            update={update}
                            key={subItem.id}
                            text={subItem.text}
                            isTabbedLine={true}
                        />
                    ))}
                {update.sentimentDetails ? (
                    <ReadOnlyUpdateRow
                        isAsanaEnabled={isAsanaEnabled}
                        isJiraEnabled={isJiraEnabled}
                        update={update}
                        key={`${update.id}-sentiment-details`}
                        text={update.sentimentDetails}
                        isSentimentDetails={true}
                    />
                ) : (
                    <></>
                )}
                {update.resolvers?.length ? (
                    update.resolvers?.map((resolver) => (
                        <ReadOnlyUpdateRow
                            isAsanaEnabled={isAsanaEnabled}
                            isJiraEnabled={isJiraEnabled}
                            key={`${update.id}-resolver-details`}
                            isTabbedLine={true}
                            update={{ ...update, sentiment: undefined }}
                            text={
                                <p style={{ margin: 'unset' }}>
                                    <b>{resolver.preferredName}</b>: <i>{resolver.details}</i>
                                </p>
                            }
                        />
                    ))
                ) : (
                    <></>
                )}
                <Row>
                    {showAuthor && isAuthoredUpdate(update) ? <Author>{update.author}</Author> : <></>}
                    <Reactions {...attachmentProps} />
                </Row>
            </Column>
        </UpdateRowContainer>
    );
}

function UpdatesByType({
    item,
    spinachSeries,
    isAsanaEnabled,
    isJiraEnabled,
}: {
    item: YTBAgendaItemProps;
    spinachSeries: SpinachSeriesProps;
    isAsanaEnabled: boolean;
    isJiraEnabled: boolean;
}): JSX.Element {
    const isIssueBasedEnabled = !!spinachSeries.featureFlags?.[FeatureToggle.IssueBased];

    const customLists = spinachSeries.enabledCustomRoundtableLists;

    const isUpdateSectionPopulated = doesUpdateSectionHaveTypedUpdates(item.standUpUpdate);
    const areReservedListsEmpty = !sectionTypeList.some(isUpdateSectionPopulated);
    const isIssueBasedCheckinEmpty = isIssueBasedEnabled && item.standUpUpdate.hasNoIssueBasedUpdates;

    return areReservedListsEmpty || isIssueBasedCheckinEmpty ? (
        item.isParticipantAgendaItem ? (
            <ReadOnlyUpdateRow
                isAsanaEnabled={isAsanaEnabled}
                isJiraEnabled={isJiraEnabled}
                text={`No updates today`}
            />
        ) : (
            <></>
        )
    ) : (
        <>
            {sectionTypeList
                .filter(isUpdateSectionPopulated)
                .filter((typeProps) => spinachSeries.isComponentEnabled(typeProps.spinachUpdateType))
                .map((typeProps) => {
                    const updatesOfSection = item.standUpUpdate.getUpdatesForType(typeProps.spinachUpdateType);
                    return (
                        <div style={{ width: '100%' }} key={typeProps.spinachUpdateType}>
                            <Spacing factor={1 / 3} />
                            <SummarySubtitle>{typeProps.title ?? ''}</SummarySubtitle>

                            {updatesOfSection.map((update) => (
                                <SummaryUpdate
                                    isAsanaEnabled={isAsanaEnabled}
                                    isJiraEnabled={isJiraEnabled}
                                    update={update}
                                />
                            ))}
                        </div>
                    );
                })}

            {customLists
                .filter((l) => item.standUpUpdate.getCustomUpdatesForList(l.id).length)
                .map((list) => {
                    return (
                        <div style={{ width: '100%' }} key={list.id}>
                            <Spacing factor={1 / 3} />
                            <SummarySubtitle>{list.title}</SummarySubtitle>

                            {item.standUpUpdate.getCustomUpdatesForList(list.id).map((update) => (
                                <SummaryUpdate
                                    isAsanaEnabled={isAsanaEnabled}
                                    isJiraEnabled={isJiraEnabled}
                                    update={update}
                                />
                            ))}
                        </div>
                    );
                })}
        </>
    );
}

const Moods = ({ userMoods, meetingStatus }: { userMoods: UserMood[]; meetingStatus: MeetingStatus }) => {
    const [user] = useGlobalAuthedUser();

    const thisUserMood = userMoods.find((userMood) => userMood.spinachUserId === user.spinachUserId);
    const userMoodsWithSentiment = userMoods.filter((userMood) => !!userMood.sentiment);
    return (
        <SentimentContainer>
            <BodyRegular style={{ paddingBottom: '3px' }}>Team Mood</BodyRegular>
            <TeamSentimentContainer>
                <Column>
                    {!thisUserMood?.sentiment && meetingStatus !== MeetingStatus.AgendaComplete ? (
                        <BodyRegular>Share your mood to see results</BodyRegular>
                    ) : null}
                    {userMoodsWithSentiment.length ? (
                        userMoodsWithSentiment.map((userMood) =>
                            (thisUserMood?.sentiment ||
                                meetingStatus === MeetingStatus.AgendaComplete ||
                                meetingStatus === MeetingStatus.MeetingComplete) &&
                            userMood.sentiment ? (
                                <Row key={`user-mood-${userMood.spinachUserId}`} style={{ alignItems: 'center' }}>
                                    <BodyRegular
                                        style={{ wordBreak: 'break-word', display: 'flex', flexDirection: 'row' }}
                                    >
                                        <SentimentComponent
                                            location={SentimentPickerLocation.TeamMood}
                                            sentiment={userMood.sentiment}
                                            selectedSentiment={userMood.sentiment}
                                        />
                                        {`${userMood.displayName} ${userMood.details ? `- ${userMood.details}` : ''}`}
                                    </BodyRegular>
                                </Row>
                            ) : userMood.sentiment ? (
                                <BlurredText key={`user-mood-${userMood.spinachUserId}`} title={userMood.displayName} />
                            ) : null
                        )
                    ) : (
                        <BodyRegular>No one has shared their mood yet</BodyRegular>
                    )}
                </Column>
            </TeamSentimentContainer>
        </SentimentContainer>
    );
};

const StandupSummaryContainer = styled.div`
    ${withSelectionPrevention()};
`;

const TopItems = ({
    updates,
    isAsanaEnabled,
    isJiraEnabled,
}: {
    updates: AuthoredUpdate[];
    isAsanaEnabled: boolean;
    isJiraEnabled: boolean;
}): JSX.Element => {
    return updates.length ? (
        <div style={{ width: '100%' }}>
            <TeammateNameRow>
                <BodyLarge>Top Items</BodyLarge>
            </TeammateNameRow>
            <Spacing factor={1 / 3} />

            {updates.map((update) => (
                <SummaryUpdate
                    isAsanaEnabled={isAsanaEnabled}
                    isJiraEnabled={isJiraEnabled}
                    key={`top-item-${update.id}`}
                    showAuthor
                    update={update}
                />
            ))}
        </div>
    ) : (
        <></>
    );
};

export function StandupAppStandupSummary({
    currentMeeting,
    spinachSeries,
    isTeamTopics2Enabled,
    isRecentCheckIn,
    withMood = true,
}: {
    currentMeeting: BaseMeetingProps;
    spinachSeries: SpinachSeriesProps;
    isTeamTopics2Enabled?: boolean;
    isRecentCheckIn?: boolean;
    withMood?: boolean;
}): JSX.Element {
    const agenda = currentMeeting.agenda;
    const isAsyncMeeting = currentMeeting.isAsyncMeeting;
    const { isIcebreakerLockedIn, icebreakerQuestionPrompt } = currentMeeting;
    const teamTopicsItems = isTeamTopics2Enabled ? agenda.topicUpdates : agenda.parkingLotUpdates;
    const isAsanaEnabled = !!spinachSeries.featureFlags?.[FeatureToggle.Asana];
    const isJiraEnabled = !!spinachSeries.featureFlags?.[FeatureToggle.Jira1];

    return (
        <StandupSummaryContainer id={ElementId.SummaryContainer}>
            <TopItems
                isAsanaEnabled={isAsanaEnabled}
                isJiraEnabled={isJiraEnabled}
                updates={agenda.blockerSentimentUpdates}
            />

            {/* NOTE - while team topics 2 is enabled for all, this wasn't passed in, so its misleading */}
            {!isTeamTopics2Enabled && teamTopicsItems?.length ? (
                <SummaryContainer>
                    <TeammateNameRow>
                        <BodyLarge>{spinachSeries.topicsLabel}</BodyLarge>

                        {!isAsyncMeeting && (
                            <TeammatesTotalTalkTime>
                                {formatTime(agenda.parkingLotItem?.totalTime ?? 0)}
                            </TeammatesTotalTalkTime>
                        )}
                    </TeammateNameRow>

                    {!isTeamTopics2Enabled && (
                        <ParkingLotItemsByParticipant
                            updates={
                                isTeamTopics2Enabled
                                    ? agenda.YTBItemsWithTeamTopicUpdates
                                    : agenda.YTBItemsWithParkingLotUpdates
                            }
                            isTeamTopics2Enabled={isTeamTopics2Enabled}
                        />
                    )}

                    <Spacing />
                </SummaryContainer>
            ) : null}

            {agenda.YTBItems.length ? (
                agenda.YTBItems.map((item) => {
                    let author: string | undefined;
                    if (item.isParticipantAgendaItem === false && item.standUpUpdate.spinachUserId) {
                        const agendaItem = agenda.findParticipantsYTBAgendaItemProps(item.standUpUpdate.spinachUserId);
                        author = agendaItem?.title;
                    }
                    const timePxWidth = 80;
                    return (
                        <SummaryContainer key={item.id}>
                            <TeammateNameRow style={{ position: 'relative' }}>
                                {isRecentCheckIn ? (
                                    <BodyLarge style={{ fontSize: '12px' }}>
                                        {getFormattedDate(agenda.startedAt!)}
                                    </BodyLarge>
                                ) : (
                                    <>
                                        <BootstrapTooltip
                                            title={author ? `Author: ${author}` : ''}
                                            placement={'top'}
                                            arrow
                                        >
                                            <Row
                                                style={{
                                                    justifyContent: 'start',
                                                    alignItems: 'center',
                                                    alignSelf: 'start',
                                                    wordBreak: 'break-word',
                                                    width: `calc(100% - ${timePxWidth}px)`,
                                                }}
                                            >
                                                <BodyLarge {...withContentMasking()}>{item.title}</BodyLarge>
                                            </Row>
                                        </BootstrapTooltip>
                                        <Column
                                            style={{ width: `${timePxWidth}px`, flexShrink: 0, alignItems: 'flex-end' }}
                                        >
                                            <TeammatesTotalTalkTime>
                                                {formatTime(item.totalTime)}
                                            </TeammatesTotalTalkTime>
                                        </Column>
                                    </>
                                )}
                            </TeammateNameRow>

                            <Column style={{ paddingLeft: '7px' }}>
                                <UpdatesByType
                                    isAsanaEnabled={isAsanaEnabled}
                                    isJiraEnabled={isJiraEnabled}
                                    item={item}
                                    spinachSeries={spinachSeries}
                                />
                            </Column>

                            <Spacing />
                        </SummaryContainer>
                    );
                })
            ) : (
                <SummaryContainer key={'empty-summary-view'}>
                    <TeammateNameRow style={{ position: 'relative' }}>
                        <BodyRegular>{'There was a scheduled meeting on this day, but no one was there.'}</BodyRegular>
                    </TeammateNameRow>
                </SummaryContainer>
            )}

            {isIcebreakerLockedIn ? (
                <SummaryContainer>
                    <BodyLarge>{icebreakerQuestionPrompt}</BodyLarge>
                    {agenda.YTBItems.map((item) => {
                        if (item.icebreakerUpdates.length) {
                            return (
                                <React.Fragment key={item.id}>
                                    <SummarySubtitle>{item.title}</SummarySubtitle>
                                    {item.icebreakerUpdates.map((update) => (
                                        <ReadOnlyUpdateRow
                                            isAsanaEnabled={isAsanaEnabled}
                                            isJiraEnabled={isJiraEnabled}
                                            key={update.id}
                                            text={update.text}
                                            update={update}
                                        />
                                    ))}
                                </React.Fragment>
                            );
                        } else {
                            return null;
                        }
                    })}
                </SummaryContainer>
            ) : null}
            {withMood ? <Moods userMoods={currentMeeting.userMoods} meetingStatus={currentMeeting.status} /> : null}
        </StandupSummaryContainer>
    );
}
