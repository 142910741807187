import { Modal, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ClientUser } from '@spinach-shared/models';
import {
    ClientEventType,
    DetailedTicket,
    FeatureFlagSet,
    FeatureToggle,
    JiraIssueSelectedPayload,
    JiraIssueSelectionType,
    SpinachUpdateType,
    TICKET_SOURCE_MAP,
    Ticket,
    TypedUpdate,
} from '@spinach-shared/types';

import { getSpinachAPI, postExperienceEvent } from '../../../apis';
import { useGlobalAuthedUser, useGlobalJiraIssues, useGlobalSeriesId } from '../../../hooks';
import { SetValue } from '../../../types';
import { useFetchJiraIssues, withContentMasking } from '../../../utils';
import { CloseButton, Spacer, Spacing } from '../../common';
import { LeftBodyLarge, ModalContent } from '../../series/common';
import { ScrollShadingCSS } from '../../stand-up/ScrollArea';
import { useSpinachInputStyles } from '../BaseInput';
import { JiraDetailsModal } from './JiraDetailsModal';
import { OldJiraPreviewContainer } from './OldJiraPreviewContainer';

const JiraModalContent = styled(ModalContent)`
    min-width: 265px;
    width: 80%;
    max-width: 600px;
`;

const ModalScroll = styled.div`
    overflow-y: scroll;
    max-height: 300px;
    width: 100%;
    ${ScrollShadingCSS};
`;

export function JiraPickerModal({
    typedUpdate,
    saveFullTypedUpdate,
    searchedJiraIssue,
    setSearchedJiraIssue,
    isModalOpen,
    onClose,
}: {
    typedUpdate: TypedUpdate;
    saveFullTypedUpdate?: (update: TypedUpdate) => void;
    searchedJiraIssue: DetailedTicket | null;
    setSearchedJiraIssue: SetValue<Ticket | null>;
    createUpdateEmitter?: (update: TypedUpdate) => (text: string) => void;
    isModalOpen: boolean;
    onClose: () => void;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const seriesId = useGlobalSeriesId();
    const [jiraIssues] = useGlobalJiraIssues();
    const [suggestedJiraIssues, setSuggestedJiraIssues] = useState(jiraIssues);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const classes = useSpinachInputStyles({ value: '', disabled: false });
    const [detailedJiraIssue] = useState<DetailedTicket | null>(null);
    const { fetchJiraIssues } = useFetchJiraIssues();

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchParams = e.target.value;

        if (!searchParams) {
            setSearchedJiraIssue(null);
            setSuggestedJiraIssues(jiraIssues ?? []);
            return;
        }

        const issues = await fetchJiraIssues({
            query: searchParams,
            showSubTasks: true,
            showSubTaskParent: true,
        });

        if (!issues) {
            setSearchedJiraIssue(null);
            setSuggestedJiraIssues(jiraIssues ?? []);
            return;
        }

        // Cleaning HTML off issues
        const cleanedIssues: Ticket[] = await Promise.all(
            issues.map(async (issue) => ({ ...issue, title: issue.title.replace(/(<([^>]+)>)/gi, '') }))
        );

        if (cleanedIssues.length === 1 && cleanedIssues[0].id) {
            setSearchedJiraIssue(cleanedIssues[0]);
        }

        setSearchedJiraIssue(null);
        setSuggestedJiraIssues(cleanedIssues);
    };

    const issuesToMap = searchedJiraIssue ? [searchedJiraIssue] : suggestedJiraIssues;

    return (
        <Modal open={isModalOpen} onClose={onClose}>
            <JiraModalContent>
                <Spacer style={{ height: '40px' }} />

                <CloseButton onClick={onClose} />

                <LeftBodyLarge>Add a Jira Issue</LeftBodyLarge>
                <TextField
                    InputProps={{ classes: { root: classes.base } }}
                    {...withContentMasking(classes.root)}
                    fullWidth
                    autoFocus
                    placeholder="Search by issue number or title"
                    onChange={onChange}
                />

                {!searchedJiraIssue ? (
                    <>
                        <Spacing />
                        <LeftBodyLarge>Or select from recents</LeftBodyLarge>
                        <Spacing factor={1 / 3} />
                    </>
                ) : (
                    <Spacing factor={1 / 3} />
                )}
                <ModalScroll>
                    {issuesToMap?.map((issue) => {
                        const onSelect = async () => {
                            onClose();

                            if (!saveFullTypedUpdate) {
                                return;
                            }

                            saveFullTypedUpdate({
                                ...typedUpdate,
                                jiraData: { ...issue, description: '' },
                                ticketData: {
                                    ...typedUpdate.ticketData,
                                    source: TICKET_SOURCE_MAP.Jira,
                                    ticket: {
                                        ...issue,
                                        description: '',
                                    },
                                },
                                // this triggers a new, empty update to appear
                                text: getHelpfulText(typedUpdate, issue),
                            });

                            if (seriesId) {
                                const selectionType = searchedJiraIssue
                                    ? JiraIssueSelectionType.Linked
                                    : JiraIssueSelectionType.Suggested;
                                sendJiraIssueSelectedAnalytics(user, seriesId, selectionType, user.featureToggles);
                            }

                            // autofocus the input after selecting a task for it
                            setTimeout(() => {
                                const relevantInput = document.getElementById(
                                    `TU-${typedUpdate.id}`
                                ) as HTMLInputElement | null;
                                relevantInput?.focus();

                                if (!typedUpdate.text) {
                                    relevantInput?.select();
                                }
                            }, 300);

                            // TODO: We don't need this API call. We have this data locally already
                            const detailedIssue = await getSpinachAPI<Ticket>(`/jira/issue/${issue.id}`);

                            if (!detailedIssue) {
                                return;
                            }

                            saveFullTypedUpdate({
                                ...typedUpdate,
                                jiraData: { ...detailedIssue, description: '' },
                                ticketData: {
                                    ...typedUpdate.ticketData,
                                    source: TICKET_SOURCE_MAP.Jira,
                                    ticket: { ...detailedIssue, description: '' },
                                },
                                // this triggers a new, empty update to appear
                                text: getHelpfulText(typedUpdate, detailedIssue),
                            });
                        };
                        <OldJiraPreviewContainer
                            key={issue.id}
                            issueData={issue}
                            onClick={onSelect}
                            interactive={false}
                        />;
                    })}
                </ModalScroll>
                {isDetailsModalOpen && detailedJiraIssue && (
                    <JiraDetailsModal
                        isOpen={isDetailsModalOpen}
                        issueData={detailedJiraIssue}
                        onClose={() => setIsDetailsModalOpen(false)}
                    />
                )}
            </JiraModalContent>
        </Modal>
    );
}

function getHelpfulText(typedUpdate: TypedUpdate, ticket: Ticket): string {
    switch (typedUpdate.updateType) {
        case SpinachUpdateType.Today:
            return `Working on ${ticket.id}`;
        case SpinachUpdateType.Yesterday:
            return `Made progress on ${ticket.id}`;
        case SpinachUpdateType.Challenge:
            return `Could use a hand on ${ticket.id}`;
        default:
            return `Let’s talk about ${ticket.id}`;
    }
}

const sendJiraIssueSelectedAnalytics = async (
    user: ClientUser,
    SeriesId: string,
    SelectionType: JiraIssueSelectionType,
    featureToggleMap: FeatureFlagSet<FeatureToggle>
) => {
    const payload: JiraIssueSelectedPayload = {
        ...user.toUserIdentityPayload(),
        UserId: user.spinachUserId,
        SeriesId,
        SelectionType,
        Email: user.email,
        FeatureToggles: featureToggleMap,
        UserName: user.preferredName,
    };

    await postExperienceEvent({ eventType: ClientEventType.JiraIssueSelected, payload });
};
