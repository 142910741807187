import { useEffect, useState } from 'react';

import { fetchCelloJWT } from '../apis/fetchCelloJWT';
import { useGlobalAuthedUser } from './useGlobalUser';

export function useCelloJWT(): [jwt: string | null] {
    const [user] = useGlobalAuthedUser();
    const [jwt, setJWT] = useState<string | null>(null);
    useEffect(() => {
        const fetchJWT = async () => {
            const jwt = await fetchCelloJWT();
            setJWT(jwt);
        };
        if (user && user.isCelloReferralProgramEnabled) {
            fetchJWT();
        }
    }, [user]);

    return [jwt];
}
