import { Menu, MenuItem } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { useState } from 'react';

import { demoBotId } from '@spinach-shared/constants';
import { ClientEventType, ClientUsersWithAiHistoryAccess } from '@spinach-shared/types';

import {
    BodyRegular,
    BodyRegularOnboard,
    BootstrapTooltip,
    GlobalModal,
    GlobalModalMetadataType,
    lightTheme,
    useClickTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useProFeatures,
} from '../../../../..';
import { useGlobalStoredSeriesList } from '../../../../hooks/useGlobalStoredSeriesList';
import { Row } from '../../../common';
import SecondaryButton from '../../../stand-up/SecondaryButton';

type ShareSummaryRowProps = {
    botId: string;
    hostId: string;
    seriesId: string;
    meetingTitle?: string;
    shouldShowDownloadVideo: boolean;
    shouldShowCopyTranscript: boolean;
    usersWithHistoryAccess: ClientUsersWithAiHistoryAccess[];
    onCopySummary: () => void;
    onDownloadVideo: () => void;
    onCopyTranscript: () => void;
};

export function ShareSummaryRow({
    botId,
    meetingTitle,
    seriesId,
    shouldShowDownloadVideo,
    shouldShowCopyTranscript,
    usersWithHistoryAccess,
    onCopySummary,
    onCopyTranscript,
    onDownloadVideo,
    hostId,
}: ShareSummaryRowProps) {
    const [user] = useGlobalAuthedUser();
    const hasProFeatures = useProFeatures();
    const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [, setGlobalModal] = useGlobalModal();
    const trackClick = useClickTracking();
    const {
        storedSeriesListState: { storedSeriesList },
    } = useGlobalStoredSeriesList();
    const selectedSeries = storedSeriesList.find((s) => s.slug === seriesId);
    const spinachHostEmail = selectedSeries
        ? selectedSeries.userMetadataList.find((um) => um._id === selectedSeries.icpId)
        : undefined;
    const spinachHostEmailText = spinachHostEmail ? `(${spinachHostEmail.email}) ` : '';
    const deletionTooltip =
        botId === demoBotId
            ? 'Sample meeting cannot be deleted'
            : !!selectedSeries?.icpId && selectedSeries.icpId !== user.spinachUserId
            ? `Only the Spinach Host ${spinachHostEmailText}can delete these meeting notes`
            : '';

    const canDeleteMeeting = user.spinachUserId === hostId && botId !== demoBotId;

    return (
        <Row style={{ width: 'fit-content' }}>
            <SecondaryButton
                title="Share"
                disabled={!botId}
                containerStyles={{ paddingRight: '10px' }}
                onClick={() => {
                    if (!botId) {
                        return;
                    }

                    setGlobalModal(GlobalModal.ShareAiHistory, {
                        metadata: {
                            metadataType: GlobalModalMetadataType.ShareAiHistory,
                            usersWithHistoryAccess: usersWithHistoryAccess ?? [],
                            botId,
                            seriesId,
                            meetingTitle,
                        },
                    });
                }}
                buttonStyles={{
                    textDecoration: 'none',
                    border: '1px solid #B8B8C5',
                    borderRadius: '6px',
                }}
                labelStyles={{ fontSize: '18px', fontWeight: 500, padding: '7px 10px' }}
                color={lightTheme.primary.midnight}
            />
            <SecondaryButton
                title={<MoreHoriz />}
                onClick={(e) => {
                    setIsShareMenuOpen(true);
                    setAnchorEl(e.currentTarget);
                }}
                buttonStyles={{
                    textDecoration: 'none',
                    border: '1px solid #B8B8C5',
                    borderRadius: '4px',
                }}
                labelStyles={{
                    display: 'flex',
                    fontSize: '18px',
                    justifyContent: 'center',
                    fontWeight: 700,
                    padding: '1px 3px',
                }}
                color={lightTheme.primary.midnight}
            />

            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ marginTop: '42px' }}
                anchorEl={anchorEl}
                open={isShareMenuOpen}
                onClose={() => {
                    setAnchorEl(null);
                    setIsShareMenuOpen(false);
                }}
            >
                <MenuItem
                    onClick={() => {
                        onCopySummary();
                        setIsShareMenuOpen(false);
                    }}
                >
                    <BodyRegularOnboard>Copy Summary</BodyRegularOnboard>
                </MenuItem>
                {shouldShowCopyTranscript ? (
                    <MenuItem
                        disabled={!hasProFeatures}
                        onClick={() => {
                            onCopyTranscript();
                            setIsShareMenuOpen(false);
                        }}
                    >
                        <BodyRegularOnboard>
                            Copy Transcript{!hasProFeatures ? ' (Spinach Pro Only)' : ''}
                        </BodyRegularOnboard>
                    </MenuItem>
                ) : (
                    <></>
                )}
                {shouldShowDownloadVideo ? (
                    <MenuItem
                        disabled={!hasProFeatures}
                        onClick={() => {
                            onDownloadVideo();
                            setIsShareMenuOpen(false);
                        }}
                    >
                        <BodyRegularOnboard>
                            Download Video{!hasProFeatures ? ' (Spinach Pro Only)' : ''}
                        </BodyRegularOnboard>
                    </MenuItem>
                ) : (
                    <></>
                )}
                {user.isEnabledForHostNotesDeletion ? (
                    <BootstrapTooltip
                        title={
                            deletionTooltip ? (
                                <BodyRegular style={{ color: 'white', fontWeight: 600 }}>{deletionTooltip}</BodyRegular>
                            ) : (
                                ''
                            )
                        }
                    >
                        <MenuItem
                            onClick={() => {
                                trackClick(ClientEventType.AIDashboardClick, 'Open Confirm Delete Meeting Notes', {
                                    HasDeletionPermission: canDeleteMeeting,
                                });

                                if (!canDeleteMeeting) {
                                    return;
                                }

                                setGlobalModal(GlobalModal.ConfirmDeleteMeeting);
                            }}
                            style={{
                                cursor: canDeleteMeeting ? 'pointer' : 'not-allowed',
                            }}
                        >
                            <BodyRegularOnboard
                                style={{
                                    color: !canDeleteMeeting
                                        ? lightTheme.tertiary.midnight
                                        : lightTheme.status.negative,
                                }}
                            >
                                Delete Meeting Data
                            </BodyRegularOnboard>
                        </MenuItem>
                    </BootstrapTooltip>
                ) : null}
            </Menu>
        </Row>
    );
}
