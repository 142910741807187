import {
    CompositionalComponentKind,
    ISOString,
    IcebreakerModule,
    MeditationModule,
    MeetingProps,
    MeetingStatus,
    Module,
    ModuleType,
    NotificationCode,
    Sentiment,
    SeriesOutputOptions,
    SpinachComponents,
    UUID,
    UserMood,
} from '@spinach-shared/types';
import { ScheduleDay, getTopicsLabel } from '@spinach-shared/utils';

import { ActiveAgendaProps, AgendaPropsFactory, BaseAgendaProps } from '../agenda';
import { BaseAgendaItemProps, ParkingLotAgendaItemProps } from '../agenda-item';
import { BaseParticipantProps } from '../participant';
import { BaseParticipantListProps } from '../participant-list';

export class BaseMeetingProps {
    id: UUID;
    startedAt?: ISOString;
    endedAt?: ISOString;
    status: MeetingStatus;
    participants: BaseParticipantListProps;
    userMoods: UserMood[];
    modules: Module[];
    agenda: BaseAgendaProps;
    createdAt: ISOString;
    updatedAt: ISOString;
    seriesId: string;
    dateTimeMetadata?: ScheduleDay;
    outputCode?: NotificationCode;
    outputResults: SeriesOutputOptions[];
    components?: SpinachComponents[];

    constructor(meeting: MeetingProps) {
        this.id = meeting.id;
        this.startedAt = meeting.startedAt;
        this.endedAt = meeting.endedAt;
        this.status = meeting.status;
        this.participants = new BaseParticipantListProps(meeting.participants);
        this.modules = meeting.modules;
        this.agenda = AgendaPropsFactory.createAgendaProps(meeting.agenda);
        this.createdAt = meeting.createdAt;
        this.updatedAt = meeting.updatedAt;
        this.seriesId = meeting.seriesId;
        this.outputCode = meeting.outputCode;
        this.userMoods = meeting.userMoods ?? [];
        this.dateTimeMetadata = meeting.dateTimeMetadata ? new ScheduleDay(meeting.dateTimeMetadata) : undefined;
        this.outputResults = meeting.outputResults ?? [];
        this.components = meeting.components;
    }

    get participantsReady(): number {
        return this.participants.readyCount;
    }

    get participantsOnline(): number {
        return this.participants.online.length;
    }

    get onlineParticipants(): BaseParticipantProps[] {
        return this.participants.online;
    }

    get topicsLabel(): string {
        const isJustTopics = this.isRoundtableDisabled;

        return getTopicsLabel(isJustTopics);
    }

    get isRoundtableDisabled(): boolean {
        return !!this.components?.find((comp) => comp.kind === CompositionalComponentKind.Roundtable)?.isHidden;
    }

    get currentAgendaItem(): BaseAgendaItemProps | undefined {
        if (this.agenda instanceof ActiveAgendaProps) {
            return this.agenda.currentItem;
        } else {
            return undefined;
        }
    }

    get isAsyncMeeting(): boolean {
        return !!this.dateTimeMetadata?.isAsync;
    }

    get isIcebreakerStarted(): boolean {
        const icebreaker = this.modules.find((m): m is IcebreakerModule => m.type === ModuleType.Icebreaker);

        return Boolean(icebreaker?.hasStarted);
    }

    get icebreakerQuestion(): string | undefined {
        const icebreaker = this.modules.find((m): m is IcebreakerModule => m.type === ModuleType.Icebreaker);
        return icebreaker?.question;
    }

    get isMeditationActivated(): boolean {
        const meditation = this.modules.find((m): m is MeditationModule => m.type === ModuleType.Meditation);

        return Boolean(meditation);
    }

    get meditationAudio(): string | undefined {
        const meditation = this.modules.find((m): m is MeditationModule => m.type === ModuleType.Meditation);

        return meditation?.file;
    }

    get meditationModule(): MeditationModule | undefined {
        const meditation = this.modules.find((m): m is MeditationModule => m.type === ModuleType.Meditation);

        return meditation;
    }

    get icebreakerQuestionPrompt(): string | undefined {
        const icebreaker = this.modules.find((m): m is IcebreakerModule => m.type === ModuleType.Icebreaker);
        if (icebreaker?.question) {
            return `Icebreaker 🧊: ${icebreaker.question}`;
        } else {
            return undefined;
        }
    }

    get icebreakerShuffleCount(): number {
        const icebreaker = this.modules.find((m): m is IcebreakerModule => m.type === ModuleType.Icebreaker);
        if (!icebreaker) {
            return 0;
        }
        return icebreaker.usedQuestions.length;
    }

    get isIcebreakerLockedIn(): boolean {
        const icebreaker = this.modules.find((m): m is IcebreakerModule => m.type === ModuleType.Icebreaker);
        return !!icebreaker?.isLockedForCheckIn;
    }

    get isMeetingEnding(): boolean {
        return this.status === MeetingStatus.AgendaComplete || this.status === MeetingStatus.MeetingComplete;
    }

    /**
     *
     * @param withIncompletedLiveBuffer default:`false`. Set to true if you want to give some buffer room between now and
     * the next start scheduled start time. Useful if you don't want UX to immediately update as soon as current scheduled day
     * is in the past
     * @returns `Date` | `null`
     */
    getNextScheduledStartDate(withIncompletedLiveBuffer = false): Date | null {
        if (!this.dateTimeMetadata) {
            return null;
        }

        return this.dateTimeMetadata.getNextStartDate(withIncompletedLiveBuffer);
    }

    findParticipantMood(spinachUserId: string): UserMood | undefined {
        return this.userMoods.find((userMood) => userMood.spinachUserId === spinachUserId);
    }

    setUserSentiment(spinachUserId: string, sentiment: Sentiment | undefined) {
        const updatedUserMood: UserMood = {
            spinachUserId,
            sentiment,
            displayName: this.participants.getParticipantName(spinachUserId),
        };

        const existingUsermoodIndex = this.userMoods.findIndex((userMood) => userMood.spinachUserId === spinachUserId);
        if (existingUsermoodIndex === -1) {
            this.userMoods.push(updatedUserMood);
        } else {
            this.userMoods[existingUsermoodIndex].sentiment = sentiment;
        }
    }

    updateParticipantMood(spinachUserId: string, mood: UserMood) {
        const existingUsermoodIndex = this.userMoods.findIndex((userMood) => userMood.spinachUserId === spinachUserId);
        if (existingUsermoodIndex === -1) {
            this.userMoods.push(mood);
        } else {
            this.userMoods[existingUsermoodIndex] = {
                ...mood,
            };
        }
    }

    get isMeetingRestartable(): boolean {
        return (
            this.isMeetingEnding ||
            (this.agenda instanceof ActiveAgendaProps &&
                (this.agenda.currentItem instanceof ParkingLotAgendaItemProps ||
                    this.agenda.currentItem.isParticipantAgendaItem === false))
        );
    }

    toJSON(): MeetingProps {
        return {
            id: this.id,
            startedAt: this.startedAt,
            endedAt: this.endedAt,
            status: this.status,
            participants: this.participants.participants.map((p) => p.toJSON()),
            modules: this.modules,
            agenda: this.agenda.toJSON(),
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            seriesId: this.seriesId,
            outputCode: this.outputCode,
            userMoods: this.userMoods,
            dateTimeMetadata: this.dateTimeMetadata?.toJSON(),
            outputResults: this.outputResults,
        };
    }
}
