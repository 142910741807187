import { useRef, useState } from 'react';
import { v4 } from 'uuid';

import { AddScribeToMeetingsTitlesToMatch } from '@spinach-shared/types';

import { Column, Row, SetValue } from '../../../../..';
import { DeletableTag, NewTag } from '../integrations/CustomVocabularySection';

export function KeywordSelection({
    keywords,
    setKeywords,
    setInputActive,
    inputActive,
    isDisabled,
}: {
    isDisabled?: boolean;
    keywords: AddScribeToMeetingsTitlesToMatch[];
    setInputActive: SetValue<boolean>;
    inputActive: boolean;
    setKeywords: SetValue<AddScribeToMeetingsTitlesToMatch[]>;
}): JSX.Element {
    const [keywordToAdd, setKeywordToAdd] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    const save = () => {
        if (!keywordToAdd) {
            return;
        }

        if (isDisabled) {
            return;
        }
        const newKeywords = [...keywords, { id: v4(), text: keywordToAdd }];
        setKeywords(newKeywords);
        setKeywordToAdd('');
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (isDisabled) {
            return;
        }
        if (e.key === 'Tab') {
            e.preventDefault();
            save();
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            save();
        }
    };

    return (
        <Column>
            {keywords.map((keyword) => (
                <Row key={keyword.id} style={{ width: 'fit-content' }}>
                    <DeletableTag
                        key={keyword.id}
                        id={keyword.id}
                        text={keyword.text}
                        deleteItem={() => {
                            const newKeywords = keywords.filter((k) => k.id !== keyword.id);
                            setKeywords(newKeywords);
                        }}
                    />
                </Row>
            ))}
            <NewTag
                inputRef={inputRef}
                onBlur={save}
                styleOverrides={{ width: 'fit-content', margin: 'unset' }}
                handleKeyDown={handleKeyDown}
                pendingText={keywordToAdd}
                setPendingText={setKeywordToAdd}
                inputActive={inputActive}
                isDisabled={isDisabled}
                placeholder="Add word or phrase"
                editNew={() => {
                    if (isDisabled) {
                        return;
                    }
                    setKeywordToAdd('');
                    setInputActive(true);
                    setTimeout(() => {
                        inputRef.current?.focus();
                    }, 100);
                }}
            />
        </Column>
    );
}
