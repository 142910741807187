import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { BaseParticipantProps, SpinachParticipantListProps } from '@spinach-shared/models';
import { BaseIDRequest, ClientEventType, ClientSocketEvent, StandUpStatus } from '@spinach-shared/types';

import { GlobalModal } from '../..';
import { ReactComponent as ShuffleIcon } from '../../assets/shuffle.svg';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalLiveSeries,
    useGlobalStoredSeries,
    usePersonaDemo,
    useSeriesReality,
} from '../../hooks';
import { useGlobalModal } from '../../hooks';
import { useInviteViaSlackEnablement } from '../../hooks/useFeature';
import { useGlobalMeetingSocket } from '../../hooks/useGlobalSocket';
import { lightTheme } from '../../styles';
import { BodyLarge, BodyRegular, BodySubtitle } from '../../styles/typography';
import { SetValue } from '../../types';
import { createWebsocketPayload } from '../../utils';
import { withContentMasking } from '../../utils/withContentMasking';
import { Hairline, SixDot } from '../common';
import Draggable from './Draggable';
import SecondaryButton from './SecondaryButton';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
    position: relative;
    margin-left: -15px;
`;

const UserDetails = styled.div`
    display: flex;
    align-items: flex-end;
    padding-left: 15px;
`;

const Name = styled.div`
    flex-grow: 1;
    font-weight: 700;
    text-align: left;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.primary.midnight};
`;

type TeamStatusProps = {
    isAsyncMeeting: boolean;
    participantsObject: SpinachParticipantListProps;
    setIsShareSeriesModalOpen: SetValue<boolean>;
    createReorderEmitter?: (id: string) => (moveToIndex: number) => void;
};

type ParticipantProps = {
    style?: CSSProperties;
    p: BaseParticipantProps;
    isUser: boolean;
    status: string;
    dragRef?: any;
    isMouseIn?: boolean;
};

const ParticipantStatus = styled(BodyRegular)<{ isUser: boolean }>`
    font-size: 12px;
    font-weight: ${(props) => (props.isUser ? 700 : 400)};
`;

const SelfIndicator = styled(BodySubtitle)`
    margin-left: 10px;
`;

const DragHandle = styled.span`
    position: absolute;
    left: 0px;
    top: 1px;
`;

const HeaderRow = styled(Row)`
    margin-left: unset;
    justify-content: space-between;
    align-items: center;
`;

function TeamStatus({
    participantsObject,
    createReorderEmitter,
    isAsyncMeeting,
    setIsShareSeriesModalOpen,
}: TeamStatusProps): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();
    const { isDemoSeries } = useSeriesReality();
    const [user] = useGlobalAuthedUser();
    const [storedSeries] = useGlobalStoredSeries();
    const [socket] = useGlobalMeetingSocket();
    const { spinachUserId } = participantsObject.spinachParticipant;
    const isInviteViaSlackEnabled = useInviteViaSlackEnablement();
    const [_, setGlobalModal] = useGlobalModal();

    const shouldShowInviteViaSlackModal =
        isInviteViaSlackEnabled &&
        !user.isAuthedForSlack &&
        storedSeries.userMetadataList.length === 1 &&
        user.selectedSlackAsMessagingIntegraton;

    const track = useExperienceTracking();

    const shuffleAgendaEmitter = () => {
        track(ClientEventType.ClickShuffleParticipants);
        const request = createWebsocketPayload<BaseIDRequest>({
            spinachUserId: liveSeries.spinachUserId,
            meetingId: liveSeries.currentMeeting.id,
            seriesSlug: liveSeries.slug,
        });
        socket.emit(ClientSocketEvent.AgendaShuffle, request);
    };

    const [statefulParticipants, setStatefulParticipants] = useState(participantsObject.participants.map((p) => p));

    useEffect(() => {
        setStatefulParticipants(participantsObject.participants);
    }, [participantsObject]);

    const moveDraggable = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const reorderedAgendaItems = statefulParticipants.map((u) => u);
            reorderedAgendaItems.splice(dragIndex, 1);
            reorderedAgendaItems.splice(hoverIndex, 0, statefulParticipants[dragIndex]);
            setStatefulParticipants(reorderedAgendaItems);
        },
        [statefulParticipants]
    );

    const isDraggable = !!createReorderEmitter && statefulParticipants.length > 1;
    const presentParticipants = statefulParticipants.filter((i) => i.isPresent || i.metadata?.isBot);
    const nonPresentParticipants = statefulParticipants.filter((i) => !i.isPresent && !i.metadata?.isBot);

    const allReady =
        presentParticipants.filter((p) => p.standUpStatus === StandUpStatus.Ready).length ===
        presentParticipants.length;

    React.useEffect(() => {
        if (allReady) {
            window.parent.postMessage(
                { type: 'all-ready', count: presentParticipants.length },
                'https://meet.google.com'
            );
        }
    }, [allReady]);

    React.useEffect(() => {
        window.parent.postMessage(
            {
                type: 'in-lobby',
                firstName: presentParticipants.find((p) => p.spinachUserId === spinachUserId)?.firstName,
            },
            'https://meet.google.com'
        );
    }, []);

    const isPersonaDemo = usePersonaDemo();

    return (
        <Container id="participants-container">
            <HeaderRow>
                <BodyLarge style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    {liveSeries.isRoundtableEnabled ? (
                        <>
                            Roundtable
                            {!isPersonaDemo ? (
                                <ShuffleIcon
                                    style={{ fill: lightTheme.primary.green, cursor: 'pointer' }}
                                    onClick={() => shuffleAgendaEmitter()}
                                />
                            ) : null}
                        </>
                    ) : (
                        'Participants'
                    )}
                </BodyLarge>
                {!isDemoSeries ? (
                    <SecondaryButton
                        title={storedSeries.scribeMetadata ? 'See users' : 'Invite users'}
                        style={{ zIndex: 20 }}
                        onClick={() => {
                            if (shouldShowInviteViaSlackModal) {
                                setGlobalModal(GlobalModal.InviteViaSlack);
                            } else {
                                setIsShareSeriesModalOpen(true);
                            }
                        }}
                    />
                ) : (
                    <></>
                )}
            </HeaderRow>
            {presentParticipants.map((p, i) => {
                const isUser = p.spinachUserId === spinachUserId;

                // TODO: Could we consolidate
                const asyncStatus = p.standUpStatus === StandUpStatus.Ready ? 'Submitted' : 'Not Submitted';

                const roundTableOffStatus = !!p.connectionIds?.length ? 'Online' : 'Offline';

                const status =
                    !p.isPresent && !p.metadata?.isBot ? StandUpStatus.Async : p.standUpStatus ?? StandUpStatus.NoApp;

                const emitOrderedParticipantsSave = createReorderEmitter?.(p.spinachUserId as string);

                return (
                    <Draggable
                        key={p.spinachUserId}
                        index={i}
                        id={p.spinachUserId}
                        moveDraggable={moveDraggable}
                        isDraggable={isDraggable}
                        emitOrderedUpdatesSave={emitOrderedParticipantsSave}
                        type={'participant'}
                        idList={presentParticipants.map((p) => p.spinachUserId)}
                    >
                        <ParticipantRow
                            {...{
                                p,
                                isUser,
                                status: isAsyncMeeting
                                    ? asyncStatus
                                    : liveSeries.isRoundtableEnabled
                                    ? status
                                    : roundTableOffStatus,
                            }}
                        />
                    </Draggable>
                );
            })}
            {nonPresentParticipants.length ? (
                <>
                    <Row
                        style={{
                            opacity: 0.3,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginLeft: 'unset',
                        }}
                    >
                        <Hairline style={{ marginTop: 'unset', marginBottom: 'unset' }} />
                        <BodySubtitle
                            style={{ marginLeft: '5px', marginRight: '5px', color: lightTheme.primary.midnight }}
                        >
                            Offline
                        </BodySubtitle>
                        <Hairline style={{ marginTop: 'unset', marginBottom: 'unset' }} />
                    </Row>
                    {nonPresentParticipants.map((p) => {
                        const isUser = p.spinachUserId === spinachUserId;

                        // TODO: Could we consolidate
                        const asyncStatus = p.standUpStatus === StandUpStatus.Ready ? 'Submitted' : 'Not Submitted';

                        const roundTableOffStatus = !!p.connectionIds?.length ? 'Online' : 'Offline';

                        const status =
                            !p.isPresent && !p.metadata?.isBot
                                ? StandUpStatus.Async
                                : p.standUpStatus ?? StandUpStatus.NoApp;

                        return (
                            <ParticipantRow
                                key={p.spinachUserId}
                                style={{ padding: '0px 10px' }}
                                {...{
                                    p,
                                    isUser,
                                    status: isAsyncMeeting
                                        ? asyncStatus
                                        : liveSeries.isRoundtableEnabled
                                        ? status
                                        : roundTableOffStatus,
                                }}
                            />
                        );
                    })}
                </>
            ) : (
                <></>
            )}
        </Container>
    );
}

function ParticipantRow({ p, isUser, status, dragRef, isMouseIn, style }: ParticipantProps) {
    return (
        <Row style={style} key={p.id}>
            <UserDetails>
                <Name {...withContentMasking()}>{p.displayName}</Name>

                {isUser ? <SelfIndicator>(You)</SelfIndicator> : null}
                {p.metadata?.isBot ? <SelfIndicator>(Spinach Bot)</SelfIndicator> : null}
            </UserDetails>
            <ParticipantStatus isUser={isUser}>{status}</ParticipantStatus>
            {dragRef && isMouseIn && (
                <DragHandle ref={dragRef}>
                    <SixDot />
                </DragHandle>
            )}
        </Row>
    );
}

export default TeamStatus;
