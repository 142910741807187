import { ClientEventType } from '@spinach-shared/types';

import { useGlobalAuthedUser, useGoogleDriveEnablement, useLandingAnalytic, useNotionEnablement } from '../../../hooks';
import { BodyBigOnboard, HeaderThreeOnboard } from '../../../styles';
import { Row, SlidingColumn, Spacing } from '../../common';
import { ConfluenceIntegrationRow } from '../../common/settings/ConfluenceIntegrationRow';
import { GoogleDriveIntegrationRow } from '../../common/settings/GoogleDriveIntegrationRow';
import { NotionIntegrationRow } from '../../common/settings/NotionIntegrationRow';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BackButtonRow, Stationary, StepProps } from './common';

export type KnowledgeBaseSetupProps = StepProps & {
    onBack: () => void;
    onSkip: () => void;
};

export function KnowledgeBaseSetupStep({ onBack, direction, onSubmit, onSkip }: KnowledgeBaseSetupProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedConnectKnowledgeBaseStep);
    const [user] = useGlobalAuthedUser();
    const hasAnyIntegration = user.isAuthedForConfluence || user.isAuthedForNotion || user.isAuthedForGoogleDrive;
    const isNotionEnabled = useNotionEnablement();
    const isGoogleDriveEnabled = useGoogleDriveEnablement();
    return (
        <Stationary>
            <BackButtonRow onBack={onBack} />
            <HeaderThreeOnboard>Get meeting notes in your knowledge base</HeaderThreeOnboard>
            <Spacing factor={1 / 2} />
            <BodyBigOnboard>Spinach will send meeting notes to any service you choose.</BodyBigOnboard>
            <Spacing factor={2} />
            <ScrollView
                sidePadding={0}
                stretch
                style={{ backgroundColor: 'rgba(0,0,0,0)', background: 'unset', maxWidth: '515px' }}
            >
                <SlidingColumn centered direction={direction}>
                    <ConfluenceIntegrationRow isConnectViewOnly={true} />
                    {isNotionEnabled ? <NotionIntegrationRow isConnectViewOnly={true} /> : null}
                    {isGoogleDriveEnabled ? <GoogleDriveIntegrationRow isConnectViewOnly={true} /> : null}
                    <Spacing />
                </SlidingColumn>
            </ScrollView>
            <Spacing factor={2} />
            <Row centered>
                <PrimaryButton title={'Next'} onClick={onSubmit} disabled={!hasAnyIntegration} />
            </Row>
            <Spacing factor={1 / 2} />
            <Row centered>
                <SecondaryButton title={'Skip'} onClick={onSkip} />
            </Row>
        </Stationary>
    );
}
