import { useEffect } from 'react';

import { DateTimeMetadata, HHMMTimeString } from '@spinach-shared/types';
import { TimeUtils, getSelectedDateTimeInformation } from '@spinach-shared/utils';

import { SetValue } from '../types';

export const useStartEndTimeValidation = (
    selectedDateTime: DateTimeMetadata | null,
    setSelectedDateTime: SetValue<DateTimeMetadata | null>,
    standupDayTimesSelected: DateTimeMetadata[],
    setStandupDayTimes: (standupDayTimes: DateTimeMetadata[]) => void
) => {
    useEffect(() => {
        if (
            selectedDateTime &&
            TimeUtils.isStartTimeInvalid(
                getSelectedDateTimeInformation<HHMMTimeString>(selectedDateTime, 'startTime'),
                getSelectedDateTimeInformation<HHMMTimeString>(selectedDateTime, 'endTime')
            )
        ) {
            const minutesOfNewEndTime =
                TimeUtils.getMinutesFromHHMM(
                    getSelectedDateTimeInformation<HHMMTimeString>(selectedDateTime, 'startTime')
                ) + 30;
            const hours = Math.floor(minutesOfNewEndTime / 60);
            const minutes = minutesOfNewEndTime % 60;
            const newEndTime = TimeUtils.getHHMMFromHoursMinutes(hours.toString(), minutes.toString());
            let updatedDateTime = {
                ...selectedDateTime,
            };
            if (selectedDateTime.isChangedForThisWeekOnly) {
                updatedDateTime = {
                    ...updatedDateTime,
                    oneOffDateTimeMetadata: {
                        ...updatedDateTime.oneOffDateTimeMetadata,
                        endTime: newEndTime, // start time + 30
                    },
                } as DateTimeMetadata;
            } else {
                updatedDateTime = {
                    ...updatedDateTime,
                    endTime: newEndTime, // start time + 30
                };
            }
            setStandupDayTimes([...standupDayTimesSelected, updatedDateTime]);
            setSelectedDateTime(updatedDateTime);
        }
    }, [selectedDateTime]);
};
