import { AsanaPickerOpts, SpinachAPIPath, Ticket } from '@spinach-shared/types';

import { getSpinachAPI } from '../apis';

export const fetchAsanaIssues = async (opts?: AsanaPickerOpts) => {
    const response = await getSpinachAPI<{ issues: Ticket[] }, AsanaPickerOpts>(SpinachAPIPath.AsanaSuggestions, {
        params: opts,
    });

    if (!response?.issues || !response.issues.length) {
        return [];
    }

    return response.issues;
};
