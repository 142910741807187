import { SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export const fetchCelloJWT = async (): Promise<string | null> => {
    try {
        const response = await getSpinachAPI<{ jwt: string }>(SpinachAPIPath.CelloAuthenticate);
        return response?.jwt || null;
    } catch (error) {
        return null;
    }
};