import { ConfluenceGetSpacesRequest, MinimalConfluenceSpace, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../getSpinachAPI';

export const fetchConfluenceSpaces = async (
    params: ConfluenceGetSpacesRequest = {}
): Promise<MinimalConfluenceSpace[]> => {
    const response = await getSpinachAPI<{ spaces: MinimalConfluenceSpace[] }>(SpinachAPIPath.ConfluenceSpace, {
        params,
    });

    if (!response?.spaces || !response.spaces.length) {
        return [];
    }
    return response.spaces;
};
