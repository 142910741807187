import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

// If you don't delete this and rename me, you're lazy.
export function ContainedExample(): JSX.Element {
    return <Container></Container>;
}
