import { SpinachAPIPath } from '@spinach-shared/types';

import { deleteSpinachAPI } from './deleteSpinachAPI';

export async function deleteBrandedImage(): Promise<string | null> {
    try {
        const response = await deleteSpinachAPI<{ message: string; presignedUrl: string }>(SpinachAPIPath.BrandedImage);

        return response?.presignedUrl ?? null;
    } catch {
        return null;
    }
}
