import { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';

import { ClientUser, YTBHistoryProps, YTBHistoryPropsFactory } from '@spinach-shared/models';
import { ServerSocketEvent, YTBHistoryJSON } from '@spinach-shared/types';

import { fetchHistory } from '../utils';
import { RunningContext } from '../zoom';

/** @deprecated */
export function useHistory(user: ClientUser, seriesId: string): [YTBHistoryProps | null | undefined] {
    const [history, setHistory] = useState<YTBHistoryProps | null | undefined>(null);

    useEffect(() => {
        const getHistory = async () => {
            const historyProps = await fetchHistory(user.spinachUserId, seriesId, RunningContext.InMeeting);
            setHistory(historyProps);
        };

        getHistory();
    }, []);

    return [history];
}

// TODO: Refactor this to recoil state
export function useLiveHistory(socket: Socket | null): [YTBHistoryProps | null | undefined] {
    const [history, setHistory] = useState<YTBHistoryProps | null | undefined>(null);

    useEffect(() => {
        socket?.on(ServerSocketEvent.YTBHistoryUpdated, (response: { history?: YTBHistoryJSON }) => {
            if (response.history) {
                const ytbHistory = YTBHistoryPropsFactory.fromJSON(response.history);
                setHistory(ytbHistory);
            } else {
                setHistory(undefined);
            }
        });
    }, [socket]);

    return [history];
}
