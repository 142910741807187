import { Chip } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { BaseMeetingProps, ClientUser, ParticipantStubObject, SeriesHistoriesProps } from '@spinach-shared/models';
import { ClientEventType, SpinachUpdateTypeYTB, UUID } from '@spinach-shared/types';

import { useExperienceTracking } from '../../../../../hooks';
import { BodySubtitle } from '../../../../../styles';
import { SetValue } from '../../../../../types';
import { StandupAppDropDown } from '../../../../common';

type SummaryFilterDropdownProps = {
    setParticipantFilter: SetValue<UUID[]>;
    participantFilter: UUID[];
    setCategoryFilter: SetValue<SpinachUpdateTypeYTB[]>;
    categoryFilter: SpinachUpdateTypeYTB[];
    setOpenedSummary: SetValue<number | null>;
    selectedDate?: string;
    user: ClientUser;
    histories: BaseMeetingProps[] | null;
};

type SummaryFilterTitleProps = {
    participantFilter: UUID[];
    clearFilters: MouseEventHandler<SVGSVGElement>;
    participantsInSeries: ParticipantStubObject;
};

const FilterLabel = styled.div`
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const SelfIndicator = styled(BodySubtitle)`
    margin-right: 3em;
`;

function SummaryFilterTitle({ participantFilter, clearFilters, participantsInSeries }: SummaryFilterTitleProps) {
    if (!participantFilter.length) {
        return <>Filter</>;
    }

    return (
        <FilterLabel>
            <Cancel color="action" onClick={clearFilters} style={{ marginRight: '.5em' }} />

            {participantFilter.length > 1 && (
                <>
                    <div style={{ marginRight: '2em' }}>Filter</div>
                    <Chip size="small" label={participantFilter.length} color={'secondary'} />
                </>
            )}
            {participantFilter.length <= 1 && participantsInSeries[participantFilter[0]].displayName}
        </FilterLabel>
    );
}

export function StandupAppSummaryFilterDropdown({
    setParticipantFilter,
    participantFilter,
    setOpenedSummary,
    selectedDate,
    user,
    histories,
}: SummaryFilterDropdownProps): JSX.Element {
    const track = useExperienceTracking();

    if (!histories) {
        return <></>;
    }

    const participantsInSeries = SeriesHistoriesProps.findAllParticipantsInSeries(histories);
    const particpantIdsSorted = SeriesHistoriesProps.getParticipantIdsSortedByName(
        participantsInSeries,
        user.spinachUserId
    );

    const dropdownValues = particpantIdsSorted.map((id) => {
        const participant = participantsInSeries[id];
        const { spinachUserId, displayName } = participant;
        const selfLabel = <SelfIndicator>(You)</SelfIndicator>;

        return {
            code: spinachUserId,
            label: displayName,
            postContent: spinachUserId === user.spinachUserId ? selfLabel : null,
        };
    });

    const clearFilters: MouseEventHandler<SVGSVGElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setParticipantFilter([]);
        setOpenedSummary(0);

        track(ClientEventType.SummaryFilterClear);
    };

    const onSelection = (code: UUID) => {
        if (participantFilter.includes(code)) {
            const newParticipantFilter = participantFilter.filter((id) => id !== code);
            setParticipantFilter(newParticipantFilter);
            setOpenedSummary(0);

            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Summary Participant Filter',
                RemovedParticipant: participantsInSeries[code].displayName,
                SelectedParticipants: newParticipantFilter.map((id) => participantsInSeries[id].displayName).join(', '),
                SelectedDate: selectedDate,
            });
        } else {
            const newParticipantFilter = [code];
            setParticipantFilter(newParticipantFilter);
            setOpenedSummary(-1);

            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Summary Participant Filter',
                AddedParticipant: participantsInSeries[code].displayName,
                SelectedParticipants: newParticipantFilter.map((id) => participantsInSeries[id].displayName).join(', '),
                SelectedDate: selectedDate,
            });
        }
    };

    const filterTitle = (
        <SummaryFilterTitle
            clearFilters={clearFilters}
            participantFilter={participantFilter}
            participantsInSeries={participantsInSeries}
        />
    );

    return (
        <StandupAppDropDown
            title={filterTitle}
            buttonProps={{ title: filterTitle }}
            values={dropdownValues}
            handleSelection={onSelection}
            selected={participantFilter[0] && participantsInSeries[participantFilter[0]].displayName}
        />
    );
}
