import React, { useState } from 'react';

import { ReactComponent as MondayDotComLogo } from '../../../assets/monday-dot-com.svg';
import { useGlobalAuthedUser } from '../../../hooks';
import { MondayApiKeyModel } from '../../spinach-ai/dashboard/integrations/MondayApiKeyModel';
import { AppIntegrationRow, IntegrationButtonText } from './AppIntegrationRow';
import { IntegrationRowProps } from './common';

export function MondayDotComIntegrationRow({ isConnectViewOnly }: IntegrationRowProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const isConnected = user.isAuthedForMondayDotCom;
    const [showApiKeyModel, setShowApiKeyModel] = useState(false);

    const onClick = async () => {
        if (isConnected) {
        } else {
            setShowApiKeyModel(true);
        }
    };

    return (
        <>
            <AppIntegrationRow
                onCTAClick={onClick}
                subtext={''}
                label="Monday.com"
                icon={<MondayDotComLogo style={{ width: '24px', height: '24px' }} />}
                isConnected={isConnected}
                buttonText={isConnected ? IntegrationButtonText.Configure : IntegrationButtonText.Connect}
                isConnectViewOnly={isConnectViewOnly}
            />
            <MondayApiKeyModel
                onClose={() => setShowApiKeyModel(false)}
                isOpen={showApiKeyModel}
                onSuccess={() => {}}
            />
        </>
    );
}
