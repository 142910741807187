import { ISOString, UUID } from '@spinach-shared/types';

import { YTBAgendaItemProps } from '../agenda-item';
import { BaseMeetingProps } from '../meeting';

type ParticipantStub = {
    displayName: string;
    spinachUserId: UUID;
};

export type ParticipantStubObject<T = ParticipantStub> = {
    [spinachUserId: UUID]: T;
};

export class SeriesHistoriesProps {
    private _items: BaseMeetingProps[];

    constructor(seriesHistories: BaseMeetingProps[]) {
        this._items = seriesHistories;
    }

    set items(items: BaseMeetingProps[]) {
        this._items = items;
    }

    get items(): BaseMeetingProps[] {
        return this._items;
    }

    findMeetingsAfter(date: ISOString): BaseMeetingProps[] {
        return this.items.filter((meeting) => {
            if (!meeting.startedAt) {
                return false;
            }

            const meetingStart = new Date(meeting.startedAt);
            const filterDate = new Date(date);

            if (meetingStart.getTime() >= filterDate.getTime()) {
                return true;
            }

            return false;
        });
    }

    findMeetingsBefore(date: ISOString): BaseMeetingProps[] {
        return this.items.filter((meeting) => {
            if (!meeting.startedAt) {
                return false;
            }

            const meetingStart = new Date(meeting.startedAt);
            const filterDate = new Date(date);

            if (meetingStart.getTime() <= filterDate.getTime()) {
                return true;
            }

            return false;
        });
    }

    static getParticipantIdsSortedByName(participantStub: ParticipantStubObject, currentUserId?: UUID): UUID[] {
        return Object.keys(participantStub).sort((a, b) => {
            const userA = participantStub[a];
            const userB = participantStub[b];
            const charCodeA = userA.displayName.charCodeAt(0);
            const charCodeB = userB.displayName.charCodeAt(0);

            if (currentUserId && a === currentUserId) {
                return -1;
            }

            if (charCodeA < charCodeB) {
                return -1;
            }

            if (charCodeA === charCodeB) {
                return 0;
            }

            return 1;
        });
    }

    static findAllParticipantsInSeries(meetings: BaseMeetingProps[]): ParticipantStubObject {
        let participants = {};

        meetings.forEach((meeting) => {
            const participantsInMeeting = SeriesHistoriesProps.findParticipantsInMeeting(meeting);

            participants = {
                ...participants,
                ...participantsInMeeting,
            };
        });

        return participants;
    }

    static findParticipantsInMeeting(meeting: BaseMeetingProps): ParticipantStubObject {
        const particpantsById: ParticipantStubObject<ParticipantStub> = {};

        meeting.participants.participants.forEach((participant) => {
            const { displayName, spinachUserId } = participant;

            particpantsById[spinachUserId] = {
                displayName,
                spinachUserId,
            };
        });

        return particpantsById;
    }

    static findParticipantUpdatesInSeries(meetings: BaseMeetingProps[], spinachUserIds: UUID[]) {
        const updates: YTBAgendaItemProps[] = [];

        meetings.forEach((meeting) => {
            spinachUserIds.forEach((id) => {
                const userUpdate = meeting.agenda.findParticipantsYTBAgendaItemProps(id);

                if (!userUpdate) {
                    return;
                }

                updates.push(userUpdate);
            });
        });

        return updates;
    }

    static applyFiltersToMeeting(meeting: BaseMeetingProps, spinachUserIds: UUID[]): BaseMeetingProps {
        const meetingCopy = { ...meeting };

        const agendaItems = meetingCopy.agenda.items.filter((item) => {
            return spinachUserIds.includes(item.id);
        });

        return new BaseMeetingProps({
            ...meetingCopy,
            agenda: {
                ...meetingCopy.agenda,
                items: agendaItems.map((item) => ({ ...item, talkTimes: item.talkTimes.toJSON() })),
            },
            participants: meetingCopy.participants.participants.map((p) => p.toJSON()),
        });
    }
}
