/**
 * Attention: the prettification of this file has been intentionally disabled so as
 * to retain the spacing as-is in the template strings.
 */

import { icebreakerUpdateSectionTypeProps, sectionTypeList } from '@spinach-shared/constants';
import {
    ActiveSeriesProps,
    BaseAgendaProps,
    BaseMeetingProps,
    YTBAgendaItemProps,
    YTBUpdateProps,
} from '@spinach-shared/models';
import {
    AuthoredUpdate,
    FeatureToggle,
    ReservedAgendaTitle,
    ResolverMetadata,
    Sentiment,
    SpinachUpdateType,
    TypedUpdate,
    TypedUpdateWrapper,
    UpdateSectionTypeProps,
    UserMood,
} from '@spinach-shared/types';
import { getWebAppHost } from '@spinach-shared/utils';

import { doesUpdateSectionHaveTypedUpdates, formatTime, getFormattedDate } from './';

type WithUpdateListItemOpts = {
    sentiment?: Sentiment;
    resolvers?: ResolverMetadata[];
    subItems?: TypedUpdate[];
};

const sentimentEmojiMap: Record<Sentiment, string> = {
    [Sentiment.Good]: '🟢',
    [Sentiment.Ok]: '🟡',
    [Sentiment.Bad]: '🔴',
};

const userMoodEmojiMap: Record<Sentiment, string> = {
    [Sentiment.Good]: '💚',
    [Sentiment.Ok]: '💛', 
    [Sentiment.Bad]: '🔴',
}

const convertSentimentIntoEmoji = (sentiment?: Sentiment) => (sentiment ? sentimentEmojiMap[sentiment] : '');

const withTeammateName = (
    richTextSummary: string,
    name: string,
    totalTime: number,
    isFromAsyncMeeting?: boolean
): string =>
    `${richTextSummary}
*${name}* ${isFromAsyncMeeting ? '' : `(${formatTime(totalTime)})`}
`;

const withUpdate = (text: string, {sentiment, subItems = [], resolvers = []}: WithUpdateListItemOpts) => `${convertSentimentIntoEmoji(sentiment)} ${text} ${subItems.filter((su) => !!su.text)
    .map((subItem) => `\n\t\t\t• ${subItem.text}`)
    .join()}
    ${resolvers?.length ? resolvers?.map((resolver) => `\n\t\t\t• *${resolver.preferredName}*: _${resolver.details}_`) : ''} \n`

const withUpdateListItem = (richUpdateListItem: string, text: string, opts: WithUpdateListItemOpts): string => {
    return `${richUpdateListItem} \t\t• ${withUpdate(text, opts)}`;
};

const withUpdatelessSection = (richTextSummary: string, text: string): string => ` ${richTextSummary} * ${text} `;

const withUpdateSection = (richTextSummary: string, sectionTitle: string, updateListItems: string): string =>
    `${richTextSummary} \t_${sectionTitle}_\n${updateListItems}`;

const withUserMoods = (userMoods: UserMood[]): string =>
    `*Team Mood*\n${userMoods
        .filter(userMood => !!userMood.sentiment)
        .map(
            (userMood) =>
                `_${userMood.displayName}_ ${
                    userMood.sentiment ? userMoodEmojiMap[userMood.sentiment] : ''
                }${userMood.details ? `\n\t• ${userMood.details}` : ''}`
        )
        .join('\n')}`;

const withParkingLot = (richTextSummary: string, updateItems: YTBAgendaItemProps[], totalTime: number) =>
    `${richTextSummary}
*${ReservedAgendaTitle.TeamTopics}* (${formatTime(totalTime)})
${updateItems
    .map(
        (item) =>
            `${item.standUpUpdate.updates
                .filter((u) => u.updateType === SpinachUpdateType.ParkingLot)
                .map((topic) => {
                    /** @TODO should asanaData be here? */
                    const issueData = topic.jiraData || topic.ticketData?.ticket;
                    if (issueData) {
                        return `\t• ${topic.text} _(${item.title})_\n\t\t• ${issueData.id} ${issueData.title} \n`;
                    } else {
                        return `\t• ${topic.text} _(${item.title})_\n`;
                    }
                })
                .join('')}`
    )
    .join('')}
`;

const withThreadPrefaceItemArray = (agenda: BaseAgendaProps) => {
    const summary = withTopItems(agenda.blockerSentimentUpdates);
    
    return agenda.parkingLotItem && agenda.YTBItemsWithParkingLotUpdates.length > 0
            ? [withParkingLot(summary, agenda.YTBItemsWithParkingLotUpdates, agenda.parkingLotItem.totalTime)]
            : summary ? [summary] : []
}

const withStandUpHeader = (series: ActiveSeriesProps): string => `
    *Standup Summary (${series.name}) - ${getFormattedDate(
    series.currentMeeting.isAsyncMeeting
        ? series.currentMeeting.dateTimeMetadata?.startTime!
        : series.currentMeeting.agenda.startedAt!
)}* \n 🥬 <${getWebAppHost()}/meeting/${series.slug}/summaries|View all summaries here>
`;

function buildListItemsFromUpdates(updates: TypedUpdate[]): string {
    const listItems = updates.reduce((section, update) => {
        const wrapper = new TypedUpdateWrapper(update);
        section = withUpdateListItem(section, update.text, {
            sentiment: wrapper.sentiment,
            subItems: update.subItems,
            resolvers: update.resolvers
        });

        return section;
    }, '');

    return listItems;
}


function getUpdatesListItems(standUpUpdate: YTBUpdateProps, typeProps: UpdateSectionTypeProps): string {
    const updatesOfSection = standUpUpdate.getUpdatesForType(typeProps.spinachUpdateType);

    return buildListItemsFromUpdates(updatesOfSection)
}

function withChallengesAndParticipants(richTextSummary: string, agenda: BaseAgendaProps): string {
    return `${richTextSummary} \n> Top Items: ${agenda.blockerSentimentUpdates.length} \n> Participants: ${
        agenda.YTBItems.filter((item) => item.isParticipantAgendaItem !== false).length
    }`;
}

function withSlackPrefacingText(series: ActiveSeriesProps): string {
    const header = withStandUpHeader(series);

    return withChallengesAndParticipants(header, series.currentMeeting.agenda);
}

function withTopItems(typedUpdates: AuthoredUpdate[]): string {
    if(!typedUpdates.length) {
        return '';
    }

    return `*Top Items*\n${typedUpdates
        .map(
            (update) =>  
                `_${update.author}_ \n${withUpdate(update.text, { sentiment: update.sentiment, subItems: update.subItems})}`
            
        )
        .join('\n')}`;
}

const getIcebreakerMessage = (meeting: BaseMeetingProps): string => {
    const icebreakerSections = meeting.agenda.YTBItems.reduce((prev, curr) => {
        if (curr.icebreakerUpdates.length) {
            return `${prev}
${withUpdateSection('', curr.title, getUpdatesListItems(curr.standUpUpdate, icebreakerUpdateSectionTypeProps))}`;
        } else {
            return prev;
        }
    }, '');

    return (
`*${meeting.icebreakerQuestionPrompt}*
${icebreakerSections}
`);
};

export function formatSummaryForSlackMD(series: ActiveSeriesProps): {
    parentMessage: string;
    threadedMessages: string[];
} {
    const currentMeeting = series.currentMeeting;
    const agenda = currentMeeting.agenda;
    const parentMessage = withSlackPrefacingText(series);
    
    const threadedMessages = agenda.YTBItems.reduce<string[]>(
        (threadedMessages, item) => {
            const isUpdateSectionPopulated = doesUpdateSectionHaveTypedUpdates(item.standUpUpdate);
            const isUpdateEntirelyEmpty = !sectionTypeList.some(isUpdateSectionPopulated);

            const isIssueBasedEnabled = !!series.featureFlags?.[FeatureToggle.IssueBased];
            const isIssueBasedCheckinEmpty = isIssueBasedEnabled && item.standUpUpdate.hasNoIssueBasedUpdates;

            const listItems: string[] = [
                withTeammateName('', item.title, item.totalTime, series.currentMeeting.isAsyncMeeting),
            ];

            if (isUpdateEntirelyEmpty || isIssueBasedCheckinEmpty) {
                listItems.push(withUpdatelessSection('', 'No updates today'));
            } else {
                const enabledCustomLists = series.enabledCustomRoundtableLists;

                sectionTypeList
                    .filter((s) => series.isComponentEnabled(s.spinachUpdateType))
                    .filter(isUpdateSectionPopulated)
                    .forEach((updateSectionTypePropsWithUpdates) => {
                        listItems.push(
                            withUpdateSection(
                                '',
                                updateSectionTypePropsWithUpdates.title,
                                getUpdatesListItems(item.standUpUpdate, updateSectionTypePropsWithUpdates)
                            )
                        );
                    });

                enabledCustomLists.forEach((l) => {
                    const customUpdates = item.standUpUpdate.getCustomUpdatesForList(l.id);
                    if (customUpdates.length) {
                        listItems.push(withUpdateSection('', l.title, buildListItemsFromUpdates(customUpdates)));
                    }
                });
            }

            threadedMessages.push(listItems.join(''));
            return threadedMessages;
        },
        withThreadPrefaceItemArray(agenda)
    );

    if (currentMeeting.userMoods?.length && currentMeeting.userMoods.some(userMood => !!userMood.sentiment)) {
        threadedMessages.push(withUserMoods(currentMeeting.userMoods));
    }

    if (series.currentMeeting.isIcebreakerLockedIn) {
        const icebreakerMessage = getIcebreakerMessage(series.currentMeeting);
        if (icebreakerMessage) {
            threadedMessages.push(icebreakerMessage);
        }
    }

    return { parentMessage, threadedMessages };
}
