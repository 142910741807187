import { Fade } from '@material-ui/core';
import { AccountBox, Create, Description, Group, Person } from '@material-ui/icons';

import { CalendarEvent, EmailDistributionConfig } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import {
    GlobalModal,
    GlobalModalMetadataType,
    PrimaryButton,
    useGlobalAuthedUser,
    useGlobalModal,
} from '../../../../..';
import InternalOnly from '../../../../assets/internal-only.svg';
import { useSelectSummaryAccess } from '../../../../hooks/useSelectSummaryAccess';
import { useToggleEditSummariesForSeries } from '../../../../hooks/useToggleEditSummaries';
import { BodyRegularOnboard, ButtonSize, lightTheme } from '../../../../styles';
import { BootstrapTooltip, ProBadge, Row, Spacing } from '../../../common';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import { SummaryExampleTemplate } from './SummaryExampleTemplates';

type MeetingInformationTooltipProps = {
    storedSeries: StoredSpinachSeries;
    isEditable: boolean;
};

const UpgradeToProHyperlink = ({ title, from }: { title: string; from: string }) => {
    const navigateToUpgrade = useNavigateToUpgrade();
    return (
        <PrimaryButton
            size={ButtonSize.Small}
            title={title}
            onClick={(e) => {
                e.stopPropagation();
                navigateToUpgrade(`Click Upgrade to Pro (${from})`);
            }}
        />
    );
};

const SummaryLocationMeetingTooltip = ({
    isEditable,
    storedSeries,
}: {
    isEditable: boolean;
    storedSeries: StoredSpinachSeries;
}) => {
    const [user] = useGlobalAuthedUser();

    const {
        isEmailingIcpOnly,
        emailDistributionConfig,
        onSelect: onChange,
    } = useSelectSummaryAccess({ storedSeries, from: 'Meeting Tooltip' });
    const toggleSummaryLocation = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (!isEditable) {
            return;
        }
        if (!user.hasProFeatures) {
            return;
        }

        // cycle between host only, all invitees, and internal only
        if (isEmailingIcpOnly) {
            onChange(false, EmailDistributionConfig.All);
        } else if (emailDistributionConfig === EmailDistributionConfig.All) {
            if (user.isEnabledForInternalOnlyEmailDistribution) {
                onChange(true, EmailDistributionConfig.InternalOnly);
            } else {
                onChange(true, EmailDistributionConfig.All);
            }
        } else if (emailDistributionConfig === EmailDistributionConfig.InternalOnly) {
            onChange(true, EmailDistributionConfig.All);
        }
    };
    let tooltipText = 'all invitees.';
    let iconComponent = (
        <Group
            onClick={toggleSummaryLocation}
            style={{
                color: lightTheme.primary.greenLight,
                cursor: user.hasProFeatures && isEditable ? 'pointer' : 'initial',
            }}
        />
    );

    if (user.hasProFeatures) {
        if (isEmailingIcpOnly) {
            tooltipText = 'the person who invited Spinach';
            iconComponent = (
                <Person
                    onClick={toggleSummaryLocation}
                    style={{
                        color: lightTheme.primary.greenLight,
                        cursor: user.hasProFeatures && isEditable ? 'pointer' : 'initial',
                    }}
                />
            );
        } else if (emailDistributionConfig === EmailDistributionConfig.InternalOnly) {
            tooltipText = 'invitees from your company';
            iconComponent = (
                <img
                    src={InternalOnly}
                    onClick={toggleSummaryLocation}
                    style={{
                        color: lightTheme.primary.greenLight,
                        cursor: user.hasProFeatures && isEditable ? 'pointer' : 'initial',
                        width: '22px',
                    }}
                />
            );
        }
    }

    return (
        <BootstrapTooltip
            interactive
            arrow
            title={
                <>
                    <BodyRegularOnboard style={{ color: lightTheme.neutrals.white }}>
                        Summaries will be sent to <span style={{ fontWeight: 'bold' }}>{tooltipText}</span>
                    </BodyRegularOnboard>
                    <Spacing factor={1 / 3} />
                    {user.hasProFeatures ? (
                        <>
                            <Row style={{ width: 'fit-content' }}>
                                <ProBadge containerStyle={{ marginLeft: 'unset' }} />
                            </Row>
                        </>
                    ) : (
                        <Row style={{ width: 'fit-content' }}>
                            <UpgradeToProHyperlink title={'Upgrade to Pro'} from="Emailing ICP Only Meeting Tooltip" />
                        </Row>
                    )}
                    <Spacing factor={1 / 5} />
                </>
            }
        >
            {iconComponent}
        </BootstrapTooltip>
    );
};

const EditSummaryMeetingTooltip = ({
    isModifiableEvent,
    storedSeries,
}: {
    isModifiableEvent: boolean;
    storedSeries: StoredSpinachSeries;
}) => {
    const [user] = useGlobalAuthedUser();
    const { isEditSummaryActive, toggleEditSummary, disabledExplanation, isDisabled } = useToggleEditSummariesForSeries(
        {
            storedSeries,
            from: 'Meeting Tooltip',
        }
    );

    let iconColor = lightTheme.primary.greenLight;
    if (isDisabled && !isEditSummaryActive) {
        iconColor = lightTheme.neutrals.grayDark;
    } else if (!isEditSummaryActive) {
        iconColor = 'gray';
    }
    return (
        <BootstrapTooltip
            interactive
            arrow
            title={
                <>
                    <BodyRegularOnboard style={{ color: lightTheme.neutrals.white }}>
                        {disabledExplanation || 'Edit summaries before sending to recipients.'}
                    </BodyRegularOnboard>

                    {disabledExplanation ? null : user.hasProFeatures ? (
                        <>
                            <Spacing factor={1 / 3} />
                            <Row style={{ width: 'fit-content' }}>
                                <ProBadge containerStyle={{ marginLeft: 'unset' }} />
                            </Row>
                            <Spacing factor={1 / 5} />
                        </>
                    ) : (
                        <>
                            <Spacing factor={1 / 3} />
                            <Row style={{ width: 'fit-content' }}>
                                <UpgradeToProHyperlink from="Edit Summary Meeting Tooltip" title="Upgrade to Pro" />
                            </Row>
                            <Spacing factor={1 / 5} />
                        </>
                    )}
                </>
            }
        >
            <Create
                onClick={(e) => {
                    e.stopPropagation();
                    if (!isModifiableEvent) {
                        return;
                    }
                    if (!user.hasProFeatures) {
                        return;
                    }
                    if (isDisabled) {
                        return;
                    }
                    toggleEditSummary();
                }}
                style={{
                    color: iconColor,
                    cursor: isModifiableEvent && user.hasProFeatures && !isDisabled ? 'pointer' : 'initial',
                }}
            />
        </BootstrapTooltip>
    );
};

const MeetingHostMeetingTooltip = ({
    storedSeries,
    isEditable,
}: {
    storedSeries: StoredSpinachSeries;
    isEditable: boolean;
}) => {
    const [, setGlobalModal] = useGlobalModal();
    const [user] = useGlobalAuthedUser();
    const spinachIcp = storedSeries.userMetadataList.find((user) => user._id === storedSeries.icpId);
    if (!spinachIcp) {
        return <></>;
    }
    const spinachIcpName = spinachIcp.preferredName;
    const spinachIcpDisplayName = spinachIcpName ? `${spinachIcpName} (${spinachIcp.email})` : spinachIcp.email;
    const isCurrentUserHost = user.email === spinachIcp.email;
    const hostName = isCurrentUserHost ? 'You' : spinachIcpDisplayName;
    return (
        <BootstrapTooltip
            interactive
            arrow
            title={
                <BodyRegularOnboard style={{ color: lightTheme.neutrals.white }}>
                    <span style={{ fontWeight: 'bold' }}>{hostName}</span> {isCurrentUserHost ? 'are' : 'is'} the
                    Spinach host {isCurrentUserHost || !user.isClaimHostEnabled ? '' : `(click to claim host)`}
                </BodyRegularOnboard>
            }
        >
            <AccountBox
                style={{
                    width: '24px',
                    height: '24px',
                    fontSize: '11px',
                    cursor:
                        isCurrentUserHost || !user.isClaimHostEnabled
                            ? 'default'
                            : isEditable
                            ? 'pointer'
                            : 'not-allowed',
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (!isEditable) {
                        return;
                    }

                    if (!user.isClaimHostEnabled) {
                        return;
                    }

                    if (isCurrentUserHost) {
                        return;
                    }

                    setGlobalModal(GlobalModal.ClaimHost, {
                        metadata: { seriesId: storedSeries.id, metadataType: GlobalModalMetadataType.ClaimHost },
                    });
                }}
                htmlColor={isCurrentUserHost ? lightTheme.primary.greenLight : 'gray'}
            />
        </BootstrapTooltip>
    );
};

const MeetingTemplateMeetingTooltip = ({ storedSeries }: MeetingInformationTooltipProps) => {
    const currentMeetingType = storedSeries.meetingType;
    if (!currentMeetingType) {
        return <></>;
    }
    return (
        <BootstrapTooltip
            arrow
            arrowStyle={{
                border: `1px solid rgb(11, 103, 74)`,
                backgroundColor: lightTheme.neutrals.white,
            }}
            tooltipStyle={{
                maxWidth: '350px',
                alignItems: 'start',
                justifyItems: 'start',
                width: '100%',
                backgroundColor: lightTheme.neutrals.white,
                borderRadius: '15px',
                padding: '15px',
                // Arrow needs to be same color
                border: `1px solid rgb(11, 103, 74)`,
            }}
            TransitionComponent={Fade}
            title={<SummaryExampleTemplate meetingType={currentMeetingType} />}
        >
            <Description
                onClick={(e) => {
                    e.stopPropagation();
                }}
                style={{
                    width: '24px',
                    height: '24px',
                    fontSize: '11px',
                }}
                htmlColor={lightTheme.primary.greenLight}
            />
        </BootstrapTooltip>
    );
};

export const MeetingInformationTooltips = ({
    calendarEvent,
    hasEventAlreadyPast,
    storedSeries,
}: {
    calendarEvent: CalendarEvent;
    hasEventAlreadyPast: boolean;
    storedSeries?: StoredSpinachSeries;
}): JSX.Element => {
    if (!storedSeries || !calendarEvent.isScribeOnEvent) {
        return <></>;
    }
    return (
        <>
            <MeetingTemplateMeetingTooltip isEditable={!hasEventAlreadyPast} storedSeries={storedSeries} />
            <Spacing horizontal factor={1 / 3} />
            <SummaryLocationMeetingTooltip isEditable={!hasEventAlreadyPast} storedSeries={storedSeries} />
            <Spacing horizontal factor={1 / 3} />
            <EditSummaryMeetingTooltip isModifiableEvent={!hasEventAlreadyPast} storedSeries={storedSeries} />
            <Spacing horizontal factor={1 / 3} />
            <MeetingHostMeetingTooltip isEditable={!hasEventAlreadyPast} storedSeries={storedSeries} />
            <Spacing horizontal factor={1 / 3} />
        </>
    );
};
