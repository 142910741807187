import { CheckCircle, Warning } from '@material-ui/icons';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { BodyRegularOnboard, lightTheme, responsiveness } from '../../../../styles';
import { IntegrationSettingsButton } from './IntegrationSettingsButton';
import { RemoveIntegrationButton } from './RemoveIntegrationButton';

const Body = styled(BodyRegularOnboard)`
    @media ${responsiveness.sm} {
        font-size: 14px;
    }
`;

export function ConnectedRowContents({
    label,
    isSettingsVisible,
    attentionRequired,
    onSettingsClick,
}: {
    label: ReactNode;
    isSettingsVisible: boolean;
    attentionRequired?: boolean;
    onSettingsClick?: () => void;
}): JSX.Element {
    return (
        <>
            {!isSettingsVisible ? (
                attentionRequired ? (
                    <Warning
                        fontSize="small"
                        style={{ marginLeft: '5px', marginRight: '10px' }}
                        htmlColor={lightTheme.primary.orangeLight}
                    />
                ) : (
                    <CheckCircle
                        fontSize="small"
                        style={{ marginLeft: '5px', marginRight: '10px' }}
                        htmlColor={lightTheme.primary.greenLight}
                    />
                )
            ) : onSettingsClick ? (
                <IntegrationSettingsButton onClick={onSettingsClick} isVisible={true} style={{ marginRight: '5px' }} />
            ) : (
                <></>
            )}

            <Body>{label}</Body>
        </>
    );
}
