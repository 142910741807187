import { Box } from '@material-ui/core';
import styled from 'styled-components';

import { useGlobalVideoAgent } from '../../../..';

const EmphasizedCommand = styled.span`
    color: #00ff00;
    font-weight: 700;
`;

export function AgentAgendaNextTopic(): JSX.Element {
    const { session } = useGlobalVideoAgent();

    if (session?.isConclusionPhase) {
        return <></>;
    }

    const next = session?.followingTopic ? session.followingTopic.title : 'Meeting End';
    let Command = session.settings.isVoicePrimaryCommandKind ? (
        <>
            Say <EmphasizedCommand>&quot;Spinach Next&quot;</EmphasizedCommand> when done
        </>
    ) : (
        <>
            Type <EmphasizedCommand>&quot;Next&quot;</EmphasizedCommand> when done
        </>
    );

    if (session?.isLobbyPhase) {
        Command = session.settings.isVoicePrimaryCommandKind ? (
            <>
                Say{' '}
                <EmphasizedCommand>
                    &quot;Spinach Add {'<'}TOPIC{'>'}&quot;
                </EmphasizedCommand>
            </>
        ) : (
            <>
                Type:{' '}
                <EmphasizedCommand>
                    &quot;Add {'<'}TOPIC{'>'}&quot;
                </EmphasizedCommand>
            </>
        );
    }

    if (session?.isPaused) {
        Command = (
            <>
                Type <EmphasizedCommand>&quot;resume&quot;</EmphasizedCommand> to continue recording
            </>
        );
    }

    return (
        <Box
            style={{
                position: 'fixed',
                bottom: '50px',
                textAlign: 'left',
                fontSize: '40px',
                color: 'white',
                alignSelf: 'normal',
                justifySelf: 'flex-end',
            }}
        >
            <Box>{Command}</Box>
            {session?.isAgendaPhase ? <Box style={{ opacity: next ? 1 : 0 }}>Next: {next}</Box> : null}
            {session?.isLobbyPhase && session.followingTopic ? (
                <Box style={{ opacity: next ? 1 : 0 }}>First up: {next}</Box>
            ) : null}
        </Box>
    );
}
