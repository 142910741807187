import axios from 'axios';

import { getDefaultRequestHeaders } from '@spinach-shared/utils';

import { getAgentSearchParams } from '../../..';
import { getHeaderOpts } from '../../utils/api';

export async function postVideoAgentLLM({
    input,
    temperature,
}: {
    input: string;
    temperature: number;
}): Promise<{ output: string }> {
    const { scribeServer, token } = getAgentSearchParams();

    const response = await axios.post(
        `${scribeServer}/video-agent/llm`,
        {
            input,
            temperature,
        },
        {
            headers: {
                ...getDefaultRequestHeaders(getHeaderOpts()),
                'Content-Type': 'application/json',
                'Cache-Control': 'no-store',
                authorization: token,
            },
        }
    );

    return response.data;
}
