import { CircularProgress, Collapse, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { useState } from 'react';

import { lightTheme } from '../../styles';
import { withContentMasking } from '../../utils';

export type SpinachAutocompleteProps<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
> = Partial<Pick<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>> &
    Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
        isLoading?: boolean;
        inputPlaceholder?: string;
    };

export type CollapsableAutocompleteProps<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
> = AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
    isCollapsed: boolean;
};

export function CollapsableAutocomplete<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>(props: CollapsableAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
    const { isCollapsed } = props;
    return (
        <Collapse mountOnEnter={true} unmountOnExit={true} in={!isCollapsed}>
            <Autocomplete {...props} />
        </Collapse>
    );
}

export function SpinachAutocomplete<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>(props: SpinachAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
    const { isLoading, inputPlaceholder } = props;
    const [textFieldFocused, setTextFieldFocused] = useState(false);
    return (
        <Autocomplete
            {...withContentMasking()}
            size="small"
            style={{ width: '280px', transform: 'scale(0.8)' }}
            autoComplete
            loadingText="Saving..."
            loading={isLoading}
            ListboxProps={{ style: { transform: 'scale(0.8)', borderRadius: '20px' } }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={inputPlaceholder}
                    InputProps={{
                        ...params.InputProps,
                        onFocus: () => {
                            setTextFieldFocused(true);
                        },
                        onBlur: () => {
                            setTextFieldFocused(false);
                        },
                        startAdornment: (
                            <Search style={{ color: textFieldFocused ? lightTheme.primary.orangeLight : 'unset' }} />
                        ),
                        endAdornment: (
                            <>
                                {isLoading ? (
                                    <CircularProgress
                                        size={'20px'}
                                        style={{
                                            color: lightTheme.primary.orangeDark,
                                        }}
                                    />
                                ) : (
                                    params.InputProps.endAdornment
                                )}
                            </>
                        ),
                        style: {
                            borderRadius: '20px',
                            padding: '4px 20px',
                            borderColor: `${lightTheme.primary.green} !important`,
                        },
                    }}
                />
            )}
            {...props}
        />
    );
}
