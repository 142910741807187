import {
    ClientPlatform,
    FeatureFlagSet,
    FeatureToggle,
    Participant,
    StandUpStatus,
    TimeInSeconds,
    UUID,
    UserIntegrationSettings,
    UserMetadata,
    ZoomParticipantRole,
} from '@spinach-shared/types';

import { StartStopProps } from '../time';

export class BaseParticipantProps {
    id: string;
    displayName: string;
    participation: StartStopProps;
    totalTimeInMeeting?: TimeInSeconds;
    standUpStatus?: StandUpStatus;
    connectionIds?: string[];
    role?: ZoomParticipantRole;
    spinachUserId: UUID;
    email?: string;
    integrationSettings?: UserIntegrationSettings;
    featureFlagMap: FeatureFlagSet<FeatureToggle>;
    metadata?: UserMetadata;
    platform: ClientPlatform;
    isAttendingMeeting?: boolean;
    shouldRefreshApp: boolean;
    appVersion?: string;

    constructor(participant: Participant) {
        this.id = participant.id;
        this.displayName = participant.displayName;
        this.participation = new StartStopProps(participant.participation);
        this.totalTimeInMeeting = participant.totalTimeInMeeting;
        this.standUpStatus = participant.standUpStatus;
        this.role = participant.role;
        this.connectionIds = participant.connectionIds;
        this.spinachUserId = participant.spinachUserId;
        this.email = participant.email;
        this.integrationSettings = participant.integrationSettings;
        this.featureFlagMap = participant.featureFlagMap;
        this.platform = participant.platform;
        this.metadata = participant.metadata;
        this.isAttendingMeeting = participant.isAttendingMeeting;
        this.appVersion = participant.appVersion;
        this.shouldRefreshApp = participant.shouldRefreshApp ?? false;
    }

    get isJoiningFromApp(): boolean {
        return this.spinachUserId !== undefined && this.email !== undefined;
    }

    get hasLeft(): boolean {
        return this.participation.areAllFinished;
    }

    get isPresent(): boolean {
        return !this.hasLeft;
    }

    get firstName(): string {
        return (
            this.metadata?.firstName || this.metadata?.preferredName?.split(' ')[0] || this.displayName.split(' ')[0]
        );
    }

    toJSON(): Participant {
        return {
            id: this.id,
            displayName: this.displayName,
            participation: this.participation.toJSON(),
            totalTimeInMeeting: this.totalTimeInMeeting,
            standUpStatus: this.standUpStatus,
            role: this.role,
            connectionIds: this.connectionIds,
            spinachUserId: this.spinachUserId,
            email: this.email,
            shouldRefreshApp: this.shouldRefreshApp,
            integrationSettings: this.integrationSettings,
            featureFlagMap: this.featureFlagMap,
            platform: this.platform,
            metadata: this.metadata,
        };
    }
}
