import Backdrop from '@material-ui/core/Backdrop';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import styled from 'styled-components';

import { SocketStatus } from '../../hooks';
import { BodyBig, BodyLarge } from '../../styles';
import { OutlinedButton } from '../stand-up/OutlinedButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdropLight: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: 'rgba(255,255,255,0.5)',
        },
        backdropDark: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    })
);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Gilroy';
`;

function ReconnectingContent(): JSX.Element {
    return (
        <Container>
            <BodyLarge>Reconnecting...</BodyLarge>
        </Container>
    );
}

function IdleContent({ resetSocketConnection }: { resetSocketConnection: () => void }): JSX.Element {
    const [err, setErr] = useState('');
    return (
        <Container>
            <div style={{ marginBottom: '10px' }}>
                {err ? <BodyBig>{err}</BodyBig> : <BodyLarge>Welcome back!</BodyLarge>}
            </div>
            <OutlinedButton
                title="Reconnect to Spinach"
                onClick={() => {
                    setTimeout(() => {
                        setErr('Unstable connection. Please try again soon.');
                    }, 2000);
                    resetSocketConnection();
                }}
            />
        </Container>
    );
}

export function SimpleBackdrop({
    status,
    resetSocketConnection,
}: {
    status: SocketStatus;
    resetSocketConnection: () => void;
}) {
    const classes = useStyles();
    const isOpen = status === SocketStatus.Idle || status === SocketStatus.Reconnecting;
    const isReconnecting = status === SocketStatus.Reconnecting;
    const className = classes.backdropLight;

    return (
        <div>
            <Backdrop className={className} open={isOpen}>
                {isReconnecting ? (
                    <ReconnectingContent />
                ) : (
                    <IdleContent resetSocketConnection={resetSocketConnection} />
                )}
            </Backdrop>
        </div>
    );
}
