import { CallMade } from '@material-ui/icons';

import { ClientEventType } from '@spinach-shared/types';

import GoogleMeetSrc from '../../../../../assets/google-meet.png';
import ZoomIconSrc from '../../../../../assets/zoom-icon.png';
import { useExperienceTracking } from '../../../../../hooks';
import { BodyRegularOnboard, ButtonSize, HeaderThree } from '../../../../../styles';
import { URLUtil, isChromeExtensionPlatform, isZoomPlatform } from '../../../../../utils';
import { Column, Row, Spacing } from '../../../../common';
import { IntegrationButtonText } from '../../../../common/settings/AppIntegrationRow';
import { OutlinedButton } from '../../../../stand-up/OutlinedButton';
import { ConnectedRowContents } from '../ConnectedRowContents';
import { Label } from '../common';

export function SpinachAppZoomIntegrationSection(): JSX.Element {
    const track = useExperienceTracking();
    return (
        <Row>
            <Column>
                <Row vCenter>
                    <img
                        src={ZoomIconSrc}
                        style={{ minWidth: '24px', maxWidth: '24px', height: '24px', marginRight: '15px' }}
                    />
                    <Label>Zoom</Label>

                    {isZoomPlatform() ? (
                        <ConnectedRowContents isSettingsVisible={false} label={'Connected'} />
                    ) : (
                        <OutlinedButton
                            title={IntegrationButtonText.Add}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: `Add Zoom Integration`,
                                });
                                track(ClientEventType.ZoomAppMarketplaceIntegrationsClick);
                                URLUtil.openURL('https://marketplace.zoom.us/apps/414jwrO_SsOksk6SAsMXgg', {
                                    redirectThroughBackendWhenOnZoom: true,
                                });
                            }}
                            size={ButtonSize.Mini}
                            endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                            labelStyles={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        />
                    )}
                </Row>
                <Spacing factor={1 / 4} />
                <BodyRegularOnboard style={{ marginLeft: '10px', fontSize: '14px' }}>
                    View Spinach directly in Zoom
                </BodyRegularOnboard>
            </Column>
        </Row>
    );
}

export function SpinachAppGoogleMeetIntegrationSection(): JSX.Element {
    const track = useExperienceTracking();
    return (
        <Row>
            <Column>
                <Row vCenter>
                    <img
                        src={GoogleMeetSrc}
                        style={{ minWidth: '24px', maxWidth: '24px', height: '24px', marginRight: '15px' }}
                    />
                    <Label>Google Meet</Label>

                    {isChromeExtensionPlatform() ? (
                        <ConnectedRowContents isSettingsVisible={false} label={'Connected'} />
                    ) : (
                        <Column style={{ alignItems: 'start' }}>
                            <OutlinedButton
                                title={IntegrationButtonText.Add}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Add Google Meet Integration`,
                                    });
                                    track(ClientEventType.AddChromeExtentionClick);
                                    URLUtil.openURL(
                                        'https://chrome.google.com/webstore/detail/spinachio-daily-standup-t/eippeohhelpgpnchcfigkjmpepicilig',
                                        { redirectThroughBackendWhenOnZoom: true }
                                    );
                                }}
                                size={ButtonSize.Mini}
                                endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                                labelStyles={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            />
                        </Column>
                    )}
                </Row>
                <Spacing factor={1 / 4} />
                <BodyRegularOnboard style={{ marginLeft: '10px', fontSize: '14px' }}>
                    View Spinach directly in Meet
                </BodyRegularOnboard>
            </Column>
        </Row>
    );
}

export function MeetingPlatformsIntegrationSection() {
    return (
        <>
            <Row>
                <HeaderThree>Meeting Platforms</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Improved integration with meeting platform</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            <SpinachAppZoomIntegrationSection />
            <Spacing />
            <SpinachAppGoogleMeetIntegrationSection />
        </>
    );
}
