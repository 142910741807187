import { SpinachIntegration } from './Integration';
import { DetailedTicket } from './Jira';

export type { CreateTicketRequest } from '@spinach-shared/schemas';

export type AddContextToTicketRequest = {
    context: string[];
    issueId: string;
    summaryLink: string;
    ticketSource: TicketSource;
};

export type PatchTicketUserSettingsRequest = {
    projectId: string;
    subProjectId?: string;
};

export type TicketSource = TicketIntegration;

export const TICKET_SOURCE_MAP: {
    [key in Capitalize<TicketSource>]: TicketSource;
} = {
    Jira: SpinachIntegration.Jira,
    Asana: SpinachIntegration.Asana,
    Linear: SpinachIntegration.Linear,
    ClickUp: SpinachIntegration.Clickup,
    Trello: SpinachIntegration.Trello,
    MondayDotCom: SpinachIntegration.MondayDotCom,
};

export type ClickUpDetailedTicket = DetailedTicket & {
    creatorEmail: string;
};

export type TicketProject = {
    id: string;
    name: string;
};

export type TicketIntegration = Extract<
    SpinachIntegration,
    | SpinachIntegration.Asana
    | SpinachIntegration.Clickup
    | SpinachIntegration.Jira
    | SpinachIntegration.Linear
    | SpinachIntegration.Trello
    | SpinachIntegration.MondayDotCom
>;

export type TicketIntegrationWithSubProject = Extract<
    SpinachIntegration,
    SpinachIntegration.Clickup | SpinachIntegration.Trello | SpinachIntegration.MondayDotCom
>;

export type TicketIntegrationConfiguration = {
    project?: TicketProject;
    subProject?: TicketProject;
};
