import { Modal } from '@material-ui/core';
import { useEffect } from 'react';
import styled from 'styled-components';

import { MeditationAgendaItemProps, SpinachSeriesProps } from '@spinach-shared/models';
import { AgendaItemBaseRequest, AgendaItemSource, ClientSocketEvent } from '@spinach-shared/types';

import { GlobalModal } from '../../../atoms';
import { useGlobalAuthedUser, useGlobalLiveSeries, useGlobalModal } from '../../../hooks';
import { useGlobalMeetingSocket } from '../../../hooks/useGlobalSocket';
import { BodyBig, BodyLarger, BodyRegular, responsiveness } from '../../../styles';
import { createWebsocketPayload } from '../../../utils';
import { ModalContent } from '../../series/common';
import { LongPressPrimaryButton } from '../../stand-up/LongPressPrimaryButton';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { Column, Row, Spacing } from '../framing';

const ResponsiveModalContent = styled(ModalContent)`
    max-width: 450px;
    min-width: 450px;

    @media ${responsiveness.thinnerThanSM} {
        max-width: 450px;
        min-width: 80%;
    }

    @media ${responsiveness.thinnerThanXS} {
        min-width: 90%;
    }
`;

const MeditationText = styled(BodyBig)`
    line-height: 150%;
    text-align: left;
`;

const MeditationList = styled.ul`
    padding-left: 25px;
    margin-top: 10px;
`;

export function MeditationPromptModalContainer(): JSX.Element {
    const [series] = useGlobalLiveSeries();
    const meditationItem = series?.agenda.meditationItem;

    if (!series || !meditationItem) {
        return <></>;
    }

    return <MeditationPromptModal series={series} meditationItem={meditationItem} />;
}

function MeditationPromptModal({
    series,
    meditationItem,
}: {
    series: SpinachSeriesProps;
    meditationItem: MeditationAgendaItemProps;
}): JSX.Element {
    const [socket] = useGlobalMeetingSocket();
    const [user] = useGlobalAuthedUser();
    const [openModal, setOpenModal] = useGlobalModal();
    const meditionPromptIsOpen = openModal === GlobalModal.MeditationPrompt;

    useEffect(() => {
        const meditationAudioStarted = meditationItem.audioStartedAt;
        const viewingMeditation = series && series.currentAgendaItem?.source === AgendaItemSource.Meditation;

        if ((!viewingMeditation || meditationAudioStarted) && meditionPromptIsOpen) {
            setOpenModal(null);
        }
    }, [meditationItem.audioStartedAt, series.currentAgendaItem]);

    const startMeditationAudio = () => {
        if (meditationItem.audioStartedAt) {
            return;
        }

        const payload = createWebsocketPayload<AgendaItemBaseRequest>({
            spinachUserId: user.spinachUserId,
            seriesSlug: series.slug,
            itemId: series.currentAgendaItem?.id || '',
        });

        socket.emit(ClientSocketEvent.MeditationAudioStarting, payload);
        setOpenModal(null);
    };

    return (
        <Modal open={openModal === GlobalModal.MeditationPrompt}>
            <ResponsiveModalContent>
                <Column>
                    <Row centered>
                        <BodyLarger style={{ textTransform: 'uppercase' }}>Begin With Meditation</BodyLarger>
                    </Row>
                    <Row>
                        <Spacing factor={0.5} />
                    </Row>
                    <Row>
                        <BodyRegular style={{ fontWeight: 'bold' }}>Getting started</BodyRegular>
                    </Row>
                    <MeditationList>
                        <li>
                            <MeditationText style={{ fontWeight: 'bold' }}>
                                Ensure the mic is muted for all participants.
                            </MeditationText>
                        </li>
                        <li>
                            <MeditationText>
                                Find a comfortable position, close your eyes, and focus on your breath.
                            </MeditationText>
                        </li>
                    </MeditationList>
                    <Row>
                        <MeditationText>
                            Give it a try! This audio will guide your team through a 3 minute breathing mediation.
                        </MeditationText>
                    </Row>
                    <Spacing factor={1} />
                    <Row centered>
                        <LongPressPrimaryButton
                            id="start-meditation-audio-from-modal"
                            title={'Start Meditation'}
                            pressTitle="Starting meditation for everyone"
                            fixedWidth={300}
                            pressTime={450}
                            onClick={startMeditationAudio}
                        />
                    </Row>
                    <Row>
                        <Spacing factor={0.83} />
                    </Row>
                    <Row centered>
                        <SecondaryButton
                            title={'Skip meditation'}
                            fadeInDuration={150}
                            onClick={() => {
                                const payload = createWebsocketPayload<AgendaItemBaseRequest>({
                                    spinachUserId: user.spinachUserId,
                                    seriesSlug: series.slug,
                                    itemId: series.currentAgendaItem?.id || '',
                                });

                                socket.emit(ClientSocketEvent.AgendaProgressing, payload);
                                setOpenModal(null);
                            }}
                        />
                    </Row>
                </Column>
            </ResponsiveModalContent>
        </Modal>
    );
}
