import { EmailVerificationCodeResponse, SpinachAPIPath, VerifyEmailCodeRequest } from '@spinach-shared/types';

import { postSpinachAPI } from '..';

export async function postVerifyCode(request: VerifyEmailCodeRequest): Promise<EmailVerificationCodeResponse> {
    try {
        const response = await postSpinachAPI<EmailVerificationCodeResponse>(SpinachAPIPath.VerifyCode, request);
        if (!response) {
            return { success: false, user: null };
        }
        return response;
    } catch {
        return { success: false, user: null };
    }
}
