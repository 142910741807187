import { AgendaItemStatus, ILegacyAgendaItemProps, TimeInSeconds } from '@spinach-shared/types';

import { BaseAgendaItemProps } from './BaseAgendaItemProps';

export class LegacyAgendaItemProps extends BaseAgendaItemProps {
    intendedDuration?: TimeInSeconds;
    status: AgendaItemStatus;
    isMutable: boolean;

    constructor(props: ILegacyAgendaItemProps) {
        super(props);

        this.intendedDuration = props.intendedDuration;
        this.status = props.status;
        this.isMutable = props.isMutable;
    }
}
