const cacheStore = new Map<string, Promise<any>>();

export async function inMemoryCache<R>(
    key: string,
    subkey: string,
    ttl: number,
    functionToCache: () => Promise<R>
): Promise<R> {
    const cacheKey = `${key}-${subkey}`;

    if (cacheStore.has(cacheKey)) {
        return await (cacheStore.get(cacheKey) as Promise<R>);
    } else {
        const promise = functionToCache();
        cacheStore.set(cacheKey, promise);

        // Automatically clear the cache after the specified TTL
        setTimeout(() => cacheStore.delete(cacheKey), ttl);

        return await promise;
    }
}
