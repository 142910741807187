import { useEffect } from 'react';

export function usePreventBackspace() {
    useEffect(() => {
        function handleKeydown(event: KeyboardEvent) {
            if (
                event.key === 'Backspace' &&
                (event.target as HTMLElement).tagName !== 'INPUT' &&
                (event.target as HTMLElement).tagName !== 'TEXTAREA'
            ) {
                event.preventDefault();
            }
        }
        window.addEventListener('keydown', handleKeydown);
        return () => window.removeEventListener('keydown', handleKeydown);
    }, []);
}
