import { Collapse, createStyles, makeStyles } from '@material-ui/core';
import { ArrowRight, KeyboardArrowDown } from '@material-ui/icons';
import { useEffect, useState } from 'react';

import { JiraRootStatus } from '@spinach-shared/constants';
import { YTBAgendaItemProps } from '@spinach-shared/models';
import { ClientEventType } from '@spinach-shared/types';

import { ReactComponent as JiraLogo } from '../../assets/jira-logo.svg';
import { useExperienceTracking } from '../../hooks';
import { BodyLarge } from '../../styles';
import { JiraBoardView, Row, Spacing } from '../common';

const useCollapseStyles = makeStyles(() =>
    createStyles({
        root: {
            overflow: 'hidden',
            maxHeight: '250px',
        },
        wrapper: {
            maxHeight: '250px',
            paddingBottom: '50px',
        },
    })
);

export const JiraBoardHeaderContainer = ({ currentItem }: { currentItem: YTBAgendaItemProps }) => {
    const track = useExperienceTracking();

    const currentAgendaItemsTickets = currentItem.ticketsOnBoard;
    const ticketsInProgress = currentItem.ticketsByStatus[JiraRootStatus.InProgress];

    useEffect(() => {
        setIsJiraBoardExpanded(false);
    }, [currentItem.id]);

    const collapseStyles = useCollapseStyles();
    const [isJiraBoardExpanded, setIsJiraBoardExpanded] = useState(false);

    if (!currentAgendaItemsTickets.length) {
        return null;
    }
    return (
        <div>
            <Row
                style={{ cursor: 'pointer', alignItems: 'center' }}
                onClick={() => {
                    setIsJiraBoardExpanded(!isJiraBoardExpanded);
                    track(ClientEventType.UserClickJiraBoardExpansion, {
                        Action: isJiraBoardExpanded ? 'collapse' : 'expand',
                    });
                }}
            >
                <JiraLogo style={{ paddingRight: '5px' }} />
                <BodyLarge>Jira ({ticketsInProgress.length} In Progress)</BodyLarge>
                {isJiraBoardExpanded ? <KeyboardArrowDown /> : <ArrowRight />}
            </Row>
            <Collapse classes={collapseStyles} in={isJiraBoardExpanded}>
                <JiraBoardView currentItem={currentItem} />
            </Collapse>
            <Spacing />
        </div>
    );
};
