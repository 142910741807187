import { OMNIBOX_DEMO_JIRA_TICKETS } from '@spinach-shared/constants';
import { SpinachUpdateType, TypedUpdate } from '@spinach-shared/types';

import { usePersonaDemo, useSeriesReality } from '../../hooks';
import { PulsingHint } from '../common';

export function LiveUpdateDemoHints({
    isInNavDrawer,
    typedUpdates,
    typedUpdate,
}: {
    isInNavDrawer: boolean;
    typedUpdates: TypedUpdate[];
    typedUpdate: TypedUpdate;
}): JSX.Element {
    const { isDemoSeries } = useSeriesReality();
    const isPersonaDemo = usePersonaDemo();

    const isVisibleInOldDemo =
        !!isInNavDrawer &&
        typedUpdate.updateType === SpinachUpdateType.Today &&
        isDemoSeries &&
        typedUpdates.some((u) => !!u.ticketData) &&
        typedUpdates.filter((u) => !u.ticketData)[0]?.text.length === 0 &&
        typedUpdates.filter((u) => !u.ticketData)[0]?.id === typedUpdate.id &&
        typedUpdates.some(
            (update) =>
                !!update.ticketData?.ticket.title && OMNIBOX_DEMO_JIRA_TICKETS.includes(update.ticketData?.ticket.title)
        );

    const isVisibleInPersonaDemo =
        !!isInNavDrawer &&
        typedUpdate.updateType === SpinachUpdateType.Today &&
        isDemoSeries &&
        !typedUpdates.some((u) => !!u.text);

    const isVisible = isPersonaDemo ? isVisibleInPersonaDemo : isVisibleInOldDemo;

    return (
        <>
            <PulsingHint
                pulseStyles={{
                    marginTop: '20px',
                }}
                isVisible={isVisible}
            />
            <PulsingHint
                pulseStyles={{
                    marginLeft: '-25px',
                    marginTop: '25px',
                }}
                isVisible={
                    // Rules for Jira Plus button to display pulse
                    !!isInNavDrawer &&
                    typedUpdate.updateType === SpinachUpdateType.Today &&
                    isDemoSeries &&
                    typedUpdates.some((u) => !!u.ticketData) &&
                    typedUpdates.find((u) => !u.text)?.id === typedUpdate.id &&
                    !typedUpdates.some(
                        (update) =>
                            !!update.ticketData?.ticket.title &&
                            OMNIBOX_DEMO_JIRA_TICKETS.includes(update.ticketData?.ticket.title)
                    )
                }
            />
        </>
    );
}
