import { APIPathParam, KnowledgeBaseIntegration, KnowledgeBaseSettings, SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '..';

export async function postSetupKnowledgeBaseSummary({
    knowledgeBaseIntegration,
}: {
    knowledgeBaseIntegration: KnowledgeBaseIntegration;
}): Promise<KnowledgeBaseSettings | undefined> {
    return await postSpinachAPI<KnowledgeBaseSettings>(
        SpinachAPIPath.SetupKnowledgeBaseSummary.replaceAll(
            APIPathParam.KnowledgeBaseIntegration,
            knowledgeBaseIntegration
        ),
        {}
    );
}
