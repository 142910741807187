import { useEffect } from 'react';

import { ClientUser } from '@spinach-shared/models';
import { AdminUserOption, ClientEventType } from '@spinach-shared/types';

import {
    getUserAsAdmin,
    getUserHistoriesAsAdmin,
    getUserOptionsAsAdmin,
    useExperienceTracking,
    useGlobalAdmin,
} from '../../../../../..';
import { Row, SpinachAutocomplete } from '../../../../common';

export function AdminUserSearch(): JSX.Element {
    const { state, setState } = useGlobalAdmin();
    const track = useExperienceTracking();

    useEffect(() => {
        async function fetchUserList() {
            setState((updatedState) => ({
                ...updatedState,
                isLoadingUserOptions: true,
            }));

            const options = await getUserOptionsAsAdmin();

            setState((updatedState) => ({
                ...updatedState,
                userOptions: options,
                isLoadingUserOptions: false,
            }));
        }

        fetchUserList();
    }, []);

    const selectedValue =
        state.searchedUser && state.userOptions
            ? state.userOptions.find((u) => u.id === state.searchedUser?.spinachUserId)
            : undefined;

    return (
        <Row>
            <SpinachAutocomplete
                style={{ width: '400px', transform: 'scale(0.8)' }}
                isLoading={state.isLoadingUserOptions || state.isLoadingSelectedUser || state.isLoadingHistoryOptions}
                options={state.userOptions}
                inputPlaceholder="Find a user"
                value={selectedValue}
                getOptionLabel={(userOption: AdminUserOption) => {
                    if (userOption.name) {
                        return `${userOption.name} (${userOption.email})`;
                    }
                    return userOption.email;
                }}
                onChange={async (event, userOption) => {
                    try {
                        if (!userOption) {
                            setState((updatedState) => ({
                                ...updatedState,
                                searchedUser: null,
                                searchedHistory: null,
                                userHistoryOptions: [],
                                isLoadingHistoryOptions: false,
                                isLoadingSelectedUser: false,
                            }));
                            return;
                        }

                        setState((updatedState) => ({
                            ...updatedState,
                            searchedHistory: null,
                            isLoadingSelectedUser: true,
                            isLoadingHistoryOptions: true,
                        }));

                        const response = await getUserAsAdmin(userOption.id);
                        const historyResponse = await getUserHistoriesAsAdmin(userOption.id);

                        if (response.user) {
                            setState((updatedState) => ({
                                ...updatedState,
                                searchedUser: new ClientUser(response.user!),
                                userHistoryOptions: historyResponse?.length ? historyResponse : [],
                                isLoadingHistoryOptions: false,
                                isLoadingSelectedUser: false,
                            }));
                        } else {
                            setState((updatedState) => ({
                                ...updatedState,
                                isLoadingHistoryOptions: false,
                                isLoadingSelectedUser: false,
                            }));
                        }

                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Selected User in Admin Search`,
                            SelectedUserId: userOption.id,
                            HistoriesLoadedForSelectedUser: historyResponse?.length,
                        });
                    } catch (error) {
                        setState((updatedState) => ({
                            ...updatedState,
                            isLoadingHistoryOptions: false,
                            isLoadingSelectedUser: false,
                        }));
                    }
                }}
            />
        </Row>
    );
}
