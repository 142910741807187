import { useEffect } from 'react';

import { ScrollArea, useGlobalAiDashboard, useGlobalAuthedUser } from '../../../../..';
import { HeaderThreeOnboard } from '../../../../styles';
import { Row, Spacing } from '../../../common';
import { MixpanelEmbed } from './mixpanel-embed';
import { UserContentAccess } from './user-content-access';

export function AdminSection(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { openMeetingsSection } = useGlobalAiDashboard();
    useEffect(() => {
        if (!user.hasAdminSectionAccess) {
            openMeetingsSection();
        }
    }, [user, openMeetingsSection]);

    if (!user.hasAdminSectionAccess) {
        return <></>;
    }

    return (
        <>
            <Spacing factor={1 / 2} />
            <Row>
                <HeaderThreeOnboard>Admin</HeaderThreeOnboard>
            </Row>
            <ScrollArea sidePadding={0}>
                <Spacing />
                {user.hasAdminUserContentAccess ? <UserContentAccess /> : null}

                {user.hasAdminMixpanelEmbedAccess ? <MixpanelEmbed /> : null}
            </ScrollArea>
        </>
    );
}
