import { TextField } from '@material-ui/core';
import { Titles } from '@spinach-clients/constants';
import { useState } from 'react';
import styled from 'styled-components';

import { IntegrationCode } from '@spinach-shared/types';

import { ReactComponent as Checkmark } from '../../assets/checkmark-green-outline.svg';
import Asana from '../../assets/integrations/asana.png';
import Clickup from '../../assets/integrations/clickup.png';
import Discord from '../../assets/integrations/discord.png';
import Github from '../../assets/integrations/github.png';
import Gitlab from '../../assets/integrations/gitlab.png';
import GoogleChat from '../../assets/integrations/google-chat.png';
import GoogleMeet from '../../assets/integrations/google-meet.png';
import Jira from '../../assets/integrations/jira.png';
import Linear from '../../assets/integrations/linear.png';
import MicrosoftTeams from '../../assets/integrations/microsoft-teams.png';
import Monday from '../../assets/integrations/monday.png';
import Notion from '../../assets/integrations/notion.png';
import Slack from '../../assets/integrations/slack.png';
import Telegram from '../../assets/integrations/telegram.png';
import Trello from '../../assets/integrations/trello.png';
import WhatsApp from '../../assets/integrations/whatsapp.png';
import Zoom from '../../assets/integrations/zoom.png';
import { ReactComponent as SLogo } from '../../assets/s-logo.svg';
import { BodyBigOnboard, BodyRegular, HeaderThreeOnboard } from '../../styles';
import { isZoomPlatform } from '../../utils';
import { Column, Row, Spacing, SpinachStationary, ViewContainer } from '../common';
import { useSpinachInputStyles } from '../input';
import { PrimaryButton, ScrollArea } from '../stand-up';
import SecondaryButton from '../stand-up/SecondaryButton';

export type IntegrationType = {
    code: IntegrationCode;
    url?: string;
    label?: string;
};

const videoIntegrations: IntegrationType[] = [
    { code: IntegrationCode.Zoom, url: Zoom },
    { code: IntegrationCode.GoogleMeet, url: GoogleMeet },
    { code: IntegrationCode.MicrosoftTeams, url: MicrosoftTeams },
    { code: IntegrationCode.Other, label: 'Other' },
];

const messagingIntegrations: IntegrationType[] = [
    { code: IntegrationCode.Slack, url: Slack },
    { code: IntegrationCode.GoogleChat, url: GoogleChat },
    { code: IntegrationCode.MicrosoftTeams, url: MicrosoftTeams },
    { code: IntegrationCode.Discord, url: Discord },
    { code: IntegrationCode.WhatsApp, url: WhatsApp },
    { code: IntegrationCode.Telegram, url: Telegram },
    { code: IntegrationCode.Other, label: 'Other' },
];

const messagingIntegrationsShort: IntegrationType[] = [
    { code: IntegrationCode.Slack, url: Slack },
    { code: IntegrationCode.GoogleChat, url: GoogleChat },
    { code: IntegrationCode.MicrosoftTeams, url: MicrosoftTeams },
];

const projectMgmtIntegrations: IntegrationType[] = [
    { code: IntegrationCode.JiraSoftware, url: Jira },
    { code: IntegrationCode.Asana, url: Asana },
    { code: IntegrationCode.Notion, url: Notion },
    { code: IntegrationCode.Trello, url: Trello },
    { code: IntegrationCode.Monday, url: Monday },
    { code: IntegrationCode.ClickUp, url: Clickup },
    { code: IntegrationCode.Github, url: Github },
    { code: IntegrationCode.Gitlab, url: Gitlab },
    { code: IntegrationCode.Linear, url: Linear },
    { code: IntegrationCode.Other, label: 'Other' },
];

const projectMgmtIntegrationsShort: IntegrationType[] = [
    { code: IntegrationCode.JiraSoftware, url: Jira },
    { code: IntegrationCode.Asana, url: Asana },
    { code: IntegrationCode.Notion, url: Notion },
];

const OtherTextField = styled(TextField)`
    padding: 5%;
    width: 60%;
    min-width: 200px;
`;

const IntegrationLabels = styled(BodyBigOnboard)`
    margin-top: 10px;
    text-align: left;
`;

const SubHeader = styled(BodyBigOnboard)`
    align-self: center;
`;

const IntegrationContainer = styled.div`
    padding: 5px 10px;
    margin-left: 30px;
`;

const IntegrationButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const SeeMoreBotton = styled(SecondaryButton)`
    text-decoration: none;
    padding: 10px;
`;

const Stationary = styled(SpinachStationary)`
    align-items: flex-start;
    text-align: center;
`;

const ExpandingSpacer = styled(Spacing)`
    flex-grow: 1;
`;

export const NextButtonContainer = styled.div`
    justify-content: center;
    align-items: center;
`;

const NavButtonsContainer = styled(NextButtonContainer)`
    flex-direction: row;
    justify-content: center;
    display: flex;
    width: 100%;
`;

const ButtonBase = styled.button<{ url?: string }>`
    background-size: contain;
    width: 100px;
    height: 50px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px #b8b8c5 solid;
    margin: 4px;
    padding: 0;
    background-image: ${(props) => (props.url ? `url(${props.url})` : 'initial')};
    background-color: transparent;
    display: inline-block;
    text-align: center;
`;

const SelectedCheckmark = styled(Checkmark)`
    position: absolute;
    top: 22%;
    right: 33%;
`;

const ButtonContainer = styled.div`
    position: relative;
    display: flex;
    padding: 0 3px;
`;

const ButtonImage = styled.img<{ isChecked?: boolean }>`
    width: 93px;
    opacity: ${(props) => (props.isChecked ? `.3` : `initial`)};
`;

const OtherText = styled(BodyRegular)<{ isChecked?: boolean }>`
    width: 100px;
    height: 36.3594px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${(props) => (props.isChecked ? `.3` : `initial`)};
`;

export type IntegrationSelectionProps = {
    integrationsMessaging: string;
    integrationsProjectMgmt: string[];
    integrationsVideo: string;
    integrationsMessagingOther: string;
    integrationsVideoOther: string;
    integrationsProjectMgmtOther: string;
    setIntegrationsVideo: (integrationsVideo: string) => void;
    setIntegrationsMessaging: (integrationsMessaging: string) => void;
    setIntegrationsProjectMgmt: (IntegrationSelectionProps: string[]) => void;
    setMessagingOther: (integrationsMessagingOther: string) => void;
    setVideoOther: (integrationsVideoOther: string) => void;
    setProjectMgmtOther: (integrationsProjectMgmtOther: string) => void;
    onSubmit: () => Promise<void>;
};

export type InegrationSectionProps = {
    selectedIntegration?: string;
    selectedIntegrations?: string[];
    integrationOther: string;
    integrationList: IntegrationType[];
    setIntegration?: (selectedIntegration: string) => void;
    handleIntPrjMgmt?: (code: string, isChecked: boolean) => void;
    setIntegrationOther: (integrationOther: string) => void;
    isSeeingAll?: boolean;
    setIsSeeingAll?: (isSeeingAll: boolean) => void;
    label: string;
};

export function IntegrationSection({
    selectedIntegration,
    selectedIntegrations,
    integrationOther,
    integrationList,
    setIntegration,
    handleIntPrjMgmt,
    setIntegrationOther,
    isSeeingAll,
    setIsSeeingAll,
    label,
}: InegrationSectionProps) {
    const classes = useSpinachInputStyles({ value: '' });

    return (
        <IntegrationContainer>
            <IntegrationLabels style={{ marginLeft: '8px' }}>{label}</IntegrationLabels>
            <IntegrationButtonContainer>
                {integrationList.map((integration) => {
                    let isChecked = false;

                    if (selectedIntegration) {
                        isChecked = selectedIntegration === integration.code;
                    } else if (selectedIntegrations?.length) {
                        isChecked = selectedIntegrations.includes(integration.code);
                    }

                    return (
                        <ButtonBase
                            key={integration.code}
                            onClick={() => {
                                if (integration.code !== IntegrationCode.Other && !selectedIntegrations) {
                                    setIntegrationOther('');
                                }
                                if (setIntegration) {
                                    setIntegration(integration.code);
                                } else if (handleIntPrjMgmt) {
                                    handleIntPrjMgmt(integration.code, isChecked);
                                }
                            }}
                        >
                            <ButtonContainer>
                                {integration.url ? (
                                    <ButtonImage isChecked={isChecked} src={integration.url}></ButtonImage>
                                ) : (
                                    <></>
                                )}

                                {isChecked ? <SelectedCheckmark /> : <></>}
                                <OtherText isChecked={isChecked}>{integration.label}</OtherText>
                            </ButtonContainer>
                        </ButtonBase>
                    );
                })}
                {!isSeeingAll && setIsSeeingAll ? (
                    <SeeMoreBotton onClick={() => setIsSeeingAll(true)} title={'See more'} />
                ) : (
                    <></>
                )}
                {selectedIntegration === IntegrationCode.Other ||
                selectedIntegrations?.includes(IntegrationCode.Other) ? (
                    <Row style={{ marginLeft: '8px' }}>
                        <Column>
                            <IntegrationLabels>Please specify</IntegrationLabels>
                            <OtherTextField
                                value={integrationOther}
                                onChange={(e) => setIntegrationOther(e.target.value)}
                                placeholder={'Other tool'}
                                InputProps={{ classes: { root: classes.base } }}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Column>
                    </Row>
                ) : (
                    <></>
                )}
            </IntegrationButtonContainer>
        </IntegrationContainer>
    );
}

export function IntegrationSelection({
    integrationsMessaging,
    integrationsProjectMgmt,
    integrationsVideo,
    setIntegrationsVideo,
    setIntegrationsMessaging,
    setIntegrationsProjectMgmt,
    onSubmit,
    integrationsMessagingOther,
    integrationsVideoOther,
    integrationsProjectMgmtOther,
    setMessagingOther,
    setVideoOther,
    setProjectMgmtOther,
}: IntegrationSelectionProps) {
    const handleAddInt = (code: string, isChecked: boolean) => {
        if (!isChecked) {
            setIntegrationsProjectMgmt([...integrationsProjectMgmt, code]);
        } else {
            setIntegrationsProjectMgmt([...integrationsProjectMgmt.filter((x) => x !== code)]);
            if (code === IntegrationCode.Other) {
                setProjectMgmtOther('');
            }
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    const [isSeeingAllMessaging, setIsSeeingAllMessaging] = useState(false);
    const messagingIntegrationsList = isSeeingAllMessaging ? messagingIntegrations : messagingIntegrationsShort;

    const [isSeeingAllProjectMgmt, setIsSeeingAllProjectMgmt] = useState(false);
    const projectMgmtIntegrationsList = isSeeingAllProjectMgmt ? projectMgmtIntegrations : projectMgmtIntegrationsShort;

    const isVideoOtherValidated = Boolean(
        integrationsVideo !== IntegrationCode.Other ||
            (integrationsVideo === IntegrationCode.Other && integrationsVideoOther.length)
    );

    const isMessagingOtherValidated = Boolean(
        integrationsMessaging !== IntegrationCode.Other ||
            (integrationsMessaging === IntegrationCode.Other && integrationsMessagingOther.length)
    );

    const isProjectMgmtOtherValidated = Boolean(
        !integrationsProjectMgmt?.includes(IntegrationCode.Other) ||
            (integrationsProjectMgmt?.includes(IntegrationCode.Other) && integrationsProjectMgmtOther.length)
    );

    const cannotProceed = Boolean(
        !integrationsMessaging ||
            !integrationsVideo ||
            !integrationsProjectMgmt.length ||
            !isVideoOtherValidated ||
            !isMessagingOtherValidated ||
            !isProjectMgmtOtherValidated
    );

    return (
        <ViewContainer>
            <Stationary>
                <SLogo />
                <HeaderThreeOnboard>Spinach integrates with your favorite tools</HeaderThreeOnboard>
                <SubHeader>Select the platforms you currently use</SubHeader>
                <Spacing />
                <ScrollArea style={{ padding: '0', height: '400px' }}>
                    {isZoomPlatform() ? null : (
                        <IntegrationSection
                            selectedIntegration={integrationsVideo}
                            integrationList={videoIntegrations}
                            integrationOther={integrationsVideoOther}
                            setIntegration={setIntegrationsVideo}
                            setIntegrationOther={setVideoOther}
                            label={'Video conferencing:'}
                        />
                    )}
                    {integrationsVideo ? (
                        <IntegrationSection
                            selectedIntegration={integrationsMessaging}
                            integrationList={messagingIntegrationsList}
                            integrationOther={integrationsMessagingOther}
                            setIntegration={setIntegrationsMessaging}
                            setIntegrationOther={setMessagingOther}
                            isSeeingAll={isSeeingAllMessaging}
                            setIsSeeingAll={setIsSeeingAllMessaging}
                            label={'Messaging:'}
                        />
                    ) : (
                        <></>
                    )}
                    {integrationsMessaging ? (
                        <IntegrationSection
                            selectedIntegrations={integrationsProjectMgmt}
                            integrationList={projectMgmtIntegrationsList}
                            integrationOther={integrationsProjectMgmtOther}
                            handleIntPrjMgmt={handleAddInt}
                            setIntegrationOther={setProjectMgmtOther}
                            isSeeingAll={isSeeingAllProjectMgmt}
                            setIsSeeingAll={setIsSeeingAllProjectMgmt}
                            label={'Project management:'}
                        />
                    ) : (
                        <></>
                    )}
                </ScrollArea>
                <ExpandingSpacer />

                <NavButtonsContainer>
                    <PrimaryButton
                        isLoading={isLoading}
                        title={Titles.Next}
                        disabled={cannotProceed}
                        onClick={async () => {
                            setIsLoading(true);
                            await onSubmit();
                            setIsLoading(false);
                        }}
                    />
                </NavButtonsContainer>
            </Stationary>
        </ViewContainer>
    );
}
