import { AgendaItemSource, ISOString, MeditationItem } from '@spinach-shared/types';

import { BaseAgendaItemProps } from './BaseAgendaItemProps';

export class MeditationAgendaItemProps extends BaseAgendaItemProps {
    audioStartedAt?: ISOString;

    constructor(props: MeditationItem) {
        super(props);

        this.audioStartedAt = props.audioStartedAt;
    }

    get elapsedMilliseconds(): number {
        if (this.audioStartedAt) {
            const now = Date.now();
            const startedAt = new Date(this.audioStartedAt);

            return now - startedAt.getTime();
        }

        return 0;
    }

    toJSON(): MeditationItem {
        return {
            ...super.toJSON(),
            title: 'Meditation',
            source: AgendaItemSource.Meditation,
            isParticipantAgendaItem: false,
            audioStartedAt: this.audioStartedAt,
        };
    }
}
