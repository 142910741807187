import { SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function getCustomBrandedImageUriForUser(): Promise<string | undefined> {
    try {
        const response = await getSpinachAPI<{ presignedUrl: string }>(SpinachAPIPath.BrandedImage);
        return response?.presignedUrl;
    } catch (err) {
        return undefined;
    }
}

export async function getSpinachBackgroundImageUri(): Promise<string | undefined> {
    try {
        const response = await getSpinachAPI<{ presignedUrl: string }>(SpinachAPIPath.SpinachMediaBackgroundImage);
        return response?.presignedUrl;
    } catch (err) {
        return undefined;
    }
}

export async function getCombinedBrandedVideoBackgroundImageUri(): Promise<string | undefined> {
    try {
        const response = await getSpinachAPI<{ presignedUrl: string }>(
            SpinachAPIPath.CombinedBrandedVideoBackgroundImage
        );
        return response?.presignedUrl;
    } catch (err) {
        return undefined;
    }
}
