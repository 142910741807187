import { useEffect } from 'react';

export const useCallbackAt = (at: Date | undefined, callback: () => void) => {
    const atTime = at?.getTime();
    useEffect(() => {
        if (atTime && atTime > Date.now()) {
            const timeUntilAt = atTime - Date.now();
            const timeout = setTimeout(callback, timeUntilAt);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [atTime, callback]);
};
