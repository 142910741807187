import { ZoomParticipantRole } from '@spinach-shared/types';

export enum ZoomAPI {
    // Zoom API "APIs"
    GetMeetingContext = 'getMeetingContext',
    GetUserContext = 'getUserContext',
    GetMeetingParticipants = 'getMeetingParticipants',
    GetMeetingUUID = 'getMeetingUUID',
    OpenUrl = 'openUrl',
    Authorize = 'authorize',
    // Zoom API "Events"
    OnParticipantChange = 'onParticipantChange',
    OnAuthorize = 'onAuthorized',
    OnCollaborateChange = 'onCollaborateChange',
}

export enum RunningContext {
    InMainClient = 'inMainClient',
    InMeeting = 'inMeeting',
}

export type ZoomClientConfiguration = {
    runningContext: RunningContext;
    unsupportedApis: string[];
};

export type BaseZoomParticipant = {
    screenName: string;
    participantId: string;
};

export type ZoomUser = BaseZoomParticipant & {
    role: ZoomParticipantRole;
};

export type ZoomResponse<T> = T | undefined;

export type ZoomMeetingContext = {
    meetingID: string;
    meetingTopic: string;
};

export type ChangingZoomParticipant = Omit<BaseZoomParticipant, 'participantId'> & {
    status: 'join' | 'leave';
    participantId: number;
};

export type ZoomParticipantsResponse = {
    participants: BaseZoomParticipant[];
};

export type OnParticipantChangeEvent = {
    timestamp: number;
    participants: ChangingZoomParticipant[];
};

export type GetMeetingUUIDResponse = {
    meetingUUID: string;
};
