import * as Sentry from '@sentry/react';
import { atom } from 'recoil';
import { z } from 'zod';

import { VideoAgentSession } from '@spinach-shared/models';

export type VideoAgentAssetMap = {
    listeningSrc: string;
    askSpinachAnswering: string;
    askSpinachThinking: string;
};

export type GlobalNullableVideoAgentState = {
    session: null | VideoAgentSession;
    assetMap: null | VideoAgentAssetMap;
    config: VideoAgentConfig;
    isShowingCommandsView: boolean;
};

export type GlobalVideoAgentState = {
    session: VideoAgentSession;
    assetMap: VideoAgentAssetMap;
    config: VideoAgentConfig;
    isShowingCommandsView: boolean;
};

/** NOTE - these are actually pointing to non-CPU-intensive videos by default */
export const DEFAULT_ASSET_MAP = {
    listeningSrc: 'https://media.spinach.io/passive-1-1.mp4',
    speakingSrc: 'https://media.spinach.io/active-1-1.mp4',
    askSpinachThinking: 'https://media.spinach.io/ask-spinach-thinking.mp4',
    askSpinachAnswering: 'https://media.spinach.io/ask-spinach-answering.mp4',
};

const validateAndParseRegexesForConfig = (value: string[]): RegExp[] => {
    const validRegexes: RegExp[] = [];
    const invalidRegexes: string[] = [];

    value.forEach((str: string) => {
        try {
            const matches = str.match(/(\/?)(.+)\1([a-z]*)/i);
            if (matches) {
                const [, , body, flags] = matches;
                validRegexes.push(new RegExp(body, flags));
            } else {
                throw new Error('Invalid regex ' + str);
            }
        } catch (error) {
            invalidRegexes.push(str);
        }
    });

    if (invalidRegexes.length > 0) {
        Sentry.captureException(new Error('Invalid regexes in config'), {
            extra: { invalidRegexes: invalidRegexes.join(', ') },
        });
    }

    return validRegexes;
};

function regexpToString(regexp: RegExp): string {
    return `/${regexp.source}/${regexp.flags}`;
}

const commonWakerWords = ['spinach', 'spanish', 'finished', 'finish', 'bid_it', 'spin', 'spin_it'];
const uncommonWakerWords = ['spring', 'string', 'sprints'];
const wakeWords = `_{1,}(?:${commonWakerWords.join('|')})`;
const allWakewords = `_{1,}(?:${commonWakerWords.concat(uncommonWakerWords).join('|')})`;

const wordPattern = "(?:_{1,14}[a-zA-Z0-9'-]+)";
const longPauseAtTheEnd = '_{22,}$';
const veryLongPauseAtTheEnd = '_{28,}$';

const startAgendaDefault = [new RegExp(`${wakeWords}_{1,20}start_{4,}$`, 'i')];

const nextTopicDefault = [
    new RegExp(`${wakeWords}_{1,15}next_{5,}$`, 'i'),
    new RegExp(`${allWakewords}_{1,1}(?:next|text|decks)_{18,}$`, 'i'),
    new RegExp(`${allWakewords}_{1,30}next$${veryLongPauseAtTheEnd}`, 'i'),
];

const previousTopicDefault = [
    new RegExp(`${wakeWords}_{1,20}(?:back|pack|rack|sack)_{4,}$`, 'i'),
    new RegExp(`${allWakewords}_{1,30}(?:back|pack|rack|sack)${veryLongPauseAtTheEnd}`, 'i'),
];

const questionMatcher = [
    new RegExp(`${wakeWords}_{1,30}((?:whats|what's|who)${wordPattern}{2,25})${longPauseAtTheEnd}`, 'i'),
    new RegExp(
        `${wakeWords}_{1,30}((?:is|are|was|were|will|would|can|could|should|might|must|has|have|had|does|do|did|may|shall)${wordPattern}{2,25})${longPauseAtTheEnd}`,
        'i'
    ),
    new RegExp(
        `${allWakewords}_{1,30}((?:who|whom|whose|what|which|when|where|why|shall|how)${wordPattern}{2,25})${longPauseAtTheEnd}`,
        'i'
    ),
    new RegExp(
        `${wakeWords}_{1,30}((?:i|we|they|he|she)_{1,10}(?:wonder|ask|inquire|question|ponder)${wordPattern}{2,25})${longPauseAtTheEnd}`,
        'i'
    ),
    new RegExp(
        `${wakeWords}_{1,30}((?:to|for|with|by|about|in|on|at|from)_{1,5}(?:whom|what|which|when|where|why|how)${wordPattern}{2,25})${longPauseAtTheEnd}`,
        'i'
    ),
];

const wakeWordDetected = [new RegExp(`${allWakewords}(?:_{1,30}[a-zA-Z0-9'-]+){0,3}_{1,15}$`, 'i')];

const introDefault = [new RegExp(`${wakeWords}_{1,20}intro_{4,}$`, 'i')];

const addTopicToAgenda = [
    new RegExp(
        `${wakeWords}_{1,20}(?:add|end|and|ed|aid|at|ad|adds)_{1,20}([a-zA-Z0-9'-]+(:?_{1,15}[a-zA-Z0-9'-]+){0,4})${longPauseAtTheEnd}`,
        'i'
    ),
    new RegExp(
        `${wakeWords}_{1,30}(?:add|end|and|ed|aid|at|ad|adds)_{1,20}([a-zA-Z0-9'-]+(:?_{1,15}[a-zA-Z0-9'-]+){0,4})${veryLongPauseAtTheEnd}`,
        'i'
    ),
];

const voiceCommands = ['Spinach Add <topic>', 'Spinach Start', 'Spinach Next', 'Spinach Back'];
const chatCommands = ['add <topic>', 'start', 'next', 'back', 'jump #', 'remove #', 'agenda'];

export const RemoteVideoAgentConfigSchema = z.object({
    meetingEndReminderInMinutes: z
        .number()
        .optional()
        .transform((o) => o ?? 5),
    longTopicReminderThresholdInMinutes: z
        .number()
        .optional()
        .transform((o) => o ?? 5),
    bannerDurationInMs: z
        .number()
        .optional()
        .transform((o) => o ?? 10000),
    chimeSoundUrl: z
        .string()
        .optional()
        .transform((o) => o ?? 'https://media.spinach.io/chime.mp3'),
    maxTopicsListedPerPage: z
        .number()
        .optional()
        .transform((o) => o ?? 5),
    storeRawTranscript: z
        .boolean()
        .optional()
        .transform((o) => o ?? true),
    startAgendaMatchersRegex: z
        .array(z.string())
        .optional()
        .default(startAgendaDefault.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    nextTopicMatchersRegex: z
        .array(z.string())
        .optional()
        .default(nextTopicDefault.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    previousTopicRegex: z
        .array(z.string())
        .optional()
        .default(previousTopicDefault.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    introCommandRegex: z
        .array(z.string())
        .optional()
        .default(introDefault.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    addTopicToTheAgendaMatchersRegex: z
        .array(z.string())
        .optional()
        .default(addTopicToAgenda.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    questionMatchersRegex: z
        .array(z.string())
        .optional()
        .default(questionMatcher.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    wakeWordDetectedRegex: z
        .array(z.string())
        .optional()
        .default(wakeWordDetected.map(regexpToString))
        .transform(validateAndParseRegexesForConfig),
    voiceCommandInstructions: z
        .array(z.string())
        .optional()
        .transform((o) => o ?? voiceCommands),
    chatCommandInstructions: z
        .array(z.string())
        .optional()
        .transform((o) => o ?? chatCommands),
    spinachIntro: z
        .object({
            loopVideo: z.string().default('https://media.spinach.io/intro-loop-1-1.mp4'),
            introVideo: z.string().default('https://media.spinach.io/intro-agent-1-1.mp4'),
        })
        .default({}),
    videos: z.record(z.string()).default({}),
});

export type VideoAgentConfig = z.infer<typeof RemoteVideoAgentConfigSchema>;

export const atomVideoAgent = atom<GlobalNullableVideoAgentState>({
    key: 'atomVideoAgent',
    default: {
        session: null,
        assetMap: null,
        config: RemoteVideoAgentConfigSchema.parse({}),
        // This may no longer be needed - left in case we bring it back
        isShowingCommandsView: false,
    },
});
