import styled, { css, keyframes } from 'styled-components';

import { Column } from './framing';

const SLIDING_DURATION_MS = 350;

const slideIn = keyframes`
    0% {
        transform: translateX(1000px);
    }
    100% {
        transform: translateX(0px);
    }
`;

const slideOut = keyframes`
    0% {
        transform: translateX(-1000px);
    }
    100% {
        transform: translateX(0px);
    }
`;

const slideInAnimation = () => css`
    animation: ${slideIn};
`;

const slideOutAnimation = () => css`
    animation: ${slideOut};
`;

export enum Direction {
    Forward = 'forward',
    Backward = 'backward',
}

export type SlidingContent = {
    direction: Direction;
};

export const SlidingColumn = styled(Column)<{ direction?: SlidingContent['direction'] }>`
    ${(props) =>
        !props.direction ? undefined : props.direction === 'forward' ? slideInAnimation() : slideOutAnimation()};
    animation-duration: ${SLIDING_DURATION_MS}ms;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
`;
