import { TextField } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';

import ChromeGifFrame from '../../assets/chrome-gif-frame.png';
import { ReactComponent as LogoSpinachio } from '../../assets/logo-spinachio.svg';
import ChromeGif from '../../assets/spinach-chrome-call.gif';
import { ReactComponent as SpinachLeaves } from '../../assets/spinach-leaves.svg';
import TeamsRunning from '../../assets/teams-running-on-spinach.png';
import Testimonial from '../../assets/testimonial.png';
import { useGlobalRouting, useWindowSize } from '../../hooks';
import {
    BodyBigOnboard,
    BodySmallOnboard,
    ButtonSize,
    HeaderThreeOnboard,
    lightTheme,
    responsiveness,
} from '../../styles';
import { AnonymousUserTracker, withContentMasking } from '../../utils';
import { Row, Spacing } from '../common';
import { useSpinachInputStyles } from '../input';
import { Notification, PrimaryButton } from '../stand-up';
import SecondaryButton from '../stand-up/SecondaryButton';
import { LoginWithGoogle } from './LoginWithGoogle';
import { VerifyEmailRouteProps } from './VerifyEmailRoute';

const HorizontalDivider = styled.hr`
    background: #dfe5e5;
    color: #dfe5e5;
    width: 35%;
    height: 1px;
    border-style: none;
`;

const BannerDivider = styled.hr`
    background: #dfe5e5;
    color: #dfe5e5;
    width: 100%;
    height: 1px;
    border-style: none;
    margin-bottom: 0;
`;

const Banner = styled.div`
    height: 86px;
    position: absolute;
    z-index: 100;
    width: 100%;
    flex-shrink: 0;
    display: flex;
`;

const HalfContainer = styled.div`
    flex: 1;
    align-items: center;
    position: relative;
    justify-content: center;
    display: flex;
`;

const FullContainer = styled.div`
    display: flex;
    @media ${responsiveness.thinnerThanMD} {
        flex-direction: column;
    }
`;

const GetStarted = styled.div`
    min-width: 300px;
    max-width: 400px;
    padding: 30px;
    align-content: center;
    display: grid;

    @media ${responsiveness.thinnerThanMD} {
        padding: 5px;
        padding-bottom: -20px;
    }
`;

export function GetStartedRoute({ email, setEmail, onEmailSubmit, notification }: VerifyEmailRouteProps): JSX.Element {
    const { routeToVerify } = useGlobalRouting();
    const [hasFiredStartedTyping, setHasFiredStartedTyping] = useState(false);
    const classes = useSpinachInputStyles({ value: email });
    const [searchParams] = useSearchParams();
    const { width, height } = useWindowSize();
    const halfContainerHeight = width > 300 ? height - 86 : (height - 86) / 2;
    const isChromeVariant = !!searchParams.get(WebUrlQuery.PlatformSource);

    useEffect(() => {
        const abstractBackground = document.getElementById('background-right');
        const abstractBackground2 = document.getElementById('background-left');

        if (abstractBackground && abstractBackground2) {
            abstractBackground.style.display = 'none';
            abstractBackground2.style.display = 'none';
        }

        return () => {
            if (abstractBackground && abstractBackground2) {
                abstractBackground.style.display = 'initial';
                abstractBackground2.style.display = 'initial';
            }
        };
    }, []);

    useEffect(() => {
        const queryEmail = searchParams.get(WebUrlQuery.Email);

        if (queryEmail) {
            const decodedEmail = decodeURIComponent(queryEmail);
            setEmail(decodedEmail);
            onEmailSubmit(decodedEmail);
        }
    }, []);

    useEffect(() => {
        const queryEmail = searchParams.get(WebUrlQuery.Email);

        AnonymousUserTracker.trackEvent(ClientEventType.UserLandedOnEmailEntryView, {
            Email: queryEmail || email || '',
        });
    }, []);

    useEffect(() => {
        const queryEmail = searchParams.get(WebUrlQuery.Email);

        if (!hasFiredStartedTyping && !queryEmail && email) {
            setHasFiredStartedTyping(true);

            AnonymousUserTracker.trackEvent(ClientEventType.UserStartedTypingEmail);
        }
    }, [email]);

    return (
        <>
            <Banner>
                <LogoSpinachio style={{ padding: '20px', height: '35px' }} />
            </Banner>
            <Spacing factor={2.5} />
            <BannerDivider />
            <FullContainer>
                <HalfContainer style={{ height: halfContainerHeight, minWidth: width / 2 }}>
                    <GetStarted>
                        <HeaderThreeOnboard style={{ paddingTop: width < 800 ? '60px' : 'initial' }}>
                            {isChromeVariant ? 'Get started with the Chrome Extension' : 'Get started'}
                        </HeaderThreeOnboard>
                        <BodyBigOnboard style={{ paddingTop: '4%', textAlign: 'center' }}>
                            Free forever for one team
                        </BodyBigOnboard>
                        <Spacing />
                        <LoginWithGoogle />
                        <Spacing />
                        <Row style={{ alignItems: 'left' }}>
                            <HorizontalDivider />
                            <BodyBigOnboard style={{ color: '#DFE5E5', fontSize: '14px', fontWeight: 'bold' }}>
                                OR
                            </BodyBigOnboard>
                            <HorizontalDivider />
                        </Row>
                        <Spacing />
                        <TextField
                            autoFocus
                            InputProps={{ classes: { root: classes.base } }}
                            {...withContentMasking(classes.root)}
                            style={{ width: '95%' }}
                            placeholder="Email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value.replace(/ /g, ''));
                            }}
                            onKeyDown={async (e) => {
                                if (!!email && e.key === 'Enter') {
                                    await onEmailSubmit();
                                }
                            }}
                        />
                        <Spacing />
                        <PrimaryButton
                            style={{ margin: '0 20px' }}
                            onClick={() => {
                                onEmailSubmit();
                            }}
                            title="Sign up, it's free"
                            disabled={!email}
                        />
                        <Spacing factor={1 / 2} />
                        <Row centered>
                            <BodySmallOnboard style={{ paddingRight: '5px', paddingTop: '4px' }}>
                                Already have an account?
                            </BodySmallOnboard>
                            <SecondaryButton
                                size={ButtonSize.Mini}
                                title={'Sign in'}
                                onClick={async () => {
                                    routeToVerify();
                                }}
                            />
                        </Row>
                        <Spacing />
                        {isChromeVariant ? <img style={{ width: '95%' }} src={TeamsRunning}></img> : null}
                        <Notification
                            containerStyle={{ position: 'relative', bottom: 'unset' }}
                            isOpen={!!notification}
                            onClose={() => null}
                            message={notification}
                            icon={
                                <Error
                                    style={{ color: lightTheme.neutrals.white }}
                                    htmlColor={lightTheme.neutrals.white}
                                />
                            }
                        />
                    </GetStarted>
                </HalfContainer>
                <HalfContainer style={{ backgroundColor: '#f5f6f6', height: halfContainerHeight, minWidth: width / 2 }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                            paddingTop: width < 800 ? '60px' : 'initial',
                        }}
                    >
                        {isChromeVariant ? null : (
                            <SpinachLeaves
                                style={{
                                    width: '90%',
                                }}
                            />
                        )}

                        {isChromeVariant ? (
                            <div
                                style={{
                                    width: '95%',
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: width < 800 ? `${width / 1.4}px` : `${width / 2 / 1.4}px`,
                                }}
                            >
                                <img style={{ width: '100%', position: 'absolute' }} src={ChromeGifFrame}></img>
                                <img
                                    style={{
                                        width: '75%',
                                        position: 'absolute',
                                        top: width < 800 ? `${width / 10}px` : `${width / 20}px`,
                                    }}
                                    src={ChromeGif}
                                />
                            </div>
                        ) : null}

                        <img style={{ width: '95%', maxWidth: '400px', marginTop: '-60px' }} src={Testimonial}></img>

                        <Spacing factor={width > 400 ? 2 : 1} />

                        {isChromeVariant ? null : (
                            <img style={{ width: '95%', maxWidth: '400px' }} src={TeamsRunning}></img>
                        )}

                        <Spacing factor={width < 400 ? 2 : 0} />
                    </div>
                </HalfContainer>
            </FullContainer>
        </>
    );
}
