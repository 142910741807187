import { atom } from 'recoil';

import { NavDrawer } from '@spinach-shared/types';

/**
 * start using an object for global nav drawer state to evolve in the future
 * a null drawer means its closed, where as a specified value means that drawer is open
 * */
export type GlobalNavDrawerState = {
    drawer: NavDrawer | null;
};

export const atomNavState = atom<GlobalNavDrawerState>({
    key: 'navDrawer',
    default: {
        drawer: null,
    },
});
