import { SpinachUpdateType, SpinachUpdateTypeYTB } from '@spinach-shared/types';

import { lightTheme } from '../styles';

export const hubViewPadding = 14;

export const jiraColorMap: Record<SpinachUpdateTypeYTB, string> = {
    [SpinachUpdateType.Yesterday]: `${lightTheme.primary.orangeLight}`,
    [SpinachUpdateType.Today]: `${lightTheme.primary.greenLight}`,
    [SpinachUpdateType.Challenge]: `${lightTheme.status.negative}`,
};
