import { ClientEventType } from '@spinach-shared/types';

import { GlobalModal, useExperienceTracking, useGlobalAuthedUser, useGlobalModal } from '../../../../..';
import { HeaderThree } from '../../../../styles';
import { Column } from '../../../common';
import SecondaryButton from '../../../stand-up/SecondaryButton';

export function AccountManagementSection(): JSX.Element {
    const [, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    if (!user.isEnabledForSelfServeUserDelete) {
        return <></>;
    }
    return (
        <Column>
            <HeaderThree>Account Management</HeaderThree>
            <SecondaryButton
                title="View or change your Spinach account"
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Open Account Management Modal',
                    });
                    setGlobalModal(GlobalModal.UserAccountManagement);
                }}
            />
        </Column>
    );
}
