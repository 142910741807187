const size = {
    xxs: '299px',
    xs: '400px',
    sm: '600px',
    md: '800px',
    lg: '1000px',
    '1100': '1100px',
    '1300': '1300px',
};

const height = {
    xs: '450px',
    sm: '700px',
};

export const responsiveness = {
    xxs: `(min-width: ${size.xxs})`,
    xs: `(min-width: ${size.xs})`,
    sm: `(min-width: ${size.sm})`,
    lg: `(min-width: ${size.lg})`,

    thinnerThanXXS: `(max-width: ${size.xxs})`,
    thinnerThanXS: `(max-width: ${size.xs})`,
    thinnerThanSM: `(max-width: ${size.sm})`,
    thinnerThanMD: `(max-width: ${size.md})`,
    thinnerThanLG: `(max-width: ${size.lg})`,
    thinnerThan1100: `(max-width: ${size['1100']})`,
    thinnerThan1300: `(max-width: ${size['1300']})`,

    vsm: `(max-height: ${height.sm})`,
    vxsm: `(max-height: ${height.xs})`,
};
