import { BodyRegularOnboard, HeaderThree } from '../../../../../styles';
import { Row, Spacing } from '../../../../common';
import { IntegrationSettingsModalKind } from '../../types';
import { SlackIntegrationsSection } from '../CommunicationsIntegrationSection';
import { useHoverRow } from '../IntegrationSection';

export function SpinachAppCommunicationsIntegrationsSection({
    setModalKind,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
}): JSX.Element {
    const hoverRowProps = useHoverRow();

    return (
        <>
            <Row>
                <HeaderThree>Communication</HeaderThree>
            </Row>
            <Row>
                <BodyRegularOnboard>Where your summaries are sent</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />

            <SlackIntegrationsSection
                setModalKind={setModalKind}
                hoverRowProps={hoverRowProps}
                showDefaultChannelSelection={false}
            />
        </>
    );
}
