import { ReactNode } from 'react';
import styled from 'styled-components';

import { useWindowSize } from '../../hooks';
import { BUTTON_LABEL_SIZE, BodyRegular, ButtonSize } from '../../styles/typography';
import { getHeightOffset } from '../../utils';
import { fadeInAnimation } from './animation';

export const StyledContainer = styled.div<{ height: number }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: ${(props) => `${props.height}px`};
`;

export function SpinachStandUpViewContainer({
    children,
    navBar,
    withDemoBanner,
}: {
    children: ReactNode;
    navBar: boolean;
    withDemoBanner: boolean;
}): JSX.Element {
    const { height } = useWindowSize();
    const calculatedHeight = height - getHeightOffset(navBar, withDemoBanner);

    return <StyledContainer height={calculatedHeight}>{children}</StyledContainer>;
}

export const Title = styled(BodyRegular)`
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
    text-align: center;
    margin-bottom: 5px;
`;

export const ImageButton = styled.img`
    cursor: pointer;
    width: 20px;
    height: 20px;
`;

export const PILL_BUTTON_BOX_SHADOW = '5px 6px 0px 2px rgba(0, 0, 0, 0.15)';

export const PillLink = styled.a<{ fadeInDuration?: number; disabled?: boolean }>`
    color: white;
    flex-shrink: 0;
    text-align: center;
    align-items: center;
    text-decoration-line: none;
    font-weight: 600;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    border: ${(props) =>
        props.disabled && !props.fadeInDuration
            ? `1px solid rgba(0,0,0,0)`
            : `1px solid ${props.theme.primary.orangeDark}`};
    background: ${(props) =>
        props.disabled && !props.fadeInDuration ? props.theme.neutrals.grayDark : props.theme.primary.orangeDark};
    border-radius: 80px;
    padding: 8px 24px;
    box-sizing: border-box;
    ${(props) => (props.disabled && props.fadeInDuration ? fadeInAnimation(props.fadeInDuration) : undefined)};
    transition: 0.2s;

    &:hover {
        box-shadow: ${(props) => (props.disabled ? 'none' : PILL_BUTTON_BOX_SHADOW)};
    }

    &:focus {
        box-shadow: ${(props) => (props.disabled ? 'none' : PILL_BUTTON_BOX_SHADOW)};
    }
`;

function getPadding(size: ButtonSize): string | undefined {
    switch (size) {
        case ButtonSize.Normal:
            return '8px 24px';
        case ButtonSize.Small:
            return '4px 12px';
        case ButtonSize.Mini:
        case ButtonSize.Micro:
            return '2px 8px';
    }
}

export const PillButton = styled.button<{ size?: ButtonSize; width?: number; fadeInDuration?: number; color?: string }>`
    flex-shrink: 0;
    text-align: center;
    align-items: center;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    border: none;
    background: ${(props) =>
        props.disabled && !props.fadeInDuration
            ? props.theme.neutrals.grayDark
            : props.color ?? props.theme.primary.orangeDark};
    border-radius: 80px;
    padding: ${(props) => getPadding(props.size ?? ButtonSize.Normal)};
    box-sizing: border-box;
    ${(props) => (props.disabled && props.fadeInDuration ? fadeInAnimation(props.fadeInDuration) : undefined)};
    transition: 0.2s;

    &:hover {
        box-shadow: ${(props) => (props.disabled ? 'none' : PILL_BUTTON_BOX_SHADOW)};
    }

    &:focus {
        box-shadow: ${(props) => (props.disabled ? 'none' : PILL_BUTTON_BOX_SHADOW)};
    }
`;

export const LongPressButton = styled.button<{ fixedWidth?: number; fadeInDuration?: number }>`
    flex-shrink: 0;
    text-align: center;
    align-items: center;
    width: ${(props) => `${props.fixedWidth}px`};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    border: none;
    background: ${(props) =>
        props.disabled && !props.fadeInDuration ? props.theme.neutrals.grayDark : props.theme.primary.orangeDark};
    border-radius: 80px;
    padding: 8px 24px;
    box-sizing: border-box;
    ${(props) => (props.disabled && props.fadeInDuration ? fadeInAnimation(props.fadeInDuration) : undefined)};
    transition: 0.2s;
    position: relative;
    overflow: hidden;

    &:hover {
        box-shadow: ${(props) => (props.disabled ? 'none' : PILL_BUTTON_BOX_SHADOW)};
    }

    &:focus {
        box-shadow: ${(props) => (props.disabled ? 'none' : PILL_BUTTON_BOX_SHADOW)};
    }
`;

export function getButtonFontSize(size: ButtonSize): number {
    switch (size) {
        case ButtonSize.Normal:
            return BUTTON_LABEL_SIZE;
        case ButtonSize.Small:
            return 14;
        case ButtonSize.Mini:
            return 12;
        case ButtonSize.Micro:
            return 10;
    }
}
