import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';

import { ClientEventType, ConfluenceSpaceType, MinimalConfluenceSpace } from '@spinach-shared/types';

import {
    Column,
    Row,
    SpinachAutocomplete,
    getUser,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
} from '../../../..';
import { patchConfluenceSpace } from '../../../apis/confluence/patchConfluenceSpace';
import { useConfluenceSpaces } from '../../../hooks/useConfluenceSpaces';

export const ConfluenceSpaceSelectionContent = (): JSX.Element => {
    const [user, setUser] = useGlobalAuthedUser();
    const [confluenceSpaces] = useConfluenceSpaces();
    const confluenceSpacesToRender = confluenceSpaces?.filter(
        (space) => space.type === ConfluenceSpaceType.Global || space.isSelfSpace
    );
    const { setToastText } = useGlobalAiDashboard();

    const track = useExperienceTracking();
    const defaultSpaceKey = user.integrationSettings?.confluenceSettings?.defaultSpaceKey;

    const [selectedConfluenceSpace, setSelectedConfluenceSpace] = useState<MinimalConfluenceSpace | undefined>(
        confluenceSpacesToRender?.find((space) => space.key === defaultSpaceKey)
    );
    const [isConfluenceSpaceSelectionLoading, setIsConfluenceSpaceSelectionLoading] = useState(false);

    useEffect(() => {
        if (defaultSpaceKey) {
            const space = confluenceSpacesToRender?.find((space) => space.key === defaultSpaceKey);
            if (space) {
                setSelectedConfluenceSpace(space);
            }
        } else if (confluenceSpacesToRender?.length === 1) {
            setSelectedConfluenceSpace(confluenceSpacesToRender[0]);
        } else {
            setSelectedConfluenceSpace(
                confluenceSpacesToRender?.find((space) => space.type === ConfluenceSpaceType.Global)
            );
        }
    }, [defaultSpaceKey, confluenceSpacesToRender]);

    if (!user.isConfluenceSpaceSelectionEnabled) {
        return <></>;
    }

    if (user.isPasteSiteSelectionEnabled) {
        return <></>;
    }

    return confluenceSpacesToRender?.length ? (
        <>
            <Row>
                <Column>
                    <Box
                        style={{
                            fontWeight: 500,
                            fontSize: '17px',
                            textTransform: 'uppercase',
                            letterSpacing: '1.5px',
                        }}
                    >{`Confirm Space Selection`}</Box>
                    <Row>
                        <SpinachAutocomplete
                            id="atlassian-space-selection"
                            options={confluenceSpacesToRender ?? []}
                            value={selectedConfluenceSpace}
                            defaultValue={confluenceSpacesToRender.find(
                                (space) => space.type === ConfluenceSpaceType.Global
                            )}
                            isLoading={isConfluenceSpaceSelectionLoading}
                            style={{
                                width: '300px',
                                marginBottom: '2px',
                                transform: 'scale(0.8)',
                                transformOrigin: 'center left',
                                marginRight: '-50px',
                            }}
                            ListboxProps={{ style: { transform: 'scale(0.8)', transformOrigin: 'center left' } }}
                            getOptionLabel={(option) => option.name}
                            inputPlaceholder="Select a Confluence space"
                            onChange={async (event, confluenceSpaceSelected) => {
                                try {
                                    setIsConfluenceSpaceSelectionLoading(true);

                                    if (!confluenceSpaceSelected) {
                                        return;
                                    }

                                    setSelectedConfluenceSpace(confluenceSpaceSelected);
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Confluence Space Selected`,
                                        ConfluenceSpaceName: confluenceSpaceSelected.name,
                                        ConfluenceSpaceKey: confluenceSpaceSelected.key,
                                        ConfluenceSpaceId: confluenceSpaceSelected.id,
                                    });

                                    await patchConfluenceSpace(confluenceSpaceSelected);
                                    const updatedUser = await getUser();
                                    if (updatedUser.user) {
                                        setUser(updatedUser.user);
                                    }
                                } catch (e) {
                                    setToastText('Error updating Atlassian site setting');
                                } finally {
                                    setIsConfluenceSpaceSelectionLoading(false);
                                }
                            }}
                        />
                    </Row>
                </Column>
            </Row>
        </>
    ) : (
        <></>
    );
};
