import { AdminUserHistoryOption, AdminUserOption, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../getSpinachAPI';

export async function getUserHistoriesAsAdmin(userId: string): Promise<AdminUserHistoryOption[] | undefined> {
    const response = await getSpinachAPI<{ histories: AdminUserHistoryOption[] }>(
        SpinachAPIPath.AdminUserHistories.replace(':userId', userId)
    );

    return response?.histories;
}

export async function getUserOptionsAsAdmin(): Promise<AdminUserOption[]> {
    const response = await getSpinachAPI<{ options: AdminUserOption[] }>(SpinachAPIPath.AdminUserList, {});

    return response?.options || [];
}
