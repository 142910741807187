import { ClientPlatform } from '@spinach-shared/types';

import { TimeUtils } from './time';

export enum SpinachHeader {
    Platform = 'x-client-platform',
    DemoMode = 'x-demo-mode',
    TimezoneRegion = 'x-timezone-region',
    Token = 'token',
}

export type GetSpinachHeaderOptions = {
    demoMode: boolean;
    platform: ClientPlatform;
};

export function getDefaultRequestHeaders({
    demoMode,
    platform,
}: GetSpinachHeaderOptions): Record<
    `${SpinachHeader.Platform}` | `${SpinachHeader.DemoMode}` | `${SpinachHeader.TimezoneRegion}`,
    string
> {
    return {
        [SpinachHeader.Platform]: platform,
        [SpinachHeader.DemoMode]: String(demoMode),
        [SpinachHeader.TimezoneRegion]: TimeUtils.getTimezoneRegion(),
    };
}
