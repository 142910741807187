import { List, ListItem, ListItemText } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { VideoAgentConnectingPayload } from '@spinach-shared/types';
import { ClientSocketEvent } from '@spinach-shared/types';
import { isLocalStage } from '@spinach-shared/utils';

import { fetchRemoteControlDetails } from '../../apis/video-agent/remoteControl';
import { atomVideoAgentSocket } from '../../atoms/atomSocket';
import { mockBotId, mockSeriesId, mockToken } from '../../constants';
import { useGlobalVideoAgent } from '../../hooks/agent/useGlobalVideoAgent';
import { useVideoAgentSocketSyncing } from '../../hooks/agent/useVideoAgentSocketSyncing';
import { useWebsocket } from '../../hooks/useWebsocket';
import { ConnectionEventMetadata } from '../../hooks/useWebsocket';
import { createWebsocketPayload } from '../../utils/analytics';
import { FYI, FYIState } from '../common/FYI';
import { ControlPanel } from './ControlPanel';

const MeetingSelector = styled.div`
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
`;

const MeetingTitle = styled.h2`
    text-align: center;
    margin-bottom: 20px;
`;

interface MeetingOption {
    botId: string;
    videoToken: string;
    seriesId: string;
    meetingTitle: string;
}

const MeetingList = ({ meetings }: { meetings: MeetingOption[] }) => {
    const navigate = useNavigate();

    return (
        <MeetingSelector>
            <MeetingTitle>Available Meetings</MeetingTitle>
            <List>
                {meetings.map((meeting) => (
                    <ListItem
                        key={meeting.botId}
                        button
                        onClick={() => {
                            navigate(`/control/${meeting.botId}`);
                        }}
                    >
                        <ListItemText primary={meeting.meetingTitle} />
                    </ListItem>
                ))}
            </List>
        </MeetingSelector>
    );
};

export function ControlPage(): JSX.Element {
    const { botId } = useParams<{ botId: string }>();
    const [meetings, setMeetings] = useState<MeetingOption[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const fetchMeetings = async () => {
        if (isLocalStage()) {
            const mockMeeting = {
                botId: mockBotId,
                videoToken: mockToken,
                seriesId: mockSeriesId,
                meetingTitle: 'Mock Meeting',
            };
            setMeetings([mockMeeting]);
            setIsLoading(false);
            navigate(`/control/${mockMeeting.botId}`);
            return;
        }

        try {
            const meetings = await fetchRemoteControlDetails();
            setMeetings(meetings);

            if (meetings.length > 0 && !botId) {
                navigate(`/control/${meetings[0].botId}`);
            } else if (meetings.length === 0) {
                setError(
                    "We couldn't find an active call you are a part of, make sure you are an invitee on the calendar event"
                );
            }
        } catch (err) {
            Sentry.captureException(err);
            setError(
                "We couldn't find an active call you are a part of, make sure you are an invitee on the calendar event"
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const handleFocus = () => {
            fetchMeetings();
        };

        window.addEventListener('focus', handleFocus);

        return () => {
            window.removeEventListener('focus', handleFocus);
        };
    }, [botId, navigate]);

    useEffect(() => {
        if (isLocalStage() && window.opener) {
            window.opener.postMessage({ type: 'local.request_sync' }, '*');
        }
        fetchMeetings();
    }, []);

    if (botId && !isLoading) {
        const meeting = meetings.find((m) => m.botId === botId);
        if (meeting) {
            return (
                <ControlPageWithSocket
                    botId={meeting.botId}
                    videoToken={meeting.videoToken}
                    seriesId={meeting.seriesId}
                    meetings={meetings}
                />
            );
        } else {
            return (
                <>
                    <FYI state={FYIState.Error} header="Error" body="Could not find the specified meeting." />
                    <MeetingList meetings={meetings} />
                </>
            );
        }
    }

    if (isLoading) {
        return <FYI state={FYIState.Loading} />;
    }

    if (error) {
        return <FYI state={FYIState.Error} header="Error" body={error} />;
    }

    return <MeetingList meetings={meetings} />;
}

const ControlPageWithSocket = ({
    botId,
    videoToken,
    seriesId,
    meetings,
}: {
    botId: string;
    videoToken: string;
    seriesId: string;
    meetings: MeetingOption[];
}) => {
    const { session, setSession } = useGlobalVideoAgent();
    const eventMeta: ConnectionEventMetadata<VideoAgentConnectingPayload> = {
        event: ClientSocketEvent.VideoAgentConnecting,
        payload: createWebsocketPayload<VideoAgentConnectingPayload>({
            seriesSlug: seriesId,
            botId,
            token: videoToken,
        }),
        botId,
    };

    const { socket } = useWebsocket(atomVideoAgentSocket, eventMeta);

    useVideoAgentSocketSyncing();

    useEffect(() => {
        if (isLocalStage() && window.opener) {
            window.opener.postMessage({ type: 'local.request_sync' }, '*');
        }
    }, []);

    useEffect(() => {
        if (isLocalStage() && window.opener) {
            const handleMessage = (event: MessageEvent) => {
                if (event.data.type === 'UPDATE_VIDEO_AGENT_SESSION') {
                    setSession(event.data.updatedSession);
                }
            };

            window.addEventListener('message', handleMessage);

            return () => {
                window.removeEventListener('message', handleMessage);
            };
        }
    }, [setSession]);

    if (!socket || !session) {
        return <FYI state={FYIState.Loading} />;
    }

    return (
        <ControlPanel
            botId={botId}
            videoToken={videoToken}
            seriesId={seriesId}
            socket={socket}
            availableMeetings={meetings}
        />
    );
};
