import React from 'react';
import styled from 'styled-components';

import { ReactComponent as GreyCheckIcon } from '../../../../assets/check-icon-grey.svg';
import { lightTheme, responsiveness } from '../../../../styles';

interface Step {
    name: string;
}

interface StepTrackerProps {
    steps: Step[];
    currentStep: number;
}

type StepState = 'active' | 'completed' | 'pending';

const StepTrackerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 655px;
    margin: 0 auto;
`;

const lineColor: Record<StepState, string> = {
    pending: '#ECF1F0',
    active: '#ECF1F0',
    completed: lightTheme.primary.greenLight,
};

const StepItem = styled.div<{ stepState: StepState }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;

    &::after {
        content: '';
        position: absolute;
        top: 22px;
        left: 50%;
        width: 100%;
        height: 2px;
        background-color: ${(props) => lineColor[props.stepState]};
        z-index: -1;
    }

    &:last-child::after {
        display: none;
    }
`;

const borderColor: Record<StepState, string> = {
    pending: '#ECF1F0',
    active: lightTheme.primary.greenLight,
    completed: lightTheme.primary.greenLight,
};

const fontColor: Record<StepState, string> = {
    pending: lightTheme.tertiary.midnight,
    active: lightTheme.primary.greenLight,
    completed: 'white',
};

const backgroundColor: Record<StepState, string> = {
    pending: '#ECF1F0',
    active: 'white',
    completed: lightTheme.primary.greenLight,
};

const StepNumber = styled.div<{ stepState: StepState }>`
    font-size: 21px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 3px solid ${(props) => borderColor[props.stepState]};
    background-color: ${(props) => backgroundColor[props.stepState]};
    color: ${(props) => fontColor[props.stepState]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 700;
`;

const stepNameFontColor: Record<StepState, string> = {
    pending: lightTheme.tertiary.midnight,
    active: lightTheme.primary.greenLight,
    completed: lightTheme.tertiary.midnight,
};

const StepName = styled.div<{ stepState: StepState }>`
    font-size: 14px;
    color: ${(props) => stepNameFontColor[props.stepState]};
    text-align: center;
    @media ${responsiveness.thinnerThanXS} {
        font-size: 10px;
    }
`;

export function StepTracker({ steps, currentStep }: StepTrackerProps) {
    return (
        <StepTrackerContainer>
            {steps.map((step, index) => {
                const stepState = currentStep === index ? 'active' : currentStep > index ? 'completed' : 'pending';
                return (
                    <StepItem key={index} stepState={stepState}>
                        <StepNumber stepState={stepState}>
                            {index + 1 < steps.length ? index + 1 : <GreyCheckIcon />}
                        </StepNumber>
                        <StepName stepState={stepState}>{step.name}</StepName>
                    </StepItem>
                );
            })}
        </StepTrackerContainer>
    );
}
