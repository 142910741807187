import moment from 'moment';
import { useEffect } from 'react';
import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { FacilitatedStartStops } from '@spinach-shared/models';
import { ClientEventType, FeatureToggle, SpinachInsightKind, WebUrlQuery } from '@spinach-shared/types';

import { useAnonymousUserId, useGlobalAiDashboard, useGlobalVideoAgent } from '../..';
import { postExperienceEvent } from '../apis';
import { ClientPath } from '../components';
import { getCurrentTimedView } from '../components/spinach-ai/facilitation/common';
import { useGlobalNullableBotIdPair, useGlobalNullableFacilitation } from './useGlobalFacilitation';
import { useGlobalUser } from './useGlobalUser';
import { useGlobalNullableLiveSeries } from './useLiveSeries';
import { useGlobalNullableStoredSeries } from './useSeriesData';

/**
 * @returns a `track(eventType, extraData)` function that auto-populates userIdentity metadata
 * Reduces boilerplate of common analytics needs on the front-end
 * Usage: const track = useExperienceTracking();
 * track(ClientEventType.SupportTabClicked)
 * track(ClientEventType.SupportTabClicked, { Count: count });
 *
 * can be awaited if desired.
 */
export function useExperienceTracking(): (eventType: ClientEventType, extraData?: any) => Promise<void> {
    const [user] = useGlobalUser();
    const [storedSeries] = useGlobalNullableStoredSeries();
    const [liveSeries] = useGlobalNullableLiveSeries();
    const anonymousUserId = useAnonymousUserId();
    const [search] = useSearchParams();
    const spinachUserIdFromSearch = search.get(WebUrlQuery.Suid);
    const { session } = useGlobalVideoAgent();
    const { isOnDemoNotes } = useGlobalAiDashboard();

    const loc = useLocation();

    return React.useCallback(
        async (eventType: ClientEventType, extraData: Record<string, any> = {}) => {
            // prioritize any spinachuserids were passing in programmatically before the riskier query param
            const spinachUserId =
                user?.spinachUserId ||
                extraData.UserId ||
                extraData.spinachUserId ||
                spinachUserIdFromSearch ||
                session?.hostId;

            await postExperienceEvent({
                eventType,
                payload: {
                    ...user?.toUserIdentityPayload(),
                    SeriesId: storedSeries?.slug,
                    AnonymousUserId: anonymousUserId,
                    IsAISeries: storedSeries?.isStandupScribeSeries,
                    ...extraData,
                    UserId: spinachUserId,
                    BotId: session?.botId,
                    CurrentDemoGuide: liveSeries?.currentDemoGuide,
                    CurrentURL: location.href,
                    /**
                     * @NOTE is supposed to be `true` if automated agent. If this works well we will block
                     * these suspected bots from firing an event at all; but, for now, we'll just add an attribute
                     * to attempt to track and analyze
                     * */
                    IsSuspectedBot: !!navigator.webdriver,
                    BrowserWidth: window.innerWidth,
                    BrowserHeight: window.innerHeight,
                    DemoVersion: user?.metadata.demoVersion,
                    IsOnDemoNotes: isOnDemoNotes,
                    OnboardingExperimentVersion: user?.featureToggles[FeatureToggle.OnboardingExperiment],
                    IsEnabledForAgentOnboarding: user?.isEnabledForAgentOnboarding,
                    IsEnabledForDefaultDashboardToNotes: user?.isEnabledForDefaultDashboardToNotes,
                    SpinachReferrer: user?.metadata.spinachReferrer,
                    CalendarPermissionsStepTrackerOnboardingVersion: 'v3',
                    Dashboard:
                        loc.pathname === ClientPath.Root
                            ? 'Standup App'
                            : loc.pathname === ClientPath.AIHome
                            ? 'Spinach AI'
                            : undefined,
                    ...(user?.aiSubscriptionMetadata || {}),
                },
            });
        },
        [
            user,
            spinachUserIdFromSearch,
            session?.hostId,
            session?.botId,
            storedSeries?.slug,
            storedSeries?.isStandupScribeSeries,
            anonymousUserId,
            liveSeries?.currentDemoGuide,
            isOnDemoNotes,
            loc.pathname,
        ]
    );
}

export function useLandingAnalytic(eventType: ClientEventType, extraData?: any) {
    const track = useExperienceTracking();

    useEffect(() => {
        track(eventType, extraData);
    }, []);
}

export function useClickTracking() {
    const track = useExperienceTracking();

    return React.useCallback(
        function trackClick(eventType: ClientEventType, clickedOn: string, extraData: Record<string, any> = {}) {
            track(eventType, {
                ...extraData,
                ClickedOn: clickedOn,
            });
        },
        [track]
    );
}

export function useActivityTracking() {
    const track = useExperienceTracking();

    return React.useCallback(
        function trackActivity(eventType: ClientEventType, activity: string, extraData: Record<string, any> = {}) {
            track(eventType, {
                ...extraData,
                Activity: activity,
            });
        },
        [track]
    );
}

export function useFacilitationTracking(): (eventType: ClientEventType, extraData?: any) => Promise<void> {
    const [facilitation] = useGlobalNullableFacilitation();
    const [botIdPair] = useGlobalNullableBotIdPair();
    const [user] = useGlobalUser();
    const [storedSeries] = useGlobalNullableStoredSeries();

    const currentTimedView = facilitation ? getCurrentTimedView(facilitation) : undefined;
    const timingForCurrentView = currentTimedView ? new FacilitatedStartStops(currentTimedView.startStops) : undefined;
    const totalTimeForCurrentView = timingForCurrentView
        ? timingForCurrentView.totalTime + timingForCurrentView.inProgressTime()
        : undefined;

    return async (eventType: ClientEventType, extraData: any = {}) => {
        await postExperienceEvent({
            eventType,
            payload: {
                ...user?.toUserIdentityPayload(),
                SeriesId: storedSeries?.slug,
                IsAISeries: storedSeries?.isStandupScribeSeries,
                ...extraData,
                CurrentURL: location.href,
                BrowserWidth: window.innerWidth,
                BrowserHeight: window.innerHeight,
                HasAgendaStarted: !!facilitation?.actualMeetingTime?.startTime,
                BotId: botIdPair?.botId,
                PreviousBotId: botIdPair?.previousBotId,
                ParticipantCount: facilitation?.participants.length,
                DiscussionTopicsCount: facilitation?.discussionTopics.length,
                InMeetingInsightsCount: facilitation?.inMeetingInsights.length,
                InMeetingBlockerInsightsCount: facilitation?.inMeetingInsights.filter(
                    (i) => i.kind === SpinachInsightKind.Blocker
                ),
                InMeetingHeySpinachInsightsCount: facilitation?.inMeetingInsights.filter(
                    (i) => i.kind === SpinachInsightKind.HeySpinach
                ),
                InMeetingTimeCheckInsightsCount: facilitation?.inMeetingInsights.filter(
                    (i) => i.kind === SpinachInsightKind.TimeCheck
                ),
                AiGapInsightsCount: facilitation?.roundtableWrapup.insights.length,
                ScheduledMeetingDuration: moment(facilitation?.projectedMeetingTime.endTime).diff(
                    moment(facilitation?.projectedMeetingTime.startTime),
                    'minutes'
                ),
                CurrentViewId: facilitation?.currentViewId,
                IsIcebreakerEnabled: !!facilitation?.icebreaker.enabled,
                IcebreakersShuffledCount: facilitation?.icebreaker.usedIndexes.length,
                TimeOnCurrentViewInSeconds: totalTimeForCurrentView,
                ...(user?.aiSubscriptionMetadata || {}),
            },
        });
    };
}
