import { icebreakers } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import { ReactComponent as ShuffleIcon } from '../../../assets/shuffle.svg';
import { useFacilitationTracking, useGlobalFacilitation } from '../../../hooks';
import { BodyLarge, lightTheme } from '../../../styles';
import { Row, Spacing } from '../../common';
import { OutlinedButton } from '../../stand-up/OutlinedButton';
import { ForwardBackwardButtons } from './TimedHeader';
import { Header, Padding, ScrollView, getNextIcebreakerIndex } from './common';

export function IcebreakerContent(): JSX.Element {
    const [facilitation, setFacilitation] = useGlobalFacilitation();
    const { icebreaker } = facilitation;
    const track = useFacilitationTracking();

    return (
        <>
            <Row
                vCenter
                style={{
                    marginLeft: '20px',
                    marginTop: '20px',
                    marginRight: '20px',
                    justifyContent: 'space-between',
                    width: 'calc(100% - 40px)',
                    userSelect: 'none',
                }}
            >
                <Header>Icebreaker</Header>
                <span>
                    <ForwardBackwardButtons />
                </span>
            </Row>
            <ScrollView style={{ width: 'calc(100% - 50px)' }}>
                <Padding>
                    <Spacing />
                    <Row centered style={{ height: '100px', textAlign: 'center' }}>
                        <BodyLarge>{icebreakers[facilitation.icebreaker.currentIndex ?? 0]}</BodyLarge>
                    </Row>
                    <Spacing factor={1 / 2} />
                    <Row centered={true} vCenter={true}>
                        <OutlinedButton
                            title={'Shuffle'}
                            onClick={() => {
                                const { currentIndex, usedIndexes } = getNextIcebreakerIndex(icebreaker);

                                track(ClientEventType.AIFacilitationClick, {
                                    ClickedOn: 'Shuffle Icebreaker',
                                    Location: 'Icebreaker View',
                                });

                                setFacilitation((facilitation) => ({
                                    ...facilitation,
                                    icebreaker: {
                                        ...facilitation.icebreaker,
                                        usedIndexes,
                                        currentIndex,
                                    },
                                }));
                            }}
                            endIcon={<ShuffleIcon style={{ fill: lightTheme.primary.green }} />}
                        />
                    </Row>
                </Padding>
            </ScrollView>
        </>
    );
}
