import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { fetchZapierApiKey } from '../../apis/zapier/fetchZapierApiKey';
import { atomZapierApiKey } from '../../atoms/zapier';
import { useGlobalAuthedUser } from '../useGlobalUser';

export const useGlobalZapierApiKey = () => {
    return useRecoilState(atomZapierApiKey);
};

export const useZapierAPIKey = () => {
    const [user] = useGlobalAuthedUser();
    const [globalZapierApiKey, setGlobalZapierApiKey] = useGlobalZapierApiKey();
    useEffect(() => {
        const getZapierApiKey = async () => {
            const zapierApiKey = await fetchZapierApiKey();
            if (zapierApiKey) {
                setGlobalZapierApiKey(zapierApiKey);
            }
        };
        if (user.isAuthedForZapier && user.isZapierEnabled) {
            getZapierApiKey();
        }
    }, [user?.isAuthedForZapier, user?.isZapierEnabled, setGlobalZapierApiKey]);

    return [globalZapierApiKey, setGlobalZapierApiKey];
};
