import { CircularProgress } from '@material-ui/core';
import { ForwardedRef, forwardRef } from 'react';
import styled from 'styled-components';

import { lightTheme } from '../../styles/theme';
import { ButtonSize, PrimaryButtonLabel } from '../../styles/typography';
import { ButtonProps, OutlineButtonSizing } from '../../types/StandUp';
import { PILL_BUTTON_BOX_SHADOW, PillButton, getButtonFontSize } from './Shared';

function getPadding(size: ButtonSize): string | undefined {
    switch (size) {
        case ButtonSize.Normal:
            return undefined;
        case ButtonSize.Small:
        case ButtonSize.Mini:
        case ButtonSize.Micro:
            return '2px 8px';
    }
}

function getBoxShadow(size: ButtonSize): string {
    switch (size) {
        case ButtonSize.Normal:
            return PILL_BUTTON_BOX_SHADOW;
        case ButtonSize.Small:
            return '5px 6px 0px 1px rgb(0 0 0 / 15%)';
        case ButtonSize.Mini:
            return '4px 5px 0px 0px rgb(0 0 0 / 15%)';
        case ButtonSize.Micro:
            return '5px 4px 0px 0px rgb(0 0 0 / 15%);';
    }
}

const OutlinedPillButton = styled(PillButton)<OutlineButtonSizing>`
    background: rgba(0, 0, 0, 0);
    border: ${(props) =>
        props.disabled && !props.fadeInDuration
            ? `1px solid ${props.theme.neutrals.grayDark}`
            : `1px solid ${props.color || props.theme.primary.green}`};
    padding: ${(props) => getPadding(props.size)};

    &:hover {
        box-shadow: ${(props) => (props.disabled ? 'none' : getBoxShadow(props.size))};
    }
`;

const OutlinedButtonLabel = styled(PrimaryButtonLabel)<
    OutlineButtonSizing & { disabled?: boolean; fontColor?: string }
>`
    color: ${(props) =>
        props.disabled ? props.theme.neutrals.grayDark : props.fontColor ?? props.theme.primary.green};
    font-size: ${(props) => getButtonFontSize(props.size)}px;
    display: flex;
    align-content: center;
    justify-content: space-between;
`;

export type OutlinedButtonProps = ButtonProps & Partial<OutlineButtonSizing>;

export const OutlinedButton = forwardRef(
    (
        {
            title,
            onClick,
            disabled = false,
            isLoading = false,
            loadingText = 'Loading...',
            fadeInDuration,
            size = ButtonSize.Normal,
            endIcon,
            preIcon,
            fontColor,
            fontWeight,
            labelStyles,
            ...rest
        }: OutlinedButtonProps,
        ref: ForwardedRef<HTMLButtonElement | null>
    ): JSX.Element => {
        return (
            <OutlinedPillButton
                ref={ref}
                onClick={onClick}
                disabled={disabled || isLoading}
                fadeInDuration={fadeInDuration}
                size={size}
                style={rest.containerStyles}
                {...rest}
            >
                <OutlinedButtonLabel
                    size={size}
                    disabled={disabled}
                    fontColor={fontColor}
                    fontWeight={fontWeight}
                    style={labelStyles}
                >
                    <>
                        {preIcon}
                        {isLoading ? (
                            <CircularProgress
                                size={'20px'}
                                style={{
                                    marginRight: '5px',
                                    marginBottom: '-5px',
                                    color: fontColor ?? lightTheme.primary.orangeDark,
                                }}
                            />
                        ) : null}
                        {isLoading ? loadingText : title}
                        {endIcon}
                    </>
                </OutlinedButtonLabel>
            </OutlinedPillButton>
        );
    }
);
