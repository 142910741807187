import { Box, IconButton } from '@material-ui/core';
import { CallMade } from '@material-ui/icons';
import CachedIcon from '@material-ui/icons/Cached';

import { ClientEventType, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import GoogleCalendarSrc from '../../../../assets/google-cal.png';
import { ReactComponent as OutlookIcon } from '../../../../assets/integrations/microsoft.svg';
import { useExperienceTracking, useGlobalAuthedUser, useIntegrationDetection } from '../../../../hooks';
import { useGlobalAiMeetingSelection } from '../../../../hooks/useGlobalAiMeetingSelection';
import { useStoredSeriesListFetcher } from '../../../../hooks/useGlobalStoredSeriesList';
import { BodyRegularOnboard, ButtonSize, HeaderThree, HeaderThreeOnboard, lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { PromoContainer, Row, Spacing } from '../../../common';
import { PrimaryButton, ScrollArea } from '../../../stand-up';
import { CopiableBotEmail } from '../../onboarding/common';
import { SummaryProcessingDisabledBanner } from '../AccountManagement/PlanBanner';
import { Label } from '../integrations/common';
import { IntegrationSettingsModalKind } from '../types';
import { MeetingDetails } from './MeetingDetails';
import './MeetingSection.css';
import { OnboardingSetupPromo } from './OnboardingSetupPromo';

function SpinachCalendarConnect(): JSX.Element {
    const track = useExperienceTracking();
    const startDetection = useIntegrationDetection();
    const [user] = useGlobalAuthedUser();
    return (
        <PromoContainer>
            <Row>
                <HeaderThree>First let&apos;s connect your calendar</HeaderThree>
            </Row>
            <Spacing />
            <Row>
                <img src={GoogleCalendarSrc} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                <Label>Google</Label>
                <PrimaryButton
                    title={'Connect Google Calendar'}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Meeting Section Google Calendar Connect`,
                        });
                        startDetection();
                        URLUtil.openURL(
                            `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleCalendarAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                        );
                    }}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            </Row>
            <Spacing />
            <Row>
                <OutlookIcon style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                <Label>Microsoft</Label>
                <PrimaryButton
                    title={'Connect Microsoft Calendar'}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Microsoft Calendar Connect`,
                        });
                        startDetection();
                        URLUtil.openURL(
                            `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.MicrosoftAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                        );
                    }}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            </Row>
            <Spacing />
            <Row>
                <BodyRegularOnboard>Unable to connect?</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 4} />
            <Row>
                <BodyRegularOnboard>
                    Add <CopiableBotEmail /> to the calendar invite. Spinach will join and email an AI-generated summary
                    afterwards.
                </BodyRegularOnboard>
            </Row>
        </PromoContainer>
    );
}

export function MeetingSectionWithoutCalendarIntegration({
    storedSeriesList,
    setStoredSeriesList,
    setModalKind,
}: {
    storedSeriesList: StoredSpinachSeries[];
    isAddingAdditionalMeetings: boolean;
    setIsAddingAdditionalMeetings: (value: boolean) => void;
    setStoredSeriesList: (value: StoredSpinachSeries[]) => void;
    setModalKind: (modalKind: IntegrationSettingsModalKind | null) => void;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const {
        openedMeetingsState: { openMeetingDetails },
        openMeetingBySeriesId,
        closeMeetingBySeriesId,
    } = useGlobalAiMeetingSelection();

    const { fetch, isLoading } = useStoredSeriesListFetcher({ recurringOnly: false });

    return (
        <>
            {user.isEnabledForCombinedSummaries ? (
                <>
                    <OnboardingSetupPromo />
                </>
            ) : (
                <>
                    <Box maxWidth="900px" marginRight="20px">
                        <SpinachCalendarConnect />
                    </Box>
                </>
            )}

            <Spacing factor={1} />
            <Row vCenter>
                <HeaderThreeOnboard style={{ textAlign: 'left' }}>Your Meetings</HeaderThreeOnboard>
                {user?.isSummaryProcessingDisabled ? (
                    <></>
                ) : (
                    <IconButton
                        onClick={async () => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Refresh Upcoming Meetings',
                            });
                            await fetch();
                        }}
                        disableRipple
                        disabled={isLoading}
                        className={isLoading ? 'refresh-upcoming-meetings' : undefined}
                    >
                        <CachedIcon htmlColor={lightTheme.primary.greenLight} fontSize="large" />
                    </IconButton>
                )}
            </Row>

            <Spacing />
            {user?.isSummaryProcessingDisabled ? (
                <SummaryProcessingDisabledBanner />
            ) : (
                <>
                    <ScrollArea sidePadding={0} style={{ background: 'transparent' }}>
                        {storedSeriesList.map((storedSeries) => {
                            return (
                                <MeetingDetails
                                    key={`${storedSeries.id}-${storedSeries.metadata.scribeMetadata?.isEmailingIcpOnly}`}
                                    isOpen={openMeetingDetails.includes(storedSeries.id)}
                                    setIsOpen={() => {
                                        if (openMeetingDetails.includes(storedSeries.id)) {
                                            closeMeetingBySeriesId(storedSeries.id);
                                        } else {
                                            openMeetingBySeriesId(storedSeries.id);
                                        }
                                    }}
                                    storedSeries={storedSeries}
                                    setStoredSeriesList={setStoredSeriesList}
                                    storedSeriesList={storedSeriesList}
                                    setModalKind={setModalKind}
                                />
                            );
                        })}
                    </ScrollArea>
                </>
            )}
        </>
    );
}
