import { useEffect, useState } from 'react';

import { SetValue } from '../types';

export enum LocalStorageKeys {
    HasUserSeenProvisioningInstructions = 'hasUserSeenProvisioningInstructions',
    HasDismissedGoogleDriveVideoBackupWarning = 'hasDismissedGoogleDriveVideoBackupWarning',
}

const storage = window.localStorage;

function readStorageValue<T>(key: string, defaultValue?: T): T {
    try {
        return JSON.parse(storage.getItem(key) as string);
    } catch (e) {
        return defaultValue as T;
    }
}

export function useLocalStorage<T>(key: string, defaultValue?: T): [T, SetValue<T>] {
    const [value, setValue] = useState(readStorageValue(key, defaultValue));

    useEffect(() => {
        storage.setItem(key, JSON.stringify(value));
        window.dispatchEvent(new Event('localStorageUpdate'));
    }, [key, value]);

    useEffect(() => {
        const onStorageUpdateValue = () => setValue(readStorageValue(key, value));
        window.addEventListener('storage', onStorageUpdateValue);
        window.addEventListener('localStorageUpdate', onStorageUpdateValue);
        return () => {
            window.removeEventListener('storage', onStorageUpdateValue);
            window.removeEventListener('localStorageUpdate', onStorageUpdateValue);
        };
    }, [key, value]);

    return [value, setValue];
}
