import { PatchAllSeriesSettingsRequest, SpinachAPIPath } from '@spinach-shared/types';

import { patchSpinachAPI } from './patchSpinachAPI';

export async function patchAllSeriesSettings(request: PatchAllSeriesSettingsRequest): Promise<string | undefined> {
    const params: Record<string, string> = {};

    const data = await patchSpinachAPI<{ code: string }>(SpinachAPIPath.AllSeries, request, {
        params,
    });
    return data.code;
}
