import { SlackHuddleClientConfiguration, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../getSpinachAPI';

export const fetchSlackHuddleConfiguration = async (): Promise<SlackHuddleClientConfiguration | null> => {
    const response = await getSpinachAPI<{ huddleConfiguration: SlackHuddleClientConfiguration }>(
        SpinachAPIPath.SlackHuddleBot
    );
    return response?.huddleConfiguration ?? null;
};
