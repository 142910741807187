import { CallMade } from '@material-ui/icons';
import styled from 'styled-components';

import { DashboardSection, INTERCOM_SUPPORT_URL } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking, useGlobalAuthedUser, useStripeUpgradeInAppEnablement } from '../../../hooks';
import { useGlobalDrafts } from '../../../hooks/useGlobalDrafts';
import { lightTheme } from '../../../styles';
import { URLUtil } from '../../../utils';
import { Column, Row, Spacing } from '../../common';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { DashboardComponent } from './types';
import { isSummariesAndTranscriptsSection } from './utils';

const SidebarColumn = styled(Column)`
    padding: 20px;
    height: 100%;
    width: calc(100% - 40px);
`;

export function Sidebar({
    activeSection,
    setActiveSection,
    setIsAddingAdditionalMeetings,
}: DashboardComponent & { setIsAddingAdditionalMeetings: (val: boolean) => void }): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [drafts] = useGlobalDrafts();
    const track = useExperienceTracking();
    const isStripeUpgradeInAppEnabled = useStripeUpgradeInAppEnablement();

    const MeetingsButton = (
        <SecondaryButton
            title="Your Meetings"
            buttonStyles={{ textDecoration: 'none' }}
            labelStyles={{
                fontSize: '18px',
                fontWeight: 700,
                display: 'flex',
                width: '100%',
                textAlign: 'left',
            }}
            color={
                activeSection === DashboardSection.Meetings
                    ? lightTheme.primary.greenLight
                    : lightTheme.primary.midnight
            }
            onClick={() => {
                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Meetings Tab' });
                setActiveSection(DashboardSection.Meetings);
                setIsAddingAdditionalMeetings(false);
            }}
        />
    );

    const SummariesButton = (
        <SecondaryButton
            title="Meeting History"
            buttonStyles={{ textDecoration: 'none' }}
            labelStyles={{
                fontSize: '18px',
                fontWeight: 700,
                display: 'flex',
                width: '100%',
                textAlign: 'left',
            }}
            color={
                isSummariesAndTranscriptsSection(activeSection)
                    ? lightTheme.primary.greenLight
                    : lightTheme.primary.midnight
            }
            onClick={() => {
                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summaries & Transcripts Tab' });
                setActiveSection(DashboardSection.Summaries);
            }}
        />
    );

    return (
        <Column
            style={{
                height: '100%',
                width: '240px',
                backgroundColor: `${lightTheme.neutrals.grayLight}`,
                zIndex: 1,
                marginRight: '20px',
            }}
        >
            <SidebarColumn>
                {user.isEnabledForDefaultDashboardToNotes ? SummariesButton : MeetingsButton}
                <Spacing factor={1 / 2} />
                {user.isEnabledForDefaultDashboardToNotes ? MeetingsButton : SummariesButton}
                {user.isEnabledForDraftsSection ? (
                    <>
                        <Spacing factor={1 / 2} />
                        <SecondaryButton
                            title={
                                <Row vCenter>
                                    Drafts{' '}
                                    {!!drafts?.length ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginLeft: '6px',
                                                color: 'white',
                                                width: '27px',
                                                height: '27px',
                                                borderRadius: '50%',
                                                fontSize:
                                                    drafts.length < 10 ? '18px' : drafts.length < 100 ? '16px' : '12px',
                                                backgroundColor: lightTheme.primary.greenLight,
                                            }}
                                        >
                                            {drafts.length > 99 ? '99+' : drafts.length}
                                        </div>
                                    ) : null}
                                </Row>
                            }
                            buttonStyles={{ textDecoration: 'none' }}
                            labelStyles={{
                                fontSize: '18px',
                                fontWeight: 700,
                                display: 'flex',
                                width: '100%',
                                textAlign: 'left',
                            }}
                            color={
                                activeSection === DashboardSection.Drafts
                                    ? lightTheme.primary.greenLight
                                    : lightTheme.primary.midnight
                            }
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Drafts Tab',
                                    NumberOfDrafts: drafts?.length,
                                });
                                setActiveSection(DashboardSection.Drafts);
                            }}
                        />
                    </>
                ) : null}
                <Spacing factor={1 / 2} />
                <SecondaryButton
                    title="Settings"
                    buttonStyles={{ textDecoration: 'none' }}
                    labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                    color={
                        activeSection === DashboardSection.Integrations
                            ? lightTheme.primary.greenLight
                            : lightTheme.primary.midnight
                    }
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Integrations Tab' });
                        setActiveSection(DashboardSection.Integrations);
                    }}
                />
                {isStripeUpgradeInAppEnabled ? (
                    <>
                        <Spacing factor={1 / 2} />
                        <SecondaryButton
                            title="Account"
                            buttonStyles={{ textDecoration: 'none' }}
                            labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                            color={
                                activeSection === DashboardSection.Account
                                    ? lightTheme.primary.greenLight
                                    : lightTheme.primary.midnight
                            }
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Account Tab' });
                                setActiveSection(DashboardSection.Account);
                            }}
                        />
                    </>
                ) : null}

                {user.hasAdminSectionAccess ? (
                    <>
                        <Spacing factor={1 / 2} />
                        <SecondaryButton
                            title="Admin"
                            buttonStyles={{ textDecoration: 'none' }}
                            labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                            color={
                                activeSection === DashboardSection.Admin
                                    ? lightTheme.primary.greenLight
                                    : lightTheme.primary.midnight
                            }
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Admin Tab' });
                                setActiveSection(DashboardSection.Admin);
                            }}
                        />
                    </>
                ) : null}

                <Spacing factor={1 / 2} />
                <SecondaryButton
                    title="Help Center"
                    buttonStyles={{ textDecoration: 'none' }}
                    labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                    color={lightTheme.primary.midnight}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Help Center Link' });
                        URLUtil.openURL(INTERCOM_SUPPORT_URL);
                    }}
                    endIcon={
                        <CallMade
                            style={{ fontSize: '16px', marginLeft: '5px', marginBottom: '-2px' }}
                            htmlColor={lightTheme.primary.greenLight}
                        />
                    }
                />
            </SidebarColumn>
        </Column>
    );
}
