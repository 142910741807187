export enum DemoGuide {
    Rollover = 'Rollover',
    CheckIn = 'CheckIn',
    JiraTicket = 'JiraTicket',
    StartStandup = 'StartStandup',
    EachPersonShares = 'EachPersonShares',
    ParkingLotDiscuss = 'ParkingLotDiscuss',
    ShareSummary = 'ShareSummary',
}

export enum PersonaDemoGuide {
    AddUpdateForToday = 'AddUpdateForToday',
    DraftingSuccess = 'DraftingSuccess',
    AgendaOrder = 'AgendaOrder',
    TimeToShare = 'TimeToShare',
    UpdateQuality = 'UpdateQuality',
    DisruptionsBeLike = 'DisruptionsBeLike',
    ParkingLot = 'ParkingLot',
    InstantSummary = 'InstantSummary',
}

/**
 * a constant used when order of guide determination is critical
 */
export const ORDERED_GUIDES: DemoGuide[] = [
    DemoGuide.Rollover,
    DemoGuide.JiraTicket,
    DemoGuide.CheckIn,
    DemoGuide.StartStandup,
    DemoGuide.EachPersonShares,
    DemoGuide.ParkingLotDiscuss,
    DemoGuide.ShareSummary,
];

export const ORDERED_PERSONA_GUIDES: PersonaDemoGuide[] = [
    PersonaDemoGuide.AddUpdateForToday,
    PersonaDemoGuide.DraftingSuccess,
    PersonaDemoGuide.AgendaOrder,
    PersonaDemoGuide.TimeToShare,
    PersonaDemoGuide.UpdateQuality,
    PersonaDemoGuide.DisruptionsBeLike,
    PersonaDemoGuide.ParkingLot,
    PersonaDemoGuide.InstantSummary,
];

export const DEMO_GUIDE_SEGMENTS: { label: string; steps: number }[] = [
    {
        label: 'Prepare your check-in',
        steps: 3,
    },
    {
        label: 'Join your standup',
        steps: 4,
    },
];

export const PERSONA_DEMO_GUIDE_SEGMENTS: { label: string; steps: number }[] = [
    {
        label: 'Join',
        steps: 1,
    },
    {
        label: 'Draft',
        steps: 1,
    },
    {
        label: 'Standup',
        steps: 1,
    },
];

export const IMPORTED_JIRA_ID_1 = '7c5daa1a-2e31-42aa-9c2d-e4cecaf95bfd';
export const ROLLED_OVER_JIRA_ITEM_ID = '7c5daa1a-2e31-42aa-9c2d-e4cecaf95bfd';

export const DEMO_TICKET_ID_PREFIX = 'SPCH-';
export const ROLLOVER_JIRA_TITLE = 'Improve our Standup';
export const OMNIBOX_DEMO_JIRA_TICKET_1 = 'Share better updates';
export const OMNIBOX_DEMO_JIRA_TICKET_2 = 'Keep the team engaged';
export const OMNIBOX_DEMO_JIRA_TICKET_3 = 'Have more fun';
export const OMNIBOX_DEMO_JIRA_TICKET_4 = 'Check on Team Mood';
export const OMNIBOX_DEMO_JIRA_TICKETS = [
    OMNIBOX_DEMO_JIRA_TICKET_1,
    OMNIBOX_DEMO_JIRA_TICKET_2,
    OMNIBOX_DEMO_JIRA_TICKET_3,
    OMNIBOX_DEMO_JIRA_TICKET_4,
];
