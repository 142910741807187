import { LookupItemsQuery } from '@spinach-shared/schemas';
import { SearchDataResponse, SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from './postSpinachAPI';

export async function getSearchData(lookup: LookupItemsQuery) {
    return (await postSpinachAPI<SearchDataResponse>(SpinachAPIPath.SearchData, lookup, {
        throwOnError: true,
    })) as SearchDataResponse;
}
