import { Modal } from '@material-ui/core';
import { useState } from 'react';
import styled from 'styled-components';

import { ClientUser } from '@spinach-shared/models';
import { UserSeriesMetadata } from '@spinach-shared/types';

import { deleteUserFromSeries, getUser } from '../../apis';
import { GlobalModal } from '../../atoms';
import { useGlobalAuthedUser, useGlobalModal, useGlobalNullableStoredSeries } from '../../hooks';
import { BodyRegular, HeaderThree, lightTheme } from '../../styles';
import { Column, Spacing } from '../common';
import { PrimaryButton } from '../stand-up';
import SecondaryButton from '../stand-up/SecondaryButton';
import { CenteredRow, ModalContent } from './common';
import { Titles } from '@spinach-clients/constants';

const Content = styled(ModalContent)`
    min-width: 280px;
`;

export function RemoveSeriesModalContainer() {
    const [user] = useGlobalAuthedUser();
    const [globalStoredSeries] = useGlobalNullableStoredSeries();
    const [globalModal, setGlobalModal] = useGlobalModal();

    if (!globalStoredSeries) {
        return <></>;
    }

    return (
        <RemoveSeriesModal
            isOpen={globalModal === GlobalModal.RemoveSeries}
            onClose={() => {
                setGlobalModal(null);
            }}
            series={globalStoredSeries}
            user={user}
            key={globalStoredSeries.id}
        />
    );
}

export function RemoveSeriesModal({
    isOpen,
    onClose,
    series,
    user,
}: {
    isOpen: boolean;
    onClose: () => void;
    series: UserSeriesMetadata;
    user: ClientUser;
}): JSX.Element {
    const [isLoading, setIsLoading] = useState(false);
    const [_, setUser] = useGlobalAuthedUser();
    return (
        <Modal open={isOpen} onClose={onClose} disableEscapeKeyDown={false}>
            <Content>
                <Column>
                    <HeaderThree>Leave Standup</HeaderThree>``
                    <Spacing factor={0.5} />
                    <BodyRegular>
                        Are you sure you want to leave standup <b>{series.name}</b>?
                    </BodyRegular>
                    <Spacing />
                    <PrimaryButton
                        title="Leave Standup"
                        isLoading={isLoading}
                        style={
                            isLoading
                                ? undefined
                                : {
                                      border: `1px solid ${lightTheme.status.negative}`,
                                      background: lightTheme.status.negative,
                                  }
                        }
                        onClick={async () => {
                            setIsLoading(true);
                            await deleteUserFromSeries(series.id, user.spinachUserId);
                            const userResponse = await getUser();

                            setIsLoading(false);

                            if (userResponse.user) {
                                setUser(userResponse.user);
                            } else {
                                location.reload();
                            }

                            onClose();
                        }}
                    />
                    <Spacing factor={0.66} />
                    <CenteredRow>
                        <SecondaryButton title={Titles.Cancel} onClick={onClose} />
                    </CenteredRow>
                </Column>
            </Content>
        </Modal>
    );
}
