import { SpinachUpdateType, UpdateSectionTypeProps } from '@spinach-shared/types';

export const actionItemTypeProps: UpdateSectionTypeProps = {
    title: 'Action Items',
    placeholder: 'What actions do you want to come out of this?',
    bullet: '•',
    spinachUpdateType: SpinachUpdateType.ActionItems,
};

export const detailItemTypeProps: UpdateSectionTypeProps = {
    title: 'Notes',
    placeholder: 'Provide some more color to this topic',
    bullet: '•',
    spinachUpdateType: SpinachUpdateType.Notes,
};

export const yesterdaysHighlightsUpdateSectionTypeProps: UpdateSectionTypeProps = {
    title: 'Yesterday',
    placeholder: 'What happened yesterday?',
    bullet: '•',
    spinachUpdateType: SpinachUpdateType.Yesterday,
};

export const todaysUpdateSectionTypeProps: UpdateSectionTypeProps = {
    title: 'Today',
    placeholder: 'What are your goals for the day?',
    bullet: '•',
    spinachUpdateType: SpinachUpdateType.Today,
};

export const challengesUpdateSectionTypeProps: UpdateSectionTypeProps = {
    title: 'Blockers',
    placeholder: 'Any challenges?',
    bullet: '•',
    spinachUpdateType: SpinachUpdateType.Challenge,
};

export const parkingLotUpdateSectionTypeProps: UpdateSectionTypeProps = {
    title: 'Team Topics',
    placeholder: 'Anything to address with the team?',
    bullet: '•',
    spinachUpdateType: SpinachUpdateType.ParkingLot,
};

// For use with team topics 2
export const teamTopicsUpdateSectionTypeProps: UpdateSectionTypeProps = {
    title: '',
    placeholder: 'Anything to address with the team?',
    bullet: '•',
    spinachUpdateType: SpinachUpdateType.Topic,
};

export const customUpdateSectionTypeProps: UpdateSectionTypeProps = {
    title: '',
    placeholder: "We'd love to know",
    bullet: '•',
    spinachUpdateType: SpinachUpdateType.Custom,
};

export const icebreakerUpdateSectionTypeProps: UpdateSectionTypeProps = {
    title: 'Icebreaker',
    placeholder: 'Make it personal',
    bullet: '•',
    spinachUpdateType: SpinachUpdateType.Icebreaker,
};

export const sectionTypeList: UpdateSectionTypeProps[] = [
    yesterdaysHighlightsUpdateSectionTypeProps,
    todaysUpdateSectionTypeProps,
    challengesUpdateSectionTypeProps,
    detailItemTypeProps,
    actionItemTypeProps,
    customUpdateSectionTypeProps,
];

export const RESERVED_TYPES: SpinachUpdateType[] = Object.values(SpinachUpdateType).filter(
    (t) => t !== SpinachUpdateType.Custom
);

export const NON_TYB_RESERVED_TYPES: SpinachUpdateType[] = [
    SpinachUpdateType.Topic,
    SpinachUpdateType.ActionItems,
    SpinachUpdateType.Notes,
    SpinachUpdateType.ParkingLot,
];
