import { atom } from 'recoil';

import { DEFAULT_AVATAR_STATE } from '@spinach-shared/constants';
import { AvatarDialogueState, AvatarSharedState, AvatarTaskKind, AvatarTaskRequest } from '@spinach-shared/types';

export type AvatarClassificationState = {
    taskId: string;
    taskKind: AvatarTaskKind;
} & AvatarTaskRequest;

export const atomLiveAvatar = atom<AvatarSharedState>({
    key: 'live-avatar', // unique ID (with respect to other atoms/selectors)
    default: DEFAULT_AVATAR_STATE,
});

/**
 * @NOTE should this be a map with array values instead to process multiple requests at the same time
 * from multiple different taskKinds?
 * */
export const atomAvatarClassificationResult = atom<AvatarClassificationState | null>({
    key: 'avatar-classification-result', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

export type AgentLocalState = {
    isNotificationCenterExpanded: boolean;
    dialogueState: AvatarDialogueState;
};

export const atomLocalAgent = atom<AgentLocalState>({
    key: 'local-agent-state',
    default: {
        isNotificationCenterExpanded: false,
        dialogueState: AvatarDialogueState.IdleWithSuggestions,
    },
});
