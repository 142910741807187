import {
    APIPathParam,
    PatchTicketUserSettingsRequest,
    SpinachAPIPath,
    TicketIntegrationConfiguration,
    TicketSource,
} from '@spinach-shared/types';

import { patchSpinachAPI } from './patchSpinachAPI';

export async function patchTicketUserSettings(
    ticketSource: TicketSource,
    ticketIntegrationSettings: PatchTicketUserSettingsRequest
): Promise<{ ticketIntegrationSettings: TicketIntegrationConfiguration | null }> {
    return await patchSpinachAPI(
        SpinachAPIPath.PatchTicketUserSettings.replaceAll(APIPathParam.TicketSource, ticketSource),
        ticketIntegrationSettings
    );
}
