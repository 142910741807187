import { AiHistoryUserAccessKind, SpinachAPIPath } from '@spinach-shared/types';

import { patchSpinachAPI } from './patchSpinachAPI';

export const patchDraftRecipients = async (
    botId: string,
    updatedUsers: { email: string; kind: AiHistoryUserAccessKind }[]
) => {
    await patchSpinachAPI(SpinachAPIPath.DraftRecipients.replaceAll(':botId', botId), { updatedUsers });
};
