import {
    ClientEventType,
    NavDrawer,
    TooltipFeatureDiscoveryId,
    TooltipFeatureDiscoveryKey,
} from '@spinach-shared/types';
import { isDemoSeries } from '@spinach-shared/utils';

import { useGlobalAuthedUser, useGlobalNavDrawer, useGlobalStoredSeries } from '../../../hooks';
import { FeatureTooltipCalloutClickProps, UseCustomTooltipCalloutResults } from '../../common/feature-discovery';
import { CustomTooltipCallout, useCustomTooltipCallout } from './CustomTooltipCallout';

export function useJiraMagicInputCallout(): UseCustomTooltipCalloutResults {
    const [storedSeries] = useGlobalStoredSeries();
    const { drawer } = useGlobalNavDrawer();
    const [user] = useGlobalAuthedUser();
    const isAuthedWithJira = user.isAuthedForJira;

    const isRealSeries = !isDemoSeries(storedSeries.slug);

    const shouldShowThisHint = isRealSeries && drawer === NavDrawer.EditCheckIn && isAuthedWithJira;

    return useCustomTooltipCallout(
        shouldShowThisHint,
        ClientEventType.OnIntegrateJiraCalloutClick,
        TooltipFeatureDiscoveryKey.JiraMagicInput
    );
}

export const JiraMagicInputCallout = ({ onEngageClick }: FeatureTooltipCalloutClickProps): JSX.Element => {
    const { isVisible } = useJiraMagicInputCallout();
    return (
        <CustomTooltipCallout
            isVisible={isVisible}
            onEngageClick={onEngageClick}
            calloutText="Add Jira Tickets by hitting the + icon."
            clientEventType={ClientEventType.JiraMagicInputCalloutDisplayed}
            tooltipFeatureDiscoveryElementId={TooltipFeatureDiscoveryId.JiraMagicInputId}
        />
    );
};
