import { useEffect, useState } from 'react';

import { MILLIS_IN_SECONDS, SECONDS_IN_MINUTES } from '@spinach-shared/constants';
import { ClientUser } from '@spinach-shared/models';
import { IClientUser } from '@spinach-shared/types';

import { getUser } from '../apis';
import { useGlobalAuthedUser } from './useGlobalUser';

let intervalTimeout: ReturnType<typeof window.setTimeout>;
let killswitchTimeout: ReturnType<typeof window.setTimeout>;

export function useIntegrationDetection(
    onSuccess?: ((updatedUser: ClientUser) => void) | ((updatedUser: ClientUser) => Promise<void>),
    didChange: (originalUser: ClientUser, fetchedUser: IClientUser) => boolean = (originalUser, fetchedUser) =>
        originalUser.areIntegrationsUpdated(fetchedUser.integrationSettings) ||
        originalUser.metadata.isUsingRecallCalendarV2 !== fetchedUser.metadata.isUsingRecallCalendarV2
) {
    const [user, setUser] = useGlobalAuthedUser();
    const [shouldStartFetching, setShouldStartFetching] = useState(false);

    function cleanup() {
        setShouldStartFetching(false);

        if (intervalTimeout) {
            clearTimeout(intervalTimeout);
        }
        if (killswitchTimeout) {
            clearTimeout(killswitchTimeout);
        }
    }

    useEffect(() => {
        return () => {
            cleanup();
        };
    }, []);

    useEffect(() => {
        if (shouldStartFetching) {
            /**
             * Begin loop that fetches user every 2 seconds for 3 minutes
             * if the integrations have been updated, we set them into state
             */
            const EVERY_2_SECONDS = 2 * MILLIS_IN_SECONDS;
            intervalTimeout = setInterval(async () => {
                const userResponse = await getUser();
                if (userResponse.user) {
                    if (didChange(user, userResponse.user)) {
                        setUser(userResponse.user);
                        cleanup();
                        if (onSuccess) {
                            onSuccess(new ClientUser(userResponse.user));
                        }
                    }
                }
            }, EVERY_2_SECONDS);

            /**
             * end interval after 3 minutes
             */
            const AFTER_3_MINUTES = 5 * MILLIS_IN_SECONDS * SECONDS_IN_MINUTES;
            killswitchTimeout = setTimeout(() => {
                cleanup();
            }, AFTER_3_MINUTES);
        }
    }, [shouldStartFetching]);

    return () => {
        setShouldStartFetching(true);
    };
}
