import { MeetingHistoryJSON, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function getSeriesHistories(seriesId: string): Promise<MeetingHistoryJSON[] | undefined> {
    const response = await getSpinachAPI<{ meetingHistory: MeetingHistoryJSON[] }>(SpinachAPIPath.History, {
        params: { seriesId },
    });

    return response?.meetingHistory;
}
