import { SpinachAPIPath } from '@spinach-shared/types';

import { deleteSpinachAPI } from '../deleteSpinachAPI';

export type DeleteMeetingNotesAsAdmin = { botId?: string; code?: string };

export async function deleteMeetingNotesAsAdmin(botId: string): Promise<DeleteMeetingNotesAsAdmin | undefined> {
    const data = await deleteSpinachAPI<DeleteMeetingNotesAsAdmin>(
        SpinachAPIPath.AdminUserMeeting.replace(':botId', botId),
        undefined,
        true
    );

    return data;
}
