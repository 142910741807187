import { Modal } from '@material-ui/core';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ClientSocketEvent, Goal, SpinachAPIPath, TypedUpdate, YTBUpdateUpdatedRequest } from '@spinach-shared/types';
import { getTypedUpdateFromGoal } from '@spinach-shared/utils';

import { getSpinachAPI } from '../../../apis';
import { GlobalModal } from '../../../atoms';
import {
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNullableLiveSeries,
    useGlobalStoredSeries,
    useWindowSize,
} from '../../../hooks';
import { useGlobalMeetingSocket } from '../../../hooks/useGlobalSocket';
import { BodyHuge, BodyRegular, responsiveness } from '../../../styles';
import { createWebsocketPayload } from '../../../utils';
import { PreviousUpdateSectionInputWithRollover } from '../../input/jira/PreviousUpdateSectionInputWithRollover';
import { ScrollArea } from '../../stand-up';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row } from '../framing';

const ResponsiveModalTitle = styled(BodyHuge)`
    @media ${responsiveness.thinnerThanSM} {
        font-size: 20px;
    }

    @media ${responsiveness.thinnerThanXS} {
        font-size: 18px;
    }
`;

export function ArchivedUserGoalsModal(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [series] = useGlobalStoredSeries();
    const [socket] = useGlobalMeetingSocket();
    const [liveSeries] = useGlobalNullableLiveSeries();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const [archived, setArchived] = useState<Goal[]>([]);
    const [archivedUpdates, setArchivedUpdates] = useState<TypedUpdate[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { height } = useWindowSize();

    useEffect(() => {
        async function fetchArchivedGoals() {
            setIsLoading(true);
            const response = await getSpinachAPI<{ userGoals: Goal[] }>(SpinachAPIPath.UserGoal, {
                params: { more: 20 },
            });
            if (response?.userGoals) {
                setArchived(response.userGoals);
                const updates = response.userGoals.map((goal) => {
                    return getTypedUpdateFromGoal(goal);
                });
                setArchivedUpdates(updates);
            }
            setIsLoading(false);
        }

        if (globalModal === GlobalModal.ArchivedUserGoals) {
            fetchArchivedGoals();
        }
    }, [globalModal]);

    const closeModal = () => {
        setGlobalModal(null);
    };

    // TODO refactor to custom hook for custom update emitters
    const createUpdateEmitter = (update: TypedUpdate) => (text: string) => {
        const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
            ...user.toUserIdentity(),
            seriesSlug: series.slug,
            spinachUserId: user.spinachUserId,
            participantId: user.spinachUserId,
            meetingId: liveSeries?.currentMeeting.id,
            update: {
                ...update,
                text,
            },
        });
        socket?.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
    };

    function onConfirmation(typedUpdate: TypedUpdate) {
        const updatedArchived = archivedUpdates.filter((u) => u.id !== typedUpdate.id);
        setArchivedUpdates(updatedArchived);
    }

    return (
        <Modal open={globalModal === GlobalModal.ArchivedUserGoals} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden' }}>
                <Row centered>
                    <ResponsiveModalTitle>
                        <b>Previous Items</b>
                    </ResponsiveModalTitle>
                </Row>
                <ScrollArea sidePadding={10} style={{ maxHeight: `${height * 0.9 - 130}px` }}>
                    <Column style={{ flexGrow: 1, justifyContent: 'center' }}>
                        {isLoading ? (
                            <BodyRegular>Loading...</BodyRegular>
                        ) : archived.length === 0 ? (
                            <Column>
                                <BodyRegular>No Previous Items</BodyRegular>
                            </Column>
                        ) : (
                            archivedUpdates.map((update) => (
                                <PreviousUpdateSectionInputWithRollover
                                    key={update.id}
                                    createUpdateEmitter={createUpdateEmitter}
                                    typedUpdate={update}
                                    onConfirmation={() => onConfirmation(update)}
                                />
                            ))
                        )}
                    </Column>
                </ScrollArea>
            </SpinachModalContent>
        </Modal>
    );
}
