import { EmailReferralRequest, EmailReferralResponseCode, SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from './postSpinachAPI';

export async function postReferralEmail(email: string, referrerName: string): Promise<EmailReferralResponseCode> {
    try {
        const response = await postSpinachAPI<{ code: EmailReferralResponseCode }, EmailReferralRequest>(
            SpinachAPIPath.EmailReferral,
            {
                email,
                referrerName,
            }
        );
        if (!response) {
            return EmailReferralResponseCode.Failed;
        }
        return response.code;
    } catch (error) {
        return EmailReferralResponseCode.Failed;
    }
}
