import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { DetailedTicket } from '@spinach-shared/types';

import { fetchAsanaIssues } from '@spinach-clients/shared/src/utils';

import { recentAsanaIssuesAtom } from '../atoms';
import { SetValue } from '../types';
import { useGlobalAuthedUser } from './useGlobalUser';

// reference fetchJiraIssues
export const useGlobalAsanaIssues = (): [
    assanaIssues: DetailedTicket[] | null,
    setAsanaIssues: SetValue<DetailedTicket[] | null>
] => {
    return useRecoilState(recentAsanaIssuesAtom);
};

/**
 *
 * @returns Asana issues (if a user is authed with asana)
 *
 * NOTE: Should only be used in places where user is garunteed to be defined
 */
export const useAsanaIssues = (isLoaded: boolean) => {
    const [asanaIssues, setSuggestedAsanaIssues] = useGlobalAsanaIssues();

    const [user] = useGlobalAuthedUser();

    useEffect(() => {
        const getAsanaIssues = async () => {
            const issues = await fetchAsanaIssues();

            if (!issues.length) {
                return;
            }

            setSuggestedAsanaIssues(issues);
        };

        if (user.isAuthedForAsana && isLoaded) {
            getAsanaIssues();
        }
    }, [user.isAuthedForAsana, isLoaded]);

    return [asanaIssues];
};
