import { useAsanaEnablement } from '../../../hooks';
import { AsanaIntegrationRow } from './AsanaIntegrationRow';
import { GoogleMeetIntegrationRow } from './GoogleMeetIntegrationRow';
import { JiraIntegrationRow } from './JiraIntegrationRow';
import { SlackIntegrationRow } from './SlackIntegrationRow';
import { ZoomIntegrationRow } from './ZoomIntegrationRow';

export function AppIntegrations(): JSX.Element {
    const isAsanaIntegrationEnabled = useAsanaEnablement();

    return (
        <>
            <SlackIntegrationRow />
            <JiraIntegrationRow />
            {isAsanaIntegrationEnabled ? <AsanaIntegrationRow /> : null}
            <ZoomIntegrationRow />
            <GoogleMeetIntegrationRow />
        </>
    );
}
