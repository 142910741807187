import { useRecoilState } from 'recoil';

import { BaseMeetingProps } from '@spinach-shared/models';

import { atomSummaryHistories } from '../atoms';
import { SetValue } from '../types';

export function useGlobalSummaryHistories(): [BaseMeetingProps[] | null, SetValue<BaseMeetingProps[] | null>] {
    const [summaries, setSummaries] = useRecoilState(atomSummaryHistories);

    return [summaries, setSummaries];
}
