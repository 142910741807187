import { createTheme } from '@material-ui/core';

declare module 'styled-components' {
    export interface DefaultTheme extends SpinachTheme {}
}

type HexColor = string;

type Palette = {
    midnight: HexColor;
    green: HexColor;
    greenDark: HexColor;
    greenLight: HexColor;
    orangeLight: HexColor;
    orangeDark: HexColor;
};

type Neutrals = {
    midnight: HexColor;
    gray: HexColor;
    grayDark: HexColor;
    grayLight: HexColor;
    white: HexColor;
    offWhite: HexColor;
    clear: string;
};

type Status = {
    positive: HexColor;
    negative: HexColor;
    warning: HexColor;
    info: HexColor;
};

type Tooltip = {
    background: HexColor;
    text: HexColor;
};

type ActionCard = {
    gradient: string;
    border: HexColor;
};

type ContentOverlay = {
    background: string;
    textColor: string;
};

type SpinachTheme = {
    primary: Palette;
    secondary: Palette;
    tertiary: Palette;
    neutrals: Neutrals;
    status: Status;
    tooltip: Tooltip;
    actionCard: ActionCard;
    actionCardPlaceholder: ActionCard;
    tag: Tag;
    contentOverlay: ContentOverlay;
};

type Tag = {
    actionColor: HexColor;
    textColor: HexColor;
    borderColor: HexColor;
    background: HexColor;
};

const primary = {
    midnight: '#1D1D27',
    green: '#0B674A',
    greenDark: '#08433B',
    greenLight: '#35A289',
    orangeLight: '#FAA21E',
    orangeDark: '#F36D22',
};

const secondary = {
    midnight: '#3E3E49',
    green: '#33A484',
    greenDark: '#085F57',
    greenLight: '#83C8B8',
    orangeLight: '#FCC97D',
    orangeDark: '#FFA83A',
};

export const lightTheme: SpinachTheme = {
    primary,
    secondary,
    tertiary: {
        midnight: '#B8B8C5',
        green: '#83CAB5',
        greenDark: '#269C96',
        greenLight: '#B3DED3',
        orangeLight: '#FDDEB0',
        orangeDark: '#FFCD87',
    },
    neutrals: {
        midnight: '#BAC6C6',
        gray: '#E9EDED',
        grayDark: '#DFE5E5',
        grayLight: '#F4F6F6',
        white: '#FFFFFF',
        offWhite: '#f5f5f5',
        clear: 'rgba(0, 0, 0, 0)',
    },
    status: {
        positive: '#35A252',
        negative: '#E14B00',
        warning: '#F3D722',
        info: '#2684FF',
    },
    tooltip: {
        background: '#000000',
        text: '#FFFFFF',
    },
    actionCard: {
        gradient: 'linear-gradient(180deg, #EDF9F6 0%, #FCFFFE 100%)',
        border: '#D5EAE5',
    },
    actionCardPlaceholder: {
        gradient: 'linear-gradient(180deg, #F7F7F7 0%, #FFFFFF 100%)',
        border: '#F1F1F1',
    },
    tag: {
        actionColor: '#0D674B',
        textColor: '#000000',
        borderColor: '#B8B8C5',
        background: '#F9F9F9',
    },
    contentOverlay: {
        background: 'rgba(0, 0, 0, 0.8)',
        textColor: '#FFFFFF',
    },
};

export const muiTheme = createTheme({
    palette: {
        primary: {
            light: primary.orangeLight,
            main: primary.orangeLight,
            dark: '#F36D22',
        },
        secondary: {
            light: '#83C8B8',
            main: lightTheme.primary.greenLight,
            dark: '#08433B',
        },
    },
});
