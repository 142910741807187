import { ISOString } from '@spinach-shared/types';

export const getFormattedDate = (agendaStartedAt: ISOString) => {
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const commaSeparatedDateTime = new Date(agendaStartedAt).toLocaleTimeString('en-us', options);

    const elementsOfDateTime = commaSeparatedDateTime.split(', ');
    elementsOfDateTime.pop();

    const desiredDateFormat = elementsOfDateTime.join(', ');
    return desiredDateFormat;
};

export const getAbbreviatedDateElements = (
    agendaStartedAt: ISOString
): { weekday: string; dayOfMonth: string; year: string } => {
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const commaSeparatedDateTime = new Date(agendaStartedAt).toLocaleTimeString('en-us', options);

    const elementsOfDateTime = commaSeparatedDateTime.split(', ');
    elementsOfDateTime.pop();

    const [weekday, dayOfMonth, year] = elementsOfDateTime;
    return {
        weekday,
        dayOfMonth,
        year,
    };
};
