import { CSSProperties } from 'react';
import styled from 'styled-components';

import { GoalStatus, TypedUpdate } from '@spinach-shared/types';

import { useIssueBasedEnablement, useLiveItemSentimentDetailsEnabled } from '../../hooks';
import { BodyRegular, withSelectionPrevention } from '../../styles';
import { withContentMasking } from '../../utils';
import { Bullet, BulletMark, Row } from '../common';
import { sentimentColorMap } from '../input/SentimentPicker';

const SentimentDetails = styled(BodyRegular)`
    ${withSelectionPrevention()};
`;

export const LiveItemSentimentDetails = ({
    typedUpdate,
    isInNavDrawer,
    labelStyle,
}: {
    typedUpdate: TypedUpdate;
    isInNavDrawer?: boolean;
    labelStyle?: CSSProperties;
}) => {
    const isLiveItemDetailsEnabled = useLiveItemSentimentDetailsEnabled();
    const isIssueBasedEnabled = useIssueBasedEnablement();

    if (
        !isLiveItemDetailsEnabled ||
        !typedUpdate.sentimentDetails ||
        !typedUpdate.sentiment ||
        (isIssueBasedEnabled &&
            typedUpdate.goalMetadata?.status &&
            typedUpdate.goalMetadata.status === GoalStatus.Complete)
    ) {
        return <></>;
    }

    return (
        <Row style={{ alignItems: 'center' }}>
            {!isInNavDrawer ? (
                <BulletMark style={{ marginTop: 'unset', alignContent: 'start' }}>
                    <Bullet fill={sentimentColorMap[typedUpdate.sentiment]} />
                </BulletMark>
            ) : (
                <></>
            )}
            <SentimentDetails
                style={{ fontSize: '15px', padding: '3px 5px', fontStyle: 'italic', ...labelStyle }}
                {...withContentMasking()}
            >
                {typedUpdate.sentimentDetails}
            </SentimentDetails>
        </Row>
    );
};
