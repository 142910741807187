import { z } from 'zod';

export type LookupItemsQuery = z.infer<typeof LookupItemsQuerySchema>;

export const LookupItemsQuerySchema = z.array(
    z.object({
        seriesId: z.string(),
        botId: z.string(),
        ticket: z.object({
            signature: z.string(),
            expiration: z.number(),
        }),
    })
);
