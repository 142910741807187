import { ClientEventType, NavDrawer, Sentiment, TooltipFeatureDiscoveryKey } from '@spinach-shared/types';

import { FeatureTooltipCalloutClickProps, UseCustomTooltipCalloutResults } from '.';
import { ElementClass } from '../../../constants/identifiers';
import { useGlobalNavDrawer, useLiveItemSentimentDetailsEnabled, useSeriesReality } from '../../../hooks';
import { CustomTooltipCallout, useCustomTooltipCallout } from './CustomTooltipCallout';

export function useSentimentReasonsCallout(): UseCustomTooltipCalloutResults & {
    elementId: string;
} {
    const isCalloutEnabledForUser = useLiveItemSentimentDetailsEnabled();
    const { drawer } = useGlobalNavDrawer();
    // find the first sentiment picker on the page
    const firstSentimentPicker = document.getElementsByClassName(ElementClass.SentimentPicker)?.[0];

    const { isRealSeries } = useSeriesReality();

    const shouldShowThisHint =
        drawer === NavDrawer.EditCheckIn && isCalloutEnabledForUser && isRealSeries && !!firstSentimentPicker;

    const calloutProps = useCustomTooltipCallout(
        shouldShowThisHint,
        ClientEventType.OnSentimentReasonPickerCalloutClick,
        TooltipFeatureDiscoveryKey.RegularUpdateSentimentReasons
    );

    return {
        ...calloutProps,
        elementId: firstSentimentPicker?.id,
    };
}

export const SentimentReasonsCallout = ({ onEngageClick }: FeatureTooltipCalloutClickProps): JSX.Element => {
    const { isVisible, elementId } = useSentimentReasonsCallout();
    return (
        <CustomTooltipCallout
            isVisible={isVisible}
            onEngageClick={onEngageClick}
            textContainerStyle={{ maxWidth: '175px' }}
            calloutText="Tell the team more about what's happening!"
            clientEventType={ClientEventType.SentimentReasonPickerCalloutDisplayed}
            tooltipFeatureDiscoveryElementId={`${elementId}-${Sentiment.Good}`}
        />
    );
};
