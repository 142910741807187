import { Modal } from '@material-ui/core';

import { ClientUser } from '@spinach-shared/models';
import { ClientEventType } from '@spinach-shared/types';

import { patchUserAsDeactivated } from '../../../apis/admin/patchUserAsDeactivated';
import { GlobalModal } from '../../../atoms';
import { useExperienceTracking, useGlobalAdmin, useGlobalModal } from '../../../hooks';
import { BodyRegular, ResponsiveModalTitle } from '../../../styles';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

export const ConfirmDeactivateUserAsAdmin = () => {
    const [globalModal, setGlobalModal] = useGlobalModal();
    const { state, setState: setAdminState } = useGlobalAdmin();
    const { searchedUser } = state;

    const track = useExperienceTracking();

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Confirm Deactivate User As Admin',
        });
        setGlobalModal(null);
        setAdminState((updatedState) => ({
            ...updatedState,
            isLoadingDeactivateUser: false,
        }));
    };

    if (!searchedUser) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.ConfirmDeactivateUserAsAdmin} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden' }}>
                <Column centered>
                    <Row centered>
                        <ResponsiveModalTitle>Confirm Deactivate User</ResponsiveModalTitle>
                    </Row>
                    <Spacing factor={1 / 3} />
                </Column>

                <Column style={{ alignItems: 'start' }}>
                    <BodyRegular>Are you sure you want to deactivate {searchedUser.email} ?</BodyRegular>
                    <Spacing factor={1 / 3} />
                </Column>
                <span style={{ flexGrow: 1 }} />
                <PrimaryButton
                    title="Confirm"
                    isLoading={state.isLoadingDeactivateUser}
                    onClick={async () => {
                        if (!searchedUser) {
                            return;
                        }

                        setAdminState((updatedState) => ({
                            ...updatedState,
                            isLoadingDeactivateUser: true,
                        }));

                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Confirm Deactivate User as Admin',
                            SelectedUserId: searchedUser.spinachUserId,
                        });

                        const response = await patchUserAsDeactivated(searchedUser.spinachUserId);

                        const updatedUser = response.user;
                        if (updatedUser) {
                            setAdminState((updatedState) => ({
                                ...updatedState,
                                searchedUser: new ClientUser(updatedUser),
                                isLoadingDeactivateUser: false,
                            }));
                        } else {
                            setAdminState((updatedState) => ({
                                ...updatedState,
                                isLoadingDeactivateUser: false,
                            }));
                        }

                        closeModal();
                    }}
                />
                <Spacing factor={1 / 3} />
                <SecondaryButton title="Cancel" onClick={closeModal} disabled={state.isLoadingDeactivateUser} />
            </SpinachModalContent>
        </Modal>
    );
};
