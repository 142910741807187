export enum ConfluenceSpaceType {
    Personal = 'personal',
    Global = 'global',
}

export type MinimalConfluenceSpace = {
    name: string;
    key: string;
    id: number | string;
    type: ConfluenceSpaceType;
    isSelfSpace?: boolean;
};

export type ConfluenceSpace = MinimalConfluenceSpace & {
    homepageId: number;
    authorId: number;
    icon: string | null;
    description: string;
    status: 'current';
};

export enum ConfluenceSpaceSortOrder {
    IdAsc = 'id',
    IdDesc = '-id',
    KeyAsc = 'key',
    KeyDesc = '-key',
    NameAsc = 'name',
    NameDesc = '-name',
}

export type ConfluenceGetSpacesRequest = {
    ids?: string[];
    keys?: string[];
    type?: ConfluenceSpaceType;
    status?: 'current' | 'archived';
    labels?: string[];
    /** @NOTE favorited-by and not-favorited-by should be an Atlassian user accountId */
    'favorited-by'?: string;
    'not-favorited-by'?: string;
    sort?: ConfluenceSpaceSortOrder;
    'description-format'?: 'plain' | 'view';
    /** @NOTE default false */
    'include-icon'?: boolean;
    cursor?: string;
    limit?: number;
};
