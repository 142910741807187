import styled from 'styled-components';

import {
    ClientEventType,
    OmniboxItemSelectionTrigger,
    OmniboxSearchOptions,
    UserIntegrations,
} from '@spinach-shared/types';

import { ReactComponent as JiraLogo } from '../../assets/jira-logo.svg';
import { useExperienceTracking, useFreeTierLimitations, useGlobalStoredSeries, useUserAuthMap } from '../../hooks';
import { BodyRegular, ButtonSize } from '../../styles';
import { OmniboxSearchProps, OmniboxSearchResults, SetValue } from '../../types';
import { FreeTierLimitationMessage, LimitationIntent, Row } from '../common';
import { OutlinedButton } from '../stand-up/OutlinedButton';
import { Omnibox } from './Omnibox';

type OmniboxSearchOptionsSelectorProps = {
    selectedOmniboxSearchOption: OmniboxSearchOptions | null;
    setSelectedOmniboxSearchOption: SetValue<OmniboxSearchOptions | null>;
    searchOptionOptions: Record<OmniboxSearchOptions, OmniboxSearchProps<OmniboxSearchResults>>;
    searchText: string;
    onClose?: () => void;
};

const OmniboxSearchOptionsContainer = styled.div`
    padding-left: 10px;
    padding-top: 5px;
`;

export const OmniboxSearchOptionSelector = ({
    searchOptionOptions,
    searchText,
    selectedOmniboxSearchOption,
    setSelectedOmniboxSearchOption,
    onClose,
}: OmniboxSearchOptionsSelectorProps) => {
    const track = useExperienceTracking();
    const [series] = useGlobalStoredSeries();
    const isFreeTierLimited = useFreeTierLimitations();

    const [userAuthMap] = useUserAuthMap();

    const userSelectedOmniboxItemTracking = (
        selectedOption: OmniboxSearchOptions,
        trigger?: OmniboxItemSelectionTrigger,
        opts?: { itemId: string }
    ) => {
        const { itemId = '' } = opts ?? {};
        track(ClientEventType.UserSelectedOmniboxItem, {
            SeriesId: series.id,
            OmniboxSearchOption: selectedOption,
            ItemId: itemId,
            Trigger: trigger,
            NumberOfLettersTyped: searchText.trim().length,
        });
    };

    if (isFreeTierLimited) {
        return (
            <OmniboxSearchOptionsContainer>
                <Row style={{ alignItems: 'center' }}>
                    <BodyRegular>
                        <FreeTierLimitationMessage intent={LimitationIntent.Jira} />
                    </BodyRegular>
                </Row>
            </OmniboxSearchOptionsContainer>
        );
    }

    return selectedOmniboxSearchOption && searchOptionOptions[selectedOmniboxSearchOption] ? (
        <OmniboxSearchOptionsContainer>
            <BodyRegular style={{ justifyContent: 'start', display: 'flex', fontWeight: 'bold' }}>
                {searchOptionOptions[selectedOmniboxSearchOption].headerText}
            </BodyRegular>
            <Omnibox
                onClick={({ item, title, trigger, index }) => {
                    searchOptionOptions[selectedOmniboxSearchOption].onClick({ item, title, index });
                    userSelectedOmniboxItemTracking(selectedOmniboxSearchOption, trigger, { itemId: item.id });
                }}
                onClose={onClose}
                items={searchOptionOptions[selectedOmniboxSearchOption].items}
            />
        </OmniboxSearchOptionsContainer>
    ) : (
        <OmniboxSearchOptionsContainer>
            <Omnibox
                disableKeyboard={true}
                onClick={({ item }) => {
                    if (!userAuthMap[item].authed) {
                        if (userAuthMap[item].isLoading) {
                            window.location.reload();
                        } else {
                            userAuthMap[item].authorize();
                        }
                    } else {
                        setSelectedOmniboxSearchOption(item as OmniboxSearchOptions);
                    }
                }}
                items={[
                    {
                        item: UserIntegrations.Jira,
                        title: !userAuthMap[UserIntegrations.Jira].authed ? (
                            userAuthMap[UserIntegrations.Jira].isLoading ? (
                                <Row style={{ alignItems: 'center' }}>
                                    <JiraLogo />
                                    <BodyRegular>Jira Connection Pending...</BodyRegular>
                                    <OutlinedButton
                                        title={'Refresh App'}
                                        style={{ marginLeft: '8px' }}
                                        size={ButtonSize.Mini}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.reload();
                                        }}
                                    />
                                </Row>
                            ) : (
                                <Row style={{ alignItems: 'center' }}>
                                    <JiraLogo />
                                    <BodyRegular>Connect Jira to add tickets</BodyRegular>
                                </Row>
                            )
                        ) : (
                            <Row style={{ alignItems: 'center' }}>
                                <JiraLogo />
                                <BodyRegular>Add a Jira Ticket</BodyRegular>
                            </Row>
                        ),
                    },
                ]}
            />
        </OmniboxSearchOptionsContainer>
    );
};
