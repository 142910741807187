import { ClientPlatform } from '@spinach-shared/types';

export function isWebPlatform(): boolean {
    return process.env.REACT_APP_PLATFORM === ClientPlatform.Web;
}

export function isChromeExtensionPlatform(): boolean {
    return process.env.REACT_APP_PLATFORM === ClientPlatform.Chrome;
}

export function isZoomPlatform(): boolean {
    return process.env.REACT_APP_PLATFORM === ClientPlatform.Zoom;
}

export function getClientPlatform(): ClientPlatform {
    return process.env.REACT_APP_PLATFORM as ClientPlatform;
}
