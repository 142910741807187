import { useEffect } from 'react';

import { FeatureToggle } from '@spinach-shared/types';

import { postAnonymousFeatureFlag, useGlobalNullableVideoAgent } from '../../..';
import { DEFAULT_ASSET_MAP } from '../../atoms';

export function useFetchAgentAssetMap() {
    const {
        session,
        state: { assetMap },
        setState,
    } = useGlobalNullableVideoAgent();
    useEffect(() => {
        async function getAgentAssetMap() {
            if (assetMap) {
                return;
            }

            if (!session) {
                return;
            }

            const assetMapResponse = await postAnonymousFeatureFlag(
                FeatureToggle.VideoAgentAssetMap,
                DEFAULT_ASSET_MAP,
                undefined,
                session.hostId,
                {
                    botId: session.botId,
                    seriesId: session.raw.seriesId,
                }
            );
            if (assetMapResponse.listeningSrc && assetMapResponse.speakingSrc) {
                setState((prev) => ({
                    ...prev,
                    assetMap: assetMapResponse,
                }));
            } else {
                setState((prev) => ({
                    ...prev,
                    assetMap: DEFAULT_ASSET_MAP,
                }));
            }
        }

        getAgentAssetMap();
    }, [session, setState, assetMap]);
}
