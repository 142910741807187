const maxMeaningfulDelay = 50;

export function bufferToStringWithUnderscoreDelay(
    buffer: { text: string; startTimestamp: number; endTimestamp: number }[],
    msSinceLastWord: number
): string {
    let result = '_'.repeat(maxMeaningfulDelay);
    let lastEndTimestamp = 0;

    if (buffer.length === 0) {
        return result;
    }

    for (let i = 0; i < buffer.length; i++) {
        const { text, startTimestamp, endTimestamp } = buffer[i];

        // Calculate delay in dashes
        let delay = Math.round((startTimestamp - lastEndTimestamp) / 100);
        delay = Math.max(1, Math.min(delay, maxMeaningfulDelay)); // Ensure delay is between 1 and 50

        if (i > 0) {
            // Add dashes for delay
            result += '_'.repeat(delay);
        }

        // Add word, replacing any dashes with spaces
        result += text.replaceAll('-', '_').replaceAll(/[\?,;:\.]/g, '');

        lastEndTimestamp = endTimestamp;
    }

    // Add final dashes
    const finalDelay = Math.max(1, Math.round(msSinceLastWord / 100));
    result += '_'.repeat(Math.min(maxMeaningfulDelay, finalDelay));

    return result;
}
export function convertUnderscoreToHumanReadableTimestamps(input: string): string {
    let result = '';
    let underscoreCount = 0;

    function addPause() {
        if (underscoreCount > 25) {
            result += '\n';
        } else if (underscoreCount < 5) {
            result += '-'.repeat(underscoreCount);
        } else {
            const dots = Math.floor(underscoreCount / 5);
            const remainingUnderscores = underscoreCount % 5;
            result += '.'.repeat(dots) + '-'.repeat(remainingUnderscores);
        }
        underscoreCount = 0;
    }

    for (let i = 0; i < input.length; i++) {
        if (input[i] === '_') {
            underscoreCount++;
        } else {
            if (underscoreCount > 0) {
                addPause();
            }
            result += input[i];
        }
    }

    // Handle trailing underscores
    if (underscoreCount > 0) {
        addPause();
    }

    return result;
}
