import { useRecoilState } from 'recoil';

import { DemoGuide, ORDERED_GUIDES } from '@spinach-shared/constants';

import { DemoModal, GlobalDemoState, atomDemoState } from '../atoms';
import { ElementId } from '../constants';
import { SetValue } from '../types';
import { useGlobalNullableLiveSeries } from './useLiveSeries';

type UseGlobalDemoStateProps = GlobalDemoState & {
    demoState: GlobalDemoState;
    currentGuide: DemoGuide;
    currentGuideIndex: number;

    setDemoState: SetValue<GlobalDemoState>;
    setFocusedFeature: (id: ElementId | null) => void;
    setHasClickedPreviousCheckIn: () => void;
    setHasOpenedSummariesTabInDemo: () => void;
    setDemoModal: (demoModal: DemoModal | null) => void;
    setIsGuideOpen: (isGuideOpen: boolean) => void;
};

export function useGlobalDemoState(): UseGlobalDemoStateProps {
    const [demoState, setDemoState] = useRecoilState(atomDemoState);
    const [liveSeries] = useGlobalNullableLiveSeries();

    function setFocusedFeature(id: ElementId | null) {
        setDemoState({
            ...demoState,
            focusedFeature: id,
        });
    }

    function setIsGuideOpen(isGuideOpen: boolean) {
        setDemoState({
            ...demoState,
            isGuideOpen,
        });
    }

    function setHasClickedPreviousCheckIn() {
        setDemoState({
            ...demoState,
            hasClickedPreviousCheckIn: true,
        });
    }

    function setHasOpenedSummariesTabInDemo() {
        setDemoState({
            ...demoState,
            hasOpenedSummariesTabInDemo: true,
        });
    }

    function setDemoModal(demoModal: DemoModal | null) {
        setDemoState({
            ...demoState,
            demoModal,
        });
    }

    return {
        hasClickedImReady: demoState.hasClickedImReady,
        haveAutoTopicNotesFired: demoState.haveAutoTopicNotesFired,
        demoPrefaceView: demoState.demoPrefaceView,
        spinachWindowOverlay: demoState.spinachWindowOverlay,
        focusedFeature: demoState.focusedFeature,
        hasClickedPreviousCheckIn: demoState.hasClickedPreviousCheckIn,
        hasOpenedSummariesTabInDemo: demoState.hasOpenedSummariesTabInDemo,
        isGuideOpen: demoState.isGuideOpen,
        demoModal: demoState.demoModal,
        currentGuide: liveSeries?.currentDemoGuide ?? ORDERED_GUIDES[0],
        currentGuideIndex: liveSeries?.currentGuideIndex ?? 0,
        demoState,
        setFocusedFeature,
        setHasClickedPreviousCheckIn,
        setDemoState,
        setHasOpenedSummariesTabInDemo,
        setDemoModal,
        setIsGuideOpen,
    };
}
