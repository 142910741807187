// @ts-nocheck
import { ClientUser } from '@spinach-shared/models';

export type IntercomOptions = {
    hideDefaultLauncher?: boolean;
    customLauncherSelector?: string;
};

export function initIntercomWithUser(user: ClientUser, options: IntercomOptions = {}): void {
    window.intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'hcbqk8ct',
        user_id: user.spinachUserId,
        user_hash: user.metadata.intercomHash,
        name: user.preferredName,
        email: user.email,
        ...user.featureToggles,
        alignment: 'right',
        hide_default_launcher: options.hideDefaultLauncher,
        custom_launcher_selector: '#spinach_intercom',
    };

    // @ts-ignore
    (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            var d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/hcbqk8ct';
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
                l();
            } else if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    })();
}
