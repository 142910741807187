import { useRecoilState } from 'recoil';

import { MeetingSettingsSubview } from '@spinach-shared/types';

import { GlobalModal } from '../atoms';
import { MeetingSettingsState, atomMeetingSettingsState } from '../atoms/atomMeetingSettings';
import { ElementId } from '../constants';
import { useGlobalModal } from './useGlobalModal';

export type UseMeetingSettingsValue = MeetingSettingsState & {
    globalModal: GlobalModal | null;
    openRootSettings: () => void;
    closeRootSettings: () => void;
    openInviteSettings: () => void;
    openSeriesSettings: () => void;
    openIntegrationsSettings: (opts?: { autoClickId?: ElementId }) => void;
    openScheduleSettings: () => void;
    closeSettingsModal: () => void;
    setSubview: (subview: MeetingSettingsSubview | null) => void;
};

export function useGlobalMeetingSettings(): UseMeetingSettingsValue {
    const [meetingSettingsState, setMeetingSettingsState] = useRecoilState(atomMeetingSettingsState);
    const [globalModal, setGlobalModal] = useGlobalModal();

    function openRootSettings() {
        setMeetingSettingsState({
            ...meetingSettingsState,
            isRootSettingsOpen: true,
        });
    }

    function closeRootSettings() {
        setMeetingSettingsState({
            ...meetingSettingsState,
            isRootSettingsOpen: false,
        });
    }

    function openInviteSettings() {
        setGlobalModal(GlobalModal.ShareSeries);
    }

    function openSeriesSettings() {
        setGlobalModal(GlobalModal.SeriesSettings);
    }

    /**
     *
     * @param opts pass in a button id if you want to auto-click an authorize button in settings
     */
    function openIntegrationsSettings(opts: { autoClickId?: ElementId } = { autoClickId: undefined }) {
        setGlobalModal(GlobalModal.IntegrationsSettings);
        if (opts.autoClickId) {
            setTimeout(() => {
                const el = document.getElementById(opts.autoClickId!);
                if (el) {
                    el.click();
                }
            }, 200);
        }
    }

    function closeSettingsModal() {
        setGlobalModal(null);
    }

    function openScheduleSettings() {
        setGlobalModal(GlobalModal.Schedule);
    }

    function setSubview(subview: MeetingSettingsSubview | null) {
        setMeetingSettingsState({
            ...meetingSettingsState,
            subview,
        });
    }

    return {
        isRootSettingsOpen: meetingSettingsState.isRootSettingsOpen,
        subview: meetingSettingsState.subview,
        globalModal,
        openRootSettings,
        closeRootSettings,
        openInviteSettings,
        openSeriesSettings,
        openIntegrationsSettings,
        openScheduleSettings,
        closeSettingsModal,
        setSubview,
    };
}
