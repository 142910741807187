import { Box } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';

import { ClientEventType, UTMSource, WebUrlQuery } from '@spinach-shared/types';
import { ViralityLandingPageV1Props } from '@spinach-shared/utils';

import {
    BodyBigOnboard,
    BodyRegularOnboard,
    ClientLogger,
    Column,
    HeaderThreeOnboard,
    LoginWithGoogle,
    Row,
    Spacing,
    ViewContainer,
    lightTheme,
    useExperienceTracking,
    useLandingAnalytic,
    useWindowSize,
} from '../../../..';
import SpinachProofSrc from '../../../assets/calendar-v2-proof.png';
import { ReactComponent as GreenCheckIcon } from '../../../assets/green-circle-check.svg';
import { ReactComponent as SpinachLogo } from '../../../assets/logo-spinachio.svg';
import MeetingNotesPlaceholderSrc from '../../../assets/meeting-notes-placeholder.png';
import SecurityBadgeSrc from '../../../assets/security-badges.png';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { LoginWithMicrosoft } from '../LoginWithMicrosoft';

export function ViralityUserLoginPage(): JSX.Element {
    const track = useExperienceTracking();
    const [search, setSearch] = useSearchParams();

    let json: ViralityLandingPageV1Props | null = null;
    const data = search.get(WebUrlQuery.ViralityExperiment);
    try {
        const stringified = data ? atob(data) : '';
        const decoded = decodeURIComponent(stringified);
        if (decoded) {
            json = JSON.parse(decoded);
        }
    } catch (error) {
        ClientLogger.info('Error decoding virality experiment payload', {
            data,
        });
    }

    useLandingAnalytic(ClientEventType.LoginPageActivity, {
        Activity: 'Landed on Virality Page (V1)',
    });

    const { width, isDesktopView } = useWindowSize();

    const recipientNameFromUrl = json?.recipientFirstName;
    const greeting = recipientNameFromUrl
        ? `Hey ${recipientNameFromUrl}, your meeting notes are ready!`
        : 'Your meeting notes are ready!';

    const meetingTitleFromUrl = json?.meetingTitle || 'Meeting notes';
    const hostNameFromUrl = json?.hostFirstName;

    return (
        <ViewContainer>
            <Row style={{ height: '100%' }}>
                <Column
                    vCentered={true}
                    centered={true}
                    style={{ flex: 1, margin: '20px', width: 'calc(100% - 40px)' }}
                >
                    <Row>
                        <SpinachLogo
                            style={{
                                height: isDesktopView ? '55px' : '25px',
                            }}
                        />
                    </Row>
                    <Column style={{ maxWidth: '600px', height: 'calc(100% - 55px)' }}>
                        <Spacing factor={2} />
                        <HeaderThreeOnboard>{greeting}</HeaderThreeOnboard>

                        <Spacing factor={1 / 2} />
                        <BodyBigOnboard>
                            <b>{meetingTitleFromUrl}</b>
                        </BodyBigOnboard>
                        <Spacing factor={1 / 3} />
                        <BodyRegularOnboard>From Spinach AI</BodyRegularOnboard>
                        {hostNameFromUrl ? (
                            <>
                                <Spacing factor={1 / 3} />
                                <Box
                                    style={{
                                        backgroundColor: lightTheme.primary.greenLight,
                                        borderRadius: '6px',
                                        padding: '2px 12px',
                                    }}
                                >
                                    <BodyRegularOnboard style={{ color: 'white' }}>
                                        Shared by {hostNameFromUrl}
                                    </BodyRegularOnboard>
                                </Box>
                            </>
                        ) : null}

                        <Spacing factor={1.5} />

                        <LoginWithGoogle label="Access with Google" variant={'orange'} />

                        <Spacing factor={1 / 2} />

                        <LoginWithMicrosoft label="Access with Microsoft" variant={'orange'} />

                        <Spacing factor={1 / 2} />
                        <SecondaryButton
                            title="Access with Email only"
                            buttonStyles={{
                                alignItems: 'center',
                                display: 'flex',
                                textDecoration: 'none',
                            }}
                            onClick={async () => {
                                await track(ClientEventType.LoginPageClick, {
                                    ClickedOn: 'Access with Email only',
                                });

                                search.append(WebUrlQuery.Source, UTMSource.ContinueWithEmail);
                                search.delete(WebUrlQuery.ViralityExperiment);

                                setSearch(search);
                            }}
                        />
                        <Spacing factor={2} />
                        <img src={SpinachProofSrc} style={{ maxWidth: '80%', maxHeight: '60%' }} />
                    </Column>
                </Column>
                {width > 900 ? (
                    <Column style={{ backgroundColor: '#F4F6F6', flex: 1 }} centered={true} vCentered={true}>
                        <Row centered={true}>
                            <GreenCheckIcon style={{ width: '18px', height: '18px', marginRight: '16px' }} />
                            <BodyRegularOnboard>Access transcripts, videos, and action items</BodyRegularOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <img src={MeetingNotesPlaceholderSrc} style={{ maxWidth: '70%', maxHeight: '60%' }} />
                        <Spacing factor={1} />
                        <Row centered>
                            <img src={SecurityBadgeSrc} style={{ width: '250px' }} />
                        </Row>
                    </Column>
                ) : null}
            </Row>
        </ViewContainer>
    );
}
