import { atom } from 'recoil';

import { ClientUser } from '@spinach-shared/models';
import { AdminUserHistoryOption, AdminUserOption } from '@spinach-shared/types';

export type GlobalAdminState = {
    searchedUser: ClientUser | null;
    searchedHistory: AdminUserHistoryOption | null;

    userOptions: AdminUserOption[];
    userHistoryOptions: AdminUserHistoryOption[];

    isLoadingHistoryOptions: boolean;
    isLoadingUserOptions: boolean;
    isLoadingSelectedUser: boolean;
    isLoadingMeetingNotesRemoval: boolean;
    isLoadingMeetingNotesViewInitiation: boolean;
    isLoadingDeactivateUser: boolean;
};

export const atomAdminState = atom<GlobalAdminState>({
    key: 'adminState',
    default: {
        searchedUser: null,
        searchedHistory: null,

        userOptions: [],
        userHistoryOptions: [],

        isLoadingHistoryOptions: false,
        isLoadingSelectedUser: false,
        isLoadingUserOptions: false,
        isLoadingMeetingNotesRemoval: false,
        isLoadingMeetingNotesViewInitiation: false,
        isLoadingDeactivateUser: false,
    },
});
