import styled, { css } from 'styled-components';

import { isWebPlatform } from '../../utils/platform';

export const Hairline = styled.div<{ factor?: number }>`
    display: flex;
    width: 100%;
    height: 0px;
    margin-top: ${(props) => (Number(props.factor) > 0 ? Number(props.factor) * 15 : 15)}px;
    margin-bottom: ${(props) => (Number(props.factor) > 0 ? Number(props.factor) * 15 : 15)}px;
    border: 0.75px solid ${(props) => props.theme.neutrals.gray};
    flex-direction: column;
    align-items: flex-start;
`;

export const Separator = styled(Hairline)`
    margin-top: unset;
    margin-bottom: unset;
    width: 80%;
    margin-left: 10%;
`;

export const Spacer = styled.div`
    width: 100%;
    height: 30px;
    flex-shrink: 0;
`;

export const Circle = styled.button`
    border-radius: 100px;
    height: 40px;
    width: 40px;
    background-color: ${(props) => props.theme.primary.greenDark};
    color: white;
    font-weight: 700;
    z-index: 2000;
    cursor: pointer;
    border: none;
    font-size: 18px;
`;

export const CircleBullet = styled.span`
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    height: 40px;
    width: 40px;
    background-color: ${(props) => props.theme.primary.greenLight};
    color: white;
    font-weight: 700;
    z-index: 2000;
    border: none;
    font-size: 18px;
    margin-right: 10px;
    user-select: none;
    line-height: 1.1;
`;

export const STATIONARY_RADIUS = '32px';

export const SpinachContainerCSS = css``;

const WebContainerStyles = css`
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const SpinachContainer = styled.div<{ isOnDemo: boolean }>`
    min-width: 300px;
    max-width: 650px;
    background-color: ${(props) => (props.isOnDemo ? '#333333' : props.theme.neutrals.white)};
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.41) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 8px;

    border: ${(props) => (props.isOnDemo ? '15px solid rgb(51, 51, 51)' : 'initial')};
    border-top: ${(props) => (props.isOnDemo ? '40px solid rgb(51, 51, 51)' : 'initial')};

    ${isWebPlatform() ? WebContainerStyles : undefined}
`;

export const SpinachPadding = styled.div`
    padding: 10px 20px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-color: ${(props) => props.theme.neutrals.offWhite};
    flex-direction: column;
    align-items: center;
    display: flex;
`;

type SpacingProps = {
    factor?: number;
    horizontal?: boolean;
};

export const Spacing = styled.div<SpacingProps>`
    width: ${(props) => (props.horizontal ? `${Number(props.factor) > 0 ? Number(props.factor) * 30 : 30}px` : '100%')};
    flex-shrink: 0;
    height: ${(props) =>
        props.horizontal ? '100%' : `${Number(props.factor) > 0 ? Number(props.factor) * 30 : 30}px`};
`;

export const BorderedSpacing = styled(Spacing)`
    margin-top: ${(props) => (Number(props.factor) > 0 ? Number(props.factor) * 30 : 30)}px;
    border-top: 1px solid ${(props) => props.theme.neutrals.gray};
`;

export const Column = styled.div<{ centered?: boolean; vCentered?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    align-items: ${(props) => (props.centered ? 'center' : 'inherit')};
    justify-content: ${(props) => (props.vCentered ? 'center' : 'inherit')};
`;

export const Row = styled.div<{ centered?: boolean; vCenter?: boolean }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-shrink: 0;
    justify-content: ${(props) => (props.centered ? 'center' : 'initial')};
    align-items: ${(props) => (props.vCenter ? 'center' : 'initial')};
`;

export const Anchor = styled.a`
    color: ${(props) => props.theme.primary.green};
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
`;

export const Picker = styled.div<{ offset?: number }>`
    position: absolute;
    z-index: 5000;
    right: ${(props) => props.offset || 0 + 12}px;
    height: 120px;
    top: -24px;
    flex-direction: row;

    padding: 6px;
    border: 1px solid #b8b8c5;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    font-weight: bold;
    font-size: 16px;
    display: inline-flex;
    justify-content: space-between;
    align-content: flex-start;
`;
