import { Tooltip, TooltipProps, styled } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import avatarToolTipBg from '../../assets/avatar-slide-bg.png';

export const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ tooltipStyle, arrowStyle }: { tooltipStyle?: CSSProperties; arrowStyle?: CSSProperties }) => ({
    [`& .MuiTooltip-tooltip`]: {
        // TODO: Codify this or use existing pallete
        backgroundColor: '#424242',
        ...tooltipStyle,
    },
    [`& .MuiTooltip-arrow::before`]: {
        color: '#424242',
        backgroundColor: '#424242',
        ...arrowStyle,
    },
}));

export const VideoToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
    [`& .MuiTooltip-tooltip`]: {
        maxWidth: '420px',
        backgroundColor: 'black',
    },
    [`& .MuiTooltip-arrow::before`]: {
        color: 'black',
        backgroundColor: 'black',
    },
}));

export const AvatarToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
    [`& .MuiTooltip-tooltip`]: {
        maxWidth: '420px',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${avatarToolTipBg})`,
        borderRadius: '12px',
        padding: '10px',
    },
    [`& .MuiTooltip-arrow::before`]: {
        color: 'black',
    },
}));
