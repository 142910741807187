import { isLocalStage } from './stage';

/**
 *
 * @param localEnvVars the minimum set of env vars required to run the artifact locally
 * @param additionalEnvVars the additional set of env vars, on top of the local ones,
 * that may be required in a deployed environment (test, staging, production)
 */
export function validateEnv(localEnvVars: string[], additionalEnvVars: string[] = []) {
    const vars = isLocalStage() ? localEnvVars : [...localEnvVars, ...additionalEnvVars];

    vars.forEach((e) => {
        if (process.env[e] === undefined) {
            throw new Error(`Missing env var ${e}. Please populate in respective .env file.`);
        }
    });
}
