import { Modal, TextField } from '@material-ui/core';
import { useState } from 'react';

import { ClientEventType, SpinachAPIPath } from '@spinach-shared/types';

import { Column, Row, Spacing, SpinachModalContent } from '..';
import { ErrorBodySubtitle, useExperienceTracking } from '../../..';
import { postSpinachAPI } from '../../../apis';
import { GlobalModal } from '../../../atoms/atomModal';
import { useGlobalModal } from '../../../hooks/useGlobalModal';
import { BodyRegular, BodyRegularOnboard, lightTheme } from '../../../styles';
import { HeaderThree } from '../../../styles';
import { PrimaryButton } from '../../stand-up';
import { OutlinedButton } from '../../stand-up/OutlinedButton';
import axios from 'axios';

export function AddTeamMemberModal(): JSX.Element {
    const [globalModal, setGlobalModal] = useGlobalModal();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);
    const track = useExperienceTracking();

    const isValidEmail = (email: string): boolean => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email.trim());
    };

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Add Team Member Modal',
        });
        setGlobalModal(null);
        setEmail('');
        setError(null);
        setSuccess(false);
    };

    const handleInvite = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await postSpinachAPI(
                SpinachAPIPath.InviteTeamMember,
                { email: email.trim() },
                { throwOnError: true }
            );

            if (!response) {
                throw new Error('Failed to send invitation');
            }

            track(ClientEventType.AIDashboardClick, {
                ClickedOn: 'Invite Team Member',
                Email: email,
            });

            setSuccess(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response?.data?.message) {
                setError(err.response.data.message);
            } else {
                setError('Failed to send invitation. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal open={globalModal === GlobalModal.AddTeamMember} onClose={closeModal}>
            <SpinachModalContent
                onClose={closeModal}
                style={{
                    width: '90%',
                    maxWidth: '480px',
                    minWidth: '320px',
                    minHeight: 'unset',
                    overflow: 'visible',
                }}
            >
                <Column style={{ width: '100%' }}>
                    <HeaderThree>Add Team Member</HeaderThree>
                    <Spacing factor={1} />
                    <BodyRegular>Enter your teammate's email address to invite them</BodyRegular>
                    <Spacing factor={1} />
                    <TextField
                        autoFocus
                        fullWidth
                        placeholder="kale@acme.co"
                        error={!!error}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        disabled={isLoading || success}
                    />
                    {error ? <ErrorBodySubtitle>{error}</ErrorBodySubtitle> : null}
                    {success ? (
                        <>
                            <Spacing factor={1} />
                            <BodyRegularOnboard style={{ color: lightTheme.status.positive }}>
                                Invitation sent successfully!
                            </BodyRegularOnboard>
                        </>
                    ) : null}
                    <Spacing factor={2} />
                    <Row
                        style={{
                            justifyContent: 'flex-end',
                            gap: '12px',
                            width: '100%',
                            marginTop: '8px',
                        }}
                    >
                        {success ? (
                            <PrimaryButton
                                title="Close"
                                onClick={closeModal}
                                style={{ minWidth: '80px' }}
                            />
                        ) : (
                            <>
                                <OutlinedButton title="Cancel" onClick={closeModal} disabled={isLoading} />
                                <PrimaryButton
                                    title="Invite"
                                    onClick={handleInvite}
                                    disabled={!isValidEmail(email) || success || isLoading}
                                    isLoading={isLoading}
                                    style={{ minWidth: '80px' }}
                                />
                            </>
                        )}
                    </Row>
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}
