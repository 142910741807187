import { useEffect } from 'react';

import { BaseMeetingProps, SpinachSeriesProps } from '@spinach-shared/models';

import { fetchSeriesHistories } from '../utils/fetchSeriesHistories';
import { useGlobalSummaryHistories } from './useGlobalSummaryHistories';

export function useSeriesHistories(series: SpinachSeriesProps | null): [BaseMeetingProps[] | null] {
    const [seriesHistories, setSeriesHistories] = useGlobalSummaryHistories();

    useEffect(() => {
        const fetchHistory = async (seriesId: string) => {
            const history = await fetchSeriesHistories(seriesId);
            let mappedHistories: BaseMeetingProps[] = [];

            if (history) {
                mappedHistories = history.map(
                    (h) =>
                        new BaseMeetingProps({
                            ...h.meeting,
                            agenda: { ...h.meeting.agenda, startedAt: h.meeting.startedAt },
                        })
                );
            }

            setSeriesHistories(mappedHistories);
        };
        if (series?.slug) {
            fetchHistory(series.slug);
        }
    }, [series?.slug, series?.currentMeeting.status]);

    return [seriesHistories];
}
