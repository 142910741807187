import { atom } from 'recoil';

import { TicketProject, TicketSource } from '@spinach-shared/types';

export type GlobalTicketProjectState = Partial<Record<TicketSource, TicketProject[] | null>>;

export const globalTicketProjects = atom<GlobalTicketProjectState | null>({
    key: 'ticketProjectsAtom',
    default: null,
});
