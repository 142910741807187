import styled from 'styled-components';

export const Input = styled.input`
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: none;
    border-bottom: 1px solid ${(props) => props.theme.secondary.midnight};
    font-size: 15px;
    color: ${(props) => props.theme.secondary.midnight};
    font-family: 'Gilroy';
    padding-top: 10px;
    padding-left: 0px;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    min-width: 200px;
    max-width: 300px;
`;
