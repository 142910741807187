import { ClientEventType } from '@spinach-shared/types';

import ZoomIconSrc from '../../../assets/zoom-icon.png';
import { useExperienceTracking } from '../../../hooks';
import { URLUtil, isZoomPlatform } from '../../../utils';
import { AppIntegrationRow, IntegrationButtonText } from './AppIntegrationRow';

export function ZoomIntegrationRow(): JSX.Element {
    const track = useExperienceTracking();
    return (
        <AppIntegrationRow
            onCTAClick={async () => {
                track(ClientEventType.ZoomAppMarketplaceIntegrationsClick);
                URLUtil.openURL('https://marketplace.zoom.us/apps/414jwrO_SsOksk6SAsMXgg', {
                    redirectThroughBackendWhenOnZoom: true,
                });
            }}
            subtext={'View Spinach directly in Zoom'}
            label={'Zoom'}
            icon={<img src={ZoomIconSrc} style={{ objectFit: 'contain', height: '25px', width: '25px' }} />}
            buttonText={IntegrationButtonText.Add}
            isConnected={isZoomPlatform()}
        />
    );
}
