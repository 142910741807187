import { SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from './postSpinachAPI';

export async function uploadBrandImage(file: Blob): Promise<string | null> {
    try {
        const formData = new FormData();

        formData.append('file', file);
        const response = await postSpinachAPI<{ message: string; presignedUrl: string }>(
            SpinachAPIPath.UploadBrandImage,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            }
        );

        return response?.presignedUrl ?? null;
    } catch {
        return null;
    }
}
