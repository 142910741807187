import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import styled from 'styled-components';

import {
    ClientEventType,
    ClientSocketEvent,
    VideoAgentControlAction,
    VideoAgentControlCommand,
    VideoAgentSessionTopic,
} from '@spinach-shared/types';
import { isLocalStage } from '@spinach-shared/utils';

import { useActivityTracking, useGlobalAuthedUser, useGlobalVideoAgent } from '../../hooks';
import { AgentCommandType } from '../../types/agent';

const ControlContainer = styled.div`
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
`;

const TopicNavigation = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

const NavButton = styled.button`
    background-color: #f0f0f0;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 4px;
    transition: all 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
        transform: translateY(-2px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:active {
        transform: translateY(0);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }
`;

const StartMeetingButton = styled(NavButton)`
    background-color: #4caf50;
    color: white;
    font-weight: bold;

    &:hover {
        background-color: #45a049;
    }
`;

const ChangeMeetingButton = styled(NavButton)`
    background-color: #ffeb3b;
    color: black;
`;

const TopicList = styled(List)`
    margin: 20px 0;
    background-color: #f0f0f0;
    border-radius: 4px;
`;

const StyledListItem = styled(ListItem)<{ isDragging: boolean; isSelected: boolean }>`
    background-color: ${(props) => (props.isSelected ? '#808080' : props.isDragging ? '#e0e0e0' : 'transparent')};
    color: ${(props) => (props.isSelected ? 'white' : 'black')};
    margin: 5px 0;
    border-radius: 4px;
    position: relative;
    padding-left: 3px;
`;

const AddTopicButton = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;
    transition: all 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
        transform: translateY(-2px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:active {
        transform: translateY(0);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const ControlPanelStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

const ControlRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const ToggleButton = styled.button<{ isActive: boolean }>`
    background-color: ${(props) => (props.isActive ? '#4CAF50' : '#f44336')};
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    flex: 1;
    margin: 0 5px;
    border-radius: 4px;
    transition: all 0.3s ease;

    &:hover {
        filter: brightness(110%);
        transform: translateY(-2px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:active {
        transform: translateY(0);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }
`;

const StyledListItemText = styled(ListItemText)<{ isSelected: boolean }>`
    .MuiListItemText-primary {
        font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
    }
`;

const DragHandleStyled = styled.div`
    color: #757575;
    cursor: grab;
    display: flex;
    align-items: center;
    margin-right: 8px;

    &:active {
        cursor: grabbing;
    }
`;

const TopicIndicator = styled.div<{ isActive: boolean }>`
    width: 3px;
    height: 100%;
    background-color: ${(props) => (props.isActive ? '#4CAF50' : 'transparent')};
    position: absolute;
    left: 0;
    top: 0;
`;

const CategoryLabel = styled.div`
    font-size: 14px;
    color: #666;
    margin: 15px 0 8px 0;
    font-weight: 500;
`;

const AudioControlRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 10px;
`;

const VideoControlRow = styled(ControlRow)`
    display: flex;
    gap: 10px;
`;

interface MeetingOption {
    botId: string;
    videoToken: string;
    seriesId: string;
    meetingTitle: string;
}

export function ControlPanel({
    botId,
    videoToken,
    seriesId,
    socket,
    availableMeetings,
}: {
    botId: string;
    videoToken: string;
    seriesId: string;
    socket: Socket;
    availableMeetings: MeetingOption[];
}) {
    const { session } = useGlobalVideoAgent();
    const [user] = useGlobalAuthedUser();

    const trackActivity = useActivityTracking();

    const [newTopicName, setNewTopicName] = useState<string>('');
    const [isAddingTopic, setIsAddingTopic] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const [videoUrl, setVideoUrl] = useState('');

    const sendCommand = (command: VideoAgentControlCommand) => {
        if (isLocalStage()) {
            console.log('Sending command via postMessage:', command);
            if (window.opener) {
                window.opener.postMessage({ type: 'local.VideoAgentControlCommand', command }, '*');
            } else {
                console.error('No parent window found. Make sure the control page is opened as a popup.');
            }
        } else if (socket && botId) {
            socket.emit(ClientSocketEvent.VideoAgentControlling, { botId, ...command });
        }
    };

    useEffect(() => {
        if (isAddingTopic && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isAddingTopic]);

    const handleAddTopic = () => {
        if (newTopicName.trim()) {
            trackActivity(ClientEventType.VideoAgentActivity, 'Add Topic', {
                TriggeredBy: AgentCommandType.RemoteControl,
                ...session?.analyticsPayload,
            });
            sendCommand({ action: VideoAgentControlAction.AddTopic, topicName: newTopicName.trim() });
            setNewTopicName('');
            setIsAddingTopic(false);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleAddTopic();
        }
    };

    const handleEditTopic = (topicId: string, newName: string) => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Edit Topic', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.EditTopic, topicId, topicName: newName });
    };

    const handleDeleteTopic = (topicId: string) => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Delete Topic', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.DeleteTopic, topicId });
    };

    const handleToggleRecording = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Toggle Recording', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.ToggleRecording });
    };

    const handleToggleRoundtable = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Toggle Roundtable', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.ToggleRoundtable });
    };

    const handleStopAnyActiveAudio = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Stop Any Active Audio', {
            TriggeredBy: AgentCommandType.RemoteControl,
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.Stop });
    };

    const handleToggleAudioAcknowledgementDisabled = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Toggle Audio Acknowledgement Disabled', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.ToggleAudioAcknowledgementDisabled });
    };

    const handleToggleAudioTimeCheckDisabled = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Toggle Audio Time Check Disabled', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.ToggleAudioTimeCheckDisabled });
    };

    const reorder = (list: VideoAgentSessionTopic[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination || !session?.topics) {
            return;
        }

        const items = reorder(session.topics, result.source.index, result.destination.index);

        trackActivity(ClientEventType.VideoAgentActivity, 'Reorder Topics', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });

        sendCommand({
            action: VideoAgentControlAction.ReorderTopics,
            topics: items.map((topic) => topic.id),
        });
    };

    const handleAddTopicClick = () => {
        setIsAddingTopic(true);
    };

    const handleStartMeeting = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Start Agenda', {
            TriggeredBy: AgentCommandType.RemoteControl,
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.Next });
    };
    const navigate = useNavigate();

    const handlePlayVideo = () => {
        sendCommand({ action: VideoAgentControlAction.PlayVideo, videoUrl });
    };

    const handleMeetingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newBotId = event.target.value as string;
        navigate(`/control/${newBotId}`);
        trackActivity(ClientEventType.VideoAgentActivity, 'Change Meeting', {
            TriggeredBy: AgentCommandType.RemoteControl,
            ...session?.analyticsPayload,
        });
    };

    const handleTopicClick = (index: number) => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Jump To Topic', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.JumpToTopic, topicIndex: index });
    };

    return (
        <ControlContainer>
            <Select
                value={botId}
                onChange={handleMeetingChange}
                fullWidth
                variant="outlined"
                style={{ marginBottom: '20px' }}
            >
                {availableMeetings.map((meeting) => (
                    <MenuItem key={meeting.botId} value={meeting.botId}>
                        {meeting.meetingTitle}
                    </MenuItem>
                ))}
            </Select>
            <TopicNavigation>
                {session.isLobbyPhase ? (
                    <StartMeetingButton onClick={handleStartMeeting}>Start Meeting</StartMeetingButton>
                ) : (
                    <>
                        <NavButton onClick={() => sendCommand({ action: VideoAgentControlAction.Previous })}>
                            Prev Topic
                        </NavButton>
                        <NavButton onClick={() => sendCommand({ action: VideoAgentControlAction.Next })}>
                            Next Topic
                        </NavButton>
                    </>
                )}
            </TopicNavigation>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="topicList">
                    {(provided, snapshot) => (
                        <TopicList ref={provided.innerRef} {...provided.droppableProps}>
                            <StyledListItem
                                isDragging={false}
                                isSelected={session.isLobbyPhase ?? false}
                                onClick={() => handleTopicClick(-1)}
                                button
                            >
                                <TopicIndicator isActive={session.isLobbyPhase ?? false} />
                                <DragHandleStyled style={{ visibility: 'hidden' }}>
                                    <DragIndicatorIcon />
                                </DragHandleStyled>
                                <StyledListItemText primary="Lobby" isSelected={session.isLobbyPhase ?? false} />
                            </StyledListItem>
                            {session.topics.map((topic: VideoAgentSessionTopic, index: number) => (
                                <Draggable key={topic.id} draggableId={topic.id} index={index}>
                                    {(provided, snapshot) => (
                                        <StyledListItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            isDragging={snapshot.isDragging}
                                            isSelected={index === session.currentTopicIndex}
                                            button
                                            onClick={() => handleTopicClick(index)}
                                        >
                                            <TopicIndicator isActive={index === session.currentTopicIndex} />
                                            <DragHandleStyled {...provided.dragHandleProps}>
                                                <DragIndicatorIcon />
                                            </DragHandleStyled>
                                            <StyledListItemText
                                                primary={topic.title}
                                                isSelected={index === session.currentTopicIndex}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="edit"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEditTopic(
                                                            topic.id,
                                                            prompt('Enter new topic name', topic.title) || topic.title
                                                        );
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteTopic(topic.id);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </StyledListItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </TopicList>
                    )}
                </Droppable>
            </DragDropContext>

            {isAddingTopic ? (
                <div>
                    <Input
                        ref={inputRef}
                        type="text"
                        value={newTopicName}
                        onChange={(e) => setNewTopicName(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Enter topic name"
                    />
                    <Button onClick={handleAddTopic}>Add Topic</Button>
                </div>
            ) : (
                <AddTopicButton onClick={handleAddTopicClick}>+ Add a Topic</AddTopicButton>
            )}

            <ControlPanelStyled>
                <ControlRow>
                    <ToggleButton isActive={!(session.isPaused ?? false)} onClick={handleToggleRecording}>
                        Recording {session.isPaused ? 'Off' : 'On'}
                    </ToggleButton>
                    <ToggleButton isActive={session.isRoundtableEnabled ?? false} onClick={handleToggleRoundtable}>
                        Roundtable {session.isRoundtableEnabled ? 'On' : 'Off'}
                    </ToggleButton>
                </ControlRow>

                <ControlRow>
                    <ToggleButton
                        isActive={false}
                        onClick={handleStopAnyActiveAudio}
                    >
                        Stop any active audio
                    </ToggleButton>
                </ControlRow>

                <CategoryLabel>Audio Settings</CategoryLabel>
                <AudioControlRow>
                    <ToggleButton
                        isActive={!(session.isAudioAcknowledgementDisabled ?? false)}
                        onClick={handleToggleAudioAcknowledgementDisabled}
                    >
                        Acknowledgement {session.isAudioAcknowledgementDisabled ? 'Off' : 'On'}
                    </ToggleButton>
                    <ToggleButton
                        isActive={!(session.isAudioTimeCheckDisabled ?? false)}
                        onClick={handleToggleAudioTimeCheckDisabled}
                    >
                        Time Check {session.isAudioTimeCheckDisabled ? 'Off' : 'On'}
                    </ToggleButton>
                </AudioControlRow>

                {user.isEnabledForVideoPlayerInRemoteControl ? (
                    <>
                        <CategoryLabel>Video Player</CategoryLabel>
                        <VideoControlRow>
                            <Input
                                type="text"
                                placeholder="Enter video URL"
                                value={videoUrl}
                                onChange={(e) => setVideoUrl(e.target.value)}
                            />
                            <ToggleButton
                                isActive={true}
                                onClick={() => {
                                    handlePlayVideo();
                                }}
                            >
                                Play
                            </ToggleButton>
                        </VideoControlRow>
                    </>
                ) : null}
            </ControlPanelStyled>
        </ControlContainer>
    );
}
