import { Backdrop, Paper, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';

import BrokenStandupPersonasTall from '../../assets/demo/persona-breakdown-tall.png';
import BrokenStandupPersonasWide from '../../assets/demo/persona-breakdown-wide.png';
import SlackAlertView from '../../assets/demo/slack-alert.png';
import { DemoModal, DemoPrefaceView } from '../../atoms';
import { useExperienceTracking, useGlobalDemoState, usePersonaDemo, useWindowSize } from '../../hooks';
import { BodyBigOnboard, BodyRegular, HeaderThreeOnboard } from '../../styles';
import { Column, LoadingSquares, Row, Spacing } from '../common';
import { ScrollView } from '../series';
import { PrimaryButton, ScrollArea } from '../stand-up';
import { DemoModalContainer } from './DemoModals';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdropLight: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: 'rgba(255,255,255,0.5)',
        },
    })
);

const DemoRecipientBadge = styled(Paper)`
    padding: 8px;
    border-radius: 8px !important;
    margin-bottom: 20px;
`;

function DemoPrefaceViews(): JSX.Element {
    const { setDemoState, demoState } = useGlobalDemoState();
    const isPersonaDemo = usePersonaDemo();
    const demoOverlayStyles = useStyles();
    const track = useExperienceTracking();
    const { width } = useWindowSize();
    const [params] = useSearchParams();
    const demoRecipient = params.get(WebUrlQuery.DemoSandboxRecipient);
    const [hasFiredJoining, setHasFiredJoining] = useState(false);

    useEffect(() => {
        if (isPersonaDemo && !hasFiredJoining && demoState.demoPrefaceView === DemoPrefaceView.SlackAlert) {
            // this is also counting other hiding timer from modal on top of slack initially
            const TRANSITION_FROM_SLACK_AFTER_4_SECONDS = 2 * 4000;
            setTimeout(() => {
                setDemoState({
                    ...demoState,
                    demoPrefaceView: null,
                    demoModal: DemoModal.JoiningStandup,
                });
                setHasFiredJoining(true);
            }, TRANSITION_FROM_SLACK_AFTER_4_SECONDS);
        }
    }, [isPersonaDemo, demoState.demoPrefaceView]);

    if (isPersonaDemo && demoState.demoPrefaceView === DemoPrefaceView.PersonaIntro) {
        return (
            <Backdrop className={demoOverlayStyles.backdropLight} open={true}>
                <Column>
                    {demoRecipient ? (
                        <DemoRecipientBadge elevation={4}>
                            <BodyRegular>
                                <b>{demoRecipient}'s sandbox</b>
                            </BodyRegular>
                        </DemoRecipientBadge>
                    ) : null}
                    <HeaderThreeOnboard>Standups are broken</HeaderThreeOnboard>
                    <Spacing factor={1 / 2} />
                    <BodyBigOnboard
                        style={{ textAlign: 'center', maxWidth: '650px', paddingLeft: '20px', paddingRight: '20px' }}
                    >
                        <b>They're inefficient, disengaging, and lengthy</b>
                    </BodyBigOnboard>
                    <Spacing />
                    <ScrollArea style={{ background: 'transparent', alignItems: 'center' }}>
                        {width > 800 ? (
                            <img src={BrokenStandupPersonasWide} style={{ width: '80%', maxWidth: '1000px' }} />
                        ) : (
                            <ScrollView style={{ maxHeight: '50vh', background: 'transparent' }}>
                                <img
                                    src={BrokenStandupPersonasTall}
                                    style={{ maxWidth: '400px', minWidth: '100px', padding: '20px' }}
                                />
                            </ScrollView>
                        )}
                    </ScrollArea>
                    <Spacing />
                    <PrimaryButton
                        title="Discover a better way"
                        onClick={() => {
                            setDemoState({
                                ...demoState,
                                demoModal: DemoModal.TimeForStandup,
                                demoPrefaceView: DemoPrefaceView.SlackAlert,
                            });
                        }}
                    />
                </Column>
            </Backdrop>
        );
    }

    if (isPersonaDemo && demoState.demoPrefaceView === DemoPrefaceView.SlackAlert) {
        return (
            <Row style={{ height: '95vh', width: '100vw', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    src={SlackAlertView}
                    style={{ maxHeight: '70%', maxWidth: '90%', zIndex: 1000 }}
                    onClick={() => {
                        track(ClientEventType.UserClickedDemoSlackView);
                    }}
                />
                {!demoState.demoModal ? (
                    <LoadingSquares style={{ position: 'absolute', top: '6%', zIndex: 1000 }} />
                ) : null}
                <DemoModalContainer />
            </Row>
        );
    }

    return <></>;
}

export function DemoPrefaceViewsContainer(): JSX.Element {
    return (
        <>
            <DemoPrefaceViews />
            <DemoModalContainer />
        </>
    );
}
