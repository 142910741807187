import { ClientEventType } from '@spinach-shared/types';

import { useFacilitationTracking, useGlobalFacilitation, useGlobalLastAiSummary } from '../../../hooks';
import { BodyBig, BodyLarge, BodyRegular } from '../../../styles';
import { withContentMasking } from '../../../utils';
import { Column, Row, Spacing } from '../../common';
import { GenericSpinachInsight, useSpinachBlockerOnClick } from './SpinachInsight';
import { ForwardBackwardButtons } from './TimedHeader';
import { Header, Padding, ScrollView } from './common';

export function BriefingContent(): JSX.Element {
    const [summaryJson] = useGlobalLastAiSummary();
    const [facilitation] = useGlobalFacilitation();
    const getOnClick = useSpinachBlockerOnClick();
    const track = useFacilitationTracking();

    const itemsWithPreviousPlans = (summaryJson?.plansAndProgress ?? [])?.filter((pp) => pp.plans);

    return (
        <>
            <Row
                style={{
                    marginLeft: '20px',
                    marginTop: '20px',
                    marginRight: '20px',
                    justifyContent: 'space-between',
                    width: 'calc(100% - 40px)',
                }}
            >
                <Header>Meeting Prep</Header>
                <ForwardBackwardButtons hideBackward />
            </Row>
            <Row>
                <BodyBig style={{ marginLeft: '20px', marginTop: '-5px' }}>
                    <b>{facilitation.meetingTitle}</b>
                </BodyBig>
            </Row>

            <ScrollView sidePadding={0} stretch style={{ height: 'calc(100% - 50px)' }}>
                <Padding>
                    <BodyLarge>Previous Action Items</BodyLarge>
                    <Spacing factor={1 / 5} />
                    {summaryJson?.actionItems?.map((actionItem, i) => (
                        <GenericSpinachInsight title={actionItem.title} context={actionItem.context} key={i} />
                    ))}
                    {!summaryJson?.actionItems?.length ? <BodyRegular>No previous action items</BodyRegular> : null}

                    <Spacing />

                    <BodyLarge>Previous Blockers</BodyLarge>
                    <Spacing factor={1 / 5} />
                    {summaryJson?.blockers?.map((blocker, i) => (
                        <GenericSpinachInsight
                            key={i}
                            title={blocker.content}
                            context={blocker.context}
                            onClick={() => {
                                track(ClientEventType.AIFacilitationClick, {
                                    ClickedOn: 'Add Blocker to Topics',
                                    Location: 'Meeting Prep',
                                });
                                return getOnClick(blocker.content, blocker.context);
                            }}
                        />
                    ))}
                    {!summaryJson?.blockers?.length ? <BodyRegular>No previous blockers</BodyRegular> : null}

                    <Spacing />

                    <Column
                        style={{
                            backgroundColor: 'rgba(249, 249, 249, 1)',
                            padding: '20px',
                            width: 'calc(100% - 40px)',
                            borderRadius: '4px',
                        }}
                    >
                        <Spacing factor={1 / 5} />
                        <BodyLarge>Previous Updates</BodyLarge>
                        <Spacing factor={1 / 3} />
                        {itemsWithPreviousPlans.map((pp, i) => (
                            <Column key={i} style={{}}>
                                <BodyRegular {...withContentMasking()}>
                                    <b>{pp.userName}:</b>
                                </BodyRegular>
                                <BodyRegular>
                                    <ul
                                        style={{
                                            margin: 'unset',
                                            marginBottom: '15px',
                                            marginTop: '5px',
                                            marginLeft: '-5px',
                                        }}
                                    >
                                        <li {...withContentMasking()}>
                                            <b>Plans: </b>
                                            {pp.plans}
                                        </li>
                                    </ul>
                                </BodyRegular>
                            </Column>
                        ))}
                        {itemsWithPreviousPlans.length === 0 && <BodyRegular>No previous plans</BodyRegular>}
                    </Column>

                    <Spacing />
                </Padding>
            </ScrollView>
        </>
    );
}
