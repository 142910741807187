import { Warning } from '@material-ui/icons';
import styled from 'styled-components';

import { BodyRegularOnboard, lightTheme } from '../../../styles';
import { Column, Row, Spacing } from '../framing';

const BodyHeader = styled(BodyRegularOnboard)`
    font-weight: bold;
    padding-bottom: 3px;
`;

const ListItemRow = styled(Row)`
    padding-bottom: 5px;
`;

const ListItemNumeral = styled(Column)`
    padding-left: 3px;
    padding-right: 5px;
    width: fit-content;
`;

export function NotionIntegrationUpgradeMessage() {
    return (
        <Column>
            <BodyHeader style={{ display: 'flex', alignItems: 'center' }}>
                <Warning fontSize="small" style={{ marginRight: '5px' }} htmlColor={lightTheme.primary.orangeLight} />{' '}
                Improved Notion Integration Available!
            </BodyHeader>
            <BodyRegularOnboard>
                If you do not update, summaries will continue to be sent to it’s current location. While you can still
                use the old service, we won't be providing future support.
            </BodyRegularOnboard>
            <Spacing factor={1 / 3} />
            <BodyHeader>What’s changing?</BodyHeader>
            <BodyRegularOnboard>
                We've enhanced our Notion integration to provide an easier setup experience allowing summaries to be
                sent to a top level private page. To ensure you don't miss upcoming enhancements and support, consider
                updating your Notion integration.
            </BodyRegularOnboard>
            <Spacing factor={1 / 3} />
            <BodyHeader>How to update?</BodyHeader>
            <ListItemRow>
                <ListItemNumeral style={{ paddingLeft: '3px', paddingRight: '5px', width: 'fit-content' }}>
                    <BodyRegularOnboard>1.</BodyRegularOnboard>
                </ListItemNumeral>
                <Column>
                    <BodyRegularOnboard>
                        Remove Notion connection using the button below. You will not lose your existing summaries.
                    </BodyRegularOnboard>
                </Column>
            </ListItemRow>
            <ListItemRow>
                <ListItemNumeral>
                    <BodyRegularOnboard>2.</BodyRegularOnboard>
                </ListItemNumeral>
                <Column>
                    <BodyRegularOnboard>
                        Reconnect Notion and follow the instructions to allow permissions
                    </BodyRegularOnboard>
                </Column>
            </ListItemRow>
            <ListItemRow>
                <ListItemNumeral>
                    <BodyRegularOnboard>3.</BodyRegularOnboard>
                </ListItemNumeral>
                <Column>
                    <BodyRegularOnboard>
                        Once connected, you can move your Private Spinach page to Public and Spinach will continue to
                        write to the new page location.
                    </BodyRegularOnboard>
                </Column>
            </ListItemRow>
        </Column>
    );
}
