export const MILLIS_IN_SECONDS = 1000;
export const SECONDS_IN_MINUTES = 60;
export const MINUTES_IN_HOURS = 60;
export const HOURS_IN_DAYS = 24;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_YEAR = 365;

export const MILLIS_IN_HOUR = MILLIS_IN_SECONDS * SECONDS_IN_MINUTES * MINUTES_IN_HOURS;
export const SECONDS_IN_HOUR = SECONDS_IN_MINUTES * MINUTES_IN_HOURS;
export const MILLIS_IN_DAY = MILLIS_IN_HOUR * HOURS_IN_DAYS;
export const THIRTY_SIX_HOURS_IN_SECONDS = SECONDS_IN_MINUTES * MINUTES_IN_HOURS * 36;
export const TWO_WEEKS_IN_SECONDS = SECONDS_IN_MINUTES * MINUTES_IN_HOURS * HOURS_IN_DAYS * DAYS_IN_WEEK * 2;
export const SECONDS_IN_YEAR = SECONDS_IN_MINUTES * MINUTES_IN_HOURS * HOURS_IN_DAYS * DAYS_IN_YEAR;
export const MILLIS_IN_YEAR = MILLIS_IN_SECONDS * SECONDS_IN_YEAR;
export const ONE_DAY_IN_SECONDS = SECONDS_IN_MINUTES * MINUTES_IN_HOURS * HOURS_IN_DAYS;
export const MS_IN_HOUR = SECONDS_IN_MINUTES * MINUTES_IN_HOURS * MILLIS_IN_SECONDS;

// Remind users to fill out their update 15 minutes before async ends
export const REMINDER_PRIOR_TO_STANDUP_IN_MILLIS = MILLIS_IN_SECONDS * SECONDS_IN_MINUTES * 15;

export const FINISH_MEETING_DELAY_IN_MILLIS = MILLIS_IN_SECONDS * 30;

export const SAFE_DURATION_FOR_LIVE_MEETING_IN_HOURS = 2;
export const FIVE_DAYS_IN_HOURS = 5 * HOURS_IN_DAYS;
export const HOURS_IN_SECONDS = SECONDS_IN_MINUTES * MINUTES_IN_HOURS;
