import { MeetingHistoryJSON } from '@spinach-shared/types';

import { ClientLogger } from '..';
import { getSeriesHistories } from '../apis/getSeriesHistories';

export async function fetchSeriesHistories(seriesId: string): Promise<MeetingHistoryJSON[] | undefined> {
    const historyJSON = await getSeriesHistories(seriesId);

    if (!historyJSON) {
        ClientLogger.info('No series history received for series', { seriesId });
        return undefined;
    }

    ClientLogger.info('Received series history', {
        seriesId,
    });

    return historyJSON;
}
