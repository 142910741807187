import { CSSProperties, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ClientEventType, TooltipFeatureDiscoveryKey } from '@spinach-shared/types';
import { getUniques } from '@spinach-shared/utils';

import { UseCustomTooltipCalloutResults } from '.';
import { Column, Spacing } from '..';
import { patchUser } from '../../../apis';
import { useExperienceTracking, useGlobalAuthedUser, useLandingAnalytic } from '../../../hooks';
import { BodyRegular } from '../../../styles';
import { FeatureTooltipCallout } from './FeatureTooltipCallout';

let scrollTimeout: ReturnType<typeof window.setTimeout> | null = null;

const CalloutButton = styled(BodyRegular)`
    cursor: pointer;
    color: white;
    align-items: center;
    justify-items: center;
    align-self: center;
    padding: 3px;
    width: fit-content;
    border: 1px solid white;
    border-radius: 4px;
    justify-content: center;
`;

const CalloutText = styled(BodyRegular)`
    color: white;
    white-space: break-spaces;
`;

export function useCustomTooltipCallout(
    isEnabled: boolean,
    clientEventType: ClientEventType,
    tooltipFeatureDiscoveryKey: TooltipFeatureDiscoveryKey
): UseCustomTooltipCalloutResults {
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const hasNotInteractedWithCallout = !user.dismissedHints.includes(tooltipFeatureDiscoveryKey);
    const shouldShowThisHint = isEnabled && hasNotInteractedWithCallout;
    const [isVisible, setIsVisible] = useState<boolean>(shouldShowThisHint);

    useEffect(() => {
        if (shouldShowThisHint && !isVisible) {
            setIsVisible(true);
        }
        if (!shouldShowThisHint && isVisible) {
            setIsVisible(false);
        }
    }, [shouldShowThisHint]);

    async function onEngageClick() {
        setIsVisible(false);
        track(clientEventType);
        // Optimistically update the user record here with the assumption that the patch call will succeed
        // This ensures the FE updates sooner
        setUser({
            ...user.toIClientUser(),
            metadata: {
                ...user.metadata,
                dismissedHints: getUniques([...user.dismissedHints, tooltipFeatureDiscoveryKey]),
            },
        });
        const { user: patchedUser } = await patchUser({
            metadata: {
                dismissedHints: getUniques([...user.dismissedHints, tooltipFeatureDiscoveryKey]),
            },
        });
        // To ensure safety of user data we also set the user after we get a response
        if (patchedUser) {
            setUser(patchedUser);
        }
    }

    return {
        onEngageClick,
        isVisible,
        feature: tooltipFeatureDiscoveryKey,
    };
}

export type CustomTooltipCalloutProps = {
    clientEventType: ClientEventType;
    isVisible: boolean;
    tooltipFeatureDiscoveryElementId: string;
    calloutText: string;
    onEngageClick: () => void;
    tooltipStyle?: CSSProperties;
    textContainerStyle?: CSSProperties;
};
export function CustomTooltipCallout({
    clientEventType,
    isVisible,
    tooltipFeatureDiscoveryElementId,
    calloutText,
    onEngageClick,
    textContainerStyle,
    tooltipStyle,
}: CustomTooltipCalloutProps): JSX.Element {
    useLandingAnalytic(clientEventType);
    const isMounted = useRef(false);
    const [shouldShow, setShouldShow] = useState(false);

    const anchorEl = document.getElementById(tooltipFeatureDiscoveryElementId);

    const validateAndSetShouldShow = (isComponentVisable: boolean, anchorEl: HTMLElement | null) => {
        const shouldShowTooltip = Boolean(
            isComponentVisable &&
                anchorEl &&
                'offsetParent' in anchorEl &&
                isElementInBounds(anchorEl?.offsetParent as HTMLDivElement, anchorEl)
        );
        setShouldShow(shouldShowTooltip);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            validateAndSetShouldShow(isVisible, anchorEl);
            clearTimeout(timeout);
        }, 350);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setShouldShow(isVisible);
        } else {
            isMounted.current = true;
        }

        return () => setShouldShow(false);
    }, [isVisible]);

    const isElementInBounds = (innerElement?: HTMLDivElement | null, anchorElement?: HTMLElement | null) => {
        const nextElementSibling: HTMLDivElement | undefined | null =
            innerElement?.nextElementSibling as HTMLDivElement;
        return (
            anchorElement &&
            innerElement &&
            nextElementSibling &&
            window.innerHeight - anchorElement.getBoundingClientRect().top <
                innerElement.offsetHeight + innerElement.offsetTop + nextElementSibling.offsetHeight &&
            anchorElement.getBoundingClientRect().top <
                nextElementSibling.offsetHeight + nextElementSibling.offsetTop + 64
        );
    };

    const resizeListener = () => {
        /**
         * @NOTE ensures on window resize and scroll that
         * the tooltip is removed from view, then reattached to the
         * anchor element to not block the user's view on resize and/or scroll
         * and to also rerender to get the new anchor element location
         * */
        setShouldShow(false);

        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
            scrollTimeout = null;
        }

        scrollTimeout = setTimeout(() => {
            if (
                anchorEl &&
                'offsetParent' in anchorEl &&
                isElementInBounds(anchorEl?.offsetParent as HTMLDivElement, anchorEl)
            ) {
                setShouldShow(true);
            }
        }, 400);
    };

    const scrollListener = (e: Event) => {
        /**
         * @NOTE ensures on window resize and scroll that
         * the tooltip is removed from view, then reattached to the
         * anchor element to not block the user's view on resize and/or scroll
         * and to also rerender to get the new anchor element location
         * */
        setShouldShow(false);

        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
            scrollTimeout = null;
        }

        scrollTimeout = setTimeout(() => {
            if (isElementInBounds(e.target as HTMLDivElement, anchorEl)) {
                setShouldShow(true);
            }
        }, 400);
    };

    useEffect(() => {
        addEventListener('scroll', scrollListener, { capture: true });
        addEventListener('resize', resizeListener, { capture: true });

        validateAndSetShouldShow(isVisible, anchorEl);

        return () => {
            removeEventListener('scroll', scrollListener, { capture: true });
            removeEventListener('resize', resizeListener, { capture: true });
        };
    }, [anchorEl?.getBoundingClientRect()]);

    if (!shouldShow) {
        return <></>;
    }

    if (!anchorEl) {
        return <></>;
    }
    return (
        /**
         * @TODO Should the `id` value go in props to be passed in?
         * I could see this being returned in the `useJiraMagicInputCallout` hook
         * instead of hardcoded here
         */
        <FeatureTooltipCallout
            open={shouldShow}
            arrow
            style={tooltipStyle}
            interactive
            PopperProps={{
                anchorEl,
            }}
        >
            <Column style={{ maxWidth: '150px', ...textContainerStyle }}>
                <CalloutText>{calloutText}</CalloutText>
                <Spacing factor={1 / 5} />
                <CalloutButton onClick={onEngageClick}>Got it</CalloutButton>
            </Column>
        </FeatureTooltipCallout>
    );
}
