import styled from 'styled-components';

import { BodyRegularOnboard } from '../../../styles';
import { Column, Row, Spacing } from '../../common';

const BodyHeader = styled(BodyRegularOnboard)`
    font-weight: bold;
    padding-bottom: 3px;
`;
const ListItemRow = styled(Row)`
    padding-bottom: 5px;
`;
const ListItemNumeral = styled(Column)`
    padding-left: 3px;
    padding-right: 5px;
    width: fit-content;
`;

export const JiraUpgradeWarning = () => (
    <Column
        style={{
            textAlign: 'start',
            background: '#F69921AA',
            borderRadius: '5px',
            padding: '5px',
            color: 'white',
            marginTop: '10px',
            marginBottom: '10px',
        }}
    >
        <BodyHeader>What’s changing?</BodyHeader>
        <BodyRegularOnboard>
            {`We've enhanced our Jira integration to provide users with the ability to create Jira tickets.`}
        </BodyRegularOnboard>
        <Spacing factor={1 / 5} />
        <BodyRegularOnboard>
            {
                'The enhanced integration will also provide you with more accurate results when fetching and linking Jira tickets'
            }
        </BodyRegularOnboard>
        <Spacing factor={1 / 3} />
        <BodyHeader>How to update?</BodyHeader>
        <ListItemRow>
            <ListItemNumeral style={{ paddingLeft: '3px', paddingRight: '5px', width: 'fit-content' }}>
                <BodyRegularOnboard>1.</BodyRegularOnboard>
            </ListItemNumeral>
            <Column>
                <BodyRegularOnboard>{'Remove your Jira connection using the button below.'}</BodyRegularOnboard>
            </Column>
        </ListItemRow>
        <ListItemRow>
            <ListItemNumeral>
                <BodyRegularOnboard>2.</BodyRegularOnboard>
            </ListItemNumeral>
            <Column>
                <BodyRegularOnboard>
                    {'Reconnect Jira and follow the on-screen instructions to allow the requested permissions.'}
                </BodyRegularOnboard>
            </Column>
        </ListItemRow>
    </Column>
);
