import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking } from '../../hooks';
import { URLUtil } from '../../utils';

const Hyperlink = styled.a`
    cursor: pointer;
    text-decoration: underline;
    margin-left: 2px;
    margin-right: 2px;
`;

const GENERIC_PAY_URL = 'https://buy.stripe.com/9AQ03Uc0u7Ryd1udSV';

export enum LimitationIntent {
    Jira = 'Jira',
    Slack = 'Slack',
    SummaryExport = 'Summary Export',
    History = 'History',
}

const INTENT_COPY_MAP = {
    [LimitationIntent.Jira]: `to enable Jira Integration`,
    [LimitationIntent.Slack]: `to enable summaries to Slack`,
    [LimitationIntent.SummaryExport]: `to export summaries`,
    [LimitationIntent.History]: `to access all Summaries`,
};

export function FreeTierLimitationMessage({
    intent,
    icon = '⚠️',
    style,
}: {
    intent: LimitationIntent;
    icon?: ReactNode;
    style?: CSSProperties;
}): JSX.Element {
    const track = useExperienceTracking();

    function onEngageClick() {
        track(ClientEventType.ClickUpgradePayURL, { Intent: intent });
        URLUtil.openURL(GENERIC_PAY_URL, { redirectThroughBackendWhenOnZoom: true });
    }

    return (
        <div style={style}>
            {icon} Please<Hyperlink onClick={onEngageClick}>upgrade</Hyperlink>
            {INTENT_COPY_MAP[intent]}
        </div>
    );
}
