import {
    AgendaItem,
    DetailedTicket,
    IYTBAgendaItemProps,
    ProjectManagement,
    SpinachUpdateType,
    TicketsByStatus,
    TypedUpdate,
} from '@spinach-shared/types';

import { YTBUpdateProps } from '../..';
import { BaseAgendaItemProps } from './BaseAgendaItemProps';

export class YTBAgendaItemProps extends BaseAgendaItemProps {
    standUpUpdate: YTBUpdateProps;
    projectManagement?: ProjectManagement;

    constructor(props: IYTBAgendaItemProps) {
        super(props);
        this.standUpUpdate = new YTBUpdateProps(props.standUpUpdate); // TODO YTBUpdateProps
        this.projectManagement = props.projectManagement;
    }

    get icebreakerUpdates(): TypedUpdate[] {
        return this.standUpUpdate.updates.filter((u) => u.updateType === SpinachUpdateType.Icebreaker);
    }

    get tickets(): DetailedTicket[] {
        return this.projectManagement?.tickets ?? [];
    }

    get ticketsByStatus(): TicketsByStatus {
        return this.projectManagement?.ticketsByStatus ?? {};
    }

    get ticketsOnBoard(): DetailedTicket[] {
        return Object.values(this.ticketsByStatus).flat();
    }

    get orderedStatuses(): string[] {
        return this.projectManagement?.orderedStatuses ?? [];
    }

    toJSON(): AgendaItem {
        return {
            ...super.toJSON(),
            standUpUpdate: this.standUpUpdate.toJSON(),
            projectManagement: this.projectManagement,
        };
    }
}
