import { DemoVersion, ISpinachParticipantProps, StandUpStatus, UUID, UserMetadata } from '@spinach-shared/types';

import { ConnectingSpinachParticipantProps } from './ConnectingSpinachParticipantProps';

export class SpinachParticipantProps extends ConnectingSpinachParticipantProps {
    spinachUserId: UUID;
    email: string;
    connectionIds: string[];
    metadata?: UserMetadata;

    constructor(props: ISpinachParticipantProps) {
        super(props);

        this.spinachUserId = props.spinachUserId;
        this.email = props.email;
        this.metadata = props.metadata;
        this.connectionIds = props.connectionIds;
    }

    get isReady(): boolean {
        return this.standUpStatus === StandUpStatus.Ready;
    }

    get usesPersonaDemo(): boolean {
        return this.metadata?.demoVersion === DemoVersion.Persona;
    }

    toJSON(): ISpinachParticipantProps {
        return {
            ...super.toJSON(),
            spinachUserId: this.spinachUserId,
            email: this.email,
            connectionIds: this.connectionIds,
            metadata: this.metadata,
        };
    }
}
