import { Box, CircularProgress, Modal } from '@material-ui/core';

import { ClientEventType, SpinachIntegrations, TicketSource } from '@spinach-shared/types';

import { GlobalModal, GlobalModalMetadataType } from '../../../atoms';
import { useExperienceTracking, useGlobalModal, useGlobalModalState } from '../../../hooks';
import { useGlobalTicketProjectsState, useTicketProjects } from '../../../hooks/useTicketProjects';
import { lightTheme } from '../../../styles';
import {
    TICKET_SOURCE_PROJECT_NAME_MAP,
    TicketProjectSelection,
} from '../../spinach-ai/dashboard/integrations/TicketProjectSelection';

export function SelectTicketProjectContent({ ticketSource }: { ticketSource: TicketSource }) {
    const [projectState] = useGlobalTicketProjectsState();
    return (
        <Box>
            {!projectState?.[ticketSource] ? (
                <CircularProgress
                    size={'30px'}
                    style={{ marginLeft: '10px', marginBottom: '15px', color: lightTheme.primary.orangeDark }}
                />
            ) : (
                <>
                    <Box display="flex" flexDirection="column">
                        <Box
                            style={{
                                fontWeight: 500,
                                fontSize: '17px',
                                textTransform: 'uppercase',
                                letterSpacing: '1.5px',
                            }}
                        >{`Choose a ${TICKET_SOURCE_PROJECT_NAME_MAP[ticketSource]}`}</Box>
                        <Box
                            style={{ fontSize: '15px', marginTop: '5px' }}
                        >{`Select which ${TICKET_SOURCE_PROJECT_NAME_MAP[ticketSource]} you want to use for your ${SpinachIntegrations[ticketSource].displayName} integration.`}</Box>
                        <TicketProjectSelection ticketSource={ticketSource} />
                    </Box>
                </>
            )}
        </Box>
    );
}

export function SelectTicketProject(): JSX.Element {
    const [globalModal, setGlobalModal] = useGlobalModal();
    const modalState = useGlobalModalState();
    const modalStateMetadata = modalState?.metadata;
    useTicketProjects();

    const track = useExperienceTracking();
    if (modalStateMetadata?.metadataType !== GlobalModalMetadataType.TicketIntegration) {
        return <></>;
    }

    const { ticketSource } = modalStateMetadata;

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Select Ticket Project Modal',
            TicketSource: ticketSource,
        });
        setGlobalModal(null);
    };

    /** @NOTE If there's no ticket source set then don't show the modal */
    if (!ticketSource) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.SelectTicketProject} onClose={closeModal}>
            <SelectTicketProjectContent ticketSource={ticketSource} />
        </Modal>
    );
}
