import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Socket } from 'socket.io-client';

import { ClientUser, SpinachSeriesProps } from '@spinach-shared/models';
import { SeriesUpdatedResponse, ServerSocketEvent } from '@spinach-shared/types';

import { atomLiveSeries } from '../atoms';
import { SetValue } from '../types';
import { Globals } from '../utils/Globals';

type LiveSeriesReturnTypes = [SpinachSeriesProps | null, SetValue<SpinachSeriesProps | null>];
type DefinedLiveSeriesReturnTypes = [SpinachSeriesProps, SetValue<SpinachSeriesProps>];

export function useLiveSeries(socket: Socket | null, user: ClientUser): LiveSeriesReturnTypes {
    const [series, setSeries] = useRecoilState(atomLiveSeries);

    useEffect(() => {
        socket?.on(ServerSocketEvent.SeriesUpdated, (response: SeriesUpdatedResponse) => {
            const series = new SpinachSeriesProps(response.series, user.spinachUserId);
            setSeries(series);
            Globals.clientServerTimeOffset = response.serverResponseTime - new Date().getTime();
        });
    }, [socket]);

    return [series, setSeries];
}

/**
 *
 * @returns a defined global live series ref.
 * NOTE: only use this in parts of the app where the live series would be guaranteed
 */
export function useGlobalLiveSeries(): DefinedLiveSeriesReturnTypes {
    const [liveSeries, setLiveSeries] = useRecoilState(atomLiveSeries);
    return [liveSeries as SpinachSeriesProps, setLiveSeries as SetValue<SpinachSeriesProps>];
}

/**
 *
 * @returns a ref to the global live series which may or may not be undefined depending on where
 * its used.
 */
export function useGlobalNullableLiveSeries(): LiveSeriesReturnTypes {
    const [liveSeries, setLiveSeries] = useRecoilState(atomLiveSeries);
    return [liveSeries, setLiveSeries];
}
