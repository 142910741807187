import { atom } from 'recoil';

import { CalendarEvent } from '@spinach-shared/types';

// This atom is an array of Calendar events returned in its raw form form the BE /calendar/events API
// This array can be used as necessary to display events in any way necessary
export const googleCalendarEventsAtom = atom<CalendarEvent[] | null>({
    key: 'googleCalendarEventsAtom', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

// This atom is a map of calendar events (obtained from the raw array form) with a key/value pair
// Where the key is the date (in form MM-DD-YY) and the value is the calendar events for that day
// This atom can be used when it's necessary to obtain calendar events for a particular day
export const googleCalendarEventsMapAtom = atom<Record<string, CalendarEvent[]> | null>({
    key: 'googleCalendarEventsMapAtom',
    default: null,
});
