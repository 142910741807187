import { useWindowSize } from '../../../../../hooks';
import { useAtlassianSites } from '../../../../../hooks/useAtlassianSites';
import { useTicketProjects } from '../../../../../hooks/useTicketProjects';
import { HeaderThreeOnboard } from '../../../../../styles';
import { Hairline, Row, Spacing } from '../../../../common';
import { ScrollArea } from '../../../../stand-up';
import { IntegrationSettingsModalKind } from '../../types';
import { SpinachAppCommunicationsIntegrationsSection } from './SpinachAppCommunicationIntegrationSection';
import { SpinachAppKnowledgeBaseIntegrationSection } from './SpinachAppKnowledgeBaseIntegrationSection';
import { MeetingPlatformsIntegrationSection } from './SpinachAppMeetingPlatformsIntegrationSection';
import { SpinachAppTicketingIntegrationsSection } from './SpinachAppTicketingIntegrationSection';

export function SpinachAppIntegrationsSection({
    setModalKind,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
}): JSX.Element {
    const { isDesktopView } = useWindowSize();
    useTicketProjects();
    useAtlassianSites();

    return (
        <>
            <Spacing factor={isDesktopView ? 1 / 2 : 1 / 2} />

            <Row vCenter>
                <HeaderThreeOnboard>Settings</HeaderThreeOnboard>
            </Row>

            <Spacing />

            <ScrollArea sidePadding={0} style={{ width: '100%' }}>
                <SpinachAppCommunicationsIntegrationsSection setModalKind={setModalKind} />
                <Hairline />
                <Spacing factor={1 / 2} />
                <SpinachAppTicketingIntegrationsSection setModalKind={setModalKind} />
                <Hairline />
                <Spacing factor={1 / 2} />
                <SpinachAppKnowledgeBaseIntegrationSection setModalKind={setModalKind} />
                <Hairline />
                <Spacing factor={1 / 2} />
                <MeetingPlatformsIntegrationSection />
            </ScrollArea>
        </>
    );
}
