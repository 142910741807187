import { ReactNode } from 'react';

import { TicketSource } from '@spinach-shared/types';

import { useGlobalAuthedUser } from '../../../../hooks';
import { Row } from '../../../common';
import { ConnectedRowContents } from './ConnectedRowContents';
import { TicketProjectSelection } from './TicketProjectSelection';

export function ConnectedTicketSourceRowContents({
    label,
    ticketSource,
    hoveredRow,
    onSettingsClick,
    attentionRequired = false, // TODO remove
}: {
    label: ReactNode;
    ticketSource: TicketSource;
    hoveredRow: TicketSource | null;
    onSettingsClick?: () => void;
    attentionRequired?: boolean;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const currentProject = user.ticketSourceProject(ticketSource);
    return (
        <Row vCenter style={{ width: 'unset' }}>
            <ConnectedRowContents
                label={label}
                isSettingsVisible={hoveredRow === ticketSource}
                attentionRequired={!currentProject || attentionRequired}
                onSettingsClick={onSettingsClick}
            />
        </Row>
    );
}
