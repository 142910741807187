export const validateEnumUniqueness = (...args: object[]) => {
    const typeArgs = new Map();
    for (const arg of args) {
        Object.values(arg).forEach((value) => {
            if (typeArgs.has(value)) {
                throw new Error(`Enums are not unique! ${value} is present in more than one of the supplied enums`);
            } else {
                typeArgs.set(value, true);
            }
        });
    }
};
