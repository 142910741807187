import { MeetingProps, UserMood } from '@spinach-shared/types';

import { SpinachParticipantListProps, SpinachParticipantProps, YTBAgendaItemProps } from '../..';
import { BaseMeetingProps } from './BaseMeetingProps';

export class SpinachMeetingProps extends BaseMeetingProps {
    spinachUserId: string;
    participants: SpinachParticipantListProps;

    constructor(props: MeetingProps, spinachUserId: string) {
        super(props);

        this.spinachUserId = spinachUserId;
        this.participants = new SpinachParticipantListProps(props.participants, spinachUserId);
    }

    get spinachParticipant(): SpinachParticipantProps {
        return this.participants.spinachParticipant;
    }

    get thisParticipantsMood(): UserMood | undefined {
        return this.findParticipantMood(this.spinachUserId);
    }

    get thisParticipantsYTBAgendaItem(): YTBAgendaItemProps {
        const { id, spinachUserId } = this.spinachParticipant;

        const item = this.agenda.findParticipantsYTBAgendaItemProps(id, spinachUserId);

        if (!item) {
            throw new Error('unable to find corresponding YTB Agenda Item for spinach user');
        }

        return item;
    }
}
