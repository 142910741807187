import { FacilitatedViewId } from '@spinach-shared/constants';
import {
    Facilitation,
    FacilitationComputedMetadata,
    ParticipantStatus,
    SpinachInsightKind,
} from '@spinach-shared/types';
import { isProductionStage } from '@spinach-shared/utils';

const useMock = window.location.search.includes('useMock') && !isProductionStage();

export const getFacilitationResponseMock = () => {
    const mockFacilitationComputedMetadata: FacilitationComputedMetadata = {
        isBotInMeeting: true,
    };
    const mockFacilitation: Facilitation = {
        actualMeetingTime: {
            startTime: '',
            endTime: '',
        },
        meetingTitle: 'Mock Meeting',
        icebreaker: {
            enabled: true,
            usedIndexes: [],
            viewId: FacilitatedViewId.Icebreaker,
        },
        roundtableWrapup: {
            viewId: FacilitatedViewId.GapAnalysis,
            startStops: [],
            insights: [
                {
                    context: 'You may want to wrap up this topic if you wish to cover the rest.',
                    title: '1 more updates to cover',
                    kind: SpinachInsightKind.Gap,
                    id: '123',
                    actions: [],
                },
            ],
        },
        discussionTopics: [
            {
                viewId: 'abc',
                title: 'Derek and his Azures',
                startStops: [],
                notes: [],
            },
            {
                viewId: 'ecc',
                title: 'Josh and his AWSes',
                startStops: [],
                notes: [],
            },
        ],
        inMeetingInsights: [
            {
                context: 'You may want to wrap up this topic if you wish to cover the rest.',
                title: '1 more updates to cover',
                kind: SpinachInsightKind.Blocker,
                id: '123',
                actions: [],
            },
            {
                context: 'You may want to wrap up this topic if you wish to cover the rest.',
                title: '5 more updates to cover',
                kind: SpinachInsightKind.TimeCheck,
                id: '435',
                actions: [],
            },
        ],
        participants: [
            {
                viewId: '123',
                displayName: 'Coburn Brandon',
                startStops: [],
                status: ParticipantStatus.Online,
            },
            {
                viewId: '456',
                displayName: 'kirill',
                startStops: [],
                status: ParticipantStatus.Online,
            },
        ],
        currentViewId: FacilitatedViewId.MeetingPrep,
        projectedMeetingTime: {
            startTime: '',
            endTime: '',
        },
    };
    // check if window has a query param called useMock=1
    if (useMock) {
        return {
            facilitation: mockFacilitation,
            facilitationComputedMetadata: mockFacilitationComputedMetadata,
            previousBotId: 'fd6499c2-0846-4986-8c28-840b383f0ecc',
            botId: '1d6499c2-0846-4986-8c28-840b383f0ecc',
        };
    } else {
        return undefined;
    }
};
