import { TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { v4 } from 'uuid';

import { FacilitatedStartStops } from '@spinach-shared/models';
import { DiscussionTopic } from '@spinach-shared/types';
import { formatTime } from '@spinach-shared/utils';

import { useGlobalBotIdPair, useGlobalFacilitation } from '../../../hooks';
import { withContentMasking } from '../../../utils';
import { Row } from '../../common';
import { useSpinachNewInputStyles } from '../../input';
import { getPreviousAgendaFacilitation } from './common';

function useEmptyAppendedTopics() {
    const [facilitation, setFacilitation] = useGlobalFacilitation();

    useEffect(() => {
        if (facilitation?.discussionTopics.every((dt) => !!dt.title)) {
            setFacilitation((facilitation) => {
                const copyOfTopics: DiscussionTopic[] = [
                    ...facilitation.discussionTopics,
                    {
                        notes: [],
                        startStops: [],
                        title: '',
                        viewId: v4(),
                    },
                ];
                return {
                    ...facilitation,
                    discussionTopics: copyOfTopics,
                };
            });
        }
    }, [facilitation.discussionTopics]);
}

export function DiscussionTopics(): JSX.Element {
    const [facilitation, setFacilitation] = useGlobalFacilitation();
    const [botIdPair] = useGlobalBotIdPair();

    const classes = useSpinachNewInputStyles({
        value: '',
    });

    useEmptyAppendedTopics();

    return (
        <>
            {facilitation?.discussionTopics.map((topic, i) => {
                return (
                    <Row
                        style={{
                            marginLeft: '10px',
                            marginTop: '10px',
                            width: 'calc(100% - 20px)',
                            justifyContent: 'space-between',
                        }}
                    >
                        <TextField
                            aria-label={`discussion-topics-input-field`}
                            InputProps={{ classes: { root: classes.base } }}
                            multiline={true}
                            {...withContentMasking(classes.root)}
                            fullWidth
                            value={topic.title}
                            autoFocus={false}
                            onChange={(e) => {
                                if (e.target.value.includes('\n')) {
                                    return;
                                }

                                setFacilitation((facilitation) => {
                                    let copyOfTopics = [...facilitation.discussionTopics];

                                    copyOfTopics = copyOfTopics.map((topic, index) => {
                                        if (index === i) {
                                            return {
                                                ...topic,
                                                title: e.target.value,
                                            };
                                        } else {
                                            return topic;
                                        }
                                    });
                                    return {
                                        ...facilitation,
                                        discussionTopics: copyOfTopics,
                                    };
                                });
                            }}
                            onBlur={(e) => {
                                /** remove empty inputs that aren't at the bottom of the topics list */
                                if (!e.target.value) {
                                    if (i !== facilitation?.discussionTopics.length - 1) {
                                        // if we delete current topic, go backwards too
                                        if (facilitation.currentViewId === topic.viewId) {
                                            setFacilitation((facilitation) => {
                                                const updatedFacilitationState = getPreviousAgendaFacilitation(
                                                    facilitation,
                                                    botIdPair
                                                );
                                                return {
                                                    ...facilitation,
                                                    ...updatedFacilitationState,
                                                    discussionTopics: facilitation.discussionTopics.filter(
                                                        (dt) => dt.viewId !== topic.viewId
                                                    ),
                                                };
                                            });
                                        } else {
                                            setFacilitation((facilitation) => ({
                                                ...facilitation,
                                                discussionTopics: facilitation.discussionTopics.filter(
                                                    (dt) => dt.viewId !== topic.viewId
                                                ),
                                            }));
                                        }
                                    }
                                }
                            }}
                            placeholder={'Add topic'}
                        />
                        <span style={{ fontSize: '12px', marginTop: '5px' }}>
                            {new FacilitatedStartStops(topic.startStops).totalTime
                                ? formatTime(new FacilitatedStartStops(topic.startStops).totalTime)
                                : '-'}
                        </span>
                    </Row>
                );
            })}
        </>
    );
}
