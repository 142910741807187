import { atom } from 'recoil';

import {
    BotIdPair,
    Facilitation,
    FacilitationComputedMetadata,
    SummaryJson,
    TeamTicketingMap,
} from '@spinach-shared/types';

export const atomFacilitation = atom<Facilitation | null>({
    key: 'atomFacilitation',
    default: null,
});

export const atomFacilitationComputedMetadata = atom<FacilitationComputedMetadata | null>({
    key: 'atomFacilitationComputedMetadata',
    default: null,
});

export const atomAiSummaryJson = atom<SummaryJson | null>({
    key: 'atomAiSummaryJson',
    default: null,
});

export const atomFacilitationBotIds = atom<BotIdPair | null>({
    key: 'atomFacilitationBotIds',
    default: null,
});

export const atomFacilitationJiraTicketMap = atom<TeamTicketingMap | null>({
    key: 'atomFacilitationJiraTicketMap',
    default: null,
});
