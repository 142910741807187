import { useEffect, useState } from 'react';

/**
 *
 * @param {T | undefined | null} condition - The condition to evaluate which indicates when the loading is done. This should be falsey when the state is still loading, and evaluate to truthy when done loading. If nothing is passed in loading is automatically finished after a deterministic amount of time. Ensure that a potential response value is not `undefined`.
 * @returns {boolean} - Should return a value that is true when the state is still loading (i.e. when you're waiting on a response from an API, or for a condition to evaluate to `true`)
 */
export const useLoadingState = <T>(condition?: T | boolean | null): boolean => {
    const [isMinDelayElapsed, setIsMinDelayElapsed] = useState<boolean>(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsMinDelayElapsed(true);
        }, 1800);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, []);

    if (condition === undefined) {
        return !isMinDelayElapsed;
    }

    return Boolean(!condition || !isMinDelayElapsed);
};
