import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { useSeriesReality, useWindowSize } from '../../hooks';
import { getDemoBannerHeight } from '../../utils';
import { DemoBanner } from '../demo';

const Container = styled.div<{ height: number }>`
    display: flex;
    width: 100vw;
    height: ${(props) => props.height}px;
`;

const Positioning = styled.div<{
    isOnDemo: boolean;
    isBannerVisible: boolean;
}>`
    display: flex;
    z-index: 1;
    width: 100%;

    margin-top: ${(props) => (props.isBannerVisible ? `${getDemoBannerHeight()}px` : 'unset')};
    justify-content: ${(props) => (props.isOnDemo ? 'space-around' : 'center')};
    align-items: ${(props) => (props.isOnDemo ? 'center' : 'initial')};

    transition: 750ms;
`;

export function InMeetingContainer({ children }: { children?: ReactNode }): JSX.Element {
    const { height } = useWindowSize();
    const { isDemoSeries } = useSeriesReality();
    const isBannerVisible = isDemoSeries;

    return (
        <Container height={height}>
            <DemoBanner />

            <Positioning isOnDemo={isDemoSeries} isBannerVisible={isBannerVisible}>
                {children}
            </Positioning>
        </Container>
    );
}
