import zoomSdk, { OnCollaborateChangeEvent } from '@zoom/appssdk';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { deleteZoomCollab, getZoomCollab, postZoomCollab } from '../apis';
import { ClientPath } from '../components';
import { isZoomPlatform } from '../utils';
import { useGlobalRouting } from './useGlobalRouting';
import { useGlobalNullableLiveSeries } from './useLiveSeries';

export function useZoomCollaborate() {
    const [liveSeries] = useGlobalNullableLiveSeries();
    const { routeToSeriesExperience } = useGlobalRouting();
    const location = useLocation();

    useEffect(() => {
        if (!isZoomPlatform()) {
            return;
        }

        async function handleCollabChange(event: OnCollaborateChangeEvent) {
            const { action } = event;
            const meeting = await zoomSdk.getMeetingUUID();

            if (action === 'start') {
                if (location.pathname === ClientPath.Root) {
                    return;
                }

                if (liveSeries?.slug) {
                    await postZoomCollab(meeting.meetingUUID, liveSeries?.slug);
                }
            }

            if (action === 'join') {
                const collabDetails = await getZoomCollab(meeting.meetingUUID);

                if (collabDetails?.seriesId) {
                    routeToSeriesExperience(collabDetails.seriesId);
                }
            }

            if (action === 'end') {
                await deleteZoomCollab(meeting.meetingUUID);
            }
        }

        zoomSdk.addEventListener('onCollaborateChange', handleCollabChange);

        return () => zoomSdk.removeEventListener('onCollaborateChange', handleCollabChange);
    }, [liveSeries?.slug]);
}
