import { TextField } from '@material-ui/core';
import { useEffect } from 'react';

import { DiscussionTopic } from '@spinach-shared/types';

import { useGlobalFacilitation } from '../../../hooks';
import { BodyBig } from '../../../styles';
import { withContentMasking } from '../../../utils';
import { Row, Spacing } from '../../common';
import { useSpinachNewInputStyles } from '../../input';
import { TimedHeader } from './TimedHeader';
import { Padding, ScrollView } from './common';

function useEmptyAppendedTopics(currentTopic?: DiscussionTopic) {
    const [, setFacilitation] = useGlobalFacilitation();

    useEffect(() => {
        if (currentTopic?.notes.every((note) => !!note)) {
            setFacilitation((facilitation) => {
                let copyOfDiscussionTopics = [...facilitation.discussionTopics];

                copyOfDiscussionTopics = copyOfDiscussionTopics.map((topic) => {
                    if (topic.viewId === currentTopic.viewId) {
                        return {
                            ...topic,
                            notes: currentTopic.notes.concat(''),
                        };
                    } else {
                        return topic;
                    }
                });
                return {
                    ...facilitation,
                    discussionTopics: copyOfDiscussionTopics,
                };
            });
        }
    }, [currentTopic]);
}

export function DiscussionTopicContent(): JSX.Element {
    const [facilitation, setFacilitation] = useGlobalFacilitation();

    const currentTopic = facilitation.discussionTopics.find((p) => p.viewId === facilitation.currentViewId);

    useEmptyAppendedTopics(currentTopic);

    const classes = useSpinachNewInputStyles({
        value: '',
    });

    if (!currentTopic) {
        return <BodyBig>No topic found. Please proceed refresh the app.</BodyBig>;
    }

    return (
        <>
            <TimedHeader title={currentTopic.title} isPii />

            <Spacing factor={1 / 5} />

            <ScrollView sidePadding={0} stretch style={{ height: 'calc(100% - 50px)' }}>
                <Padding>
                    {currentTopic.notes.map((note, i) => {
                        return (
                            <Row key={i} style={{ marginLeft: '10px', marginTop: '10px', width: 'calc(100% - 20px)' }}>
                                <TextField
                                    aria-label={`discussion-topic-notes-input-field`}
                                    InputProps={{ classes: { root: classes.base } }}
                                    multiline={true}
                                    {...withContentMasking(classes.root)}
                                    fullWidth
                                    value={note}
                                    autoFocus={false}
                                    onChange={(e) => {
                                        if (e.target.value.includes('\n')) {
                                            // TODO - tab from here?
                                            return;
                                        }

                                        setFacilitation((facilitation) => {
                                            let copyOfNotes = [...currentTopic.notes];

                                            let copyOfDiscussionTopics = [...facilitation.discussionTopics];

                                            copyOfNotes = copyOfNotes.map((note, index) => {
                                                if (index === i) {
                                                    return e.target.value;
                                                } else {
                                                    return note;
                                                }
                                            });

                                            copyOfDiscussionTopics = copyOfDiscussionTopics.map((topic) => {
                                                if (topic.viewId === currentTopic.viewId) {
                                                    return {
                                                        ...topic,
                                                        notes: copyOfNotes,
                                                    };
                                                } else {
                                                    return topic;
                                                }
                                            });
                                            return {
                                                ...facilitation,
                                                discussionTopics: copyOfDiscussionTopics,
                                            };
                                        });
                                    }}
                                    onBlur={(e) => {
                                        /** remove empty inputs that aren't at the bottom of the topics list */
                                        if (!e.target.value) {
                                            if (i !== currentTopic.notes.length - 1) {
                                                setFacilitation((facilitation) => {
                                                    let copyOfDiscussionTopics = [...facilitation.discussionTopics];

                                                    copyOfDiscussionTopics = copyOfDiscussionTopics.map((topic) => {
                                                        if (topic.viewId === currentTopic.viewId) {
                                                            return {
                                                                ...topic,
                                                                notes: currentTopic.notes.filter((n, ni) => ni !== i),
                                                            };
                                                        } else {
                                                            return topic;
                                                        }
                                                    });
                                                    return {
                                                        ...facilitation,
                                                        discussionTopics: copyOfDiscussionTopics,
                                                    };
                                                });
                                            }
                                        }
                                    }}
                                    placeholder={'+ Add a note'}
                                />
                            </Row>
                        );
                    })}
                </Padding>
            </ScrollView>
        </>
    );
}
