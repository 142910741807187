import React, { CSSProperties, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { HeaderThree } from '../..';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    word-wrap: anywhere;
    margin-bottom: 15px;
    flex-shrink: 0;
    z-index: 5;
`;

const Header = styled(HeaderThree)`
    text-align: left;
`;

export type StandUpHeaderProps = Omit<HTMLAttributes<HTMLHeadingElement>, 'title'> & {
    title: string | JSX.Element;
    containerStyle?: CSSProperties;
};

export function StandUpHeader({ title, containerStyle, ...rest }: StandUpHeaderProps): JSX.Element {
    return (
        <Container style={{ ...containerStyle }}>
            <Header {...rest}>{title}</Header>
        </Container>
    );
}
