import Tooltip from '@material-ui/core/Tooltip';
import CurrentItemIcon from '@material-ui/icons/SignalCellular4BarSharp';
import { makeStyles } from '@material-ui/styles';
import { Titles } from '@spinach-clients/constants';
import React, { useState } from 'react';
import Scroll from 'react-scroll';
import styled, { DefaultTheme } from 'styled-components';

import { ActiveAgendaProps, BaseAgendaItemProps, SpinachSeriesProps } from '@spinach-shared/models';
import { AgendaItemSource, MeetingStatus } from '@spinach-shared/types';

import { ReactComponent as MeditationProgresIcon } from '../../assets/meditation-indication-icon.svg';
import { ReactComponent as NavigationArrow } from '../../assets/reply.svg';
import { ReactComponent as SummaryViewProgressIcon } from '../../assets/summary-indication-icon.svg';
import { ReactComponent as UserHasGoneItemProgressIcon } from '../../assets/user-has-gone-item-progress-icon.svg';
import { ReactComponent as UserHasNotGoneItemProgressIcon } from '../../assets/user-has-not-gone-item-progress-icon.svg';
import { ReactComponent as UserIsGoingItemProgressIcon } from '../../assets/user-is-going-item-progress-icon.svg';
import { useProgressNavigationEnablement } from '../../hooks/useFeature';
import { lightTheme } from '../../styles';
import { withContentMasking } from '../../utils';

const Box = styled.div<{ color?: string }>`
    width: 16px;
    height: 16px;
    min-width: 16px;
    background-color: ${(props) => (props.color ? props.color : lightTheme.tertiary.orangeLight)};
    margin-left: 2px;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

const useTooltipStyles = makeStyles<DefaultTheme>(() => ({
    tooltipPlacementBottom: {
        marginTop: 3,
    },
}));

function ProgressElementIcon({
    hasMeetingEnded,
    currentAgendaIndex,
    isSpinachUserItem,
    isTeamTopicsItem,
    index,
    isMeditationItem,
    isNavigable,
    isMouseOver,
}: {
    hasMeetingEnded: boolean;
    currentAgendaIndex: number;
    isSpinachUserItem: boolean;
    isTeamTopicsItem: boolean;
    index: number;
    isMeditationItem: boolean;
    isNavigable: boolean;
    isMouseOver: boolean;
}): JSX.Element {
    if (isTeamTopicsItem) {
        return <></>;
    }

    if (hasMeetingEnded || index < currentAgendaIndex) {
        if (isSpinachUserItem) {
            return (
                <UserHasGoneItemProgressIcon
                    style={{
                        height: '16px',
                        width: '16px',
                        marginLeft: '2px',
                    }}
                    id="users-agenda-item-completed-icon"
                />
            );
        }

        if (isMeditationItem) {
            return (
                <MeditationProgresIcon
                    style={{
                        height: '16px',
                        width: '16px',
                    }}
                    id="meditation-icon"
                />
            );
        }

        if (!isSpinachUserItem && !isMeditationItem) {
            return (
                <Box
                    color="#F69921"
                    style={{
                        height: '16px',
                        width: '16px',
                        marginLeft: '2px',
                    }}
                    id="other-users-agenda-item-completed-icon"
                />
            );
        }
    } else if (!hasMeetingEnded && index === currentAgendaIndex) {
        if (isSpinachUserItem) {
            return (
                <UserIsGoingItemProgressIcon
                    style={{
                        height: '16px',
                        width: '16px',
                        marginLeft: '2px',
                    }}
                    id="users-agenda-item-current-icon"
                />
            );
        }

        if (isMeditationItem) {
            return (
                <MeditationProgresIcon
                    style={{
                        height: '16px',
                        width: '16px',
                    }}
                    id="meditation-icon"
                />
            );
        }

        if (!isSpinachUserItem && !isMeditationItem) {
            return (
                <CurrentItemIcon
                    style={{
                        backgroundColor: lightTheme.tertiary.orangeLight,
                        fontSize: '16px',
                        marginLeft: '2px',
                        transform: 'scaleX(-1)',
                    }}
                    htmlColor="#F69921"
                    id="other-users-agenda-item-current-icon"
                />
            );
        }
    } else {
        if (isSpinachUserItem) {
            return (
                <UserHasNotGoneItemProgressIcon
                    style={{
                        height: '16px',
                        width: '16px',
                        marginLeft: '2px',
                    }}
                    id="users-agenda-item-coming-up-icon"
                />
            );
        }

        if (isMeditationItem) {
            return (
                <MeditationProgresIcon
                    style={{
                        backgroundColor: lightTheme.tertiary.orangeLight,
                        height: '16px',
                        width: '16px',
                    }}
                    id="meditation-icon"
                />
            );
        }

        if (!isSpinachUserItem && !isMeditationItem) {
            return (
                <Box
                    color={isMouseOver && isNavigable ? '#F69921' : lightTheme.tertiary.orangeLight}
                    style={{
                        height: '16px',
                        width: '16px',
                        marginLeft: '2px',
                    }}
                    id="other-users-agenda-item-coming-up-icon"
                />
            );
        }
    }
    return <> </>;
}
function ProgressElement({
    hasMeetingEnded,
    currentAgendaIndex,
    spinachParticipantAgendaItemId,
    index,
    item,
    onProgressElementClick,
}: {
    hasMeetingEnded: boolean;
    currentAgendaIndex: number;
    spinachParticipantAgendaItemId: string;
    index: number;
    item: BaseAgendaItemProps;
    onProgressElementClick: (itemId: string) => void;
}) {
    const isProgressNavigationEnabled = useProgressNavigationEnablement();
    const [isConfirmingNavigation, setIsConfirmingNavigation] = useState(false);
    const tooltipStyles = useTooltipStyles();
    const isTeamTopicsItem = item.title === Titles.TeamTopics;
    const isSpinachUserItem = spinachParticipantAgendaItemId === item.id;
    const isMeditationItem = item.source === AgendaItemSource.Meditation;
    const isNavigable = isProgressNavigationEnabled && index !== currentAgendaIndex;
    const [isMouseOver, setIsMouseOver] = useState(false);

    return (
        <Tooltip
            classes={tooltipStyles}
            title={index === currentAgendaIndex ? item.title : `Go to ${item.title}`}
            placement="bottom"
            key={item.id}
            {...withContentMasking()}
        >
            <Scroll.Element
                style={{ display: 'flex', cursor: isNavigable ? 'pointer' : 'default', position: 'relative' }}
                name={`progress-element-${item.id}`}
                id={`progress-element-${item.id}`}
                onClick={() => {
                    if (!isNavigable) {
                        return;
                    }

                    if (isConfirmingNavigation) {
                        onProgressElementClick(item.id);
                    }
                    setTimeout(
                        () => setIsConfirmingNavigation(!isConfirmingNavigation),
                        isConfirmingNavigation ? 200 : 0
                    );
                }}
                onMouseLeave={() => {
                    setIsConfirmingNavigation(false);
                    setIsMouseOver(false);
                }}
                onMouseEnter={() => setIsMouseOver(true)}
            >
                <ProgressElementIcon
                    hasMeetingEnded={hasMeetingEnded}
                    currentAgendaIndex={currentAgendaIndex}
                    isSpinachUserItem={isSpinachUserItem}
                    isTeamTopicsItem={isTeamTopicsItem}
                    index={index}
                    isMeditationItem={isMeditationItem}
                    isNavigable={isNavigable}
                    isMouseOver={isMouseOver}
                />
                {isNavigable && isMouseOver ? (
                    <Box
                        color={isConfirmingNavigation ? '#64BA81' : '#F69921'}
                        style={{
                            height: '16px',
                            width: '16px',
                            position: 'absolute',
                            left: '0px',
                            top: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        id="other-users-agenda-item-completed-icon"
                    >
                        <NavigationArrow />
                    </Box>
                ) : null}
            </Scroll.Element>
        </Tooltip>
    );
}

export function ProgressIndication({
    spinachParticipantAgendaItemId,
    series,
    onProgressElementClick,
}: {
    series: SpinachSeriesProps;
    spinachParticipantAgendaItemId: string;
    onProgressElementClick: (itemId: string) => void;
}): JSX.Element {
    const tooltipStyles = useTooltipStyles({});
    const agenda = series.agenda as ActiveAgendaProps;
    const hasMeetingEnded = series.currentMeeting.status === MeetingStatus.AgendaComplete;

    const getIcons = () => {
        let icon: JSX.Element | undefined;

        return agenda.items.map((item, i) => {
            if (!series.isRoundtableEnabled && item.isParticipantAgendaItem) {
                return <React.Fragment key={item.id}></React.Fragment>;
            }

            return (
                <ProgressElement
                    hasMeetingEnded={hasMeetingEnded}
                    spinachParticipantAgendaItemId={spinachParticipantAgendaItemId}
                    currentAgendaIndex={agenda.currentIndex}
                    index={i}
                    item={item}
                    onProgressElementClick={onProgressElementClick}
                />
            );
        });
    };
    const icons = getIcons();
    return (
        <Container key={(hasMeetingEnded ? agenda.items.length : agenda.currentIndex) + agenda.items.length}>
            {
                <>
                    {icons}
                    <Tooltip
                        classes={tooltipStyles}
                        title={'Agenda Summary'}
                        placement="bottom"
                        key={'summary-view-agenda-item-completed'}
                        {...withContentMasking()}
                    >
                        <SummaryViewProgressIcon
                            fill={hasMeetingEnded ? '#F69921' : lightTheme.tertiary.orangeLight}
                            style={{
                                height: '16px',
                                width: '16px',
                                marginLeft: '2px',
                            }}
                            id={
                                hasMeetingEnded
                                    ? 'summary-view-agenda-item-completed-icon'
                                    : 'summary-view-agenda-item-coming-up-icon'
                            }
                        />
                    </Tooltip>
                </>
            }
        </Container>
    );
}
