import { UserTypeAffix } from '@spinach-shared/constants';
import { ClientUser } from '@spinach-shared/models';
import { CalendarEvent, ScribeMeetingType } from '@spinach-shared/types';
import { getMeetingTypeByTitleKeyword, getStage, isLocalStage, isProductionStage } from '@spinach-shared/utils';

import { isWebPlatform } from './platform';

export class TagManager {
    static isInitialized = false;

    static init() {
        if (isWebPlatform() && !isLocalStage()) {
            (function (w, d, s, l, i) {
                // @ts-ignore
                w[l] = w[l] || [];
                // @ts-ignore
                w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                // @ts-ignore
                j.async = true;
                // @ts-ignore
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                // @ts-ignore
                f.parentNode.insertBefore(j, f);

                TagManager.isInitialized = true;
            })(window, document, 'script', 'dataLayer', 'GTM-NCRJHZV');
        }
    }

    static trackSignUp(user: ClientUser) {
        /** @NOTE - maintaining `sign_up` without prefix as that's what it's been before stage-based suffices */
        if (isProductionStage()) {
            this.track('sign_up', user, false);
        } else {
            this.track('sign_up', user);
        }
    }

    static getClientId(): string {
        try {
            if (!this.shouldTrack()) {
                return '';
            }

            // @ts-ignore
            return window.gaGlobal?.vid;
        } catch (error) {
            return '';
        }
    }

    static trackGoogleCodeScribeSignup(user: ClientUser) {
        this.track(`sign_up_with_site_google`, user);
        this.trackCompanySiteSignup(user);
    }

    static trackMicrosoftSiteSignup(user: ClientUser) {
        this.track(`sign_up_with_site_microsoft`, user);
        this.trackCompanySiteSignup(user);
    }

    static trackStartOnboarding(user: ClientUser) {
        this.track(`user_started_onboarding`, user);
    }

    static trackAuthedUser(user: ClientUser) {
        this.track(`user_authed_web_app`, user);
    }

    static trackCompanySiteSignup(user: ClientUser) {
        this.track(`sign_up_with_company_site`, user);
    }

    static trackMicrosoftWebAppSignup(user: ClientUser) {
        this.track(`sign_up_with_webapp_microsoft`, user);
        this.trackWebAppScribeSignup(user);
    }

    static trackGoogleWebAppScribeSignup(user: ClientUser) {
        this.track(`sign_up_ai_with_webapp_google`, user);
        this.trackWebAppScribeSignup(user);
    }

    static trackWebAppScribeSignup(user: ClientUser) {
        this.track(`sign_up_ai_with_webapp`, user);
    }

    static trackGoogleCodeScribeUserAddedSpinach(user: ClientUser) {
        this.track(`site_google_user_added_meeting`, user);
        this.trackCompanyWebsiteScribeUserAddedSpinach(user);
    }

    static trackMicrosoftCompanyWebsiteScribeUserAddedSpinach(user: ClientUser) {
        this.track(`site_microsoft_user_added_meeting`, user);
        this.trackCompanyWebsiteScribeUserAddedSpinach(user);
    }

    static trackCompanyWebsiteScribeUserAddedSpinach(user: ClientUser) {
        this.track(`site_user_added_meeting`, user);
    }

    static trackTypeOfMeetingAdded(user: ClientUser, events: CalendarEvent[], onboardingVersion: string) {
        if (!events.length) {
            return;
        }

        // if we have more than one event, none of this setup matters
        const event = events[0];
        const meetingType = getMeetingTypeByTitleKeyword(event.summary || '');
        const attendeeCount = event.attendees?.length || 0;
        const isQuorum = attendeeCount > 1;
        const isRecurring = !!event.recurringEventId;
        const isStandup = meetingType === ScribeMeetingType.Standup;
        const isGeneric = meetingType === ScribeMeetingType.Generic;
        const isOther = !isStandup && !isGeneric;

        const isRecurringStandup = isStandup && isQuorum && isRecurring;
        const isRecurringGeneric = isGeneric && isQuorum && isRecurring;
        const isRecurringOther = isOther && isQuorum && isRecurring;

        const isOneOffStandup = isStandup && isQuorum && !isRecurring;
        const isOneOffGeneric = isGeneric && isQuorum && !isRecurring;
        const isOneOffOther = isOther && isQuorum && !isRecurring;

        const prefix = this.getOnboardingEventPrefix(onboardingVersion);

        if (events.length > 1) {
            this.track(`${prefix}multiple_meetings`, user);
        } else if (attendeeCount === 1 || attendeeCount === 0) {
            // shouldn't happen but in case it is 0, if they have access to it, it's at least 1 since they had access to it
            this.track(`${prefix}1p_meeting`, user);
        } else if (isRecurringStandup) {
            this.track(`${prefix}recur_standup`, user);
        } else if (isRecurringGeneric) {
            this.track(`${prefix}recur_generic`, user);
        } else if (isRecurringOther) {
            this.track(`${prefix}recur_other`, user);
        } else if (isOneOffStandup) {
            this.track(`${prefix}1off_standup`, user);
        } else if (isOneOffGeneric) {
            this.track(`${prefix}1off_generic`, user);
        } else if (isOneOffOther) {
            this.track(`${prefix}1off_other`, user);
        }
    }

    static getOnboardingEventPrefix(onboardingVersion: string): string {
        return `onb_${onboardingVersion}_`;
    }

    static destroy() {
        if (!isWebPlatform()) {
            return;
        }

        try {
            this.isInitialized = false;

            const scripts = document.getElementsByTagName('script');

            Array.from(scripts).forEach((script) => {
                if (
                    script.src.includes('snap.licdn') ||
                    script.textContent?.includes('facebook') ||
                    script.textContent?.includes('clearbitjs')
                ) {
                    document.body.removeChild(script);
                }
            });

            const noscripts = document.getElementsByTagName('noscript');

            Array.from(noscripts).forEach((noscript) => {
                if (noscript.textContent?.includes('facebook')) {
                    document.body.removeChild(noscript);
                }
            });
        } catch (error) {}
    }

    /** @NOTE - using the STAGE in the event name itself as it's easier for the users of GA to use, rather than metadata */
    private static getStageSuffix(): string {
        /** @NOTE - event names can only be so long, ensuring production is prod */
        const suffix = getStage().substring(0, 4);
        return `_${suffix}`;
    }

    private static track(eventName: string, user: ClientUser, addStageSuffixIfNeeded = true) {
        const shouldTrack = this.shouldTrack();

        if (!shouldTrack) {
            return;
        }

        const events = [eventName];

        const userTypeAffix = user.isPersonal ? UserTypeAffix.Personal : UserTypeAffix.Business;
        const isGoogleUser =
            user.metadata.creationSource?.includes('google') ||
            user.isAuthedForGoogleCalendar ||
            user.googleId ||
            eventName.includes('google');

        const isMicrosoftUser =
            user.metadata.creationSource?.includes('microsoft') ||
            user.isAuthedForMicrosoftCalendar ||
            user.microsoftId ||
            eventName.includes('microsoft');

        if (isGoogleUser) {
            const replaced = eventName.replace('_google_user', '').replace('_google', '').replace('meeting', 'mtg');
            events.push(`${replaced}_${userTypeAffix}_goog`);
        } else if (isMicrosoftUser) {
            const replaced = eventName
                .replace('_microsoft_user', '')
                .replace('_microsoft', '')
                .replace('meeting', 'mtg');
            events.push(`${replaced}_${userTypeAffix}_ms`);
        }

        const replaced = eventName.replace('meeting', 'mtg');
        events.push(`${replaced}_${userTypeAffix}`);

        for (const event of events) {
            const finalEventName = addStageSuffixIfNeeded ? `${event}${this.getStageSuffix()}` : event;
            try {
                // @ts-ignore
                window.dataLayer.push({
                    event: finalEventName,
                    // all tracking with tagmanager thus far has been related to onboarding on UI
                    step: 'welcome',
                    user_id: user.spinachUserId,
                });
            } catch (err) {}
        }
    }

    private static shouldTrack(): boolean {
        return this.isInitialized && isWebPlatform();
    }
}
