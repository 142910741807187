import MicrosoftWhiteSquares from '../../assets/microsoft-white-squares.svg';
import { BodyRegularOnboard } from '../../styles';
import { AuthorizeIntegrationButton } from './integrations';

export function MicrosoftLoginComponent({
    onClick,
    text,
    style = {},
    textStyle = {},
}: {
    onClick: () => void;
    text: string;
    style?: React.CSSProperties;
    textStyle?: React.CSSProperties;
}) {
    return (
        <AuthorizeIntegrationButton
            onClick={onClick}
            style={{
                justifyContent: 'center',
                fontWeight: 400,
                lineHeight: '20px',
                height: '30px',
                ...style,
            }}
        >
            <img style={{ height: '20px', marginRight: '10px' }} src={MicrosoftWhiteSquares} />
            <BodyRegularOnboard style={{ fontWeight: 600, color: 'white', ...textStyle }}>{text}</BodyRegularOnboard>
        </AuthorizeIntegrationButton>
    );
}
