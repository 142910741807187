import { useState } from 'react';
import { Socket } from 'socket.io-client';
import styled from 'styled-components';

import { customUpdateSectionTypeProps } from '@spinach-shared/constants';
import { YTBAgendaItemProps } from '@spinach-shared/models';
import { SpinachList, TimeInSeconds, UUID, UpdateSectionTypeProps } from '@spinach-shared/types';

import { ElementId } from '../../constants';
import { useGlobalLiveSeries, useLiveCustomUpdates, useLiveSyncedFocus } from '../../hooks';
import { LiveUpdateViewProps, TypedUpdates, UpdateSectionTitle } from './LiveUpdateSection';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 30px;
    flex-shrink: 0;
`;

export function CustomUpdates({
    socket,
    agendaItem,
    isPresentingSection = false,
    isInNavDrawer,
    listIdsToHide = [],
}: {
    socket: Socket;
    agendaItem: YTBAgendaItemProps;
    isPresentingSection?: boolean;
    isInNavDrawer?: boolean;
    listIdsToHide?: UUID[];
}): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();

    return (
        <>
            {liveSeries.enabledCustomRoundtableLists
                .filter((list) => !listIdsToHide?.includes(list.id))
                .map((list) => {
                    const isSectionEmpty = agendaItem.standUpUpdate.getCustomUpdatesForList(list.id).length === 0;
                    const shouldHideEmptyPresentingSection = isPresentingSection && isSectionEmpty;

                    if (shouldHideEmptyPresentingSection) {
                        return <></>;
                    }

                    return (
                        <CustomLiveUpdateSection
                            isInNavDrawer={isInNavDrawer}
                            key={list.id}
                            list={list}
                            socket={socket}
                            agendaItem={agendaItem}
                        />
                    );
                })}
        </>
    );
}

export type LiveCustomUpdateProps = {
    typeProps: UpdateSectionTypeProps;
    isInNavDrawer?: boolean;
    autoFocusNewInputOnMountAfter?: TimeInSeconds;
    id?: ElementId;
    socket: Socket;
    agendaItem: YTBAgendaItemProps;
    customListId: string;
};

export function LiveCustomUpdateSection({
    typeProps,
    autoFocusNewInputOnMountAfter,
    id,
    isInNavDrawer,
    customListId,
    socket,
    agendaItem,
}: LiveCustomUpdateProps): JSX.Element {
    const [focusedInputIndex, setFocusedInputIndex] = useState<number | null>(null);
    const { customUpdates, setCustomUpdates, createFullUpdateEmitter, createReorderEmitter, createUpdateEmitter } =
        useLiveCustomUpdates(socket, customListId, agendaItem.id);

    const updateViewProps: LiveUpdateViewProps = {
        createFullUpdateEmitter,
        createUpdateEmitter,
        createReorderEmitter,
        standUpUpdate: agendaItem.standUpUpdate,
    };

    useLiveSyncedFocus(
        customUpdates,
        focusedInputIndex,
        setFocusedInputIndex,
        updateViewProps.standUpUpdate.getCustomUpdatesForList(customListId)
    );

    return (
        <Container id={id}>
            <UpdateSectionTitle typeProps={typeProps} />

            <TypedUpdates
                typedUpdates={customUpdates}
                setTypedUpdates={setCustomUpdates}
                typeProps={typeProps}
                isInNavDrawer={isInNavDrawer}
                updateViewProps={updateViewProps}
                focusedInputIndex={focusedInputIndex}
                setFocusedInputIndex={setFocusedInputIndex}
                autoFocusNewInputOnMountAfter={autoFocusNewInputOnMountAfter}
                customListId={customListId}
            />
        </Container>
    );
}

export function CustomLiveUpdateSection({
    list,
    socket,
    agendaItem,
    isInNavDrawer,
}: {
    list: SpinachList;
    socket: Socket;
    agendaItem: YTBAgendaItemProps;
    isInNavDrawer?: boolean;
}): JSX.Element {
    const typeProps: UpdateSectionTypeProps = {
        ...customUpdateSectionTypeProps,
        title: list.title,
        customListId: list.id,
        placeholder: list.placeholder ?? customUpdateSectionTypeProps.placeholder,
    };

    return (
        <LiveCustomUpdateSection
            isInNavDrawer={isInNavDrawer}
            socket={socket}
            customListId={list.id}
            typeProps={typeProps}
            agendaItem={agendaItem}
        />
    );
}
