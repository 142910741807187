import { useState } from 'react';

import { Ticket } from '@spinach-shared/types';

import { AsanaPickerProps } from '../../types';
import { AsanaPickerModal } from './AsanaPickerModal';

export function AsanaPicker({
    typedUpdate,
    saveFullTypedUpdate,
    isOpen,
    setIsOpen,
    createUpdateEmitter,
}: AsanaPickerProps) {
    const [searchedAsanaIssue, setSearchedAsanaIssue] = useState<Ticket | null>(null);

    return (
        <>
            {isOpen && (
                <AsanaPickerModal
                    createUpdateEmitter={createUpdateEmitter}
                    isModalOpen={isOpen}
                    onClose={() => {
                        setIsOpen(false);
                        setSearchedAsanaIssue(null);
                    }}
                    saveFullTypedUpdate={saveFullTypedUpdate}
                    searchedAsanaIssue={searchedAsanaIssue}
                    setSearchedAsanaIssue={setSearchedAsanaIssue}
                    typedUpdate={typedUpdate}
                />
            )}
        </>
    );
}
