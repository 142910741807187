export const hasConfluenceSiteAndSpaceData = (url: string): boolean => {
    const regexForConfluenceSiteAndSpaceData = new RegExp(
        /(https:\/\/.*\.atlassian.net)(.+?)(?<=spaces\/)(.+?(?=\/|$))/gm
    );
    const testResults = regexForConfluenceSiteAndSpaceData.test(url);
    return testResults;
};

export const getConfluenceSiteAndSpaceData = (
    url: string
): { atlassianSite?: string; confluenceSpaceData?: string } => {
    const regexForConfluenceSiteAndSpaceData = new RegExp(
        /(https:\/\/.*\.atlassian.net)(.+?)(?<=spaces\/)(.+?(?=\/|$))/gm
    );
    const matchedGroups = url.split(regexForConfluenceSiteAndSpaceData);
    if (!matchedGroups || matchedGroups.length < 4) {
        return {};
    }
    const atlassianSite = matchedGroups[1];
    const spaceData = matchedGroups[3];

    return { atlassianSite, confluenceSpaceData: spaceData };
};
