import { CreateTicketRequest, DetailedTicket, SpinachAPIPath, TicketSource } from '@spinach-shared/types';

import { postSpinachAPI } from './postSpinachAPI';

export async function postCreateTicket(payload: CreateTicketRequest, ticketSource?: TicketSource) {
    if (ticketSource) {
        return await postSpinachAPI<DetailedTicket>(
            SpinachAPIPath.CreateTicketForSource.replaceAll(':ticketSource', ticketSource),
            payload
        );
    } else {
        return await postSpinachAPI<DetailedTicket>(SpinachAPIPath.CreateTicket, payload);
    }
}
