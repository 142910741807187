import { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { PrimaryButtonLabel } from '../..';
import { ButtonProps } from '../../types/StandUp';
import { isSubmitKey } from '../../utils/dom';
import { LongPressButton } from './Shared';

const rippleSize = 40;
const rippleKeyframe = () => keyframes`
    to {
        right: 0px;
        opacity: 0.5;
    }
`;
export const Ripple = styled.span<{ fixedWidth: number; pressTime: number }>`
    position: absolute;
    top: 0px;
    width: ${(props) => props.fixedWidth * 2 + 'px'};
    height: ${rippleSize + 'px'};
    border-radius: ${rippleSize / 2 + 'px'};
    right: ${(props) => props.fixedWidth + 'px'};
    animation: ${(props) =>
        css`
            ${rippleKeyframe()} ${props.pressTime}ms linear
        `};
    background-color: rgba(255, 255, 255, 1);
    opacity: 0.3;
`;

type LongPressPrimaryButtonProps = ButtonProps & {
    pressTitle: string;
    fixedWidth: number;
    pressTime?: number;
};

const ToolTipTrigger = styled.div`
    position: relative;
`;

const ToolTip = styled.div`
    position: absolute;
    top: -20px;
    text-align: center;
    width: 100%;
    font-size: 11px;
    color: ${(props) => props.theme.neutrals.midnight};
    font-weight: bold;
`;

export function LongPressPrimaryButton({
    id,
    title,
    pressTitle,
    fixedWidth,
    pressTime = 1000, //ms
    onClick,
    disabled = false,
    fadeInDuration,
    endIcon,
    ...rest
}: LongPressPrimaryButtonProps): JSX.Element {
    const [timer, setTimer] = useState<ReturnType<typeof window.setTimeout>>();
    const [isPressing, setIsPressing] = useState(false);
    const [showHint, setShowHint] = useState(false);

    const start = (e: any) => {
        const newTimer = setTimeout(() => {
            onClick(e);
            setIsPressing(false);
        }, pressTime);

        if (timer) {
            clearTimeout(timer);
        }

        setTimer(newTimer);
        setIsPressing(true);
    };

    const cancel = () => {
        clearTimeout(timer as ReturnType<typeof window.setTimeout>);
        setIsPressing(false);
    };

    return (
        <ToolTipTrigger
            onFocus={() => setShowHint(true)}
            onBlur={() => setShowHint(false)}
            onMouseEnter={() => setShowHint(true)}
            onMouseLeave={() => setShowHint(false)}
        >
            {showHint && <ToolTip>(Press and hold)</ToolTip>}
            <LongPressButton
                id={id}
                fixedWidth={fixedWidth}
                disabled={disabled}
                fadeInDuration={fadeInDuration}
                {...rest}
                onMouseDown={start}
                onMouseUp={cancel}
                onTouchStart={start}
                onTouchEnd={cancel}
                onKeyPress={(e) => {
                    if (isSubmitKey(e.key) && !isPressing) {
                        start(e);
                    }
                }}
                onKeyUp={(e) => {
                    if (isSubmitKey(e.key)) {
                        cancel();
                    }
                }}
            >
                {isPressing && <Ripple fixedWidth={fixedWidth} pressTime={pressTime} />}
                <PrimaryButtonLabel>
                    <>
                        {isPressing ? pressTitle : title}
                        {endIcon}
                    </>
                </PrimaryButtonLabel>
            </LongPressButton>
        </ToolTipTrigger>
    );
}
