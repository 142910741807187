import React, { useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { DEMO_GUIDE_SEGMENTS, PERSONA_DEMO_GUIDE_SEGMENTS } from '@spinach-shared/constants';
import { ClientEventType, StandUpStatus } from '@spinach-shared/types';

import ChevronRight from '../../assets/demo/chevron-right.svg';
import { GlobalModal } from '../../atoms';
import { ElementId } from '../../constants';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalDemoState,
    useGlobalLiveSeries,
    useGlobalModal,
    usePersonaDemo,
    useSeriesReality,
    useWindowSize,
} from '../../hooks';
import { HeaderThreeOnboard, lightTheme, responsiveness } from '../../styles';
import { Row } from '../common';
import { OutlinedButton } from '../stand-up/OutlinedButton';
import { DemoBannerGuideContainer } from './DemoGuides';

const Banner = styled.div<{ isDemoEnabled: boolean; isGoingToNextMeeting?: boolean }>`
    padding-top: 15px;
    padding-bottom: 15px;
    height: 15px;
    position: absolute;
    z-index: 100;
    width: 100%;
    flex-shrink: 0;
    background-color: ${(props) => (props.isDemoEnabled ? props.theme.neutrals.white : props.theme.secondary.midnight)};
    color: ${(props) => (props.isDemoEnabled ? props.theme.secondary.midnight : props.theme.neutrals.white)};
    display: flex;

    transition: 750ms;

    @media ${responsiveness.thinnerThanSM} {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    @media ${responsiveness.thinnerThanXS} {
        padding-top: 8px;
        padding-bottom: 8px;
        width: 100%;
    }
`;

const ResponsiveSignupButton = styled(OutlinedButton)`
    transform: scale(0.75);
    width: 115px;
    position: absolute;
    right: 0;
    top: 1px;

    @media ${responsiveness.thinnerThanMD} {
        top: unset;
        right: -35px;
    }

    @media ${responsiveness.thinnerThanSM} {
        top: unset;
        right: -115px;
    }
`;

const NavToolTipLabel = styled(HeaderThreeOnboard)`
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px;
    width: 240px;
    color: ${(props) => props.theme.primary.green};
`;

const ProgressBar = styled.span<{ widthPercentage: number; color: string }>`
    width: ${(props) => props.widthPercentage}%;
    height: 5px;
    border-radius: 5px;
    background-color: ${(props) => props.color ?? props.theme.tertiary.midnight};
    position: relative;
`;

const fadeIn = keyframes`
    0% {
        opacity: 0.2;
    }
    100% {
        opacity: 1
    }
`;

const fadeInAnimation = () => css`
    animation: ${fadeIn};
    animation-duration: 300ms;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
`;

const TooltipTip = styled.div`
    position: absolute;
    border-radius: 8px;
    left: 40px;
    transform: translateX(-50%);
    padding: 15px;

    color: black;
    background: white;
    font-size: 20px;
    font-family: Gilroy sans-serif;
    line-height: 1;
    z-index: 100;
    top: 40px;

    text-align: left;
    box-shadow: 3px 3px 1px 1px rgba(0, 0, 0, 0.15);

    ${fadeInAnimation()};

    /* CSS border triangles */
    &:before {
        content: ' ';
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: 15px;
        margin-left: calc(15px * -1);
        bottom: 100%;
        border-bottom-color: white;
    }
`;

function StepProgressBars({
    stepCount,
    stepStartIndex,
    currentGuideIndex,
}: {
    stepCount: number;
    stepStartIndex: number;
    currentGuideIndex: number;
}): JSX.Element {
    /** Seek to use live series for even hasClickedPrevCheckIn determination */
    const { isGuideOpen } = useGlobalDemoState();
    const isPersonaDemo = usePersonaDemo();

    const widthPercentage = 100 / stepCount - 2;
    const offColor = lightTheme.tertiary.midnight;
    const onColor = lightTheme.primary.green;

    return (
        <Row style={{ justifyContent: 'space-around', marginTop: '2px' }}>
            {Array.from({ length: stepCount }, (v, i) => {
                // if upcoming, gray, if on or done, green
                const adjustedIndex = stepStartIndex + i;
                const color = adjustedIndex <= currentGuideIndex ? onColor : offColor;
                return (
                    <ProgressBar widthPercentage={widthPercentage} color={color}>
                        {adjustedIndex === currentGuideIndex && isGuideOpen && !isPersonaDemo ? (
                            <TooltipTip>
                                <div style={{ width: '250px', margin: '4px' }}>
                                    <DemoBannerGuideContainer />
                                </div>
                            </TooltipTip>
                        ) : null}
                    </ProgressBar>
                );
            })}
        </Row>
    );
}

const BannerStepperContainer = styled(Row)`
    justify-content: center;
    align-items: center;
`;

const TooltipWrapper = styled.div`
    display: inline-block;
    position: relative;
`;

const WIDTH_TO_HIDE_STEPS = 500;

function DemoNavigation(): JSX.Element {
    const isPersonaDemo = usePersonaDemo();

    if (isPersonaDemo) {
        return <PersonaDemoStepper />;
    }

    return <DemoStepper />;
}

function PersonaDemoStepper(): JSX.Element {
    const { demoPrefaceView, spinachWindowOverlay } = useGlobalDemoState();
    const { isDemoSeries } = useSeriesReality();
    const { width } = useWindowSize();
    const { isGuideOpen, setIsGuideOpen } = useGlobalDemoState();
    const [liveSeries] = useGlobalLiveSeries();
    const { currentPersonaDemoGuide } = liveSeries;

    useEffect(() => {
        if (!isGuideOpen) {
            setIsGuideOpen(true);
        }
    }, [currentPersonaDemoGuide]);

    const currentGuideIndex =
        demoPrefaceView !== null || spinachWindowOverlay !== null ? 0 : liveSeries.spinachParticipant.isReady ? 2 : 1;

    const shouldShowCurrentSegmentOnly = isDemoSeries && width < WIDTH_TO_HIDE_STEPS;

    let stepStartIndex = 0;

    return (
        <BannerStepperContainer>
            {PERSONA_DEMO_GUIDE_SEGMENTS.map(({ label, steps }, index) => {
                if (index !== 0) {
                    stepStartIndex += PERSONA_DEMO_GUIDE_SEGMENTS[index - 1].steps;
                }

                if (shouldShowCurrentSegmentOnly) {
                    const stepsForThisSegment = Array.from({ length: steps }, (v, i) => {
                        return i + stepStartIndex;
                    });

                    if (!stepsForThisSegment.includes(currentGuideIndex)) {
                        return <></>;
                    }
                }

                return (
                    <React.Fragment key={label}>
                        <TooltipWrapper>
                            <NavToolTipLabel style={{ width: '70px' }}>
                                {label}

                                <StepProgressBars
                                    stepCount={steps}
                                    stepStartIndex={stepStartIndex}
                                    currentGuideIndex={currentGuideIndex}
                                />
                            </NavToolTipLabel>
                        </TooltipWrapper>

                        {index !== PERSONA_DEMO_GUIDE_SEGMENTS.length - 1 ? (
                            <img style={{ height: '20px' }} src={ChevronRight} />
                        ) : null}
                    </React.Fragment>
                );
            })}
        </BannerStepperContainer>
    );
}

function DemoStepper(): JSX.Element {
    const { isGuideOpen, setIsGuideOpen, currentGuideIndex, currentGuide } = useGlobalDemoState();
    const track = useExperienceTracking();
    const { isDemoSeries } = useSeriesReality();
    const { width } = useWindowSize();

    // re-open guide upon guide change if last one was manually closed
    useEffect(() => {
        if (!isGuideOpen) {
            setIsGuideOpen(true);
        }
    }, [currentGuideIndex]);

    function revealGuide() {
        if (!isGuideOpen) {
            track(ClientEventType.UserHoveredDemoStepAndRevealedGuide, { Guide: currentGuide });
            setIsGuideOpen(true);
        }
    }

    const shouldShowCurrentSegmentOnly = isDemoSeries && width < WIDTH_TO_HIDE_STEPS;

    let stepStartIndex = 0;

    return (
        <BannerStepperContainer>
            {DEMO_GUIDE_SEGMENTS.map(({ label, steps }, index) => {
                if (index !== 0) {
                    stepStartIndex += DEMO_GUIDE_SEGMENTS[index - 1].steps;
                }

                if (shouldShowCurrentSegmentOnly) {
                    const stepsForThisSegment = Array.from({ length: steps }, (v, i) => {
                        return i + stepStartIndex;
                    });

                    if (!stepsForThisSegment.includes(currentGuideIndex)) {
                        return <></>;
                    }
                }

                return (
                    <React.Fragment key={label}>
                        <TooltipWrapper>
                            <NavToolTipLabel onMouseEnter={revealGuide}>
                                {label}

                                <StepProgressBars
                                    stepCount={steps}
                                    stepStartIndex={stepStartIndex}
                                    currentGuideIndex={currentGuideIndex}
                                />
                            </NavToolTipLabel>
                        </TooltipWrapper>

                        {index !== DEMO_GUIDE_SEGMENTS.length - 1 ? (
                            <img style={{ height: '20px' }} src={ChevronRight} />
                        ) : null}
                    </React.Fragment>
                );
            })}
        </BannerStepperContainer>
    );
}

const BannerContentContainer = styled(Row)<{ isDemoSeries: boolean; isVideoHidden: boolean }>`
    height: 100%;
    align-items: center;
    justify-content: ${(props) => (props.isVideoHidden && props.isDemoSeries ? 'flex-start' : 'center')};
    width: 100%;

    @media ${responsiveness.thinnerThanMD} {
        transform: scale(0.85);
    }

    @media ${responsiveness.thinnerThanSM} {
        transform: scale(0.65);
    }
`;

export function DemoBanner(): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();
    const { isDemoSeries: isDemoEnabled } = useSeriesReality();
    const [, setOpenModal] = useGlobalModal();
    const [user] = useGlobalAuthedUser();
    const isBannerVisible = isDemoEnabled;
    const track = useExperienceTracking();

    if (!isBannerVisible) {
        return <></>;
    }

    const shouldHideVideoDuringDemo = liveSeries.spinachParticipant.standUpStatus !== StandUpStatus.Ready;
    const bailFromDemoButtonText = user.isDemoing ? 'Skip' : 'Leave';

    return (
        <Banner isDemoEnabled={isDemoEnabled} id={ElementId.DemoBanner}>
            <BannerContentContainer isDemoSeries={isDemoEnabled} isVideoHidden={shouldHideVideoDuringDemo}>
                {user.metadata.isAnonymousUser ? (
                    <></>
                ) : (
                    <ResponsiveSignupButton
                        title={bailFromDemoButtonText}
                        style={{
                            width: 'unset',
                        }}
                        onClick={() => {
                            track(ClientEventType.ToggleDemoModeClicked, { ToggleAction: 'off' });
                            setOpenModal(GlobalModal.ConfirmLeaveDemo);
                        }}
                    />
                )}

                <DemoNavigation />

                {user.metadata.isAnonymousUser ? (
                    <ResponsiveSignupButton
                        title={'Sign up'}
                        onClick={() => {
                            setOpenModal(GlobalModal.VerifyEmail);
                        }}
                    />
                ) : null}
            </BannerContentContainer>
        </Banner>
    );
}
