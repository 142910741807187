import { useEffect } from 'react';

import { ClientEventType } from '@spinach-shared/types';

import { getStoredSeries, getUser } from '../../../apis';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalModal, useGlobalStoredSeries } from '../../../hooks';
import { ResponsiveModalTitle } from '../../../styles';
import { ScrollArea } from '../../stand-up';
import { Direction, SlidingColumn } from '../SlidingColumn';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Hairline, Row, Spacing } from '../framing';
import { AppIntegrations } from './AppIntegrations';

export function useLatestStoredSeriesOnMount() {
    const [storedSeries, setStoredSeries] = useGlobalStoredSeries();

    useEffect(() => {
        async function fetch() {
            const latestStoredSeries = await getStoredSeries(storedSeries.slug);
            if (latestStoredSeries) {
                setStoredSeries(latestStoredSeries);
            }
        }
        fetch();
    }, []);
}

export function useLatestAuthedUserOnMount() {
    const [, setUser] = useGlobalAuthedUser();

    useEffect(() => {
        async function fetch() {
            const userResponse = await getUser();
            if (userResponse.user) {
                setUser(userResponse.user);
            }
        }
        fetch();
    }, []);
}

export function TopLevelIntegrationSettings({ direction }: { direction: Direction | undefined }): JSX.Element {
    const [, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();

    // Ensure that other folks' changes are represented when I open up integration settings
    useLatestStoredSeriesOnMount();
    useLatestAuthedUserOnMount();

    function onClose() {
        setGlobalModal(null);
        track(ClientEventType.CloseIntegrationsModalClick);
        // @TODO
        // track(ClientEventType.SeriesSettingsClosedWithoutSaving);
    }

    return (
        <SpinachModalContent
            onClose={onClose}
            style={{
                maxWidth: '80%',
                padding: '20px',
                minHeight: 'unset',
                overflowY: 'unset',
            }}
        >
            <SlidingColumn centered direction={direction}>
                <Column>
                    <Row centered>
                        <ResponsiveModalTitle>
                            <b>App Integrations</b>
                        </ResponsiveModalTitle>
                    </Row>

                    <Hairline />

                    <Spacing factor={1 / 2} />

                    <ScrollArea style={{ height: '395px' }} sidePadding={10}>
                        <AppIntegrations />
                    </ScrollArea>
                </Column>
            </SlidingColumn>
        </SpinachModalContent>
    );
}
