import { useEffect } from 'react';

import { ServerSocketEvent, VideoAgentControlCommand, VideoAgentUpdatedResponse } from '@spinach-shared/types';
import { isLocalStage } from '@spinach-shared/utils';

import { patchVideoAgentSession } from '../../apis/video-agent/patchVideoAgentSession';
import { useGlobalNullableVideoAgentSocket } from '../useGlobalSocket';
import { useGlobalNullableVideoAgent } from './useGlobalVideoAgent';

export function useVideoAgentSocketSyncing(onControlCommand?: (msg: VideoAgentControlCommand) => void) {
    const { session, setSession } = useGlobalNullableVideoAgent();
    const [socket] = useGlobalNullableVideoAgentSocket();

    useEffect(() => {
        const callback = (response: VideoAgentUpdatedResponse) => {
            setSession(response.session);
        };

        if (isLocalStage()) {
            // Listen for postMessage events in local stage
            const messageHandler = (event: MessageEvent) => {
                if (event.data.type === 'local.VideoAgentControlCommand' && onControlCommand) {
                    onControlCommand(event.data.command);
                } else if (event.data.type === 'local.request_sync' && session) {
                    patchVideoAgentSession(session.toJSON());
                }
            };
            window.addEventListener('message', messageHandler);

            return () => {
                window.removeEventListener('message', messageHandler);
            };
        }

        socket?.on(ServerSocketEvent.VideoAgentUpdated, callback);
        if (onControlCommand) {
            socket?.on(ServerSocketEvent.VideoAgentControlled, onControlCommand);
        }

        return () => {
            socket?.off(ServerSocketEvent.VideoAgentUpdated, callback);
            if (onControlCommand) {
                socket?.off(ServerSocketEvent.VideoAgentControlled, onControlCommand);
            }
        };
    }, [socket, setSession, onControlCommand]);
}
