import { useMemo, useState } from 'react';
import { Socket } from 'socket.io-client';

import { BaseAgendaProps, YTBAgendaItemProps } from '@spinach-shared/models';
import {
    ClientSocketEvent,
    SpinachUpdateType,
    TypedUpdate,
    YTBHistoryUpdateRequest,
    YTBUpdateUpdatedRequest,
} from '@spinach-shared/types';

import { FullUpdateEmitter, SetTypedUpdatesMap, TypedUpdatesMap } from '../types';
import { createWebsocketPayload } from '../utils/analytics';
import { useCombinedUpdates } from './useCombinedUpdates';
import { useGlobalAuthedUser } from './useGlobalUser';
import { useGlobalLiveSeries } from './useLiveSeries';

type UseLiveUpdateSectionProps = {
    createUpdateEmitter: (update: TypedUpdate) => (text: string) => void;
    createFullUpdateEmitter: FullUpdateEmitter;
    createReorderEmitter: (update: TypedUpdate) => (moveToIndex: number) => void;
    onToggleConfirmation: (update: TypedUpdate) => void;
    typedUpdatesMap: TypedUpdatesMap; //{ [key in SpinachUpdateType]: TypedUpdate[] };
    setTypedUpdatesMap: SetTypedUpdatesMap; //{ [key in SpinachUpdateType]: SetValue<TypedUpdate[]> };
};

// TODO: migrate away from this expensive hook in favor of useLiveReservedUpdates
export function useLiveUpdateCheckInSection(
    agendaItem: YTBAgendaItemProps,
    socket: Socket,
    seriesId: string,
    agenda: BaseAgendaProps,
    incrementUpdateCount?: (updateType: SpinachUpdateType) => void
): UseLiveUpdateSectionProps {
    const [user] = useGlobalAuthedUser();
    const userIdentity = user.toUserIdentity();
    const [liveSeries] = useGlobalLiveSeries();
    const { standUpUpdate } = agendaItem;
    const updatesByType = useMemo(() => standUpUpdate.updatesByReservedTypes, [standUpUpdate]);
    const sharedUpdateTypes = useMemo(() => agenda.topicUpdates, [agenda.topicUpdates]);

    const savedYesterdays = updatesByType[SpinachUpdateType.Yesterday];
    const savedTodays = updatesByType[SpinachUpdateType.Today];
    const savedChallenges = updatesByType[SpinachUpdateType.Challenge];
    const savedTeamTopics = updatesByType[SpinachUpdateType.ParkingLot];
    const savedDetails = updatesByType[SpinachUpdateType.Notes];
    const savedTopics = sharedUpdateTypes;
    const savedActionItems = updatesByType[SpinachUpdateType.ActionItems];
    const savedCustomItems = updatesByType[SpinachUpdateType.Custom];

    const [yesterdaysHighlights, setYesterdaysHighlights] = useState<TypedUpdate[]>(savedYesterdays);
    const [todays, setTodays] = useState<TypedUpdate[]>(savedTodays);
    const [challenges, setChallenges] = useState<TypedUpdate[]>(savedChallenges);
    const [parkingLotItems, setParkingLotItems] = useState<TypedUpdate[]>(savedTeamTopics);
    const [details, setDetails] = useState<TypedUpdate[]>(savedDetails);
    const [topics, setTopics] = useState<TypedUpdate[]>(savedTopics);
    const [actionItems, setActionItems] = useState<TypedUpdate[]>(savedActionItems);

    const [customItems, setCustomItems] = useState<TypedUpdate[]>(savedCustomItems);

    const typedUpdatesMap: TypedUpdatesMap = {
        [SpinachUpdateType.Yesterday]: yesterdaysHighlights,
        [SpinachUpdateType.Today]: todays,
        [SpinachUpdateType.Challenge]: challenges,
        [SpinachUpdateType.ParkingLot]: parkingLotItems,
        [SpinachUpdateType.Notes]: details,
        [SpinachUpdateType.Topic]: topics,
        [SpinachUpdateType.ActionItems]: actionItems,
        [SpinachUpdateType.Custom]: customItems,
        [SpinachUpdateType.Icebreaker]: [],
    };

    const setTypedUpdatesMap: SetTypedUpdatesMap = {
        [SpinachUpdateType.Yesterday]: setYesterdaysHighlights,
        [SpinachUpdateType.Today]: setTodays,
        [SpinachUpdateType.Challenge]: setChallenges,
        [SpinachUpdateType.ParkingLot]: setParkingLotItems,
        [SpinachUpdateType.Notes]: setDetails,
        [SpinachUpdateType.Topic]: setTopics,
        [SpinachUpdateType.ActionItems]: setActionItems,
        [SpinachUpdateType.Custom]: setCustomItems,
        [SpinachUpdateType.Icebreaker]: () => {},
    };

    useCombinedUpdates(savedYesterdays, yesterdaysHighlights, setYesterdaysHighlights, standUpUpdate);
    useCombinedUpdates(savedTodays, todays, setTodays, standUpUpdate);
    useCombinedUpdates(savedChallenges, challenges, setChallenges, standUpUpdate);
    useCombinedUpdates(savedTeamTopics, parkingLotItems, setParkingLotItems, standUpUpdate);
    useCombinedUpdates(savedDetails, details, setDetails, standUpUpdate);
    useCombinedUpdates(savedTopics, topics, setTopics, standUpUpdate);
    useCombinedUpdates(savedActionItems, actionItems, setActionItems, standUpUpdate);
    useCombinedUpdates(savedCustomItems, customItems, setCustomItems, standUpUpdate);

    const participantId = agendaItem.id;
    const { spinachUserId } = user;

    const createUpdateEmitter = (update: TypedUpdate) => (text: string) => {
        incrementUpdateCount?.(update.updateType);
        const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
            ...userIdentity,
            seriesSlug: seriesId,
            spinachUserId,
            participantId,
            meetingId: liveSeries.currentMeeting.id,
            update: {
                ...update,
                text,
            },
        });
        socket.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
    };

    const createFullUpdateEmitter = (update: TypedUpdate) => (updated: TypedUpdate, updates?: TypedUpdate[]) => {
        incrementUpdateCount?.(update.updateType);
        const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
            ...userIdentity,
            seriesSlug: seriesId,
            spinachUserId,
            participantId,
            meetingId: liveSeries.currentMeeting.id,
            updates,
            update: {
                ...update,
                ...updated,
            },
        });
        socket.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
    };

    const createReorderEmitter = (update: TypedUpdate) => (moveToIndex: number) => {
        const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
            ...userIdentity,
            seriesSlug: seriesId,
            spinachUserId,
            participantId,
            meetingId: liveSeries.currentMeeting.id,
            update,
            moveToIndex,
        });
        socket.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
    };

    const onToggleConfirmation = (update: TypedUpdate) => {
        const payload = createWebsocketPayload<YTBHistoryUpdateRequest>({
            seriesId,
            seriesSlug: seriesId,
            spinachUserId,
            meetingId: liveSeries.currentMeeting.id,
            update,
        });
        socket.emit(ClientSocketEvent.YTBHistoryConfirming, payload);
    };

    return {
        createUpdateEmitter,
        createFullUpdateEmitter,
        createReorderEmitter,
        onToggleConfirmation,
        typedUpdatesMap,
        setTypedUpdatesMap,
    };
}
