import { Home } from '@material-ui/icons';
import styled from 'styled-components';

import { BodySubtitle, lightTheme } from '../../styles';
import { withAccessibleSubmitProps } from '../../utils';
import { Column } from '../common';
import { CreateCard } from './common';

const AddSeriesCardColumn = styled(Column)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export function AIDashboardCard({ onClick }: { onClick: () => void }): JSX.Element {
    return (
        <CreateCard
            style={{ borderStyle: 'dotted', borderColor: lightTheme.neutrals.gray }}
            {...withAccessibleSubmitProps(onClick)}
        >
            <AddSeriesCardColumn>
                <Home htmlColor={lightTheme.primary.greenLight} />
                <BodySubtitle style={{ fontSize: '14px', color: lightTheme.primary.greenLight }}>
                    Go to Home
                </BodySubtitle>
            </AddSeriesCardColumn>
        </CreateCard>
    );
}
