import { JiraSearchOpts, SpinachAPIPath, Ticket } from '@spinach-shared/types';

import { postSpinachAPI } from '../postSpinachAPI';

export const searchJiraIssues = async (opts?: JiraSearchOpts) => {
    const response = await postSpinachAPI<{ issues: Ticket[] }, JiraSearchOpts>(
        SpinachAPIPath.JiraIssueSearch,
        opts ?? {}
    );

    if (!response?.issues || !response.issues.length || !response.issues[0].id) {
        return [];
    }

    return response.issues;
};
