import React from 'react';

export function isSubmitKey(key: string): boolean {
    return key === 'Enter' || key === ' ';
}

export function withAccessibleSubmitProps(
    handler: (event: React.MouseEvent | React.KeyboardEvent) => void,
    tabIndex = 0
): { onClick: React.MouseEventHandler; onKeyPress: React.KeyboardEventHandler; tabIndex: number | undefined } {
    return {
        tabIndex,
        onClick: (e) => {
            handler(e);
        },
        onKeyPress: (e) => {
            if (isSubmitKey(e.key)) {
                handler(e);
            }
        },
    };
}

// TODO: consolidate with ClientPath.Demo
export const DEMO_PATH = '/demo';

/**
 *
 * @returns a stateless indication of whether the app is on demo mode or not.
 *
 * Should be used only when the `useSeriesReality` hook cannot be (ie outside a React Function Component)
 */
export function isOnDemoRoute(): boolean {
    return window.location.pathname.includes(DEMO_PATH);
}
