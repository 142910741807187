import { BaseWebSocketPayload } from '@spinach-shared/types';

import { getAppVersion } from './appVersion';
import { isOnDemoRoute } from './dom';
import { getClientPlatform } from './platform';

export const createWebsocketPayload = <T extends BaseWebSocketPayload>(
    request: Omit<T, 'platform' | 'isDemoMode' | 'appVersion'>
): Omit<T, 'platform' | 'isDemoMode' | 'appVersion'> & BaseWebSocketPayload => {
    return {
        platform: getClientPlatform(),
        isDemoMode: isOnDemoRoute(),
        appVersion: getAppVersion(),
        ...request,
    };
};
