import { SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function getMedia({
    botId,
}: {
    botId: string;
}): Promise<{ recordingUrl: string | null; participantsNames: string[] } | undefined> {
    const response = await getSpinachAPI<{ recordingUrl: string | null; participantsNames: string[] }>(
        SpinachAPIPath.Media,
        {
            params: {
                botId,
            },
        }
    );
    return response;
}
