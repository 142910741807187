import { Modal } from '@material-ui/core';

// no typsecript support

/* tslint:enable:no-var-requires */
import { Ticket } from '@spinach-shared/types';

import { HeaderThree } from '../../styles';
import { SpinachModalContent } from '../common';
import { AsanaDetails } from './AsanaPreviewContainer';

interface AsanaPreviewContainerProps {
    issueData: Ticket;
    isOpen: boolean;
    onClose: () => void;
}

export const AsanaDetailsModal = ({ issueData, isOpen, onClose }: AsanaPreviewContainerProps) => {
    return (
        <Modal open={isOpen} onClose={onClose}>
            <SpinachModalContent
                style={{ borderRadius: '30px', boxShadow: '0px 0px 0px 8px rgba(0, 0, 0, .25' }}
                onClose={onClose}
            >
                <HeaderThree>Ticket details</HeaderThree>
                <AsanaDetails issueData={issueData} />
            </SpinachModalContent>
        </Modal>
    );
};
