import { useEffect, useState } from 'react';
import React from 'react';

import { FeatureToggle } from '@spinach-shared/types';

import { useGlobalNullableVideoAgent } from '..';
import { postAnonymousFeatureFlag } from '../../apis';
import { RemoteVideoAgentConfigSchema } from '../../atoms';

export const useFetchVideoAgentConfig = (hostId: string | undefined) => {
    const {
        setState,
        session,
        state: { config },
    } = useGlobalNullableVideoAgent();
    const [hasFetched, setHasFetched] = useState(false);

    useEffect(() => {
        async function fetch() {
            const appliedConfig = RemoteVideoAgentConfigSchema.parse(
                await postAnonymousFeatureFlag(FeatureToggle.VideoAgentConfig, {}, undefined, hostId, {
                    seriesId: session?.raw.seriesId,
                    botId: session?.raw.botId,
                })
            );

            setHasFetched(true);
            setState((prev) => ({
                ...prev,
                config: appliedConfig,
            }));
        }

        if (hostId && !hasFetched) {
            fetch();
        }
    }, [hasFetched, hostId, session?.raw.botId, session?.raw.seriesId, setState]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => config, [JSON.stringify(config)]);
};
