import { SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from './postSpinachAPI';

export async function postLogOut(): Promise<{ success: boolean; reason?: string }> {
    try {
        const response = await postSpinachAPI<{ success: boolean; reason?: string }>(SpinachAPIPath.LogOut, {});
        if (!response) {
            return { success: false };
        }
        return response;
    } catch (error) {
        return { success: false };
    }
}
