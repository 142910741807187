import React from 'react';

interface DiagonalBoxProps {
    color: string;
    text: string;
}

export const DiagonalBox: React.FC<DiagonalBoxProps> = ({ color, text }) => {
    const commonStyles: React.CSSProperties = {
        paddingTop: '3px',
        paddingBottom: '3px',
        paddingLeft: '10px',
        paddingRight: '10px',
        fontWeight: 'bold',
        color: 'white',
    };

    const boxStyles: React.CSSProperties = {
        ...commonStyles,
        backgroundColor: color,
        boxShadow: '0 0 4px rgba(0, 0, 0, 0.3), 0 0 8px rgba(0, 0, 0, 0.2), 0 0 12px rgba(0, 0, 0, 0.1)',
        position: 'absolute',
        opacity: '0.6',
    };

    return (
        <span style={{ position: 'relative', display: 'inline-block' }}>
            <span
                style={{
                    ...boxStyles,
                    top: '0',
                    left: '0',
                    transform: 'translate(-7px, 7px)',
                }}
            >
                <span style={{ opacity: 0 }}>{text}</span>
            </span>
            <span style={boxStyles}>
                <span style={{ opacity: 0 }}>{text}</span>
            </span>
            <span style={{ ...commonStyles, position: 'relative' }}>{text}</span>
        </span>
    );
};
