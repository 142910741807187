import { SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '../postSpinachAPI';

export const postCreateZapierApiKey = async () => {
    const response = await postSpinachAPI<{ userSettings: { apiKey: string } }>(SpinachAPIPath.ZapierApiKey, {});
    if (response?.userSettings) {
        return response.userSettings;
    }
    return null;
};
