import { ClientEventType } from '@spinach-shared/types';

import { useGlobalAuthedUser, useLandingAnalytic, useProAiPlanFeatureList } from '../../../hooks';
import { BodyBigOnboard, HeaderThreeOnboard } from '../../../styles';
import { Column, Row, SlidingColumn, Spacing } from '../../common';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import { BackButtonRow, Stationary, StepProps } from './common';

export type FinishedFlowProps = StepProps & {
    onBack: () => void;
};

export function FinishedFlowReverseTrial({
    onBack,
    direction,
    onSubmit,
    loadingMessage,
}: FinishedFlowProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const proFeatures = useProAiPlanFeatureList();
    useLandingAnalytic(ClientEventType.UserViewedFinishOnboardingReverseTrialStep);
    const trialDaysLeftText = user.isOnManuallyManagedTrial ? '' : ` for the next ${user.reverseTrialDaysLeft} days`;
    return (
        <Stationary>
            <BackButtonRow onBack={onBack} />
            <HeaderThreeOnboard>You&apos;re all set 🥳</HeaderThreeOnboard>
            <ScrollView sidePadding={0} stretch>
                <SlidingColumn centered direction={direction}>
                    <Spacing />
                    <BodyBigOnboard style={{ fontWeight: 600, width: '100%', textAlign: 'center' }}>
                        Spinach Pro features are enabled{trialDaysLeftText}!
                    </BodyBigOnboard>
                    <Column style={{ width: '80%' }}>
                        <ul>
                            {proFeatures.map((feature, i) => (
                                <li key={i}>
                                    <Row vCenter>
                                        <BodyBigOnboard>{feature}</BodyBigOnboard>
                                    </Row>
                                    <Spacing factor={1 / 2} />
                                </li>
                            ))}
                        </ul>
                    </Column>
                </SlidingColumn>
            </ScrollView>
            <Spacing factor={2} />
            <Row centered>
                <PrimaryButton
                    title={'Go to my dashboard'}
                    onClick={onSubmit}
                    isLoading={!!loadingMessage}
                    loadingText={loadingMessage}
                />
            </Row>
        </Stationary>
    );
}
