import { Modal } from '@material-ui/core';

import { ClientEventType } from '@spinach-shared/types';

import { deleteMeetingNotesAsAdmin, useGlobalAiDashboard } from '../../../..';
import { GlobalModal } from '../../../atoms';
import { useExperienceTracking, useGlobalAdmin, useGlobalAuthedUser, useGlobalModal } from '../../../hooks';
import { BodyRegular, ResponsiveModalTitle } from '../../../styles';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

export const ConfirmDeleteMeetingAsAdminModal = () => {
    const [user] = useGlobalAuthedUser();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const { state, setState: setAdminState } = useGlobalAdmin();
    const { searchedHistory, searchedUser } = state;
    const { setToastText } = useGlobalAiDashboard();

    const track = useExperienceTracking();

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Confirm Delete Meeting As Admin',
        });
        setGlobalModal(null);
        setAdminState((updatedState) => ({
            ...updatedState,
            isLoadingMeetingNotesRemoval: false,
        }));
    };

    if (!user) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.ConfirmDeleteMeetingAsAdmin} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden' }}>
                <Column centered>
                    <Row centered>
                        <ResponsiveModalTitle>Confirm Meeting Notes Removal</ResponsiveModalTitle>
                    </Row>
                    <Spacing factor={1 / 3} />
                </Column>

                <Column style={{ alignItems: 'start' }}>
                    <BodyRegular>Are you sure you want to completely remove access to these meeting notes?</BodyRegular>
                    <Spacing factor={1 / 3} />
                </Column>
                <span style={{ flexGrow: 1 }} />
                <PrimaryButton
                    title="Confirm"
                    isLoading={state.isLoadingMeetingNotesRemoval}
                    onClick={async () => {
                        if (!searchedHistory) {
                            return;
                        }

                        setAdminState((updatedState) => ({
                            ...updatedState,
                            isLoadingMeetingNotesRemoval: true,
                        }));

                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Confirm Delete Meeting Notes as Admin',
                            BotId: searchedHistory.id,
                            SelectedUserId: searchedUser?.spinachUserId,
                            MeetingTitle: searchedHistory.meetingTitle,
                        });

                        let response;

                        try {
                            response = await deleteMeetingNotesAsAdmin(searchedHistory.id);
                        } catch (error) {
                            setAdminState((updatedState) => ({
                                ...updatedState,
                                isLoadingMeetingNotesRemoval: false,
                            }));
                            setToastText('Something went wrong here. Please reach out if this persists.');
                        }

                        if (response?.botId) {
                            setAdminState((updatedState) => ({
                                ...updatedState,
                                searchedHistory: null,
                                userHistoryOptions: updatedState.userHistoryOptions.filter(
                                    (h) => h.id !== searchedHistory.id
                                ),
                                isLoadingMeetingNotesRemoval: false,
                            }));
                        } else {
                            setAdminState((updatedState) => ({
                                ...updatedState,
                                isLoadingMeetingNotesRemoval: false,
                            }));
                        }

                        closeModal();
                    }}
                />
                <Spacing factor={1 / 3} />
                <SecondaryButton title="Cancel" onClick={closeModal} disabled={state.isLoadingMeetingNotesRemoval} />
            </SpinachModalContent>
        </Modal>
    );
};
