import { useEffect } from 'react';

import { useGlobalRouting } from './useGlobalRouting';
import { useGlobalStoredSeriesList } from './useGlobalStoredSeriesList';
import { useGlobalAuthedUser } from './useGlobalUser';
import { useGlobalNullableStoredSeries } from './useSeriesData';

/**
 * here we have advantage of having all series data at our disposal
 * there's demo, onboarding, and first series flow, legacy dash, new dashboard
 */
export function useRedirectionWithSeriesLoaded() {
    const [user] = useGlobalAuthedUser();
    const {
        storedSeriesListState: { lastFetched, storedSeriesList },
    } = useGlobalStoredSeriesList();
    const { routeToOnboarding, routeToAIDashboard, routeToDemoExperience, routeToDashboard } = useGlobalRouting();

    const hasAtLeastOneLegacySeries = storedSeriesList
        .filter((s) => !s.isDemoSeries)
        .some((series) => !series.metadata?.scribeMetadata);

    useEffect(() => {
        // only process once we have fetched
        if (!lastFetched) {
            return;
        }

        if (user.isDemoing) {
            routeToDemoExperience({ replace: true });
            return;
        }

        if (!user.metadata.isOnboarded && user.isForcedLegacyOnboarding) {
            routeToOnboarding();
            return;
        }

        if (hasAtLeastOneLegacySeries) {
            // stay on legacy dashboard
            routeToDashboard({ replace: true });
            return;
        }

        routeToAIDashboard({ replace: true });
    }, [storedSeriesList.length, lastFetched, user.seriesMetadataList]);
}

export function useClearSeriesOnMount() {
    const [, setSeries] = useGlobalNullableStoredSeries();

    useEffect(() => {
        setSeries(undefined);
    }, []);
}
