import { VolumeMute, VolumeUp } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import Sound from 'react-sound';
import styled from 'styled-components';

import { AgendaItemBaseRequest, ClientSocketEvent } from '@spinach-shared/types';

import { ReactComponent as LotusIcon } from '../../assets/Lotus.svg';
import MeditationGif from '../../assets/meditation-pulse.gif';
import { GlobalModal } from '../../atoms';
import { ElementId } from '../../constants';
import { useGlobalAuthedUser, useGlobalModal } from '../../hooks';
import { BodyBig, HeaderThree } from '../../styles';
import { SpinachMeditationProps } from '../../types';
import { ClientLogger, createWebsocketPayload } from '../../utils';
import { Row, Spacing } from '../common';
import { LongPressPrimaryButton } from './LongPressPrimaryButton';
import { ScrollArea } from './ScrollArea';
import SecondaryButton from './SecondaryButton';
import StandUpFooter from './StandUpFooter';

const meditationAudio = require('../../assets/meditation_mixed_320.mp3').default;

const HeaderRow = styled(Row)`
    justify-content: left;
    align-items: center;
`;

const MeditationText = styled(BodyBig)`
    line-height: 150%;
    text-align: left;
`;

const MeditationList = styled.ul`
    padding-left: 25px;
`;

function MeditationView({ series, socket, cooldownProps }: SpinachMeditationProps): JSX.Element {
    const meditationState = series.currentMeeting.agenda.meditationItem;
    const [user] = useGlobalAuthedUser();
    const [openModal, setOpenModal] = useGlobalModal();
    const { bypassCooldown } = cooldownProps;
    const [soundStatus, setSoundStatus] = useState<'PLAYING' | 'STOPPED' | 'PAUSED'>(
        meditationState?.audioStartedAt ? 'PLAYING' : 'STOPPED'
    );

    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const toggleMute = () => {
        setIsAudioMuted(!isAudioMuted);
    };

    useEffect(() => {
        if (meditationState && !meditationState.audioStartedAt && openModal !== GlobalModal.MeditationPrompt) {
            setOpenModal(GlobalModal.MeditationPrompt);
        }

        if (meditationState && meditationState.audioStartedAt && soundStatus !== 'PLAYING') {
            setSoundStatus('PLAYING');
        }
    }, [meditationState?.audioStartedAt]);

    return (
        <>
            <Sound
                url={meditationAudio}
                playStatus={soundStatus}
                volume={isAudioMuted ? 0 : 50}
                autoLoad
                onFinishedPlaying={() => setSoundStatus('STOPPED')}
                onError={() => {
                    ClientLogger.error('error with meditation audio playback', {
                        location: window.location.href,
                    });
                }}
                playFromPosition={meditationState?.elapsedMilliseconds}
            />
            <HeaderRow>
                <LotusIcon style={{ marginRight: '9px' }} />
                <HeaderThree>Meditation</HeaderThree>
            </HeaderRow>
            <Spacing factor={0.5} />
            <Row>
                {isAudioMuted ? (
                    <VolumeMute onClick={toggleMute} style={{ cursor: 'pointer' }} />
                ) : (
                    <VolumeUp onClick={toggleMute} style={{ cursor: 'pointer' }} />
                )}
            </Row>
            <ScrollArea useGrayShading={true} stretch>
                <Row centered>
                    <img style={{ height: '40vh', maxWidth: '100%' }} src={MeditationGif} />
                </Row>
                <Row>
                    <MeditationText>
                        Team meditation may seem unconventional, but can help create a more peaceful, collaborative
                        environment.
                    </MeditationText>
                </Row>
                <Row>
                    <MeditationList>
                        <li>
                            <MeditationText>Promote creativity</MeditationText>
                        </li>
                        <li>
                            <MeditationText>Decrease stress</MeditationText>
                        </li>
                        <li>
                            <MeditationText>Increase focus</MeditationText>
                        </li>
                        <li>
                            <MeditationText>Prevent distractions</MeditationText>
                        </li>
                        <li>
                            <MeditationText>Improve relationships</MeditationText>
                        </li>
                    </MeditationList>
                </Row>

                <Row>
                    <MeditationText>
                        Give it a try! This audio will guide your team through a 2 minute breathing mediation.
                    </MeditationText>
                </Row>
                <Spacing factor={2} />
            </ScrollArea>
            <StandUpFooter key={'meditation-footer'}>
                <LongPressPrimaryButton
                    id={ElementId.EndMeditation}
                    title={'End Meditation'}
                    pressTitle="Ending meditation for everyone"
                    fixedWidth={300}
                    pressTime={450}
                    onClick={() => {
                        bypassCooldown();

                        const payload = createWebsocketPayload<AgendaItemBaseRequest>({
                            spinachUserId: user.spinachUserId,
                            seriesSlug: series.slug,
                            itemId: series.currentAgendaItem?.id || '',
                        });

                        socket.emit(ClientSocketEvent.MeditationEnding, payload);
                    }}
                />
                <Spacing factor={0.83} />

                <SecondaryButton
                    title={'Back to lobby'}
                    fadeInDuration={cooldownProps.cooldown}
                    onClick={() => {
                        bypassCooldown();

                        const payload = createWebsocketPayload<AgendaItemBaseRequest>({
                            spinachUserId: user.spinachUserId,
                            seriesSlug: series.slug,
                            itemId: series.currentAgendaItem?.id || '',
                        });

                        socket.emit(ClientSocketEvent.AgendaReversing, payload);
                    }}
                />
            </StandUpFooter>
        </>
    );
}

export default MeditationView;
