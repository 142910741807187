import styled from 'styled-components';

export const BaseButton = styled.button`
    text-align: center;
    align-items: center;
    font-weight: ${(props) => (props.disabled ? 400 : 600)};
    border: none;
    background: none;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    font-family: 'Gilroy';
`;
