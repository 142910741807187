import { useState } from 'react';

import { SpinachUpdateType } from '@spinach-shared/types';

export function useUpdateCounters(): {
    incrementUpdateCount: (updateType: SpinachUpdateType) => void;
    resetCounters: () => void;
    yesterdayItemsPopulated: number;
    todayItemsPopulated: number;
    challengeItemsPopulated: number;
    teamTopicItemsPopulated: number;
} {
    const [yesterdayItemsPopulated, setYesterdayItemsPopulated] = useState(0);
    const [todayItemsPopulated, setTodayItemsPopulated] = useState(0);
    const [challengeItemsPopulated, setChallengeItemsPopulated] = useState(0);
    const [teamTopicItemsPopulated, setTeamTopicItemsPopulated] = useState(0);

    const YTBSetterMap: Record<SpinachUpdateType, () => void> = {
        [SpinachUpdateType.Today]: () => setTodayItemsPopulated(todayItemsPopulated + 1),
        [SpinachUpdateType.Yesterday]: () => setYesterdayItemsPopulated(yesterdayItemsPopulated + 1),
        [SpinachUpdateType.Challenge]: () => setChallengeItemsPopulated(challengeItemsPopulated + 1),
        [SpinachUpdateType.ParkingLot]: () => setTeamTopicItemsPopulated(teamTopicItemsPopulated + 1),
        [SpinachUpdateType.Notes]: () => {},
        [SpinachUpdateType.Topic]: () => {},
        [SpinachUpdateType.ActionItems]: () => {},
        [SpinachUpdateType.Custom]: () => {},
        [SpinachUpdateType.Icebreaker]: () => {},
    };

    function incrementUpdateCount(updateType: SpinachUpdateType) {
        YTBSetterMap[updateType]();
    }

    function resetCounters() {
        setYesterdayItemsPopulated(0);
        setTodayItemsPopulated(0);
        setChallengeItemsPopulated(0);
        setTeamTopicItemsPopulated(0);
    }

    return {
        incrementUpdateCount,
        resetCounters,
        yesterdayItemsPopulated,
        todayItemsPopulated,
        challengeItemsPopulated,
        teamTopicItemsPopulated,
    };
}
