import { SpinachAPIPath, StoredSeries } from '@spinach-shared/types';

import { deleteSpinachAPI } from './deleteSpinachAPI';

export async function deleteUserFromSeries(seriesId: string, userId: string): Promise<StoredSeries | undefined> {
    const data = await deleteSpinachAPI<{ series: StoredSeries | undefined }>(
        SpinachAPIPath.RemoveUserFromSeries.replace(':seriesId', seriesId).replace(':userId', userId)
    );

    return data?.series;
}
