import { Stage } from '@spinach-shared/types';

export function isLocalStage(): boolean {
    return getStage() === Stage.Local;
}

export function isProductionStage(): boolean {
    return getStage() === Stage.Production;
}

export function isTestStage(): boolean {
    return getStage() === Stage.Test;
}

export function isStagingStage(): boolean {
    return getStage() === Stage.Staging;
}

export function isStage(stage: unknown): stage is Stage {
    return Object.values<unknown>(Stage).includes(stage);
}

export function getStage(): Stage {
    const stage: unknown = process.env.STAGE ?? process.env.REACT_APP_STAGE;

    if (!stage) {
        throw new Error('Missing STAGE or REACT_APP_STAGE env var when evaluating stage');
    }

    if (!isStage(stage)) {
        throw new Error(`STAGE or REACT_APP_STAGE env var must be one of ${Object.values(Stage)}`);
    }

    return stage;
}
