import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { ClientAiHistory } from '@spinach-shared/types';

import { atomDrafts, getAiDrafts } from '../..';

export function useGlobalDrafts(): [ClientAiHistory[] | null, boolean, () => Promise<void>] {
    const [drafts, setDrafts] = useRecoilState(atomDrafts);
    const [isFetching, setIsFetching] = useState(false);
    const refetch = useCallback(async () => {
        setIsFetching(true);
        const res = await getAiDrafts();
        setDrafts(res || []);
        setIsFetching(false);
    }, [setDrafts]);

    useEffect(() => {
        async function fetch() {
            await refetch();
        }

        fetch();
    }, [refetch]);

    return [drafts, isFetching, refetch];
}
