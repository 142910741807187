import styled from 'styled-components';

export type TeamTopicsAuthorIndicatorProps = {
    name: string;
    isCurrentAgendaItem?: boolean;
};

const InitialsContainer = styled.div<{ isCurrentAgendaItem?: boolean }>`
    border-radius: 20px;
    font-weight: 700;
    border: none;
    font-size: 10px;
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
        props.isCurrentAgendaItem ? props.theme.primary.orangeLight : props.theme.primary.midnight};
    color: white;
    flex-shrink: 0;
    line-height: 1;
    user-select: none;
`;

export function TeamTopicsAuthorIndicator({ name, isCurrentAgendaItem }: TeamTopicsAuthorIndicatorProps): JSX.Element {
    const initials = getInitialsFromName(name);
    return <InitialsContainer isCurrentAgendaItem={isCurrentAgendaItem}>{initials}</InitialsContainer>;
}

export function getInitialsFromName(name: string): string {
    const wordsInName = name.split(' ');

    if (!wordsInName.length) {
        return 'A'; // for anonymous? unlikely scenario, should always have name
    }

    if (wordsInName.length === 1) {
        return wordsInName[0].charAt(0);
    }

    const firstWordInName = wordsInName[0];
    const lastWordInName = wordsInName[wordsInName.length - 1];

    return `${firstWordInName.charAt(0)}${lastWordInName.charAt(0)}`;
}
