import { IClientUser, SpinachAPIPath } from '@spinach-shared/types';

import { deleteSpinachAPI } from './deleteSpinachAPI';

export async function disconnectMicrosoftCalendar(): Promise<IClientUser | null> {
    const data = await deleteSpinachAPI<{ updatedUser: IClientUser | null }>(
        SpinachAPIPath.MicrosoftCalendarDisconnect
    );

    return data?.updatedUser ?? null;
}
