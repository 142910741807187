import moment from 'moment';

import { demoBotId, demoSeriesId, getDemoData } from '@spinach-shared/constants';
import { AiHistoryJSON } from '@spinach-shared/types';

import type { MeetingSelection } from '../../../clients/clients-shared/src/components/spinach-ai/dashboard/types';

const demoMeetingDate = new Date();

export const getDemoSelection = ({ icpId }: { icpId: string }): MeetingSelection => {
    return {
        botId: demoBotId,
        seriesId: demoSeriesId,
        code: `${demoSeriesId}::${demoBotId}`,
        label: `Getting Started with Spinach AI`,
        verbalDate: moment(demoMeetingDate).format('dddd, MMM Do YYYY'),
        title: '[Sample] Getting Started with Spinach AI',
        createdAt: demoMeetingDate,
        isPartialCappedSummary: false,
        icpId,
        ticket: {
            signature: 'n/a',
            expiration: 0,
        },
        isEdited: false,
    };
};

export const getDemoAiHistory = ({ icpId }: { icpId: string }): AiHistoryJSON => {
    return {
        seriesId: demoSeriesId,
        sessionId: demoBotId,
        userIds: [],
        botId: demoBotId,
        meetingTitle: 'Daily Standup',
        createdAt: demoMeetingDate,
        icpId,
        isPartialCappedSummary: false,
        isEnabledForEditSummary: true,
        platform: 'zoom', // or any other appropriate platform
        updatedAt: demoMeetingDate,
        icalUid: 'demo-ical-uid',
        status: 'completed',
        statusHistory: [
            {
                status: 'completed',
                dateTime: demoMeetingDate,
            },
        ],
        summary: getDemoData({ username: 'Yoav' })[3],
    };
};
