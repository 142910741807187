import { useState } from 'react';

import { Ticket } from '@spinach-shared/types';

import { JiraPickerProps } from '../../../types';
import { JiraPickerModal } from './JiraPickerModal';

export function JiraPicker({
    typedUpdate,
    saveFullTypedUpdate,
    isOpen,
    setIsOpen,
    createUpdateEmitter,
}: JiraPickerProps) {
    const [searchedJiraIssue, setSearchedJiraIssue] = useState<Ticket | null>(null);

    return (
        <>
            {isOpen && (
                <JiraPickerModal
                    createUpdateEmitter={createUpdateEmitter}
                    isModalOpen={isOpen}
                    onClose={() => {
                        setIsOpen(false);
                        setSearchedJiraIssue(null);
                    }}
                    saveFullTypedUpdate={saveFullTypedUpdate}
                    searchedJiraIssue={searchedJiraIssue}
                    setSearchedJiraIssue={setSearchedJiraIssue}
                    typedUpdate={typedUpdate}
                />
            )}
        </>
    );
}
