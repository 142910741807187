import { useState } from 'react';

import { ClientEventType, FeatureDiscoveryKey, GenericFeatureKey } from '@spinach-shared/types';
import { getUniques } from '@spinach-shared/utils';

import { patchUser } from '../../../apis';
import { ReactComponent as ClickButton } from '../../../assets/feature-hint-click.svg';
import { ReactComponent as Megaphone } from '../../../assets/megaphone.svg';
import { useExperienceTracking, useGenericCalloutProps, useGlobalAuthedUser, useLandingAnalytic } from '../../../hooks';
import { URLUtil, getClientPlatform } from '../../../utils';
import { FeatureCallout, FeatureCalloutClickProps, UseCustomCalloutResult } from './FeatureCallout';

export function useGenericCallout(): UseCustomCalloutResult {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const props = useGenericCalloutProps();

    const hintForThisCallout: GenericFeatureKey = `${FeatureDiscoveryKey.Generic}${props.id}`;
    const hasNotInteractedWithCallout = !user.dismissedHints.includes(hintForThisCallout);
    const isCalloutEnabledForUser = !!props.title && !!props.subtitle && props.id !== undefined;
    const shouldShowThisHint =
        isCalloutEnabledForUser &&
        hasNotInteractedWithCallout &&
        !props.excludePlatforms?.includes(getClientPlatform());

    const [isVisible, setIsVisible] = useState(shouldShowThisHint);

    async function onEngageClick() {
        if (!props.url) {
            return;
        }

        URLUtil.openURL(props.url, { redirectThroughBackendWhenOnZoom: true });
        track(ClientEventType.GenericCalloutEngageClick, {
            Title: props.title,
            Subtitle: props.subtitle,
            GenericId: props.id,
        });

        if (props.onlyHideOnCloseClick) {
            return;
        }

        setIsVisible(false);

        await patchUser({
            metadata: {
                dismissedHints: getUniques([...user.dismissedHints, hintForThisCallout]),
            },
        });
    }

    async function onCloseClick() {
        setIsVisible(false);
        track(ClientEventType.GenericCalloutCloseClick, {
            Title: props.title,
            Subtitle: props.subtitle,
            GenericId: props.id,
        });
        await patchUser({
            metadata: {
                dismissedHints: getUniques([...user.dismissedHints, hintForThisCallout]),
            },
        });
    }

    return {
        onEngageClick,
        onCloseClick,
        isVisible,
        feature: FeatureDiscoveryKey.Generic,
    };
}

export function GenericFeatureCallout({ onCloseClick, onEngageClick }: FeatureCalloutClickProps): JSX.Element {
    const { title, subtitle, imgSrc, url, id } = useGenericCalloutProps();

    useLandingAnalytic(ClientEventType.GenericCalloutDisplayed, {
        Title: title,
        Subtitle: subtitle,
        GenericId: id,
    });

    const Icon = imgSrc ? (
        <img src={imgSrc} style={{ height: '32px', width: '32px' }} />
    ) : (
        <Megaphone style={{ height: '32px', width: '32px' }} />
    );

    if ((!title && !subtitle) || !url) {
        return <></>;
    }

    return (
        <FeatureCallout
            icon={Icon}
            title={title}
            subtitle={subtitle}
            ctaIcon={<ClickButton />}
            onEngageClick={onEngageClick}
            onCloseClick={onCloseClick}
        />
    );
}
