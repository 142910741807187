import { Modal } from '@material-ui/core';
import { useState } from 'react';

import { ClientEventType } from '@spinach-shared/types';

import {
    BodyRegularOnboard,
    GlobalModal,
    GlobalModalMetadataType,
    PrimaryButton,
    ResponsiveModalTitle,
    Row,
    Spacing,
    SpinachModalContent,
    useClickTracking,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalModal,
    useGlobalModalState,
} from '../../../..';
import { postClaimHost } from '../../../apis/postClaimHost';
import { useGlobalStoredSeriesList } from '../../../hooks/useGlobalStoredSeriesList';
import SecondaryButton from '../../stand-up/SecondaryButton';

function ClaimHostModalContent({ closeModal, seriesId }: { closeModal: () => void; seriesId: string }) {
    const { storedSeriesListState, updateSeriesInList } = useGlobalStoredSeriesList();
    const { setToastText } = useGlobalAiDashboard();
    const track = useExperienceTracking();
    const clickTracking = useClickTracking();
    const [isLoading, setIsLoading] = useState(false);
    const storedSeries = storedSeriesListState.storedSeriesList.find((s) => s.id === seriesId);
    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Assign me as Spinach Host</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 3} />
            <BodyRegularOnboard style={{ justifyContent: 'center', fontWeight: 'bold' }}>
                {storedSeries?.name}
            </BodyRegularOnboard>
            <Spacing factor={1 / 3} />
            <BodyRegularOnboard>
                All Spinach settings and configurations for this meeting, such as Edit Summaries and who gets Summaries,
                will now be managed by you. Your default settings will be used.
            </BodyRegularOnboard>
            <span style={{ display: 'flex', flexGrow: 1 }} />
            <PrimaryButton
                title="Make me host"
                isLoading={isLoading}
                onClick={async () => {
                    const metadata = {
                        SeriesId: seriesId,
                        CurrentHostUserId: storedSeries?.icpId,
                        MeetingTitle: storedSeries?.name,
                    };
                    try {
                        clickTracking(ClientEventType.AIDashboardClick, 'Claim Host Confirm', metadata);
                        setIsLoading(true);
                        const updatedSeries = await postClaimHost({ seriesId });
                        setIsLoading(false);
                        if (!updatedSeries) {
                            track(ClientEventType.AIDashboardActivity, {
                                Activity: 'Failed to Claim Host',
                                ...metadata,
                            });
                            setToastText('Failed to claim host');
                        } else {
                            updateSeriesInList(updatedSeries);
                        }
                        closeModal();
                    } catch (e) {
                    } finally {
                        setIsLoading(false);
                    }
                }}
            />
            <Spacing factor={1 / 3} />
            <SecondaryButton title="Cancel" onClick={closeModal} />
        </>
    );
}

export function ClaimHostModal(): JSX.Element {
    const [, setGlobalModal] = useGlobalModal();
    const { modal, metadata } = useGlobalModalState();

    const clickTracking = useClickTracking();

    const closeModal = () => {
        clickTracking(ClientEventType.AIDashboardClick, 'Close Claim Host Modal');
        setGlobalModal(null);
    };

    if (modal !== GlobalModal.ClaimHost) {
        return <></>;
    }

    if (metadata?.metadataType !== GlobalModalMetadataType.ClaimHost) {
        return <></>;
    }
    const { seriesId } = metadata;
    return (
        <Modal open={modal === GlobalModal.ClaimHost} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden', minHeight: '300px' }}>
                <ClaimHostModalContent seriesId={seriesId} closeModal={closeModal} />
            </SpinachModalContent>
        </Modal>
    );
}
