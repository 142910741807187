import {
    EmailVerificationCodeResponse,
    MicrosoftVerificationEmailCodeResponse,
    SpinachAPIPath,
} from '@spinach-shared/types';

import { postSpinachAPI } from '.';

export async function postHandleMicrosoftLoginWithCode(
    code: string,
    state?: string,
    useRecallV2?: boolean
): Promise<MicrosoftVerificationEmailCodeResponse | EmailVerificationCodeResponse> {
    try {
        const response = await postSpinachAPI<MicrosoftVerificationEmailCodeResponse>(
            useRecallV2
                ? SpinachAPIPath.MicrosoftHandleRecallV2LoginWithCode
                : SpinachAPIPath.MicrosoftHandleLoginWithCode,
            { code, state },
            {
                throwOnError: true,
            }
        );
        return response || { success: false };
    } catch (e: any) {
        return { success: false, errorCode: e.response?.data?.code };
    }
}
