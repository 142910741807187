import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Fragment } from 'react';

import { BaseMeetingProps, SeriesHistoriesProps, SpinachSeriesProps } from '@spinach-shared/models';
import { SpinachUpdateTypeYTB, UUID } from '@spinach-shared/types';
import { StoredSpinachSeries, getFormattedDate } from '@spinach-shared/utils';

import { useGlobalAuthedUser, useWindowSize } from '../../../../../hooks';
import { BodyLarge, ButtonLabel, ButtonSize, lightTheme } from '../../../../../styles';
import { BootstrapTooltip, Row, Spacing } from '../../../../common';
import { ScrollArea } from '../../../../stand-up';
import { OutlinedButton } from '../../../../stand-up/OutlinedButton';
import { StandupAppStandupSummary } from './StandupAppStandupSummary';

type SummaryWrapperProps = {
    participantFilter: UUID[];
    meeting: BaseMeetingProps;
    spinachSeries: StoredSpinachSeries;
};

function SummaryWrapper({ meeting, participantFilter, spinachSeries }: SummaryWrapperProps) {
    const [user] = useGlobalAuthedUser();
    const filteredMeeting = SeriesHistoriesProps.applyFiltersToMeeting(meeting, participantFilter);
    const { endedAt } = filteredMeeting;
    const formattedDate = endedAt ? getFormattedDate(endedAt) : 'Live Summary ⚡️';

    if (!filteredMeeting.agenda.YTBItems.length) {
        return <></>;
    }

    return (
        <Fragment>
            <Row>
                <BodyLarge>{formattedDate}</BodyLarge>
            </Row>
            <Spacing factor={0.5} />

            <StandupAppStandupSummary
                spinachSeries={
                    new SpinachSeriesProps(
                        {
                            ...spinachSeries.toJSON(),
                            featureFlags: spinachSeries.featureToggles,
                            currentMeeting: {
                                ...meeting.toJSON(),
                                participantsOnline: 0,
                                participantsReady: 0,
                            },
                        },
                        user.spinachUserId
                    )
                }
                currentMeeting={filteredMeeting}
                withMood={false}
            />
            <Spacing factor={0.5} />
        </Fragment>
    );
}

type SummaryFilterViewProps = {
    participantFilter: UUID[];
    categoryFilter: SpinachUpdateTypeYTB[];
    openedSummary: number | null;
    spinachSeries: StoredSpinachSeries;
    onCopyClick: () => void;
    summaries: BaseMeetingProps[];
};

export function StandupAppSummaryFilterView({
    participantFilter,
    categoryFilter,
    openedSummary,
    onCopyClick,
    spinachSeries,
    summaries,
}: SummaryFilterViewProps): JSX.Element {
    const hasFilters = !!(participantFilter.length || categoryFilter.length);
    const { width } = useWindowSize();
    const tooltipPlacement = width < 600 ? 'top' : 'left';

    if (!summaries || !hasFilters || openedSummary === null) {
        return <></>;
    }

    const selectedSeries = openedSummary === -1 ? summaries : [summaries[openedSummary - 1]];

    return (
        <>
            <Spacing factor={0.75} />
            <Row style={{ justifyContent: 'end' }}>
                <BootstrapTooltip title="Copy a formatted summary to your clipboard" placement={tooltipPlacement}>
                    <OutlinedButton
                        size={ButtonSize.Small}
                        onClick={onCopyClick}
                        title={
                            <>
                                <FileCopyOutlinedIcon htmlColor={lightTheme.primary.greenLight} fontSize="small" />
                                <ButtonLabel size={ButtonSize.Small} style={{ marginLeft: '5px' }}>
                                    Copy
                                </ButtonLabel>
                            </>
                        }
                    />
                </BootstrapTooltip>
            </Row>
            <ScrollArea
                style={{ height: '10vh', flexGrow: 1, paddingBottom: '20px' }}
                sidePadding={0}
                useGrayShading={true}
            >
                {selectedSeries.map((meeting) => (
                    <SummaryWrapper
                        key={meeting.id}
                        spinachSeries={spinachSeries}
                        meeting={meeting}
                        participantFilter={participantFilter}
                    />
                ))}
            </ScrollArea>
        </>
    );
}
