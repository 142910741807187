import { Tooltip, makeStyles } from '@material-ui/core';
import { EmojiData, NimbleEmoji } from 'emoji-mart';
import data from 'emoji-mart/data/apple.json';
import { uniqBy } from 'lodash';
import { createRef, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { emojiList, emojiMap, emojiMapWithCategories } from '@spinach-shared/constants';
import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, StoredReaction } from '@spinach-shared/types';

import { postExperienceEvent } from '../../apis';
import { atomLiveSeries } from '../../atoms';
import { useGlobalAuthedUser, useGlobalSeriesId } from '../../hooks';
import { AttachmentPill, BodySubtitle, lightTheme } from '../../styles';
import { ReactionsAttachmentProps, ReactionsPickerProps } from '../../types';
import { Column, Picker, Row } from '../common';

export type Reaction = {
    emojiObject: EmojiData;
    userId: string;
    reactedUserIds?: string[];
};

const onEmojiClick = (reactionsProps: ReactionsAttachmentProps & { seriesId: string | null }, user: ClientUser) => {
    const { typedUpdate, saveFullTypedUpdate, setTypedUpdate, seriesId } = reactionsProps;
    const userId = user.spinachUserId;
    if (!saveFullTypedUpdate || !setTypedUpdate) {
        return () => undefined;
    }

    return (emojiObject: EmojiData) => {
        const reactionToDelete = (typedUpdate?.reactions || []).find(
            (reaction) => reaction.reactedUserIds.includes(userId) && reaction.emojiId === emojiObject.id
        );

        if (reactionToDelete && typedUpdate?.reactions) {
            const reactionIndex = typedUpdate.reactions.findIndex((reaction) => reaction.emojiId === emojiObject.id);
            const reactions = typedUpdate.reactions;
            const reactedUserIds = reactions[reactionIndex].reactedUserIds;
            if (reactedUserIds.length > 1) {
                const userIdIndex = reactions[reactionIndex].reactedUserIds.findIndex(
                    (reactUserId) => reactUserId === userId
                );
                const newReactedUserIds = [
                    ...reactions[reactionIndex].reactedUserIds.slice(0, userIdIndex),
                    ...reactions[reactionIndex].reactedUserIds.slice(userIdIndex + 1),
                ];
                const newReactions = [...reactions];
                newReactions[reactionIndex] = { ...reactions[reactionIndex], reactedUserIds: newReactedUserIds };
                postExperienceEvent({
                    eventType: ClientEventType.ReactionRemoved,
                    payload: {
                        ...user.toUserIdentityPayload(),
                        SeriesId: seriesId ?? '',
                        EmojiId: reactionToDelete.emojiId,
                        NumberOfPeopleReacted: newReactedUserIds.length,
                        UsersReacted: newReactedUserIds,
                    },
                });
                setTypedUpdate({
                    ...typedUpdate,
                    reactions: newReactions,
                });
                saveFullTypedUpdate({
                    ...typedUpdate,
                    reactions: newReactions,
                });
            } else {
                const newReactions = [...reactions.slice(0, reactionIndex), ...reactions.slice(reactionIndex + 1)];
                postExperienceEvent({
                    eventType: ClientEventType.ReactionRemoved,
                    payload: {
                        ...user.toUserIdentityPayload(),
                        SeriesId: seriesId ?? '',
                        EmojiId: reactionToDelete.emojiId,
                        NumberOfPeopleReacted: 0,
                        UsersReacted: [],
                    },
                });
                setTypedUpdate({
                    ...typedUpdate,
                    reactions: newReactions,
                });
                saveFullTypedUpdate({
                    ...typedUpdate,
                    reactions: newReactions,
                });
            }
        } else {
            const existingReactionIndex = (typedUpdate?.reactions || []).findIndex(
                (reaction) => reaction.emojiId === emojiObject.id
            );
            const reaction: StoredReaction = {
                userId: userId,
                emojiId: emojiObject.id || 'raised_hands',
                reactedUserIds: [
                    ...(typedUpdate?.reactions
                        ? typedUpdate.reactions[existingReactionIndex]?.reactedUserIds || []
                        : []),
                    userId,
                ],
            };

            let reactions = [...(typedUpdate?.reactions ?? []), reaction];
            if (existingReactionIndex !== -1) {
                const consolidatedReactions = [...(typedUpdate?.reactions ?? [])];
                consolidatedReactions[existingReactionIndex] = reaction;
                reactions = [...consolidatedReactions];
            }

            postExperienceEvent({
                eventType: ClientEventType.ReactionAdded,
                payload: {
                    ...user.toUserIdentityPayload(),
                    SeriesId: seriesId ?? '',
                    EmojiId: reaction.emojiId,
                    NumberOfPeopleReacted: reaction.reactedUserIds.length,
                    UsersReacted: reaction.reactedUserIds,
                },
            });

            setTypedUpdate({
                ...typedUpdate,
                reactions,
            });

            saveFullTypedUpdate({
                ...typedUpdate,
                reactions,
            });
        }
    };
};

const ReactionsContainer = styled.div<{ overflow?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: ${(props) => (props.overflow ? 'wrap' : 'unset')};
    align-items: center;
    right: 0px;
    margin: 1px 0 2px;
    justify-content: flex-end;
    background-color: transparent;
`;

const EmojiOption = styled.span`
    width: 16px;
    height: 14px;
    padding: 3px;
    margin-right: 2px;
    border-radius: 50%;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
        border-color: #b8b8c5;
    }
`;

export const ReactionPicker = (reactionsProps: ReactionsPickerProps) => {
    const { isOpen, setIsOpen, offset } = reactionsProps;
    const [user] = useGlobalAuthedUser();
    const seriesId = useGlobalSeriesId();
    const reactionCategoryComponents = Object.keys(emojiMapWithCategories).map((category) => (
        <div style={{ paddingRight: '4px' }}>{category}</div>
    ));

    const reactionComponents = Object.keys(emojiMapWithCategories).map((category) => (
        <Row>
            {Object.values(emojiMapWithCategories[category]).map((emoji) => (
                <EmojiOption
                    key={emoji.name}
                    onClick={() => onEmojiClick({ ...reactionsProps, seriesId }, user)(emoji)}
                >
                    <NimbleEmoji
                        backgroundImageFn={() => `${window.location.origin}/static/media/emoji-sheet.png`}
                        data={data}
                        emoji={emoji}
                        size={16}
                    />
                </EmojiOption>
            ))}
        </Row>
    ));

    return (
        <>
            {isOpen && (
                <Picker offset={offset} onMouseLeave={() => setIsOpen(false)}>
                    <Column style={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        {reactionCategoryComponents}
                    </Column>
                    <Column style={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        {reactionComponents}
                    </Column>
                </Picker>
            )}
        </>
    );
};

const ReactionPill = styled(AttachmentPill)<{ isParticipantReaction?: boolean; isDisabled?: boolean }>`
    user-select: none;
    cursor: ${(props) => (props.isDisabled ? 'initial' : 'pointer')};
    padding: 2px 20px 0px 5px;
    justify-content: flex-end;
    background-color: ${(props) =>
        props.isParticipantReaction ? lightTheme.tertiary.orangeLight : lightTheme.neutrals.grayLight};

    &:hover {
        background-color: ${(props) =>
            props.isDisabled
                ? ''
                : props.isParticipantReaction
                ? lightTheme.secondary.orangeDark
                : lightTheme.neutrals.midnight};
    }
`;
const ReactionCount = styled.span`
    position: absolute;
    right: 7px;
    top: 4px;
    font-size: 12px;
`;

const useTooltipStyles = makeStyles(() => ({
    tooltip: {
        width: '100px',
    },
    tooltipPlacementTop: {
        marginBottom: 5,
    },
}));

// TODO: user-references create reusable AttachmentPill component
export const Reactions = (reactionsProps: ReactionsAttachmentProps) => {
    const { typedUpdate, isDisabled, saveFullTypedUpdate, setTypedUpdate } = reactionsProps;
    const [isShowMoreOpen, setIsShowMoreOpen] = useState(false);
    const overflowIndex = 7;

    const [user] = useGlobalAuthedUser();
    const [globalSeries] = useRecoilState(atomLiveSeries);
    const seriesId = useGlobalSeriesId();

    const reactions = uniqBy(
        (typedUpdate.reactions || []).map((r: StoredReaction) => {
            const emojiObject = emojiMap[r.emojiId] || emojiList[0];
            return { emojiObject, ...r };
        }),
        'emojiId'
    );

    const reactionRefs = useRef(reactions.map(() => createRef<HTMLDivElement>()));
    const tooltipClasses = useTooltipStyles();

    const participants = globalSeries?.currentMeeting?.participants.participants;

    if (!typedUpdate.reactions?.length) {
        return null;
    }

    // Since emojis are justified right, we should display the most recent emoji added
    // more left. So, emojis ordered by "added date" should have the most recent on the left,
    // and the oldest on the right.
    const reactionComponents = reactions.reverse().map((reaction, i) => {
        const reactionsCount = reaction.reactedUserIds?.length || 0;
        const reactedNames = participants
            ? (reaction.reactedUserIds || [])
                  .map((id) => {
                      const participant = participants.find((p) => p.spinachUserId === id);
                      return participant?.displayName;
                  })
                  .filter((name) => !!name)
            : [];
        const title =
            reactedNames.length > 0
                ? `${reactedNames.slice(0, 3).join(', ')}${
                      reactionsCount > 3 ? ` and ${reactionsCount - 3} more` : ''
                  } reacted with ${reaction.emojiObject.colons}`
                : `${reactionsCount} ${reactionsCount === 1 ? 'person' : 'people'} reacted with ${
                      reaction.emojiObject.colons
                  }`;
        return (
            <Tooltip classes={tooltipClasses} title={title} arrow={true} placement="top">
                <ReactionPill
                    ref={reactionRefs.current[i]}
                    style={{ display: !isShowMoreOpen && i > overflowIndex ? 'none' : 'flex' }}
                    isParticipantReaction={
                        user?.spinachUserId ? reaction.reactedUserIds.includes(user.spinachUserId) : false
                    }
                    isDisabled={isDisabled}
                    onClick={() =>
                        isDisabled || !saveFullTypedUpdate || !setTypedUpdate
                            ? undefined
                            : onEmojiClick({ ...reactionsProps, seriesId }, user)(reaction.emojiObject)
                    }
                >
                    <NimbleEmoji
                        backgroundImageFn={() => `${window.location.origin}/static/media/emoji-sheet.png`}
                        data={data}
                        emoji={reaction.emojiObject}
                        size={16}
                    />
                    <ReactionCount>{reactionsCount && reactionsCount > 9 ? '9+' : reactionsCount}</ReactionCount>
                </ReactionPill>
            </Tooltip>
        );
    });

    // If there are emojis overflowing then we should show the showMore pill button

    /**
     * If there are emojis overflowing, we determine _how many_ emojis are overflowing,
     * and display that number to the user
     */
    const shouldShowMore = reactions.length > overflowIndex;
    const showMoreNumber = reactions.slice(overflowIndex).length;

    return (
        <ReactionsContainer overflow={shouldShowMore}>
            {reactionComponents}
            {shouldShowMore ? (
                <ReactionPill
                    style={
                        isShowMoreOpen
                            ? { padding: '2px 10px 5px 5px' }
                            : {
                                  padding: '2px 10px 5px 5px',
                                  borderColor: lightTheme.secondary.orangeDark,
                                  borderWidth: '1px',
                                  borderStyle: 'outset',
                              }
                    }
                    isParticipantReaction={false}
                    isDisabled={isDisabled}
                    onClick={() => {
                        if (!isDisabled) {
                            setIsShowMoreOpen(!isShowMoreOpen);
                            postExperienceEvent({
                                eventType: ClientEventType.ReactionShowMoreButtonClick,
                                payload: {
                                    ...user.toUserIdentityPayload(),
                                    ButtonAction: isShowMoreOpen ? 'Close' : 'Open',
                                    NumberOfHiddenEmojis: showMoreNumber,
                                },
                            });
                        }
                    }}
                >
                    {isShowMoreOpen ? (
                        <BodySubtitle style={{ color: lightTheme.primary.midnight }}>show less</BodySubtitle>
                    ) : (
                        <BodySubtitle
                            style={{ color: lightTheme.primary.midnight }}
                        >{`${showMoreNumber} more`}</BodySubtitle>
                    )}
                </ReactionPill>
            ) : null}
        </ReactionsContainer>
    );
};
