import moment from 'moment';

import { SAFE_DURATION_FOR_LIVE_MEETING_IN_HOURS } from '@spinach-shared/constants';
import {
    DateTimeMetadata,
    Day,
    HHMMAMPMLocalTimeString,
    HHMMAMPMTimeString,
    HHMMTimeString,
    MeetingFormat,
    ORDERED_DAYS_MAP,
} from '@spinach-shared/types';

import { TimeUtils } from './time';

export class ScheduleDay {
    private _startTime: HHMMTimeString;
    private _meetingFormat: MeetingFormat | null;
    private _timezoneRegion: string;
    private _day: Day;
    private _endTime: HHMMTimeString;

    dayOfYearToSkipAfterSameDayAdHoc?: number;

    isChangedForThisWeekOnly?: boolean;
    oneOffDateTimeMetadata?: Omit<DateTimeMetadata, 'oneOffDateTimeMetadata' | 'isChangedForThisWeekOnly'>;
    /** @TODO confirm this isnt used */
    timezoneOffset: number;

    constructor(dateTimeMetadata: DateTimeMetadata) {
        this._day = dateTimeMetadata.day;
        this._startTime = dateTimeMetadata.startTime;
        this._meetingFormat = dateTimeMetadata.meetingFormat;
        this._timezoneRegion = dateTimeMetadata.timezoneRegion;
        this._endTime = dateTimeMetadata.endTime;

        this.isChangedForThisWeekOnly = !!dateTimeMetadata.isChangedForThisWeekOnly;
        /** @NOTE prevent `null` from being assigned which can throw off equivalence checking */
        this.oneOffDateTimeMetadata = dateTimeMetadata.oneOffDateTimeMetadata || undefined;
        this.timezoneOffset = dateTimeMetadata.timezoneOffset;

        /** reset this when its past the day of year */
        this.dayOfYearToSkipAfterSameDayAdHoc =
            this.dayOfYear() === dateTimeMetadata.dayOfYearToSkipAfterSameDayAdHoc
                ? dateTimeMetadata.dayOfYearToSkipAfterSameDayAdHoc
                : undefined;
    }

    get isDisabled(): boolean {
        return this.meetingFormat === null;
    }

    get startTime(): HHMMTimeString {
        if (
            this.isChangedForThisWeekOnly &&
            this.oneOffDateTimeMetadata &&
            this.oneOffDateTimeMetadata.startTime &&
            this.oneOffDateTimeMetadata.meetingFormat !== null
        ) {
            return this.oneOffDateTimeMetadata.startTime;
        }
        return this._startTime;
    }

    get rootStartTime(): HHMMTimeString {
        return this._startTime;
    }

    get endTime(): HHMMTimeString {
        if (
            this.isChangedForThisWeekOnly &&
            this.oneOffDateTimeMetadata &&
            this.oneOffDateTimeMetadata.endTime &&
            this.oneOffDateTimeMetadata.meetingFormat !== null
        ) {
            return this.oneOffDateTimeMetadata.endTime;
        }
        return this._endTime;
    }

    get rootTimezoneRegion(): string {
        return this._timezoneRegion;
    }

    get timezoneRegion(): string {
        if (
            this.isChangedForThisWeekOnly &&
            this.oneOffDateTimeMetadata &&
            this.oneOffDateTimeMetadata.timezoneRegion &&
            this.oneOffDateTimeMetadata.meetingFormat !== null
        ) {
            return this.oneOffDateTimeMetadata.timezoneRegion;
        }
        return this._timezoneRegion;
    }

    get meetingFormat(): MeetingFormat | null {
        if (this.isChangedForThisWeekOnly && this.oneOffDateTimeMetadata) {
            return this.oneOffDateTimeMetadata.meetingFormat;
        }
        return this._meetingFormat;
    }

    get day(): Day {
        if (
            this.isChangedForThisWeekOnly &&
            this.oneOffDateTimeMetadata &&
            this.oneOffDateTimeMetadata.day &&
            this.oneOffDateTimeMetadata.meetingFormat !== null
        ) {
            return this.oneOffDateTimeMetadata.day;
        }
        return this._day;
    }

    get localTimeWithTimezone(): HHMMAMPMLocalTimeString {
        const tz = this.timezoneRegion;
        const date = this.getNextStartDate();

        const hhmma = moment(date).tz(tz).format('hh:mm a') as HHMMAMPMTimeString;

        return `${hhmma} ${tz}`;
    }

    get isAsync(): boolean {
        return this.meetingFormat === MeetingFormat.Async;
    }

    get isLive(): boolean {
        return this.meetingFormat === MeetingFormat.Live;
    }

    /**
     *
     * @param withIncompletedLiveBuffer default:`false`. Set to true if you want to give some buffer room between now and
     * the next start scheduled start time. Useful if you don't want UX to immediately update as soon as current scheduled day
     * is in the past
     * @param withSlightAsyncFinishingDelay default:`false`. in some cases, weve seen the finish async handler occur seconds before
     * its actual scheduled time, so this option allows easy buffering for such a scenario
     * @returns `Date` | `null`
     */
    getNextStartDate(withIncompletedLiveBuffer = false, withSlightAsyncFinishingDelay = false): Date {
        const { hours, minutes } = TimeUtils.getHoursMinutesFromHHMM(this.startTime);

        const momentOfNextDateTimeStartTime = moment()
            .tz(this.timezoneRegion)
            .day(this.day)
            .hours(Number(hours))
            .minutes(Number(minutes))
            .seconds(0)
            .milliseconds(0);

        if (withSlightAsyncFinishingDelay) {
            momentOfNextDateTimeStartTime.add(3, 'seconds');
        }

        const currentTimeAdjustedForZone = moment().tz(this.timezoneRegion);

        if (withIncompletedLiveBuffer) {
            currentTimeAdjustedForZone.subtract(SAFE_DURATION_FOR_LIVE_MEETING_IN_HOURS, 'hours');
        }

        const isStartTimeInThePast = momentOfNextDateTimeStartTime.isBefore(currentTimeAdjustedForZone);
        const isSkippingNormalScheduledTimeThisWeek = this.dayOfYearToSkipAfterSameDayAdHoc === this.dayOfYear();

        if (isStartTimeInThePast || isSkippingNormalScheduledTimeThisWeek) {
            momentOfNextDateTimeStartTime.add(7, 'days');
        }

        return new Date(momentOfNextDateTimeStartTime.toISOString());
    }

    getDayWithoutAdHoc(): ScheduleDay {
        let dayOfYearToSkipAfterSameDayAdHoc;

        if (this.isChangedForThisWeekOnly && this.oneOffDateTimeMetadata?.startTime) {
            const adHocUnixStartTime = TimeUtils.getDateFromHHMMDayTimezone(
                this.oneOffDateTimeMetadata.startTime,
                this.oneOffDateTimeMetadata.day,
                this.oneOffDateTimeMetadata.timezoneRegion
            ).getTime();

            const typicalUnixStartTime = TimeUtils.getDateFromHHMMDayTimezone(
                this._startTime,
                this._day,
                this._timezoneRegion
            ).getTime();

            if (adHocUnixStartTime < typicalUnixStartTime) {
                dayOfYearToSkipAfterSameDayAdHoc = this.dayOfYear();
            }
        }

        return new ScheduleDay({
            ...this.toJSON(),
            isChangedForThisWeekOnly: false,
            dayOfYearToSkipAfterSameDayAdHoc,
        });
    }

    private dayOfYear(): number {
        return moment.tz(this.timezoneRegion).dayOfYear();
    }

    toJSON(): DateTimeMetadata {
        return {
            day: this._day,
            startTime: this._startTime,
            endTime: this._endTime,
            meetingFormat: this._meetingFormat,
            isChangedForThisWeekOnly: this.isChangedForThisWeekOnly,
            oneOffDateTimeMetadata: this.oneOffDateTimeMetadata,
            timezoneRegion: this._timezoneRegion,
            timezoneOffset: this.timezoneOffset,
            dayOfYearToSkipAfterSameDayAdHoc: this.dayOfYearToSkipAfterSameDayAdHoc,
        };
    }
}

export class SeriesSchedule {
    private _schedule: ScheduleDay[];

    constructor(dateTimeMetadataList: DateTimeMetadata[]) {
        this._schedule = dateTimeMetadataList.map((dt) => new ScheduleDay(dt));
    }

    get days(): ScheduleDay[] {
        const sorted = [...this._schedule];
        return sorted
            .sort((a, b) => ORDERED_DAYS_MAP[a.day] - ORDERED_DAYS_MAP[b.day])
            .filter((day) => !!day.meetingFormat);
    }

    get nonFilteredDays(): ScheduleDay[] {
        const sorted = [...this._schedule];
        return sorted.sort((a, b) => ORDERED_DAYS_MAP[a.day] - ORDERED_DAYS_MAP[b.day]);
    }

    get isDisabled(): boolean {
        return this.days.length === 0 || this.days.every((date) => date.isDisabled);
    }

    get isEnabled(): boolean {
        return !this.isDisabled;
    }

    getNextScheduledDay(withIncompletedLiveBuffer = false): ScheduleDay | undefined {
        if (!this.days.length) {
            return undefined;
        }

        const orderedListOfTimesUntilEachStartDate = this.days.map((day) => {
            const currentTimeAdjustedForZone = moment().tz(day.timezoneRegion);

            if (withIncompletedLiveBuffer) {
                currentTimeAdjustedForZone.subtract(SAFE_DURATION_FOR_LIVE_MEETING_IN_HOURS, 'hours');
            }

            const currentUnixTimeAdjustedForZone = currentTimeAdjustedForZone.valueOf();

            const unixTimeOfDateTimesNextStart = day.getNextStartDate().getTime();
            return unixTimeOfDateTimesNextStart - currentUnixTimeAdjustedForZone;
        });

        const smallestTimeBetweenNowAndNextStartTime = Math.min(...orderedListOfTimesUntilEachStartDate);

        const indexOfSortedMetadata = orderedListOfTimesUntilEachStartDate.findIndex(
            (delta) => delta === smallestTimeBetweenNowAndNextStartTime
        );

        const selectedDay = this.days[indexOfSortedMetadata];

        return selectedDay;
    }

    getScheduleWithResetDay(day?: Day): SeriesSchedule {
        const newDays = this.days.map((sd) => {
            if (sd.day === day) {
                return sd.getDayWithoutAdHoc();
            } else {
                return sd;
            }
        });
        return new SeriesSchedule(newDays.map((nd) => nd.toJSON()));
    }

    hasDayInSchedule(day: Day): boolean {
        return !!this.days.find((d) => d.day === day);
    }

    /** @ASK does this need to be _schedule */
    toJSON(): DateTimeMetadata[] {
        return this.days.map((day) => day.toJSON());
    }
}
