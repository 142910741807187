import { IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import { FileCopyOutlined, InfoOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import { useState } from 'react';

import { ClientEventType, SpinachIntegration } from '@spinach-shared/types';

import {
    Badge,
    BodyRegularOnboard,
    BootstrapTooltip,
    ButtonSize,
    HeaderThree,
    Spacing,
    copyTextToClipboard,
    isWebPlatform,
    lightTheme,
    useClickTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    withContentMasking,
} from '../../../../..';
import { postCreateZapierApiKey } from '../../../../apis/zapier/postCreateZapierApiKey';
import { ReactComponent as ZapierTooltipDescription } from '../../../../assets/zapier/zapier-description.svg';
import { ReactComponent as ZapierLogo } from '../../../../assets/zapier/zapier-logo_black.svg';
import { useGlobalZapierApiKey } from '../../../../hooks/zapier/useGlobalZapierApiKey';
import { Row } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { UseHoverRowProps } from '../types';

export function ZapierEmbeddingSection() {
    const [user] = useGlobalAuthedUser();

    if (!isWebPlatform()) {
        return <></>;
    }
    return (
        <zapier-workflow
            style={{
                width: '95%',
                marginTop: '10px',
                marginBottom: '10px',
            }}
            sign-up-email={user.email}
            sign-up-first-name={user.firstName}
            sign-up-last-name={user.lastName}
            client-id="zxk91ExB6xDj8NrJntS0g24jiVpxvSs1yKMv5giA"
            theme="light"
            intro-copy-display="show"
            zap-create-from-scratch-display="hide"
            template-limit={0}
        />
    ) as JSX.Element;
}

export function ZapierIntegrationSection({ hoverRowProps }: { hoverRowProps: UseHoverRowProps }): JSX.Element {
    const { getHoverableRowProps } = hoverRowProps;
    const clickTracking = useClickTracking();
    const [user] = useGlobalAuthedUser();
    const [zapierAPIKey, setGlobalZapierAPIKey] = useGlobalZapierApiKey();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { setToastText } = useGlobalAiDashboard();
    const handleClickShowPassword = () => {
        clickTracking(ClientEventType.AIDashboardClick, 'Toggle Show Zapier API Key', {
            IsHidden: showPassword,
        });
        setShowPassword((show) => !show);
    };

    if (!user.isZapierEnabled) {
        return <></>;
    }
    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.Zapier)}>
            <Row style={{ width: 'unset' }}>
                <ZapierLogo style={{ width: '100px', height: 'unset' }} />
                <Spacing horizontal factor={1 / 3} />
                <BootstrapTooltip
                    interactive
                    tooltipStyle={{
                        maxWidth: '350px',
                        backgroundColor: lightTheme.neutrals.white,
                        border: `1px solid ${lightTheme.neutrals.grayDark}`,
                        borderRadius: '6px',
                    }}
                    arrowStyle={{
                        backgroundColor: lightTheme.neutrals.white,
                        border: `1px solid ${lightTheme.neutrals.grayDark}`,
                    }}
                    placement="top"
                    title={
                        <>
                            <ZapierTooltipDescription />
                            <BodyRegularOnboard>
                                User Zapier to create custom triggers and actions with Spianch data
                            </BodyRegularOnboard>
                        </>
                    }
                >
                    <InfoOutlined htmlColor={lightTheme.primary.greenLight} style={{ marginRight: '10px' }} />
                </BootstrapTooltip>
                <Spacing horizontal factor={1 / 3} />
                <Badge title={'New'} />
                <Spacing horizontal factor={1 / 3} />
            </Row>

            {zapierAPIKey ? (
                <Row vCenter style={{ width: 'unset' }}>
                    <BodyRegularOnboard>Spinach API Key: </BodyRegularOnboard>
                    <Spacing horizontal factor={1 / 3} />

                    <OutlinedInput
                        fullWidth={false}
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={zapierAPIKey}
                        {...withContentMasking()}
                        disabled={true}
                        multiline={false}
                        endAdornment={
                            <>
                                <InputAdornment position="end">
                                    <BootstrapTooltip title="Reveal API Key">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </BootstrapTooltip>
                                </InputAdornment>
                                <InputAdornment position="end">
                                    <BootstrapTooltip title="Copy" placement="top">
                                        <IconButton
                                            aria-label="copy api key"
                                            onClick={() => {
                                                clickTracking(ClientEventType.AIDashboardClick, 'Copy Zapier API Key');
                                                copyTextToClipboard(zapierAPIKey);
                                                setToastText('Copied!');
                                            }}
                                            edge="end"
                                        >
                                            <FileCopyOutlined />
                                        </IconButton>
                                    </BootstrapTooltip>
                                </InputAdornment>
                            </>
                        }
                        label="Password"
                    />
                </Row>
            ) : (
                <Row vCenter style={{ width: 'unset' }}>
                    <BodyRegularOnboard>Connect to Zapier with API Key</BodyRegularOnboard>
                    <Spacing horizontal factor={1 / 3} />
                    <OutlinedButton
                        title="Display Key"
                        isLoading={isLoading}
                        size={ButtonSize.Small}
                        onClick={async () => {
                            try {
                                setIsLoading(true);
                                clickTracking(ClientEventType.AIDashboardClick, 'Create Zapier API Key');
                                const response = await postCreateZapierApiKey();
                                if (response?.apiKey) {
                                    setGlobalZapierAPIKey(response.apiKey);
                                }
                            } catch (e) {
                            } finally {
                                setIsLoading(false);
                            }
                        }}
                    />
                </Row>
            )}
        </Row>
    );
}

export function AutomationIntegrationSection({ hoverRowProps }: { hoverRowProps: UseHoverRowProps }): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [isLearnMoreHovered, setIsLearnMoreHovered] = useState(false);

    if (!user.isZapierEnabled) {
        return <></>; // TODO remove if we ever add more things to this section
    }

    return (
        <>
            <Row>
                <HeaderThree>Automations</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>
                    Accelerate your workflow (
                    <span
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: isLearnMoreHovered ? '#0068b1' : lightTheme.primary.midnight,
                        }}
                        onMouseEnter={() => {
                            setIsLearnMoreHovered(true);
                        }}
                        onMouseLeave={() => setIsLearnMoreHovered(false)}
                        onClick={() => window.Intercom?.('showArticle', '9905914')}
                    >
                        learn more
                    </span>
                    )
                </BodyRegularOnboard>
            </Row>

            <Spacing factor={1 / 2} />
            {user.isZapierEnabled ? (
                <>
                    <ZapierIntegrationSection hoverRowProps={hoverRowProps} />
                    <Spacing />
                    {user.isZapierEmbeddingEnabled ? <ZapierEmbeddingSection /> : null}
                </>
            ) : (
                <></>
            )}
        </>
    );
}
