import React, { useState } from 'react';

export const useFlagWithTimeout = (ms: number) => {
    const [flagToExpireAfter, setFlagToExpireAfter] = useState<number | null>(null);
    React.useEffect(() => {
        if (flagToExpireAfter) {
            const flagToExpireIn = flagToExpireAfter - Date.now();
            const timeout = setTimeout(() => {
                setFlagToExpireAfter(null);
            }, flagToExpireIn);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [flagToExpireAfter]);
    const set = React.useCallback(() => {
        setFlagToExpireAfter(Date.now() + ms);
    }, [ms]);
    const reset = React.useCallback(() => {
        setFlagToExpireAfter(null);
    }, []);
    const isSet = flagToExpireAfter !== null && flagToExpireAfter > Date.now();
    return React.useMemo(() => [isSet, set, reset] as const, [isSet, set, reset]);
};
