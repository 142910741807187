import * as Sentry from '@sentry/react';
import { useMachine } from '@xstate/react';
import moment from 'moment';
import { features } from 'process';
import { useEffect } from 'react';
import React from 'react';
import { useRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';
import { assign } from 'xstate';
import { z } from 'zod';

import { MILLIS_IN_SECONDS } from '@spinach-shared/constants';
import {
    AvatarDialogueState,
    AvatarHeySpinachDetectedSocketRequest,
    AvatarSharedState,
    AvatarTaskKind,
    AvatarTaskRequest,
    AvatarTaskSocketRequest,
    AvatarTranscriptChunkCollectedEvent,
    ClientEventType,
    ClientSocketEvent,
    FeatureToggle,
    ServerSocketEvent,
    SpinachAPIPath,
    UpdateSeriesComponentsRequest,
} from '@spinach-shared/types';
import { AvatarClassifyTaskSocketRequest } from '@spinach-shared/types';
import { isLocalStage, isProductionStage } from '@spinach-shared/utils';

import {
    getAgentSimulationOptions,
    getRandomMockTranscriptLine,
    isWebPlatform,
    patchSeries,
    postAvatarWelcomeSpeechMessage,
    useActivityTracking,
    usePrevious,
} from '../..';
import { getSpinachAPI } from '../apis';
import { atomAvatarClassificationResult, atomLiveAvatar, atomLocalAgent, atomMeetingSocket } from '../atoms';
import { useGlobalAuthedUser, useGlobalLiveSeries, useGlobalRouting, useGlobalStoredSeries } from '../hooks';
import {
    TEXT_AGE_VALID_FOR_CLASSIFICATION,
    textSchema,
    transcriptProccessing,
} from '../state-machines/transcript-proccessing';
import { createWebsocketPayload, isChromeExtensionPlatform } from '../utils';
import { useGlobalMeetingSocket, useGlobalNullableMeetingSocket } from './useGlobalSocket';

export function useLiveAvatar() {
    const [user] = useGlobalAuthedUser();
    const [socket] = useRecoilState(atomMeetingSocket);
    const [, setAvatar] = useRecoilState(atomLiveAvatar);

    useEffect(() => {
        if (!user.isEnabledForAgent) {
            return;
        }

        const callback = (response: AvatarSharedState) => {
            if (isProductionStage()) {
                console.log('AVATAR STATE UPDATED');
            } else {
                console.log('AVATAR STATE UPDATED', response);
            }
            setAvatar(response);
        };

        socket?.on(ServerSocketEvent.AvatarStateUpdated, callback);

        return () => {
            socket?.off(ServerSocketEvent.AvatarStateUpdated, callback);
        };
    }, [socket, setAvatar, user.isEnabledForAgent]);
}

export function useGlobalLiveAvatar() {
    return useRecoilState(atomLiveAvatar);
}

export function useGlobalAvatarClassification() {
    return useRecoilState(atomAvatarClassificationResult);
}

export function useGlobalLocalAgent() {
    return useRecoilState(atomLocalAgent);
}

export const useAvatarClassifierListener = () => {
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useGlobalLiveSeries();
    const [avatarState] = useGlobalLiveAvatar();
    const [socket] = useGlobalNullableMeetingSocket();
    const [, setLocalAgent] = useGlobalLocalAgent();
    const trackActivity = useActivityTracking();

    const botId = avatarState.botId;
    const slug = liveSeries.slug;
    const meetingId = avatarState.meetingId;
    const spinachUserId = user.spinachUserId;

    const isOnTopicItem = liveSeries.currentAgendaItem?.isParticipantAgendaItem === false;
    const currentTopicAgendaItemId = isOnTopicItem ? liveSeries.currentAgendaItem?.id : undefined;
    const mockTranscriptionInterval = getAgentSimulationOptions()?.mockTranscriptionInterval;
    const transcriptionInterval = (mockTranscriptionInterval || 60) * MILLIS_IN_SECONDS;

    function getTestCreateTicketEventData() {
        return {
            id: uuid(),
            text: `Hey Spinach, create a ticket for this statement: ${getRandomMockTranscriptLine()}`,
            final: true,
            speaker: user.preferredName,
            timestamp: new Date().toISOString(),
            isSelf: true,
            relatedAgendaItemId: currentTopicAgendaItemId,
        };
    }

    const [state, send] = useMachine(transcriptProccessing, {
        delays: {
            dispatchTranscriptOnceEvery: transcriptionInterval, // default value, override in useMachine
        },
        guards: {},
        actions: {
            init: () => {
                console.log('INIT MACHINE');
                trackActivity(ClientEventType.AgentUxActivity, 'State Machine Started');
                window.parent.postMessage(
                    {
                        type: 'extension-connected',
                        firstName: user.firstName,
                        lastName: user.lastName,
                        featureToggles: user.featureToggles,
                    },
                    'https://meet.google.com'
                );
            },
            heySpinachDetected: () => {
                trackActivity(ClientEventType.AgentUxActivity, 'Hey Spinach Detected');
                window.parent.postMessage({ type: 'hey-spinach-detected' }, 'https://meet.google.com');
            },
            enterIdleState: () => {
                window.parent.postMessage({ type: 'enter-idle-state' }, 'https://meet.google.com');
            },
            storeHeySpinachTimestamp: assign({
                heySpinachTimestamp: (ctx, event) => {
                    if (isLocalStage() && isWebPlatform()) {
                        return new Date(moment().subtract(10, 'seconds').toDate()).getTime();
                    } else {
                        const { timestamp } = textSchema.parse((event as any).data);
                        return new Date(timestamp).getTime();
                    }
                },
            }),
            sendTextToClassification: (context) => {
                const { textBuffer } = context;
                const afterCommands = textBuffer
                    .filter(
                        (t) =>
                            new Date(t.timestamp).getTime() >= context.heySpinachTimestamp &&
                            Date.now() - new Date(t.timestamp).getTime() > TEXT_AGE_VALID_FOR_CLASSIFICATION
                    )
                    .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
                // beforeCommands is everything before the hey spinach command up to 30 seconds
                const beforeCommands = textBuffer
                    .filter(
                        (t) =>
                            new Date(t.timestamp).getTime() < context.heySpinachTimestamp &&
                            new Date(t.timestamp).getTime() > context.heySpinachTimestamp - 30000
                    )
                    .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
                const id = uuid(); // figure how to store it
                const classifyPayload = createWebsocketPayload<AvatarClassifyTaskSocketRequest>({
                    seriesSlug: slug,
                    meetingId: meetingId,
                    taskRequest: {
                        id,
                        commanderUserId: spinachUserId,
                        meetingId: meetingId,
                        seriesId: slug,
                        botId: botId,
                        transcriptChunkBeforeCommand: beforeCommands,
                        transcriptChunkAfterCommand: afterCommands,
                    },
                });
                trackActivity(ClientEventType.AgentUxActivity, 'Classify Hey Spinach Command');
                socket?.emit(ClientSocketEvent.ClassifyAvatarTask, classifyPayload);
            },
            processTicketCreation: (context) => {
                window.parent.postMessage({ type: 'hey-spinach-processing' }, 'https://meet.google.com');
                const { textBuffer } = context;
                const afterCommands = textBuffer
                    .filter((t) => new Date(t.timestamp).getTime() > context.heySpinachTimestamp)
                    .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
                // beforeCommands is everything before the hey spinach command up to 30 seconds
                const beforeCommands = textBuffer
                    .filter(
                        (t) =>
                            new Date(t.timestamp).getTime() < context.heySpinachTimestamp &&
                            new Date(t.timestamp).getTime() > context.heySpinachTimestamp - 30000
                    )
                    .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
                const id = uuid(); // figure how to store it
                const classifyPayload = createWebsocketPayload<AvatarTaskSocketRequest>({
                    seriesSlug: slug,
                    meetingId: meetingId,
                    task: {
                        id,
                        commanderUserId: spinachUserId,
                        meetingId: meetingId,
                        seriesId: slug,
                        botId: botId,
                        transcriptChunkBeforeCommand: beforeCommands,
                        transcriptChunkAfterCommand: afterCommands,
                        taskKind: AvatarTaskKind.CreateTicket,
                    },
                });
                trackActivity(ClientEventType.AgentUxActivity, 'Prepare Ticket Trigger');
                console.log('EMITTING AVATAR PREPARE TICKET', {
                    taskId: id,
                    commanderUserId: spinachUserId,
                    transcriptChunkBeforeCommand: beforeCommands.map((a) =>
                        isProductionStage() ? a.id : `${a.speaker}:${a.text}`
                    ),
                    transcriptChunkAfterCommand: afterCommands.map((a) =>
                        isProductionStage() ? a.id : `${a.speaker}:${a.text}`
                    ),
                });
                socket?.emit(ClientSocketEvent.AvatarPrepareTicket, classifyPayload);
            },
            broadcastHeySpinachDetected: () => {
                console.log('BROADCASTING HEY SPINACH DETECTED');
                const classifyPayload = createWebsocketPayload<AvatarHeySpinachDetectedSocketRequest>({
                    spinachUserId,
                    timestamp: new Date().toISOString(),
                    seriesSlug: slug,
                    meetingId: meetingId,
                });
                socket?.emit(ClientSocketEvent.HeySpinachDetected, classifyPayload);
            },
            onLiveTranscriptChunkCollected: (context) => {
                function getMockTranscriptEventData() {
                    const chosenLine = getRandomMockTranscriptLine();

                    return [
                        {
                            id: uuid(),
                            timestamp: new Date().toISOString(),
                            speaker: user.preferredName || 'Spinach',
                            text: chosenLine,
                            final: true,
                            isSelf: true,
                            relatedAgendaItemId: currentTopicAgendaItemId,
                        },
                    ];
                }

                const textToUse = !!getAgentSimulationOptions()?.mockTranscriptionEnabled
                    ? getMockTranscriptEventData()
                    : context.textBuffer;

                const lines = textToUse
                    .filter((t) => new Date(t.timestamp).getTime() > Date.now() - transcriptionInterval)
                    .filter((t) => t.final)
                    .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

                console.log('COLLECTED CHUNKS', {
                    count: lines.length,
                    text: lines.map((l) => (isProductionStage() ? l.id : `${l.speaker}:${l.text}`)).join('\n'),
                });
                // send to socket
                if (lines.length > 0) {
                    const payload = createWebsocketPayload<AvatarTranscriptChunkCollectedEvent>({
                        seriesSlug: liveSeries.slug,
                        meetingId: liveSeries.currentMeeting.id,
                        lines: lines.map((line) => ({
                            ...line,
                            relatedAgendaItemId: currentTopicAgendaItemId,
                        })),
                        userId: user.spinachUserId,
                        botId: avatarState?.botId,
                        currentAgendaItemId: currentTopicAgendaItemId,
                        skipGeneration: !!getAgentSimulationOptions()?.skipLiveNoteGeneration,
                    });
                    socket?.emit(ClientSocketEvent.AvatarTranscriptChunkCollected, payload);
                }
            },
        },
    });

    useEffect(() => {
        if (!user.isEnabledForAgent) {
            return;
        }

        const l = (response: { taskId: string; taskKind: AvatarTaskKind } & AvatarTaskRequest) => {
            console.log('RECEIVED AVATAR TASK CLASSIFIED', {
                id: response.taskId,
                taskKind: response.taskKind,
            });
            switch (response.taskKind) {
                case AvatarTaskKind.CreateTicket:
                    send({ type: 'PRROPOSE_TICKET_CLASS_DETECTED' });
                    break;
                default:
                    send({ type: 'NO_CLASS_DETECTED' });
            }
        };
        socket?.on(ServerSocketEvent.AvatarTaskClassified, l);
        return () => {
            socket?.off(ServerSocketEvent.AvatarTaskClassified, l);
        };
    }, [send, socket, user.isEnabledForAgent]);

    useEffect(() => {
        const l = (response: { spinachUserId: string; timestamp: string }) => {
            // todo validate timestamp to ensure it's not stale
            if (response.spinachUserId !== user.spinachUserId) {
                send({ type: 'ANOTHER_PEER_IS_PROCCESSING' });
                // migth worth considering adding a flag on the presisted object to ensure if will work for users who join during proccessing
            }
        };
        socket?.on(ServerSocketEvent.HeySpinachDetected, l);
        return () => {
            socket?.off(ServerSocketEvent.HeySpinachDetected, l);
        };
    }, [send, socket, user.spinachUserId]);

    useEffect(() => {
        if (avatarState.avatarTasks.length > 0) {
            const lastHeySpinach =
                avatarState.avatarTasks[avatarState.avatarTasks.length - 1]?.transcriptChunkAfterCommand[0]?.timestamp;
            const wasRequestedWithin60Seconds =
                lastHeySpinach && new Date().getTime() - new Date(lastHeySpinach).getTime() < 60000;
            if (wasRequestedWithin60Seconds) {
                // todo: hookup to an actual event to increase reliability
                send({ type: 'TICKET_PROPOSAL_RESULTS', tasks: avatarState.avatarTasks });
            }
        }
        // ignore the avatarState.avatarTasks.length dependency since we only want to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarState.avatarTasks.length, send]);

    const lastTicketId = avatarState.avatarTasks[avatarState.avatarTasks.length - 1]?.id;
    const previousLastTicketId = usePrevious(lastTicketId);
    const isLastTicketCreated = !!avatarState.avatarTasks[avatarState.avatarTasks.length - 1]?.createdTicket;
    const previousIsLastTicketCreated = usePrevious(isLastTicketCreated);
    useEffect(() => {
        if (lastTicketId == previousLastTicketId && isLastTicketCreated && !previousIsLastTicketCreated) {
            send({ type: 'TICKET_CREATED' });
        }
    }, [isLastTicketCreated, lastTicketId, previousIsLastTicketCreated, previousLastTicketId, send]);

    useEffect(() => {
        const transcriptListener = async (event: MessageEvent<unknown>) => {
            if (event.origin !== 'https://meet.google.com') {
                return;
            }
            const { id, type, text, speaker, final, timestamp, isSelf } = event.data as any;
            switch (type) {
                case 'transcript':
                    window.parent.postMessage({ type: 'transcript-received' }, 'https://meet.google.com');
                    send({
                        type: 'NEW_TEXT',
                        data: {
                            id,
                            text,
                            final,
                            speaker,
                            timestamp,
                            isSelf,
                            relatedAgendaItemId: currentTopicAgendaItemId,
                        },
                    });
            }
        };
        window.addEventListener('message', transcriptListener);
        return () => {
            window.removeEventListener('message', transcriptListener);
        };
    }, [currentTopicAgendaItemId, send]);

    let dialogueState = AvatarDialogueState.IdleWithSuggestions;
    // since it is a parallel state machine we only check the part of the machine that manages the dialogue state and not transciption buffering
    const fullState = state
        .toStrings()
        .reverse()
        .find((s) => s.startsWith('dialogue'));

    if (fullState?.startsWith('dialogue.processing')) {
        if (
            fullState === 'dialogue.processing.proccessing_ticket_creation' ||
            fullState === 'dialogue.processing.peer_processing'
        ) {
            dialogueState = AvatarDialogueState.ProcessingTask;
        } else {
            dialogueState = AvatarDialogueState.HeySpinachHeard;
        }
    } else if (fullState === 'dialogue.idle.waiting') {
        dialogueState = AvatarDialogueState.IdleWithSuggestions;
    } else if (fullState === 'dialogue.idle.proposing_task') {
        dialogueState = AvatarDialogueState.ProposingTask;
    } else if (fullState === 'dialogue.idle.task_accepted') {
        dialogueState = AvatarDialogueState.TaskAccepted;
    } else if (fullState === 'dialogue.idle.task_unknown') {
        dialogueState = AvatarDialogueState.TaskUnknown;
    }

    useEffect(() => {
        console.log('FULL STATE', fullState);
        setLocalAgent((prev) => ({
            ...prev,
            dialogueState,
        }));
    }, [fullState, dialogueState, setLocalAgent]);

    return React.useMemo(
        () => ({
            onCreatedTicket: () => {
                send({ type: 'ACCEPTED' });
            },
            onTestHeySpinachCreateTicket: () => {
                const data = getTestCreateTicketEventData();
                send({
                    type: 'NEW_TEXT',
                    data,
                });
            },
        }),
        [send, getTestCreateTicketEventData]
    );
};

export function useExtensionAvatar() {
    const [user] = useGlobalAuthedUser();
    const pullIntervalRef = React.useRef<number>();
    const { routeToSeriesExperience } = useGlobalRouting();
    const [welcomeMessageSent, setWelcomeMessageSent] = React.useState(false);

    // todo set botid in avatar global state
    const [avatar, setAvatarState] = useGlobalLiveAvatar();
    useEffect(() => {
        const ExtenstionConnectedEventSchema = z.object({
            type: z.enum(['extension-connected']),
            platform: z.enum(['google-meet']),
            meetingId: z.string(),
        });

        const TranscriptEventSchema = z.object({
            type: z.enum(['transcript']),
            timestamp: z.string().datetime(),
            speaker: z.string(),
            text: z.string(),
            final: z.boolean(),
        });

        const ErrorSchema = z.object({
            type: z.enum(['extension-error']),
            errorMessage: z.string(),
            stack: z.string().optional(),
        });

        const ExtensionEventSchema = ExtenstionConnectedEventSchema.or(TranscriptEventSchema).or(ErrorSchema);

        // TODO reconnect if partial page reload
        const isEnabled = isChromeExtensionPlatform() && user.isEnabledForAgent;

        if (!isEnabled) {
            return;
        }

        const listener = async (event: MessageEvent<unknown>) => {
            if (event.origin !== 'https://meet.google.com') {
                return;
            }
            const { type } = ExtensionEventSchema.parse(event.data);
            switch (type) {
                case 'extension-connected':
                    const { meetingId } = ExtenstionConnectedEventSchema.parse(event.data);
                    window.parent.postMessage(
                        {
                            type: 'extension-connected',
                            firstName: user.firstName,
                            lastName: user.lastName,
                            featureToggles: user.featureToggles,
                        },
                        'https://meet.google.com'
                    );
                    if (pullIntervalRef.current) {
                        window.clearInterval(pullIntervalRef.current);
                    }
                    // pullIntervalRef.current = window.setInterval(async function pullData() {
                    // check if there is a cookie called connect.sid so we can make requests to the backend
                    try {
                        const response:
                            | {
                                  lastPausedAt: string;
                                  lastResumedAt: string;
                                  lastHeySpinach: string;
                                  lastTicketCreation: string;
                                  lastOnboarding: string;
                                  seriesId: string;
                                  botId: string;
                              }
                            | undefined = await getSpinachAPI(SpinachAPIPath.AvatarStatus, {
                            params: { meetingPlatformId: meetingId, platform: 'google-meet' },
                        });
                        if (response) {
                            window.parent.postMessage(
                                {
                                    type: 'avatar-status',
                                    meetingId,
                                    status: response,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                },
                                'https://meet.google.com'
                            );
                            routeToSeriesExperience(response.seriesId, { replace: true });

                            if (!avatar.botId) {
                                setAvatarState((avatarState) => ({
                                    ...avatarState,
                                    botId: avatarState.botId || response.botId,
                                }));
                            }
                        }
                    } catch (error) {
                        console.error('Error while fetching avatar status', error);
                    }
                    // }, 1000);
                    break;
                case 'extension-error':
                    const error = new Error(ErrorSchema.parse(event.data).errorMessage);
                    error.stack = ErrorSchema.parse(event.data).stack;
                    Sentry.captureException(error);
            }
        };

        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener);
            if (pullIntervalRef.current) {
                window.clearInterval(pullIntervalRef.current);
            }
        };
    }, [
        user.featureToggles,
        routeToSeriesExperience,
        avatar.botId,
        user.isEnabledForAgent,
        user.firstName,
        user.lastName,
        setAvatarState,
    ]);

    useEffect(() => {
        if (
            !!user.featureToggles[FeatureToggle.AvatarWelcomeJoiningParticipant] &&
            !welcomeMessageSent &&
            avatar.botId
        ) {
            setWelcomeMessageSent(true);
            postAvatarWelcomeSpeechMessage(avatar.botId);
        }
    }, [avatar.botId, user.featureToggles, welcomeMessageSent]);
}

export function useDisableRoundtableForAgentSeries() {
    const [liveSeries] = useGlobalLiveSeries();
    const [socket] = useGlobalMeetingSocket();
    const [user] = useGlobalAuthedUser();
    const [, setStoredSeries] = useGlobalStoredSeries();
    const trackActivity = useActivityTracking();

    useEffect(() => {
        async function exec() {
            const roundtable = liveSeries.roundtableComponent;

            const updatedComponents = [
                {
                    ...roundtable,
                    isHidden: true,
                },
            ];

            const payload = createWebsocketPayload<UpdateSeriesComponentsRequest>({
                components: updatedComponents,
                seriesSlug: liveSeries.slug,
                meetingId: liveSeries.currentMeeting.id,
                spinachUserId: user.spinachUserId,
                userId: user.spinachUserId,
                userName: user.preferredName,
                userEmail: user.email,
                rootDomain: user.rootDomain,
            });

            trackActivity(ClientEventType.AgentUxActivity, 'Auto-Disabled Roundtable for Agent Series');

            socket.emit(ClientSocketEvent.UpdatingSeriesComponents, payload);

            const updatedSeries = await patchSeries(liveSeries.slug, {
                components: updatedComponents,
            });

            if (updatedSeries) {
                setStoredSeries(updatedSeries);
            }
        }

        // we only want to execute this if the current series has roudntable on
        if (!liveSeries.roundtableComponent.isHidden) {
            exec();
        }
    }, [
        liveSeries.currentMeeting.id,
        liveSeries.roundtableComponent,
        liveSeries.slug,
        setStoredSeries,
        socket,
        user.email,
        user.preferredName,
        user.rootDomain,
        user.spinachUserId,
    ]);
}
