import React, { HTMLAttributes, ReactNode, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { SCROLL_VIEW_ID } from './constants';

export const ScrollShadingCSS = css`
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
`;

export const ScrollShadingCSSGray = css`
    background: /* Shadow covers */ linear-gradient(
            ${(props) => props.theme.neutrals.offWhite} 30%,
            rgba(245, 245, 245, 0)
        ),
        linear-gradient(rgba(245, 245, 245, 0), ${(props) => props.theme.neutrals.offWhite} 70%) 0 100%,
        /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: ${(props) => props.theme.neutrals.offWhite};
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
`;

export const HorizontalScrollShadingCSS = css`
    background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
        linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0)),
        linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0));

    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
    background-attachment: local, local, scroll, scroll;
`;

const SCROLL_PADDING = 25;
export const ScrollingContainer = styled.div<{
    stretch?: boolean;
    balancePadding?: boolean;
    sidePadding?: number;
    horizontal?: boolean;
    useGrayShading?: boolean;
    disabled?: boolean;
}>`
    display: flex;
    flex-direction: column;
    overflow-y: ${(props) => (props.disabled ? 'hidden' : props.horizontal ? 'hidden' : 'auto')};
    overflow-x: ${(props) => (props.disabled ? 'hidden' : props.horizontal ? 'auto' : 'hidden')};
    padding: 0px ${(props) => (props.sidePadding !== undefined ? props.sidePadding : SCROLL_PADDING)}px;
    width: ${(props) => (props.balancePadding ? `calc(100% - ${2 * SCROLL_PADDING}px)` : '100%')};

    height: ${(props) => (props.stretch ? '100%' : 'initial')};
    flex-grow: ${(props) => (props.stretch ? '1' : 'initial')};

    ${(props) =>
        props.horizontal
            ? HorizontalScrollShadingCSS
            : !!props.useGrayShading
            ? ScrollShadingCSSGray
            : ScrollShadingCSS};
`;

type ScrollAreaProps = HTMLAttributes<HTMLDivElement> & {
    stretch?: boolean;
    children?: ReactNode;
    balancePadding?: boolean;
    sidePadding?: number;
    horizontal?: boolean;
    disabled?: boolean;
    useGrayShading?: boolean;
};

export const ScrollArea = forwardRef<HTMLDivElement | null, ScrollAreaProps>(
    (
        {
            children,
            stretch,
            balancePadding,
            sidePadding,
            horizontal,
            useGrayShading,
            disabled = false,
            ...rest
        }: ScrollAreaProps,
        ref
    ) => {
        return (
            <ScrollingContainer
                ref={ref}
                id={SCROLL_VIEW_ID}
                stretch={stretch}
                balancePadding={balancePadding}
                sidePadding={sidePadding}
                horizontal={horizontal}
                disabled={disabled}
                useGrayShading={useGrayShading}
                {...rest}
            >
                {children}
            </ScrollingContainer>
        );
    }
);
