import { CircularProgress } from '@material-ui/core';
import { Event, Group, InfoOutlined, Repeat } from '@material-ui/icons';
import { Info } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CalendarEvent } from '@spinach-shared/types';
import { getMasterEventId } from '@spinach-shared/utils';

import { ReactComponent as CheckIcon } from '../../assets/check-icon.svg';
import { useGlobalAiMeetingSelection, useGlobalAuthedUser, useIsNonOrganizerAllowedToRemoveSpinach } from '../../hooks';
import { isScribeEmail } from '../../hooks/useScribe';
import { BodyBigOnboard, BodyRegular, lightTheme } from '../../styles';
import { SetValue } from '../../types';
import { MeetingsAndSuggestions, withContentMasking } from '../../utils';
import { BootstrapTooltip, Column, Row, TransitionLeft } from '../common';
import { ScrollView } from '../series/common';
import { Notification } from '../stand-up';

const MeetingContainer = styled.li<{ isInteractive: boolean; isHighlighted?: boolean }>`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    border: 1px solid #eaeaea;
    box-shadow: ${(props) =>
        props.isHighlighted ? `0px 0px 5px ${props.theme.primary.orangeLight}` : '3px 3px 8px 2px rgba(0, 0, 0, 0.1)'};
    border-radius: 6px;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    background-color: white;
    cursor: ${(props) => (props.isInteractive ? 'pointer' : 'default')};
`;

const MeetingsContainer = styled.ul`
    margin: 0;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const MeetingsScrollView = styled(ScrollView)<{ hasError: boolean }>`
    position: relative;
    ${(props) => props.hasError && 'overflow:hidden;'}
    background: transparent;
`;

const MeetingsScrollViewContainer = styled.div`
    flex: 1;
    display: flex;
    position: relative;
    margin-top: 8px;
    overflow: hidden;
    width: 100%;
`;

export const MeetingCheck = styled.div<{ isChecked: boolean; isInteractive: boolean; isHovered?: boolean }>`
    width: 30px;
    height: 30px;
    background: ${(props) =>
        props.isChecked || props.isHovered ? props.theme.secondary.green : props.theme.neutrals.gray};
    justify-self: flex-end;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 10px;
    opacity: ${(props) => (props.isHovered && !props.isChecked ? 0.2 : 1)};
    cursor: ${(props) => (!props.isInteractive ? 'not-allowed' : 'pointer')};
`;

export function isScribeOnEvent(event: CalendarEvent) {
    return event.isScribeOnEvent;
}

export function getScribeAttendeesFromEvent(event: CalendarEvent) {
    return event.attendees?.filter((attendee) => isScribeEmail(attendee.email));
}

function CalendarEventTooltipContent({ event }: { event: CalendarEvent }): JSX.Element {
    return (
        <Column>
            <Row style={{ alignItems: 'center' }}>
                <Event style={{ fontSize: '12px', marginRight: '5px' }} /> {event.summary}
            </Row>
            <Row>
                <Group style={{ fontSize: '12px', marginRight: '5px' }} />
                {event.attendees?.length ? `${event.attendees?.length} attendees` : '1 attendee'}
            </Row>
            <Row>
                <Repeat style={{ fontSize: '12px', marginRight: '5px' }} />
                {event.recurringEventId ? 'Recurring' : 'Single occurrence'}
            </Row>
        </Column>
    );
}

function MeetingItem({
    event,
    isInteractive,
    isChecked,
    isOrganizer,
    isEventHovered,
    isUpdatingEvent,
    isOnboarding,
}: {
    isUpdatingEvent: boolean;
    event: CalendarEvent;
    showTooltip: boolean;
    isInteractive: boolean;
    isChecked: boolean;
    isOnboarding: boolean;
    isOrganizer?: boolean;
    isEventHovered?: boolean;
}): JSX.Element {
    let tooltip = '';
    if (!isInteractive && !isOrganizer) {
        tooltip = 'Spinach is already on this invite';
    }
    return (
        <>
            <Row style={{ alignItems: 'center' }}>
                <BootstrapTooltip
                    title={<CalendarEventTooltipContent event={event} />}
                    interactive={true}
                    placement="left"
                    style={{ fontSize: '16px' }}
                >
                    <Info style={{ marginRight: '10px' }} fontSize="small" />
                </BootstrapTooltip>
                <span style={{ fontWeight: 'bold' }} {...withContentMasking()}>
                    {event.summary}
                </span>
                <span style={{ flex: 1 }} />
                {isUpdatingEvent ? (
                    <CircularProgress size={'30px'} style={{ color: lightTheme.secondary.green }} />
                ) : (
                    <BootstrapTooltip
                        title={tooltip ? <BodyRegular style={{ color: 'white' }}>{tooltip}</BodyRegular> : ''}
                    >
                        {isOnboarding && !isInteractive ? (
                            <InfoOutlined htmlColor={lightTheme.primary.greenLight} />
                        ) : (
                            <MeetingCheck
                                isChecked={isChecked}
                                isInteractive={isInteractive}
                                isHovered={isEventHovered}
                            >
                                {isChecked ? (
                                    <CheckIcon
                                        stroke={'white'}
                                        style={{
                                            width: '66%',
                                            height: 'auto',
                                            filter:
                                                isEventHovered && isInteractive && !isChecked
                                                    ? 'brightness(0.8)'
                                                    : undefined,
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                                {!!isEventHovered && !isChecked ? (
                                    <CheckIcon stroke={'white'} style={{ width: '66%', height: 'auto', opacity: 1 }} />
                                ) : (
                                    <></>
                                )}
                            </MeetingCheck>
                        )}
                    </BootstrapTooltip>
                )}
            </Row>
        </>
    );
}

const MeetingListLabel = styled(BodyBigOnboard)`
    margin-bottom: 10px;
    font-weight: 600;
`;

export function SelectCalendarEventsForSpinach({
    isOnboardingFlow,
    hasError,
    shouldShowNotification,
    setShouldShowNotification,
    onEventClick,
    relevantEvents,
    onboardingEventsToAddScribeTo,
    meetingsAndSuggestions,
    updatingEvents,
}: {
    updatingEvents: string[];
    isOnboardingFlow: boolean;
    hasError: boolean;
    shouldShowNotification: boolean;
    setShouldShowNotification: SetValue<boolean>;
    highlightedMeetingICalUid?: string;
    onEventClick: (event: CalendarEvent) => void;
    relevantEvents: CalendarEvent[];
    onboardingEventsToAddScribeTo: CalendarEvent[];
    meetingsAndSuggestions?: MeetingsAndSuggestions;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [eventHovered, setEventHovered] = useState<null | string>(null);
    const {
        openedMeetingsState: { highlightedMeetingDetails },
    } = useGlobalAiMeetingSelection();
    const isNonOrganizerAllowedToRemoveSpinach = useIsNonOrganizerAllowedToRemoveSpinach();

    const highlightedMeetingICalUid = highlightedMeetingDetails?.iCalUid;

    function mapEventRows(eventsToRender: CalendarEvent[]) {
        return eventsToRender.map((event) => {
            const updatingEventId = getMasterEventId(event);
            const isUpdatingEvent = updatingEvents.includes(updatingEventId!);
            const isOrganizer = user.isUserTheOrganizer(event);
            const isScribeOn = isScribeOnEvent(event);
            const isInteractive = isOnboardingFlow
                ? // in onboarding you can add/remove as much as you want, bulk action is performed on continue click
                  !isScribeOn || onboardingEventsToAddScribeTo.includes(event)
                : isNonOrganizerAllowedToRemoveSpinach || isOrganizer || !isScribeOn;
            return (
                <MeetingContainer
                    id={`meeting-${event.iCalUID}`}
                    onMouseEnter={() => {
                        if (event.iCalUID) {
                            setEventHovered(event.iCalUID);
                        }
                    }}
                    onMouseLeave={() => {
                        setEventHovered(event.iCalUID!);
                    }}
                    isHighlighted={Boolean(highlightedMeetingICalUid && highlightedMeetingICalUid === event.iCalUID)}
                    isInteractive={isInteractive && !isUpdatingEvent}
                    key={event.iCalUID}
                    onClick={() => {
                        if (isUpdatingEvent) {
                            return;
                        }
                        onEventClick(event);
                    }}
                >
                    <MeetingItem
                        event={event}
                        isEventHovered={eventHovered === event.iCalUID}
                        isChecked={isScribeOn || (isOnboardingFlow && onboardingEventsToAddScribeTo.includes(event))}
                        isInteractive={isInteractive && !isUpdatingEvent}
                        showTooltip={isOrganizer || !isInteractive}
                        isUpdatingEvent={isUpdatingEvent}
                        isOnboarding={isOnboardingFlow}
                    />
                    <Notification
                        isOpen={Boolean(
                            shouldShowNotification &&
                                highlightedMeetingICalUid &&
                                event.iCalUID === highlightedMeetingICalUid
                        )}
                        onClose={() => setShouldShowNotification(false)}
                        message={'Successfully added Spinach!'}
                        duration={1800}
                        icon={
                            <CheckCircleIcon
                                style={{ color: lightTheme.neutrals.white, height: '20px' }}
                                htmlColor={lightTheme.neutrals.white}
                            />
                        }
                        containerStyle={{
                            position: 'absolute',
                            top: 'unset',
                            bottom: 'unset',
                            width: 'calc(100% - 100px)',
                            left: 'unset',
                            right: 'unset',
                            transform: 'unset',
                        }}
                        contentStyle={{
                            width: '100%',
                            height: '23px',
                            borderRadius: '3px',
                            border: 'solid',
                            borderWidth: 'thin',
                        }}
                        messageStyle={{
                            padding: 'unset',
                        }}
                        transitionComponent={TransitionLeft}
                    />
                </MeetingContainer>
            );
        });
    }

    let CalendarSelection;
    if (meetingsAndSuggestions?.suggestionsWithoutSpinach.length) {
        CalendarSelection = (
            <>
                {meetingsAndSuggestions.suggestionsWithoutSpinach ? (
                    <>
                        <Row centered>
                            <MeetingListLabel>Team meetings </MeetingListLabel>
                            <BootstrapTooltip
                                title={
                                    'Spinach works best on recurring team meetings like daily standup. We’ve pre-selected a few meetings that look like a great fit!'
                                }
                                interactive={true}
                                placement="top"
                                style={{ fontSize: '16px' }}
                            >
                                <Info style={{ marginLeft: '10px', marginTop: '2px' }} fontSize="small" />
                            </BootstrapTooltip>
                        </Row>
                        {mapEventRows(meetingsAndSuggestions.suggestionsWithoutSpinach)}
                    </>
                ) : null}
                {meetingsAndSuggestions.eventsWithoutSuggestions.length ? (
                    <>
                        <MeetingListLabel>More meetings</MeetingListLabel>
                        {mapEventRows(meetingsAndSuggestions.eventsWithoutSuggestions)}
                    </>
                ) : null}
            </>
        );
    } else {
        CalendarSelection = mapEventRows(relevantEvents);
    }

    useEffect(() => {
        if (highlightedMeetingICalUid) {
            document.getElementById(`meeting-${highlightedMeetingICalUid}`)?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [highlightedMeetingICalUid]);

    return (
        <MeetingsScrollViewContainer>
            <MeetingsScrollView hasError={hasError}>
                <MeetingsContainer onMouseLeave={() => setEventHovered(null)}>{CalendarSelection}</MeetingsContainer>
            </MeetingsScrollView>
        </MeetingsScrollViewContainer>
    );
}
