import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import styled from 'styled-components';

import { BaseIDRequest, ClientEventType, ClientSocketEvent } from '@spinach-shared/types';

import { ReactComponent as AddToIcon } from '../../assets/add-to.svg';
import { ReactComponent as ShuffleIcon } from '../../assets/shuffle.svg';
import { DRAWER_ANIMATION_DURATION_MS, ElementId } from '../../constants';
import { useExperienceTracking, useGlobalLiveSeries, useGlobalNavDrawer } from '../../hooks';
import { useGlobalMeetingSocket } from '../../hooks/useGlobalSocket';
import { BodyBig, BodyLarge, lightTheme } from '../../styles';
import { createWebsocketPayload } from '../../utils';
import { Column, Row } from '../common';
import SecondaryButton from './SecondaryButton';

const IcebreakerContainer = styled(Row)`
    justify-content: space-between;
    align-items: center;
`;

const IcebreakerRevealContainer = styled.div`
    display: flex;
    align-items: center;
`;

const IcebreakerQuestion = styled(BodyBig)`
    text-align: center;
    color: ${(props) => props.theme.primary.green};
`;

enum IcebreakerButton {
    Reveal = 'Reveal Button',
    Shuffle = 'Shuffle Button',
}

export function IcebreakerSection(): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();
    const { isIcebreakerStarted, isIcebreakerLockedIn, icebreakerQuestion, icebreakerShuffleCount } =
        liveSeries.currentMeeting;
    const track = useExperienceTracking();
    const [socket] = useGlobalMeetingSocket();
    const { openCheckIn } = useGlobalNavDrawer();

    const shuffleIcebreakerEmitter = (buttonClicked: IcebreakerButton) => {
        track(ClientEventType.IcebreakerClicked, {
            ButtonClicked: buttonClicked,
            Icebreaker: icebreakerQuestion,
            IcebreakerShuffleCount: icebreakerShuffleCount,
        });

        const request = createWebsocketPayload<BaseIDRequest>({
            spinachUserId: liveSeries.spinachUserId,
            seriesSlug: liveSeries.slug,
        });
        socket.emit(ClientSocketEvent.IcebreakerShuffle, request);
    };

    const lockInIcebreaker = () => {
        track(ClientEventType.IcebreakerLockedIn, {
            Icebreaker: icebreakerQuestion,
            IcebreakerShuffleCount: icebreakerShuffleCount,
        });
        const request = createWebsocketPayload<BaseIDRequest>({
            spinachUserId: liveSeries.spinachUserId,
            seriesSlug: liveSeries.slug,
        });
        socket.emit(ClientSocketEvent.IcebreakerLockIn, request);
    };

    const onAddResponseClick = () => {
        track(ClientEventType.AddIcebreakerResponseButtonClicked, {
            Icebreaker: icebreakerQuestion,
            IcebreakerShuffleCount: icebreakerShuffleCount,
        });
        openCheckIn();
        setTimeout(() => {
            const icebreakerSectionElement = document.getElementById(ElementId.IcebreakerSection);
            if (icebreakerSectionElement) {
                const textAreas = icebreakerSectionElement.getElementsByTagName('textarea');
                // there are multiple textareas per input, but those with IDs are the ones we want to target and focus
                const textAreasWithIds = [...textAreas].filter((ta) => !!ta.id);

                if (textAreasWithIds.length - 1 < 0) {
                    return;
                }

                const lowestEmptyInput = textAreasWithIds[textAreasWithIds.length - 1];
                const scrollArea = document.getElementById(ElementId.ScrollviewNav);

                const checkInContainer = document.getElementById(ElementId.CurrentCheckinContainer);
                const scrollDistance = checkInContainer?.getBoundingClientRect().height;
                const ENOUGH_TIME_TO_SCROLL = 500;

                if (lowestEmptyInput && scrollArea) {
                    scrollArea.scrollTo({
                        top: scrollDistance,
                        behavior: 'smooth',
                    });
                    setTimeout(() => {
                        lowestEmptyInput.focus();
                    }, ENOUGH_TIME_TO_SCROLL);
                }
            }
        }, DRAWER_ANIMATION_DURATION_MS);
    };

    return (
        <>
            {!isIcebreakerStarted && (
                <IcebreakerContainer>
                    <BodyLarge style={{ display: 'flex', justifyContent: 'left' }}>Icebreaker 🧊</BodyLarge>

                    <IcebreakerRevealContainer>
                        <SecondaryButton
                            title="Reveal"
                            style={{ zIndex: 20 }}
                            onClick={() => shuffleIcebreakerEmitter(IcebreakerButton.Reveal)}
                        />
                    </IcebreakerRevealContainer>
                </IcebreakerContainer>
            )}
            {isIcebreakerStarted && (
                <Row>
                    <Column>
                        <BodyLarge style={{ display: 'flex', justifyContent: 'left', marginBottom: '5px' }}>
                            Icebreaker 🧊
                        </BodyLarge>

                        <IcebreakerQuestion style={{ fontWeight: isIcebreakerLockedIn ? 600 : 400 }}>
                            {icebreakerQuestion}
                        </IcebreakerQuestion>
                        {isIcebreakerLockedIn && liveSeries.isRoundtableEnabled ? (
                            <Row centered style={{ alignItems: 'center', marginTop: '10px' }}>
                                <SecondaryButton onClick={onAddResponseClick} title="Add your response" />
                            </Row>
                        ) : (
                            <>
                                {liveSeries.isRoundtableEnabled ? (
                                    <Row centered style={{ alignItems: 'center', marginTop: '10px' }}>
                                        <AddToIcon
                                            style={{ marginRight: '5px', cursor: 'pointer' }}
                                            onClick={() => {
                                                lockInIcebreaker();
                                            }}
                                        />
                                        <SecondaryButton
                                            onClick={() => {
                                                lockInIcebreaker();
                                            }}
                                            title={'Add to check-ins'}
                                        />
                                    </Row>
                                ) : null}
                            </>
                        )}
                    </Column>

                    <Column style={{ width: '20px', justifyContent: 'center' }}>
                        {isIcebreakerLockedIn ? (
                            <CheckIcon htmlColor={lightTheme.primary.green} />
                        ) : (
                            <ShuffleIcon
                                style={{ fill: lightTheme.primary.green, cursor: 'pointer' }}
                                onClick={() => shuffleIcebreakerEmitter(IcebreakerButton.Shuffle)}
                            />
                        )}
                    </Column>
                </Row>
            )}
        </>
    );
}
