import { Box, Modal } from '@material-ui/core';
import MicrophoneIcon from '@material-ui/icons/Mic';
import { useState } from 'react';
import React from 'react';
import { v4 } from 'uuid';
import { z } from 'zod';

import {
    getNewAgentChatMessage,
    getNewAgentTopic,
    getRoundtableTopicsToAdd,
    pseudoUniqueParticipantIdSuffix,
} from '@spinach-shared/models';
import { RecallBotSenderPlatform } from '@spinach-shared/types';

import { BootstrapTooltip, copyTextToClipboard, setControlPageWindow, useGlobalVideoAgent } from '../../../..';
import { GlobalModal } from '../../../atoms';
import { useGlobalModal } from '../../../hooks';
import { BodyRegular } from '../../../styles';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { SpinachModalContent } from '../SpinachModalContent';
import { Row } from '../framing';

// TODO better send type
export function VideoAgentTestControlsModal({ send }: { send: any }) {
    const [globalModal, setGlobalModal] = useGlobalModal();
    const { session, setSession } = useGlobalVideoAgent();
    const [topicIndexToRemove, setTopicIndexToRemove] = useState('');
    const [topicIndexToJumpTo, setTopicIndexToJumpTo] = useState('');
    const [testChatMessage, setTestChatMessage] = useState('');
    const [testTranscription, setTestTranscription] = useState('');
    const [newParticipantName, setNewParticipantName] = useState('');
    const [isMicEnabled, setIsMicEnabled] = useState(false);
    const deepgramToken = React.useMemo(() => {
        const value =
            new URLSearchParams(window.location.hash?.replace('#', '')).get('dg') ??
            localStorage.getItem('deepgramToken');
        if (value) {
            localStorage.setItem('deepgramToken', value);
        }
        window.location.hash = '';
        return value;
    }, []);

    React.useEffect(() => {
        if (deepgramToken && isMicEnabled) {
            let mediaRecorder: MediaRecorder | null = null;
            let socket: WebSocket | null = null;
            let stream: MediaStream | null = null;

            const wordBoost = ['hey-spinach:4'];

            // convert to keywords=hey-spinach:4&keywords=hey-spinach-next:3
            const keywords = wordBoost.map((word) => `keywords=${word}`).join('&');

            const startRecording = async () => {
                try {
                    setTimeout(() => {
                        window.location.reload();
                    }, 5 * 60 * 1000); // reload page every 5 minutes to prevent credits to run out

                    stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    mediaRecorder = new MediaRecorder(stream);
                    socket = new WebSocket(`wss://api.deepgram.com/v1/listen?interim_results=true&${keywords}`, [
                        'token',
                        deepgramToken,
                    ]);

                    socket.onopen = () => {
                        mediaRecorder?.addEventListener('dataavailable', (event) => {
                            if (event.data.size > 0 && socket?.readyState === 1) {
                                socket?.send(event.data);
                            }
                        });
                        mediaRecorder?.start(250);
                    };

                    socket.onmessage = (message) => {
                        const received = JSON.parse(message.data);

                        if (received.type !== 'Results') {
                            console.error('Unexpected message type', received);
                            return;
                        }
                        const msg = z
                            .object({
                                type: z.literal('Results'),
                                channel_index: z.array(z.number()),
                                duration: z.number(),
                                start: z.number(),
                                is_final: z.boolean(),
                                speech_final: z.boolean(),
                                channel: z.object({
                                    alternatives: z.array(
                                        z.object({
                                            transcript: z.string(),
                                            confidence: z.number(),
                                            words: z.array(
                                                z.object({
                                                    word: z.string(),
                                                    start: z.number(),
                                                    end: z.number(),
                                                    confidence: z.number(),
                                                })
                                            ),
                                        })
                                    ),
                                }),
                                metadata: z.object({
                                    request_id: z.string(),
                                    model_info: z.object({
                                        name: z.string(),
                                        version: z.string(),
                                        arch: z.string(),
                                    }),
                                    model_uuid: z.string(),
                                }),
                                from_finalize: z.boolean(),
                            })
                            .parse(received);

                        const transcript = msg.channel.alternatives[0];
                        if (transcript.words.length === 0) {
                            return;
                        }

                        const toSend = {
                            data: JSON.stringify({
                                bot_id: '00000000-0000-0000-0000-000000000000',
                                recording_id: '',
                                transcript: {
                                    original_transcript_id: 1,
                                    speaker: 'bob',
                                    speaker_id: 1,
                                    words: transcript.words.map((word) => ({
                                        text: word.word,
                                        start_time: word.start,
                                        end_time: word.end,
                                    })),
                                    is_final: msg.is_final,
                                    language: 'en-us',
                                    source: 'meeting_captions',
                                },
                            }),
                        };
                        (window as any).recallWebSocketRef.onmessage(toSend);
                    };

                    socket.onclose = () => {
                        console.log('WebSocket closed');
                    };

                    socket.onerror = (error) => {
                        console.error('WebSocket error:', error);
                    };
                } catch (error) {
                    console.error('Error accessing media devices.', error);
                }
            };

            startRecording();

            return () => {
                if (mediaRecorder && mediaRecorder.state !== 'inactive') {
                    mediaRecorder.stop();
                }
                if (socket) {
                    socket.close();
                }
                if (stream) {
                    stream.getTracks().forEach((track) => track.stop());
                }
            };
        }
    }, [deepgramToken, isMicEnabled]);

    const closeModal = () => {
        setGlobalModal(null);
    };

    const openControlPage = () => {
        const width = 600;
        const height = 1024;
        const left = 0;
        const top = 0;
        const newWindow = window.open(
            `${window.origin}/control`,
            'ControlPage',
            `width=${width},height=${height},left=${left},top=${top}`
        );
        setControlPageWindow(newWindow)
        setGlobalModal(null);
    };

    if (!session) {
        return <></>;
    }

    return (
        <Modal
            open={globalModal === GlobalModal.VideoAgentTestControls}
            onClose={closeModal}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden', minHeight: 'unset' }}>
                <Row style={{ justifyContent: 'space-around' }}>
                    <SecondaryButton
                        title="Start Agenda"
                        onClick={() => {
                            const startedAgenda = session.withStartedAgenda();
                            setSession(startedAgenda);
                        }}
                    />
                    <SecondaryButton
                        title="Back"
                        onClick={() => {
                            const previousAgenda = session.withBackToPreviousTopic();
                            setSession(previousAgenda);
                        }}
                    />
                    <SecondaryButton
                        title="Next"
                        onClick={() => {
                            const progressedAgenda = session.withProgressedAgenda();
                            setSession(progressedAgenda);
                        }}
                    />
                </Row>
                <Row centered={true} style={{ justifyContent: 'space-around' }}>
                    <div>
                        <BodyRegular>Remove Topic #</BodyRegular>
                        <input
                            style={{ width: '40px' }}
                            value={topicIndexToRemove}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const parsed = parseInt(topicIndexToRemove);
                                    if (!isNaN(parsed)) {
                                        const agendaWithRemovedTopic = session.withRemovedTopicByNumber(parsed);
                                        setSession(agendaWithRemovedTopic);
                                    }
                                }
                            }}
                            onChange={(e) => {
                                setTopicIndexToRemove(e.target.value);
                            }}
                        />
                    </div>

                    <div>
                        <BodyRegular>Jump to Topic #</BodyRegular>
                        <input
                            style={{ width: '40px' }}
                            value={topicIndexToJumpTo}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const parsed = parseInt(topicIndexToJumpTo);
                                    if (!isNaN(parsed)) {
                                        const agendaWithTopicJumpedTo = session.withJumpedToTopicByNumber(parsed);
                                        setSession(agendaWithTopicJumpedTo);
                                    }
                                }
                            }}
                            onChange={(e) => {
                                setTopicIndexToJumpTo(e.target.value);
                            }}
                        />
                    </div>
                </Row>
                <Row centered={true}>
                    <BootstrapTooltip
                        placement={'right'}
                        interactive={true}
                        title={<code style={{}}>{JSON.stringify(session, null, '\n')}</code>}
                    >
                        <div>
                            <SecondaryButton
                                title={'Copy / View Session JSON'}
                                onClick={() => {
                                    copyTextToClipboard(JSON.stringify(session, null, 2));
                                }}
                            />
                        </div>
                    </BootstrapTooltip>
                </Row>
                <Row centered={true} style={{ justifyContent: 'space-around' }}>
                    <div>
                        <BodyRegular>Send chat message</BodyRegular>
                        <input
                            style={{ width: '200px' }}
                            value={testChatMessage}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const newChatMessage = getNewAgentChatMessage({
                                        bot_id: session.botId,
                                        created_at: new Date().toISOString(),
                                        text: testChatMessage,
                                        sender: {
                                            name: 'username',
                                            id: 1,
                                            platform: RecallBotSenderPlatform.Desktop,
                                            is_host: true,
                                            extra_data: {},
                                        },
                                        to: 'everyone',
                                    });

                                    setSession({
                                        ...session.toJSON(),
                                        chatMessages: [...session.chatMessages, newChatMessage],
                                    });

                                    setTestChatMessage('');
                                }
                            }}
                            onChange={(e) => {
                                setTestChatMessage(e.target.value);
                            }}
                        />
                    </div>
                </Row>
                <Row centered={true} style={{ justifyContent: 'space-around' }}>
                    <div>
                        <BodyRegular>Send transcription</BodyRegular>
                        <input
                            style={{ width: '200px' }}
                            value={testTranscription}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    send({
                                        type: 'NEW_TEXT',
                                        data: {
                                            id: v4(),
                                            text: testTranscription,
                                            final: true,
                                            speaker: 'You',
                                            rawWords: testTranscription.split(' ').map((word) => ({
                                                text: word,
                                                startTimestamp: Date.now(),
                                                endTimestamp: Date.now(),
                                            })),
                                            // TODO - might need a better timestamp
                                            timestamp: new Date().toISOString(),
                                        },
                                    });
                                    setTestTranscription('');
                                    setGlobalModal(null);
                                }
                            }}
                            onChange={(e) => {
                                setTestTranscription(e.target.value);
                            }}
                        />
                    </div>
                </Row>
                <Row centered={true} style={{ justifyContent: 'space-around' }}>
                    <div>
                        <BodyRegular>Add Participant by Name</BodyRegular>
                        <input
                            style={{ width: '200px' }}
                            value={newParticipantName}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const roundtableTopicsToAdd = getRoundtableTopicsToAdd(
                                        session.isRoundtableEnabled,
                                        [
                                            {
                                                name: newParticipantName,
                                                pseudoUniqueId: `${newParticipantName}${pseudoUniqueParticipantIdSuffix}`,
                                            },
                                        ],
                                        session.topics
                                    );

                                    let updatedSession = session;
                                    for (const newTopic of roundtableTopicsToAdd) {
                                        updatedSession = updatedSession.withAddedTopic(newTopic);
                                    }

                                    setSession(updatedSession);
                                    setNewParticipantName('');
                                    setGlobalModal(null);
                                }
                            }}
                            onChange={(e) => {
                                setNewParticipantName(e.target.value);
                            }}
                        />
                    </div>
                </Row>
                <Row centered={true} style={{ justifyContent: 'space-around', marginBottom: '20px' }}>
                    {deepgramToken ? (
                        <Row
                            onClick={() => {
                                setIsMicEnabled((e) => !e);
                            }}
                        >
                            <MicrophoneIcon
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    color: isMicEnabled ? 'green' : 'red',
                                }}
                            />
                            <SecondaryButton
                                title={`Voice Input via microphone (currently ${isMicEnabled ? 'on' : 'off'})`}
                                onClick={() => {}}
                            />
                        </Row>
                    ) : (
                        'make sure to pass token as #dg=token in the url to enable mic'
                    )}
                </Row>
                {/* Add a link to open the control page */}
                <Row centered={true} style={{ justifyContent: 'space-around', marginBottom: '20px' }}>
                    <SecondaryButton title="Open Control Page" onClick={openControlPage} />
                </Row>
            </SpinachModalContent>
        </Modal>
    );
}
