import { ISOString } from '@spinach-shared/types';

import { BaseAgendaItemProps } from '../agenda-item';
import { BaseAgendaProps, IBaseAgendaProps } from './BaseAgendaProps';

export type IActiveAgendaProps = IBaseAgendaProps & {
    startedAt: ISOString;
    currentIndex: number;
};

export class ActiveAgendaProps extends BaseAgendaProps {
    currentIndex: number;
    startedAt: ISOString;

    constructor(props: IActiveAgendaProps) {
        super(props);
        this.currentIndex = props.currentIndex;
        this.startedAt = props.startedAt;
    }

    get currentItem(): BaseAgendaItemProps {
        return this.items[this.currentIndex];
    }
}
