import mixpanel from 'mixpanel-browser';

import { ClientEventType } from '@spinach-shared/types';
import { getStage } from '@spinach-shared/utils';

import { postExperienceEvent } from '../apis';
import { getClientPlatform, isZoomPlatform } from './platform';

/**
 * Used to track anonymous users from marketing site to web app.
 * Events should only be tracked with this when their user ID is not known,
 * eg before successful authorization on web auth views.
 *
 * Since this does not funnel through the backend, properties needing
 * redaction (eg Email) should also be handled here where applicable
 * */
export class AnonymousUserTracker {
    static init() {
        if (!isZoomPlatform()) {
            mixpanel.init('60f5c184f303195dffbdabf457485a8b');
        }
    }

    static trackUser(userId: string) {
        if (!isZoomPlatform()) {
            mixpanel.identify(userId);
        }
    }

    static trackEvent(eventName: ClientEventType, properties: any = {}) {
        const props = { ...properties };

        props.Platform = getClientPlatform();
        props.Stage = getStage();

        if (!isZoomPlatform()) {
            mixpanel.track(eventName, props);
        } else {
            postExperienceEvent({ eventType: eventName, payload: props });
        }
    }
}
