import { ElementId } from '../constants';
import { isWebPlatform } from './platform';

export function getDemoBannerHeight(): number {
    const height = document.getElementById(ElementId.DemoBanner)?.offsetHeight ?? 45;
    return height;
}

const VERTICAL_PADDING_HEIGHT_PX = 40;
const CTA_BUTTON_ROW = 14;
const PROGRESS_BAR_HEIGHT_PX = 38;
const NAV_BAR_HEIGHT_PX = 62;

const HEIGHT_OFFSET_WITHOUT_NAV = VERTICAL_PADDING_HEIGHT_PX + PROGRESS_BAR_HEIGHT_PX + CTA_BUTTON_ROW;
const HEIGHT_OFFSET_WITH_NAV = HEIGHT_OFFSET_WITHOUT_NAV + NAV_BAR_HEIGHT_PX;
const HEIGHT_DEMO_OS_BORDER = 55;

export function getHeightOffset(withNav: boolean, withDemoBanner: boolean): number {
    let offset = 0;

    if (withDemoBanner) {
        offset += getDemoBannerHeight();
        offset += HEIGHT_DEMO_OS_BORDER;
    }

    if (!isWebPlatform()) {
        offset -= 20;
    }

    if (withNav) {
        offset += HEIGHT_OFFSET_WITH_NAV;
    } else {
        offset += HEIGHT_OFFSET_WITHOUT_NAV;
    }

    return offset;
}
