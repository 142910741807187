import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useState } from 'react';

import { BaseMeetingProps } from '@spinach-shared/models';

import { ScrollArea, Spacing, StandupSummary } from '..';
import { lightTheme } from '../..';
import { Notification } from '../stand-up/Notification';

export function OpenedSummary({ currentMeeting }: { currentMeeting: BaseMeetingProps }): JSX.Element {
    const [isNotificationToastOpen, setIsNotificationToastOpen] = useState(false);

    if (!currentMeeting) {
        return <></>;
    }

    return (
        <>
            <Spacing factor={1 / 1.5} />

            <ScrollArea
                style={{ height: '10vh', flexGrow: 1, paddingBottom: '20px', overflow: 'visible' }}
                sidePadding={0}
                useGrayShading={true}
            >
                <StandupSummary currentMeeting={currentMeeting} />
            </ScrollArea>

            <Notification
                containerStyle={{
                    position: 'relative',
                    top: '-75px',
                    height: '0',
                    margin: 'auto',
                }}
                isOpen={isNotificationToastOpen}
                duration={4000}
                onClose={() => setIsNotificationToastOpen(false)}
                message={'Summary copied!'}
                icon={
                    <CheckCircleIcon
                        style={{ color: lightTheme.neutrals.white }}
                        htmlColor={lightTheme.neutrals.white}
                    />
                }
            />
        </>
    );
}
