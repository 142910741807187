import { PatchSeriesRequest, SpinachAPIPath, StoredSeries } from '@spinach-shared/types';

import { patchSpinachAPI } from './patchSpinachAPI';

export async function patchSeries(seriesId: string, request: PatchSeriesRequest): Promise<StoredSeries | undefined> {
    const data = await patchSpinachAPI<{ series?: StoredSeries }>( // todo returns user
        SpinachAPIPath.PatchSeries.replace(':seriesId', seriesId),
        request
    );
    return data.series;
}
