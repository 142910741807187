import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getStoredSeries, getUser } from '../../../apis';
import { useGlobalNullableStoredSeries, useGlobalRouting, useGlobalUser, useLocationalSeriesId } from '../../../hooks';

export function useLoadSeriesOnMount() {
    const locationalSeriesId = useLocationalSeriesId();
    const location = useLocation();
    const [series, setSeries] = useGlobalNullableStoredSeries();
    const [user, setUser] = useGlobalUser();
    const { routeToDashboard } = useGlobalRouting();

    useEffect(() => {
        async function loadSeries(): Promise<void> {
            if (locationalSeriesId) {
                const fetchedSeries = await getStoredSeries(locationalSeriesId);

                if (!user?.getSeriesById(locationalSeriesId)) {
                    const userResponse = await getUser(); // new series might not be on user object yet, so we have to refetch it
                    if (userResponse.user) {
                        setUser(userResponse.user);
                    }
                }

                if (fetchedSeries) {
                    setSeries(fetchedSeries);
                } else {
                    routeToDashboard({ replace: true });
                }
            }
        }
        if (!series) {
            loadSeries();
        }
    }, [location.pathname, series]);

    return series;
}
