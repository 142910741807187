import { atom } from 'recoil';
import { Socket } from 'socket.io-client';

export const atomDashboardSocket = atom<Socket | null>({
    key: 'socket',
    default: null,
    dangerouslyAllowMutability: true,
});

export const atomMeetingSocket = atom<Socket | null>({
    key: 'meeting-socket',
    default: null,
    dangerouslyAllowMutability: true,
});

export const atomVideoAgentSocket = atom<Socket | null>({
    key: 'video-agent-socket',
    default: null,
    dangerouslyAllowMutability: true,
});
