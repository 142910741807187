import { atom } from 'recoil';

import { AtlassianGetAvailableResourceItem, SpinachIntegration } from '@spinach-shared/types';

export type GlobalAtlassianSitesState = {
    [SpinachIntegration.Jira]?: AtlassianGetAvailableResourceItem[];
    [SpinachIntegration.Confluence]?: AtlassianGetAvailableResourceItem[];
};

export const globalAtlassianSites = atom<GlobalAtlassianSitesState | null>({
    key: 'atlassianSitesAtom',
    default: {},
});
