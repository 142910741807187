import { Box, CircularProgress, TextField, makeStyles } from '@material-ui/core';
import { CancelOutlined, Check, InfoOutlined } from '@material-ui/icons';
import { ClipboardEventHandler, useEffect, useState } from 'react';
import { DefaultTheme } from 'styled-components';

import {
    ClientEventType,
    ConfluenceSpaceType,
    MinimalAtlassianGetAvailableResourceItem,
    MinimalConfluenceSpace,
    SpinachIntegration,
} from '@spinach-shared/types';
import { getConfluenceSiteAndSpaceData, hasConfluenceSiteAndSpaceData } from '@spinach-shared/utils';

import {
    BodyRegularOnboard,
    BootstrapTooltip,
    ClientLogger,
    Column,
    Hyperlink,
    SetValue,
    Spacing,
    URLUtil,
    fetchConfluenceSpaces,
    getUser,
    lightTheme,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
} from '../../../..';
import { patchConfluenceSpace } from '../../../apis/confluence/patchConfluenceSpace';
import { patchAtlassianSite } from '../../../apis/patchAtlassianSite';
import { useAtlassianSites } from '../../../hooks/useAtlassianSites';
import { Row } from '../framing';

export const useStyles = makeStyles<
    DefaultTheme,
    {
        value: string;
        isLoading?: boolean;
        disabled?: boolean;
        isMouseIn?: boolean;
    }
>({
    base: {
        fontFamily: 'Gilroy',
    },
    root: () => ({
        '& .MuiInputBase-input': {
            color: lightTheme.primary.midnight,
            textDecoration: () => 'unset',
            fontStyle: (props) => (props.isLoading ? 'italic' : 'initial'),
            textDecorationColor: () => 'unset',
            cursor: (props) => (props.disabled ? 'not-allowed' : 'text'),
        },
        borderBottom: 'none',

        '& .MuiInput-underline': {
            borderBottom: () => 'none',
            '&:hover': {
                borderBottom: () => 'none',
                backgroundColor: (props) => (props.disabled ? 'none' : 'none'),
                '&:before': {
                    borderBottom: 'none',
                    backgroundColor: 'none',
                },
            },
            '&.Mui-focused': {
                borderBottom: `none`,
                backgroundColor: (props) => (props.disabled ? lightTheme.neutrals.grayLight : 'none'),
            },
            '&:after': {
                backgroundColor: 'none',
                borderBottom: 'none',
            },
            '&:before': {
                backgroundColor: 'none',
                borderBottom: 'none',
            },
        },
        '& .MuiInputBase-multiline': {
            padding: '3px 0 3px',
        },
    }),
});

export function ConfluenceSiteSelectionContent({ setIsDoneDisabled }: { setIsDoneDisabled: SetValue<boolean> }) {
    const [user, setUser] = useGlobalAuthedUser();
    const { atlassianSites, isLoading: isAtlassianSiteFetchLoading } = useAtlassianSites();
    const { setToastText } = useGlobalAiDashboard();

    const [isHovered, setIsHovered] = useState(false);

    const [selectedConfluenceSpaceKey, setSelectedConfluenceSpaceKey] = useState<string | undefined>(
        user.integrationSettings?.confluenceSettings?.defaultSpaceKey
    );
    const [selectedConfluenceSpace, setSelectedConfluenceSpace] = useState<MinimalConfluenceSpace | undefined>();
    const confluenceAccountUrl = user.integrationSettings?.confluenceSettings?.confluenceAccountUrl;
    const specificAtlassianSites = atlassianSites?.[SpinachIntegration.Confluence];
    const minimalAtlassianSites = specificAtlassianSites?.map(
        (site): MinimalAtlassianGetAvailableResourceItem => ({
            id: site.id,
            url: site.url,
            name: site.name,
        })
    );
    const confluenceAccountId = user.integrationSettings?.confluenceSettings?.confluenceAccountId;
    const [selectedAtlassianSite, setSelectedAtlassianSite] = useState<
        MinimalAtlassianGetAvailableResourceItem | undefined
    >(
        confluenceAccountId && confluenceAccountUrl
            ? {
                  id: confluenceAccountId,
                  url: confluenceAccountUrl,
              }
            : minimalAtlassianSites?.length === 1
            ? minimalAtlassianSites[0]
            : undefined
    );
    const [value, setValue] = useState<string | undefined>(
        selectedAtlassianSite && selectedConfluenceSpaceKey
            ? `${selectedAtlassianSite?.url}/wiki/spaces/${selectedConfluenceSpace?.key ?? selectedConfluenceSpaceKey}`
            : undefined
    );

    const track = useExperienceTracking();

    const [isAtlassianSiteSelectionLoading, setIsAtlassianSiteSelectionLoading] = useState(false);
    const styles = useStyles({ value: value ?? '', isLoading: !!isAtlassianSiteSelectionLoading });
    const [isRemoveSiteLoading, setIsRemoveSiteLoading] = useState(false);

    useEffect(() => {
        setIsDoneDisabled(!selectedAtlassianSite || !selectedConfluenceSpaceKey);
    }, [selectedAtlassianSite, selectedConfluenceSpaceKey, setIsDoneDisabled]);

    const confluenceOnPaste: ClipboardEventHandler | undefined = async (event) => {
        try {
            const clipboardData = event.clipboardData.getData('text/plain');
            setValue(clipboardData);

            /**
             * @NOTE explanation, 4 groups are matched in the regex
             * 1. Atlassian site URL
             *    a. This is found by looking for `https:` followed by any number of characters until `.net`
             *    b. All Atlassian Cloud URLs end with `.net`
             * 2. Any characters after the Atlassian site URL until the end of word `spaces/` (with a backslash captured)
             * 3. Any characters after the word `spaces/` until the next backslash or end of string
             *    a. This will capture the "space key" which is unique to each space
             *    b. This will capture things like `/someSpaceKey/pages/12345789/Spinach+Meeting+Notes and /someSpaceKey` and `/someSpaceKey/`
             * 4. The last group is the backslash or end of string
             *
             * Groups 1 and 3 are the only ones that are used in the code, groups 2 and 4 are ignored, but used to match the URL
             * and ensure that the URL is in the correct format
             * */

            const doesMatch = hasConfluenceSiteAndSpaceData(clipboardData);
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'User Pasted Confluence URL',
            });
            const toastText =
                'INVALID URL: ACCESS THE CONFLUENCE SPACE AND ENSURE SPINACH HAS THE NECESSARY PERMISSIONS BEFORE COPYING THE URL';
            if (!doesMatch) {
                setToastText(toastText);
                setIsAtlassianSiteSelectionLoading(false);
                setValue('');
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'User Pasted Invalid Confluence URL',
                    InvalidAtlassianSite: true,
                });
                return;
            }

            const { atlassianSite, confluenceSpaceData } = getConfluenceSiteAndSpaceData(clipboardData);

            setIsAtlassianSiteSelectionLoading(true);

            if (!atlassianSite) {
                setToastText(toastText);
                setIsAtlassianSiteSelectionLoading(false);
                setValue('');
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'User Pasted Invalid Confluence URL',
                    InvalidAtlassianSite: true,
                });
                return;
            }

            const atlassianSiteInList = minimalAtlassianSites?.find((site) => site.url === atlassianSite);
            if (!atlassianSiteInList) {
                setToastText(toastText);
                setIsAtlassianSiteSelectionLoading(false);
                setValue('');
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'User Pasted Invalid Confluence URL',
                    ErrorReason: 'Site not in list',
                    SitesAvailable: minimalAtlassianSites?.map((site) => site.url),
                    SpaceDataMatchedInPastedURL: confluenceSpaceData,
                    SiteDataMatchedInPastedURL: atlassianSite,
                    IsAtlassianSiteFetchLoading: isAtlassianSiteFetchLoading,
                });
                return;
            }

            if (!confluenceSpaceData) {
                setToastText(toastText);
                setIsAtlassianSiteSelectionLoading(false);
                setValue('');
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'User Pasted Invalid Confluence URL',
                    ErrorReason: 'No space data found in URL',
                    SpaceDataMatchedInPastedURL: confluenceSpaceData,
                    SiteDataMatchedInPastedURL: atlassianSite,
                });
                return;
            }
            setSelectedAtlassianSite(atlassianSiteInList);
            await patchAtlassianSite(SpinachIntegration.Confluence, atlassianSiteInList);
            const validatedSpace = await fetchConfluenceSpaces({ keys: [confluenceSpaceData] });

            const ifNoSpaceData = !validatedSpace.length || validatedSpace.length !== 1;
            const ifDataKeyIsNotEqualToPastedValue = validatedSpace[0].key !== confluenceSpaceData;
            const ifSpaceIsPersonalButNotOwnedByUser =
                validatedSpace[0].type === ConfluenceSpaceType.Personal && !validatedSpace[0].isSelfSpace;

            if (ifNoSpaceData || ifDataKeyIsNotEqualToPastedValue || ifSpaceIsPersonalButNotOwnedByUser) {
                setToastText(toastText);
                setIsAtlassianSiteSelectionLoading(false);
                setValue('');
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'User Pasted Invalid Confluence URL',
                    ErrorReason: 'Space not valid',
                    SpaceDataMatchedInPastedURL: confluenceSpaceData,
                    SiteDataMatchedInPastedURL: atlassianSite,
                    ValidatedSpaceKey: validatedSpace[0]?.key,
                    ValidatedSpaceId: validatedSpace[0]?.id,
                    ValidatedSpaceName: validatedSpace[0]?.name,
                    ValidatedSpaceIsSelfSpace: validatedSpace[0]?.isSelfSpace,
                    ValidatedSpaceType: validatedSpace[0]?.type,
                });
                return;
            }

            await patchConfluenceSpace(validatedSpace[0]);
            const updatedUser = await getUser();
            if (updatedUser.user) {
                setUser(updatedUser.user);
            }
            setSelectedConfluenceSpace(validatedSpace[0]);
            setSelectedConfluenceSpaceKey(validatedSpace[0].key);
            setValue(`${atlassianSite}/wiki/spaces/${confluenceSpaceData}`);
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'User Pasted Valid Confluence URL',
                SpaceDataMatchedInPastedURL: confluenceSpaceData,
                SiteDataMatchedInPastedURL: atlassianSite,
                ValidatedSpaceKey: validatedSpace[0].key,
                ValidatedSpaceId: validatedSpace[0].id,
                ValidatedSpaceName: validatedSpace[0].name,
                ValidatedSpaceIsSelfSpace: validatedSpace[0].isSelfSpace,
                ValidatedSpaceType: validatedSpace[0].type,
            });
            setToastText('Site and Space successfully selected');
        } catch (e) {
            ClientLogger.error('Error in ConfluenceSiteSelectionContent when handling paste', e);
        } finally {
            setIsAtlassianSiteSelectionLoading(false);
        }
    };
    const isLocked = !!selectedAtlassianSite && !!selectedConfluenceSpaceKey;

    return (
        <>
            <Row>
                <Column>
                    <Box
                        style={{
                            fontWeight: 500,
                            fontSize: '17px',
                            textTransform: 'uppercase',
                            letterSpacing: '1.5px',
                        }}
                    >{`Confirm Site Selection`}</Box>
                    <Row style={{ alignItems: 'center' }}>
                        <BodyRegularOnboard>Paste a URL from your space</BodyRegularOnboard>
                        <BootstrapTooltip
                            interactive={true}
                            title={
                                isAtlassianSiteFetchLoading ? (
                                    'Loading Available Sites and Spaces'
                                ) : (
                                    <>
                                        Locate the Confluence Space you want summaries sent to, copy the URL, and ensure
                                        Spinach has permissions.{' '}
                                        <Hyperlink
                                            onClick={() =>
                                                URLUtil.openURL(
                                                    'https://intercom.help/spinach_io/en/articles/8070342-confluence-integration'
                                                )
                                            }
                                        >
                                            See details
                                        </Hyperlink>
                                    </>
                                )
                            }
                            placement="top"
                        >
                            <InfoOutlined
                                htmlColor={lightTheme.primary.greenLight}
                                style={{ marginRight: '10px' }}
                                fontSize="small"
                            />
                        </BootstrapTooltip>
                    </Row>
                    <Row style={{ alignItems: 'center' }}>
                        <TextField
                            id="confluence-paste-site-selection"
                            disabled={
                                isLocked ||
                                isAtlassianSiteSelectionLoading ||
                                isRemoveSiteLoading ||
                                isAtlassianSiteFetchLoading
                            }
                            value={isAtlassianSiteSelectionLoading ? 'Verifying...' : value}
                            classes={{ root: styles.root }}
                            onPaste={confluenceOnPaste}
                            placeholder="Paste Confluence URL here"
                            onMouseEnter={() => {
                                setIsHovered(true);
                            }}
                            onMouseLeave={() => {
                                setIsHovered(false);
                            }}
                            style={{
                                width: '90%',
                                marginBottom: '2px',
                                transform: 'scale(0.8)',
                                borderRadius: '39px',
                                backgroundColor: isLocked ? '#F4F6F6' : 'none',
                                transformOrigin: 'center left',
                                marginRight: '-70px',
                                padding: '2px 8px',
                            }}
                            InputProps={{
                                endAdornment:
                                    isAtlassianSiteSelectionLoading ||
                                    isRemoveSiteLoading ||
                                    isAtlassianSiteFetchLoading ? (
                                        <CircularProgress
                                            size={'20px'}
                                            style={{
                                                marginRight: '10px',
                                                color: lightTheme.primary.orangeDark,
                                            }}
                                        />
                                    ) : isHovered && isLocked ? (
                                        <CancelOutlined
                                            htmlColor={lightTheme.status.negative}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                if (isAtlassianSiteSelectionLoading || isRemoveSiteLoading) {
                                                    return;
                                                }
                                                setIsRemoveSiteLoading(true);
                                                setTimeout(() => {
                                                    setSelectedAtlassianSite(undefined);
                                                    setSelectedConfluenceSpace(undefined);
                                                    setSelectedConfluenceSpaceKey(undefined);
                                                    setValue('');
                                                    setIsRemoveSiteLoading(false);
                                                }, 500);
                                            }}
                                        />
                                    ) : isLocked ? (
                                        <Check fontSize="small" htmlColor={lightTheme.secondary.green} />
                                    ) : (
                                        <></>
                                    ),
                            }}
                            InputLabelProps={{
                                style: { padding: '5px', color: lightTheme.primary.midnight },
                                placeholder: 'Paste link here',
                            }}
                        />
                    </Row>
                    <BodyRegularOnboard>We'll store summaries from Spinach in this space</BodyRegularOnboard>
                    <Spacing factor={1 / 3} />
                    <BodyRegularOnboard>
                        Note: The page notes will only be visibile to you by default
                    </BodyRegularOnboard>
                </Column>
            </Row>
        </>
    );
}
