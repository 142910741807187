import { CalendarEvent, SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '../apis';

export const updateScribeOnCalendarEvent = async (iCalUid: string, addToEvent: boolean) => {
    const response = await postSpinachAPI<{ event: CalendarEvent }>(
        `${SpinachAPIPath.CalendarEvents}/${iCalUid}/scribe`,
        {
            addToEvent,
        },
        {
            throwOnError: true,
        }
    );

    return response?.event;
};
