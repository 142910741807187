import { useEffect } from 'react';

import { DateTimeMetadata, HHMMTimeString, ORDERED_DAYS_MAP } from '@spinach-shared/types';
import { getSelectedDateTimeInformation } from '@spinach-shared/utils';

import { SetValue } from '../types';
import { getDefaultDateTime } from '../utils';

export const useIsChangedForThisWeekOnly = (
    selectedDateTime: DateTimeMetadata | null,
    setSelectedDateTime: SetValue<DateTimeMetadata | null>,
    standupDayTimesSelected: DateTimeMetadata[],
    setStandupDayTimes: (standupDayTimes: DateTimeMetadata[]) => void,
    originalDateTimes: DateTimeMetadata[] = [],
    lastTimestampSelected: HHMMTimeString
) => {
    useEffect(() => {
        const selected = standupDayTimesSelected.find((dayTime) => dayTime.day === selectedDateTime?.day);
        if (selected && selectedDateTime?.isChangedForThisWeekOnly === false) {
            const syncedStandupDayTimes: DateTimeMetadata[] = standupDayTimesSelected
                .filter((dayTime) => dayTime.day !== selectedDateTime.day)
                .sort((a, b) => ORDERED_DAYS_MAP[a.day] - ORDERED_DAYS_MAP[b.day]);

            const originalDateTime = originalDateTimes.find((dayTime) => dayTime.day === selectedDateTime.day);

            if (originalDateTime) {
                setStandupDayTimes([
                    ...syncedStandupDayTimes,
                    { ...originalDateTime, isChangedForThisWeekOnly: false },
                ]);
                setSelectedDateTime({ ...originalDateTime, isChangedForThisWeekOnly: false });
            } else {
                const defaultDateTime = getDefaultDateTime(
                    standupDayTimesSelected,
                    lastTimestampSelected,
                    selectedDateTime.day
                );
                setSelectedDateTime({ ...defaultDateTime, meetingFormat: null });
                setStandupDayTimes([...syncedStandupDayTimes, { ...defaultDateTime, meetingFormat: null }]);
            }
        }
    }, [selectedDateTime?.isChangedForThisWeekOnly]);
};

export const useIsChangedForThisWeekOnlyValidation = (
    selectedDay: DateTimeMetadata | null
): { isValid: boolean; validationMessage?: string } => {
    if (getSelectedDateTimeInformation(selectedDay, 'meetingFormat') === null) {
        return {
            isValid: false,
            validationMessage: 'Toggling a meeting for this week only is not supported.',
        };
    }

    return { isValid: true };
};
