import React from 'react';
import styled from 'styled-components';

import { Column } from '../common';

const CenteredColumn = styled(Column)`
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-shrink: 0;
    z-index: 5;
`;

function StandUpFooter({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
    return <CenteredColumn>{children}</CenteredColumn>;
}

export default StandUpFooter;
