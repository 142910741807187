import { useState } from 'react';

import {
    CheckInFeatureDiscoveryKey,
    ClientEventType,
    EventEmitLocation,
    IntegrationCode,
    WebUrlQuery,
} from '@spinach-shared/types';
import { getUniques } from '@spinach-shared/utils';

import { patchUser } from '../../../apis';
import JiraIconSrc from '../../../assets/jira-hint-icon.png';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useIntegrationDetection,
    useJiraEnablement,
    useLandingAnalytic,
    useSeriesReality,
} from '../../../hooks';
import { URLUtil } from '../../../utils';
import { CheckInFeatureCallout, FeatureCalloutClickProps, UseCheckInCustomCalloutResult } from './FeatureCallout';

const metadata = { Location: EventEmitLocation.CheckInDrawer };

export function useJiraIntegrationCallout(): UseCheckInCustomCalloutResult {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const isNotBlackListedForJira = useJiraEnablement();

    const { isRealSeries } = useSeriesReality();
    const isJiraUser = !!user.metadata.integrationsProjectMgmt?.includes(IntegrationCode.JiraSoftware);
    const isNotIntegratedWithJira = !user.isAuthedForJira;
    const hasNotClosedCallout = !user.dismissedHints.includes(CheckInFeatureDiscoveryKey.JiraIntegration);

    const shouldShowThisHint =
        isNotBlackListedForJira && isJiraUser && isNotIntegratedWithJira && isRealSeries && hasNotClosedCallout;

    const [isVisible, setIsVisible] = useState(shouldShowThisHint);

    function onSuccess() {
        setIsVisible(false);
        patchUser({
            metadata: {
                dismissedHints: getUniques([...user.dismissedHints, CheckInFeatureDiscoveryKey.JiraIntegration]),
            },
        });
    }

    const startDetection = useIntegrationDetection(onSuccess);

    async function onEngageClick() {
        URLUtil.openURL(`${process.env.REACT_APP_AUTH_URL}/jira/auth-url/?${WebUrlQuery.Suid}=${user.spinachUserId}`);
        track(ClientEventType.OnIntegrateJiraCalloutClick, metadata);
        startDetection();
    }

    async function onCloseClick() {
        setIsVisible(false);
        track(ClientEventType.OnIntegrateJiraCalloutCloseClick, metadata);
        await patchUser({
            metadata: {
                dismissedHints: getUniques([...user.dismissedHints, CheckInFeatureDiscoveryKey.JiraIntegration]),
            },
        });
    }

    return {
        onEngageClick,
        onCloseClick,
        isVisible,
        feature: CheckInFeatureDiscoveryKey.JiraIntegration,
    };
}

export function JiraIntegrationFeatureCallout({ onCloseClick, onEngageClick }: FeatureCalloutClickProps): JSX.Element {
    useLandingAnalytic(ClientEventType.JiraIntegrationCalloutDisplayed, metadata);

    return (
        <CheckInFeatureCallout
            icon={<img src={JiraIconSrc} style={{ height: '20px', width: '20px' }} />}
            title={'Add a Jira Ticket!'}
            onEngageClick={onEngageClick}
            onCloseClick={onCloseClick}
        />
    );
}
