import { VideoAgentTranscriptPayload } from '@spinach-shared/schemas';

import { getAgentSearchParams } from '../../..';

export async function postTranscript(payload: VideoAgentTranscriptPayload[]): Promise<void> {
    const { scribeServer, token, botId } = getAgentSearchParams();
    await fetch(`${scribeServer}/video-agent/${botId}/transcript`, {
        method: 'POST',
        headers: {
            Authorization: token,
        },
        body: JSON.stringify(payload),
    });
}
