import { JoiningSpinachParticipant, UUID, UserMetadata } from '@spinach-shared/types';

import { BaseParticipantProps } from './BaseParticipantProps';

export class ConnectingSpinachParticipantProps extends BaseParticipantProps {
    spinachUserId: UUID;
    email: string;
    metadata?: UserMetadata;

    constructor(props: JoiningSpinachParticipant) {
        super(props);

        this.spinachUserId = props.spinachUserId;
        this.email = props.email;
        this.metadata = props.metadata;
    }

    toJSON(): JoiningSpinachParticipant {
        return {
            ...super.toJSON(),
            spinachUserId: this.spinachUserId,
            metadata: this.metadata,
            email: this.email,
        };
    }
}
