import { z } from 'zod';

export type VideoAgentTranscriptPersistdData = z.infer<typeof VideoAgentTranscriptPersistdDataSchema>;
export type VideoAgentTranscriptPayload = z.infer<typeof VideoAgentTranscriptPayloadSchema>;
export type VideoAgentTranscriptBase = z.infer<typeof VideoAgentTranscriptBase>;

const VideoAgentTranscriptBase = z.object({
    botId: z.string(),
    rawPayload: z.string(),
    speaker: z.string().optional(),
    words: z.string().optional(),
    rawWords: z
        .array(
            z.object({
                text: z.string(),
                startTimestamp: z.number(),
                endTimestamp: z.number(),
            })
        )
        .optional(),
    isFinal: z.boolean().optional(),
    original_transcript_id: z.string().optional(),
    firstWordTimestamp: z.number().optional(),
    estimatedAverageTranscriptDelay: z.number().optional(),
});

export const VideoAgentTranscriptPayloadSchema = VideoAgentTranscriptBase.extend({
    receivedAt: z.string(),
});

export const VideoAgentTranscriptPersistdDataSchema = VideoAgentTranscriptBase.extend({
    receivedAt: z.date(),
});
