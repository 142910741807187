import { DetailedTicket } from './Jira';
import { TicketSource } from './Tickets';
import { ISODateString } from './Time';

// correct detailed ticket type
// Proposed ticket has a title and description
export type AvatarProposedTicket = {
    ticketSource: TicketSource;
    ticketTitle: string;
    ticketDescription?: string;
    accepted?: boolean;
};

export type LiveTranscript = {
    id: string;
    timestamp: ISODateString;
    speaker: string;
    text: string;
    final: boolean;
    relatedAgendaItemId?: string;
};

export type AvatarTaskRequest = {
    id: string;
    commanderUserId: string;
    meetingId: string; // may need seriesID / meetingID
    seriesId: string; // may need seriesID / meetingID
    botId: string;
    transcriptChunkBeforeCommand: LiveTranscript[];
    transcriptChunkAfterCommand: LiveTranscript[];
};

export enum AvatarCreateTicketEvents {
    TicketRequested = 'ticket-requested',
    TicketApproved = 'ticket-approved',
    TicketRejected = 'ticket-rejected',
}

export type AvatarTaskEvents = AvatarCreateTicketEvents;

export enum AvatarTaskKind {
    CreateTicket = 'create-ticket',
    Unknown = 'unknown',
}

export type AvatarCreateTicketTask = AvatarTaskRequest & {
    taskKind: AvatarTaskKind.CreateTicket;
    proposedTicket?: AvatarProposedTicket;
    createdTicket?: DetailedTicket;
};

export type AgentSimulationOptions = {
    /** skips generation call and passes last transcription chunk as note into history */
    skipLiveNoteGeneration: boolean;

    /** Enabled by default, calls capture transcription chunks from sample list */
    mockTranscriptionEnabled: boolean;

    /** defaults to 30 seconds */
    mockTranscriptionInterval: number;

    /** Alternative to ?avatar=true which is sometimes a pain to deal with, such as when using popout windows */
    agentModeEnabled: boolean;

    /** Determines whether or not to show the agent video to the left of the sidebar experience */
    showAgentVideo: boolean;

    /** override the history to use - helpful for clearing live notes / shared state in testing */
    mockBotId: string;
};

// union of different tasks
export type AvatarTask = AvatarCreateTicketTask;

export enum AvatarDialogueState {
    IdleWithSuggestions,
    HeySpinachHeard,
    TaskUnknown,
    ProcessingTask,
    ProposingTask,
    TaskAccepted,
}

export type AvatarLiveNote = {
    id: string;
    context: string;
    relatedAgendaItemId?: string;
    createdBy: string;
    details?: {
        kind: string;
        description: string;
        user: string;
    }[];
};

export type AvatarSharedState = {
    botId: string;
    meetingId: string;
    seriesId: string;

    avatarTasks: AvatarTask[];

    dialogueState: AvatarDialogueState;

    liveNotes?: AvatarLiveNote[];
    topicObjectives?: AgentTopicObjective[];
    /** Currently used for topic object determination, where only lines are pushed when needed, so impartial transcript coverage */
    transcriptLines?: LiveTranscript[];
};

export type AgentTopicObjective = {
    id: string;
    objectiveText: string;
    topicId: string;
};

export type AvatarStatusResponse = {
    lastPausedAt: number | null;
    lastResumedAt: number | null;
    lastHeySpinach: number | null;
    lastTicketCreation?: string;
    lastOnboarding?: string;
    seriesId: string;
    botId: string;
};
