/** @NOTE moving animation code from /stand-up/animation.tsx to here for avoiding circular dep issues */
import { CSSProperties } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { lightTheme } from '../../styles';
import { Row } from './framing';

const fill = (props: FillKeyframeProps) => keyframes`
    0%, ${
        props.animationDuration && props.animationDelay && props.animationDelay !== 0
            ? `${Math.floor((props.animationDelay / (props.animationDuration + props.animationDelay)) * 100)}%`
            : ''
    } {
        background-color: ${props.startingColor};
    }
    100% { 
        background-color: ${props.endingColor};
    }
`;

export const fillAnimation = ({
    animationDuration = 0,
    startingColor,
    endingColor,
    animationDelay = 0,
}: FillAnimationProps) => css`
    animation: ${fill({ startingColor, endingColor, animationDuration, animationDelay })} ${animationDuration}ms
        infinite;
`;

export type FillAnimationProps = {
    fillDuration?: number;
    animationDuration?: number;
    startingColor: string;
    endingColor: string;
    animationDelay?: number;
};

type FillKeyframeProps = Pick<
    FillAnimationProps,
    'startingColor' | 'endingColor' | 'animationDelay' | 'animationDuration'
>;

const Box = styled.div`
    width: 7px;
    height: 7px;
    padding: 10px;
    border: 1px solid ${lightTheme.primary.orangeLight};
    margin: 7px;
`;

export const AnimatedBox = styled(Box)<{ animationDelay: number; animationDuration: number }>`
    ${(props) =>
        fillAnimation({
            startingColor: lightTheme.neutrals.clear,
            endingColor: lightTheme.primary.orangeLight,
            animationDuration: props.animationDuration,
            animationDelay: props.animationDelay,
        })};
    animation-duration: ${(props) => props.animationDuration + props.animationDelay}ms;
`;

export function LoadingSquares({ style }: { style?: CSSProperties }): JSX.Element {
    return (
        <Row style={{ justifyContent: 'center', ...style }}>
            <AnimatedBox animationDuration={1500} animationDelay={0} />
            <AnimatedBox animationDuration={1200} animationDelay={300} />
            <AnimatedBox animationDuration={900} animationDelay={600} />
            <AnimatedBox animationDuration={600} animationDelay={900} />
        </Row>
    );
}
