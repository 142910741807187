import { SetterOrUpdater, useRecoilState } from 'recoil';

import { GlobalAdminState, atomAdminState } from '../atoms';

export type SetGlobalAdminState = SetterOrUpdater<GlobalAdminState>;

export type GlobalAdminProps = {
    state: GlobalAdminState;
    setState: SetGlobalAdminState;
};

export function useGlobalAdmin(): GlobalAdminProps {
    const [state, setState] = useRecoilState(atomAdminState);
    // using an object for more extensibility
    return { state, setState };
}
