import { AiHistoryUserAccessKind, SpinachAPIPath } from '@spinach-shared/types';

import { patchSpinachAPI } from './patchSpinachAPI';

export const patchGiveMeetingNotesAccess = async (
    botId: string,
    updatedUsers: { email: string; kind: AiHistoryUserAccessKind }[]
) => {
    return await patchSpinachAPI<{ usersWithHistoryAccess: { email: string; kind: AiHistoryUserAccessKind }[] }>(
        SpinachAPIPath.ShareAIHistory.replaceAll(':botId', botId),
        { updatedUsers },
        { params: { botId } }
    );
};
