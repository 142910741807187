import { useCallback } from 'react';

import { getCommandContents } from '@spinach-shared/models';

import { SendFunction } from '../../../types/agent';

export function getIntroCommand(message: string): boolean {
    const supportedCommandTriggers = ['intro', 'spinach intro'];
    const { commandMatched } = getCommandContents(message, supportedCommandTriggers);

    return !!commandMatched;
}

export function useIntroCommand(send: SendFunction) {
    return useCallback(async () => {
        send('SHOW_INTRO');
    }, [send]);
}
