import { AiHistoryUserAccessKind, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function getUserEmailsWithHistoryAccess({
    botId,
}: {
    botId: string;
}): Promise<{ usersWithHistoryAccess: { email: string; kind: AiHistoryUserAccessKind }[] } | undefined> {
    const response = await getSpinachAPI<{
        usersWithHistoryAccess: { email: string; kind: AiHistoryUserAccessKind }[];
    }>(SpinachAPIPath.UsersWithAIHistoryAccess, {
        params: {
            botId,
        },
    });
    return response;
}
