import { z } from 'zod';

export const MeetingHeySpinachTicketCreationResultSchema = z.object({
    id: z.string(),
    content: z.string(),
    context: z.string(),
    justification: z.string(),
    username: z.string(),
    intentKind: z.enum(['ticket']),
    ticketTitle: z.string(),
    ticketDescription: z.string(),
    ticketData: z
        .object({
            title: z.string(),
            id: z.string(),
            displayName: z.string(),
            ticketSource: z.string(),
            ticketUrl: z.string(),
        })
        .nullish()
        .transform((x) => x ?? undefined),
});

export const MeetingHeySpinachResultSchema = z
    .object({
        id: z
            .string()
            .nullish()
            .transform((x) => x ?? undefined),
        content: z.string(),
        context: z.string(),
        justification: z.string(),
        username: z.string(),
    })
    .or(MeetingHeySpinachTicketCreationResultSchema);



export function isMeetingHeySpinachTicketCreationResult(
    item: any
): item is z.infer<typeof MeetingHeySpinachTicketCreationResultSchema> {
    return MeetingHeySpinachTicketCreationResultSchema.safeParse(item).success;
}
