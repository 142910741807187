import { Modal, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';

import { SpinachUpdateType, TICKET_SOURCE_MAP, Ticket, TypedUpdate } from '@spinach-shared/types';
import { getNewTypedUpdate } from '@spinach-shared/utils';

import { useGlobalAuthedUser, useGlobalJiraIssues } from '../../../hooks';
import { useFetchJiraIssues, withContentMasking } from '../../../utils';
import { CloseButton, Spacer, Spacing } from '../../common';
import { LeftBodyLarge, ModalContent } from '../../series/common';
import { ScrollShadingCSS } from '../../stand-up/ScrollArea';
import { useSpinachInputStyles } from '../BaseInput';
import { JiraDetailsModal } from './JiraDetailsModal';
import { OldJiraPreviewContainer } from './OldJiraPreviewContainer';

const JiraModalContent = styled(ModalContent)`
    min-width: 265px;
    width: 80%;
    max-width: 600px;
`;

const ModalScroll = styled.div`
    overflow-y: scroll;
    max-height: 300px;
    width: 100%;
    ${ScrollShadingCSS};
`;

export function JiraPickerModalWithAssignment({
    isModalOpen,
    onClose,
    createUpdateEmitter,
    onConfirmation,
}: {
    isModalOpen: boolean;
    onClose: () => void;
    createUpdateEmitter: (typedUpdate: TypedUpdate) => (text: string) => void;
    onConfirmation?: (typedUpdate: TypedUpdate) => void;
}): JSX.Element {
    const [jiraIssues] = useGlobalJiraIssues();
    const [suggestedJiraIssues, setSuggestedJiraIssues] = useState<Ticket[]>(jiraIssues ?? []);
    const [isSearching, setIsSearching] = useState(false);
    const [isJiraDetailsModalOpen, setIsJiraDetailsModalOpen] = useState(false);
    const [selectedJiraIssue, setSelectedJiraIssue] = useState<Ticket | null>(null);
    const classes = useSpinachInputStyles({ value: '', disabled: false });
    const [user] = useGlobalAuthedUser();
    const { fetchJiraIssues } = useFetchJiraIssues();

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchParams = e.target.value;
        if (!!searchParams) {
            setIsSearching(true);
        } else {
            setIsSearching(false);
        }

        const issues = await fetchJiraIssues({
            ...(searchParams
                ? { query: searchParams, showSubTasks: true, showSubTaskParent: true }
                : {
                      currentJQL: `assignee in (currentUser()) OR reporter in (currentUser())`,
                      showSubTasks: true,
                      showSubTaskParent: true,
                  }),
        });

        if (!issues) {
            setSuggestedJiraIssues(jiraIssues ?? []);
            return;
        }

        // Cleaning HTML off issues
        const cleanedIssues: Ticket[] = await Promise.all(
            issues.map(async (issue) => ({ ...issue, title: issue.title.replace(/(<([^>]+)>)/gi, '') }))
        );

        setSuggestedJiraIssues(cleanedIssues);
    };

    const onModalClose = () => {
        setSuggestedJiraIssues(jiraIssues ?? []);
        onClose();
    };

    return (
        <Modal open={isModalOpen} onClose={onModalClose}>
            <JiraModalContent>
                <Spacer style={{ height: '40px' }} />

                <CloseButton onClick={onModalClose} />

                <LeftBodyLarge>Add a Jira Issue</LeftBodyLarge>
                <TextField
                    InputProps={{ classes: { root: classes.base } }}
                    {...withContentMasking(classes.root)}
                    fullWidth
                    autoFocus
                    placeholder="Search by issue number or title"
                    onChange={onChange}
                />

                <Spacing />
                <LeftBodyLarge>{isSearching ? 'Search Results' : 'Assigned to me'}</LeftBodyLarge>
                <Spacing factor={1 / 3} />
                <ModalScroll style={{ paddingTop: '6px' }}>
                    {suggestedJiraIssues.map((issue) => {
                        return (
                            <OldJiraPreviewContainer
                                update={getNewTypedUpdate({
                                    updateType: SpinachUpdateType.Custom,
                                    text: `${issue.id} ${issue.title}`,
                                    creatorId: user.spinachUserId,
                                    jiraData: issue,
                                    ticketData: {
                                        source: TICKET_SOURCE_MAP.Jira,
                                        ticket: issue,
                                    },
                                })}
                                key={issue.id}
                                issueData={issue}
                                interactive={true}
                            />
                        );
                    })}
                </ModalScroll>
                {isJiraDetailsModalOpen && selectedJiraIssue && (
                    <JiraDetailsModal
                        isOpen={isJiraDetailsModalOpen}
                        onClose={() => {
                            setIsJiraDetailsModalOpen(false);
                            setSelectedJiraIssue(null);
                        }}
                        issueData={selectedJiraIssue}
                    />
                )}
            </JiraModalContent>
        </Modal>
    );
}
