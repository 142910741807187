import { DetailedTicket, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../getSpinachAPI';

export const fetchDetailedJiraIssues = async (issueIds: string[]) => {
    const response = await getSpinachAPI<{ issues: DetailedTicket[] }>(SpinachAPIPath.JiraIssues, {
        params: {
            issueIds,
        },
    });

    if (!response?.issues || !response.issues.length || !response.issues[0].id) {
        return [];
    }
    return response.issues;
};
