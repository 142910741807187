import React from 'react';
import styled from 'styled-components';

import { lightTheme } from '../../styles';

export type LoginButtonProps = {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    icon: React.ReactNode;
    text: string;
    disabled?: boolean;
    variant?: 'orange' | 'default';
};

const Button = styled.button<{ variant?: 'orange' | 'default' }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 240px;
    height: 60px;
    gap: 15px;
    padding: 16px 22px;
    border: ${(props) => (props.variant === 'orange' ? '1px solid #F26E24' : '1px solid #bcbcc7')};
    background-color: ${(props) => (props.variant === 'orange' ? '#F26E24' : '#ffffff')};
    &:hover {
        background-color: ${(props) => (props.variant === 'orange' ? '#ee9561' : '#ffffff')};
    }
    &:disabled {
        cursor: default;
        background-color: #dfe5e5;
        color: #bcbcc7;
    }
    color: ${(props) => (props.variant === 'orange' ? '#ffffff' : lightTheme.primary.midnight)};
    font-size: 16px;
`;

export function LoginButton(props: LoginButtonProps) {
    return (
        <Button onClick={props.onClick} disabled={!!props.disabled} variant={props.variant}>
            {props.icon}
            {props.text}
        </Button>
    );
}
