import { Box } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSearchParams } from 'react-router-dom';

import { AsyncVideoDTO } from '@spinach-shared/types';
import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';

import { getAskSpinachRealtimeConfig } from '../../../../..';
import { getAsyncVideo } from '../../../../apis/getAsyncVideo';
import slideBackground from '../../../../assets/avatar-slide-bg.png';
import background from '../../../../assets/bg-room.jpg';
import { useActivityTracking, useClickTracking } from '../../../../hooks';
import { AvatarToolTip } from '../../../common';
import { AskSpinach, AskSpinachFrom } from './AskSpinach';
import style from './markdown-styles.module.css';

const commandPlayAudio = 'playAudio';

let overrideBotId: string | undefined;
let overrideSeriesId: string | undefined;
let overrideDto: AsyncVideoDTO | undefined;
try {
    // see testing controller for more details
    const params = new URLSearchParams(window.location.hash.slice(1)); // skip the #
    overrideBotId = params.get(WebUrlQuery.BotId) ?? undefined;
    overrideSeriesId = params.get(WebUrlQuery.SeriesId) ?? undefined;
    const base64dto = params.get(WebUrlQuery.DTO);
    overrideDto = JSON.parse(atob(base64dto ?? ''));
} catch (error) {
    // no op used by testing
}

export function AsyncVideoSection(): JSX.Element {
    const trackActivity = useActivityTracking();
    const trackClick = useClickTracking();
    const [searchParams] = useSearchParams();
    const botId = overrideBotId ?? searchParams.get(WebUrlQuery.BotId);
    const seriesId = overrideSeriesId ?? searchParams.get(WebUrlQuery.SeriesId);
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [percentage, setPercentage] = useState('');
    const [activeVideoSection, setActiveVideoSection] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [minimalTranscriptResponse, setMinimalTranscriptResponse] = useState<string[] | null>(null);
    const [media, setMedia] = useState<AsyncVideoDTO | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (botId) {
            (async () => {
                try {
                    const media = overrideDto ?? (await getAsyncVideo({ botId }));
                    setMedia(media);
                } catch (error) {
                    setErrorMessage('Failed to load media');
                }
            })();

            const fetchAskSpinachRealtimeConfig = async (sid: string, bid: string) => {
                const realtimeConfigRes = await getAskSpinachRealtimeConfig({ seriesId: sid, botId: bid });
                if (realtimeConfigRes?.minimalTranscript) {
                    setMinimalTranscriptResponse(realtimeConfigRes.minimalTranscript);
                } else {
                    setMinimalTranscriptResponse(null);
                }
            };
            if (seriesId && botId) {
                fetchAskSpinachRealtimeConfig(seriesId, botId);
            }
        } else {
            setErrorMessage('Unable to load media - unknown meeting reference');
        }
    }, [botId, seriesId]);

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data.type === 'unityLoaded') {
                if (iframeRef.current) {
                    setTimeout(() => {
                        setPercentage('100');
                        setIsLoaded(true);
                    }, 2800); // figure out if we can legally do that
                }
            }
            if (event.data.type === 'unityLoading') {
                const fakeProgress = event.data.progress - 0.01;
                if (fakeProgress > 0) {
                    setPercentage(Math.floor(fakeProgress * 100).toFixed(0));
                } else {
                    setPercentage('0');
                }
            }
            if (event.data.payloadFromUnity && media) {
                if (event.data.payloadFromUnity.type === 'audioDone' && isPlaying) {
                    // todo parse elsewhere
                    const nextIndex = activeVideoSection + 1;
                    if (nextIndex < media.clips.length) {
                        trackActivity(ClientEventType.AIDashboardActivity, 'User finished segment playback', {
                            progress: nextIndex / media.clips.length,
                        });
                        setActiveVideoSection(nextIndex);
                        const message = {
                            type: commandPlayAudio,
                            data: media.clips[nextIndex].audioUrl,
                        };
                        iframeRef.current?.contentWindow?.postMessage(message, '*');
                        setIsPlaying(true);
                    } else {
                        trackActivity(ClientEventType.AIDashboardActivity, 'User finished segment playback', {
                            progress: 1,
                        });
                        setIsPlaying(false);
                        setActiveVideoSection(0);
                    }
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [activeVideoSection, isPlaying, media, trackActivity]);

    return (
        <Box height="100%" display="flex" flexDirection="row" alignItems="stretch">
            <Box style={{ flex: 1, position: 'relative' }}>
                <iframe
                    ref={iframeRef}
                    allow="autoplay"
                    src="https://media.spinach.io/avatar-webgpu2/wip.html?hide"
                    style={{ border: 'none', width: '100%', height: '100%' }}
                    onLoad={() => {}}
                />
                <img
                    src={background}
                    style={{
                        position: 'absolute',
                        zIndex: 1001,
                        inset: '0px',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        opacity: isLoaded ? 0 : 1,
                        transition: 'opacity 0.8s',
                        pointerEvents: isLoaded ? 'none' : 'auto',
                    }}
                />
                {media && (
                    <Box
                        style={{
                            position: 'absolute',
                            zIndex: 1011,
                            top: '22px',
                            left: '42px',
                            right: '42px',
                            color: 'white', // Set text color to white
                            fontSize: '55px',
                            fontWeight: 'bold',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {`${media.meetingTitle} (${moment(media.meetingDate).format('YYYY/MM/DD')})`}
                    </Box>
                )}
                {errorMessage && (
                    <Box
                        style={{
                            position: 'absolute',
                            zIndex: 1011,
                            inset: '0px',
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '60px',
                            fontWeight: 'bold',
                        }}
                    >
                        {errorMessage}
                    </Box>
                )}
                {!errorMessage && (
                    <Box
                        style={{
                            position: 'absolute',
                            zIndex: 1005,
                            inset: '0px',
                            opacity: isLoaded ? 0 : 1,
                            transition: 'opacity 0.8s',
                            pointerEvents: isLoaded ? 'none' : 'auto',
                            color: 'white', // Set text color to white
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '80px',
                            fontWeight: 'bold',
                        }}
                    >
                        {percentage}%
                    </Box>
                )}
                {!isPlaying && isLoaded && !errorMessage && (
                    <Box
                        style={{
                            position: 'absolute',
                            zIndex: 1001,
                            inset: '0px',
                            opacity: !isLoaded ? 0 : 1,
                            transition: 'opacity 0.8s',
                            pointerEvents: 'none',
                            color: 'white', // Set text color to white
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '80px',
                            fontWeight: 'bold',
                        }}
                    >
                        <PlayArrowIcon
                            style={{
                                color: 'white',
                                cursor: 'pointer',
                                width: '150px',
                                height: '150px',
                                pointerEvents: 'auto',
                            }}
                            onClick={() => {
                                if (iframeRef.current && media) {
                                    const message = {
                                        type: commandPlayAudio,
                                        data: media.clips[activeVideoSection].audioUrl,
                                    };
                                    iframeRef.current.contentWindow?.postMessage(message, '*');
                                    setIsPlaying(true);
                                }
                            }}
                        />
                    </Box>
                )}
                {!errorMessage && (
                    <Box
                        position="absolute"
                        left="0px"
                        bottom="0px"
                        right="0px"
                        style={{
                            background: 'rgba(0, 0, 0, 0.3)',
                            borderTopRightRadius: '10px',
                            borderTopLeftRadius: '10px',
                            zIndex: 1000,
                        }}
                    >
                        <Box
                            position="relative"
                            left="0px"
                            bottom="0px"
                            right="0px"
                            style={{
                                backgroundImage: `url(${slideBackground})`,
                                backgroundSize: 'cover',
                                borderTopRightRadius: '10px',
                                borderTopLeftRadius: '10px',
                                color: 'white',
                                fontSize: '25px',
                                whiteSpace: 'pre-wrap',
                            }}
                            pr="30px"
                            pl="30px"
                            pb="20px"
                        >
                            <Box
                                display="flex"
                                position="relative"
                                flexDirection="column"
                                style={{
                                    padding: '5px',
                                }}
                            >
                                <Box style={{ overflow: 'hidden' }} px="12px" py="23px">
                                    <ReactMarkdown className={style.reactMarkDown}>
                                        {media?.clips[activeVideoSection].slide.markdown ?? ''}
                                    </ReactMarkdown>
                                </Box>
                            </Box>

                            <Box
                                height="50px"
                                paddingX="10px"
                                style={{
                                    background: 'rgba(0, 0, 0, 0.9)',
                                    zIndex: 1000,
                                    borderRadius: '6px',
                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                                }}
                                display="flex"
                                flexDirection="row"
                                alignItems={'center'}
                            >
                                <Box
                                    style={{ background: 'white', borderRadius: '50%', width: '30px', height: '30px' }}
                                    mr="10px"
                                >
                                    {isPlaying ? (
                                        <StopIcon
                                            style={{ color: 'black', cursor: 'pointer', width: '30px', height: '30px' }}
                                            onClick={() => {
                                                setIsPlaying(false);
                                                trackClick(
                                                    ClientEventType.AIDashboardClick,
                                                    'User stopped async video playback'
                                                );
                                                if (iframeRef.current) {
                                                    const message = {
                                                        type: 'stopAudio',
                                                    };
                                                    iframeRef.current.contentWindow?.postMessage(message, '*');
                                                }
                                            }}
                                        />
                                    ) : (
                                        <PlayArrowIcon
                                            style={{ color: 'black', cursor: 'pointer', width: '30px', height: '30px' }}
                                            onClick={() => {
                                                if (iframeRef.current) {
                                                    const message = {
                                                        type: commandPlayAudio,
                                                        data: media?.clips[activeVideoSection].audioUrl,
                                                    };
                                                    iframeRef.current.contentWindow?.postMessage(message, '*');
                                                    setIsPlaying(true);
                                                    trackClick(
                                                        ClientEventType.AIDashboardClick,
                                                        'User started async video playback'
                                                    );
                                                }
                                            }}
                                        />
                                    )}
                                </Box>
                                {media?.clips.map((_script, index) => (
                                    <AvatarToolTip
                                        arrow
                                        key={index}
                                        interactive
                                        title={
                                            <Box p="5px" style={{ fontSize: '25px' }}>
                                                {media?.clips[index].slide.title}
                                            </Box>
                                        }
                                        placement="top"
                                        style={{
                                            flexDirection: 'column',
                                            maxWidth: '400px',
                                        }}
                                    >
                                        <Box
                                            key={index}
                                            display="flex"
                                            flexDirection="row"
                                            height="6px"
                                            mx="4px"
                                            flex={1}
                                            style={{
                                                cursor: 'pointer',
                                                background: activeVideoSection === index ? '#34A289' : '#FFFFFF',
                                                borderRadius: '2px',
                                            }}
                                            onClick={() => {
                                                setActiveVideoSection(index);
                                                if (iframeRef.current) {
                                                    const message = {
                                                        type: commandPlayAudio,
                                                        data: media?.clips[index].audioUrl,
                                                    };
                                                    iframeRef.current.contentWindow?.postMessage(message, '*');
                                                    setIsPlaying(true);
                                                    trackClick(
                                                        ClientEventType.AIDashboardClick,
                                                        'User changed async video segment',
                                                        {
                                                            index,
                                                        }
                                                    );
                                                }
                                            }}
                                        ></Box>
                                    </AvatarToolTip>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                style={{ width: '300px', overflow: 'auto', padding: '20px', background: 'white' }}
            >
                {botId && (
                    <AskSpinach
                        from={AskSpinachFrom.AsyncSummary}
                        minimalTranscriptResponse={minimalTranscriptResponse}
                        botId={botId}
                        seriesId={seriesId}
                    />
                )}
            </Box>
        </Box>
    );
}
