import { CircularProgress, TextField } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';

import {
    ClientEventType,
    SpinachIntegration,
    TICKET_SOURCE_MAP,
    TicketIntegrationWithSubProject,
    TicketProject,
    TicketSource,
} from '@spinach-shared/types';
import { isTicketSourceWithSubProject } from '@spinach-shared/utils';

import { fetchTicketProjects, fetchTicketSubProjects, getUser } from '../../../../apis';
import { patchTicketUserSettings } from '../../../../apis/patchTicketUserSettings';
import { useExperienceTracking, useGlobalAuthedUser } from '../../../../hooks';
import { useGlobalTicketProjectsState } from '../../../../hooks/useTicketProjects';
import { lightTheme } from '../../../../styles';
import { ListItemValue } from '../../../../types';
import { Notification } from '../../../stand-up';

export const TICKET_SOURCE_CUSTOM_LABEL: Record<TicketSource, string> = {
    [SpinachIntegration.MondayDotCom]: 'Monday.com',
    [SpinachIntegration.Jira]: 'Jira',
    [SpinachIntegration.Trello]: 'Trello',
    [SpinachIntegration.Asana]: 'Asana',
    [SpinachIntegration.Clickup]: 'Click Up',
    [SpinachIntegration.Linear]: 'Linear',
};

export const TICKET_SOURCE_PROJECT_NAME_MAP: Record<TicketSource, string> = {
    [SpinachIntegration.Jira]: 'project',
    [SpinachIntegration.Trello]: 'organization',
    [SpinachIntegration.Asana]: 'project',
    [SpinachIntegration.Clickup]: 'workspace',
    [SpinachIntegration.Linear]: 'team',
    [SpinachIntegration.MondayDotCom]: 'account',
};

export const TICKET_SOURCE_SUB_PROJECT_NAME_MAP: Record<TicketIntegrationWithSubProject, string> = {
    [SpinachIntegration.Trello]: 'board',
    [SpinachIntegration.Clickup]: 'list',
    [SpinachIntegration.MondayDotCom]: 'board',
};

export function TicketProjectSelection({
    ticketSource,
    setHasValidSelections,
}: {
    ticketSource: TicketSource;
    setHasValidSelections?: (bool: boolean) => void;
}): JSX.Element {
    let timeout: NodeJS.Timeout;
    const [isSubProjectsLoading, setIsSubProjectsLoading] = useState(false);

    const [user, setUser] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const currentProject = user.ticketSourceProject(ticketSource);
    const currentSubProject = user.ticketSourceSubProject(ticketSource);
    const [isLoading, setIsLoading] = useState(false);

    const [projectsState] = useGlobalTicketProjectsState();
    const [error, setError] = useState('');

    const defaultProjects = projectsState?.[ticketSource];
    const shouldDoBackendSearch =
        !defaultProjects || (ticketSource === TICKET_SOURCE_MAP.Jira && defaultProjects.length >= 40);
    const [projects, setProjects] = useState(defaultProjects);
    const projectDropDownValues: ListItemValue<string>[] | undefined = projects?.map((project) => ({
        code: project.id,
        label: project.name,
    }));
    const [projectSelection, setProjectSelection] = useState<ListItemValue<string> | undefined>(
        projectDropDownValues?.find((value) => value.code === currentProject?.id)
    );

    const [subProjects, setSubProjects] = useState<TicketProject[] | undefined>(undefined);

    const subProjectDropDownValues: ListItemValue<string>[] | undefined = subProjects?.map((subProject) => ({
        code: subProject.id,
        label: subProject.name,
    }));
    const [isSearching, setIsSearching] = useState(false);

    const [subProjectSelection, setSubProjectSelection] = useState<ListItemValue<string> | undefined>(
        currentSubProject ? { code: currentSubProject.id, label: currentSubProject.name } : undefined
    );

    useEffect(() => {
        const ticketSourceProject = user.ticketSourceProject(ticketSource);
        if (ticketSourceProject) {
            const fetchSubProjects = async () => {
                setIsSubProjectsLoading(true);

                const subProjects = await fetchTicketSubProjects(ticketSource, ticketSourceProject.id);

                track(ClientEventType.AIDashboardActivity, {
                    Action: 'Ticket SubProjects fetched',
                    From: 'User Load',
                    TicketSource: ticketSource,
                    NumberOfSubProjects: subProjects.length,
                });
                setSubProjects(subProjects);
                setIsSubProjectsLoading(false);
            };
            fetchSubProjects();
        }
    }, [user.ticketSourceProject(ticketSource)]);

    useEffect(() => {
        const ticketSourceProject = user.ticketSourceProject(ticketSource);
        if (ticketSourceProject && ticketSourceProject.id !== projectSelection?.code) {
            setProjectSelection(projectDropDownValues?.find((value) => value.code === ticketSourceProject?.id));
        }

        const ticketSourceSubProject = user.ticketSourceSubProject(ticketSource);
        if (
            ticketSourceSubProject &&
            subProjectDropDownValues?.length &&
            ticketSourceSubProject.id !== subProjectSelection?.code
        ) {
            setSubProjectSelection(
                subProjectDropDownValues?.find((value) => value.code === ticketSourceSubProject?.id)
            );
        }
    }, [user.ticketSourceProject(ticketSource), user.ticketSourceSubProject(ticketSource), subProjects]);

    if (!user.actionItemCreationEnabledTicketSources.includes(ticketSource)) {
        return <></>;
    }

    return (
        <>
            {!projectsState ? (
                <CircularProgress
                    size={'22px'}
                    style={{ marginLeft: '10px', marginBottom: '15px', color: lightTheme.primary.orangeDark }}
                />
            ) : (projects?.length && projectDropDownValues?.length) || currentProject ? (
                <Autocomplete
                    title={`Select a ${TICKET_SOURCE_PROJECT_NAME_MAP[ticketSource]}`}
                    options={projectDropDownValues ?? []}
                    value={projectSelection}
                    getOptionLabel={(option) => option.label}
                    loading={isLoading || isSearching}
                    loadingText={'Saving...'}
                    size="small"
                    style={{
                        width: '300px',
                        marginBottom: '2px',
                        transform: 'scale(0.8)',
                        transformOrigin: 'center left',
                        marginRight: '-50px',
                    }}
                    ListboxProps={{ style: { transform: 'scale(0.8)', transformOrigin: 'center left' } }}
                    onChange={async (_, selection) => {
                        track(ClientEventType.AIDashboardActivity, {
                            Action: 'Ticket Project Selection',
                            Index: projectDropDownValues?.findIndex((val) => val.code === selection?.code),
                            TicketSource: ticketSource,
                            HasCurrentProject: !!currentProject,
                            HasCurrentSubProject: !!currentSubProject,
                        });
                        if (!selection) {
                            return;
                        }
                        try {
                            if (selection.code === projectSelection?.code || selection.code === currentProject?.id) {
                                // no change
                                return;
                            }

                            setIsLoading(true);
                            setProjectSelection(
                                projectDropDownValues?.find((project) => project.code === selection.code)
                            );
                            clearTimeout(timeout);
                            await patchTicketUserSettings(ticketSource, {
                                projectId: selection.code,
                            });
                            const userResponse = await getUser();
                            if (userResponse.user) {
                                setUser(userResponse.user);
                                setHasValidSelections?.(true);
                            } else {
                                throw new Error('Failed to fetch user');
                            }
                        } catch (e) {
                            setError(`Failed to save ${TICKET_SOURCE_PROJECT_NAME_MAP[ticketSource]} selection`);
                        }

                        setIsLoading(false);
                        if (!isTicketSourceWithSubProject(ticketSource)) {
                            setHasValidSelections?.(true);
                            return;
                        }

                        try {
                            setIsSubProjectsLoading(true);

                            const subProjects = await fetchTicketSubProjects(ticketSource, selection.code);

                            track(ClientEventType.AIDashboardActivity, {
                                Action: 'Ticket SubProjects fetched',
                                From: 'DropDown Selection',
                                TicketSource: ticketSource,
                                NumberOfSubProjects: subProjects.length,
                            });

                            setSubProjects(subProjects);
                        } catch (e) {
                            setError(`Failed to fetch ${TICKET_SOURCE_SUB_PROJECT_NAME_MAP[ticketSource]}`);
                        }
                        setIsSubProjectsLoading(false);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            onChange={(e) => {
                                const search = e.target.value;
                                clearTimeout(timeout);
                                track(ClientEventType.AIDashboardActivity, {
                                    Action: 'Ticket Project Search',
                                    Search: search,
                                    TicketSource: ticketSource,
                                    HasCurrentProject: !!currentProject,
                                    HasCurrentSubProject: !!currentSubProject,
                                    ShouldDoBackendSearch: shouldDoBackendSearch,
                                });
                                if (!search) {
                                    setProjects(defaultProjects);
                                    return;
                                }
                                timeout = setTimeout(() => {
                                    const searchProjects = async () => {
                                        setIsSearching(true);
                                        const fetchedProjects = await fetchTicketProjects(ticketSource, search);
                                        setProjects(fetchedProjects);
                                        setIsSearching(false);
                                    };

                                    if (shouldDoBackendSearch) {
                                        track(ClientEventType.AIDashboardActivity, {
                                            Action: 'Ticket Project Backend Search',
                                            Search: search,
                                            TicketSource: ticketSource,
                                            HasCurrentProject: !!currentProject,
                                            HasCurrentSubProject: !!currentSubProject,
                                        });
                                        searchProjects();
                                    }
                                }, 500);
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment:
                                    isLoading || isSearching ? (
                                        <CircularProgress
                                            size={'22px'}
                                            style={{
                                                color: lightTheme.primary.orangeDark,
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    ),
                                style: {
                                    borderRadius: '20px',
                                    padding: '4px 20px',
                                    borderColor: `${lightTheme.primary.green} !important`,
                                },
                            }}
                            label={`${capitalize(TICKET_SOURCE_CUSTOM_LABEL[ticketSource])} ${capitalize(
                                TICKET_SOURCE_PROJECT_NAME_MAP[ticketSource]
                            )}`}
                            placeholder={`${capitalize(TICKET_SOURCE_CUSTOM_LABEL[ticketSource])} ${capitalize(
                                TICKET_SOURCE_PROJECT_NAME_MAP[ticketSource]
                            )}`}
                        />
                    )}
                />
            ) : (
                <></>
            )}
            {isTicketSourceWithSubProject(ticketSource) && !!projects?.length ? (
                !projectsState ? (
                    <CircularProgress
                        size={'22px'}
                        style={{ marginLeft: '10px', marginBottom: '15px', color: lightTheme.primary.orangeDark }}
                    />
                ) : subProjects?.length || currentSubProject || isSubProjectsLoading ? (
                    <Autocomplete
                        title={`Select a ${TICKET_SOURCE_SUB_PROJECT_NAME_MAP[ticketSource]}`}
                        options={subProjectDropDownValues ?? []}
                        value={subProjectSelection}
                        getOptionLabel={(option) => option.label}
                        loading={isSubProjectsLoading}
                        loadingText={'Saving...'}
                        size="small"
                        style={{
                            width: '300px',
                            marginBottom: '2px',
                            transform: 'scale(0.8)',
                            transformOrigin: 'center left',
                            marginRight: '-50px',
                        }}
                        ListboxProps={{ style: { transform: 'scale(0.8)', transformOrigin: 'center left' } }}
                        onChange={async (_, selection) => {
                            track(ClientEventType.AIDashboardActivity, {
                                Action: 'Ticket SubProject Selection',
                                Index: projectDropDownValues?.findIndex((val) => val.code === selection?.code),
                                TicketSource: ticketSource,
                                HasCurrentProject: !!currentProject,
                                HasCurrentSubProject: !!currentSubProject,
                            });
                            if (!selection?.code) {
                                return;
                            }
                            const projectId = currentProject?.id ?? projectSelection?.code;
                            if (!projectId || selection.code === projectId) {
                                // no change
                                return;
                            }

                            try {
                                setIsSubProjectsLoading(true);
                                setSubProjectSelection(
                                    subProjectDropDownValues?.find((project) => project.code === selection.code)
                                );

                                await patchTicketUserSettings(ticketSource, {
                                    projectId,
                                    subProjectId: selection.code,
                                });

                                const userResponse = await getUser();
                                if (userResponse.user) {
                                    setUser(userResponse.user);
                                    setHasValidSelections?.(true);
                                } else {
                                    throw new Error('Failed to fetch user');
                                }
                            } catch (e) {
                                setError(
                                    `Failed to save ${TICKET_SOURCE_SUB_PROJECT_NAME_MAP[ticketSource]} selection`
                                );
                            }
                            setIsSubProjectsLoading(false);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: isSubProjectsLoading ? (
                                        <CircularProgress
                                            size={'22px'}
                                            style={{
                                                color: lightTheme.primary.orangeDark,
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    ),
                                    style: {
                                        borderRadius: '20px',
                                        padding: '4px 20px',
                                        borderColor: `${lightTheme.primary.green} !important`,
                                    },
                                }}
                                label={`${capitalize(TICKET_SOURCE_CUSTOM_LABEL[ticketSource])} ${capitalize(
                                    TICKET_SOURCE_SUB_PROJECT_NAME_MAP[ticketSource]
                                )}`}
                                placeholder={`${capitalize(TICKET_SOURCE_CUSTOM_LABEL[ticketSource])} ${capitalize(
                                    TICKET_SOURCE_SUB_PROJECT_NAME_MAP[ticketSource]
                                )}`}
                            />
                        )}
                    />
                ) : (
                    <></>
                )
            ) : (
                <></>
            )}
            <Notification
                containerStyle={{ position: 'relative', bottom: 'unset' }}
                isOpen={!!error}
                onClose={() => null}
                duration={1000}
                message={error}
                icon={<ErrorIcon style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />}
            />
        </>
    );
}
