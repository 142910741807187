import { atom } from 'recoil';

import { AttachmentBarOptions } from '@spinach-shared/types';

/**
 * The thought here is that we can control modals from anywhere with global state
 * we can house them in a single place so as to not bloat up other components
 * There should only be a single modal up at a time, so global shouldn't produce
 * too many conflicts. Open to alternatives though.
 *
 * Initial usecase - need to finish demo mode -> route to real lobby -> pop modal there
 * Could use a query param, but ultimately i'd want to remove it. Global state makes this much easier.
 */

export type GlobalAttachmentState = {
    attachment: AttachmentBarOptions | null;
    itemId: string | null;
};

export const atomAttachmentState = atom<GlobalAttachmentState>({
    key: 'attachmentState',
    default: {
        attachment: null,
        itemId: null,
    },
});
