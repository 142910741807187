import { v4 } from 'uuid';

import { addCookie, getCookieValue } from '../..';

const COOKIE_NAME = 'anonymousUserId';
export function getAnonymousUserId() {
    let anonymousUserId = getCookieValue(COOKIE_NAME);
    if (!anonymousUserId) {
        anonymousUserId = v4();
        addCookie(COOKIE_NAME, anonymousUserId);
    }
    return anonymousUserId;
}

export function useAnonymousUserId() {
    return getAnonymousUserId();
}
