import { useRecoilState } from 'recoil';

import { atomIntegrationSettingsModalKind } from '../atoms';
import { IntegrationSettingsModalKind } from '../components/spinach-ai/dashboard/types';

export const useGlobalIntegrationSettingsModalKind = (): [
    IntegrationSettingsModalKind | null,
    (kind: IntegrationSettingsModalKind | null) => void
] => {
    return useRecoilState(atomIntegrationSettingsModalKind);
};
