import axios from 'axios';

import { SlackChannelsRequest, SlackTeamType, SpinachAPIPath } from '@spinach-shared/types';
import { getDefaultRequestHeaders } from '@spinach-shared/utils';

import { getHeaderOpts } from '../utils/api';

export async function fetchSlackChannels(
    teamId: string | undefined,
    teamType: SlackTeamType | undefined,
    spinachUserId: string,
    noCache?: boolean
): Promise<{ name: string; id: string; isPrivate?: boolean }[]> {
    if (!teamId || !teamType) {
        return [];
    }

    const request: SlackChannelsRequest = {
        teamId,
        teamType,
        spinachUserId,
        noCache,
    };

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_SLACK_SERVER_URL}${SpinachAPIPath.SlackChannels}`,
            request,
            {
                headers: {
                    ...getDefaultRequestHeaders(getHeaderOpts()),
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-store',
                },
                // @TODO: is this needed?
                // withCredentials: isWebPlatform() || isChromeExtensionPlatform(),
            }
        );
        return response.data;
    } catch (error) {
        return [];
    }
}
