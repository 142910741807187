import { useRecoilState } from 'recoil';

import { StoredSeries } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import { atomLiveSeries, atomStoredSeries } from '../atoms';
import { SetValue } from '../types';

// TODO: I believe this can be removed with the use of useGlobalNullableStoredSeries below
export function useGlobalSeriesId(): string | null {
    const [series] = useRecoilState(atomLiveSeries);
    return series?.slug as string | null;
}

/**
 *
 * @returns stateful getter and setter for the global stored series reference. Use this in places where it may not be defined for sure
 */
export function useGlobalNullableStoredSeries(): [
    StoredSpinachSeries | null | undefined,
    SetValue<StoredSeries | StoredSpinachSeries | null | undefined>
] {
    const [storedSpinachSeries, setStoredSpinachSeries] = useRecoilState(atomStoredSeries) as [
        StoredSpinachSeries | null | undefined,
        SetValue<StoredSeries | StoredSpinachSeries | null | undefined>
    ];

    function set(storedSeries: StoredSeries | StoredSpinachSeries | null | undefined) {
        if (!storedSeries) {
            setStoredSpinachSeries(storedSeries);
        } else if (storedSeries instanceof StoredSpinachSeries) {
            setStoredSpinachSeries(storedSeries);
        } else {
            const updated = new StoredSpinachSeries(storedSeries);
            setStoredSpinachSeries(updated);
        }
    }

    return [
        storedSpinachSeries as StoredSpinachSeries | null | undefined,
        set as SetValue<StoredSeries | StoredSpinachSeries | null | undefined>,
    ];
}

/**
 *
 * @returns great for referencing permanent parts of the stored series performantly, like series slug/ID
 * // Only to be used when series is defined, such as while in a meeting
 */
export function useGlobalStoredSeries(): [StoredSpinachSeries, SetValue<StoredSeries | StoredSpinachSeries>] {
    const [storedSpinachSeries, setStoredSpinachSeries] = useRecoilState(atomStoredSeries) as [
        StoredSpinachSeries,
        SetValue<StoredSpinachSeries>
    ];

    function set(storedSeries: StoredSeries | StoredSpinachSeries) {
        if (storedSeries instanceof StoredSpinachSeries) {
            setStoredSpinachSeries(storedSeries);
        } else {
            const updated = new StoredSpinachSeries(storedSeries);
            setStoredSpinachSeries(updated);
        }
    }

    return [storedSpinachSeries, set as SetValue<StoredSeries | StoredSpinachSeries>];
}
