import { IconButton, StandardTextFieldProps, TextField, makeStyles } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DashboardSection } from '@spinach-shared/constants';
import {
    ClientEventType,
    FeatureIntent,
    MeetingActionItem,
    MeetingAgendaItem,
    MeetingBlocker,
    MeetingCelebration,
    MeetingKeyDecision,
    ResponseCode,
    ScribeMeetingType,
    SummaryFeatureIntents,
    SummaryJson,
    UserNameWithEmailConfidenceMap,
    UserPlansAndProgress,
    WebUrlQuery,
} from '@spinach-shared/types';

import { getAiSummaryJson } from '../../apis';
import { postEditedSummary } from '../../apis/postEditedSummary';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalRouting,
    useLandingAnalytic,
    useWindowSize,
} from '../../hooks';
import { useGlobalAiDashboard } from '../../hooks/useGlobalAiDashboard';
import { BodyRegularOnboard, HeaderThree, HeaderThreeOnboard, lightTheme } from '../../styles';
import { SetValue } from '../../types';
import { withContentMasking } from '../../utils';
import { Column, FYI, FYIState, Row, Spacing, ViewContainer } from '../common';
import { SLogo } from '../series/first-series/common';
import { PrimaryButton, ScrollArea } from '../stand-up';
import { Notification } from '../stand-up';
import SecondaryButton from '../stand-up/SecondaryButton';

const delimiter = '::-::';

const useSummaryTextStyles = makeStyles(() => ({
    root: {
        border: `1px solid white`,
        overflow: 'hidden',
        borderRadius: 6,
        padding: '2px',
        width: '97%',
        '&:hover': {
            backgroundColor: '#fff',
            border: `1px solid ${lightTheme.primary.orangeLight}`,
        },
        // would love to figure out how to keep orange border on focus without hover
        // '&:focus': {
        //     border: `0.5px solid ${lightTheme.primary.orangeDark}`,
        // },
    },
}));

function SummaryTextField(props: StandardTextFieldProps): JSX.Element {
    const { state, setState } = useGlobalAiDashboard();
    const classes = useSummaryTextStyles();
    const track = useExperienceTracking();

    return (
        <TextField
            size="small"
            classes={classes}
            {...withContentMasking(props.className)}
            multiline
            {...props}
            /** @NOTE InputProps needs to be after {...props} */
            onChange={(e) => {
                props.onChange?.(e);
                if (!state.hasEditedSummary) {
                    track(ClientEventType.AIDashboardActivity, {
                        Activity: 'Started Editing Summary',
                    });
                    setState({ ...state, hasEditedSummary: true });
                }
            }}
            inputProps={{
                maxLength: 5000,
            }}
            InputProps={{
                disableUnderline: true,
                style: { fontFamily: 'Gilroy', ...(props.InputProps?.style ?? {}) },
                ...(props.InputProps ?? {}),
            }}
        />
    );
}

function RemoveItemButton({ onClick }: { onClick: () => void | (() => Promise<void>) }): JSX.Element {
    return (
        <div style={{ position: 'relative', width: '0px' }}>
            <div style={{ position: 'absolute', left: '-30px' }}>
                <IconButton size="small" onClick={onClick}>
                    <RemoveCircleIcon htmlColor={lightTheme.primary.orangeDark} />
                </IconButton>
            </div>
        </div>
    );
}

function AddItemRow({
    buttonText,
    onClick,
}: {
    buttonText: ReactNode;
    onClick: () => void | (() => Promise<void>);
}): JSX.Element {
    return (
        <Row>
            <SecondaryButton
                title={
                    <>
                        <AddCircleIcon htmlColor={lightTheme.primary.greenLight} />
                        {buttonText}
                    </>
                }
                buttonStyles={{ textDecoration: 'none' }}
                color={lightTheme.primary.greenLight}
                labelStyles={{ fontSize: '18px', fontWeight: 700, display: 'flex', alignItems: 'center' }}
                onClick={onClick}
            />
        </Row>
    );
}

function ItemRow({
    children,
    onRemoveClick,
    rowIndex,
}: {
    onRemoveClick: () => void;
    children?: ReactNode;
    rowIndex: number;
}): JSX.Element {
    const [highlightedItemIndex, setHighlightedItemIndex] = useState(-1);

    return (
        <Row
            style={{ marginBottom: '10px' }}
            onMouseEnter={() => setHighlightedItemIndex(rowIndex)}
            onMouseLeave={() => setHighlightedItemIndex(-1)}
        >
            {highlightedItemIndex === rowIndex ? <RemoveItemButton onClick={onRemoveClick} /> : null}
            {children}
        </Row>
    );
}

type EditSummaryProps = {
    summaryJSON: SummaryJson;
    setSummaryJSON: SetValue<SummaryJson | null | undefined>;
};
function AgendaItemSection({ summaryJSON, setSummaryJSON }: EditSummaryProps): JSX.Element {
    const track = useExperienceTracking();
    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>Key Topics</HeaderThree>
            </Row>

            {summaryJSON.agendaItems?.map((item, i) => {
                return (
                    <ItemRow
                        key={i}
                        rowIndex={i}
                        onRemoveClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Remove Summary Item',
                                ItemType: 'Key Topic',
                            });
                            const updatedAgendaItems = [...(summaryJSON.agendaItems ?? [])];
                            updatedAgendaItems.splice(i, 1);

                            const updatedJson = {
                                ...summaryJSON,
                                agendaItems: updatedAgendaItems,
                            };
                            setSummaryJSON(updatedJson);
                        }}
                    >
                        <Column>
                            <SummaryTextField
                                placeholder="Key Topic Title"
                                InputProps={{ style: { fontWeight: 600 } }}
                                value={item.content}
                                onChange={(e) => {
                                    const updatedAgendaItems = [...(summaryJSON.agendaItems ?? [])];
                                    updatedAgendaItems[i] = {
                                        ...updatedAgendaItems[i],
                                        content: e.target.value,
                                    };
                                    const updatedJson = {
                                        ...summaryJSON,
                                        agendaItems: updatedAgendaItems,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                            />
                            <SummaryTextField
                                placeholder="Key Topic Context"
                                value={item.context}
                                onChange={(e) => {
                                    const updatedAgendaItems = [...(summaryJSON.agendaItems ?? [])];
                                    updatedAgendaItems[i] = {
                                        ...updatedAgendaItems[i],
                                        context: e.target.value,
                                    };
                                    const updatedJson = {
                                        ...summaryJSON,
                                        agendaItems: updatedAgendaItems,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                            />
                        </Column>
                    </ItemRow>
                );
            })}
            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: 'Key Topic',
                    });
                    const blankAgendaItem: MeetingAgendaItem = {
                        context: '',
                        content: '',
                    };
                    const updatedAgendaItems = [...(summaryJSON.agendaItems ?? []), blankAgendaItem];
                    const updatedJson = {
                        ...summaryJSON,
                        agendaItems: updatedAgendaItems,
                    };
                    setSummaryJSON(updatedJson);
                }}
                buttonText="Add a Key Topic"
            />
        </>
    );
}

const filter = createFilterOptions<string>();

function ActionItemSection({
    summaryJSON,
    setSummaryJSON,
    usernameEmailMapping,
}: EditSummaryProps & { usernameEmailMapping: UserNameWithEmailConfidenceMap | undefined }): JSX.Element {
    const track = useExperienceTracking();

    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>Action Items</HeaderThree>
            </Row>

            {summaryJSON.actionItems?.map((item, i) => {
                return (
                    <ItemRow
                        key={i}
                        onRemoveClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Remove Summary Item',
                                ItemType: 'Action Item',
                            });
                            const updatedActionItems = [...(summaryJSON.actionItems ?? [])];
                            updatedActionItems.splice(i, 1);

                            const updatedJson: SummaryJson = {
                                ...summaryJSON,
                                actionItems: updatedActionItems,
                            };
                            setSummaryJSON(updatedJson);
                        }}
                        rowIndex={i}
                    >
                        <Column>
                            <SummaryTextField
                                InputProps={{ style: { fontWeight: 600 } }}
                                placeholder="Action item title"
                                value={item.title}
                                onChange={(e) => {
                                    const updatedActionItems = [...(summaryJSON.actionItems ?? [])];
                                    updatedActionItems[i] = {
                                        ...updatedActionItems[i],
                                        title: e.target.value,
                                    };
                                    const updatedJson = {
                                        ...summaryJSON,
                                        actionItems: updatedActionItems,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                            />
                            <Column
                                style={{
                                    width: 'calc(100% - 20px)',
                                    marginLeft: '20px',
                                }}
                            >
                                <Autocomplete
                                    placeholder="Assignee"
                                    multiple
                                    size="small"
                                    value={item.users.map(
                                        (user, i) => `${user.username}${delimiter}${i}${delimiter}${user.email ?? ''}`
                                    )}
                                    freeSolo={true}
                                    selectOnFocus={true}
                                    id="action-item-owner-selection"
                                    options={summaryJSON.attendees}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        if (params.inputValue !== '') {
                                            filtered.push(`Add "${params.inputValue}"`);
                                        }

                                        return filtered;
                                    }}
                                    getOptionLabel={(username) => username.split(delimiter)[0]}
                                    onChange={(event, owners) => {
                                        const updatedActionItems = [...(summaryJSON.actionItems ?? [])];

                                        const partsList = owners.map((owner) => owner.split(delimiter));
                                        const users = partsList.map((parts) => ({
                                            // remove the instructional text if added
                                            username: parts[0].replaceAll('Add "', '').replaceAll('"', ''),
                                            email: parts[2] ?? usernameEmailMapping?.[parts[0]]?.email ?? '',
                                        }));

                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Action Item Owner Selection',
                                            OwnerDiff: users.length - updatedActionItems[i].users.length,
                                        });

                                        updatedActionItems[i] = {
                                            ...updatedActionItems[i],
                                            users,
                                        };
                                        const updatedJson = {
                                            ...summaryJSON,
                                            actionItems: updatedActionItems,
                                        };
                                        setSummaryJSON(updatedJson);
                                    }}
                                    ChipProps={{
                                        style: { backgroundColor: lightTheme.neutrals.grayLight },
                                    }}
                                    fullWidth={false}
                                    getOptionDisabled={(option) =>
                                        !!summaryJSON.actionItems?.[i].users.some((user) => user.username === option)
                                    }
                                    style={{
                                        borderRadius: '6',
                                        width: '97.5%',
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                style: {
                                                    borderRadius: '20px',
                                                    padding: '4px 20px',
                                                    borderColor: `${lightTheme.primary.green} !important`,
                                                },
                                            }}
                                            placeholder="Add an assignee"
                                            variant="outlined"
                                        />
                                    )}
                                />

                                <SummaryTextField
                                    value={item.context}
                                    placeholder="Action item context"
                                    onChange={(e) => {
                                        const updatedActionItems = [...(summaryJSON.actionItems ?? [])];
                                        updatedActionItems[i] = {
                                            ...updatedActionItems[i],
                                            context: e.target.value,
                                        };
                                        const updatedJson = {
                                            ...summaryJSON,
                                            actionItems: updatedActionItems,
                                        };
                                        setSummaryJSON(updatedJson);
                                    }}
                                />
                            </Column>
                        </Column>
                    </ItemRow>
                );
            })}

            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: 'Action Item',
                    });
                    const blankActionItem: MeetingActionItem = {
                        context: '',
                        title: '',
                        users: [],
                    };
                    const updatedActionItems = [...(summaryJSON.actionItems ?? []), blankActionItem];
                    const updatedJson: SummaryJson = {
                        ...summaryJSON,
                        actionItems: updatedActionItems,
                    };
                    setSummaryJSON(updatedJson);
                }}
                buttonText="Add an Action Item"
            />
        </>
    );
}

function KeyDecisionSection({ summaryJSON, setSummaryJSON }: EditSummaryProps): JSX.Element {
    const track = useExperienceTracking();

    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>Key Decisions</HeaderThree>
            </Row>

            {summaryJSON.keyDecisions?.map((kd, i) => {
                return (
                    <ItemRow
                        key={i}
                        onRemoveClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Remove Summary Item',
                                ItemType: 'Key Decision',
                            });
                            const updatedItems = [...(summaryJSON.keyDecisions ?? [])];
                            updatedItems.splice(i, 1);

                            const updatedJson: SummaryJson = {
                                ...summaryJSON,
                                keyDecisions: updatedItems,
                            };
                            setSummaryJSON(updatedJson);
                        }}
                        rowIndex={i}
                    >
                        <Column>
                            <SummaryTextField
                                InputProps={{ style: { fontWeight: 600 } }}
                                value={kd.content}
                                onChange={(e) => {
                                    const updatedKeyDecisions = [...(summaryJSON.keyDecisions ?? [])];
                                    updatedKeyDecisions[i] = {
                                        ...updatedKeyDecisions[i],
                                        content: e.target.value,
                                    };
                                    const updatedJson: SummaryJson = {
                                        ...summaryJSON,
                                        keyDecisions: updatedKeyDecisions,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                                placeholder="Key decision title"
                            />
                            <SummaryTextField
                                value={kd.context}
                                onChange={(e) => {
                                    const updatedKeyDecisions = [...(summaryJSON.keyDecisions ?? [])];
                                    updatedKeyDecisions[i] = {
                                        ...updatedKeyDecisions[i],
                                        context: e.target.value,
                                    };
                                    const updatedJson: SummaryJson = {
                                        ...summaryJSON,
                                        keyDecisions: updatedKeyDecisions,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                                placeholder="Key decision context"
                            />
                        </Column>
                    </ItemRow>
                );
            })}

            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: 'Key Decision',
                    });
                    const blankItem: MeetingKeyDecision = {
                        content: '',
                        context: '',
                    };
                    const updatedItems = [...(summaryJSON.keyDecisions ?? []), blankItem];
                    const updatedJson: SummaryJson = {
                        ...summaryJSON,
                        keyDecisions: updatedItems,
                    };
                    setSummaryJSON(updatedJson);
                }}
                buttonText="Add a Key Decision"
            />
        </>
    );
}

function BlockerSection({ summaryJSON, setSummaryJSON }: EditSummaryProps): JSX.Element {
    const track = useExperienceTracking();

    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>Blockers</HeaderThree>
            </Row>

            {summaryJSON.blockers?.map((blocker, i) => {
                return (
                    <ItemRow
                        key={i}
                        onRemoveClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Remove Summary Item',
                                ItemType: 'Blocker',
                            });
                            const updatedItems = [...(summaryJSON.blockers ?? [])];
                            updatedItems.splice(i, 1);

                            const updatedJson: SummaryJson = {
                                ...summaryJSON,
                                blockers: updatedItems,
                            };
                            setSummaryJSON(updatedJson);
                        }}
                        rowIndex={i}
                    >
                        <Column>
                            <SummaryTextField
                                InputProps={{ style: { fontWeight: 600 } }}
                                value={blocker.user}
                                onChange={(e) => {
                                    const updatedBlockers = [...(summaryJSON.blockers ?? [])];
                                    updatedBlockers[i] = {
                                        ...updatedBlockers[i],
                                        user: e.target.value,
                                    };
                                    const updatedJson: SummaryJson = {
                                        ...summaryJSON,
                                        blockers: updatedBlockers,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                                placeholder="Owners"
                            />
                            <SummaryTextField
                                InputProps={{ style: { fontWeight: 600 } }}
                                value={blocker.content}
                                onChange={(e) => {
                                    const updatedBlockers = [...(summaryJSON.blockers ?? [])];
                                    updatedBlockers[i] = {
                                        ...updatedBlockers[i],
                                        content: e.target.value,
                                    };
                                    const updatedJson: SummaryJson = {
                                        ...summaryJSON,
                                        blockers: updatedBlockers,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                                placeholder="Blocker title"
                            />
                            <SummaryTextField
                                value={blocker.context}
                                onChange={(e) => {
                                    const updatedBlockers = [...(summaryJSON.blockers ?? [])];
                                    updatedBlockers[i] = {
                                        ...updatedBlockers[i],
                                        context: e.target.value,
                                    };
                                    const updatedJson: SummaryJson = {
                                        ...summaryJSON,
                                        blockers: updatedBlockers,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                                placeholder="Blocker context"
                            />
                        </Column>
                    </ItemRow>
                );
            })}

            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: 'Blocker',
                    });
                    const blankItem: MeetingBlocker = {
                        content: '',
                        context: '',
                        user: '',
                    };
                    const updatedItems = [...(summaryJSON.blockers ?? []), blankItem];
                    const updatedJson: SummaryJson = {
                        ...summaryJSON,
                        blockers: updatedItems,
                    };
                    setSummaryJSON(updatedJson);
                }}
                buttonText="Add a Blocker"
            />
        </>
    );
}

function CelebrationSection({ summaryJSON, setSummaryJSON }: EditSummaryProps): JSX.Element {
    const track = useExperienceTracking();

    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>Celebrations</HeaderThree>
            </Row>

            {summaryJSON.celebrations?.map((celebration, i) => {
                return (
                    <ItemRow
                        key={i}
                        onRemoveClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Remove Summary Item',
                                ItemType: 'Celebration',
                            });
                            const updatedItems = [...(summaryJSON.celebrations ?? [])];
                            updatedItems.splice(i, 1);

                            const updatedJson: SummaryJson = {
                                ...summaryJSON,
                                celebrations: updatedItems,
                            };
                            setSummaryJSON(updatedJson);
                        }}
                        rowIndex={i}
                    >
                        <Column>
                            <BodyRegularOnboard>
                                <SummaryTextField
                                    InputProps={{ style: { fontWeight: 600 } }}
                                    value={celebration.user}
                                    onChange={(e) => {
                                        const updatedCelebrations = [...(summaryJSON.celebrations ?? [])];
                                        updatedCelebrations[i] = {
                                            ...updatedCelebrations[i],
                                            user: e.target.value,
                                        };
                                        const updatedJson: SummaryJson = {
                                            ...summaryJSON,
                                            celebrations: updatedCelebrations,
                                        };
                                        setSummaryJSON(updatedJson);
                                    }}
                                    placeholder="Who are we celebrating?"
                                />
                                <SummaryTextField
                                    value={celebration.content}
                                    onChange={(e) => {
                                        const updatedCelebrations = [...(summaryJSON.celebrations ?? [])];
                                        updatedCelebrations[i] = {
                                            ...updatedCelebrations[i],
                                            content: e.target.value,
                                        };
                                        const updatedJson: SummaryJson = {
                                            ...summaryJSON,
                                            celebrations: updatedCelebrations,
                                        };
                                        setSummaryJSON(updatedJson);
                                    }}
                                    placeholder="What are we celebrating"
                                />
                            </BodyRegularOnboard>
                        </Column>
                    </ItemRow>
                );
            })}

            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: 'Celebration',
                    });
                    const blankItem: MeetingCelebration = {
                        content: '',
                        user: '',
                    };
                    const updatedItems = [...(summaryJSON.celebrations ?? []), blankItem];
                    const updatedJson: SummaryJson = {
                        ...summaryJSON,
                        celebrations: updatedItems,
                    };
                    setSummaryJSON(updatedJson);
                }}
                buttonText="Add a Celebration"
            />
        </>
    );
}

function PersonalUpdatesSection({ summaryJSON, setSummaryJSON }: EditSummaryProps): JSX.Element {
    const track = useExperienceTracking();

    // TODO ensure this is only displaying for standups
    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>Updates</HeaderThree>
            </Row>
            {summaryJSON.plansAndProgress?.map((pp, i) => {
                return (
                    <ItemRow
                        key={i}
                        onRemoveClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Remove Summary Item',
                                ItemType: 'Personal Update',
                            });
                            const updatedItems = [...(summaryJSON.plansAndProgress ?? [])];
                            updatedItems.splice(i, 1);

                            const updatedJson: SummaryJson = {
                                ...summaryJSON,
                                plansAndProgress: updatedItems,
                            };
                            setSummaryJSON(updatedJson);
                        }}
                        rowIndex={i}
                    >
                        <Column>
                            <SummaryTextField
                                InputProps={{ style: { fontWeight: 600 } }}
                                value={pp.userName}
                                onChange={(e) => {
                                    const updatedPlansAndProgress = [...(summaryJSON.plansAndProgress ?? [])];
                                    updatedPlansAndProgress[i] = {
                                        ...updatedPlansAndProgress[i],
                                        userName: e.target.value,
                                    };
                                    const updatedJson: SummaryJson = {
                                        ...summaryJSON,
                                        plansAndProgress: updatedPlansAndProgress,
                                    };
                                    setSummaryJSON(updatedJson);
                                }}
                                placeholder="Whose update?"
                            />
                            <Row
                                style={{
                                    width: 'calc(100% - 20px)',
                                    marginLeft: '20px',
                                }}
                            >
                                <Column>
                                    <BodyRegularOnboard>
                                        <b>Plans:</b>
                                    </BodyRegularOnboard>

                                    <SummaryTextField
                                        value={pp.plans}
                                        onChange={(e) => {
                                            const updatedPlansAndProgress = [...(summaryJSON.plansAndProgress ?? [])];
                                            updatedPlansAndProgress[i] = {
                                                ...updatedPlansAndProgress[i],
                                                plans: e.target.value,
                                            };
                                            const updatedJson: SummaryJson = {
                                                ...summaryJSON,
                                                plansAndProgress: updatedPlansAndProgress,
                                            };
                                            setSummaryJSON(updatedJson);
                                        }}
                                        placeholder="Their plans"
                                    />

                                    <BodyRegularOnboard>
                                        <b>Progress:</b>
                                    </BodyRegularOnboard>

                                    <SummaryTextField
                                        value={pp.progress}
                                        onChange={(e) => {
                                            const updatedPlansAndProgress = [...(summaryJSON.plansAndProgress ?? [])];
                                            updatedPlansAndProgress[i] = {
                                                ...updatedPlansAndProgress[i],
                                                progress: e.target.value,
                                            };
                                            const updatedJson: SummaryJson = {
                                                ...summaryJSON,
                                                plansAndProgress: updatedPlansAndProgress,
                                            };
                                            setSummaryJSON(updatedJson);
                                        }}
                                        placeholder="Their progress"
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </ItemRow>
                );
            })}

            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: 'Personal Update',
                    });
                    const blankItem: UserPlansAndProgress = {
                        email: '',
                        id: '',
                        plans: '',
                        progress: '',
                        userName: '',
                    };
                    const updatedItems = [...(summaryJSON.plansAndProgress ?? []), blankItem];
                    const updatedJson: SummaryJson = {
                        ...summaryJSON,
                        plansAndProgress: updatedItems,
                    };
                    setSummaryJSON(updatedJson);
                }}
                buttonText="Add an Update"
            />
        </>
    );
}

function SectionContainer({
    intent,
    enabledSections,
    children,
}: {
    intent: SummaryFeatureIntents;
    enabledSections?: SummaryFeatureIntents[];
    children: ReactNode;
}): JSX.Element {
    if (!!enabledSections?.length && !enabledSections.includes(intent)) {
        return <></>;
    }

    return <>{children}</>;
}

export function AIEditSummary(): JSX.Element {
    const track = useExperienceTracking();
    const { setToastText } = useGlobalAiDashboard();
    const [summaryJSON, setSummaryJSON] = useState<SummaryJson | null>();
    const [meetingTime, setMeetingTime] = useState('');
    const [meetingTitle, setMeetingTitle] = useState('');
    const [meetingType, setMeetingType] = useState('');
    const [seriesId, setSeriesId] = useState('');
    const [usernameEmailMapping, setUsernameEmailMapping] = useState<UserNameWithEmailConfidenceMap | undefined>(
        undefined
    );
    const [enabledSummarySections, setEnabledSummarySections] = useState<SummaryFeatureIntents[] | undefined>(
        undefined
    );
    const [notificationText, setNotificationText] = useState('');

    const [searchParams] = useSearchParams();
    const { routeToAIDashboard } = useGlobalRouting();
    const { setActiveSection } = useGlobalAiDashboard();
    const [user] = useGlobalAuthedUser();
    const botId = searchParams.get(WebUrlQuery.BotId);
    const markedAsDraft = searchParams.get(WebUrlQuery.MarkedAsDraft) === 'true';

    useEffect(() => {
        if (user.isEnabledForDraftsSection && markedAsDraft) {
            routeToAIDashboard(
                undefined,
                new URLSearchParams({
                    [WebUrlQuery.Section]: DashboardSection.Drafts,
                })
            );
        }
    }, [user, routeToAIDashboard, markedAsDraft]);

    useLandingAnalytic(ClientEventType.AIDashboardActivity, {
        Activity: 'Opened Edit Summary',
        BotId: botId,
    });

    useEffect(() => {
        async function exec() {
            if (!botId) {
                routeToAIDashboard();
                return;
            }

            const response = await getAiSummaryJson(botId, true);

            if (!response) {
                routeToAIDashboard({ replace: true });
            } else if ('code' in response && response.code === ResponseCode.SummaryAlreadySent) {
                setToastText('That summary has already been sent.');
                routeToAIDashboard({ replace: true });
            } else if ('summaryJson' in response) {
                setSummaryJSON(response.summaryJson);
                setMeetingTime(response.meetingTime);
                setMeetingTitle(response.meetingTitle);
                setUsernameEmailMapping(response.usernameEmailMapping);
                setMeetingType(response.meetingType);
                setSeriesId(response.seriesId);
                setEnabledSummarySections(response.enabledSummarySections);
            } else {
                routeToAIDashboard({ replace: true });
            }
        }
        exec();
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const { width } = useWindowSize();

    if (!summaryJSON) {
        return <FYI state={FYIState.Loading} />;
    }

    const isMobile = width <= 600;

    return (
        <ViewContainer>
            <Column style={{ height: isMobile ? '95%' : '85%', width: isMobile ? '90%' : '70%' }}>
                <Row style={{ justifyContent: 'space-between', zIndex: 1000, marginBlock: '10px' }}>
                    <SLogo
                        style={{
                            width: '50px',
                            height: '47px',
                            marginLeft: '-12px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Spinach Logo (Edit Summary)' });
                            routeToAIDashboard();
                        }}
                    />
                    <PrimaryButton
                        title="Send"
                        isLoading={isLoading}
                        loadingText={isLoading ? 'Sending...' : undefined}
                        disabled={!botId}
                        style={{ height: '40px' }}
                        onClick={async () => {
                            if (!botId) {
                                return;
                            }

                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Send Edited Summary',
                            });

                            setIsLoading(true);
                            const response = await postEditedSummary({
                                summaryJSON,
                                botId,
                            });

                            if (!response.success) {
                                setNotificationText(
                                    "We couldn't send your summary at this time. Try again in a bit or contact us."
                                );
                            } else {
                                const params = new URLSearchParams();
                                params.append(WebUrlQuery.EditSuccess, 'true');
                                params.append(WebUrlQuery.BotId, botId);
                                params.append(WebUrlQuery.SeriesId, seriesId);
                                setActiveSection(DashboardSection.Summaries);
                                routeToAIDashboard({ replace: true }, params);
                            }
                            setIsLoading(false);
                        }}
                    />
                </Row>

                <ScrollArea sidePadding={0} style={{ paddingLeft: '30px' }}>
                    <Row>
                        <HeaderThreeOnboard>
                            {meetingTitle} - {meetingTime}
                        </HeaderThreeOnboard>
                    </Row>
                    <Row>
                        <BodyRegularOnboard>Review and edit the summary below before sending.</BodyRegularOnboard>
                    </Row>
                    <Spacing />
                    <Row>
                        <HeaderThree>Attendees</HeaderThree>
                    </Row>
                    <Row>
                        <BodyRegularOnboard>
                            {summaryJSON.attendees
                                .filter((attendee) => attendee.toLowerCase() !== 'spinach.io')
                                .filter((attendee) => attendee.toLowerCase() !== 'spinach ai')
                                .join(', ')}
                        </BodyRegularOnboard>
                    </Row>

                    {meetingType === ScribeMeetingType.Generic ? (
                        <SectionContainer
                            intent={FeatureIntent.MeetingAgendaItems}
                            enabledSections={enabledSummarySections}
                        >
                            <AgendaItemSection setSummaryJSON={setSummaryJSON} summaryJSON={summaryJSON} />
                        </SectionContainer>
                    ) : null}

                    <SectionContainer
                        intent={FeatureIntent.MeetingActionItems}
                        enabledSections={enabledSummarySections}
                    >
                        <ActionItemSection
                            setSummaryJSON={setSummaryJSON}
                            summaryJSON={summaryJSON}
                            usernameEmailMapping={usernameEmailMapping}
                        />
                    </SectionContainer>

                    <SectionContainer
                        intent={FeatureIntent.MeetingKeyDecisions}
                        enabledSections={enabledSummarySections}
                    >
                        <KeyDecisionSection setSummaryJSON={setSummaryJSON} summaryJSON={summaryJSON} />
                    </SectionContainer>

                    <SectionContainer intent={FeatureIntent.MeetingBlockers} enabledSections={enabledSummarySections}>
                        <BlockerSection setSummaryJSON={setSummaryJSON} summaryJSON={summaryJSON} />
                    </SectionContainer>

                    <SectionContainer
                        intent={FeatureIntent.MeetingCelebrations}
                        enabledSections={enabledSummarySections}
                    >
                        <CelebrationSection setSummaryJSON={setSummaryJSON} summaryJSON={summaryJSON} />
                    </SectionContainer>

                    {meetingType === ScribeMeetingType.Standup ? (
                        <SectionContainer
                            intent={FeatureIntent.PerPersonUpdates}
                            enabledSections={enabledSummarySections}
                        >
                            <PersonalUpdatesSection setSummaryJSON={setSummaryJSON} summaryJSON={summaryJSON} />
                        </SectionContainer>
                    ) : null}
                </ScrollArea>
            </Column>
            <Notification
                containerStyle={{ position: 'absolute', bottom: '10px' }}
                isOpen={!!notificationText}
                duration={7000}
                onClose={() => setNotificationText('')}
                message={notificationText}
                icon={<Info style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />}
                contentStyle={{ width: 'unset' }}
            />
        </ViewContainer>
    );
}
