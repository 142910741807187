import { ClientUser } from '@spinach-shared/models';
import { DateTimeMetadata, StoredSeries } from '@spinach-shared/types';

import { SetValue } from '../../../types';
import { SlidingContent } from '../../common';

export type DashboardUser = {
    user: ClientUser;
};

export type InviteTeamViewProps = SlidingContent &
    DashboardUser & {
        series: StoredSeries;
        onNext: () => void;
        onBack?: () => void;
    };

export type StandupNameProps = SlidingContent &
    DashboardUser & {
        standupName: string;
        setStandupName: SetValue<string>;
        isLoading: boolean;
        onSubmit: () => Promise<void>;
        onBack: () => void;
    };

export type StandupTimesProps = {
    standupDayTimesSelected: DateTimeMetadata[];
    setStandupDayTimesSelected: SetValue<DateTimeMetadata[]>;
    onSubmit: () => Promise<void>;
    isLoading: boolean;
};

export type StandupTimesViewProps = SlidingContent &
    StandupTimesProps & {
        onSubmit: () => Promise<void>;
        onBack: () => void;
        isLoading: boolean;
    };

export type LetsGoProps = SlidingContent &
    DashboardUser & {
        onSubmit: () => void;
        isLoading: boolean;
        isStep2: boolean;
    };

export type RequestAndRedirectProps = SlidingContent &
    DashboardUser & {
        onBack: () => void;
    };

export enum EmptySeriesStep {
    StandupName,
    RequestAndRedirect,
}
