import styled from 'styled-components';

import { SpinachStationary } from '../common';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    align-items: center;
    position: relative;
`;
export const ContentContainer = styled(SpinachStationary)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 73px 83px;
    position: relative;
`;

export const ButtonContainer = styled.div`
    top: 30px;
    left: 45px;
    position: absolute;
`;
