import styled from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking, useGlobalAuthedUser } from '../../../../hooks';
import { BodyRegularOnboard, HeaderThree } from '../../../../styles';
import { Anchor, Row, Spacing } from '../../../common';
import { PrimaryButton } from '../../../stand-up';

const BannerContainer = styled.div`
    border-radius: 6px;
    border: 1px solid #cecece;
    width: 100%;
    padding: 12px 12px 33px 12px;
    position: relative;
`;

export function BillingPortalBanner(props: {
    onBillingPortalClick: () => void;
    isNavigatingToCustomerPortal: boolean;
}) {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();

    const features = user.isPayingUser
        ? ['Update Payment Method', 'Update Billing Information', 'View Previous Invoices', 'View Current Plan']
        : ['View Previous Invoices'];

    return (
        <BannerContainer>
            <Row style={{ zIndex: 1 }}>
                <HeaderThree>Billing Portal</HeaderThree>
            </Row>
            <Spacing factor={1 / 3} />
            <Row>
                <BodyRegularOnboard>Access the billing portal to manage your account. You can:</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 3} />
            <ul>
                {features.map((feature, i) => (
                    <li key={i}>
                        <BodyRegularOnboard>{feature}</BodyRegularOnboard>
                        <Spacing factor={1 / 3} />
                    </li>
                ))}
            </ul>
            <Spacing />
            <Row style={{ justifyContent: 'center' }}>
                <PrimaryButton
                    style={{ paddingLeft: '73px', paddingRight: '73px' }}
                    title={'Access Billing Portal'}
                    onClick={() => props.onBillingPortalClick()}
                    isLoading={props.isNavigatingToCustomerPortal}
                />
            </Row>
            <Spacing factor={1 / 3} />
            <Row style={{ justifyContent: 'center' }}>
                <BodyRegularOnboard>
                    Have a question or need to cancel? Contact us{' '}
                    <Anchor
                        id={'spinach_intercom'}
                        // dynamic id if we have no article
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Billing Help`,
                            });
                        }}
                    >
                        here
                    </Anchor>
                </BodyRegularOnboard>
            </Row>
        </BannerContainer>
    );
}
