import { ClientEventType, MeetingSettingsSubview, SpinachAPIPath } from '@spinach-shared/types';

import GoogleDriveLogo from '../../../assets/integrations/google-drive.png';
import { useExperienceTracking, useGlobalAuthedUser, useIntegrationDetection } from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { URLUtil } from '../../../utils';
import { AppIntegrationRow, IntegrationButtonText } from './AppIntegrationRow';
import { IntegrationRowProps } from './common';

function useGoogleDriveIntegrationRow() {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { setSubview } = useGlobalMeetingSettings();

    function openSettingsOnSuccess() {
        setSubview(MeetingSettingsSubview.GoogleDriveSettings);
    }

    const startDetection = useIntegrationDetection(openSettingsOnSuccess);

    const isConnectFlow = !user.isAuthedForGoogleDrive;

    async function onClick() {
        if (isConnectFlow) {
            track(ClientEventType.UserAuthorizeGoogleDriveClick);
            startDetection();
            URLUtil.openURL(`${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleDriveAuth}`);
        } else {
            setSubview(MeetingSettingsSubview.GoogleDriveSettings);
            track(ClientEventType.OpenGoogleDriveSettingsSubviewClick);
        }
    }

    return {
        subtext: `Save summaries to Google Drive`,
        onClick,
        isConnected: user.isAuthedForGoogleDrive,
        buttonText: user.isAuthedForGoogleDrive ? IntegrationButtonText.Configure : IntegrationButtonText.Connect,
    };
}

export function GoogleDriveIntegrationRow({ isConnectViewOnly }: IntegrationRowProps): JSX.Element {
    const { onClick, subtext, isConnected, buttonText } = useGoogleDriveIntegrationRow();

    return (
        <AppIntegrationRow
            onCTAClick={onClick}
            subtext={subtext}
            label={'Google Drive'}
            icon={<img src={GoogleDriveLogo} style={{ width: '24px', height: '24px' }} />}
            isConnected={isConnected}
            buttonText={buttonText}
            isConnectViewOnly={isConnectViewOnly}
        />
    );
}
