import { MeetingSettingsSubview, SpinachIntegration } from '@spinach-shared/types';

import NotionLogo from '../../../assets/integrations/notion-logo.svg';
import { useGlobalAuthedUser } from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { useIntegrationRow } from '../../../hooks/useIntegrationRow';
import { AppIntegrationRow } from './AppIntegrationRow';
import { IntegrationRowProps } from './common';

export function NotionIntegrationRow({ isConnectViewOnly }: IntegrationRowProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { setSubview } = useGlobalMeetingSettings();

    const defaultDatabaseName = user.integrationSettings?.notionSettings?.defaultDatabaseName;
    const defaultDatabaseId = user.integrationSettings?.notionSettings?.defaultDatabaseId;
    const connectedText =
        defaultDatabaseName && defaultDatabaseId ? (
            <span>
                Summaries will be saved to{' '}
                <a href={`https://www.notion.so/${defaultDatabaseId.replaceAll('-', '')}`}>{defaultDatabaseName}</a>
            </span>
        ) : (
            'Select a page to save summaries to'
        );

    const onIntegrationSuccess = () => {
        setSubview(MeetingSettingsSubview.NotionSettings);
    };
    const { onClick, subtext, isConnected, buttonText, isButtonDisabled } = useIntegrationRow(
        SpinachIntegration.Notion,
        MeetingSettingsSubview.NotionSettings,
        user.isAuthedForNotion,
        user.isAuthedForNotion ? connectedText : `Save summaries to Notion`,
        onIntegrationSuccess
    );

    return (
        <AppIntegrationRow
            onCTAClick={onClick}
            subtext={subtext}
            label={'Notion'}
            icon={<img src={NotionLogo} style={{ width: '24px', height: '24px' }} />}
            isConnected={isConnected}
            buttonText={buttonText}
            isButtonDisabled={isButtonDisabled}
            isConnectViewOnly={isConnectViewOnly}
        />
    );
}
