import { Modal } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { useCallback, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { BYTES_IN_MB } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import { deleteBrandedImage } from '../../../apis/deleteBrandedImage';
import { uploadBrandImage } from '../../../apis/uploadBrandImage';
import { ReactComponent as FileUpload } from '../../../assets/file-upload.svg';
import { ReactComponent as SpinachAIVideoImage } from '../../../assets/spinach-video-display-icon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/trash-icon.svg';
import { GlobalModal } from '../../../atoms';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalModal } from '../../../hooks';
import {
    useGlobalBrandedImageUri,
    useGlobalCombinedSpinachVideoBackgroundImageUri,
} from '../../../hooks/useGlobalBrandedImage';
import { BodyRegularOnboard, ResponsiveModalTitle, lightTheme } from '../../../styles';
import { Notification, PrimaryButton } from '../../stand-up';
import { OutlinedButton } from '../../stand-up/OutlinedButton';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

const ACCEPTABLE_FILE_TYPES = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
};

const MAX_FILE_SIZE_IN_BYES = 3 * BYTES_IN_MB;

const getErrorMessageFromDropzoneErrorCode = (errorCode: ErrorCode | string): string | null => {
    switch (errorCode) {
        case ErrorCode.FileInvalidType:
            return `File type must be: ${Object.values(ACCEPTABLE_FILE_TYPES).join(', ')}`;
        case ErrorCode.FileTooLarge:
            return `The file must be smaller than ${MAX_FILE_SIZE_IN_BYES / BYTES_IN_MB}MB`;
        case ErrorCode.FileTooSmall:
            return 'The file must be larger than 0 bytes';
        case ErrorCode.TooManyFiles:
            return 'You may only upload one file at a time.';
        default:
            return null;
    }
};

const DragAndDropRow = styled(Row)<{ isDragActive: boolean }>`
    padding: 10px;
    display: flex;
    width: 80%;
    background-color: #f7f7f7;
    border-color: ${(props) => (props.isDragActive ? lightTheme.tertiary.greenDark : '#CFD9D3')};
    border-style: ${(props) => (props.isDragActive ? 'solid' : 'dashed')};
    border-radius: 8px;
    border-width: 1px;
`;

function Dropzone({
    setError,
    customUserImage,
    setCustomUserImage,
    setFile,
}: {
    customUserImage: string | null;
    setCustomUserImage: (image: string | null) => void;
    setError: (error: string | null) => void;
    setFile: (file: Blob | null) => void;
}) {
    const track = useExperienceTracking();
    const [isHovering, setHovering] = useState(false);

    const onDrop = useCallback((acceptedFiles: Blob[]) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => track(ClientEventType.AIDashboardActivity, { Action: 'Aborted Selecting Image' });
            reader.onerror = (e) =>
                track(ClientEventType.AIDashboardActivity, {
                    Action: 'Error Selecting Image',
                    ErrorMessage: e.target?.error?.message,
                    ErrorName: e.target?.error?.name,
                });
            reader.onload = async (e) => {
                if (!e.target) {
                    return;
                }
                const { result } = e.target;
                setFile(file);
                if (result) {
                    let parsedResult;
                    if (typeof result === 'string') {
                        parsedResult = result;
                    } else {
                        parsedResult = btoa(String.fromCharCode(...new Uint8Array(result)));
                    }
                    track(ClientEventType.AIDashboardActivity, { Action: 'Selected Image Accepted' });

                    setCustomUserImage(parsedResult);
                }
            };
            reader.readAsDataURL(file);
        });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: ACCEPTABLE_FILE_TYPES,
        onDropRejected: (fileRejections) => {
            track(ClientEventType.AIDashboardActivity, {
                Action: 'Selected Files Rejectd',
                NumberOfRejectedFiles: fileRejections.length,
                Reasons: fileRejections.map((fileRejection) => fileRejection.errors[0].message).join(', '),
            });

            const errorMessage =
                getErrorMessageFromDropzoneErrorCode(fileRejections[0].errors[0].code) ??
                fileRejections[0].errors[0].message;

            setError(errorMessage);
        },
        onDrop,
        maxFiles: 1,
        maxSize: MAX_FILE_SIZE_IN_BYES,
    });

    return (
        <Column>
            {customUserImage ? (
                <div
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    style={{ position: 'relative', display: 'flex' }}
                >
                    <img
                        style={{ width: '100px', height: '100px', display: 'flex', borderRadius: '100%' }}
                        src={customUserImage}
                    />
                    {isHovering ? (
                        <TrashIcon
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                right: 0,
                                color: lightTheme.status.negative,
                            }}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Delete Branded Image',
                                });
                                setCustomUserImage(null);
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <SpinachAIVideoImage style={{ width: '100px', height: '100px' }} />
            )}

            <Spacing factor={1 / 2} />
            <DragAndDropRow vCenter {...getRootProps({ isDragActive })}>
                <input {...getInputProps()} />
                <Column>
                    <BodyRegularOnboard>Drag and drop or</BodyRegularOnboard>
                    <Spacing factor={1 / 2} />
                    <OutlinedButton
                        labelStyles={{ alignItems: 'center', fontSize: '14px' }}
                        title="Select file"
                        endIcon={<FileUpload style={{ marginLeft: '8px' }} />}
                        onClick={() => undefined}
                    />
                    <Spacing factor={1 / 2} />
                    <BodyRegularOnboard style={{ fontWeight: 400, fontSize: 12, color: lightTheme.tertiary.midnight }}>
                        Max file size {MAX_FILE_SIZE_IN_BYES / BYTES_IN_MB}MB. Images will be cropped to a square
                        (resolution of 500x500px)
                    </BodyRegularOnboard>
                </Column>
            </DragAndDropRow>
        </Column>
    );
}

const ImageUploadModalContent = ({ onClose }: { onClose: () => void }) => {
    const track = useExperienceTracking();
    const [error, setError] = useState<string | null>(null);
    const [brandedImageUri, setBrandedImageUri] = useGlobalBrandedImageUri();
    const [, setCombinedBrandedVideoBackgroundImageUri] = useGlobalCombinedSpinachVideoBackgroundImageUri();
    const [customUserImage, setCustomeUserImage] = useState<string | null>(brandedImageUri);
    const [file, setFile] = useState<Blob | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <SpinachModalContent onClose={onClose} style={{ overflow: 'hidden' }}>
            <Column centered style={{ flexGrow: 1 }}>
                <Row centered>
                    <ResponsiveModalTitle>Display Settings</ResponsiveModalTitle>
                </Row>
                <Spacing factor={1 / 3} />
            </Column>

            <Dropzone
                setFile={setFile}
                customUserImage={customUserImage}
                setCustomUserImage={setCustomeUserImage}
                setError={setError}
            />

            <span style={{ flex: 1 }} />
            <BodyRegularOnboard>This will show up in your next meeting</BodyRegularOnboard>
            <Spacing factor={1 / 3} />
            <PrimaryButton
                title="Update display"
                disabled={customUserImage === brandedImageUri || (!brandedImageUri && !customUserImage) || isLoading}
                isLoading={isLoading}
                onClick={async () => {
                    try {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Update Branded Image',
                        });

                        setIsLoading(true);
                        if (file) {
                            const imageUri = await uploadBrandImage(file);
                            setBrandedImageUri(imageUri);
                            track(ClientEventType.AIDashboardActivity, { Action: 'Uploaded Branded Image' });
                        } else {
                            await deleteBrandedImage();
                            track(ClientEventType.AIDashboardActivity, { Action: 'Deleted Branded Image' });
                            setBrandedImageUri(null);
                        }
                        setCombinedBrandedVideoBackgroundImageUri(null);
                    } catch (e) {
                        throw e;
                    } finally {
                        setIsLoading(false);
                        onClose();
                    }
                }}
            />
            <Notification
                containerStyle={{ position: 'absolute', bottom: 'unset' }}
                isOpen={!!error}
                onClose={() => setError(null)}
                message={error}
                icon={<Error style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />}
            />
        </SpinachModalContent>
    );
};

export const ImageUploadModal = () => {
    const [user] = useGlobalAuthedUser();
    const [globalModal, setGlobalModal] = useGlobalModal();

    const track = useExperienceTracking();

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Image Upload Modal',
        });
        setGlobalModal(null);
    };

    if (!user) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.ImageUpload} onClose={closeModal}>
            <ImageUploadModalContent onClose={closeModal} />
        </Modal>
    );
};
