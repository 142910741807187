import { useCallback } from 'react';

import { getCommandContents } from '@spinach-shared/models';

import { SendFunction } from '../../../types/agent';
import { useFetchVideoAgentConfig } from '../useFetchVideoAgentConfig';
import { useGlobalNullableVideoAgent } from '../useGlobalVideoAgent';

export function getVideoCommandParameter(message: string): string {
    const { remainingCommand: videoParameter } = getCommandContents(message, ['video', '/video']);

    return videoParameter;
}

export function useVideoCommand(send: SendFunction) {
    const {
        state: { session },
    } = useGlobalNullableVideoAgent();
    const config = useFetchVideoAgentConfig(session?.hostId);

    return useCallback(
        async (matchedCommand: string) => {
            const shouldStopVideo = matchedCommand.toLowerCase() === 'stop';
            const shouldPlayVideo = !shouldStopVideo && !!config.videos[matchedCommand.toLowerCase()];

            if (shouldStopVideo) {
                send('STOP_VIDEO');
            } else if (shouldPlayVideo) {
                send('PLAY_VIDEO', { videoUrl: config.videos[matchedCommand.toLowerCase()] });
            }
        },
        [send, config]
    );
}
