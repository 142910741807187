import { ClientLogger } from './logger';

function clarityInit(id: string) {
    (function (c: any, l: any, a: any, r: any, i: any, t?: any, y?: any) {
        c[a] =
            c[a] ||
            function () {
                (c[a].q = c[a].q || []).push(arguments);
            };
        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', id);
}

function callClarityMethod(methodName: string, ...args: any[]) {
    if (!('clarity' in window) || typeof window.clarity !== 'function') {
        ClientLogger.error('Clarity not initialized when trying to call method', { ...args, methodName });
        return;
    }

    window.clarity.apply(undefined, [methodName, ...args]);
}

function init(id: string) {
    clarityInit(id);
}

function hasStarted() {
    return 'clarity' in window && typeof window.clarity === 'function';
}

function identify(userId: string, ...properties: any[]) {
    callClarityMethod('identify', userId, properties);
}

function consent() {
    callClarityMethod('consent');
}

function setTag(key: string, value: string) {
    callClarityMethod('set', key, value);
}

function upgrade(reason: string) {
    callClarityMethod('upgrade', reason);
}

function postEvent(eventName: string) {
    callClarityMethod('postEvent', eventName);
}

export const clarity = {
    event: postEvent,
    init,
    hasStarted,
    identify,
    consent,
    setTag,
    upgrade,
};
