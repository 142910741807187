import { SpinachList, SpinachUpdateType, TypedUpdate, UUID, YTBHistoryJSON } from '@spinach-shared/types';

import { YTBArchiveProps } from './YTBArchiveProps';
import { YTBArchivePropsFactory } from './YTBArchivePropsFactory';

export class YTBHistoryProps {
    userId: UUID;
    seriesId: UUID;
    archives: YTBArchiveProps[];

    constructor(history: YTBHistoryJSON) {
        this.userId = history.userId;
        this.seriesId = history.seriesId;
        this.archives = history.archives.map(YTBArchivePropsFactory.fromJSON);
    }

    get mostRecentTodays(): TypedUpdate[] {
        return this.archives?.[0]?.updates?.filter((update) => update.updateType === SpinachUpdateType.Today) ?? [];
    }

    get mostRecentBlockers(): TypedUpdate[] {
        return this.archives?.[0]?.updates?.filter((update) => update.updateType === SpinachUpdateType.Challenge) ?? [];
    }

    getMostRecentCustom(enabledCustomLists: SpinachList[]): TypedUpdate[] {
        return (
            this.archives?.[0]?.updates
                ?.filter((update) => update.updateType === SpinachUpdateType.Custom)
                .filter((u) => enabledCustomLists.some((l) => l.id === u.customListId)) ?? []
        );
    }
}
