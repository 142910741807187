import { Box, LinearProgress, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { ChangeEvent, useRef, useState } from 'react';

import { SpinachAPIPath } from '@spinach-shared/types/src/APIs';
import { FileUploadResponse } from '@spinach-shared/types/src/FileUpload';

import { postSpinachAPI } from '../../../..';
import { Row, useGlobalAiDashboard } from '../../../../..';
import { useGlobalUser } from '../../../../hooks/useGlobalUser';
import { lightTheme } from '../../../../styles/theme';
import axios from 'axios';


export const FileUploader = () => {
    const { setToastText } = useGlobalAiDashboard();
    const [user] = useGlobalUser();
    const [participantNames, setParticipantNames] = useState<string>(user?.preferredName || '');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [lastUploadId, setLastUploadId] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const uploadFileToS3 = async (file: File, signedUrlResponse: FileUploadResponse) => {
        const { s3PostResponse } = signedUrlResponse;
        if (!s3PostResponse) {
            throw new Error('Missing S3 post response');
        }

        const formData = new FormData();
        Object.entries(s3PostResponse.fields).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append('file', file);

        const xhr = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    const progress = Math.round((event.loaded / event.total) * 100);
                    setUploadProgress(progress);
                }
            });

            xhr.addEventListener('load', () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(xhr.response);
                } else {
                    reject(new Error(`Upload failed: ${xhr.status} ${xhr.statusText}`));
                }
            });

            xhr.addEventListener('error', () => {
                reject(new Error('Upload failed'));
            });

            xhr.open('POST', s3PostResponse.url);

            if (s3PostResponse.fields['x-amz-tagging']) {
                xhr.setRequestHeader('x-amz-tagging', decodeURIComponent(s3PostResponse.fields['x-amz-tagging']));
            }

            xhr.send(formData);
        });
    };

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;


        setIsUploading(true);
        setUploadProgress(0);

        try {
            // Get signed URL
            const signedUrlResponse = await postSpinachAPI<FileUploadResponse>(
                SpinachAPIPath.NewUploadFile,
                {
                    contentType: file.type,
                    contentLength: file.size,
                },
                {
                    throwOnError: true,
                }
            );

            if (!signedUrlResponse?.s3PostResponse) {
                throw new Error('Failed to get upload URL');
            }

            // Upload to S3
            await uploadFileToS3(file, signedUrlResponse);

            // Complete upload
            await postSpinachAPI(SpinachAPIPath.CompleteUploadFile, {
                fileUploadId: signedUrlResponse.fileUploadId,
                participantNames: participantNames.split(',').filter(Boolean),
            });

            setLastUploadId(signedUrlResponse.fileUploadId);
            setToastText('File uploaded successfully');
        } catch (error) {
            Sentry.captureException(error);
            if (axios.isAxiosError(error)) {
                // todo improve error handling
                setToastText(error.response?.data.message || 'Failed to upload file');
            } else {
                setToastText('Failed to upload file');
            }
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    return (
        <Row style={{ marginBottom: '20px' }}>
            <Box
                style={{
                    width: '100%',
                    maxWidth: '600px',
                    border: `1px solid ${lightTheme.neutrals.grayDark}`,
                    borderRadius: '4px',
                    padding: '10px',
                }}
            >
                <Typography variant="subtitle1">Comma separated participant names for uploaded file</Typography>
                <input
                    type="text"
                    value={participantNames}
                    onChange={(e) => setParticipantNames(e.target.value)}
                    placeholder="Enter participant names"
                    style={{
                        width: '100%',
                        padding: '10px',
                        border: `1px solid ${lightTheme.neutrals.grayDark}`,
                        borderRadius: '4px',
                        marginBottom: '10px',
                    }}
                />
                <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFileChange}
                    disabled={isUploading}
                    style={{
                        width: '100%',
                        padding: '10px',
                        border: `1px solid ${lightTheme.neutrals.grayDark}`,
                        borderRadius: '4px',
                    }}
                />
                {lastUploadId && (
                    <Typography variant="body2" style={{ marginTop: '10px' }}>
                        Last upload ID: {lastUploadId}
                    </Typography>
                )}
                {isUploading && (
                    <Box mt={2}>
                        <LinearProgress variant="determinate" value={uploadProgress} style={{ marginBottom: '8px' }} />
                        <Typography variant="body2" align="center">
                            Uploading... {uploadProgress}%
                        </Typography>
                    </Box>
                )}
            </Box>
        </Row>
    );
};
