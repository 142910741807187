import { Popover, makeStyles } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';
import { isLocalStage } from '@spinach-shared/utils';

import { ReactComponent as CustomizeStandupIcon } from '../../../assets/customize-standup-icon.svg';
import { ReactComponent as DashboardIcon } from '../../../assets/dashboard-icon.svg';
import { ReactComponent as EmailSummariesIcon } from '../../../assets/email-summaries-icon.svg';
import { ReactComponent as IntegrationsIcon } from '../../../assets/integrations-icon.svg';
import { ReactComponent as InviteIcon } from '../../../assets/invite-icon.svg';
import { ReactComponent as PracticeRoundIcon } from '../../../assets/practice-round-icon.svg';
import { ReactComponent as ScheduleIcon } from '../../../assets/schedule-icon.svg';
import { ReactComponent as StandupSettingsIcon } from '../../../assets/standup-settings.svg';
import { GlobalModal } from '../../../atoms';
import {
    useEmailSummariesEnablement,
    useExperienceTracking,
    useFreeTierLimitations,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalRouting,
    useSeriesReality,
} from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { SetValue } from '../../../types';
import { withAccessibleSubmitProps } from '../../../utils';
import {
    OptionRow,
    RegularCardOptionItem,
    SeriesOptionsButton,
    SeriesOptionsButtonContainer,
} from '../../series/common';
import { BootstrapTooltip } from '../BootstrapTooltip';
import { FreeTierLimitationMessage, LimitationIntent } from '../FreeTierMessages';
import { Column } from '../framing';

const usePopoverStyles = makeStyles(() => ({
    paper: {
        borderRadius: '10px',
        padding: '10px',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 5px 5px 0px 1px rgba(0, 0, 0, 0.15)',
    },
}));

const OptionsButton = styled(SeriesOptionsButton)`
    margin-top: -3px;
    margin-right: 20px;
    background-color: unset;
`;

type MeetingSettingOptionProps = {
    icon: NonNullable<ReactNode>;
    onClick: () => void;
    title: string;
    isLastRow?: boolean;
    isOpeningSubMenu?: boolean;
    shouldKeepMenuOpen?: boolean;
    disabled?: boolean;
    className?: string;
};

function MeetingSettingOption({
    icon,
    onClick,
    title,
    isLastRow,
    isOpeningSubMenu,
    shouldKeepMenuOpen = false,
    disabled,
    className,
}: MeetingSettingOptionProps): JSX.Element {
    return (
        <OptionRow
            className={className}
            style={{
                cursor: disabled ? 'not-allowed' : 'pointer',
            }}
            {...withAccessibleSubmitProps((e) => {
                if (shouldKeepMenuOpen || isOpeningSubMenu) {
                    e.stopPropagation();
                }

                onClick();
            })}
            isLastRow={!!isLastRow}
        >
            <Column style={{ width: '25px' }}>{icon}</Column>
            <span style={{ width: '10px' }} />
            <Column style={{ alignItems: 'flex-start' }}>
                <RegularCardOptionItem>{title}</RegularCardOptionItem>
            </Column>
            {isOpeningSubMenu ? (
                <Column style={{ width: '20px' }}>
                    <ChevronRight style={{ fontSize: '18px' }} />
                </Column>
            ) : null}
        </OptionRow>
    );
}

function BackToDashboardButton({ isLastRow }: { isLastRow?: boolean }): JSX.Element {
    const { routeToDashboard, routeToAIDashboard } = useGlobalRouting();
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    return (
        <MeetingSettingOption
            icon={<DashboardIcon />}
            isLastRow={!!isLastRow}
            onClick={() => {
                track(ClientEventType.BackToDashboardClick);
                if (user.isEnabledForCombinedSummaries) {
                    routeToAIDashboard();
                } else {
                    routeToDashboard();
                }
            }}
            title={'Go to Meeting Dashboard'}
        />
    );
}

function AppIntegrationsButton(): JSX.Element {
    const [, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();

    return (
        <MeetingSettingOption
            icon={<IntegrationsIcon />}
            onClick={() => {
                track(ClientEventType.IntegrationsButtonClick);
                setGlobalModal(GlobalModal.IntegrationsSettings);
            }}
            title={'Integrations'}
        />
    );
}

function StandupSettingsButton({ setSubMenu }: { setSubMenu: SetValue<SubMenu | null> }): JSX.Element {
    const track = useExperienceTracking();

    return (
        <MeetingSettingOption
            icon={<StandupSettingsIcon />}
            onClick={() => {
                track(ClientEventType.StandupSettingsSubmenuClick);
                setSubMenu(SubMenu.StandupSettings);
            }}
            title={'Standup Settings'}
            isOpeningSubMenu={true}
        />
    );
}

function CustomizeStandupButton({ setSubMenu }: { setSubMenu: SetValue<SubMenu | null> }): JSX.Element {
    const [, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();

    return (
        <MeetingSettingOption
            icon={<CustomizeStandupIcon />}
            onClick={() => {
                track(ClientEventType.OpenCustomizeStandupSettingsClick);
                setGlobalModal(GlobalModal.SeriesSettings);
                setSubMenu(null);
            }}
            title={'Customize Standup'}
        />
    );
}

function InviteButton(): JSX.Element {
    const [, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();

    return (
        <MeetingSettingOption
            icon={<InviteIcon />}
            onClick={() => {
                setGlobalModal(GlobalModal.ShareSeries);
                track(ClientEventType.InviteButtonClicked);
            }}
            title={'Invite'}
        />
    );
}

function PracticeRoundButton(): JSX.Element {
    const { routeToDemoExperience } = useGlobalRouting();
    const track = useExperienceTracking();
    return (
        <MeetingSettingOption
            isLastRow={true}
            icon={<PracticeRoundIcon />}
            onClick={() => {
                track(ClientEventType.ToggleDemoModeClicked, { Location: 'meeting-settings' });
                routeToDemoExperience();
            }}
            title="Enable practice round"
        />
    );
}

function ScheduleButton({ setSubMenu }: { setSubMenu: SetValue<SubMenu | null> }): JSX.Element {
    const { openScheduleSettings } = useGlobalMeetingSettings();
    const track = useExperienceTracking();

    return (
        <MeetingSettingOption
            icon={<ScheduleIcon />}
            onClick={() => {
                track(ClientEventType.OpenScheduleModalFromSettingsClick);
                openScheduleSettings();
                setSubMenu(null);
            }}
            title="Schedule"
        />
    );
}

function ManageEmailSummariesButton({ setSubMenu }: { setSubMenu: SetValue<SubMenu | null> }): JSX.Element {
    const [, setGlobalModal] = useGlobalModal();
    const isEmailSummariesEnabled = useEmailSummariesEnablement();
    const track = useExperienceTracking();
    const isFreeTierLimited = useFreeTierLimitations();

    if (!isEmailSummariesEnabled) {
        return <></>;
    }

    return (
        <BootstrapTooltip
            interactive={isFreeTierLimited}
            title={
                isFreeTierLimited ? (
                    <FreeTierLimitationMessage intent={LimitationIntent.SummaryExport} style={{ fontSize: '14px' }} />
                ) : (
                    ''
                )
            }
            placement="right"
        >
            <MeetingSettingOption
                icon={<EmailSummariesIcon />}
                onClick={() => {
                    if (isFreeTierLimited) {
                        return;
                    }

                    const location = 'meeting-settings';
                    track(ClientEventType.OpenSubscribeSeriesOutputModalClick, { Location: location });

                    setGlobalModal(GlobalModal.SubscribeSeriesOutput, { location });
                    setSubMenu(null);
                }}
                title="Manage Email Summaries"
                isLastRow={true}
                disabled={isFreeTierLimited}
                shouldKeepMenuOpen={isFreeTierLimited}
            />
        </BootstrapTooltip>
    );
}

function BackButton({ setSubMenu }: { setSubMenu: SetValue<SubMenu | null> }): JSX.Element {
    const track = useExperienceTracking();

    return (
        <MeetingSettingOption
            icon={<ChevronLeft style={{ fontSize: '18px' }} />}
            onClick={() => {
                track(ClientEventType.BackButtonInSettingsClick);
                setSubMenu(null);
            }}
            title="Back"
            shouldKeepMenuOpen={true}
        />
    );
}

export function PrimarySettings({ setSubMenu }: { setSubMenu: SetValue<SubMenu | null> }): JSX.Element {
    const [user] = useGlobalAuthedUser();
    return (
        <>
            <BackToDashboardButton />
            <StandupSettingsButton setSubMenu={setSubMenu} />
            {user.isEnabledForCombinedSummaries ? <></> : <InviteButton />}
            <AppIntegrationsButton />
            <PracticeRoundButton />
        </>
    );
}

export function StandupSettings({ setSubMenu }: { setSubMenu: SetValue<SubMenu | null> }): JSX.Element {
    return (
        <>
            <BackButton setSubMenu={setSubMenu} />
            <CustomizeStandupButton setSubMenu={setSubMenu} />
            <ScheduleButton setSubMenu={setSubMenu} />
            <ManageEmailSummariesButton setSubMenu={setSubMenu} />
        </>
    );
}

export function StandupScribeSeriesSettings(): JSX.Element {
    return (
        <>
            <BackToDashboardButton isLastRow={true} />
        </>
    );
}

enum SubMenu {
    StandupSettings = 'standup-settings',
}

export function MeetingSettings(): JSX.Element {
    const popoverStyles = usePopoverStyles();
    const [isUserOptionsOpen, setIsUserOptionsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { isDemoSeries } = useSeriesReality();

    const onOptionsClick = async (e: React.MouseEvent | React.KeyboardEvent) => {
        e.stopPropagation();
        setIsUserOptionsOpen(!isUserOptionsOpen);
        setAnchorEl(document.getElementById(`user-settings`));
    };

    const [subMenu, setSubMenu] = useState<SubMenu | null>(null);

    if (isDemoSeries && !isLocalStage()) {
        return <></>;
    }

    return (
        <SeriesOptionsButtonContainer
            style={{ flexGrow: 0, zIndex: 100 }}
            {...withAccessibleSubmitProps(onOptionsClick)}
        >
            <OptionsButton id={`user-settings`} />

            {!!isUserOptionsOpen ? (
                <Popover
                    classes={popoverStyles}
                    id={'popover'}
                    open={isUserOptionsOpen}
                    anchorEl={anchorEl}
                    onClose={() => {
                        setIsUserOptionsOpen(false);
                        setSubMenu(null);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    {subMenu === SubMenu.StandupSettings ? (
                        <StandupSettings setSubMenu={setSubMenu} />
                    ) : (
                        <PrimarySettings setSubMenu={setSubMenu} />
                    )}
                </Popover>
            ) : null}
        </SeriesOptionsButtonContainer>
    );
}
