import { Modal, TextField } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useMemo } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { AiHistoryUserAccessKind, ClientEventType } from '@spinach-shared/types';
import { isEmailValid } from '@spinach-shared/utils';

import { patchDraftRecipients } from '../../../apis/patchDraftRecipients';
import { GlobalModal, GlobalModalMetadataType } from '../../../atoms';
import { ElementId } from '../../../constants';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalModal, useGlobalModalState } from '../../../hooks';
import { BodyLarge, BodyRegular, BodyRegularOnboard, HeaderThree } from '../../../styles';
import { withAccessibleSubmitProps, withContentMasking } from '../../../utils';
import { useSpinachInputStyles } from '../../input';
import { ErrorBodySubtitle, ModalContent } from '../../series/common';
import { PrimaryButton, ScrollArea } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { DropDown } from '../DropDown';
import { ItemList } from '../ItemList';
import { Column, Row, Spacing } from '../framing';

const Content = styled(ModalContent)`
    max-width: 450px;
`;

function ManageDraftSummaryRecipientsModal({
    isLoading,
    isOpen,
    onCancel,
    onSave,
    userEmail,
    hostUserEmail,
    linkedUserEmails,
    userRootDomain,
    defaultList,
    attendees,
    isEnabledForInternalOnlyEmailDistribution,
}: {
    isLoading: boolean;
    isOpen: boolean;
    userEmail: string;
    hostUserEmail: string;
    linkedUserEmails: string[];
    userRootDomain: string;
    defaultList: string[];
    attendees: {
        email: string;
        rootDomain: string;
    }[];
    onCancel: () => void;
    onSave: (emails: string[], hasChanged: boolean) => void;
    isEnabledForInternalOnlyEmailDistribution: boolean;
}): JSX.Element {
    const [emailInput, setEmailInput] = useState('');
    const defaultListWithoutHost = defaultList.filter((e: string) => e !== hostUserEmail);
    const [recipients, setRecipients] = useState<string[]>(
        defaultListWithoutHost.length > 0 ? [hostUserEmail, ...defaultListWithoutHost] : [hostUserEmail]
    );
    const [summaryOption, setSummaryOption] = useState(recipients.length > 1 ? 'Specific people' : 'Just me');
    const classes = useSpinachInputStyles({ value: emailInput });

    const summaryOptions = [
        { code: 'Everyone on the invite', label: 'Everyone on the invite' },
        { code: 'Specific people', label: 'Specific people' },
    ];

    // Only way we can support "just me" is if the editor is the host
    if (hostUserEmail === userEmail) {
        summaryOptions.unshift({ code: 'Just me', label: 'Just me' });
    }

    if (isEnabledForInternalOnlyEmailDistribution) {
        summaryOptions.splice(2, 0, { code: 'Invitees from my company', label: 'Invitees from my company' });
    }
    const error = recipients.includes(emailInput)
        ? 'Email is already added'
        : emailInput?.length > 0 && !isEmailValid(emailInput)
        ? 'Invalid email'
        : '';

    const attendeesWithoutUser = attendees.filter((attendee) => !userEmail.includes(attendee.email));

    const hasListChanged = useMemo(() => {
        return !(
            recipients.length === defaultList.length && recipients.every((recipient) => defaultList.includes(recipient))
        );
    }, [recipients, defaultList]);

    const handleSummaryOptionChange = (option: string) => {
        setSummaryOption(option);
        let newRecipients: string[] = [];
        switch (option) {
            case 'Just me':
                newRecipients = [userEmail];
                break;
            case 'Everyone on the invite':
                newRecipients = [userEmail, ...attendeesWithoutUser.map((attendee) => attendee.email)];
                break;
            case 'Invitees from my company':
                newRecipients = [
                    userEmail,
                    ...attendeesWithoutUser
                        .filter((attendee) => attendee.rootDomain === userRootDomain)
                        .map((attendee) => attendee.email),
                ];
                break;
            case 'Specific people':
                newRecipients = recipients;
                break;
        }

        // make sure host is not removed
        if (!newRecipients.includes(hostUserEmail)) {
            newRecipients = [hostUserEmail, ...newRecipients];
        } else {
            // make sure host is at the top
            newRecipients = [hostUserEmail, ...newRecipients.filter((recipient) => recipient !== hostUserEmail)];
        }

        setRecipients(newRecipients);
    };

    const handleAddEmail = () => {
        if (isEmailValid(emailInput) && !recipients.includes(emailInput)) {
            setRecipients([...recipients, emailInput]);
            setEmailInput('');
            setSummaryOption('Specific people');
        }
    };

    const handleRemoveEmail = (email: string) => {
        const newRecipients = recipients.filter((recipient) => recipient !== email);
        setRecipients(newRecipients);

        if (newRecipients.length === 1) {
            setSummaryOption('Just me');
        } else {
            setSummaryOption('Specific people');
        }
    };

    const allUserEmails = [userEmail, ...linkedUserEmails];

    return (
        <Modal open={isOpen} onClose={onCancel}>
            <Content>
                <Column centered>
                    <HeaderThree>Send Meeting Summary</HeaderThree>
                    <Spacing factor={1} />

                    <Row vCenter style={{ width: 'auto' }}>
                        <BodyRegularOnboard style={{ fontWeight: 'bold', marginRight: '36px' }}>
                            Send summary to
                        </BodyRegularOnboard>
                        <DropDown
                            style={{ width: '220px' }}
                            selected={summaryOption}
                            handleSelection={handleSummaryOptionChange}
                            title="Send summary to"
                            values={summaryOptions}
                        />
                    </Row>
                    <Spacing factor={1} />

                    <Row style={{ flex: 'unset' }}>
                        <TextField
                            id={ElementId.InviteEmailInput}
                            InputProps={{ classes: { root: classes.base } }}
                            {...withContentMasking(classes.root)}
                            fullWidth
                            placeholder="Add by email"
                            value={emailInput}
                            onKeyDown={async (e) => {
                                if (e.key === 'Enter') {
                                    handleAddEmail();
                                }
                            }}
                            onChange={(e) => {
                                setEmailInput(e.target.value.toLowerCase());
                            }}
                        />

                        <PrimaryButton
                            title="Add"
                            loadingText={''}
                            onClick={() => {
                                handleAddEmail();
                            }}
                            disabled={!!error || !emailInput.trim()}
                        />
                    </Row>

                    <ErrorBodySubtitle>{error}</ErrorBodySubtitle>
                    <Spacing factor={1} />
                </Column>
                <Column style={{ alignItems: 'flex-start' }}>
                    <BodyLarge>Summary Recipients</BodyLarge>
                    <Spacing factor={1 / 2} />

                    <ScrollArea style={{ height: '20vh' }} sidePadding={0}>
                        <ItemList
                            style={{ borderBottom: '1px solid lightgray' }}
                            values={recipients.map((email) => ({
                                code: email,
                                label: (
                                    <BodyRegular>
                                        {`${email}${allUserEmails.includes(email) ? ' (You)' : ''}`}
                                        {email?.toLowerCase() === hostUserEmail ? (
                                            <BodyRegular
                                                style={{ color: '#35A289', display: 'inline', marginLeft: '8px' }}
                                            >
                                                host
                                            </BodyRegular>
                                        ) : null}
                                    </BodyRegular>
                                ),
                                postContent:
                                    !allUserEmails.includes(email) && email !== hostUserEmail ? (
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            {...withAccessibleSubmitProps(() => handleRemoveEmail(email))}
                                        >
                                            <HighlightOffIcon htmlColor="gray" />
                                        </span>
                                    ) : null,
                            }))}
                        />
                    </ScrollArea>

                    <Spacing factor={1} />

                    <BodyRegular style={{ color: '#707070' }}>
                        People on this list will receive an email summary and have access to these notes in their
                        dashboard.
                    </BodyRegular>

                    <Spacing factor={1} />
                </Column>
                <Column centered>
                    <PrimaryButton
                        title="Save"
                        onClick={() => onSave(recipients, hasListChanged)}
                        disabled={isLoading}
                        isLoading={isLoading}
                    />
                    <Spacing factor={1 / 3} />
                    <SecondaryButton title="Cancel" onClick={onCancel} disabled={isLoading} />
                </Column>
            </Content>
        </Modal>
    );
}

export function GlobalManageDraftSummaryRecipientsModal(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [openModal, setOpenModal] = useGlobalModal();
    const { modal, metadata } = useGlobalModalState();
    const [isLoading, setIsLoading] = useState(false);
    const track = useExperienceTracking();

    if (
        modal !== GlobalModal.ManageDraftSummaryRecipients ||
        metadata?.metadataType !== GlobalModalMetadataType.ManageDraftSummaryRecipients
    ) {
        return <></>;
    }

    return (
        <ManageDraftSummaryRecipientsModal
            isLoading={isLoading}
            linkedUserEmails={user.linkedUserEmails}
            userEmail={user.email}
            hostUserEmail={metadata.hostUserEmail}
            userRootDomain={user.rootDomain}
            defaultList={metadata.defaultList}
            attendees={metadata.attendees}
            isOpen={openModal === GlobalModal.ManageDraftSummaryRecipients}
            onCancel={() => {
                track(ClientEventType.AIDashboardClick, { ClickedOn: 'EditDraftSummaryRecipientsModal - Cancel' });
                setOpenModal(null);
            }}
            onSave={async (emails, hasChanged) => {
                if (hasChanged) {
                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'EditDraftSummaryRecipientsModal - Save' });
                    setIsLoading(true);
                    await patchDraftRecipients(
                        metadata.botId,
                        emails
                            .filter((email) => email !== user.email)
                            .map((email) => ({ email, kind: AiHistoryUserAccessKind.Shared }))
                    );
                    setIsLoading(false);
                    metadata.onSaved(emails);
                }
                setOpenModal(null);
            }}
            isEnabledForInternalOnlyEmailDistribution={user.isEnabledForInternalOnlyEmailDistribution}
        />
    );
}
