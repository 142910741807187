import { ClientPlatform, FeatureToggle, Participant, StandUpStatus, ZoomParticipantRole } from '@spinach-shared/types';

import { BaseParticipantProps, ParticipantPropsFactory, SpinachParticipantProps } from '../..';

export class BaseParticipantListProps {
    participants: BaseParticipantProps[];

    constructor(participants: Participant[]) {
        this.participants = participants.map((p) => ParticipantPropsFactory.createParticipant(p));
    }

    get totalCount(): number {
        return this.participants.length;
    }

    get hasMeetingOwner(): boolean {
        return this.participants.some((p) => p.role === ZoomParticipantRole.MeetingOwner);
    }

    get lastParticipantIndex(): number {
        return this.participants.length - 1;
    }

    get online(): BaseParticipantProps[] {
        return this.participants.filter((p) => p.isPresent);
    }

    get readyCount(): number {
        return this.participants.filter((p) => p.standUpStatus === StandUpStatus.Ready).length;
    }

    get withApp(): SpinachParticipantProps[] {
        return this.participants.filter((p): p is SpinachParticipantProps => !!p.spinachUserId);
    }

    get withWebApp(): SpinachParticipantProps[] {
        return this.participants.filter((p): p is SpinachParticipantProps => p.platform === ClientPlatform.Web);
    }

    get onlineWithApp(): SpinachParticipantProps[] {
        return this.online.filter((p): p is SpinachParticipantProps => !!p.connectionIds?.length);
    }

    isFeatureEnabledForAny(featureToggle: FeatureToggle): boolean {
        return this.participants.some((p) => !!p.featureFlagMap[featureToggle]);
    }

    getParticipantBySpinachUserId(spinachUserId: string): SpinachParticipantProps | undefined {
        return this.withApp.find((p) => p.spinachUserId === spinachUserId);
    }

    getParticipantName(userId: string): string {
        const participant = this.participants.find((p) => p.id === userId);

        if (participant) {
            return participant.displayName;
        } else {
            return '';
        }
    }
}
