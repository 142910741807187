import { SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../';

export const validateSlackDomain = async (domain: string) => {
    try {
        await getSpinachAPI(
            `${SpinachAPIPath.ValidateSlackDomain.replace(':slackTeamDomain', encodeURIComponent(domain))}`,
            {
                throwOnError: true,
            }
        );
        return true;
    } catch (e) {
        return false;
    }
};
