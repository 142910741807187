import { Box } from '@material-ui/core';

import { DiagonalBox } from './DiagonalBox';

export function AgentNotificationBanner({
    text,
    visible,
    highlightColor,
}: {
    text: string;
    visible: boolean;
    highlightColor: string;
}): JSX.Element {
    return (
        <Box
            style={{
                left: 0,
                right: 0,
                textAlign: 'center',
                width: '100%',
                position: 'fixed',
                zIndex: 55,
                top: visible ? '0px' : '-400px',
                marginTop: '120px',
                transition: 'top 0.5s ease-in-out',
                whiteSpace: 'nowrap',
            }}
        >
            <Box
                style={{
                    textAlign: 'center',
                    fontSize: '60px',
                    marginBottom: '50px',
                    transform: 'scale(1.1)',
                    color: 'white',
                    marginTop: '30px',
                    position: 'relative', // Added to position the boxes relatively
                    lineHeight: 1.4,
                }}
            >
                <DiagonalBox color={highlightColor} text={text} />
            </Box>
        </Box>
    );
}
