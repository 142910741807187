import { AtlassianGetAvailableResourceItem, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../getSpinachAPI';

export const fetchJiraSites = async () => {
    const response = await getSpinachAPI<AtlassianGetAvailableResourceItem[]>(
        `${SpinachAPIPath.JiraAccessibleResources}`
    );
    return response ?? [];
};
