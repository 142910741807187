import axios from 'axios';

import { ClientVideoAgentSessionJSON, PatchVideoAgentSessionRequest } from '@spinach-shared/types';
import { getDefaultRequestHeaders, isLocalStage } from '@spinach-shared/utils';

import { getAgentSearchParams } from '../../..';
import { mockBotId } from '../../constants';
import { getHeaderOpts } from '../../utils/api';

// Declare a variable to store the control page window reference
let controlPageWindow: Window | null = null;

// Function to set the control page window reference
export function setControlPageWindow(window: Window | null) {
    controlPageWindow = window;
}

export async function patchVideoAgentSession(
    updatedSession: ClientVideoAgentSessionJSON
): Promise<ClientVideoAgentSessionJSON | undefined> {
    const { scribeServer, token } = getAgentSearchParams();

    const payload: PatchVideoAgentSessionRequest = {
        updatedSession,
    };

    if (updatedSession.botId === mockBotId && isLocalStage()) {
        // Send message to the control page window if it exists
        if (controlPageWindow && !controlPageWindow.closed) {
            console.log('Sending message to control page window');
            controlPageWindow.postMessage({ type: 'UPDATE_VIDEO_AGENT_SESSION', updatedSession }, '*');
        } else {
            console.warn('Control page window is not available or has been closed');
        }
        return updatedSession;
    }

    const response = await axios.patch(`${scribeServer}/video-agent/session`, payload, {
        headers: {
            ...getDefaultRequestHeaders(getHeaderOpts()),
            'Content-Type': 'application/json',
            'Cache-Control': 'no-store',
            authorization: token,
        },
    });

    return response.data;
}
