import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { AtlassianGetAvailableResourceItem, ClientEventType, SpinachIntegration } from '@spinach-shared/types';

import { fetchConfluenceSites } from '../apis/fetchConfluenceSites';
import { fetchJiraSites } from '../apis/jira/fetchJiraSites';
import { GlobalAtlassianSitesState, globalAtlassianSites } from '../atoms';
import { SetValue } from '../types';
import { useExperienceTracking } from './useExperienceTracking';
import { useGlobalAuthedUser } from './useGlobalUser';

export const useGlobalAtlassianSitesState = (): [
    globalAtlassianSitesState: GlobalAtlassianSitesState | null,
    setGlobalTicketProjectsState: SetValue<GlobalAtlassianSitesState | null>
] => {
    return useRecoilState(globalAtlassianSites);
};

export const useAtlassianSites = (): {
    atlassianSites: GlobalAtlassianSitesState | null;
    isLoading: boolean;
} => {
    const [globalAtlassianSites, setGlobalAtlassianSites] = useGlobalAtlassianSitesState();
    const [isLoading, setIsLoading] = useState(false);
    const track = useExperienceTracking();

    const [user] = useGlobalAuthedUser();

    useEffect(() => {
        const getJiraSites = async () => {
            const jiraSites = await fetchJiraSites();
            track(ClientEventType.AIDashboardActivity, {
                Action: 'Jira sites fetched',
                From: 'Page Load',
                NumberOfJiraSites: jiraSites.length,
            });
            return jiraSites;
        };

        const getConfluenceSites = async () => {
            const confluenceSites = await fetchConfluenceSites();
            track(ClientEventType.AIDashboardActivity, {
                Action: 'Confluence sites fetched',
                From: 'Page Load',
                NumberOfConfluenceSites: confluenceSites.length,
            });

            return confluenceSites;
        };

        const getAtlassianSites = async () => {
            let jiraSites: AtlassianGetAvailableResourceItem[] | undefined;
            let confluenceSites: AtlassianGetAvailableResourceItem[] | undefined;
            setIsLoading(true);

            if (user.isAuthedForJira) {
                jiraSites = await getJiraSites();
            }

            if (user.isAuthedForConfluence) {
                confluenceSites = await getConfluenceSites();
            }

            setGlobalAtlassianSites({
                [SpinachIntegration.Jira]: jiraSites,
                [SpinachIntegration.Confluence]: confluenceSites,
            });
            setIsLoading(false);
        };

        getAtlassianSites();
    }, [user.isAuthedForJira, user.isAuthedForConfluence, track, setGlobalAtlassianSites]);

    return { atlassianSites: globalAtlassianSites, isLoading };
};
