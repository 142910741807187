import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore, Warning } from '@material-ui/icons';

import {
    ClientEventType,
    KnowledgeBaseIntegration,
    SpinachIntegration,
    SpinachIntegrations,
} from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import { useExperienceTracking, useGlobalAuthedUser } from '../../../../hooks';
import { BodyBigOnboard, BodyRegularOnboard, BodySmallOnboard, Hyperlink, lightTheme } from '../../../../styles';
import { URLUtil, withContentMasking } from '../../../../utils';
import { BootstrapTooltip, Row, Spacing } from '../../../common';
import { IntegrationSettingsModalKind } from '../types';
import { MeetingConfigurationDetails, useOpenInMeetingApp } from './CombinedMeetingDetails';

export function KnowledgeBaseIntegrationDetails({
    storedSeries,
    knowledgeBase,
}: {
    storedSeries: StoredSpinachSeries;
    knowledgeBase: KnowledgeBaseIntegration;
}): JSX.Element {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();

    const notionSettings = storedSeries.getIntegrationSettings(SpinachIntegration.Notion);
    const googleDriveSettings = storedSeries.getIntegrationSettings(SpinachIntegration.GoogleDrive);

    const confluenceUserSettings = user.integrationSettings?.confluenceSettings;
    const confluenceUrl = user.integrationSettings?.confluenceSettings?.confluenceAccountUrl;
    const confluenceParentPageId = storedSeries.getSummaryParentPageId(SpinachIntegration.Confluence);
    const confluenceParentPageUrl =
        confluenceUrl && confluenceUserSettings?.defaultSpaceKey
            ? `${confluenceUrl}/wiki/spaces/${confluenceUserSettings.defaultSpaceKey}/pages/${confluenceParentPageId}`
            : '';

    const notionParentPageId = notionSettings?.summaryParentPageId;
    const notionParentPageUrl = notionParentPageId
        ? `https://www.notion.so/${notionParentPageId.replaceAll('-', '')}`
        : '';

    const googleDriveParentFolderId = googleDriveSettings?.summaryParentPageId;
    const googleDriveParentPageUrl = googleDriveParentFolderId
        ? `https://drive.google.com/drive/u/0/folders/${googleDriveParentFolderId}`
        : '';

    const knowledgeBaseUrlMap: Record<
        KnowledgeBaseIntegration,
        { url: string; isAuthed: boolean; title: string; parentPageId?: string | number }
    > = {
        [SpinachIntegration.Confluence]: {
            url: confluenceParentPageUrl,
            parentPageId: confluenceParentPageId,
            isAuthed: user.isAuthedForConfluence,
            title: SpinachIntegrations[SpinachIntegration.Confluence].displayName,
        },
        [SpinachIntegration.Notion]: {
            url: notionParentPageUrl,
            parentPageId: notionParentPageId,
            isAuthed: user.isAuthedForNotion,
            title: SpinachIntegrations[SpinachIntegration.Notion].displayName,
        },
        [SpinachIntegration.GoogleDrive]: {
            url: googleDriveParentPageUrl,
            parentPageId: googleDriveParentFolderId,
            isAuthed: user.isAuthedForGoogleDrive,
            title: SpinachIntegrations[SpinachIntegration.GoogleDrive].displayName,
        },
    };

    const { url, parentPageId, title, isAuthed } = knowledgeBaseUrlMap[knowledgeBase];

    return isAuthed || parentPageId ? (
        <>
            <Spacing factor={1 / 3} />
            <Row style={{ alignItems: 'center', paddingBottom: '5px' }}>
                <BodyRegularOnboard>
                    <b>{title}:</b>
                </BodyRegularOnboard>
            </Row>
            <Row>
                <BodySmallOnboard style={{ marginLeft: '10px' }}>
                    <span>
                        Summaries will be saved to{' '}
                        {url && parentPageId ? (
                            <Hyperlink
                                style={{ fontWeight: 'bold' }}
                                onClick={() => {
                                    track(ClientEventType.KnowledgeBaseParentPageUrlClick, {
                                        parentPageId,
                                        knowledgeBase,
                                    });
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Knowledge Base URL Click',
                                        parentPageId,
                                        knowledgeBase,
                                    });
                                    URLUtil.openURL(url);
                                }}
                            >
                                this page
                            </Hyperlink>
                        ) : (
                            'a page prefixed with your meeting name'
                        )}
                        .
                    </span>
                </BodySmallOnboard>
            </Row>
        </>
    ) : (
        <></>
    );
}

export function MeetingDetails({
    isOpen,
    setIsOpen,
    storedSeries,
}: {
    isOpen: boolean;
    storedSeries: StoredSpinachSeries;
    setIsOpen: () => void;
    setStoredSeriesList: (value: StoredSpinachSeries[]) => void;
    storedSeriesList: StoredSpinachSeries[];
    setModalKind: (modalKind: IntegrationSettingsModalKind | null) => void;
}): JSX.Element {
    const launchInMeeting = useOpenInMeetingApp();

    return (
        <Row
            style={{
                marginBottom: '20px',
                marginLeft: '10px',
            }}
        >
            <Accordion expanded={isOpen} onChange={setIsOpen} elevation={4} style={{ width: '98%' }}>
                <AccordionSummary>
                    <Row style={{ alignItems: 'center', padding: '5px' }}>
                        <ExpandMore
                            style={{ transition: '100ms', transform: !isOpen ? 'rotate(270deg)' : 'rotate(360deg)' }}
                        />
                        <BodyBigOnboard style={{ marginRight: '15px', marginLeft: '15px' }} {...withContentMasking()}>
                            <b {...withContentMasking()}>{storedSeries.scribeMetadata?.seriesTitle}</b>
                        </BodyBigOnboard>
                        <span style={{ display: 'flex', flexGrow: 1 }} />
                        {storedSeries.isBotInvitedWithoutLink ? (
                            <BootstrapTooltip title="Add a video link to this calendar event so Spinach can join.">
                                <Warning htmlColor={lightTheme.primary.orangeLight} style={{ zIndex: 100 }} />
                            </BootstrapTooltip>
                        ) : null}
                    </Row>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '20px' }}>
                    <MeetingConfigurationDetails
                        storedSeries={storedSeries}
                        launchInMeeting={() => launchInMeeting(storedSeries, 'No calander series settings')}
                    />
                </AccordionDetails>
            </Accordion>
        </Row>
    );
}
