import { useState } from 'react';

import { ClientEventType, FeatureDiscoveryKey } from '@spinach-shared/types';
import { getUniques } from '@spinach-shared/utils';

import { patchUser } from '../../../apis';
import { ReactComponent as ClickButton } from '../../../assets/feature-hint-click.svg';
import GiftIconSrc from '../../../assets/gift.png';
import { GlobalModal } from '../../../atoms';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useLandingAnalytic,
    useReferralCallout,
} from '../../../hooks';
import { FeatureCallout, FeatureCalloutClickProps, UseCustomCalloutResult } from './FeatureCallout';

export function useSpinachReferralCallout(): UseCustomCalloutResult {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const [, setGlobalModal] = useGlobalModal();
    const isCalloutEnabledForUser = useReferralCallout();
    const hasNotInteractedWithCallout = !user.dismissedHints.includes(FeatureDiscoveryKey.SpinachReferral);

    const shouldShowThisHint = isCalloutEnabledForUser && hasNotInteractedWithCallout;

    const [isVisible, setIsVisible] = useState(shouldShowThisHint);

    async function onEngageClick() {
        setIsVisible(false);
        setGlobalModal(GlobalModal.SpinachReferral);
        track(ClientEventType.SpinachReferralCalloutEngageClick);
        await patchUser({
            metadata: {
                dismissedHints: getUniques([...user.dismissedHints, FeatureDiscoveryKey.SpinachReferral]),
            },
        });
    }

    async function onCloseClick() {
        setIsVisible(false);
        track(ClientEventType.SpinachReferralCalloutCloseClick);
        await patchUser({
            metadata: {
                dismissedHints: getUniques([...user.dismissedHints, FeatureDiscoveryKey.SpinachReferral]),
            },
        });
    }

    return {
        onEngageClick,
        onCloseClick,
        isVisible,
        feature: FeatureDiscoveryKey.SpinachReferral,
    };
}

export function ReferralFeatureCallout({ onCloseClick, onEngageClick }: FeatureCalloutClickProps): JSX.Element {
    useLandingAnalytic(ClientEventType.SpinachReferralCalloutDisplayed);

    return (
        <FeatureCallout
            icon={<img src={GiftIconSrc} style={{ height: '32px', width: '32px' }} />}
            title={'Enjoying Spinach?'}
            subtitle={'Tell a friend and share the love!'}
            ctaIcon={<ClickButton />}
            onEngageClick={onEngageClick}
            onCloseClick={onCloseClick}
        />
    );
}
