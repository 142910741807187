import { useRecoilState } from 'recoil';

import { AttachmentBarOptions } from '@spinach-shared/types';

import { GlobalAttachmentState, atomAttachmentState } from '../atoms/atomAttachments';

export type GlobalAttachmentSetter = (attachment: AttachmentBarOptions | null, itemId: string) => void;

export type GlobalAttachmentStateSetter = (attachmentState: GlobalAttachmentState) => void;

export type GlobalAttachment = GlobalAttachmentState & {
    closeAttachments: () => void;
    setAttachment: (attachment: AttachmentBarOptions | null, itemId: string | null) => void;
    isAttachmentOpen: (attachment: AttachmentBarOptions) => boolean;
    isReactionsOpen: () => boolean;
    isJiraOpen: () => boolean;
    isAsanaOpen: () => boolean;
    isIssueResolutionOpen: () => boolean;
};

/**
 *
 * @returns a ref to the current open modal or null if none is open, and a setter to this state
 */
export function useGlobalAttachment(): GlobalAttachment {
    const [attachmentState, setAttachmentState] = useRecoilState(atomAttachmentState);

    function closeAttachments() {
        setAttachmentState({
            attachment: null,
            itemId: null,
        });
    }

    function setAttachment(attachment: AttachmentBarOptions | null, itemId: string | null) {
        setAttachmentState({
            attachment,
            itemId,
        });
    }

    function isAttachmentOpen(attachment: AttachmentBarOptions) {
        return attachmentState.attachment === attachment;
    }

    function isReactionsOpen() {
        return attachmentState.attachment === AttachmentBarOptions.Reaction;
    }

    function isJiraOpen() {
        return attachmentState.attachment === AttachmentBarOptions.Jira;
    }

    function isAsanaOpen() {
        return attachmentState.attachment === AttachmentBarOptions.Asana;
    }

    function isIssueResolutionOpen() {
        return attachmentState.attachment === AttachmentBarOptions.IssueResolution;
    }

    return {
        closeAttachments,
        setAttachment,
        isAttachmentOpen,
        isReactionsOpen,
        isJiraOpen,
        isAsanaOpen,
        isIssueResolutionOpen,
        ...attachmentState,
    };
}

/**
 *
 * @returns all state related to global modals. Ideally, this gets baked into useGlobalModal in a separate refactor PR
 */
export function useGlobalAttachmentState(): [GlobalAttachmentState, GlobalAttachmentStateSetter] {
    const [attachmentState, setAttachmentState] = useRecoilState(atomAttachmentState);

    return [attachmentState, setAttachmentState];
}
