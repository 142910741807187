import { useLocation } from 'react-router-dom';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';

import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalRouting,
    usePaymentUrl,
    useStripeUpgradeInAppEnablement,
} from '../../../hooks';
import { URLUtil } from '../../../utils';
import { ClientPath } from '../../common';

export function useNavigateToUpgrade() {
    const paymentUrl = usePaymentUrl();
    const isStripeUpgradeInAppEnabled = useStripeUpgradeInAppEnablement();
    const track = useExperienceTracking();
    const { routeToAIDashboard } = useGlobalRouting();
    const { setActiveSection } = useGlobalAiDashboard();
    const location = useLocation();

    return (clickedOn: string) => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: clickedOn,
            IsStripeUpgradeInAppEnabled: isStripeUpgradeInAppEnabled,
        });

        if (isStripeUpgradeInAppEnabled) {
            if (location.pathname === ClientPath.AIHome) {
                setActiveSection(DashboardSection.Account);
            } else {
                routeToAIDashboard(
                    { replace: true },
                    new URLSearchParams({
                        [WebUrlQuery.Section]: DashboardSection.Account,
                    })
                );
            }
        } else {
            URLUtil.openURL(paymentUrl, {
                redirectThroughBackendWhenOnZoom: true,
            });
        }
    };
}
