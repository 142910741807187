import { useCallback } from 'react';

import { pauseCommands, resumeCommands } from '@spinach-shared/constants';
import { getCommandContents } from '@spinach-shared/models';

import { patchVideoAgentSession } from '../../../..';
import { useGlobalVideoAgent } from '../useGlobalVideoAgent';

export function isToggleRecordingCommand(message: string): string {
    const validCommands = [...pauseCommands, ...resumeCommands];

    const { commandMatched } = getCommandContents(message, validCommands);

    return commandMatched;
}

export function useToggleRecording() {
    const { session, setSession } = useGlobalVideoAgent();

    return useCallback(
        async (matchedCommand: string) => {
            const shouldResume = resumeCommands.includes(matchedCommand) && session.isPaused;
            const shouldPause = pauseCommands.includes(matchedCommand) && !session.isPaused;
            const shouldToggle = shouldResume || shouldPause;

            if (shouldToggle) {
                const updatedSession = session.withToggledPaused();
                setSession(updatedSession);
                await patchVideoAgentSession(updatedSession.toJSON());
            }
        },
        [session, setSession]
    );
}
