export function getCookieValue(cookieName: string): string | null {
    // Split the document.cookie string into individual cookies
    const cookies = document.cookie.split(';');

    // Loop through each cookie to find the one with the specified name
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();

        // Check if the cookie starts with the specified name followed by '='
        if (cookie.startsWith(`${cookieName}=`)) {
            // Extract and return the cookie value
            return cookie.substring(cookieName.length + 1);
        }
    }

    // Return null if the cookie with the specified name is not found
    return null;
}

export function addCookie(name: string, value: string, maxAgeSeconds: number = 31536000, path: string = '/') {
    // Set the cookie with the specified name, value, and expiration date
    document.cookie = `${name}=${value}; max-age=${maxAgeSeconds}; path=${path}`;
}
