import { IconButton, InputAdornment, Modal, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useState } from 'react';

import { ClientEventType } from '@spinach-shared/types';

import {
    BodyRegularOnboard,
    BootstrapTooltip,
    GlobalModal,
    PrimaryButton,
    ResponsiveModalTitle,
    Row,
    Spacing,
    SpinachModalContent,
    copyTextToClipboard,
    useClickTracking,
    useGlobalAiDashboard,
    useGlobalModal,
} from '../../../..';
import { postCreateZapierApiKey } from '../../../apis/zapier/postCreateZapierApiKey';
import { useGlobalZapierApiKey } from '../../../hooks/zapier/useGlobalZapierApiKey';

const ZapierAPIKeyModalContent = ({ closeModal }: { closeModal: () => void }) => {
    const [zapierAPIKey, setGlobalZapierAPIKey] = useGlobalZapierApiKey();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { setToastText } = useGlobalAiDashboard();
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Your Zapier API Token</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 3} />
            <BodyRegularOnboard>Do not share this with anyone</BodyRegularOnboard>
            <Spacing factor={1 / 3} />
            {zapierAPIKey ? (
                <OutlinedInput
                    fullWidth
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={zapierAPIKey}
                    disabled={true}
                    multiline={false}
                    endAdornment={
                        <>
                            <InputAdornment position="end">
                                <BootstrapTooltip title="Reveal API Key">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </BootstrapTooltip>
                            </InputAdornment>
                            <InputAdornment position="end">
                                <BootstrapTooltip title="Copy" placement="top">
                                    <IconButton
                                        aria-label="copy api key"
                                        onClick={() => {
                                            copyTextToClipboard(zapierAPIKey);
                                            setToastText('Copied!');
                                        }}
                                        edge="end"
                                    >
                                        <FileCopyOutlinedIcon />
                                    </IconButton>
                                </BootstrapTooltip>
                            </InputAdornment>
                        </>
                    }
                    label="Password"
                />
            ) : (
                <PrimaryButton
                    title="Generate"
                    isLoading={isLoading}
                    onClick={async () => {
                        try {
                            setIsLoading(true);
                            const response = await postCreateZapierApiKey();
                            if (response?.apiKey) {
                                setGlobalZapierAPIKey(response.apiKey);
                            }
                        } catch (e) {
                        } finally {
                            setIsLoading(false);
                        }
                    }}
                />
            )}
        </>
    );
};

export const ZapierApiKeyModal = () => {
    const [globalModal, setGlobalModal] = useGlobalModal();

    const clickTracking = useClickTracking();

    const closeModal = () => {
        clickTracking(ClientEventType.AIDashboardClick, 'Close Zapier API Key Modal');
        setGlobalModal(null);
    };

    if (globalModal !== GlobalModal.Zapier) {
        return <></>;
    }
    return (
        <Modal open={globalModal === GlobalModal.Zapier} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden', minHeight: '300px' }}>
                <ZapierAPIKeyModalContent closeModal={closeModal} />
            </SpinachModalContent>
        </Modal>
    );
};
