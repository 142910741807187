import { useCallback } from 'react';

import { getCommandContents } from '@spinach-shared/models';

import { SendFunction } from '../../../types/agent';

export function getDebugCommand(message: string): string {
    const supportedCommandTriggers = ['/debug'];

    const { remainingCommand } = getCommandContents(message, supportedCommandTriggers);

    return remainingCommand;
}

export function useDebugCommand(send: SendFunction) {
    return useCallback(
        async (type: string) => {
            switch (type) {
                case 'th':
                case 'trigger history':
                    send('WHY_DID_IT_TRIGGER');
                    break;
                case 't':
                case 'transcript':
                    send('WHY_DID_IT_NOT_TRIGGER');
                    break;
                default:
                    break;
            }
        },
        [send]
    );
}
