import { IClientUser, SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '..';

export async function postAnonymousUser(params: URLSearchParams): Promise<IClientUser | null> {
    const request: Record<string, string> = Object.fromEntries(params);

    try {
        const response = await postSpinachAPI<{ user: IClientUser }>(SpinachAPIPath.User, request);
        if (!response) {
            return null;
        }
        return response.user;
    } catch {
        return null;
    }
}
