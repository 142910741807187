import { useMemo, useState } from 'react';
import { Socket } from 'socket.io-client';
import styled from 'styled-components';

import { parkingLotUpdateSectionTypeProps } from '@spinach-shared/constants';
import { BaseAgendaProps } from '@spinach-shared/models';
import { YTBAgendaItemProps } from '@spinach-shared/models';
import { ClientSocketEvent, SpinachUpdateType, TypedUpdate, YTBUpdateUpdatedRequest } from '@spinach-shared/types';
import { getTicketSource } from '@spinach-shared/utils';

import { BodySubtitle, LiveUpdateViewProps, TypedUpdates, useCombinedUpdates } from '../..';
import { useGlobalAuthedUser, useGlobalLiveSeries } from '../../hooks';
import { BodyLarge } from '../../styles';
import { createWebsocketPayload } from '../../utils/analytics';
import { withContentMasking } from '../../utils/withContentMasking';
import { ReadOnlyUpdateRow } from './SummaryView';

const TeamTopicRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
`;

const Container = styled.div`
    flex-shrink: 0;
    width: 100%;
`;

export type TeamTopicsForParticipantProps = {
    seriesSlug: string;
    spinachUserId: string;
    socket: Socket;
};

function TeamTopicsForParticipant({
    userItem,
    agenda,
    teamTopicsProps,
    excludeParticipant,
}: {
    userItem: YTBAgendaItemProps;
    agenda: BaseAgendaProps;
    teamTopicsProps: TeamTopicsForParticipantProps;
    excludeParticipant?: string;
}): JSX.Element | null {
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useGlobalLiveSeries();
    const { seriesSlug, spinachUserId, socket } = teamTopicsProps;

    const updatesByType = useMemo(() => userItem.standUpUpdate.updatesByReservedTypes, [agenda]);
    const savedTeamTopics = updatesByType[SpinachUpdateType.ParkingLot];
    const [statefulTeamTopics, setStatefulTeamTopics] = useState<TypedUpdate[]>(savedTeamTopics);
    const [focusedInputIndex, setFocusedInputIndex] = useState<number | null>(null);

    useCombinedUpdates(savedTeamTopics, statefulTeamTopics, setStatefulTeamTopics, userItem.standUpUpdate);

    const userIdentity = user.toUserIdentity();

    const createUpdateEmitter = (update: TypedUpdate) => (text: string) => {
        const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
            ...userIdentity,
            seriesSlug,
            spinachUserId,
            meetingId: liveSeries.currentMeeting.id,
            participantId: userItem.id,
            update: {
                ...update,
                text,
            },
        });
        socket.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
    };

    const createFullUpdateEmitter = (update: TypedUpdate) => (updated: TypedUpdate) => {
        const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
            ...userIdentity,
            seriesSlug,
            spinachUserId,
            participantId: userItem.id,
            meetingId: liveSeries.currentMeeting.id,
            update: {
                ...update,
                ...updated,
            },
        });
        socket.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
    };

    const createReorderEmitter = (update: TypedUpdate) => (moveToIndex: number) => {
        const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
            ...userIdentity,
            seriesSlug,
            spinachUserId,
            participantId: userItem.id,
            meetingId: liveSeries.currentMeeting.id,
            update,
            moveToIndex,
        });
        socket.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
    };

    const liveUpdateViewProps: LiveUpdateViewProps = {
        standUpUpdate: userItem.standUpUpdate,
        createUpdateEmitter,
        createFullUpdateEmitter,
        createReorderEmitter,
    };

    return excludeParticipant !== userItem.id && (savedTeamTopics.length > 0 || spinachUserId === userItem.id) ? (
        <TeamTopicRow>
            <BodyLarge>{userItem.title}</BodyLarge>
            <TypedUpdates
                typedUpdates={statefulTeamTopics}
                setTypedUpdates={setStatefulTeamTopics}
                typeProps={parkingLotUpdateSectionTypeProps}
                updateViewProps={liveUpdateViewProps}
                focusedInputIndex={focusedInputIndex}
                setFocusedInputIndex={setFocusedInputIndex}
                userId={userItem.id}
                hideAddLine={userItem.id !== spinachUserId}
            />
        </TeamTopicRow>
    ) : null;
}

export function TeamTopicsByParticipant({
    agenda,
    teamTopicsProps,
    excludeParticipant,
}: {
    agenda: BaseAgendaProps;
    teamTopicsProps: TeamTopicsForParticipantProps;
    excludeParticipant?: string;
}): JSX.Element {
    return (
        <Container>
            {agenda.YTBItems.map((agendaItem) => {
                if (agendaItem.isParticipantAgendaItem !== false) {
                    return (
                        <TeamTopicsForParticipant
                            key={agendaItem.id}
                            userItem={agendaItem}
                            agenda={agenda}
                            teamTopicsProps={teamTopicsProps}
                            excludeParticipant={excludeParticipant}
                        />
                    );
                }
            })}
        </Container>
    );
}

export function ParkingLotItemsByParticipant({
    updates,
    isTeamTopics2Enabled,
}: {
    updates: YTBAgendaItemProps[];
    isTeamTopics2Enabled?: boolean;
}): JSX.Element {
    return (
        <Container>
            {updates.map((item) => (
                <TeamTopicRow key={item.id}>
                    <BodySubtitle {...withContentMasking()}>{item.title}</BodySubtitle>
                    {item.standUpUpdate
                        .getUpdatesForType(
                            isTeamTopics2Enabled ? SpinachUpdateType.Topic : SpinachUpdateType.ParkingLot
                        )
                        .map((topic) => (
                            <ReadOnlyUpdateRow
                                key={topic.id}
                                text={topic.text}
                                ticket={topic.jiraData || topic.asanaData || topic.ticketData?.ticket}
                                ticketSource={getTicketSource(topic)}
                            />
                        ))}
                </TeamTopicRow>
            ))}
        </Container>
    );
}
