import { useEffect, useState } from 'react';

import {
    ClientEventType,
    FeatureDiscoveryKey,
    IntegrationCode,
    SpinachAPIPath,
    WebUrlQuery,
} from '@spinach-shared/types';
import { getUniques, isDemoSeries } from '@spinach-shared/utils';

import { patchUser } from '../../../apis';
import { ReactComponent as AsanaButton } from '../../../assets/Asana-dots-btn.svg';
import { ReactComponent as ClickButton } from '../../../assets/feature-hint-click.svg';
import {
    useAsanaEnablement,
    useAsanaIntegrationCalloutEnablement,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalStoredSeries,
    useLandingAnalytic,
} from '../../../hooks';
import { URLUtil } from '../../../utils';
import { FeatureCallout, FeatureCalloutClickProps, UseCustomCalloutResult } from './FeatureCallout';

export function useAsanaIntegrationCallout(): UseCustomCalloutResult {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const [storedSeries] = useGlobalStoredSeries();
    const isEnabledForAsanaIntegration = useAsanaEnablement();
    const isCalloutEnabledForUser = useAsanaIntegrationCalloutEnablement();
    const isAsanaUser = !!user.metadata.integrationsProjectMgmt?.includes(IntegrationCode.Asana);
    const isNotIntegratedWithAsana = !user.isAuthedForAsana;
    const isRealSeriesWithOthersInvited = !isDemoSeries(storedSeries.slug) && storedSeries.userMetadataList.length > 1;
    const hasNotInteractedWithCallout = !user.dismissedHints.includes(FeatureDiscoveryKey.AsanaIntegration);

    const shouldShowThisHint =
        isEnabledForAsanaIntegration &&
        isCalloutEnabledForUser &&
        isAsanaUser &&
        isNotIntegratedWithAsana &&
        isRealSeriesWithOthersInvited &&
        hasNotInteractedWithCallout;

    const [isVisible, setIsVisible] = useState(shouldShowThisHint);

    // if the user invites someone after mounting, we want to reveal the
    // component then, while still relying on the stateful isVisible for
    // ultimate render, rather than something like isVisible || shouldShowThisHint
    useEffect(() => {
        if (shouldShowThisHint) {
            setIsVisible(true);
        }
    }, [shouldShowThisHint]);

    async function onEngageClick() {
        setIsVisible(false);
        URLUtil.openURL(
            `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.AsanaAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
        );
        track(ClientEventType.OnIntegrateAsanaCalloutClick);
        await patchUser({
            metadata: {
                dismissedHints: getUniques([...user.dismissedHints, FeatureDiscoveryKey.AsanaIntegration]),
            },
        });
    }

    async function onCloseClick() {
        setIsVisible(false);
        track(ClientEventType.OnIntegrateAsanaCalloutCloseClick);
        await patchUser({
            metadata: {
                dismissedHints: getUniques([...user.dismissedHints, FeatureDiscoveryKey.AsanaIntegration]),
            },
        });
    }

    return {
        onEngageClick,
        onCloseClick,
        isVisible,
        feature: FeatureDiscoveryKey.AsanaIntegration,
    };
}

export function AsanaIntegrationFeatureCallout({ onCloseClick, onEngageClick }: FeatureCalloutClickProps): JSX.Element {
    useLandingAnalytic(ClientEventType.AsanaIntegrationCalloutDisplayed);

    return (
        <FeatureCallout
            icon={<AsanaButton style={{ height: '32px', width: '32px' }} />}
            title={'Connect to Asana'}
            subtitle={'Add tickets to your check-in.'}
            ctaIcon={<ClickButton />}
            onEngageClick={onEngageClick}
            onCloseClick={onCloseClick}
        />
    );
}
