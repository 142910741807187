import styled from 'styled-components';

import {
    CURRENT_ITEMS_CUSTOM_LIST,
    FINISHED_ITEMS_CUSTOM_LIST_ID,
    PRESENTING_WORKING_ITEMS_CUSTOM_LIST_ID,
    icebreakerUpdateSectionTypeProps,
} from '@spinach-shared/constants';
import { SentimentPickerLocation } from '@spinach-shared/types';

import { ElementId } from '../../constants';
import { useGlobalLiveSeries } from '../../hooks';
import { useGlobalMeetingSocket } from '../../hooks/useGlobalSocket';
import { Spacing } from '../common';
import { CheckInFeatureCalloutContainer } from '../common/feature-discovery';
import { CustomLiveUpdateSection, CustomUpdates } from './CustomUpdates';
import { LiveReservedUpdateSection } from './LiveReservedUpdateSection';
import { MoodSelectionComponent, MoodSelectionComponentHeader } from './MoodSelectionComponent';

const Container = styled.div`
    background-color: ${(props) => props.theme.neutrals.offWhite};
`;

export function IssueBasedCheckIn(): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();
    const [socket] = useGlobalMeetingSocket();
    const { isIcebreakerLockedIn } = liveSeries.currentMeeting;

    return (
        <Container>
            <CheckInFeatureCalloutContainer />

            <Spacing factor={1 / 2} />

            <CustomLiveUpdateSection
                isInNavDrawer={true}
                list={CURRENT_ITEMS_CUSTOM_LIST}
                socket={socket}
                agendaItem={liveSeries.thisParticipantsYTBAgendaItem}
            />

            <CustomUpdates
                isInNavDrawer={true}
                socket={socket}
                agendaItem={liveSeries.thisParticipantsYTBAgendaItem}
                listIdsToHide={[FINISHED_ITEMS_CUSTOM_LIST_ID, PRESENTING_WORKING_ITEMS_CUSTOM_LIST_ID]}
            />

            {isIcebreakerLockedIn ? (
                <LiveReservedUpdateSection
                    agendaItem={liveSeries.thisParticipantsYTBAgendaItem}
                    socket={socket}
                    typeProps={icebreakerUpdateSectionTypeProps}
                    id={ElementId.IcebreakerSection}
                    isInNavDrawer={true}
                />
            ) : null}

            <MoodSelectionComponentHeader />
            <MoodSelectionComponent location={SentimentPickerLocation.TeamMood} />
        </Container>
    );
}
