import { Modal, TextField } from '@material-ui/core';
import { useState } from 'react';
import { Socket } from 'socket.io-client';

import { ClientSocketEvent, UserSeriesMetadata } from '@spinach-shared/types';

import { patchSeries } from '../../apis/patchSeries';
import { GlobalModal } from '../../atoms';
import { useGlobalAuthedUser, useGlobalModal, useGlobalNullableStoredSeries } from '../../hooks';
import { useGlobalDashboardSocket } from '../../hooks/useGlobalSocket';
import { withContentMasking } from '../../utils';
import { Spacing } from '../common';
import { useSpinachInputStyles } from '../input';
import { PrimaryButton } from '../stand-up';
import SecondaryButton from '../stand-up/SecondaryButton';
import { CenteredRow, LeftBodyLarge, ModalContent } from './common';
import { Titles } from '@spinach-clients/constants';

export function RenameSeriesModalContainer() {
    const [globalStoredSeries] = useGlobalNullableStoredSeries();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const [socket] = useGlobalDashboardSocket();

    if (!globalStoredSeries) {
        return <></>;
    }

    return (
        <RenameSeriesModal
            isOpen={globalModal === GlobalModal.RenameSeries}
            onClose={() => {
                setGlobalModal(null);
            }}
            series={globalStoredSeries}
            socket={socket}
            key={globalStoredSeries.id}
        />
    );
}

export function RenameSeriesModal({
    isOpen,
    onClose,
    series,
    socket,
}: {
    isOpen: boolean;
    onClose: () => void;
    series: UserSeriesMetadata;
    socket: Socket | null;
}): JSX.Element {
    const [newSeriesName, setNewSeriesName] = useState(series.name);
    const classes = useSpinachInputStyles({ value: newSeriesName });
    const [user] = useGlobalAuthedUser();

    return (
        <Modal open={isOpen} onClose={onClose}>
            <ModalContent>
                <LeftBodyLarge>Rename your Standup</LeftBodyLarge>

                <TextField
                    InputProps={{ classes: { root: classes.base } }}
                    {...withContentMasking(classes.root)}
                    fullWidth
                    placeholder="Better name than Standup"
                    value={newSeriesName}
                    autoFocus
                    onChange={(e) => {
                        setNewSeriesName(e.target.value.substring(0, 50));
                    }}
                />

                <Spacing factor={2} />

                <PrimaryButton
                    title={'Rename Standup'}
                    disabled={!newSeriesName || newSeriesName === series.name}
                    onClick={async () => {
                        await patchSeries(series?.id, {
                            name: newSeriesName,
                        });

                        socket?.emit(ClientSocketEvent.SeriesNameUpdating, {
                            seriesSlug: series?.id,
                            newSeriesName,
                            spinachUserId: user.spinachUserId,
                        });

                        // reload dashboard to pick up new user data with new series data
                        window.location.reload();
                    }}
                />

                <Spacing factor={0.66} />

                <CenteredRow>
                    <SecondaryButton title={Titles.Cancel} onClick={onClose} />
                </CenteredRow>
            </ModalContent>
        </Modal>
    );
}
