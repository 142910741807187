import { AskSpianchRealtimeConfig, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function getAskSpinachRealtimeConfig({
    seriesId,
    botId,
}: {
    seriesId: string;
    botId: string;
}): Promise<AskSpianchRealtimeConfig | null> {
    const response = await getSpinachAPI<AskSpianchRealtimeConfig>(SpinachAPIPath.AskSpinachRealtimeConfig, {
        params: {
            seriesId,
            botId,
        },
    });

    return response ?? null;
}
