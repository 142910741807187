import styled from 'styled-components';

export const AuthorizeIntegrationButton = styled.div<{ active?: boolean }>`
    background-color: ${(props) => props.theme.primary.orangeDark};
    display: inline-flex;
    align-items: center;
    color: white;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-family: Gilroy, sans-serif;
    padding: 10px;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }

    &:active {
        cursor: pointer;
        background-color: #eee;
        color: rgba(0, 0, 0, 0.54);
        opacity: 1;
    }
`;
