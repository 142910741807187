import { Box } from '@material-ui/core';

import { ClientEventType } from '@spinach-shared/types';

import {
    GlobalModal,
    useExperienceTracking,
    useGlobalAdmin,
    useGlobalAuthedUser,
    useGlobalModal,
} from '../../../../../..';
import { Column, Row, Spacing } from '../../../../common';
import SecondaryButton from '../../../../stand-up/SecondaryButton';

export function AdminSelectedUserInfo(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { state } = useGlobalAdmin();
    const [, setGlobalModal] = useGlobalModal();

    const track = useExperienceTracking();
    const { searchedUser } = state;

    if (!searchedUser) {
        return <></>;
    }

    return (
        <Column style={{ marginLeft: '50px', width: 'calc(100% - 50px)' }}>
            <Row>
                <Box style={{ width: '200px' }}>
                    <b>Selected User</b>
                </Box>

                {user.isEnabledForScimAccess ? (
                    <Box style={{ width: '200px' }}>
                        <b>Is Deactivated?</b>
                    </Box>
                ) : null}
            </Row>
            <Row>
                <Box style={{ width: '200px' }}>{searchedUser.email}</Box>
                {user.isEnabledForScimAccess ? (
                    <>
                        <Box style={{ width: '200px' }}>
                            {searchedUser.metadata.isScimActive === true ? 'No' : 'Yes'}
                        </Box>
                        <Box width="200px" justifyContent={'center'} display={'flex'}>
                            <SecondaryButton
                                title="Deactivate"
                                disabled={!searchedUser.metadata.isScimActive}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Deactivate User as Admin',
                                        SelectedUserId: searchedUser?.spinachUserId,
                                    });
                                    setGlobalModal(GlobalModal.ConfirmDeactivateUserAsAdmin);
                                }}
                            />
                        </Box>
                    </>
                ) : null}
            </Row>
            <Spacing factor={1 / 2} />
        </Column>
    );
}
