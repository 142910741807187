import { useEffect } from 'react';

import { SetValue, getInFlightUpdates } from '..';

export function useLiveSyncedFocus(
    typedUpdates: { id: string; text: string }[],
    focusedInputIndex: number | null,
    setFocusedInputIndex: SetValue<number | null>,
    remoteTypedUpdates: { id: string; text: string }[]
) {
    useEffect(() => {
        const unsavedUpdates = getInFlightUpdates(remoteTypedUpdates, typedUpdates);

        if (unsavedUpdates.length > 0 && unsavedUpdates[0].text !== '') {
            const index = typedUpdates.findIndex((u) => u.id === unsavedUpdates[0].id);
            if (focusedInputIndex !== null && index !== -1) {
                setFocusedInputIndex(index);
            }
        }
    }, [remoteTypedUpdates, typedUpdates]);
}
