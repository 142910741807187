import { InfoSharp } from '@material-ui/icons';
import styled from 'styled-components';

import { ClientEventType, FeatureDiscoveryKey } from '@spinach-shared/types';

import { ReactComponent as ClickButton } from '../../../assets/feature-hint-click.svg';
import { useExperienceTracking, useLandingAnalytic, usePayUpCalloutURL } from '../../../hooks';
import { URLUtil } from '../../../utils';
import { FeatureCallout, FeatureCalloutClickProps, UseCustomCalloutResult } from './FeatureCallout';

const Hyperlink = styled.a`
    cursor: pointer;
    text-decoration: underline;
`;

export function usePayUpCallout(): UseCustomCalloutResult {
    const track = useExperienceTracking();
    const payURL = usePayUpCalloutURL();

    const isVisible = payURL.includes('http');

    async function onEngageClick() {
        track(ClientEventType.PayUpCalloutEngageClick);
        if (payURL) {
            URLUtil.openURL(payURL, { redirectThroughBackendWhenOnZoom: true });
        }
    }

    async function onCloseClick() {}

    return {
        onEngageClick,
        onCloseClick,
        isVisible,
        feature: FeatureDiscoveryKey.PayUp,
    };
}

export function PayUpFeatureCallout({ onCloseClick, onEngageClick }: FeatureCalloutClickProps): JSX.Element {
    useLandingAnalytic(ClientEventType.PayUpCalloutDisplayed);

    return (
        <FeatureCallout
            icon={<InfoSharp style={{ height: '32px', width: '32px' }} />}
            title={'Payment info needed'}
            subtitle={
                <>
                    To continue using Spinach please{' '}
                    <Hyperlink onClick={onEngageClick}>set up your payment details</Hyperlink>. For any questions please
                    contact us at <b>help@spinach.io</b>
                </>
            }
            isCloseable={false}
            ctaIcon={<ClickButton />}
            onEngageClick={onEngageClick}
            onCloseClick={onCloseClick}
            containerStyle={{ background: 'rgb(230, 202, 202)' }}
        />
    );
}
