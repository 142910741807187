import { ISOString } from './Time';

export enum ZoomParticipantRole {
    Attendee = 'attendee',
    Host = 'host',
    MeetingOwner = 'meeting-owner',
    Unknown = 'unknown',
}

enum ZoomUserType {
    Basic = 1,
    Licensed = 2,
    OnPrem = 3,
    SSO = 99,
}

enum AccountVerificationStatus {
    NotVerified = 0,
    Verified = 1,
}

enum ZoomAccountStatus {
    Active = 'active',
    Inactive = 'inactive',
    Pending = 'pending',
}

enum ZoomLoginMethod {
    FacebookOAuth = 0,
    GoogleOAuth = 1,
    AppleOAuth = 24,
    MicrosoftOAuth = 27,
    MobileDevice = 97,
    RingCentralOAuth = 98,
    APIUser = 99,
    ZoomWorkEmail = 100,
    SSO = 101,
}

export type ZoomUserContext = {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    type: ZoomUserType;
    role_name: string; // is an enum
    pmi: number;
    use_pmi: boolean;
    personal_meeting_url: string;
    timezone: string;
    verified: AccountVerificationStatus;
    dept: string;
    created_at: ISOString;
    last_login_time: ISOString;
    last_client_version: string;
    pic_url: string;
    host_key: string;
    cms_user_id: string;
    jid: string;
    group_ids: string[];
    im_group_ids: string[];
    account_id: string;
    language: string;
    phone_country: string;
    phone_number: string;
    status: ZoomAccountStatus;
    job_title: string;
    location: string;
    login_types: ZoomLoginMethod[];
    role_id: string;
    account_number: number;
};
