import { Popover, makeStyles } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { Titles } from '@spinach-clients/constants';
import { useState } from 'react';

import { ClientEventType } from '@spinach-shared/types';

import { GlobalModal } from '../../../../../atoms';
import { ElementId } from '../../../../../constants';
import { useExperienceTracking, useGlobalModal } from '../../../../../hooks';
import { lightTheme } from '../../../../../styles';
import { Column } from '../../../../common';
import { CopySummary, SendOptionsProps, SlackSettingsRow, SubscribeEmails } from '../../../../common/SendOptions';
import { OutlinedButton } from '../../../../stand-up/OutlinedButton';

const usePopoverStyles = makeStyles(() => ({
    paper: {
        borderRadius: '10px',
        backgroundColor: lightTheme.neutrals.white,
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 5px 5px 0px 1px rgba(0, 0, 0, 0.15)',
    },
}));

export function StandupAppSendOptions({ parentId, onCopyClick, style }: SendOptionsProps): JSX.Element {
    const popoverStyles = usePopoverStyles();
    const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);
    const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState<HTMLElement | null>(null);
    const [, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();
    const [isHovered, setIsHovered] = useState(false);

    const buttonId = `${ElementId.SendOptions}-${parentId}`;

    function onOptionsButtonClick() {
        track(ClientEventType.SendOptionsButtonClick, { Location: parentId });
        setStatusMenuAnchorEl(document.getElementById(buttonId));
        setIsStatusMenuOpen(true);
    }

    function onCopyOptionClick() {
        onCopyClick();
        setIsStatusMenuOpen(false);
    }

    function onSendEmailsClick() {
        track(ClientEventType.OpenSubscribeSeriesOutputModalClick, { Location: parentId });
        setGlobalModal(GlobalModal.SubscribeSeriesOutput, {
            location: parentId,
        });
        setIsStatusMenuOpen(false);
    }

    return (
        <>
            <OutlinedButton
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => {
                    onOptionsButtonClick();
                }}
                endIcon={
                    isStatusMenuOpen ? (
                        <ArrowDropUp htmlColor={'rgba(0, 0, 0, 0.54)'} />
                    ) : (
                        <ArrowDropDown htmlColor={'rgba(0, 0, 0, 0.54)'} />
                    )
                }
                labelStyles={{ color: lightTheme.primary.midnight, fontWeight: 'unset' }}
                title={Titles.Send}
                id={buttonId}
                style={{ ...style, borderColor: isHovered ? 'rgba(0, 0, 0, 0.87)' : '#BAC6C6' }}
            />
            <Popover
                style={{
                    padding: '10px',
                }}
                classes={popoverStyles}
                id={`popover-${ElementId.SendOptions}`}
                open={isStatusMenuOpen}
                anchorEl={statusMenuAnchorEl}
                onClose={() => {
                    setIsStatusMenuOpen(false);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Column>
                    <CopySummary onCopyClick={onCopyOptionClick} />
                    <SubscribeEmails onClick={onSendEmailsClick} />
                    <SlackSettingsRow />
                </Column>
            </Popover>
        </>
    );
}
