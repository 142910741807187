import { AgendaItem, AgendaItemSource, ISOString, TimeInSeconds, UUID } from '@spinach-shared/types';

import { StartStopProps } from '../time';

export class BaseAgendaItemProps {
    id: UUID;
    title: string;
    totalTime: TimeInSeconds;
    talkTimes: StartStopProps;
    createdAt: ISOString;
    updatedAt: ISOString;
    source: AgendaItemSource;
    isParticipantAgendaItem: boolean;

    constructor({
        title,
        source,
        createdAt,
        updatedAt,
        id,
        talkTimes,
        totalTime,
        isParticipantAgendaItem,
    }: AgendaItem) {
        this.id = id;
        this.title = title;
        this.totalTime = totalTime;
        this.source = source;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.isParticipantAgendaItem = isParticipantAgendaItem;
        this.talkTimes = new StartStopProps(talkTimes);
    }

    toJSON(): AgendaItem {
        return {
            id: this.id,
            title: this.title,
            totalTime: this.totalTime,
            source: this.source,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            isParticipantAgendaItem: this.isParticipantAgendaItem,
            talkTimes: this.talkTimes.toJSON(),
        };
    }
}
