import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { useWindowSize } from '../../hooks';

export const OnboardContainer = styled.div<{ height: number }>`
    display: flex;
    flex-direction: column;
    height: ${(props) => `${props.height}px`};
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    align-items: center;
    position: relative;
`;

export function ViewContainer(props: { children: ReactNode }): JSX.Element {
    const { height } = useWindowSize();

    return <OnboardContainer height={height}>{props.children}</OnboardContainer>;
}
