import { atom } from 'recoil';

export type GlobalAiMeetingDetailsSectionState = {
    openMeetingDetails: string[];
    highlightedMeetingDetails?: { seriesId?: string; iCalUid: string } | null;
    isAddingAdditionalMeetings: boolean;
    showInviteErrorToast: boolean;
};

export const atomAiMeetingDetailsSection = atom<GlobalAiMeetingDetailsSectionState>({
    key: 'aiMeetingDetailsSection',
    default: {
        openMeetingDetails: [],
        highlightedMeetingDetails: null,
        isAddingAdditionalMeetings: false,
        showInviteErrorToast: false,
    },
});
