import { CustomVocabulary, SpinachAPIPath } from '@spinach-shared/types';

import { deleteSpinachAPI } from './deleteSpinachAPI';
import { getSpinachAPI } from './getSpinachAPI';
import { patchSpinachAPI } from './patchSpinachAPI';

export async function getCustomVocabulary(): Promise<CustomVocabulary[]> {
    const response = await getSpinachAPI<CustomVocabulary[]>(SpinachAPIPath.UserCustomVocabulary, {
        throwOnError: true,
    });
    if (response === undefined) {
        throw new Error('Failed to get custom vocabulary');
    }
    return response;
}

export async function insertCustomVocabulary(id: string, text: string): Promise<void> {
    await patchSpinachAPI(SpinachAPIPath.UserCustomVocabulary, { id, text });
}

export async function insertCustomVocabularyMisspellings(
    id: string,
    misspellingId: string,
    text: string
): Promise<void> {
    await patchSpinachAPI(SpinachAPIPath.UserCustomVocabularyMispellings.replace(':id', id), {
        id: misspellingId,
        text,
    });
}

export async function deleteCustomVocabulary(id: string): Promise<void> {
    await deleteSpinachAPI(SpinachAPIPath.UserCustomVocabulary, { id }, true);
}

export async function deleteCustomVocabularyMisspellings(id: string, misspellingId: string): Promise<void> {
    await deleteSpinachAPI(
        SpinachAPIPath.UserCustomVocabularyMispellings.replace(':id', id),
        { id: misspellingId },
        true
    );
}
