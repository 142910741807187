import { Modal, TextField } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ISSUE_BASED_EXPERIMENT_CUSTOM_LIST_IDS } from '@spinach-shared/constants';
import { SpinachSeriesProps } from '@spinach-shared/models';
import {
    ClientEventType,
    ClientSocketEvent,
    MeetingStatus,
    SpinachList,
    UpdateSeriesComponentsRequest,
} from '@spinach-shared/types';

import { patchSeries } from '../../../apis/patchSeries';
import { GlobalModal } from '../../../atoms';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNullableLiveSeries,
    useGlobalStoredSeries,
} from '../../../hooks';
import { useGlobalMeetingSocket } from '../../../hooks/useGlobalSocket';
import { BodyHuge, BodyLarge, BodyRegular, responsiveness } from '../../../styles';
import { createWebsocketPayload } from '../../../utils';
import { useSpinachInputStyles } from '../../input';
import { ModalContent } from '../../series/common';
import { PrimaryButton, ScrollArea } from '../../stand-up';
import { CloseButton } from '../CloseButton';
import { SpinachSwitch } from '../SpinachSwitch';
import { Column, Hairline, Row, Spacing } from '../framing';

const ResponsiveModalContent = styled(ModalContent)`
    max-width: 450px;
    min-width: 450px;

    @media ${responsiveness.thinnerThanSM} {
        max-width: 450px;
        min-width: 80%;
    }

    @media ${responsiveness.thinnerThanXS} {
        min-width: 90%;
    }
`;

const SubComponentCol = styled(Column)`
    margin-left: 15px;
    width: calc(100% - 15px);
`;

export function SeriesSettingsModalContainer(): JSX.Element {
    const [liveSeries] = useGlobalNullableLiveSeries();

    if (!liveSeries) {
        return <></>;
    }

    return <SeriesSettingsModal liveSeries={liveSeries} />;
}

const ResponsiveModalTitle = styled(BodyHuge)`
    @media ${responsiveness.thinnerThanSM} {
        font-size: 20px;
    }

    @media ${responsiveness.thinnerThanXS} {
        font-size: 18px;
    }
`;

function SeriesSettingsModal({ liveSeries }: { liveSeries: SpinachSeriesProps }): JSX.Element {
    const [socket] = useGlobalMeetingSocket();
    const [user] = useGlobalAuthedUser();
    const [, setStoredSeries] = useGlobalStoredSeries();

    const [globalModal, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();
    const classes = useSpinachInputStyles({ value: '' });
    const [isLoading, setIsLoading] = useState(false);

    const [initialRoundtableState, setInitialRoundtableState] = useState(liveSeries?.roundtableComponent);
    const [updatedRoundtable, setUpdatedRoundtable] = useState(initialRoundtableState);

    useEffect(() => {
        setInitialRoundtableState(liveSeries?.roundtableComponent);
    }, [liveSeries?.roundtableComponent]);

    function getUpdatedList(list: SpinachList): SpinachList | undefined {
        return updatedRoundtable.components.find((uc) => uc.id === list.id);
    }

    function toggleHidden(list: SpinachList) {
        const index = updatedRoundtable.components.findIndex((uc) => uc.id === list.id);
        const updatedList = getUpdatedList(list);

        if (index === -1 || !updatedList) {
            return;
        }

        const copiedComponents = [...updatedRoundtable.components];
        copiedComponents[index] = {
            ...updatedList,
            isHidden: !updatedList.isHidden,
        };

        setUpdatedRoundtable({
            ...updatedRoundtable,
            components: copiedComponents,
        });
    }

    function updateListName(list: SpinachList, updatedTitle: string) {
        const index = updatedRoundtable.components.findIndex((uc) => uc.id === list.id);
        const updatingList = getUpdatedList(list);

        if (index === -1 || !updatingList) {
            return;
        }

        const copiedComponents = [...updatedRoundtable.components];
        copiedComponents[index] = {
            ...updatingList,
            title: updatedTitle,
            isHidden: updatedTitle.trim() === '' ? true : !!updatedTitle.trim() ? false : updatingList.isHidden,
        };

        setUpdatedRoundtable({
            ...updatedRoundtable,
            components: copiedComponents,
        });
    }

    function toggleRoundtableVisibility() {
        setUpdatedRoundtable({
            ...updatedRoundtable,
            isHidden: !updatedRoundtable.isHidden,
        });
    }

    const roundtableHasUpdated = JSON.stringify(updatedRoundtable) !== JSON.stringify(initialRoundtableState);
    // const isUpdatedStateInvalid = updatedRoundtable.components.some((c) => (c as SpinachList).title === newListName);
    const isSaveable = roundtableHasUpdated;

    // resets update state when user closes modal, however they decide
    // to close it, saving changes or canceling them
    React.useEffect(() => {
        if (globalModal === null) {
            setUpdatedRoundtable(liveSeries?.roundtableComponent);
        }
    }, [globalModal, liveSeries?.roundtableComponent]);

    React.useEffect(() => {
        if (globalModal === GlobalModal.SeriesSettings) {
            track(ClientEventType.SeriesSettingsOpened);
        }
    }, [globalModal]);

    if (!initialRoundtableState) {
        return <></>;
    }

    if (!socket) {
        return <></>;
    }

    if (!liveSeries) {
        return <></>;
    }

    const getDisabledTextColor = () => (updatedRoundtable.isHidden ? 'gray' : 'initial');

    function closeModalWithoutSaving() {
        setGlobalModal(null);
        track(ClientEventType.SeriesSettingsClosedWithoutSaving);
    }

    return (
        <Modal open={globalModal === GlobalModal.SeriesSettings} onClose={closeModalWithoutSaving}>
            <ResponsiveModalContent>
                <Column>
                    <Row style={{ height: '20px' }}>
                        <CloseButton
                            style={{
                                cursor: 'pointer',
                                marginRight: '5px',
                                left: '-7px',
                                top: '0px',
                            }}
                            onClick={closeModalWithoutSaving}
                        />
                    </Row>
                    <Row centered>
                        <ResponsiveModalTitle>
                            <b>{liveSeries.name} Settings</b>
                        </ResponsiveModalTitle>
                    </Row>

                    <Hairline />

                    <Spacing factor={1 / 2} />

                    <Row>
                        <BodyLarge>Customize your standup</BodyLarge>
                    </Row>

                    <Spacing factor={1 / 4} />

                    <ScrollArea sidePadding={10}>
                        <Row
                            key={initialRoundtableState.id}
                            style={{ alignItems: 'center', justifyContent: 'flex-start', marginLeft: '10px' }}
                        >
                            <SpinachSwitch
                                disabled={liveSeries.currentMeeting.status !== MeetingStatus.Initialized} // TODO: tooltip when disabled
                                checked={!updatedRoundtable.isHidden}
                                onChange={() => {
                                    toggleRoundtableVisibility();
                                }}
                            />

                            <BodyRegular style={{ marginLeft: '5px' }}>{updatedRoundtable.name}</BodyRegular>
                        </Row>
                        <Spacing factor={1 / 2} />

                        <SubComponentCol>
                            <Row>
                                <BodyLarge style={{ color: getDisabledTextColor() }}>
                                    Customize your roundtable
                                </BodyLarge>
                            </Row>

                            <Spacing factor={1 / 5} />
                            <BodyRegular style={{ width: '100%', color: getDisabledTextColor() }}>
                                Default Categories
                            </BodyRegular>

                            {liveSeries.roundtableComponent.components
                                .filter((c) => !!c.reservedType)
                                .map((c) => {
                                    return (
                                        <Row key={c.id} style={{ alignItems: 'center', height: '32px' }}>
                                            <SpinachSwitch
                                                scale={0.8}
                                                disabled={updatedRoundtable.isHidden}
                                                checked={!getUpdatedList(c)?.isHidden}
                                                onChange={() => {
                                                    toggleHidden(c);
                                                }}
                                            />
                                            <BodyRegular style={{ marginLeft: '3px', color: getDisabledTextColor() }}>
                                                {c.title}
                                            </BodyRegular>
                                        </Row>
                                    );
                                })}

                            <Spacing factor={1 / 2} />

                            <BodyRegular style={{ width: '100%', color: getDisabledTextColor() }}>
                                Custom Categories
                            </BodyRegular>

                            {liveSeries.roundtableComponent.components
                                .slice(3)
                                .filter(
                                    (c) => !c.reservedType && !ISSUE_BASED_EXPERIMENT_CUSTOM_LIST_IDS.includes(c.id)
                                )
                                .map((c) => {
                                    const updatedList = updatedRoundtable.components.find((l) => l.id === c.id);
                                    if (!updatedList) {
                                        return;
                                    }

                                    return (
                                        <Row key={c.id} style={{ alignItems: 'center' }}>
                                            <SpinachSwitch
                                                scale={0.8}
                                                disabled={updatedRoundtable.isHidden || !updatedList.title}
                                                checked={!updatedList.isHidden}
                                                onChange={() => {
                                                    // TODO: tracking
                                                    toggleHidden(c);
                                                }}
                                            />
                                            <TextField
                                                value={updatedList.title}
                                                InputProps={{ classes: { root: classes.base } }}
                                                className={classes.root}
                                                onChange={(e) => updateListName(updatedList, e.target.value)}
                                                style={{ marginRight: '10px' }}
                                                inputProps={{ maxLength: 20 }}
                                                placeholder="Add a category"
                                                disabled={updatedRoundtable.isHidden}
                                            />
                                        </Row>
                                    );
                                })}
                        </SubComponentCol>
                    </ScrollArea>

                    <Spacing factor={1 / 2} />

                    <PrimaryButton
                        title={isSaveable ? 'Save' : 'Done'}
                        // disabled={isUpdatedStateInvalid}
                        isLoading={isLoading}
                        onClick={async () => {
                            if (!isSaveable) {
                                closeModalWithoutSaving();
                                return;
                            }

                            setIsLoading(true);
                            // todo: better validation
                            // todo: hiding of reserved sections

                            const updatedComponents = [
                                {
                                    ...updatedRoundtable,
                                    components: [...updatedRoundtable.components],
                                },
                            ];

                            const updatedSeries = await patchSeries(liveSeries.slug, {
                                components: updatedComponents,
                            });

                            // TODO: a useWebsocketPayloadCreator hook would auto-fill most of this
                            const payload = createWebsocketPayload<UpdateSeriesComponentsRequest>({
                                components: updatedComponents,
                                seriesSlug: liveSeries.slug,
                                meetingId: liveSeries.currentMeeting.id,
                                spinachUserId: user.spinachUserId,
                                userId: user.spinachUserId,
                                userName: user.preferredName,
                                userEmail: user.email,
                                rootDomain: user.rootDomain,
                            });
                            socket.emit(ClientSocketEvent.UpdatingSeriesComponents, payload);

                            function getState(isHidden: boolean): 'off' | 'on' {
                                return isHidden ? 'off' : 'on';
                            }

                            try {
                                track(ClientEventType.SavedRoundtableCustomization, {
                                    PreviousRoundtableState: getState(initialRoundtableState.isHidden),
                                    NewRoundtableState: getState(updatedRoundtable.isHidden),

                                    PreviousYesterdayCategoryState: getState(
                                        initialRoundtableState.components[0].isHidden
                                    ),
                                    NewYesterdayCategoryState: getState(updatedRoundtable.components[0].isHidden),
                                    PreviousTodayCategoryState: getState(initialRoundtableState.components[1].isHidden),
                                    NewTodayCategoryState: getState(updatedRoundtable.components[1].isHidden),
                                    PreviousBlockersCategoryState: getState(
                                        initialRoundtableState.components[2].isHidden
                                    ),
                                    NewBlockersCategoryState: getState(updatedRoundtable.components[2].isHidden),

                                    PreviousCustomCategory1State: getState(
                                        initialRoundtableState.components[3].isHidden
                                    ),
                                    NewCustomCategory1State: getState(updatedRoundtable.components[3].isHidden),
                                    PreviousCustomCategory1Title: initialRoundtableState.components[3].title,
                                    NewCustomCategory1Title: updatedRoundtable.components[3].title,

                                    PreviousCustomCategory2State: getState(
                                        initialRoundtableState.components[4].isHidden
                                    ),
                                    NewCustomCategory2State: getState(updatedRoundtable.components[4].isHidden),
                                    PreviousCustomCategory2Title: initialRoundtableState.components[4].title,
                                    NewCustomCategory2Title: updatedRoundtable.components[4].title,

                                    PreviousCustomCategory3State: getState(
                                        initialRoundtableState.components[5].isHidden
                                    ),
                                    NewCustomCategory3State: getState(updatedRoundtable.components[5].isHidden),
                                    PreviousCustomCategory3Title: initialRoundtableState.components[5].title,
                                    NewCustomCategory3Title: updatedRoundtable.components[5].title,
                                });
                            } catch (error) {
                                Sentry.captureException(error);
                            }
                            if (updatedSeries) {
                                setStoredSeries(updatedSeries);
                            }
                            setIsLoading(false);
                            setGlobalModal(null);
                        }}
                    />
                </Column>
            </ResponsiveModalContent>
        </Modal>
    );
}
