import { AtlassianGetAvailableResourceItem, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export const fetchConfluenceSites = async () => {
    const response = await getSpinachAPI<AtlassianGetAvailableResourceItem[]>(
        `${SpinachAPIPath.ConfluenceAccessibleResources}`
    );
    return response ?? [];
};
