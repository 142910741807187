import { Titles } from '@spinach-clients/constants';
import { useState } from 'react';
import { Socket } from 'socket.io-client';
import styled from 'styled-components';

import { SpinachSeriesProps } from '@spinach-shared/models';
import { BaseIDRequest, ClientEventType, ClientSocketEvent } from '@spinach-shared/types';

import { GlobalModal, GlobalModalFrom } from '../../atoms';
import { ElementId } from '../../constants';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalDemoState,
    useGlobalLiveSeries,
    useGlobalModal,
    useGlobalNavDrawer,
    useGlobalStoredSeries,
    useInviteViaSlackEnablement,
    useMeetingFormatText,
    usePersonaDemo,
    useSeriesReality,
    useTeamTopics2Enablement,
} from '../../hooks';
import { BodyLarge, BodyRegular, lightTheme } from '../../styles';
import { SharedSpinachStandUpProps } from '../../types';
import { createAgendaReorderEmitter, withContentMasking } from '../../utils';
import { createWebsocketPayload } from '../../utils/analytics';
import { BorderedSpacing, Column, OverlayHint, PulsingHint, Row, Spacing } from '../common';
import { FeatureCalloutContainer } from '../common/feature-discovery';
import { ShareSeriesModal } from '../series';
import { DiscussionReaction, DiscussionReactionButtons, useDiscussionReactions } from './DiscussionReactions';
import { IcebreakerSection } from './IcebreakerSection';
import { LongPressPrimaryButton } from './LongPressPrimaryButton';
import { MeditationSection } from './MeditationSection';
import { PrimaryButton } from './PrimaryButton';
import { ScrollArea } from './ScrollArea';
import SecondaryButton from './SecondaryButton';
import StandUpFooter from './StandUpFooter';
import { StandUpHeader } from './StandUpHeader';
import TeamStatus from './TeamStatus';
import TeamTopicStatus from './TeamTopicStatus';

const ScrollContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    flex-shrink: 0;
    background-color: ${(props) => props.theme.neutrals.offWhite};
`;

const HeaderRow = styled(Row)`
    margin-left: unset;
    justify-content: space-between;
    align-items: center;
`;

type LobbyViewProps = SharedSpinachStandUpProps & {
    isAsyncMeeting: boolean;
};

function LobbyViewFooter({
    isAsyncMeeting,
    bypassCooldown,
    series,
    socket,
}: {
    isAsyncMeeting: boolean;
    bypassCooldown: () => void;
    series: SpinachSeriesProps;
    socket: Socket;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useGlobalLiveSeries();
    const [storedSeries] = useGlobalStoredSeries();
    const { isDemoSeries } = useSeriesReality();
    const [, setGlobalModal] = useGlobalModal();
    const isInviteViaSlackEnabled = useInviteViaSlackEnablement();

    const shouldShowInviteViaSlackModal =
        isInviteViaSlackEnabled &&
        !user.isAuthedForSlack &&
        storedSeries.userMetadataList.length === 1 &&
        user.selectedSlackAsMessagingIntegraton;

    const { openCheckIn, openTeamTopics, drawer } = useGlobalNavDrawer();
    const track = useExperienceTracking();

    const isOnDemoMode = series.isDemo;

    const { focusedFeature } = useGlobalDemoState();
    const isStartButtonHighlightedForDemo = focusedFeature === ElementId.StartStandup && isDemoSeries; // && !user.hasPlayedAtLeastOnePracticeRound;

    let FooterButtons = <></>;

    if (isOnDemoMode) {
        FooterButtons = (
            <>
                <PulsingHint
                    pulseStyles={{
                        marginTop: '35px',
                    }}
                    isVisible={series.isDemo && !drawer}
                />

                {isStartButtonHighlightedForDemo ? (
                    <OverlayHint
                        style={{ marginTop: '-7px', borderRadius: '50px' }}
                        extraWidth={15}
                        extraHeight={15}
                        idOfElement={ElementId.StartStandup}
                    />
                ) : null}

                <PrimaryButton
                    id={ElementId.StartStandup}
                    title={"Let's go!"}
                    onClick={() => {
                        track(ClientEventType.ClickLetsGoButtonInLobby);
                        bypassCooldown();
                        const payload = createWebsocketPayload<BaseIDRequest>({
                            spinachUserId: user.spinachUserId,
                            seriesSlug: series.slug,
                            meetingId: liveSeries.currentMeeting.id,
                        });

                        socket.emit(ClientSocketEvent.AgendaStarting, payload);
                    }}
                />
            </>
        );
    } else if (isAsyncMeeting) {
        FooterButtons = (
            <PrimaryButton
                id="async-check-in"
                title={'Async check-in'}
                color={lightTheme.tertiary.orangeLight}
                labelStyles={{ color: lightTheme.primary.midnight }}
                onClick={() => {
                    openCheckIn();
                }}
            />
        );
    } else if (!series.isRoundtableEnabled && !series.agenda.topicItems.length) {
        FooterButtons = (
            <PrimaryButton
                id={ElementId.AddATopicBeforeStartingStandup}
                title={'Add a topic to start'}
                onClick={() => {
                    openTeamTopics();
                }}
            />
        );
    } else {
        let title = 'Start standup!';
        if (shouldShowInviteViaSlackModal) {
            title = 'Test drive standup!';
        } else if (series.currentMeeting.isMeditationActivated) {
            title = 'Start meditation';
        } else if (!series.isRoundtableEnabled) {
            title = 'Start agenda!';
        }

        FooterButtons = (
            <LongPressPrimaryButton
                id={ElementId.StartStandup}
                title={title}
                pressTitle="Starting for everyone"
                fixedWidth={220}
                pressTime={450}
                onClick={() => {
                    bypassCooldown();
                    if (shouldShowInviteViaSlackModal) {
                        setGlobalModal(GlobalModal.InviteViaSlack, { from: GlobalModalFrom.StartStandup });
                        return;
                    }
                    const payload = createWebsocketPayload<BaseIDRequest>({
                        spinachUserId: user.spinachUserId,
                        seriesSlug: series.slug,
                        meetingId: liveSeries.currentMeeting.id,
                    });

                    if (series.currentMeeting.isMeditationActivated) {
                        socket.emit(ClientSocketEvent.MeditationStarting, payload);

                        return;
                    }

                    socket.emit(ClientSocketEvent.AgendaStarting, payload);
                }}
            />
        );
    }

    return <StandUpFooter>{FooterButtons}</StandUpFooter>;
}

function MeetingFormatSection() {
    const [, setGlobalModal] = useGlobalModal();
    const { text: formatText, isAsync } = useMeetingFormatText();

    return (
        <Column>
            <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <BodyLarge style={{ paddingRight: '5px' }}>{isAsync ? 'Async' : 'Live'} Format</BodyLarge>
                <SecondaryButton
                    id={'edit-schedule-lobby-button'}
                    title={Titles.Edit}
                    onClick={() => setGlobalModal(GlobalModal.Schedule)}
                />
            </Row>
            <Spacing factor={1 / 3} />
            <Row>
                {formatText ? (
                    <BodyRegular>{formatText}</BodyRegular>
                ) : (
                    <Row style={{ alignItems: 'center' }}>
                        <BodyRegular style={{ paddingRight: '5px' }}>Notifications are off.</BodyRegular>
                    </Row>
                )}
            </Row>
        </Column>
    );
}

function LobbyView({ series, socket, cooldownProps, isAsyncMeeting }: LobbyViewProps): JSX.Element {
    const [isShareOpen, setIsShareOpen] = useState(false);
    const [storedSeries] = useGlobalStoredSeries();
    const { openTeamTopics, drawer } = useGlobalNavDrawer();
    const { isDemoSeries } = useSeriesReality();

    const { isDiscussionReactionVisible, reaction, triggeringSpinachUserId } = useDiscussionReactions(socket);

    const { spinachUserId } = series.spinachParticipant;
    const { bypassCooldown } = cooldownProps;
    const header = isDemoSeries ? 'Standup Agenda' : series.name;

    const isTeamTopics2Enabled = useTeamTopics2Enablement();

    const isAsyncMeetingEnabled = isAsyncMeeting;
    const isPersonaDemo = usePersonaDemo();
    return (
        <>
            <FeatureCalloutContainer />

            <StandUpHeader
                containerStyle={{ justifyContent: 'center' }}
                title={header}
                {...withContentMasking()}
                id="lobby-title"
            />
            {!series.isDemo ? <MeetingFormatSection /> : null}

            <MeditationSection />
            {series.isDemo ? (
                <></>
            ) : (
                <>
                    <BorderedSpacing factor={0.5} />
                    <IcebreakerSection />
                </>
            )}

            {storedSeries ? (
                <ShareSeriesModal
                    userId={series.spinachParticipant.id}
                    isOpen={isShareOpen}
                    onClose={() => {
                        setIsShareOpen(false);
                    }}
                    series={storedSeries}
                />
            ) : null}

            <BorderedSpacing factor={0.5} />

            <ScrollArea useGrayShading={true} stretch={true}>
                <ScrollContainer>
                    <TeamStatus
                        isAsyncMeeting={isAsyncMeetingEnabled}
                        participantsObject={series.currentMeeting.participants}
                        setIsShareSeriesModalOpen={setIsShareOpen}
                        createReorderEmitter={createAgendaReorderEmitter(series.slug, spinachUserId, socket)}
                    />
                    <Spacing factor={0.75} />
                    {isTeamTopics2Enabled && (
                        <>
                            <HeaderRow>
                                <BodyLarge style={{ display: 'flex', justifyContent: 'left', textAlign: 'left' }}>
                                    {series.topicsLabel}
                                </BodyLarge>
                                {!isPersonaDemo ? (
                                    <SecondaryButton
                                        title="Add a topic"
                                        style={{ zIndex: 20 }}
                                        onClick={() => {
                                            openTeamTopics();
                                        }}
                                    />
                                ) : null}
                            </HeaderRow>

                            <Spacing factor={1 / 3} />

                            <TeamTopicStatus
                                series={series}
                                socket={socket}
                                createAgendaReorderEmitter={createAgendaReorderEmitter(
                                    series.slug,
                                    spinachUserId,
                                    socket
                                )}
                            />
                        </>
                    )}
                </ScrollContainer>
            </ScrollArea>

            <LobbyViewFooter
                bypassCooldown={bypassCooldown}
                isAsyncMeeting={isAsyncMeetingEnabled}
                series={series}
                socket={socket}
            />

            <DiscussionReaction
                isDiscussionReactionVisible={isDiscussionReactionVisible && !drawer}
                triggeringSpinachUserId={triggeringSpinachUserId}
                reaction={reaction}
            />

            <DiscussionReactionButtons
                isDiscussionReactionVisible={isDiscussionReactionVisible}
                seriesSlug={series.slug}
                socket={socket}
            />
        </>
    );
}

export default LobbyView;
