import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import styled from 'styled-components';

import { SpinachUpdateType, TimeInSeconds, TypedUpdate, UpdateSectionTypeProps } from '@spinach-shared/types';

import {
    useGlobalAuthedUser,
    useIssueBasedEnablement,
    useIssueResolutionEnablement,
    useJiraEnablement,
    useJiraSentimentInFooterEnablement,
    usePersonaDemo,
    useReadOnlyPresentation,
    useRegularItemSentimentEnabled,
    useTypedUpdateWrapper,
} from '../../hooks';
import { AttachmentProps, SetTypedUpdates } from '../../types';
import { Column } from '../common';
import { IssueResolutionPicker } from '../input/IssueResolutionPicker';
import { LiveItem, LiveItemProps } from '../input/LiveItem';
import { LiveSubItem } from '../input/LiveSubItem';
import { Reactions } from '../input/Reaction';
import Draggable from './Draggable';
import { LiveItemSentimentDetails } from './LiveItemSentimentDetails';
import { LiveItemSentimentSelectionComponent } from './LiveItemSentimentSelectionComponent';
import { LiveUpdateViewProps } from './LiveUpdateSection';

export type LiveUpdateSectionProps = {
    typedUpdates: TypedUpdate[];
    setTypedUpdates: SetTypedUpdates;
    typeProps: UpdateSectionTypeProps;
    updateViewProps: LiveUpdateViewProps;
    isInNavDrawer?: boolean;
    autoFocusNewInputOnMountAfter?: TimeInSeconds;
    isRecentCheckinsEnabled?: boolean;
};

export const LiveUpdateContainer = styled.div<{ isTeamTopic?: boolean }>`
    position: relative;
    border-radius: 5px;
    margin-bottom: 8px;
    margin-left: 7px;
    padding: ${(props) => (props.isTeamTopic ? '4px' : '2px')};
    background-color: #fafafa;
    &:hover {
        background-color: #ffffff;
    }
    &:focus-within {
        background-color: #ffffff;
    }
`;

export type LiveTypedUpdatedProps = LiveUpdateSectionProps & {
    focusedInputIndex: number | null;
    setFocusedInputIndex: Dispatch<SetStateAction<number | null>>;
    autoFocusNewInputOnMount?: boolean;
    userId?: string;
    hideAddLine?: boolean;
    customListId?: string;
    isPresentingSection?: boolean;
};
export function LiveUpdate({
    isMouseIn,
    dragRef,
    index,
    removeTabbedItemByIndex,
    setTabbedItemByIndex,
    savedTabbedItemByIndex,
    saveFullTabbedItemByIndex,
    typedUpdate,
    autoFocusOnMountAfter,
    createTabbedItem,
    removeTabbedItem,
    placeholder,
    saveFullTypedUpdate,
    isInNavDrawer,
    setTypedUpdate,
    setFocusedInput,
    emptyInputRef,
    subItemEmptyInputRef,
    isFocused,
    saveTypedUpdate,
    updateOwnerId,
    hint,
}: LiveItemProps & {
    subItemEmptyInputRef: any;
    removeTabbedItemByIndex: (index: number) => () => void;
    setTabbedItemByIndex: (index: number) => (update: TypedUpdate) => void;
    savedTabbedItemByIndex: (index: number) => (text: string) => void;
    saveFullTabbedItemByIndex: (index: number) => (update: TypedUpdate) => void;
}) {
    const [tabbedFocusedIndex, setTabbedFocusedIndex] = useState<number | null>(null);
    const [user] = useGlobalAuthedUser();
    const isJiraEnabled = useJiraEnablement();
    const isIssueBasedEnabled = useIssueBasedEnablement();
    const isIssueResolutionEnabled = useIssueResolutionEnablement();
    const isRegularItemSentimentEnabled = useRegularItemSentimentEnabled();
    const isJiraSentimentInFooterEnabled = useJiraSentimentInFooterEnablement();
    const isReadonlyPresentation = useReadOnlyPresentation();
    const isPersonaDemo = usePersonaDemo();

    const shouldShowAddedReactions = !isIssueBasedEnabled || (isIssueBasedEnabled && !isInNavDrawer);

    const reactionsAttachmentProps: AttachmentProps = {
        typedUpdate,
        saveFullTypedUpdate,
        setTypedUpdate,
    };

    // update sentiment picker
    const typedUpdateWrapper = useTypedUpdateWrapper(typedUpdate);

    const setTabbedFocusedIndexByIndex = (tabbedIndex: number) => () => {
        setTabbedFocusedIndex(tabbedIndex);
    };
    const setFocusedIndexAndUnsetTabbedIndex = (nullable?: null) => {
        setTabbedFocusedIndex(null);
        return setFocusedInput(nullable);
    };

    const withoutEmptyReadOnlySubItem = (item: TypedUpdate): boolean => {
        if (isReadonlyPresentation && !isInNavDrawer && !item.text) {
            return false;
        }
        return true;
    };

    const moveDraggableSubItem = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            if (typedUpdate.subItems?.length) {
                const reorderedSubUpdates = typedUpdate.subItems.map((u: TypedUpdate) => u);
                reorderedSubUpdates.splice(dragIndex, 1);
                reorderedSubUpdates.splice(hoverIndex, 0, typedUpdate.subItems[dragIndex]);
                setTypedUpdate({ ...typedUpdate, subItems: reorderedSubUpdates });
            }
        },
        [typedUpdate.subItems]
    );

    // either it's a regular item and we have sentiments for regular items enabled
    // or it's a jira item and we have jira sentiment in footer enabled
    // and we're in the nav drawer
    // and the update has text
    const showSentimentPicker =
        ((isRegularItemSentimentEnabled && !typedUpdateWrapper.ticket) ||
            (isJiraSentimentInFooterEnabled && typedUpdateWrapper.ticket)) &&
        isInNavDrawer &&
        !!typedUpdateWrapper.typedUpdate.text &&
        typedUpdateWrapper.typedUpdate.updateType !== SpinachUpdateType.Icebreaker &&
        !isPersonaDemo;

    return (
        <LiveUpdateContainer>
            {hint}
            <LiveItem
                index={index}
                key={typedUpdate.id}
                isInNavDrawer={isInNavDrawer}
                typedUpdate={typedUpdate}
                createTabbedItem={createTabbedItem}
                removeTabbedItem={removeTabbedItem}
                removeTabbedItemByIndex={removeTabbedItemByIndex}
                setTypedUpdate={setTypedUpdate}
                onBlur={() => {
                    saveTypedUpdate(typedUpdate.text);
                    setFocusedIndexAndUnsetTabbedIndex(null);
                }}
                setFocusedInput={setFocusedIndexAndUnsetTabbedIndex}
                emptyInputRef={emptyInputRef}
                subItemEmptyInputRef={subItemEmptyInputRef}
                isFocused={isFocused && tabbedFocusedIndex === null}
                saveTypedUpdate={saveTypedUpdate}
                saveFullTypedUpdate={saveFullTypedUpdate}
                placeholder={placeholder}
                isMouseIn={isMouseIn}
                dragRef={dragRef}
                autoFocusOnMountAfter={autoFocusOnMountAfter}
                updateOwnerId={updateOwnerId}
                isOmniboxSearchEnabled={isJiraEnabled && typedUpdate.updateType !== SpinachUpdateType.Icebreaker} // only reason to show omnibox is jira for now
            />

            <Column style={{ paddingLeft: '6px' }}>
                {!!typedUpdate.subItems?.length ? (
                    typedUpdate.subItems
                        .filter(withoutEmptyReadOnlySubItem)
                        .map((item: TypedUpdate, subItemIndex: number, subItems: TypedUpdate[]) => {
                            const isDraggable = subItems.length > 1;
                            return (
                                <Draggable
                                    key={item.id}
                                    index={subItemIndex}
                                    id={item.id}
                                    style={{
                                        paddingLeft: 'unset',
                                        marginLeft: 'unset',
                                    }}
                                    moveDraggable={moveDraggableSubItem}
                                    isDraggable={isDraggable}
                                    emitOrderedUpdatesSave={() => saveFullTypedUpdate(typedUpdate)}
                                    type={`${item.updateType}-${user.spinachUserId}`}
                                    idList={subItems.map((u) => u.id)}
                                >
                                    <LiveSubItem
                                        index={subItemIndex}
                                        key={item.id}
                                        typedUpdate={item}
                                        onBlur={() => {
                                            // This is the `saveTypedUpdate` equivelant for sub items
                                            savedTabbedItemByIndex(subItemIndex)(item.text);
                                            setFocusedIndexAndUnsetTabbedIndex(null);
                                        }}
                                        isInNavDrawer={isInNavDrawer}
                                        createTabbedItem={createTabbedItem}
                                        removeTabbedItem={removeTabbedItemByIndex(subItemIndex)}
                                        setTypedUpdate={setTabbedItemByIndex(subItemIndex)}
                                        setFocusedInput={setTabbedFocusedIndexByIndex(subItemIndex)}
                                        emptyInputRef={emptyInputRef}
                                        subItemEmptyInputRef={subItemEmptyInputRef}
                                        isFocused={tabbedFocusedIndex === subItemIndex}
                                        saveTypedUpdate={savedTabbedItemByIndex(subItemIndex)}
                                        saveFullTypedUpdate={saveFullTabbedItemByIndex(subItemIndex)}
                                        placeholder={
                                            typedUpdate.jiraData || typedUpdate.ticketData?.ticket
                                                ? typedUpdate.updateType !== SpinachUpdateType.Yesterday
                                                    ? `What's new with this ticket?`
                                                    : 'What are you doing on this ticket?'
                                                : 'More details?'
                                        }
                                        autoFocusOnMountAfter={autoFocusOnMountAfter}
                                    />
                                </Draggable>
                            );
                        })
                ) : (
                    <></>
                )}
                {!isInNavDrawer ? (
                    <LiveItemSentimentDetails
                        isInNavDrawer={isInNavDrawer}
                        typedUpdate={typedUpdateWrapper.typedUpdate}
                    />
                ) : (
                    <></>
                )}
                {!isInNavDrawer && isIssueResolutionEnabled ? (
                    <IssueResolutionPicker
                        setTypedUpdate={setTypedUpdate}
                        typedUpdate={typedUpdate}
                        saveFullTypedUpdate={saveFullTypedUpdate}
                        isInNavDrawer={isInNavDrawer}
                    />
                ) : (
                    <></>
                )}
            </Column>

            {showSentimentPicker ? (
                <LiveItemSentimentSelectionComponent
                    typedUpdate={typedUpdateWrapper}
                    setTypedUpdate={setTypedUpdate}
                    isParentHovered={isFocused || isMouseIn}
                    saveFullTypedUpdate={saveFullTypedUpdate}
                    isInNavDrawer={isInNavDrawer}
                />
            ) : (
                <></>
            )}

            {shouldShowAddedReactions ? <Reactions {...reactionsAttachmentProps} /> : null}
        </LiveUpdateContainer>
    );
}
