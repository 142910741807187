import React from 'react';

import { ClientEventType, MeetingProject, MeetingWorkstream, SummaryJson } from '@spinach-shared/types';

import { DropDown, ListItemValue, useExperienceTracking } from '../../../../../../..';
import { BodyRegularOnboard, HeaderThree } from '../../../../../../styles';
import { Column, Row } from '../../../../../common';
import { AddItemRow, ItemRow, SectionComponentProps, SummaryTextField } from '../shared';

export function WorkstreamsSectionEditor({ summaryJSON, setSummaryJSON }: SectionComponentProps): JSX.Element {
    const track = useExperienceTracking();

    const updateProject = (updatedProject: MeetingProject, index: number) => {
        const updatedProjects = [...(summaryJSON.workstreamProjects ?? [])];
        updatedProjects[index] = updatedProject;
        const updatedJson: SummaryJson = {
            ...summaryJSON,
            workstreamProjects: updatedProjects,
        };
        setSummaryJSON(updatedJson);
    };

    const addWorkstream = (projectIndex: number) => {
        const updatedProjects = [...(summaryJSON.workstreamProjects ?? [])];
        const newWorkstream: MeetingWorkstream = {
            name: '',
            description: '',
            tags: [],
            progress: '',
            owner: '',
            status: '',
        };
        updatedProjects[projectIndex].workstreams.push(newWorkstream);
        updateProject(updatedProjects[projectIndex], projectIndex);
    };

    const progressOptions: ListItemValue<string>[] = [
        { label: '🟢', code: 'green' },
        { label: '🟡', code: 'yellow' },
        { label: '🔴', code: 'red' },
    ];

    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <HeaderThree>Projects</HeaderThree>
            </Row>

            {summaryJSON.workstreamProjects?.map((project: MeetingProject, projectIndex: number) => (
                <ItemRow
                    key={projectIndex}
                    onRemoveClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Remove Summary Item',
                            ItemType: 'Project',
                        });
                        const updatedProjects = [...(summaryJSON.workstreamProjects ?? [])];
                        updatedProjects.splice(projectIndex, 1);
                        const updatedJson: SummaryJson = {
                            ...summaryJSON,
                            workstreamProjects: updatedProjects,
                        };
                        setSummaryJSON(updatedJson);
                    }}
                    rowIndex={projectIndex}
                >
                    <Column>
                        <SummaryTextField
                            InputProps={{ style: { fontWeight: 600 } }}
                            value={project.name}
                            onChange={(e: any) => {
                                const updatedProject = { ...project, name: e.target.value };
                                updateProject(updatedProject, projectIndex);
                            }}
                            placeholder="Project name"
                        />
                        <Column style={{ marginLeft: '20px' }}>
                            {project.workstreams.map((workstream, workstreamIndex) => (
                                <ItemRow
                                    key={workstreamIndex}
                                    onRemoveClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Remove Summary Item',
                                            ItemType: 'Workstream',
                                        });
                                        const updatedWorkstreams = [...project.workstreams];
                                        updatedWorkstreams.splice(workstreamIndex, 1);
                                        const updatedProject = { ...project, workstreams: updatedWorkstreams };
                                        updateProject(updatedProject, projectIndex);
                                    }}
                                    rowIndex={workstreamIndex}
                                >
                                    <Column>
                                        <Row vCenter>
                                            <DropDown
                                                title={'Progress'}
                                                values={progressOptions}
                                                selected={
                                                    progressOptions.find(
                                                        (option) => option.code === workstream.progress
                                                    )?.label
                                                }
                                                defaultValue={progressOptions[0].label}
                                                handleSelection={(code) => {
                                                    const updatedWorkstream = { ...workstream, progress: code };
                                                    const updatedWorkstreams = [...project.workstreams];
                                                    updatedWorkstreams[workstreamIndex] = updatedWorkstream;
                                                    const updatedProject = {
                                                        ...project,
                                                        workstreams: updatedWorkstreams,
                                                    };
                                                    updateProject(updatedProject, projectIndex);
                                                }}
                                                style={{
                                                    minWidth: '50px',
                                                }}
                                                buttonProps={{
                                                    style: {
                                                        transform: 'scale(0.8)',
                                                        transformOrigin: 'center left',
                                                        padding: '4px 8px',
                                                        marginRight: '-3px',
                                                    },
                                                }}
                                            />
                                            <SummaryTextField
                                                value={workstream.name}
                                                onChange={(e) => {
                                                    const updatedWorkstream = { ...workstream, name: e.target.value };
                                                    const updatedWorkstreams = [...project.workstreams];
                                                    updatedWorkstreams[workstreamIndex] = updatedWorkstream;
                                                    const updatedProject = {
                                                        ...project,
                                                        workstreams: updatedWorkstreams,
                                                    };
                                                    updateProject(updatedProject, projectIndex);
                                                }}
                                                placeholder="Workstream name"
                                            />
                                        </Row>
                                        <Column style={{ paddingLeft: '60px' }}>
                                            <Row>
                                                <BodyRegularOnboard style={{ paddingTop: '8px', fontWeight: 'bold' }}>
                                                    Owner:
                                                </BodyRegularOnboard>
                                                <SummaryTextField
                                                    style={{ marginLeft: '6px' }}
                                                    value={workstream.owner}
                                                    onChange={(e) => {
                                                        const updatedWorkstream = {
                                                            ...workstream,
                                                            owner: e.target.value,
                                                        };
                                                        const updatedWorkstreams = [...project.workstreams];
                                                        updatedWorkstreams[workstreamIndex] = updatedWorkstream;
                                                        const updatedProject = {
                                                            ...project,
                                                            workstreams: updatedWorkstreams,
                                                        };
                                                        updateProject(updatedProject, projectIndex);
                                                    }}
                                                    placeholder="Owner of this workstream update"
                                                />
                                            </Row>
                                            <Row>
                                                <BodyRegularOnboard style={{ paddingTop: '8px', fontWeight: 'bold' }}>
                                                    Status:
                                                </BodyRegularOnboard>
                                                <SummaryTextField
                                                    style={{ marginLeft: '6px' }}
                                                    value={workstream.status}
                                                    onChange={(e) => {
                                                        const updatedWorkstream = {
                                                            ...workstream,
                                                            status: e.target.value,
                                                        };
                                                        const updatedWorkstreams = [...project.workstreams];
                                                        updatedWorkstreams[workstreamIndex] = updatedWorkstream;
                                                        const updatedProject = {
                                                            ...project,
                                                            workstreams: updatedWorkstreams,
                                                        };
                                                        updateProject(updatedProject, projectIndex);
                                                    }}
                                                    placeholder="The current status of this workstream"
                                                />
                                            </Row>
                                        </Column>
                                    </Column>
                                </ItemRow>
                            ))}
                            <AddItemRow
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Add Summary Item',
                                        ItemType: 'Workstream',
                                    });
                                    addWorkstream(projectIndex);
                                }}
                                buttonText="Add a Workstream"
                            />
                        </Column>
                    </Column>
                </ItemRow>
            ))}

            <AddItemRow
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Add Summary Item',
                        ItemType: 'Project',
                    });
                    const blankProject: MeetingProject = {
                        name: '',
                        workstreams: [],
                    };
                    const updatedProjects = [...(summaryJSON.workstreamProjects ?? []), blankProject];
                    const updatedJson: SummaryJson = {
                        ...summaryJSON,
                        workstreamProjects: updatedProjects,
                    };
                    setSummaryJSON(updatedJson);
                }}
                buttonText="Add a Project"
            />
        </>
    );
}
