import { useState } from 'react';

import { UserAccountButton, ViewContainer } from '../../../common';
import { OnboardingStep } from '../common';
import { StepTrackerOnboardingFlow } from '../step-tracker-onboarding/StepTrackerOnboardingFlow';
import { AgentGuide } from './AgentGuide';

export function AgentOnboardingFlowContainer(): JSX.Element {
    const STARTING_STEP = OnboardingStep.About;
    const [onboardingStep, setOnboardingStep] = useState<OnboardingStep>(STARTING_STEP);
    const shouldShowAgentGuide = [
        OnboardingStep.About,
        OnboardingStep.AddSpinachToMeetings,
        OnboardingStep.CalendarPermissions,
    ].includes(onboardingStep);

    return (
        <ViewContainer>
            <UserAccountButton isOnboardingFlow={true} />
            <StepTrackerOnboardingFlow
                isAgentOnboarding={true}
                onboardingStep={onboardingStep}
                setOnboardingStep={setOnboardingStep}
                startingStep={STARTING_STEP}
            />
            {shouldShowAgentGuide ? <AgentGuide onboardingStep={onboardingStep} /> : null}
        </ViewContainer>
    );
}
