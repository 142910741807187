import {
    DemoGuide,
    OMNIBOX_DEMO_JIRA_TICKETS,
    ORDERED_GUIDES,
    PersonaDemoGuide,
    ROLLOVER_JIRA_TITLE,
} from '@spinach-shared/constants';
import { MeetingStatus, Series, SpinachUpdateType, StandUpStatus, UserMood } from '@spinach-shared/types';

import { ActiveAgendaProps, SpinachMeetingProps, SpinachParticipantProps, YTBAgendaItemProps } from '..';
import { ActiveSeriesProps } from './ActiveSeriesProps';

export class SpinachSeriesProps extends ActiveSeriesProps {
    spinachUserId: string;
    currentMeeting: SpinachMeetingProps;

    constructor(series: Series, spinachUserId: string) {
        super(series);

        this.currentMeeting = new SpinachMeetingProps(series.currentMeeting, spinachUserId);
        this.spinachUserId = spinachUserId;
    }

    get thisParticipantsYTBAgendaItem(): YTBAgendaItemProps {
        return this.currentMeeting.thisParticipantsYTBAgendaItem;
    }

    get spinachParticipant(): SpinachParticipantProps {
        return this.currentMeeting.spinachParticipant;
    }

    get thisParticipantsMood(): UserMood | undefined {
        return this.currentMeeting.thisParticipantsMood;
    }

    get currentDemoGuide(): DemoGuide {
        const isReady = this.spinachParticipant.standUpStatus === StandUpStatus.Ready;

        const doesCheckInHaveRolledOverData = this.thisParticipantsYTBAgendaItem.standUpUpdate
            .getUpdatesForType(SpinachUpdateType.Today)
            .some(
                (update) => !!update.ticketData?.ticket.title && update.ticketData?.ticket.title === ROLLOVER_JIRA_TITLE
            );

        const doesCheckInHaveImportedJiraData = this.thisParticipantsYTBAgendaItem.standUpUpdate.updates.some(
            (update) =>
                !!update.ticketData?.ticket.title && OMNIBOX_DEMO_JIRA_TICKETS.includes(update.ticketData?.ticket.title)
        );

        if (this.currentMeeting.status === MeetingStatus.AgendaComplete) {
            return DemoGuide.ShareSummary;
        } else if (this.agenda instanceof ActiveAgendaProps && !this.agenda.currentItem.isParticipantAgendaItem) {
            return DemoGuide.ParkingLotDiscuss;
        } else if (this.agenda instanceof ActiveAgendaProps && this.agenda.currentItem.isParticipantAgendaItem) {
            return DemoGuide.EachPersonShares;
        } else if (isReady && this.currentMeeting.status === MeetingStatus.Initialized) {
            return DemoGuide.StartStandup;
        } else if (!isReady && doesCheckInHaveRolledOverData && doesCheckInHaveImportedJiraData) {
            return DemoGuide.CheckIn;
        } else if (!isReady && doesCheckInHaveRolledOverData) {
            return DemoGuide.JiraTicket;
        } else {
            return DemoGuide.Rollover;
        }
    }

    get currentPersonaDemoGuide(): PersonaDemoGuide {
        const isReady = this.spinachParticipant.standUpStatus === StandUpStatus.Ready;

        const hasTodayItem = !!this.thisParticipantsYTBAgendaItem.standUpUpdate.getUpdatesForType(
            SpinachUpdateType.Today
        ).length;

        if (this.currentMeeting.status === MeetingStatus.AgendaComplete) {
            return PersonaDemoGuide.InstantSummary;
        } else if (this.agenda instanceof ActiveAgendaProps && !this.agenda.currentItem.isParticipantAgendaItem) {
            return PersonaDemoGuide.ParkingLot;
        } else if (
            this.agenda instanceof ActiveAgendaProps &&
            this.agenda.currentItem.isParticipantAgendaItem &&
            this.agenda.currentItem.id === this.thisParticipantsYTBAgendaItem.id
        ) {
            return PersonaDemoGuide.DisruptionsBeLike;
        } else if (
            this.agenda instanceof ActiveAgendaProps &&
            this.agenda.currentItem.isParticipantAgendaItem &&
            this.agenda.currentIndex === 1
        ) {
            return PersonaDemoGuide.UpdateQuality;
        } else if (this.agenda instanceof ActiveAgendaProps && this.agenda.currentItem.isParticipantAgendaItem) {
            return PersonaDemoGuide.TimeToShare;
        } else if (isReady && this.currentMeeting.status === MeetingStatus.Initialized) {
            return PersonaDemoGuide.AgendaOrder;
        } else if (!isReady && hasTodayItem) {
            return PersonaDemoGuide.DraftingSuccess;
        } else {
            return PersonaDemoGuide.AddUpdateForToday;
        }
    }

    get currentGuideIndex(): number {
        const currentGuide = this.currentDemoGuide;
        return ORDERED_GUIDES.findIndex((guide) => guide === currentGuide);
    }

    get isDemoCheckInFinishable(): boolean {
        if (!this.isDemo) {
            return true;
        }

        if (this.spinachParticipant.usesPersonaDemo) {
            return true;
        }

        const doesCheckInHaveImportedJiraData = this.thisParticipantsYTBAgendaItem.standUpUpdate.updates.some(
            (update) =>
                !!update.ticketData?.ticket.title && OMNIBOX_DEMO_JIRA_TICKETS.includes(update.ticketData?.ticket.title)
        );

        const doesCheckInHaveRolledOverData = this.thisParticipantsYTBAgendaItem.standUpUpdate
            .getUpdatesForType(SpinachUpdateType.Today)
            .some(
                (update) => !!update.ticketData?.ticket.title && update.ticketData?.ticket.title === ROLLOVER_JIRA_TITLE
            );

        return doesCheckInHaveImportedJiraData || doesCheckInHaveRolledOverData;
    }

    get readyButtonHint(): string {
        if (this.currentGuideIndex === 0) {
            return 'Write a goal for today in your check-in.';
        } else if (this.currentGuideIndex === 1) {
            return 'Rollover your update from last standup.';
        } else if (this.currentGuideIndex === 2) {
            return 'Type "/" or use the "+" button to add a Jira ticket to your check-in.';
        } else {
            return '';
        }
    }
}
