import { ActiveAgendaProps, BaseAgendaProps, YTBAgendaItemProps } from '@spinach-shared/models';
import { SpinachUpdateType } from '@spinach-shared/types';
import { getFormattedDate, getTicketSource } from '@spinach-shared/utils';

import { useGlobalLiveSeries, useGlobalSummaryHistories, useWindowSize } from '../../hooks';
import { BodyLarge } from '../../styles';
import { ReadOnlyUpdateRow, ScrollArea } from '../stand-up';
import { Column, Hairline } from './framing';

export function PresentersRecentCheckIns({ updateType }: { updateType: SpinachUpdateType }): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();
    const [summaries] = useGlobalSummaryHistories();
    const { height } = useWindowSize();

    if (!(liveSeries.agenda instanceof ActiveAgendaProps) || !summaries) {
        return <></>;
    }

    const lastThreePresenterItemSets = summaries
        .map((summary) => {
            return summary.agenda.findParticipantsYTBAgendaItemProps(
                (liveSeries.agenda as ActiveAgendaProps).currentItem.id
            );
        })
        .filter(
            (item): item is YTBAgendaItemProps => !!item && !!item.standUpUpdate.getUpdatesForType(updateType).length
        )
        .slice(0, 3);

    if (liveSeries.agenda.currentItem instanceof YTBAgendaItemProps) {
        lastThreePresenterItemSets.unshift(liveSeries.agenda.currentItem);
    }

    const presenterBasedAgendas = lastThreePresenterItemSets.map((agendaItem) => {
        return new BaseAgendaProps({
            items: [agendaItem],
            startedAt: agendaItem.createdAt,
        });
    });

    return (
        <Column>
            <ScrollArea useGrayShading={true} sidePadding={0} style={{ height: `${height * 0.5}px` }}>
                {presenterBasedAgendas.map((agenda, i) => {
                    return (
                        <Column style={{ marginBottom: '15px' }}>
                            {i === 0 ? (
                                <BodyLarge style={{ fontSize: '12px' }}>This Check-in</BodyLarge>
                            ) : (
                                <BodyLarge style={{ fontSize: '12px' }}>
                                    {getFormattedDate(agenda.startedAt!)}
                                </BodyLarge>
                            )}
                            {i === 0 && agenda.YTBItems[0]?.standUpUpdate.getUpdatesForType(updateType).length === 0 ? (
                                <ReadOnlyUpdateRow text={'No updates in this section today.'} />
                            ) : null}
                            {agenda.YTBItems[0]?.standUpUpdate.getUpdatesForType(updateType).map((update) => {
                                const ticketSource = getTicketSource(update);

                                return (
                                    <ReadOnlyUpdateRow
                                        key={update.id}
                                        text={update.text}
                                        ticket={update.jiraData || update.asanaData || update.ticketData?.ticket}
                                        ticketSource={ticketSource}
                                    />
                                );
                            })}

                            {i === 0 ? <Hairline /> : null}
                        </Column>
                    );
                })}
            </ScrollArea>
        </Column>
    );
}
