import { APIPathParam, SpinachAPIPath, TicketProject, TicketSource } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function fetchTicketProjects(ticketSource: TicketSource, search?: string): Promise<TicketProject[]> {
    try {
        const response = await getSpinachAPI<{ projects: TicketProject[] }>(
            `${SpinachAPIPath.GetTicketProjects.replaceAll(APIPathParam.TicketSource, ticketSource)}`,
            {
                params: {
                    search,
                },
            }
        );
        return response?.projects ?? [];
    } catch (err) {
        return [];
    }
}

export async function fetchTicketSubProjects(ticketSource: TicketSource, projectId: string): Promise<TicketProject[]> {
    try {
        const response = await getSpinachAPI<{ subProjects: TicketProject[] }>(
            `${SpinachAPIPath.GetTicketSubProjects.replaceAll(APIPathParam.TicketSource, ticketSource).replaceAll(
                ':projectId',
                projectId
            )}`
        );
        return response?.subProjects ?? [];
    } catch (err) {
        return [];
    }
}
