import { SpinachAPIPath } from '@spinach-shared/types';

import { deleteSpinachAPI } from './deleteSpinachAPI';

export type DeleteMeetingNotes = { botId?: string; code?: string };

export async function deleteMeetingNotes(botId: string): Promise<DeleteMeetingNotes | undefined> {
    const data = await deleteSpinachAPI<DeleteMeetingNotes>(
        SpinachAPIPath.AIHistory.replace(':botId', botId),
        {
            botId,
        },
        true
    );

    return data;
}
