import { TextField } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import {
    ClientEventType,
    ClientSocketEvent,
    ParticipantMoodUpdatingRequest,
    SelectedSentimentAction,
    SentimentPickerLocation,
} from '@spinach-shared/types';

import { ReactComponent as CircledRightArrow } from '../../assets/sentiment/circled-right-arrow.svg';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalLiveSeries, useGlobalNavDrawer } from '../../hooks';
import { useGlobalMeetingSocket } from '../../hooks/useGlobalSocket';
import { BodyLarge, BodySubtitle, ButtonSize, lightTheme } from '../../styles';
import { createWebsocketPayload, withContentMasking } from '../../utils';
import { Bullet, BulletMark, Column, Row } from '../common';
import { UpdateRow, useSpinachInputStyles } from '../input';
import { SentimentPicker } from '../input/SentimentPicker';
import { Notification } from './Notification';
import { OutlinedButton } from './OutlinedButton';

const SentimentContainer = styled.div`
    margin-left: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 20px;
    border-radius: 4px;
`;

const ParentRow = styled(Row)`
    align-items: center;
    justify-content: space-between;

    @media ${`(max-width: 490px)`} {
        flex-direction: column;
        /* justify-content: soa; */
        align-items: start;
    }
`;

const SentimentPickerRow = styled(Row)`
    width: 76.2%;
    align-items: center;

    @media ${`(max-width: 490px)`} {
        flex-direction: column;
        width: unset;
        padding-bottom: 5px;
    }
`;

export const MoodSelectionComponentHeader = () => (
    <Row
        style={{
            alignItems: 'center',
            marginLeft: 'unset',
        }}
    >
        <BodyLarge style={{ textAlign: 'left', letterSpacing: '0.1px' }}>Mood</BodyLarge>
        <BodySubtitle
            style={{ whiteSpace: 'nowrap', marginLeft: '5px', marginRight: '5px', color: lightTheme.primary.midnight }}
        >
            (Results visible in the summary)
        </BodySubtitle>
    </Row>
);

export const MoodSelectionComponent = ({ location }: { location: SentimentPickerLocation }) => {
    const [liveSeries] = useGlobalLiveSeries();
    const [socket] = useGlobalMeetingSocket();
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const { openSummaries } = useGlobalNavDrawer();

    const [sentiment, setSentiment] = useState(liveSeries.thisParticipantsMood?.sentiment);
    const [showSentimentDetails, setShowSentimentDetails] = useState(!!liveSeries.thisParticipantsMood?.sentiment);
    const [sentimentDetails, setSentimentDetails] = useState(liveSeries.thisParticipantsMood?.details);
    const [isSentimentDetailsFocused, setIsSentimentDetailsFocused] = useState(false);
    const [timeoutRef, setTimeoutRef] = useState<null | ReturnType<typeof window.setTimeout>>(null);
    const buttonReference = useRef<HTMLButtonElement | null>(null);
    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    const [errorText, setErrorText] = useState<string | null>(null);

    useEffect(() => {
        setSentiment(liveSeries.thisParticipantsMood?.sentiment);
    }, [liveSeries.thisParticipantsMood?.sentiment]);

    useEffect(() => {
        setSentimentDetails(liveSeries.thisParticipantsMood?.details);
    }, [liveSeries.thisParticipantsMood?.details]);

    useEffect(() => {
        /**
         * @NOTE To properly focus, input ref needs to be set
         * Since inputRef is not set on the first render, focusing won't work in the onSelect method
         * So, we need to have a hook which checks for the following condition
         * Is inputRef set AND isSentimentDetails supposed to be focused AND sentimentDetails is not currently focused
         * */
        if (inputRef.current && isSentimentDetailsFocused && document.activeElement !== inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef.current]);

    const classes = useSpinachInputStyles({
        /**
         * @NOTE We don't includea  value so the input text field always
         * has an underline available
         * With normal YTB updates, we remove the underline border to clean
         * up some clutter becasue there are multiple text fields beneath the item
         * However, that's not the case here, so we want to keep the underline
         * in all cases
         *  */
        value: '',
    });

    function disposeTimeout() {
        if (timeoutRef) {
            clearTimeout(timeoutRef);
            setTimeoutRef(null);
        }
    }

    useEffect(() => {
        return () => {
            disposeTimeout();
        };
    }, []);

    useEffect(() => {
        if (!!sentiment) {
            buttonReference.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [sentiment]);

    return (
        <SentimentContainer>
            <ParentRow>
                <SentimentPickerRow>
                    <SentimentPicker
                        location={location}
                        title="How's it going?"
                        selectedSentiment={sentiment}
                        onSelect={(selectedSentiment) => {
                            track(ClientEventType.UserSelectedMood, {
                                SelectedSentiment: selectedSentiment,
                                Action:
                                    !sentiment && selectedSentiment
                                        ? SelectedSentimentAction.Added
                                        : sentiment && !selectedSentiment
                                        ? SelectedSentimentAction.Removed
                                        : SelectedSentimentAction.Modified,
                                Location: location,
                            });
                            setSentiment(selectedSentiment);
                            setShowSentimentDetails(!!selectedSentiment);
                            setIsSentimentDetailsFocused(true);
                            inputRef.current?.focus();
                            socket.emit(
                                ClientSocketEvent.ParticipantMoodUpdating,
                                createWebsocketPayload<ParticipantMoodUpdatingRequest>({
                                    seriesSlug: liveSeries.slug,
                                    spinachUserId: user.spinachUserId,
                                    userMood: {
                                        sentiment: selectedSentiment,
                                        displayName: user.preferredName,
                                    },
                                })
                            );
                        }}
                    />
                </SentimentPickerRow>
                {sentiment ? (
                    <OutlinedButton
                        size={ButtonSize.Mini}
                        style={{ borderColor: '#33885C' }}
                        labelStyles={{
                            color: '#33885C',
                            alignItems: 'center',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                        title="See team mood"
                        onClick={() => {
                            track(ClientEventType.UserClickMoodRedirect, {
                                Location: location,
                                SelectedSentiment: sentiment,
                                DetailsLength: sentimentDetails?.length,
                                UserSubmittedDetails: !!sentimentDetails?.length,
                                NumberOfOtherSubmittees: liveSeries.currentMeeting.userMoods?.length,
                            });
                            openSummaries();
                        }}
                        endIcon={<CircledRightArrow style={{ paddingLeft: '3px', width: '14px', height: '14px' }} />}
                    />
                ) : null}
            </ParentRow>
            {showSentimentDetails ? (
                <UpdateRow>
                    <BulletMark>{!sentimentDetails ? <span style={{ width: '6px' }}></span> : <Bullet />}</BulletMark>
                    <Column>
                        <TextField
                            aria-label={`sentiment-input-field`}
                            InputProps={{ classes: { root: classes.base } }}
                            disabled={false}
                            multiline={false}
                            inputProps={{ maxLength: 80, styles: { padding: '3px 0 3px' } }}
                            inputRef={inputRef}
                            {...withContentMasking(classes.root)}
                            fullWidth
                            value={sentimentDetails}
                            autoFocus={false}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    if (inputRef.current) {
                                        inputRef.current.blur();
                                    }
                                }
                            }}
                            onChange={(e) => {
                                if (e.target.value.length >= 80) {
                                    setErrorText('You can only use 80 characters');
                                } else {
                                    setErrorText(null);
                                }
                                disposeTimeout();
                                setSentimentDetails(e.target.value);
                                const timeout = setTimeout(() => {
                                    if (isSentimentDetailsFocused) {
                                        track(ClientEventType.UserAddedMoodDetails, {
                                            SelectedSentiment: sentiment,
                                            Location: location,
                                            DetailsLength: sentimentDetails?.length,
                                            UserSubmittedDetails: !!sentimentDetails?.length,
                                        });
                                        socket.emit(
                                            ClientSocketEvent.ParticipantMoodUpdating,
                                            createWebsocketPayload<ParticipantMoodUpdatingRequest>({
                                                seriesSlug: liveSeries.slug,
                                                spinachUserId: user.spinachUserId,
                                                userMood: {
                                                    details: e.target.value,
                                                    sentiment,
                                                    displayName: user.preferredName,
                                                },
                                            })
                                        );
                                    }
                                }, 3000);

                                setTimeoutRef(timeout);
                            }}
                            onFocus={() => {
                                setIsSentimentDetailsFocused(true);
                            }}
                            onBlur={() => {
                                setIsSentimentDetailsFocused(false);
                                track(ClientEventType.UserAddedMoodDetails, {
                                    SelectedSentiment: sentiment,
                                    Location: location,
                                    DetailsLength: sentimentDetails?.length,
                                    UserSubmittedDetails: !!sentimentDetails?.length,
                                });
                                socket.emit(
                                    ClientSocketEvent.ParticipantMoodUpdating,
                                    createWebsocketPayload<ParticipantMoodUpdatingRequest>({
                                        seriesSlug: liveSeries.slug,
                                        spinachUserId: user.spinachUserId,
                                        userMood: {
                                            displayName: user.preferredName,
                                            sentiment,
                                            details: sentimentDetails,
                                        },
                                    })
                                );
                            }}
                            placeholder={'Any context to share? (optional)'}
                        />
                    </Column>
                </UpdateRow>
            ) : null}
            <Notification
                containerStyle={{ position: 'relative', bottom: 'unset' }}
                isOpen={!!errorText}
                onClose={() => null}
                message={errorText}
                icon={<Error style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />}
            />
        </SentimentContainer>
    );
};
