import { TextField } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import {
    ClientEventType,
    GoalStatus,
    SelectedSentimentAction,
    Sentiment,
    SentimentPickerLocation,
    TypedUpdate,
    TypedUpdateWrapper,
} from '@spinach-shared/types';

import { ElementClass } from '../../constants';
import { useExperienceTracking, useIssueBasedEnablement, useLiveItemSentimentDetailsEnabled } from '../../hooks';
import { withContentMasking } from '../../utils';
import { Row } from '../common';
import { useSentimentReasonsCallout } from '../common/feature-discovery/SentimentReasonsCallout';
import { useSpinachNewInputStyles } from '../input';
import { SentimentPicker } from '../input/SentimentPicker';

const getSentimentPlaceholder = (sentiment?: Sentiment): string => {
    switch (sentiment) {
        case Sentiment.Good:
            return 'Great! Any details?';
        case Sentiment.Ok:
        case Sentiment.Bad:
            return 'What would help?';
        default:
            return '';
    }
};

const SentimentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;
    border-radius: 4px;
`;

const ParentRow = styled(Row)`
    align-items: center;
    height: 20px;
    justify-content: space-between;

    @media ${`(max-width: 490px)`} {
        align-items: start;
    }
`;

const SentimentPickerRow = styled(Row)`
    width: unset;
    align-items: center;

    @media ${`(max-width: 490px)`} {
        flex-direction: column;
        width: unset;
        padding-bottom: 5px;
    }
`;

export const LiveItemSentimentSelectionComponent = ({
    typedUpdate,
    setTypedUpdate,
    isParentHovered,
    saveFullTypedUpdate,
    isInNavDrawer,
}: {
    typedUpdate: TypedUpdateWrapper;
    setTypedUpdate: (typedUpdate: TypedUpdate) => void;
    isParentHovered?: boolean;
    isInNavDrawer?: boolean;
    saveFullTypedUpdate?: (typedUpdate: TypedUpdate) => void;
}) => {
    const track = useExperienceTracking();
    const [timeoutRef, setTimeoutRef] = useState<ReturnType<typeof window.setTimeout> | null>(null);
    const { onEngageClick } = useSentimentReasonsCallout();

    const [sentiment, setSentiment] = useState(typedUpdate.sentiment);
    const [showSentimentDetails, setShowSentimentDetails] = useState(!!typedUpdate.sentiment);
    const isLiveItemSentimentDetailsEnabled = useLiveItemSentimentDetailsEnabled();
    const sentimentPickerRef = useRef<HTMLDivElement | null>(null);
    const [sentimentDetails, setSentimentDetails] = useState(typedUpdate.sentimentDetails);
    const [isSentimentDetailsFocused, setIsSentimentDetailsFocused] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    const isIssueBasedEnabled = useIssueBasedEnablement();

    useEffect(() => {
        /**
         * @NOTE To properly focus, input ref needs to be set
         * Since inputRef is not set on the first render, focusing won't work in the onSelect method
         * So, we need to have a hook which checks for the following condition
         * Is inputRef set AND isSentimentDetails supposed to be focused AND sentimentDetails is not currently focused
         * */
        if (inputRef.current && isSentimentDetailsFocused && document.activeElement !== inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef.current]);

    const classes = useSpinachNewInputStyles({
        muiInputMultilineOverrides: {
            height: '20px',
        },
        muiBaseInputOverrides: {
            padding: 'unset',
            height: '20px',
        },
        /**
         * @NOTE We don't include a value so the input text field always
         * has an underline available
         * With normal YTB updates, we remove the underline border to clean
         * up some clutter becasue there are multiple text fields beneath the item
         * However, that's not the case here, so we want to keep the underline
         * in all cases
         *  */
        value: '',
    });

    function disposeTimeout() {
        if (timeoutRef) {
            clearTimeout(timeoutRef);
            setTimeoutRef(null);
        }
    }

    useEffect(() => {
        return () => {
            disposeTimeout();
        };
    }, []);

    if (isIssueBasedEnabled && typedUpdate.asGoal?.status && typedUpdate.asGoal.status === GoalStatus.Complete) {
        return <></>;
    }

    return (
        <SentimentContainer>
            <ParentRow>
                <SentimentPickerRow>
                    <SentimentPicker
                        id={`TU-${typedUpdate.typedUpdate.id}-SP`}
                        className={ElementClass.SentimentPicker}
                        location={SentimentPickerLocation.UpdateFooter}
                        title=""
                        isParentHovered={isParentHovered}
                        selectedSentiment={sentiment}
                        ref={sentimentPickerRef}
                        onSelect={(selectedSentiment) => {
                            // debounce
                            disposeTimeout();
                            track(ClientEventType.UserSelectedSentiment, {
                                SelectedSentiment: selectedSentiment,
                                Action:
                                    !sentiment && selectedSentiment
                                        ? SelectedSentimentAction.Added
                                        : sentiment && !selectedSentiment
                                        ? SelectedSentimentAction.Removed
                                        : SelectedSentimentAction.Modified,
                                Location: SentimentPickerLocation.UpdateFooter,
                                HasTicket: !!typedUpdate.ticket,
                                UpdateType: typedUpdate.typedUpdate.updateType,
                                TicketSource: typedUpdate.typedUpdate.ticketData?.source,
                            });
                            onEngageClick();
                            setSentiment(selectedSentiment);
                            setShowSentimentDetails(!!selectedSentiment);
                            setIsSentimentDetailsFocused(true);
                            inputRef.current?.focus();
                            setTypedUpdate({
                                ...typedUpdate.typedUpdate,
                                sentiment: selectedSentiment,
                            });

                            const timeout = setTimeout(() => {
                                saveFullTypedUpdate?.({
                                    ...typedUpdate.typedUpdate,
                                    sentiment: selectedSentiment,
                                });
                                disposeTimeout();
                            }, 500);
                            setTimeoutRef(timeout);
                        }}
                    />
                </SentimentPickerRow>

                {showSentimentDetails && isLiveItemSentimentDetailsEnabled ? (
                    <TextField
                        aria-label={`sentiment-input-field`}
                        InputProps={{ classes: { root: classes.base } }}
                        disabled={!isInNavDrawer}
                        multiline={false}
                        inputProps={{ maxLength: 74, styles: { padding: '3px 0 3px' } }}
                        inputRef={inputRef}
                        {...withContentMasking(classes.root)}
                        fullWidth
                        value={sentimentDetails}
                        autoFocus={false}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                if (inputRef.current) {
                                    inputRef.current.blur();
                                }
                            }
                        }}
                        onChange={(e) => {
                            if (e.target.value.length >= 80) {
                                return;
                            }
                            disposeTimeout();
                            setSentimentDetails(e.target.value);
                            const timeout = setTimeout(() => {
                                if (isSentimentDetailsFocused) {
                                    track(ClientEventType.UserAddedMoodDetails, {
                                        SelectedSentiment: sentiment,
                                        Location: SentimentPickerLocation.UpdateFooter,
                                        DetailsLength: sentimentDetails?.length,
                                        UpdateType: typedUpdate.typedUpdate.updateType,
                                        UserSubmittedDetails: !!sentimentDetails?.length,
                                    });
                                }
                                disposeTimeout();
                            }, 3000);
                            setTimeoutRef(timeout);
                        }}
                        onFocus={() => {
                            setIsSentimentDetailsFocused(true);
                        }}
                        onBlur={() => {
                            disposeTimeout();
                            setIsSentimentDetailsFocused(false);
                            track(ClientEventType.UserAddedMoodDetails, {
                                SelectedSentiment: sentiment,
                                Location: SentimentPickerLocation.UpdateFooter,
                                DetailsLength: sentimentDetails?.length,
                                UpdateType: typedUpdate.typedUpdate.updateType,
                                UserSubmittedDetails: !!sentimentDetails?.length,
                            });
                            setTypedUpdate({
                                ...typedUpdate.typedUpdate,
                                sentiment,
                                sentimentDetails,
                            });
                            saveFullTypedUpdate?.({
                                ...typedUpdate.typedUpdate,
                                sentiment,
                                sentimentDetails,
                            });
                        }}
                        placeholder={getSentimentPlaceholder(sentiment)}
                    />
                ) : null}
            </ParentRow>
        </SentimentContainer>
    );
};
