import { CSSProperties } from 'react';
import styled from 'styled-components';

import { useSeriesReality } from '../../hooks';
import { Row } from '../common';

const BrowserButton = styled.span<{ color: string }>`
    background-color: ${(props) => props.color};

    height: 15px;
    width: 15px;
    margin-right: 10px;
    border-radius: 50%;
`;

export function MacOSButtons({
    style = {},
    rowStyle = {},
}: {
    style?: CSSProperties;
    rowStyle?: CSSProperties;
}): JSX.Element {
    const { isRealSeries } = useSeriesReality();

    if (isRealSeries) {
        return <></>;
    }

    return (
        <div style={{ ...style, position: 'relative', height: '0px' }}>
            <Row style={{ position: 'absolute', top: '-27px', ...rowStyle }}>
                <BrowserButton color="#F66767" />
                <BrowserButton color="#FADA6A" />
                <BrowserButton color="#73E01E" />
            </Row>
        </div>
    );
}
