import {
    ConfluenceUserSettings,
    IClientUser,
    JiraUserSettings,
    MinimalConfluenceSpace,
    SpinachAPIPath,
} from '@spinach-shared/types';

import { patchSpinachAPI } from '../patchSpinachAPI';

export async function patchConfluenceSpace(
    updatedSelection: MinimalConfluenceSpace
): Promise<{ settings: JiraUserSettings | ConfluenceUserSettings; user: IClientUser }> {
    return await patchSpinachAPI(SpinachAPIPath.ConfluenceSpace, updatedSelection);
}
