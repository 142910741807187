/**
 *
 * @param list a combination of spread arrays of individual items that may contain duplicates of which you want to reduce. Does not work with objects.
 * @returns a list containing unique values
 */
export function getUniques<T>(list: Array<T>): Array<T> {
    return [...new Set(list)];
}

/**
 *
 * @param list1 a list of primitive elements such as strings or numbers
 * @param list2 a different list of primitive elements such as strings or numbers
 * @returns a list of the common elements between the two lists. The length will be 0 if there's no common element.
 */
export function getCommonElements<T extends string | number | boolean>(list1: Array<T>, list2: Array<T>): Array<T> {
    const commonElements = list1.filter((value) => list2.includes(value));
    return commonElements;
}
