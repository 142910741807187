import { atom } from 'recoil';

import { ISOString } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

export type GlobalStoredSeriesList = {
    storedSeriesList: StoredSpinachSeries[];
    lastFetched: ISOString | null;
    isFetching: boolean;
};

export const atomStoredSeriesList = atom<GlobalStoredSeriesList>({
    key: 'storedSeriesList',
    default: {
        storedSeriesList: [],
        lastFetched: null,
        isFetching: false,
    },
});
