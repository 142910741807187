import { DashboardSection } from '@spinach-shared/constants';
import {
    ScribeMeetingType,
    SummaryJson,
    UserNameWithEmailConfidenceMap,
    ViewableTranscriptLineData,
} from '@spinach-shared/types';

export type SummaryWrapper = {
    summaryJson: SummaryJson;
    meetingTime: string;
    meetingTitle: string;
    usernameEmailMapping?: UserNameWithEmailConfidenceMap;
    meetingType: ScribeMeetingType;
    seriesId: string;
};

export type GlobalSearchDataItem = {
    seriesId: string;
    botId: string;
    transcript: ViewableTranscriptLineData[];
    summary: SummaryWrapper;
    createdAt: Date;
};

export type GlobalSearchData = GlobalSearchDataItem[];

export enum GlobalSearchMatchType {
    Transcript = 'transcript',
    Blocker = 'blocker',
    ActionItem = 'actionItem',
    KeyDecision = 'keyDecision',
}

export type GlobalSearchTranscriptMatch = {
    type: GlobalSearchMatchType.Transcript;
    group: ViewableTranscriptLineData[];
};

export type GlobalSearchBlockerMatch = {
    type: GlobalSearchMatchType.Blocker;
    content: string;
    context: string;
};

export type GlobalSearchActionItemMatch = {
    type: GlobalSearchMatchType.ActionItem;
    title: string;
    context: string;
};

export type GlobalSearchKeyDecisionMatch = {
    type: GlobalSearchMatchType.KeyDecision;
    content: string;
    context: string;
};

export type GlobalSearchDetailedMatch =
    | GlobalSearchTranscriptMatch
    | GlobalSearchBlockerMatch
    | GlobalSearchActionItemMatch
    | GlobalSearchKeyDecisionMatch;

export type SearchResult = {
    botId: string;
    matches: number;
    createdAt: Date;
    meetingTitle: string;
    timeOfSelection?: number;
};

export type GlobalSearchUIProps = {
    searchTerm: string;
    setSearchTerm: (term: string) => void;
    searchResults: SearchResult[];
    handleResultClick: (result: SearchResult) => void;
    selectedResult: SearchResult | null;
    detailedMatches: GlobalSearchDetailedMatch[];
    handleMatchClick: (botId: string, section: DashboardSection) => void;
    hideRequestCallback: () => void;
    meetingCount: number;
    isSearching: boolean;
};