export const HALFWAY_TIMECHECK_ID = 'dc383df2-1a00-4c41-b2c9-9869047821ae';
export const FIVE_MINUTES_LEFT_ID = 'f22bd9e0-1a28-4322-919f-d497b605cd42';
export const FAREWELL_INSIGHT_ID = 'dd915e97-8bfd-49e6-b027-d3b0ad828cee';
export const HEY_SPINACH_TIP_INSIGHT_ID = '47086423-5f55-46b3-bd05-11afd15c09d0';
export const INSIGHTS_TIP_INSIGHT_ID = '71d98616-6d38-49d5-8836-17724c165b22';

export enum FacilitatedViewId {
    MeetingPrep = 'briefing',
    Icebreaker = 'icebreaker',
    GapAnalysis = 'gap-analysis',
    Finale = 'finished',
}
