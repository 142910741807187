import React, { CSSProperties, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { ElementId } from '../../constants';

const pulseIn = keyframes`
    0% {
        opacity: 1;
        transform: scale(0);
        transform-origin: center;
    }
    50% {
        opacity: 0.35;
        transform: scale(1)
    }

    70% {
        opacity: 0.35;
        transform: scale(1)
    }
    100% {
        opacity: 0;
        transform: scale(2.5);
        transform-origin: center;
    }
`;
const pulseInAnimation = () => css`
    animation: ${pulseIn} 1300ms ease-in;
    animation-iteration-count: infinite;
`;

const PulsingOrb = styled.div<{ size: number }>`
    background-color: #68e196;
    opacity: 0.5;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    position: absolute;
    border-radius: 50%;
    transform-origin: center;
    pointer-events: none;
    z-index: 10000;

    ${pulseInAnimation()}
`;

const Container = styled.div`
    position: relative;
    width: 0px;
    height: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export function PulsingHint({
    isVisible,
    pulseStyles,
    containerStyles,
    size = 40,
}: {
    isVisible: boolean;
    pulseStyles?: CSSProperties;
    containerStyles?: CSSProperties;
    size?: number;
}): JSX.Element {
    if (!isVisible) {
        return <></>;
    }

    return (
        <Container style={containerStyles}>
            <PulsingOrb size={size} style={pulseStyles} />
            <PulsingOrb size={2 * size} style={pulseStyles} />
        </Container>
    );
}

export const OverlayHintComponent = styled.div`
    position: absolute;
    pointer-events: none;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
`;

export function OverlayHint({
    idOfElement,
    extraHeight = 0,
    extraWidth = 0,
    style = {},
}: {
    idOfElement: string | ElementId;
    extraHeight?: number;
    extraWidth?: number;
    style?: CSSProperties;
}): JSX.Element {
    const [boundingRect, setBoundingRect] = useState<DOMRect | null>(null);
    useEffect(() => {
        const element = document.getElementById(idOfElement);
        if (!element) {
            return;
        }

        const boundingRect = element.getBoundingClientRect();
        setBoundingRect(boundingRect);
    }, []);

    if (!boundingRect) {
        return <></>;
    }

    return (
        <OverlayHintComponent
            style={{
                width: `${boundingRect.width + (extraWidth || 10)}px`,
                height: `${boundingRect.height + (extraHeight || 10)}px`,
                ...style,
            }}
        />
    );
}
