export const onboardingSteps = [
    { name: 'About' },
    { name: 'Connect Calendar' },
    { name: 'Select meetings' },
    { name: 'Integrations' },
    { name: 'Use Spinach' },
];

export const templateVariable = '<ARG>';

const stepTrackerStrings = {
    AboutPageTitle: 'Tell us about yourself',
    HowDidYouHear: 'How did you hear about us?',
    CalendarConnectTitle: `Welcome${templateVariable} 👋 let’s get started!`,
    DaysInTrialTitle: `Your company has ${templateVariable} days of Spinach Pro!`,
    AddToMeetingsTitle: 'Meetings Spinach will join',
    WhoToSendSummariesTo: 'Send Summaries:',
};

const agentStrings: typeof stepTrackerStrings = {
    AboutPageTitle: 'Hey I’m Spinach! 👋 Tell me about yourself.',
    HowDidYouHear: 'How did you hear about me?',
    DaysInTrialTitle: `For the first ${templateVariable} days, I can assist your team free of charge.`,
    CalendarConnectTitle: `Hello${templateVariable}! Excited to get started!`,
    AddToMeetingsTitle: 'What meetings do you want me to join?',
    WhoToSendSummariesTo: 'Who should I send summaries to?',
};

export function getString(key: keyof typeof stepTrackerStrings, isAgentOnboarding: boolean) {
    return isAgentOnboarding ? agentStrings[key] : stepTrackerStrings[key];
}
