import { FeatureFlagSet, FeatureFlagValue, FeatureToggle } from '@spinach-shared/types';

// TODO: Rethink naming as we use non-boolean values in our feature management solution
export function isFeatureEnabled<T extends FeatureFlagValue>(
    featureToggles: FeatureFlagSet<FeatureToggle> | {},
    key: FeatureToggle,
    defaultValue: T
): T {
    return ((featureToggles as FeatureFlagSet<FeatureToggle>)?.[key] as T) ?? defaultValue;
}
