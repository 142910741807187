import { SpinachComponentKind, SpinachList } from '@spinach-shared/types';

/** @PRAGMA - issue-based experiment components below */

/** @TODO remove ADDITIONAL_ITEMS* code after 3/10/2023 */
export const FINISHED_ITEMS_CUSTOM_LIST_ID = 'f483617c-2506-450b-82fd-c017929cb538';
export const PRESENTING_WORKING_ITEMS_CUSTOM_LIST_ID = 'b5edb43b-a2ae-40ae-bafb-285098019616';
export const ADDITIONAL_ITEMS_CUSTOM_LIST_ID = '75cc1591-1b50-4138-9abc-48738f81d0c7';
export const CURRENT_ITEMS_CUSTOM_LIST_ID = 'a0a027a4-83c2-4c40-855d-2a527b915517';

export const ISSUE_BASED_EXPERIMENT_CUSTOM_LIST_IDS = [
    CURRENT_ITEMS_CUSTOM_LIST_ID,
    FINISHED_ITEMS_CUSTOM_LIST_ID,
    PRESENTING_WORKING_ITEMS_CUSTOM_LIST_ID,
    ADDITIONAL_ITEMS_CUSTOM_LIST_ID,
];

export const FINISHED_ITEMS_CUSTOM_LIST: SpinachList = {
    id: FINISHED_ITEMS_CUSTOM_LIST_ID,
    kind: SpinachComponentKind.List,
    title: 'Finished!',
    isHidden: false,
};

export const PRESENTING_WORKING_ITEMS_CUSTOM_LIST: SpinachList = {
    id: PRESENTING_WORKING_ITEMS_CUSTOM_LIST_ID,
    kind: SpinachComponentKind.List,
    title: 'Current Items',
    isHidden: false,
};

export const ADDITIONAL_ITEMS_CUSTOM_LIST: SpinachList = {
    id: ADDITIONAL_ITEMS_CUSTOM_LIST_ID,
    kind: SpinachComponentKind.List,
    title: 'Additional Items',
    isHidden: false,
    placeholder: 'Add an item you are working on',
};

export const CURRENT_ITEMS_CUSTOM_LIST: SpinachList = {
    id: CURRENT_ITEMS_CUSTOM_LIST_ID,
    kind: SpinachComponentKind.List,
    title: 'Your current items',
    isHidden: true,
    placeholder: 'Add an item',
};
