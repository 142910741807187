import { SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import { URLUtil } from '../../utils';

export function jiraAuth(spinachUserId: string, isAi: boolean) {
    URLUtil.openURL(
        `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.JiraAuth}/?${WebUrlQuery.Suid}=${spinachUserId}${
            isAi ? `&${WebUrlQuery.AI}=true` : ''
        }`
    );
}
