import { useEffect } from 'react';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import { useClickTracking } from '../../../../hooks/useExperienceTracking';
import { SearchResult } from './globalSearchTypes';

export const useSearchShortcuts = ({
    inputRef,
    searchResults,
    selectedResult,
    handleResultClick,
    hideRequestCallback,
    handleMatchClick,
}: {
    inputRef: React.MutableRefObject<HTMLInputElement | null>;
    searchResults: SearchResult[];
    selectedResult: SearchResult | null;
    handleResultClick: (result: SearchResult) => void;
    hideRequestCallback: () => void;
    handleMatchClick: (botId: string, section: DashboardSection) => void;
}) => {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const trackClick = useClickTracking();
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'k' && (isMac ? event.metaKey : event.ctrlKey)) {
                trackClick(ClientEventType.AIDashboardClick, 'focus search from keyboard');
                inputRef.current?.focus();
                inputRef.current?.select();
                if (searchResults.length > 0) {
                    handleResultClick(searchResults[0]);
                }
                event.preventDefault();
            }
            // navigate up and down
            if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                event.preventDefault(); // Prevent cursor movement
                if (event.key === 'ArrowUp' && selectedResult) {
                    const index = searchResults.findIndex((r) => r.botId === selectedResult.botId);
                    if (index > 0) {
                        trackClick(ClientEventType.AIDashboardClick, 'go one search result up from keyboard');
                        handleResultClick(searchResults[index - 1]);
                    }
                }
                if (event.key === 'ArrowDown') {
                    const index = searchResults.findIndex((r) => r.botId === selectedResult?.botId);
                    if (selectedResult == null && searchResults.length > 0) {
                        handleResultClick(searchResults[0]);
                    } else if (index < searchResults.length - 1) {
                        trackClick(ClientEventType.AIDashboardClick, 'go one search result down from keyboard');
                        handleResultClick(searchResults[index + 1]);
                    }
                }
            }
            if (event.key === 'Escape') {
                trackClick(ClientEventType.AIDashboardClick, 'close search from keyboard');
                hideRequestCallback();
            }
            if (event.key === 'Enter' && selectedResult) {
                trackClick(ClientEventType.AIDashboardClick, 'open selected search result from keyboard');
                handleMatchClick(selectedResult.botId, DashboardSection.Transcripts);
            }
        };

        const inputElement = inputRef.current;

        if (inputElement) {
            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (inputElement) {
                window.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [isMac, selectedResult, searchResults, handleResultClick, hideRequestCallback, trackClick, handleMatchClick]);
};
