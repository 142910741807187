import { IconButton, TextField } from '@material-ui/core';
import { ChevronLeft, ChevronRight, ExpandMore } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
    AgendaItemBaseRequest,
    AgentTopicObjective,
    AvatarLiveNote,
    AvatarUpsertNoteRequest,
    AvatarUpsertTopicObjective,
    ClientEventType,
    ClientSocketEvent,
    MeetingStatus,
} from '@spinach-shared/types';
import { getUniques } from '@spinach-shared/utils';

import {
    BodyRegular,
    ButtonSize,
    Column,
    GlobalModal,
    HeaderThree,
    PrimaryButton,
    Row,
    createWebsocketPayload,
    lightTheme,
    useActivityTracking,
    useClickTracking,
    useGlobalAuthedUser,
    useGlobalLiveAvatar,
    useGlobalLiveSeries,
    useGlobalModal,
    useSpinachNewInputStyles,
    withContentMasking,
} from '../../../..';
import AvatarProfile from '../../../assets/avatar-profile.png';
import { useGlobalMeetingSocket } from '../../../hooks/useGlobalSocket';
import { StopWatch } from '../../stand-up/ProgressRow';

function FollowingAvatar(props: { isFollowing: boolean; setIsFollowing: (value: boolean) => void }): JSX.Element {
    const trackClick = useClickTracking();
    return (
        <div
            style={{
                position: 'relative',
                background: props.isFollowing ? '#35A289' : '#555555',
                borderRadius: '20px',
                width: '124px',
                height: '26px',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingLeft: '10px',
                cursor: 'pointer',
            }}
            onClick={() => {
                trackClick(ClientEventType.AgentUxClick, 'Toggle Follow Agent Notes', {
                    Action: props.isFollowing ? 'Toggle Following Off' : 'Toggle Following On',
                });
                props.setIsFollowing(!props.isFollowing);
            }}
        >
            <div style={{ fontSize: '12px', fontWeight: 700 }}>
                {props.isFollowing ? 'Following' : 'Click to follow'}
            </div>
            <img src={AvatarProfile} style={{ width: '40px', height: 'auto', position: 'absolute', right: '0px' }} />
        </div>
    );
}

export function LiveNotesHeader(props: {
    isFollowing: boolean;
    setIsFollowing: (value: boolean) => void;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [socket] = useGlobalMeetingSocket();
    const [, setGlobalModal] = useGlobalModal();
    const [liveSeries] = useGlobalLiveSeries();
    const topicsCount = liveSeries.agenda.topicItems.length;
    const hasTopics = !!topicsCount;
    const isOnFirstTopic = !!hasTopics && liveSeries.agenda.topicItems[0].id === liveSeries.currentAgendaItem?.id;
    const isOnLastTopic =
        !!hasTopics && liveSeries.agenda.topicItems[topicsCount - 1]?.id === liveSeries.currentAgendaItem?.id;
    const showStopWatch = liveSeries.meetingStatus === MeetingStatus.AgendaStarted;
    const trackClick = useClickTracking();

    return (
        <Column>
            <Row
                vCenter={true}
                style={{
                    width: 'auto',
                    padding: '20px 20px 0px 20px',
                }}
            >
                <PrimaryButton
                    title={'Open Agenda'}
                    onClick={() => {
                        trackClick(ClientEventType.AgentUxClick, 'Open Agenda Modal');
                        setGlobalModal(GlobalModal.AgentAgenda);
                    }}
                    size={ButtonSize.Small}
                />
                <IconButton
                    size={'small'}
                    disabled={isOnFirstTopic || !hasTopics}
                    onClick={() => {
                        trackClick(ClientEventType.AgentUxClick, 'Previous Agenda Item Attempt');

                        if (!liveSeries.currentAgendaItem) {
                            return;
                        }

                        const agendaNavigationRequest = createWebsocketPayload<AgendaItemBaseRequest>({
                            spinachUserId: user.spinachUserId,
                            meetingId: liveSeries.currentMeeting.id,
                            seriesSlug: liveSeries.slug,
                            itemId: liveSeries.currentAgendaItem?.id,
                        });
                        socket.emit(ClientSocketEvent.AgendaReversing, agendaNavigationRequest);
                    }}
                >
                    <ChevronLeft
                        htmlColor={
                            isOnFirstTopic || !hasTopics ? lightTheme.neutrals.gray : lightTheme.primary.greenLight
                        }
                    />
                </IconButton>
                <IconButton
                    size={'small'}
                    disabled={isOnLastTopic || !hasTopics}
                    onClick={() => {
                        trackClick(ClientEventType.AgentUxClick, 'Next Agenda Item Attempt');
                        if (!liveSeries.currentAgendaItem) {
                            return;
                        }

                        const agendaNavigationRequest = createWebsocketPayload<AgendaItemBaseRequest>({
                            spinachUserId: user.spinachUserId,
                            meetingId: liveSeries.currentMeeting.id,
                            seriesSlug: liveSeries.slug,
                            itemId: liveSeries.currentAgendaItem?.id,
                        });
                        socket.emit(ClientSocketEvent.AgendaProgressing, agendaNavigationRequest);
                    }}
                >
                    <ChevronRight
                        htmlColor={
                            isOnLastTopic || !hasTopics ? lightTheme.neutrals.gray : lightTheme.primary.greenLight
                        }
                    />
                </IconButton>
                <div style={{ display: 'flex', flexGrow: 1 }}></div>
                {showStopWatch ? <StopWatch key={liveSeries.currentAgendaItem?.id ?? ''} series={liveSeries} /> : null}
            </Row>
            <Row
                vCenter={true}
                style={{
                    width: 'auto',
                    padding: '20px 20px 0px 20px',
                }}
            >
                <HeaderThree style={{ textAlign: 'left', fontSize: '26px' }}>Meeting Notes</HeaderThree>
                <div style={{ flex: 1 }}></div>
                <div>
                    {user.isEnabledForLiveNotesV2 ? null : (
                        <FollowingAvatar isFollowing={props.isFollowing} setIsFollowing={props.setIsFollowing} />
                    )}
                </div>
            </Row>
            {liveSeries.currentAgendaItem?.isParticipantAgendaItem === false ? (
                <Row
                    vCenter={true}
                    style={{
                        width: 'auto',
                        padding: '0px 20px 0px 20px',
                    }}
                >
                    <HeaderThree style={{ textAlign: 'left', fontSize: '20px' }}>
                        {liveSeries.currentAgendaItem?.title}
                    </HeaderThree>
                </Row>
            ) : null}
            {user.isEnabledForTopicObjective ? <TopicObjectiveContainer /> : null}
        </Column>
    );
}

function TopicObjectiveContainer(): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();
    const [avatar] = useGlobalLiveAvatar();
    const [user] = useGlobalAuthedUser();
    const classes = useSpinachNewInputStyles({
        value: '',
    });
    const [socket] = useGlobalMeetingSocket();
    const establishedObjectiveForTopic = avatar.topicObjectives?.find(
        (obj) => obj.topicId === liveSeries.currentAgendaItem?.id
    );
    const establishedObjectiveTextForTopic = establishedObjectiveForTopic?.objectiveText;
    const objectiveTextPlaceholder = 'Start by sharing an objective.';

    const [objectiveText, setObjectiveText] = useState(establishedObjectiveTextForTopic || '');
    const trackActivity = useActivityTracking();

    useEffect(() => {
        setObjectiveText(establishedObjectiveTextForTopic || '');
    }, [establishedObjectiveTextForTopic]);

    if (!liveSeries.currentAgendaItem) {
        return <></>;
    }

    function upsertTopicObjective() {
        if (!liveSeries.currentAgendaItem?.id) {
            return;
        }

        trackActivity(ClientEventType.AgentUxActivity, 'User Updated Topic Objective');

        const updatedObjective: AgentTopicObjective = establishedObjectiveForTopic
            ? {
                  ...establishedObjectiveForTopic,
                  objectiveText,
              }
            : {
                  id: uuid(),
                  objectiveText,
                  topicId: liveSeries.currentAgendaItem.id,
              };

        socket.emit(
            ClientSocketEvent.AvatarUpsertTopicObjective,
            createWebsocketPayload<AvatarUpsertTopicObjective>({
                seriesSlug: liveSeries.slug,
                spinachUserId: user.spinachUserId,
                meetingId: liveSeries.currentMeeting.id,
                botId: avatar.botId,
                currentAgendaItemId: liveSeries.currentAgendaItem.id,
                updatedObjective,
                isNew: !establishedObjectiveForTopic,
            })
        );
    }

    return (
        <Row
            style={{
                border: '2px solid lightgray',
                borderRadius: '5px',
                width: 'auto',
                padding: '10px',
                margin: '5px 20px',
            }}
        >
            <Column style={{ textAlign: 'left', fontSize: '18px' }}>
                <b>Objective: </b>
                <TextField
                    InputProps={{
                        classes: { root: classes.base },
                        style: { letterSpacing: '0.3px', fontSize: '18px' },
                    }}
                    multiline={true}
                    {...withContentMasking(classes.root)}
                    fullWidth
                    value={objectiveText}
                    onChange={(e) => {
                        if (e.target.value.includes('\n')) {
                            upsertTopicObjective();
                            return;
                        }

                        setObjectiveText(e.target.value);
                    }}
                    onBlur={() => {
                        upsertTopicObjective();
                    }}
                    placeholder={objectiveTextPlaceholder}
                />
            </Column>
        </Row>
    );
}

function AvatarCursor(): JSX.Element {
    const [ellipsis, setEllipsis] = useState<string>('');
    useEffect(() => {
        const interval = setInterval(() => {
            setEllipsis((prev) => {
                if (prev.length >= 3) {
                    return '';
                }
                return prev + '.';
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [setEllipsis]);
    return (
        <Row style={{ marginTop: '8px', marginLeft: '-25px' }}>
            <img style={{ width: '28px', height: 'auto' }} src={AvatarProfile}></img>
            <div style={{ marginLeft: '6px', fontSize: '18px' }}>{ellipsis}</div>
        </Row>
    );
}
export const LiveNotesContainer = (props: { isFollowing: boolean }): JSX.Element => {
    const [user] = useGlobalAuthedUser();
    const [avatar] = useGlobalLiveAvatar();
    const [liveSeries] = useGlobalLiveSeries();
    const [openDictionary, setIsOpenDictionary] = useState<Record<string, boolean | undefined>>({});
    const [socket] = useGlobalMeetingSocket();
    const trackActivity = useActivityTracking();
    const trackClick = useClickTracking();

    const listEndRef = useRef<HTMLDivElement | null>(null);
    const scrollToBottom = () => {
        if (listEndRef.current) {
            listEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    useEffect(() => {
        if (props.isFollowing) {
            scrollToBottom();
        }
    }, [avatar.liveNotes, props.isFollowing]);

    function upsertUserNote(updatedNote: AvatarLiveNote, isNewNote: boolean = true) {
        if (!liveSeries.currentAgendaItem?.id) {
            return;
        }

        trackActivity(ClientEventType.AgentUxActivity, 'User Added Live Note', {
            isNewNote,
        });

        socket.emit(
            ClientSocketEvent.AvatarUpsertLiveNote,
            createWebsocketPayload<AvatarUpsertNoteRequest>({
                seriesSlug: liveSeries.slug,
                note: updatedNote,
                spinachUserId: user.spinachUserId,
                meetingId: liveSeries.currentMeeting.id,
                botId: avatar.botId,
                currentAgendaItemId: liveSeries.currentAgendaItem.id,
                isNewNote,
            })
        );
    }

    function getColorForTag(tag: string): string {
        if (tag === 'action item') {
            return '#DFF0EA';
        } else if (tag === 'context') {
            return '#EAF2FB';
        } else if (tag === 'blocker') {
            return '#F0DFDF';
        } else {
            return '#FAF2C4';
        }
    }

    const notes = avatar.liveNotes || [];

    const classes = useSpinachNewInputStyles({
        value: '',
    });

    const [newNoteText, setNewNoteText] = useState('');
    // will circle back to this next rev
    // const [editedSpinachText, setEditedSpinachText] = useState('');
    // const [editingNoteId, setEditingNoteId] = useState('');
    const notesForThisTopic = notes.filter((note) => {
        // if no topics, display all notes
        if (
            !liveSeries?.agenda.topicItems.length ||
            !liveSeries.currentAgendaItem ||
            // edge case we want to handle for now - remove when we no longer have to worry about participant agenda items
            liveSeries.currentAgendaItem.isParticipantAgendaItem === true
        ) {
            return true;
        }

        if (!!liveSeries.currentAgendaItem && liveSeries.currentAgendaItem.id === note.relatedAgendaItemId) {
            return true;
        }

        return false;
    });

    return (
        <div style={{ overflowY: 'scroll', flexGrow: 1, padding: '0 40px' }}>
            {notesForThisTopic
                .filter((note, i) => {
                    if (!user.isEnabledForLiveNotesV2) {
                        return true;
                    }
                    const NUM_SPINACH_NOTES_TO_SHOW = 2;
                    const notesFromSpinachForTopic = notesForThisTopic.filter((note) => note.createdBy === 'spinach');
                    // if there are less than 2 notes from spinach for this topic, display all notes, as we want all user-written in this case
                    if (notesFromSpinachForTopic.length <= NUM_SPINACH_NOTES_TO_SHOW) {
                        return true;
                    }

                    // if there are more than 2 spinach notes, display last 2 + any user notes that came after second to last.
                    const lastTwoSpinachNotes = notesFromSpinachForTopic.slice(-NUM_SPINACH_NOTES_TO_SHOW);
                    const secondToLastNoteId = lastTwoSpinachNotes[0].id;
                    const indexThreshold = notesForThisTopic.findIndex((note) => note.id === secondToLastNoteId);

                    return i >= indexThreshold;
                })
                .map((note) => {
                    const isOpen = !!openDictionary[note.id];
                    const tagsFound = getUniques(note.details?.map((d) => d.kind) || []);
                    const validatedDetails = note.details?.filter((d) => !!d.kind && !!d.description);
                    return (
                        <Column key={note.id} style={{ marginBottom: '15px' }}>
                            <Row style={{ marginBottom: '5px', marginTop: '5px' }}>
                                <BodyRegular style={{ textAlign: 'left', fontSize: '18px' }}>
                                    {note.context}
                                </BodyRegular>
                                {/* <TextField
                                    InputProps={{
                                        classes: { root: classes.base },
                                        style: { letterSpacing: '0.3px', fontSize: '18px' },
                                    }}
                                    // style={{ maxHeight: '60px', overflowY: 'scroll' }}
                                    multiline={true}
                                    {...withContentMasking(classes.root)}
                                    fullWidth
                                    value={editingNoteId === note.id ? editedSpinachText || note.context : note.context}
                                    // autoFocus={true}
                                    onChange={(e) => {
                                        if (e.target.value.includes('\n')) {
                                            upsertUserNote(
                                                {
                                                    ...note,
                                                    context: editedSpinachText,
                                                },
                                                false
                                            );
                                            setEditedSpinachText('');
                                            setEditingNoteId('');
                                            return;
                                        }

                                        setEditedSpinachText(e.target.value);
                                        setEditingNoteId(note.id);
                                    }}
                                    onBlur={(e) => {
                                        upsertUserNote(
                                            {
                                                ...note,
                                                context: editedSpinachText,
                                            },
                                            false
                                        );
                                        setEditedSpinachText('');
                                        setEditingNoteId('');
                                    }}
                                    placeholder={'Deleted note...'}
                                /> */}
                            </Row>
                            {user.isEnabledForLiveNotesV2 && tagsFound.length ? (
                                <Column>
                                    <Row>
                                        <ExpandMore
                                            onClick={() => {
                                                trackClick(ClientEventType.AgentUxClick, 'Toggle Notes Details', {
                                                    Action: openDictionary[note.id] ? 'Close' : 'Open',
                                                });
                                                setIsOpenDictionary((prev) => ({
                                                    ...prev,
                                                    [note.id]: !prev[note.id],
                                                }));
                                            }}
                                            style={{
                                                transition: '100ms',
                                                transform: !isOpen ? 'rotate(270deg)' : 'rotate(360deg)',
                                                cursor: 'pointer',
                                            }}
                                        />
                                        {tagsFound.map((tag) => (
                                            <span
                                                key={tag}
                                                style={{
                                                    fontWeight: 'bold',
                                                    borderRadius: '5px',
                                                    padding: '3px 5px',
                                                    backgroundColor: getColorForTag(tag),
                                                    marginRight: '3px',
                                                    marginLeft: '3px',
                                                    color: 'rgba(1, 1, 1, 0.5)',
                                                }}
                                            >
                                                {tag}
                                            </span>
                                        ))}
                                    </Row>
                                    {isOpen ? (
                                        <Row
                                            style={{
                                                marginTop: '10px',
                                                width: 'calc(100% - 10px)',
                                                marginLeft: '10px',
                                            }}
                                        >
                                            <Column>
                                                {validatedDetails?.map((detail, i) => (
                                                    <Row
                                                        key={i}
                                                        style={{
                                                            borderLeft: `4px solid ${getColorForTag(detail.kind)}`,
                                                            paddingLeft: '5px',
                                                            marginTop: '5px',
                                                            marginBottom: '5px',
                                                        }}
                                                    >
                                                        <BodyRegular style={{ fontSize: '18px', textAlign: 'left' }}>
                                                            {detail.description}
                                                        </BodyRegular>
                                                    </Row>
                                                ))}
                                            </Column>
                                        </Row>
                                    ) : null}
                                </Column>
                            ) : null}
                        </Column>
                    );
                })}

            {user.isEnabledForLiveNotesV2 ? (
                <TextField
                    InputProps={{
                        classes: { root: classes.base },
                        style: { letterSpacing: '0.3px', fontSize: '18px' },
                    }}
                    multiline={true}
                    {...withContentMasking(classes.root)}
                    fullWidth
                    value={newNoteText}
                    onChange={(e) => {
                        if (e.target.value.includes('\n')) {
                            upsertUserNote({
                                context: newNoteText,
                                id: uuid(),
                                relatedAgendaItemId: liveSeries.currentAgendaItem?.id,
                                createdBy: user.spinachUserId,
                            });
                            setNewNoteText('');
                            return;
                        }

                        setNewNoteText(e.target.value);
                    }}
                    onBlur={(e) => {
                        upsertUserNote({
                            context: newNoteText,
                            id: uuid(),
                            relatedAgendaItemId: liveSeries.currentAgendaItem?.id,
                            createdBy: user.spinachUserId,
                        });
                        setNewNoteText('');
                    }}
                    placeholder={'Add a note'}
                />
            ) : null}

            <div ref={listEndRef} />
            <AvatarCursor />
        </div>
    );
};
