import { useEffect, useState } from 'react';

import { FeatureDiscoveryKey } from '@spinach-shared/types';

import { useSeriesReality } from '../../../hooks';
import { AsanaIntegrationFeatureCallout, useAsanaIntegrationCallout } from './AsanaIntegrationFeatureCallout';
import { GenericFeatureCallout, useGenericCallout } from './GenericFeatureCallout';
import { PayUpFeatureCallout, usePayUpCallout } from './PayUpFeatureCallout';
import { ReferralFeatureCallout, useSpinachReferralCallout } from './ReferralFeatureCallout';

/**
 *
 * @returns at most, a single feature callout. If no callouts apply to the user, nothing is rendered.
 * Once a callout is hidden, either for being closed or engaged with, the next available callout should not
 * show until the next lobby mount.
 *
 * To add a callout, follow the pattern for JiraIntegration:
 * 1. Create a wrapper of the FeatureCallout component
 * 2. Create a custom hook to provide the visibility rule and click callbacks
 * 3. Invoke the hook in the section of custom hooks below
 * 4. Add the props response from the hook into the `calloutPropsPrecedence` array
 * 5. Add the callout component into the switch statement at the bottom
 */

export function FeatureCalloutContainer(): JSX.Element {
    const { isDemoSeries } = useSeriesReality();

    /**
     * STEP 3: invoke the custom hooks for your new callout
     */
    const asanaIntegrationProps = useAsanaIntegrationCallout();
    const referralProps = useSpinachReferralCallout();
    const payUpProps = usePayUpCallout();
    const genericProps = useGenericCallout();

    /**
     * STEP 4:
     * Set precedence of callouts and pick the first one that is still visible.
     * This selected callout will be the only one that can be visible while this lobby is mounted.
     * If none are visible, or we're in demo mode, return an empty fragment
     */
    const calloutPropsPrecedence = [payUpProps, genericProps, asanaIntegrationProps, referralProps];

    const nextVisibleCalloutIfAny = calloutPropsPrecedence.find((props) => props.isVisible === true);
    const [initialCallout, setInitialCallout] = useState(nextVisibleCalloutIfAny);

    /**
     * If the user triggers some action that renders the first callout for this mount
     * We do want to display that, even though we mounted without a callout
     */
    useEffect(() => {
        if (initialCallout === undefined && nextVisibleCalloutIfAny) {
            setInitialCallout(nextVisibleCalloutIfAny);
        }
    }, [nextVisibleCalloutIfAny]);

    /**
     * If the user has interacted with the initial callout and hidden it,
     * we want to hide it without displaying the next one in the precedence
     */
    if (
        isDemoSeries ||
        !initialCallout ||
        initialCallout.feature !== nextVisibleCalloutIfAny?.feature ||
        initialCallout.isVisible !== nextVisibleCalloutIfAny?.isVisible
    ) {
        return <></>;
    }

    switch (initialCallout.feature) {
        case FeatureDiscoveryKey.AsanaIntegration:
            return (
                <AsanaIntegrationFeatureCallout
                    onCloseClick={asanaIntegrationProps.onCloseClick}
                    onEngageClick={asanaIntegrationProps.onEngageClick}
                />
            );
        case FeatureDiscoveryKey.SpinachReferral:
            return (
                <ReferralFeatureCallout
                    onCloseClick={referralProps.onCloseClick}
                    onEngageClick={referralProps.onEngageClick}
                />
            );
        case FeatureDiscoveryKey.PayUp:
            return (
                <PayUpFeatureCallout onCloseClick={payUpProps.onCloseClick} onEngageClick={payUpProps.onEngageClick} />
            );
        case FeatureDiscoveryKey.Generic:
            return (
                <GenericFeatureCallout
                    onCloseClick={genericProps.onCloseClick}
                    onEngageClick={genericProps.onEngageClick}
                />
            );
        default:
            return <></>;
    }
}
