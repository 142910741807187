import { atom } from 'recoil';

import { SpinachSeriesProps } from '@spinach-shared/models';
import { StoredSpinachSeries } from '@spinach-shared/utils';

export const atomLiveSeries = atom<SpinachSeriesProps | null>({
    key: 'liveSeries', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

export const atomStoredSeries = atom<StoredSpinachSeries | undefined | null>({
    key: 'storedSeries',
    default: null,
});
