import React, { useCallback } from 'react';

import { getCommandContents } from '@spinach-shared/models';
import { PostVideoAgentChatMessageRequest } from '@spinach-shared/types';

import { postVideoAgentChatMessage } from '../../../..';
import { AgentCommandType, SayFunction } from '../../../types/agent';
import { useGlobalVideoAgent } from '../useGlobalVideoAgent';

export function isCommandViewerCommand(message: string): boolean {
    const validCommands = ['command', 'commands', '/commands', '/command'];
    const { commandMatched: isNextTopicTrigger } = getCommandContents(message, validCommands);

    return !!isNextTopicTrigger;
}

export function useCommandViewer(say: SayFunction) {
    const { state, setState, session } = useGlobalVideoAgent();
    const { config } = state;

    // TODO pass in voice or chat trigger source
    return useCallback(
        async (triggerMedium = AgentCommandType.Voice) => {
            if (state.isShowingCommandsView) {
                setState((prev) => ({
                    ...prev,
                    isShowingCommandsView: false,
                }));
            } else {
                setState((prev) => ({
                    ...prev,
                    isShowingCommandsView: true,
                }));

                if (!session) {
                    return;
                }

                const message = `You can use these Spinach chat commands:\n${config.chatCommandInstructions.join(
                    '\n'
                )}`;

                const chatPayload: PostVideoAgentChatMessageRequest = {
                    message,
                    botId: session.botId,
                };
                await postVideoAgentChatMessage(chatPayload);
            }
        },
        [config.chatCommandInstructions, session, setState, state.isShowingCommandsView]
    );
}
