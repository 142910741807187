import { Modal } from '@material-ui/core';

import { ClientEventType } from '@spinach-shared/types';

import { GlobalModal, GlobalModalMetadataType } from '../../../atoms';
import { useExperienceTracking, useGlobalModal, useGlobalModalState } from '../../../hooks';
import { LocalStorageKeys, useLocalStorage } from '../../../hooks/useLocalStorage';
import { BodyRegular, ResponsiveModalTitle } from '../../../styles';
import { URLUtil } from '../../../utils';
import { PrimaryButton } from '../../stand-up';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

export const SSOProvisioningInstructionsModal = () => {
    const [globalModal, setGlobalModal] = useGlobalModal();
    const [, setHasUserSeenProvisioningInstructions] = useLocalStorage(
        LocalStorageKeys.HasUserSeenProvisioningInstructions,
        false
    );
    const { metadata } = useGlobalModalState();

    const track = useExperienceTracking();

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close SSO Provisioning Instructions Modal',
        });
        setHasUserSeenProvisioningInstructions(true);
        setGlobalModal(null);
    };

    if (metadata?.metadataType !== GlobalModalMetadataType.SSOProvisioningInstructions) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.SSOProvisioningInstructions} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden' }}>
                <Column centered>
                    <Row centered>
                        <ResponsiveModalTitle>Here's how to get started</ResponsiveModalTitle>
                    </Row>
                    <Spacing factor={1 / 3} />
                </Column>

                <Column style={{ alignItems: 'start' }}>
                    <BodyRegular>
                        1. Join the{' '}
                        <span
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() =>
                                URLUtil.openURL(`https://slack.com/app_redirect?channel=${metadata.slackChannelId}`)
                            }
                        >
                            {metadata.slackChannelName}
                        </span>{' '}
                        Slack channel.
                    </BodyRegular>
                    <Spacing factor={1 / 3} />
                    <BodyRegular>
                        2.{' '}
                        <span
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => {
                                URLUtil.openURL(metadata.userProvisioningLink);
                            }}
                        >
                            Request licenses for your team
                        </span>
                        .
                    </BodyRegular>
                    <Spacing factor={1 / 3} />
                    <BodyRegular>3. Log in once you’ve been provisioned.</BodyRegular>
                </Column>
                <span style={{ flexGrow: 1 }} />
                <PrimaryButton
                    title="Sign in with SSO"
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Sign in with SSO',
                            From: 'SSO Provisioning Instructions Modal',
                        });
                        closeModal();
                    }}
                />
            </SpinachModalContent>
        </Modal>
    );
};
