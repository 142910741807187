import { Tooltip, TooltipProps } from '@material-ui/core';
import styled from 'styled-components';

const CustomTooltip = styled(Tooltip)`
    width: fit-content;
    z-index: 100000;
    position: fixed;
    background: #424242;
    border-radius: 6px;
    pointer-events: unset;
    max-width: 90%;
`;

export const FeatureTooltipCallout = ({
    id,
    children,
    style,
    ...props
}: {
    children: JSX.Element;
} & Partial<Omit<TooltipProps, 'ref' | 'classes'>>) => {
    return (
        <CustomTooltip title={children} style={{ ...style }} id={id} placement="bottom-start" {...props}>
            <div></div>
        </CustomTooltip>
    );
};
