import { EmojiData } from 'emoji-mart';

export const emojiMapWithCategories: Record<string, Record<string, EmojiData>> = {
    Emotion: {
        smiley: {
            id: 'smiley',
            name: 'Smiling face with Open Mouth',
            short_names: ['smiley'],
            colons: ':smiley:',
            emoticons: [],
            unified: '1F603',
            skin: null,
            native: '😃',
        },
        joy: {
            id: 'joy',
            name: 'Face with Tears of Joy',
            short_names: ['joy'],
            colons: ':joy:',
            emoticons: [],
            unified: '1f602',
            skin: null,
            native: '😂',
        },
        sunglasses: {
            id: 'sunglasses',
            name: 'Smiling Face with Sunglasses',
            short_names: ['sunglasses'],
            colons: ':sunglasses:',
            emoticons: [],
            unified: '1F60E',
            skin: 1,
            native: '😎',
        },
        cry: {
            id: 'cry',
            name: 'Crying Face',
            short_names: ['cry'],
            colons: ':cry:',
            emoticons: [],
            unified: '1F622',
            skin: 1,
            native: '😢',
        },
    },
    Love: {
        raised_hands: {
            id: 'raised_hands',
            name: 'Person Raising Both Hands in Celebration',
            short_names: ['raised_hands'],
            colons: ':raised_hands:',
            emoticons: [],
            unified: '1f64c',
            skin: 1,
            native: '🙌',
        },
        clap: {
            id: 'clap',
            name: 'Clapping Hands Sign',
            short_names: ['clap'],
            colons: ':clap:',
            emoticons: [],
            unified: '1f44f',
            skin: 1,
            native: '👏',
        },
        tada: {
            id: 'tada',
            name: 'Party Popper',
            short_names: ['tada'],
            colons: ':tada:',
            emoticons: [],
            unified: '1f389',
            skin: null,
            native: '🎉',
        },
        green_heart: {
            id: 'green_heart',
            name: 'Green Heart',
            short_names: ['green_heart'],
            colons: ':green_heart:',
            emoticons: [],
            unified: '1F49A',
            skin: null,
            native: '💚',
        },
    },
    Hype: {
        fire: {
            id: 'fire',
            name: 'Fire',
            short_names: ['fire'],
            colons: ':fire:',
            emoticons: [],
            unified: '1F525',
            skin: null,
            native: '🔥',
        },
        boom: {
            id: 'boom',
            name: 'Collision Symbol',
            short_names: ['boom'],
            colons: ':boom:',
            emoticons: [],
            unified: '1F4A5',
            skin: null,
            native: '💥',
        },
        rocket: {
            id: 'rocket',
            name: 'Rocket',
            short_names: ['rocket'],
            colons: ':rocket:',
            emoticons: [],
            unified: '1F680',
            skin: null,
            native: '🚀',
        },
        dart: {
            id: 'dart',
            name: 'Direct Hit',
            short_names: ['dart'],
            colons: ':dart:',
            emoticons: [],
            unified: '1F3AF',
            skin: null,
            native: '🎯',
        },
        '100': {
            id: '100',
            name: 'Hundred Points Symbol',
            short_names: ['100'],
            colons: ':100:',
            emoticons: [],
            unified: '1F4AF',
            skin: null,
            native: '💯',
        },
    },

    Seasonal: {
        snowman: {
            id: 'snowman',
            name: 'Snowman',
            short_names: ['snowman'],
            colons: ':snowman:',
            emoticons: [],
            unified: '26C4',
            skin: null,
            native: '⛄️',
        },
        cookie: {
            id: 'cookie',
            name: 'Cookie',
            short_names: ['cookie'],
            colons: ':cookie:',
            emoticons: [],
            unified: '1F36A',
            skin: null,
            native: '🍪',
        },
        coffee: {
            id: 'coffee',
            name: 'Hot Beverage',
            short_names: ['coffee'],
            colons: ':coffee:',
            emoticons: [],
            unified: '2615',
            skin: null,
            native: '☕️',
        },
        scarf: {
            id: 'scarf',
            name: 'Scarf',
            short_names: ['scarf'],
            colons: ':scarf:',
            emoticons: [],
            unified: '1F9E3',
            skin: null,
            native: '🧣',
        },
        cold_face: {
            id: 'cold_face',
            name: 'Cold Face',
            short_names: ['cold_face'],
            colons: ':cold_face:',
            emoticons: [],
            unified: '1F976',
            skin: null,
            native: '🥶',
        },
    },
    Voting: {
        '+1': {
            id: '+1',
            name: 'Thumbs Up Sign',
            short_names: ['+1', 'thumbsup'],
            colons: ':+1:',
            emoticons: [],
            unified: '1f44d',
            skin: 1,
            native: '👍',
        },
        '-1': {
            id: '-1',
            name: 'Thumbs Down Sign',
            short_names: ['-1', 'thumbsdown'],
            colons: ':-1:',
            emoticons: [],
            unified: '1F44E',
            skin: 1,
            native: '👎',
        },
    },
};

export const emojiMap: Record<string, EmojiData> = Object.values(emojiMapWithCategories).reduce((acc, curr) => {
    acc = {
        ...acc,
        ...Object.entries(curr).reduce((acc, [key, emoji]) => {
            acc[key] = emoji;
            return acc;
        }, {} as Record<string, EmojiData>),
    };
    return acc;
}, {} as Record<string, EmojiData>);

export const emojiList = Object.values(emojiMap);
