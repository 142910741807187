import zoomSdk from '@zoom/appssdk';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getZoomCollab } from '../apis';
import { ClientPath } from '../components';
import { isZoomPlatform } from '../utils';
import { useGlobalRouting } from './useGlobalRouting';

/**
 * This redirects a joiner to a lobby if theyre zoom client missed the collaborate join event
 * due to the event listener being registered too late
 */
export function useZoomCollabRedirect() {
    const location = useLocation();
    const { routeToSeriesExperience } = useGlobalRouting();

    useEffect(() => {
        if (!isZoomPlatform()) {
            return;
        }

        async function runningContext() {
            const context = await zoomSdk.getRunningContext();
            if (context.context === 'inCollaborate') {
                const meeting = await zoomSdk.getMeetingUUID();

                const collabDetails = await getZoomCollab(meeting.meetingUUID);

                if (collabDetails?.seriesId) {
                    routeToSeriesExperience(collabDetails.seriesId);
                }
            }
        }
        if (location.pathname === ClientPath.Root) {
            runningContext();
        }
    }, []);
}
