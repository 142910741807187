import MoreVertIcon from '@material-ui/icons/MoreVert';
import styled from 'styled-components';

import { responsiveness } from '../../styles/responsiveness';
import { BodyLarge, BodyRegular, BodySubtitle } from '../../styles/typography';
import { Row } from '../common/framing';
import { ScrollArea } from '../stand-up/ScrollArea';

// TODO: use isHovered to handle box-shadow
export const SeriesCard = styled.div`
    border-radius: 15px;
    text-align: center;
    width: 30%;
    min-height: 110px;
    max-height: 150px;
    min-width: 110px;
    display: flex;
    margin: 6px;
    z-index: 1;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;

    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.15);

    transition: 0.2s;

    &:hover {
        cursor: pointer;
        box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.15);
    }

    @media ${responsiveness.thinnerThanXS} {
        width: 45%;
    }
`;

export const SubtitleCardOptionItem = styled(BodySubtitle)<{ fontWeight?: number; fontSize?: number }>`
    margin-left: 10px;
    font-weight: ${(props) => props.fontWeight ?? 600};
`;

export const RegularCardOptionItem = styled(BodyRegular)<{ fontWeight?: number; fontSize?: number }>`
    font-weight: ${(props) => props.fontWeight ?? 700};
    user-select: none;
    text-align: left;
`;

export const OptionRow = styled(Row)<{ isLastRow?: boolean }>`
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 230px;
    border-radius: 8px;
    align-items: center;
    border-bottom: ${(props) => (props.isLastRow ? 'initial' : `1px solid  ${props.theme.neutrals.grayDark}`)};

    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.neutrals.gray};
    }
`;

export const Blurred = styled(BodyRegular)`
    text-shadow: 0 0 9px black;
    color: transparent;
`;

export const DisabledOptionRow = styled(OptionRow)`
    cursor: initial;

    &:hover {
        background-color: initial;
    }
`;

export const SeriesOptionsButtonContainer = styled.span<{ isHovered?: boolean }>`
    height: 100%;
    z-index: -1;
    align-items: flex-start;
    width: 0px;
    background-color: ${(props) => props.theme.neutrals.offWhite};
    border-radius: 40px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
`;

export const SeriesOptionsButton = styled(MoreVertIcon)`
    z-index: 1000;
    margin-top: 5px;
    margin-right: -30px;
    color: gray;
    background-color: ${(props) => props.theme.neutrals.offWhite};
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.neutrals.gray};
    }
`;

export const ScrollView = styled(ScrollArea)`
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 5px;
    justify-content: space-around;
    align-content: flex-start;
`;

export const CreateCard = styled(SeriesCard)`
    border: 1px dashed #d9d9d9;
`;

export const ModalContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 250px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: calc(0.05 * 70vmin);
    border-radius: 15px;
`;

export const ResponsiveModalContent = styled(ModalContent)`
    max-width: 450px;
    min-width: 450px;
    min-height: 400px;
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: scroll;

    @media ${responsiveness.thinnerThanXS} {
        max-width: unset;
        min-width: 90%;
    }

    @media ${responsiveness.vxsm} {
        min-height: 90%;
    }

    @media ${responsiveness.thinnerThanSM} {
        max-width: unset;
        min-width: unset;
        width: 90%;
    }
`;

export const ErrorBodySubtitle = styled(BodySubtitle)`
    align-self: flex-start;
    color: ${(props) => props.theme.primary.orangeDark};
    height: 18px;
    width: 100%;
    flex-shrink: 0;
`;

export const LeftBodyLarge = styled(BodyLarge)`
    align-self: flex-start;
`;

export const UnflexRow = styled(Row)`
    flex: unset;
`;

export const CenteredRow = styled(UnflexRow)`
    justify-content: center;
`;
