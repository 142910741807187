import moment from 'moment';
import styled from 'styled-components';

import { FeatureToggle } from '@spinach-shared/types';

import { ReactComponent as GreenCheckIcon } from '../../../../assets/green-circle-check.svg';
import { ReactComponent as GreyCheckIcon } from '../../../../assets/grey-circle-check.svg';
import { useGlobalAuthedUser, useProAiPlanFeatureList } from '../../../../hooks';
import { BodyRegularOnboard, HeaderThree, lightTheme } from '../../../../styles';
import { Column, Row, Spacing } from '../../../common';
import { PromoContainer } from '../../../common/PromoContainer';
import { PrimaryButton } from '../../../stand-up';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';

const BannerContainer = styled(PromoContainer)`
    width: 100%;
    .celebrate-pro-account-animation {
        position: absolute;
        height: 400px !important;
        top: -50%;
        left: 0;
        right: 0;
        margin: auto;
        pointer-events: none;
    }
`;

const UsersExceededBannerContainer = styled.div`
    border-radius: 6px;
    border: 1px solid #c3e0d7;
    background: #fff0da;
    padding: 23px 62px;
    position: relative;
    min-height: 129px;
    width: 85%;
    box-sizing: border-box;
    display: flex;
`;

export function SummaryProcessingDisabledBanner() {
    const [user] = useGlobalAuthedUser();

    if (!user.isSummaryProcessingDisabled) {
        return <></>;
    }

    if (!!user.isPersonalUserTrialExpired) {
        return <PersonalUserTrialEndedBanner />;
    }

    if (user.isExceedingUsageLimits) {
        return <ExceededLimitedUsageForCycleBanner />;
    }

    if (!!user.featureToggles[FeatureToggle.DisableScribeProcessing] && !user.metadata.isPaidAi) {
        return <UsersExceededBanner />;
    }

    return <></>;
}

function UsersExceededBanner() {
    return (
        <SpinachBlockedBanner
            title="You've exceeded 50 users"
            text="The free Spinach Starter plan supports up to 50 users. Upgrade to Spinach Pro to resume meeting
            processing."
        />
    );
}

function PersonalUserTrialEndedBanner() {
    return (
        <SpinachBlockedBanner
            title="Your Spinach Pro trial has ended"
            text="Please upgrade to continue using Spinach or sign up with a company email."
        />
    );
}

function ExceededLimitedUsageForCycleBanner() {
    const [user] = useGlobalAuthedUser();
    const dateOfCycleStart = moment(user.reverseTrialStartDate).add(user.reverseTrialLengthInDays, 'days').date();
    const nextCycleStartDateFormatted = moment().add(1, 'month').date(dateOfCycleStart).format('MMMM Do');

    const continuingAction = user.isEligibleForPartialCappedSummary ? 'processing meetings' : 'using Spinach';

    return (
        <SpinachBlockedBanner
            title="You’re out of meeting hours for the month"
            text={`Please upgrade to continue ${continuingAction}. Your cycle will refresh on ${nextCycleStartDateFormatted}.`}
        />
    );
}

function SpinachBlockedBanner({ title, text }: { title: string; text: string }) {
    const onClick = useNavigateToUpgrade();
    return (
        <UsersExceededBannerContainer>
            <Column>
                <Row
                    style={{
                        width: 'fit-content',
                        padding: '4px 8px',
                        backgroundColor: lightTheme.secondary.orangeDark,
                        borderRadius: '3px',
                    }}
                >
                    <BodyRegularOnboard
                        style={{ fontSize: '11px', fontWeight: '700', color: lightTheme.neutrals.white }}
                    >
                        Action Needed
                    </BodyRegularOnboard>
                </Row>
                <Spacing factor={1 / 5} />

                <BodyRegularOnboard style={{ fontSize: '22px', fontWeight: '700' }}>{title}</BodyRegularOnboard>

                <Spacing factor={1 / 5} />
                <BodyRegularOnboard>{text}</BodyRegularOnboard>
            </Column>
            <Column style={{ width: 'fit-content', justifyContent: 'center' }}>
                <Row style={{ width: 'fit-content' }}>
                    <PrimaryButton title={'Upgrade to Pro'} onClick={() => onClick('Exceeded Users Banner')} />
                </Row>
            </Column>
        </UsersExceededBannerContainer>
    );
}

function PlanBanner(props: {
    title?: string;
    checkIcon: JSX.Element;
    onRestartPlanClick?: () => void;
    isNavigatingToCustomerPortal: boolean;
}) {
    const features = useProAiPlanFeatureList();
    const half = Math.ceil(features.length / 2);
    return (
        <BannerContainer id="banner-container">
            {!!props.title ? (
                <Row style={{ zIndex: 1 }}>
                    <HeaderThree>{props.title}</HeaderThree>
                </Row>
            ) : null}
            <Spacing factor={1 / 2} />
            <Row style={{ flexWrap: 'wrap' }}>
                <div style={{ flex: '1', minWidth: '370px' }}>
                    {features.slice(0, half).map((feature, index) => (
                        <>
                            <Row key={index}>
                                {props.checkIcon}
                                <BodyRegularOnboard>{feature}</BodyRegularOnboard>
                            </Row>
                            <Spacing factor={1 / 2} />
                        </>
                    ))}
                </div>
                <div style={{ flex: '1', minWidth: '370px' }}>
                    {features.slice(half).map((feature, index) => (
                        <>
                            <Row key={index}>
                                {props.checkIcon}
                                <BodyRegularOnboard>{feature}</BodyRegularOnboard>
                            </Row>
                            <Spacing factor={1 / 2} />
                        </>
                    ))}
                </div>
            </Row>
            {!!props.onRestartPlanClick ? (
                <>
                    <Spacing />
                    <Row style={{ justifyContent: 'center' }}>
                        <PrimaryButton
                            style={{ paddingLeft: '73px', paddingRight: '73px' }}
                            title={'Restart Your Plan'}
                            onClick={() => props.onRestartPlanClick!()}
                            isLoading={props.isNavigatingToCustomerPortal}
                        />
                    </Row>
                    <Spacing />
                </>
            ) : null}
        </BannerContainer>
    );
}

export function FreePlanBanner(props: { onRestartPlanClick?: () => void; isNavigatingToCustomerPortal: boolean }) {
    return (
        <PlanBanner
            title="You are currently on the Starter plan. Upgrade to get:"
            checkIcon={<GreyCheckIcon style={{ width: '18px', height: '18px', marginRight: '16px' }} />}
            onRestartPlanClick={props.onRestartPlanClick}
            isNavigatingToCustomerPortal={props.isNavigatingToCustomerPortal}
        />
    );
}
export function ProBanner(props: { isNavigatingToCustomerPortal: boolean }) {
    return (
        <PlanBanner
            isNavigatingToCustomerPortal={props.isNavigatingToCustomerPortal}
            checkIcon={<GreenCheckIcon style={{ width: '18px', height: '18px', marginRight: '16px' }} />}
        />
    );
}
