import { SpinachAPIPath, SummaryJson } from '@spinach-shared/types';

import { ClientLogger, postSpinachAPI } from '..';

export async function postEditedSummary(request: {
    summaryJSON: SummaryJson;
    botId: string;
}): Promise<{ success: boolean }> {
    try {
        const response = await postSpinachAPI<{ success: boolean }>(SpinachAPIPath.EditSummary, request);
        if (!response) {
            return { success: false };
        }
        return response;
    } catch (e) {
        ClientLogger.error('failed to postEditedSummary', e);
        return { success: false };
    }
}
