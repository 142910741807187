import styled from 'styled-components';

import { responsiveness } from '../../styles/responsiveness';

export const SpinachStationary = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 50%;
    max-height: 95%;
    z-index: 300;
    position: relative;
    filter: drop-shadow(2px 2px 14px rgba(0, 0, 0, 0.25));
    border-radius: 32px;
    background-color: white;
    padding: calc(0.05 * 70vmin);

    @media ${responsiveness.thinnerThanSM} {
        width: 83%;
    }

    @media ${responsiveness.sm} {
        width: 450px;
        margin: auto;
    }

    @media ${responsiveness.lg} {
        width: 600px;
    }
`;
