import { Modal } from '@material-ui/core';
import { useState } from 'react';
import styled from 'styled-components';

import { AgendaItemBaseRequest, ClientSocketEvent } from '@spinach-shared/types';

import { ElementId } from '../../constants';
import { useGlobalDemoState, useGlobalNullableLiveSeries, usePersonaDemo, useSeriesReality } from '../../hooks';
import { BodyLarge, BodyRegular } from '../../styles';
import { ActiveAgendaViewProps } from '../../types';
import { createWebsocketPayload } from '../../utils';
import { CloseButton, OverlayHint, PulsingHint, Spacer, Spacing } from '../common';
import { TeamTopicsByParticipant, TeamTopicsForParticipantProps } from './ParkingLotItemsByParticipant';
import { PrimaryButton } from './PrimaryButton';
import { ScrollArea } from './ScrollArea';
import SecondaryButton from './SecondaryButton';
import StandUpFooter from './StandUpFooter';
import { StandUpHeader } from './StandUpHeader';

const ModalContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 250px;
    max-width: 300px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: calc(0.05 * 70vmin);
    border-radius: 15px;
`;

const Content = styled(ModalContent)`
    min-width: 280px;
`;

const CenteredContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    flex-shrink: 0;
    justify-content: space-between;
    align-content: center;
    text-align: center;
`;

function ParkingLotView({
    seriesSlug,
    spinachParticipant,
    agenda,
    socket,
    cooldownProps,
}: ActiveAgendaViewProps): JSX.Element {
    const [liveSeries] = useGlobalNullableLiveSeries();
    const { focusedFeature } = useGlobalDemoState();
    const { isDemoSeries } = useSeriesReality();
    const isPersonaDemo = usePersonaDemo();
    const { bypassCooldown, isNavigationDisabled, cooldown } = cooldownProps;
    const { currentItem } = agenda;
    const { spinachUserId } = spinachParticipant;
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const backButtonText = agenda.currentIndex === 0 ? '' : `Go back`;

    const agendaNavigationRequest = createWebsocketPayload<AgendaItemBaseRequest>({
        spinachUserId: spinachParticipant.spinachUserId,
        meetingId: liveSeries?.currentMeeting.id,
        seriesSlug,
        itemId: currentItem.id,
    });

    const teamTopicsProps: TeamTopicsForParticipantProps = {
        seriesSlug,
        spinachUserId,
        socket,
    };

    const endMeeting = () => {
        bypassCooldown();
        socket.emit(ClientSocketEvent.AgendaProgressing, agendaNavigationRequest);
    };

    const isEndButtonHighlightedForDemo = focusedFeature === ElementId.EndMeetingFromTeamTopics && isDemoSeries; // && !user.hasPlayedAtLeastOnePracticeRound;

    return (
        <>
            <StandUpHeader title={currentItem.title} id="team-topics-title" />

            <Modal
                open={showConfirmationModal}
                onClose={() => {
                    setShowConfirmationModal(false);
                }}
            >
                <Content>
                    <Spacer style={{ height: '40px' }} />
                    <CloseButton
                        onClick={() => {
                            setShowConfirmationModal(false);
                        }}
                    />
                    <CenteredContent>
                        <BodyLarge>This will end the meeting for everyone.</BodyLarge>
                        <BodyRegular>Are you sure?</BodyRegular>
                        <PrimaryButton title={'End meeting'} onClick={endMeeting} />
                    </CenteredContent>
                </Content>
            </Modal>

            <ScrollArea stretch={true}>
                <SecondaryButton
                    title={'Add a Team Topic'}
                    onClick={() => {
                        document.getElementById(ElementId.TeamTopicButton)?.click();
                    }}
                />
                <TeamTopicsByParticipant agenda={agenda} teamTopicsProps={teamTopicsProps} />
            </ScrollArea>

            <StandUpFooter>
                <PulsingHint
                    pulseStyles={{
                        marginTop: '35px',
                    }}
                    isVisible={isDemoSeries && !isPersonaDemo}
                />

                {isEndButtonHighlightedForDemo ? (
                    <OverlayHint
                        style={{ marginTop: '-7px', borderRadius: '50px' }}
                        extraWidth={15}
                        extraHeight={15}
                        idOfElement={ElementId.EndMeetingFromTeamTopics}
                    />
                ) : (
                    <></>
                )}

                <PrimaryButton
                    id={ElementId.EndMeetingFromTeamTopics}
                    title={'End meeting'}
                    disabled={isNavigationDisabled}
                    fadeInDuration={cooldown}
                    onClick={() => {
                        if (isDemoSeries) {
                            endMeeting();
                        } else {
                            setShowConfirmationModal(true);
                        }
                    }}
                />

                <Spacing factor={0.83} />

                {backButtonText ? (
                    <SecondaryButton
                        disabled={isNavigationDisabled}
                        fadeInDuration={cooldown}
                        title={backButtonText}
                        onClick={() => {
                            bypassCooldown();
                            socket.emit(ClientSocketEvent.AgendaReversing, agendaNavigationRequest);
                        }}
                    />
                ) : (
                    <></>
                )}
            </StandUpFooter>
        </>
    );
}

export default ParkingLotView;
