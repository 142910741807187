import { SpinachAPIPath } from '@spinach-shared/types';

import { deleteSpinachAPI } from './deleteSpinachAPI';

export async function deleteZoomCollab(
    meetingUUID: string
): Promise<{ meetingUUID: string; seriesId: string } | undefined> {
    const response = await deleteSpinachAPI<{ meetingUUID: string; seriesId: string }>(
        SpinachAPIPath.ZoomCollaborateMeetingUUID.replace(':meetingUUID', encodeURIComponent(meetingUUID))
    );
    return response;
}
