import styled from 'styled-components';

import GoogleLogoWhite from '../../assets/google-white-letter.png';
import { AuthorizeIntegrationButton } from './integrations';

const GoogleText = styled.span`
    font-weight: 600;
`;

export const GoogleLoginComponent = ({
    onClick,
    text = 'Sign in with Google',
    style = {},
}: {
    onClick: () => void;
    text?: string;
    style?: React.CSSProperties;
}) => {
    return (
        <AuthorizeIntegrationButton onClick={onClick} style={style}>
            <img style={{ height: '20px', marginRight: '10px' }} src={GoogleLogoWhite} />
            <GoogleText>{text}</GoogleText>
        </AuthorizeIntegrationButton>
    );
};
