import { useState } from 'react';

import { ENGLISH_LANGUAGE, MEETING_LANGUAGE_SEPARATOR, OUTPUT_LANGUAGES } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import {
    ClientLogger,
    DropDown,
    patchSeries,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useOutputLanguageSelectionPerMeetingEnablement,
} from '../../../../..';
import { useGlobalStoredSeriesList } from '../../../../hooks/useGlobalStoredSeriesList';
import { BodyRegularOnboard } from '../../../../styles';
import { Row } from '../../../common';
import { SettingsContent, SettingsSubTitle } from './CombinedMeetingDetails';

export function LanguageSettingsDetails({ storedSeries }: { storedSeries: StoredSpinachSeries }) {
    const isOutputLanguageEnabled = useOutputLanguageSelectionPerMeetingEnablement();
    const { storedSeriesListState, setStoredSeriesList } = useGlobalStoredSeriesList();
    const [user] = useGlobalAuthedUser();
    const [isLoadingOutputLanguage, setIsLoadingOutputLanguage] = useState(false);
    const { setToastText } = useGlobalAiDashboard();
    const outputLanguages = OUTPUT_LANGUAGES.map((lang) => ({
        label: lang,
        code: lang,
        disabled: MEETING_LANGUAGE_SEPARATOR === lang,
    }));

    const track = useExperienceTracking();
    if (!isOutputLanguageEnabled) {
        return null;
    }

    const initialSelectedOutputLanguage =
        storedSeries.metadata.scribeMetadata?.outputLanguage || user.metadata.defaultOutputLanguage;

    return (
        <>
            <SettingsSubTitle>{'Language Settings'}</SettingsSubTitle>
            <SettingsContent>
                <Row style={{ flexWrap: 'wrap' }} vCenter>
                    <BodyRegularOnboard style={{ marginRight: '15px' }}>
                        Select the language for your summary output
                    </BodyRegularOnboard>
                    <DropDown
                        title={'Select Output Language'}
                        values={outputLanguages}
                        defaultValue={outputLanguages.find((item) => item.code === ENGLISH_LANGUAGE)?.label}
                        selected={outputLanguages.find((item) => item.code === initialSelectedOutputLanguage)?.label}
                        handleSelection={async (code) => {
                            try {
                                setIsLoadingOutputLanguage(true);

                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Series Summary Language',
                                    Language: code,
                                    SeriesId: storedSeries.id,
                                });
                                const updatedSeries = await patchSeries(storedSeries.id, {
                                    metadata: {
                                        scribeMetadata: {
                                            outputLanguage: code,
                                        },
                                    },
                                });

                                if (updatedSeries) {
                                    const instance = new StoredSpinachSeries(updatedSeries);
                                    const newList = storedSeriesListState.storedSeriesList.map((series) => {
                                        if (series.id === instance.id) {
                                            return instance;
                                        } else {
                                            return series;
                                        }
                                    });
                                    setStoredSeriesList(newList);
                                }
                            } catch (e) {
                                ClientLogger.error('Error updating summary language for series', e);
                                setToastText('Something went wrong when updating your summary language.');
                            } finally {
                                setIsLoadingOutputLanguage(false);
                            }
                        }}
                        buttonProps={{
                            style: { transform: 'scale(0.8)', transformOrigin: 'center left', padding: '4px 24px' },
                            disabled: isLoadingOutputLanguage,
                            isLoading: isLoadingOutputLanguage,
                        }}
                    />
                </Row>
            </SettingsContent>
        </>
    );
}
