import { Box } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import React from 'react';

import { SendFunction } from '../../../types/agent';

export function useVideoOpacity(videoRef: React.RefObject<HTMLVideoElement>) {
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const handleTimeUpdate = () => {
            const video = videoRef.current;
            if (video) {
                const currentTime = video.currentTime;
                const duration = video.duration;

                if (currentTime < 1) {
                    // Fade in during the first second
                    setOpacity(currentTime);
                } else if (duration - currentTime < 1.5) {
                    // Start fading out 2 seconds before the end
                    if (duration - currentTime > 1) {
                        // Fade out for 1 second
                        setOpacity(duration - currentTime - 1);
                    } else {
                        // Last 0.5 second is fully transparent
                        setOpacity(0);
                    }
                } else {
                    // Fully opaque for the rest of the video
                    setOpacity(1);
                }
            }
        };

        const video = videoRef.current;
        if (video) {
            video.addEventListener('timeupdate', handleTimeUpdate);
        }

        return () => {
            if (video) {
                video.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, [videoRef]);

    return opacity;
}

export const VideoPlayerComponent = ({ send, videoUrl }: { send: SendFunction; videoUrl: string }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const opacity = useVideoOpacity(videoRef);

    useEffect(() => {
        if (!isPlaying) {
            const video = videoRef.current;
            if (video) {
                video.play();
                setIsPlaying(true);
            }
        }
    }, [isPlaying]);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" height="100vh">
            <video
                ref={videoRef}
                src={videoUrl}
                playsInline
                onEnded={() => {
                    send({
                        type: 'STOP_VIDEO',
                    });
                }}
                onAbort={() => {
                    send({
                        type: 'STOP_VIDEO',
                    });
                }}
                style={{
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    opacity: opacity,
                    transition: 'opacity 0.5s ease-in-out',
                    zIndex: 100,
                }}
            />
        </Box>
    );
};
