import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getUser } from '../apis';
import { ClientPath } from '../components';
import { useGlobalRouting } from './useGlobalRouting';
import { useGlobalUser } from './useGlobalUser';
import { useWindowInactivity } from './useWindow';

/**
 * if the user is populated and we fetch user and nothing is returned and were not on verify, route to verify
 */

export function useLoggedOutDetection() {
    const isInactive = useWindowInactivity();
    const { routeToVerify } = useGlobalRouting();
    const location = useLocation();
    const [user, setUser] = useGlobalUser();

    useEffect(() => {
        async function routeToVerifyIfNoSession() {
            const userResponse = await getUser();

            if (!userResponse.user && userResponse.code === 440) {
                setUser(null);
                routeToVerify({ replace: true });
            }
        }

        if (!isInactive && user && location.pathname !== ClientPath.Verify) {
            routeToVerifyIfNoSession();
        }
    }, [isInactive, user, location.pathname]);
}
