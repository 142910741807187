import { ArrowUpwardRounded } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

import { BodyRegular, HeaderThree, SecondaryButtonLabel, lightTheme } from '../../styles';
import { isWebPlatform } from '../../utils/platform';
import { AbstractBackground, AbstractStatelessBackground } from '../onboarding';
import { BaseSecondaryButton, pulseAnimation } from '../stand-up';
import { ViewContainer } from './OnboardContainer';
import { SpinachStationary } from './Stationary';
import { LoadingSquares } from './animation';
import { Column, Row, Spacer, Spacing } from './framing';

export type FYIProps = {
    state: FYIState;
    body?: string | JSX.Element;
    header?: string;
    connectivityIssueProps?: ConnectivityIssueProps;
};

export enum FYIState {
    Refresh,
    IncompleteSetup,
    Loading,
    Error,
}

type FYIContent = {
    header: string;
    body: string | JSX.Element;
};

const Stationary = styled(SpinachStationary)`
    justify-content: center;
    position: unset;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const AnimatedArrow = styled(ArrowUpwardRounded)`
    ${() => pulseAnimation({ animationDuration: 1200, scale: 2.0, numberOfPulses: 2 })}
    right: 11px;
    top: 0px;
    position: absolute;
    color: ${lightTheme.primary.orangeDark};
`;

const LoadingScreen = styled.div`
    display: flex;
`;

function getFYIContent(
    state: FYIState,
    showLinkText: boolean,
    connectivityIssueProps?: ConnectivityIssueProps
): FYIContent {
    switch (state) {
        case FYIState.Loading:
            return {
                header: 'Loading Spinach AI',
                body: (
                    <LoadingScreen>
                        <Column>
                            <LoadingSquares />

                            {showLinkText &&
                            connectivityIssueProps &&
                            !connectivityIssueProps.isWebsocketPayloadLoaded ? (
                                <>
                                    <Spacing />
                                    <Row>
                                        <Column style={{ justifyContent: 'center' }}>
                                            <BodyRegular>
                                                Trouble loading?{' '}
                                                <BaseSecondaryButton
                                                    onClick={() => {
                                                        connectivityIssueProps.onSlowLoadLinkClick();
                                                    }}
                                                >
                                                    <SecondaryButtonLabel>
                                                        Try Spinach AI on the web.
                                                    </SecondaryButtonLabel>
                                                </BaseSecondaryButton>
                                            </BodyRegular>
                                            <Spacing factor={2 / 3} />
                                            <BodyRegular>For the best experience, use Chrome or Firefox.</BodyRegular>
                                        </Column>
                                    </Row>
                                </>
                            ) : null}
                        </Column>
                    </LoadingScreen>
                ),
            };
        case FYIState.IncompleteSetup:
            return {
                header: 'Join the fun!',
                body: <BodyRegular>Join a meeting to get started.</BodyRegular>,
            };
        case FYIState.Error:
            return {
                header: `We'd love to help`,
                body: '',
            };
        case FYIState.Refresh:
            return {
                header: "Woah! There's a new Spinach AI update!",
                body: (
                    <TextContainer>
                        <BodyRegular>We want you to have the best experience possible!</BodyRegular>
                        <BodyRegular>Refresh your Spinach AI app to get the latest and greatest</BodyRegular>
                        {isWebPlatform() ? null : <AnimatedArrow fontSize={'large'} />}
                    </TextContainer>
                ),
            };
    }
}

const CenteredCol = styled(Column)`
    justify-content: center;
    align-items: center;
`;

const CenteredRow = styled(Row)`
    justify-content: center;
    align-items: center;
`;

// Having a specified height allows back button to position
// properly for old safari
const FYIStationary = styled(Stationary)`
    min-height: 300px;
    max-height: 600px;
    height: 0.5vh;
`;

const WAIT_FOR_SLOW_LOAD = 5000;

export type ConnectivityIssueProps = {
    isWebsocketPayloadLoaded: boolean;
    onSlowLoadLinkClick: () => void;
};

export function FYI(props: FYIProps): JSX.Element {
    const [showLinkText, setShowLinkText] = React.useState(false);
    React.useEffect(() => {
        if (
            props.state === FYIState.Loading &&
            props.connectivityIssueProps &&
            !props.connectivityIssueProps.isWebsocketPayloadLoaded
        ) {
            setTimeout(() => {
                setShowLinkText(true);
            }, WAIT_FOR_SLOW_LOAD);
        }
    }, []);

    const { header, body } = getFYIContent(props.state, showLinkText, props.connectivityIssueProps);

    const bodyContent = props.body ?? body;
    const headerContent = props.header ?? header;

    return (
        <>
            <ViewContainer>
                <FYIStationary>
                    <CenteredRow style={{ height: '100%' }}>
                        <CenteredCol>
                            <HeaderThree>{headerContent}</HeaderThree>

                            <Spacer />

                            {typeof bodyContent === 'string' ? <BodyRegular>{bodyContent}</BodyRegular> : bodyContent}
                        </CenteredCol>
                    </CenteredRow>
                </FYIStationary>
            </ViewContainer>
            <AbstractStatelessBackground />
        </>
    );
}
