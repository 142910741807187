import { SpinachAPIPath, StoredSeries } from '@spinach-shared/types';

import { deleteSpinachAPI } from './deleteSpinachAPI';

export async function deleteSlackSeriesSettings(seriesId?: string): Promise<StoredSeries | null> {
    const path = seriesId
        ? SpinachAPIPath.SlackSeries.replace(':seriesId', seriesId)
        : SpinachAPIPath.SlackDefaultUserChannel;
    const data = await deleteSpinachAPI<{ updatedSeries: StoredSeries | null }>(path);

    return data?.updatedSeries ?? null;
}
