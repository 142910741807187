import { useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import z from 'zod';

import { CheckoutBillingScope, ClientPlatform, DemoVersion, FeatureToggle, WebUrlQuery } from '@spinach-shared/types';

import { atomLiveSeries } from '../atoms';
import { ClientLogger, isFeatureEnabled } from '../utils';
import { useSeriesReality } from './useGlobalSearchParams';
import { useGlobalAuthedUser, useGlobalUser } from './useGlobalUser';

export function useJiraEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.Jira1, false);
    const isEnabledForParticipant = isFeatureEnabled<boolean>(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.Jira1,
        false
    );
    return isEnabledForUser || isEnabledForParticipant;
}

export function useTeamTopics2Enablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);
    const isTT2Enabled = isFeatureEnabled(liveSeries?.featureToggles || {}, FeatureToggle.TeamTopics2, false);
    return isTT2Enabled;
}

export function useMeditationEnablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);
    const isMeditationEnabled = isFeatureEnabled(liveSeries?.featureToggles || {}, FeatureToggle.Meditation, false);
    return isMeditationEnabled;
}

export function useRecentCheckInEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.RecentCheckIns, false);
    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.RecentCheckIns,
        false
    );
    return isEnabledForUser || isEnabledForParticipant;
}

export function useJiraBoardEnablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.JiraBoard,
        false
    );
    return isEnabledForParticipant;
}

export function useSentimentCopy(): string {
    const [liveSeries] = useRecoilState(atomLiveSeries);
    // isFeatureEnabled naming is a bit odd here since we're returning a string, not a boolean
    return isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.SentimentCopy,
        'This is going...'
    );
}

export function useGoogleCalendarEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.GoogleCalendar, false);
    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.GoogleCalendar,
        false
    );
    return isEnabledForUser || isEnabledForParticipant;
}

export function useAsanaEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.Asana, false);
    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.Asana,
        false
    );

    return isEnabledForUser || isEnabledForParticipant;
}

export function useEditNameEnablement(): boolean {
    const [user] = useGlobalAuthedUser();

    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.EditUserName, false);
    return isEnabledForUser;
}

export function useDiscussionReactionEnablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.DiscussionReactions,
        false
    );
    return isEnabledForParticipant;
}

export function useEmailSummariesEnablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.EmailSummaries,
        false
    );

    return isEnabledForParticipant;
}

export function useJiraMagicInputCalloutEnablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.JiraMagicInputCallout,
        false
    );

    return isEnabledForParticipant;
}

export function useSentimentOpacity(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.SentimentOpacity,
        false
    );

    return isEnabledForParticipant;
}

export function useJiraIntegrationCalloutEnablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.JiraIntegrationCallout,
        false
    );

    return isEnabledForParticipant;
}

export function useAsanaIntegrationCalloutEnablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.AsanaIntegrationCallout,
        false
    );

    return isEnabledForParticipant;
}

export function useReferralCallout(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.ReferralCallout,
        false
    );

    return isEnabledForParticipant;
}

export function usePayUpCalloutURL(): string {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const url = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.PayupCalloutURL,
        ''
    );

    return url;
}

export function useGenericCalloutProps(): {
    title?: string;
    subtitle?: string;
    imgSrc?: string;
    id?: number;
    url?: string;
    onlyHideOnCloseClick?: boolean;
    excludePlatforms?: ClientPlatform[];
} {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const props = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.GenericCalloutProps,
        {}
    );

    return props;
}

export function useRegularItemSentimentEnabled() {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.RegularItemSentiment,
        false
    );

    return isEnabledForParticipant;
}

export function useFreeTierLimitations(): boolean {
    /**
     * @NOTE manually set to false to avoid refactors,
     * we may want to remove this feature flag entirley in the future
     * */
    return false;
}

export function useLiveItemSentimentDetailsEnabled() {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.LiveItemSentimentDetails,
        false
    );

    return isEnabledForParticipant;
}

export function useJiraSentimentInFooterEnablement() {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.JiraSentimentInFooter,
        false
    );

    return isEnabledForParticipant;
}

export function useIssueBasedEnablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForSeries = isFeatureEnabled(liveSeries?.featureToggles || {}, FeatureToggle.IssueBased, false);

    return isEnabledForSeries;
}

export function useCustomSlackRemindersEnablement() {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.CustomSlackReminders,
        false
    );

    return isEnabledForParticipant;
}

export function usePersonaDemo(): boolean {
    const [params] = useSearchParams();
    const variation = params.get(WebUrlQuery.DemoVersion);
    const isPersonaVariation = variation === DemoVersion.Persona;
    const { isDemoSeries } = useSeriesReality();
    const [user] = useGlobalUser();

    return isDemoSeries && (isPersonaVariation || user?.metadata.demoVersion === DemoVersion.Persona);
}

export function useReadOnlyPresentation(): boolean {
    const isIssueBased = useIssueBasedEnablement();
    const isPersonaDemo = usePersonaDemo();
    return isIssueBased || isPersonaDemo;
}

export function useProgressNavigationEnablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.ProgressNavigation,
        false
    );

    return isEnabledForParticipant;
}

export function useJiraSearchAllEnablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.JiraSearchAll,
        false
    );

    return isEnabledForParticipant;
}

export function useInviteViaSlackEnablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.InviteViaSlack,
        false
    );

    return isEnabledForParticipant;
}

export function useIssueResolutionEnablement(): boolean {
    const [liveSeries] = useRecoilState(atomLiveSeries);

    const isEnabledForParticipant = isFeatureEnabled(
        liveSeries?.spinachParticipant.featureFlagMap ?? {},
        FeatureToggle.IssueResolution,
        false
    );

    return isEnabledForParticipant;
}

export function useAISeriesAsanaIntegrationEnablement(): boolean {
    const [user] = useGlobalAuthedUser();

    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.AISeriesAsanaIntegration, false);

    return isEnabledForUser;
}
export function useAISeriesJiraIntegrationEnablement(): boolean {
    const [user] = useGlobalAuthedUser();

    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.AISeriesJiraIntegration, false);

    return isEnabledForUser;
}

export function useAiGoogleCalendarEnablement(): boolean {
    const [user] = useGlobalAuthedUser();

    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.AiGoogleCalendar, false);

    return isEnabledForUser;
}

export function useLinearEnablement(): boolean {
    const [user] = useGlobalAuthedUser();

    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.LinearIntegration, false);

    return isEnabledForUser;
}

export function useClickUpEnablement(): boolean {
    const [user] = useGlobalAuthedUser();

    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.ClickUpIntegration, false);

    return isEnabledForUser;
}

export function useTrelloEnablement(): boolean {
    const [user] = useGlobalAuthedUser();

    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.TrelloIntegration, false);

    return isEnabledForUser;
}

export function useConfluenceEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.ConfluenceIntegration, false);
    return isEnabledForUser;
}

export function useNotionEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.NotionIntegration, false);
    return isEnabledForUser;
}

export function useGoogleDriveEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.GoogleDriveIntegration, false);
    return isEnabledForUser;
}
export function useLanguageSelectionInDashboardEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.LanguageSelectionInDashboard, false);
    return isEnabledForUser;
}

export function useOutputLanguageSelectionEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.OutputLanguageSelection, false);
    return isEnabledForUser;
}

export function useOutputLanguageSelectionPerMeetingEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    const isOutputLanguageSelectionEnabled = useOutputLanguageSelectionEnablement();
    const isEnabledForUser = isFeatureEnabled(
        user.featureToggles,
        FeatureToggle.OutputLanguagePerMeetingSelection,
        false
    );
    return isEnabledForUser && isOutputLanguageSelectionEnabled;
}

export function useEmailSummaryToInviterOnly(): boolean {
    const [user] = useGlobalAuthedUser();
    const isEnabledForUser = isFeatureEnabled(user.featureToggles, FeatureToggle.EmailOrganizerOnly, false);
    return isEnabledForUser;
}

export function useProFeatures(): boolean {
    const [user] = useGlobalAuthedUser();
    return user.hasProFeatures;
}

export function useIsTemporaryLinkToAsyncVideo(): boolean {
    const [user] = useGlobalAuthedUser();
    return user.isTemporaryLinkToAsyncVideo;
}

export function usePaymentUrl(): string {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(user.featureToggles, FeatureToggle.PaymentUrl, 'https://spinach.io/pricing-pro');
}

export function useStripeUpgradeInAppEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(user.featureToggles, FeatureToggle.StripeUpgradeInApp, false);
}

export function useProAiPlanFeatureList(): string[] {
    const [user] = useGlobalAuthedUser();
    const plans = useStripePlans();
    if (plans.isConsumptionBased) {
        const featuresForFreeUsers: any = user.featureToggles[FeatureToggle.AiPlanFeatureListForFreeUsers].valueOf();
        const featuresForTrialUsers: any =
            user.featureToggles[FeatureToggle.AiPlanFeatureListForReverseTrialUsers].valueOf();

        if (user.isOnLiveReverseTrial) {
            return featuresForTrialUsers?.features ?? [];
        }

        if (!user.hasProFeatures) {
            return featuresForFreeUsers?.features ?? [];
        }
        return [];
    }

    const value: any = user.featureToggles[FeatureToggle.ProAiPlanFeatureList].valueOf();
    return (
        value?.features ?? [
            'Priority processing',
            'Sharing controls',
            'Review & edit summaries',
            'Transcriptions & meeting recordings',
            'Integrate with Confluence, Google Docs, Notion  ',
        ]
    );
}

export function useStripeCustomerPortalEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(user.featureToggles, FeatureToggle.StripeCustomerPortal, false);
}

export function useStripePlans(): {
    plans: { planName: string; checkoutBillingScope?: CheckoutBillingScope }[];
    isConsumptionBased?: boolean;
} {
    const [user] = useGlobalAuthedUser();
    const value: any = user.featureToggles[FeatureToggle.StripePlanIds].valueOf();
    return value;
}

export function useIsNonOrganizerAllowedToRemoveSpinach(): boolean {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(user.featureToggles, FeatureToggle.NonOrganizerSpinachRemove, false);
}

export function useIsZoomIntegration(): boolean {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(user.featureToggles, FeatureToggle.ZoomIntegration, false);
}

export function useIsCustomVocabularySettings(): boolean {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(user.featureToggles, FeatureToggle.CustomVocabularySettings, false);
}

export function useIsMeetingVideoPlayer(): boolean {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(user.featureToggles, FeatureToggle.MeetingVideoPlayer, false);
}

export function useIsMeetingVideoDownload(): boolean {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(user.featureToggles, FeatureToggle.MeetingVideoDownload, false);
}

export function useAskSpinachEnabledQueries() {
    const schema = z.record(
        z.string(),
        z
            .object({
                default: z.boolean().optional(),
                freeformLimit: z.number(),
                freeformMinumumLength: z.number().default(1),
                freeform: z.literal(true),
                name: z.string(),
                hint: z.string(),
                sortingScore: z.number().optional(),
                multiMeeting: z.boolean().optional(),
                storeFreeformInLocalStorage: z.boolean().optional(),
            })
            .or(
                z.object({
                    freeform: z.literal(false),
                    name: z.string(),
                    default: z.boolean().optional(),
                    sortingScore: z.number().optional(),
                    multiMeeting: z.boolean().optional(),
                })
            )
    );
    const [user] = useGlobalAuthedUser();
    let combined = schema.parse({});
    try {
        combined = {
            ...combined,
            ...schema.parse(user.featureToggles[FeatureToggle.AskSpinachEnabledQueriesBaseline] ?? {}),
        };
    } catch (e) {
        ClientLogger.error(`failed to parse ${FeatureToggle.AskSpinachEnabledQueriesBaseline}`);
    }
    try {
        combined = {
            ...combined,
            ...schema.parse(user.featureToggles[FeatureToggle.AskSpinachEnabledQueries] ?? {}),
        };
    } catch (e) {
        ClientLogger.error(`failed to parse ${FeatureToggle.AskSpinachEnabledQueries}`);
    }
    return combined;
}
export function useEditMeetingTypeEnablement(): boolean {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(user.featureToggles, FeatureToggle.EditMeetingType, false);
}

export function useIncludeUserResearchInterviewInMeetingTypeSelection(): boolean {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(
        user.featureToggles,
        FeatureToggle.IncludeUserResearchInterviewInMeetingTypeSelection,
        false
    );
}

export function useDisabledKnowledgeBaseForFreeUsers(): boolean {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(user.featureToggles, FeatureToggle.DisableKnowledgeBaseIntegrationForFreeUsers, false);
}

export function useIsCustomBrandingEnabled(): boolean {
    const [user] = useGlobalAuthedUser();
    return (
        isFeatureEnabled(user.featureToggles, FeatureToggle.CustomBotBranding, false) &&
        !isFeatureEnabled(user.featureToggles, FeatureToggle.AccountCustomBotBranding, false)
    );
}

export function useCustomVocabularySettingsPremiumOnly(): boolean {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(user.featureToggles, FeatureToggle.CustomVocabularySettingsPremiumOnly, false);
}

export function useDefaultUsageCycleDays(): number {
    const [user] = useGlobalAuthedUser();
    return isFeatureEnabled(user.featureToggles, FeatureToggle.UsageDefaultCycleDays, 30);
}
