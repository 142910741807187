import { ClientEventType } from '@spinach-shared/types';

import { ReactComponent as SlackLogo } from '../../../assets/slack-logo-white.svg';
import SpinachSlackUsageSrc from '../../../assets/spinach-slack-usage.png';
import { useExperienceTracking, useIntegrationDetection, useLandingAnalytic } from '../../../hooks';
import { useGlobalSlack } from '../../../hooks/useSlack';
import { BodyBigOnboard, BodyRegular, HeaderThreeOnboard } from '../../../styles';
import { URLUtil } from '../../../utils';
import { Row, SlidingColumn, Spacing } from '../../common';
import { AuthorizeIntegrationButton } from '../../common/integrations';
import { ScrollView } from '../../series';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BackButtonRow, Stationary, StepProps } from './common';

export type SlackSetupProps = Omit<StepProps, 'onSubmit'> & {
    onBack: () => void;
    onSkip: (reason: string) => void;
    progressToSlackDefaults: () => void;
};

export function SlackSetup({ onBack, direction, onSkip, progressToSlackDefaults }: SlackSetupProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedConnectSlackStep);
    const { slackState } = useGlobalSlack();
    const startDetection = useIntegrationDetection(progressToSlackDefaults);
    const track = useExperienceTracking();
    return (
        <Stationary>
            <BackButtonRow onBack={onBack} />
            <HeaderThreeOnboard>Get meeting notes in Slack</HeaderThreeOnboard>
            <ScrollView sidePadding={0} style={{ backgroundColor: 'rgba(0,0,0,0)', background: 'unset' }}>
                <SlidingColumn centered direction={direction}>
                    <Spacing />
                    <Row centered>
                        <BodyBigOnboard>Spinach will send meeting notes to any channel you choose.</BodyBigOnboard>
                    </Row>

                    <Spacing factor={1 / 2} />

                    <AuthorizeIntegrationButton
                        onClick={() => {
                            track(ClientEventType.UserClickedConnectToSlack);
                            startDetection();
                            URLUtil.openURL(slackState.installUrl);
                        }}
                    >
                        <SlackLogo style={{ height: '25px' }} />
                        <BodyRegular style={{ marginLeft: '10px', color: 'white' }}>
                            <b>Connect to Slack</b>
                        </BodyRegular>
                    </AuthorizeIntegrationButton>
                    <Spacing factor={1 / 2} />

                    <img
                        src={SpinachSlackUsageSrc}
                        alt="Slack Spinach Integration with action items"
                        style={{ width: '100%' }}
                    />

                    <Spacing />

                    <SecondaryButton title="I don't have permission" onClick={() => onSkip('need-permissions')} />
                    <Spacing />

                    <SecondaryButton title="We don't use Slack" onClick={() => onSkip('other-messaging-platform')} />
                    <Spacing />
                </SlidingColumn>
            </ScrollView>
        </Stationary>
    );
}
