import { Box } from '@material-ui/core';

import { useGlobalVideoAgent } from '../../../..';
import { safeWidth } from '../../../constants';

export const AgentAgendaBreadcrumbs = () => {
    const { session } = useGlobalVideoAgent();
    const breadCrumbsSizeMultiplier = (session?.agenda?.topics?.length ?? 0) > 13 ? 0.7 : 1;
    return (
        <Box
            position="fixed"
            paddingX="15px"
            width={safeWidth}
            flexWrap={'wrap'}
            top="45px"
            zIndex="20"
            display="flex"
            justifyContent="center"
            style={{ gap: `${18 * breadCrumbsSizeMultiplier}px` }}
        >
            {session.currentTopicIndex !== undefined
                ? session.agenda.topics.map((topic, index) => (
                      <Box
                          key={index}
                          width={`${breadCrumbsSizeMultiplier * 45}px`}
                          height={`${breadCrumbsSizeMultiplier * 45}px`}
                          style={{
                              backgroundColor:
                                  session.currentTopicIndex === index
                                      ? '#00FF00'
                                      : session.currentTopicIndex !== undefined && session.currentTopicIndex > index
                                      ? '#16641B'
                                      : '#24262F',
                              boxShadow: session.currentTopicIndex === index ? '0 0 50px 8px #FFFFFF' : undefined,
                              opacity: session.currentTopicIndex === index ? 1 : 0.7,
                          }}
                      />
                  ))
                : undefined}
        </Box>
    );
};
