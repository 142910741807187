import { useMemo, useState } from 'react';
import { Socket } from 'socket.io-client';

import {
    ClientSocketEvent,
    TypedUpdate,
    YTBHistoryUpdateRequest,
    YTBUpdateUpdatedRequest,
} from '@spinach-shared/types';

import { FullUpdateEmitter, SetTypedUpdates } from '../types';
import { createWebsocketPayload } from '../utils';
import { useCombinedUpdates } from './useCombinedUpdates';
import { useGlobalAuthedUser } from './useGlobalUser';
import { useGlobalLiveSeries } from './useLiveSeries';

type UseLiveCustomUpdatesProps = {
    createUpdateEmitter: (update: TypedUpdate) => (text: string) => void;
    createFullUpdateEmitter: FullUpdateEmitter;
    createReorderEmitter: (update: TypedUpdate) => (moveToIndex: number) => void;
    onToggleConfirmation: (update: TypedUpdate) => void;
    customUpdates: TypedUpdate[];
    setCustomUpdates: SetTypedUpdates;
};

export function useLiveCustomUpdates(
    socket: Socket,
    customListId: string,
    agendaItemId: string
): UseLiveCustomUpdatesProps {
    const [user] = useGlobalAuthedUser();
    const [liveSeries] = useGlobalLiveSeries();
    const userIdentity = user.toUserIdentity();
    const { standUpUpdate } = liveSeries.agenda.findParticipantsYTBAgendaItemProps(agendaItemId)!;

    const savedCustomItems = useMemo(() => standUpUpdate.getCustomUpdatesForList(customListId), [standUpUpdate]);

    const [customUpdates, setCustomUpdates] = useState<TypedUpdate[]>(savedCustomItems);

    useCombinedUpdates(savedCustomItems, customUpdates, setCustomUpdates, standUpUpdate);

    const spinachUserId = liveSeries.spinachParticipant.id;
    const participantId = agendaItemId;
    const seriesSlug = liveSeries.slug;

    const createUpdateEmitter = (update: TypedUpdate) => (text: string) => {
        const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
            ...userIdentity,
            seriesSlug,
            spinachUserId,
            participantId,
            meetingId: liveSeries.currentMeeting.id,
            update: {
                ...update,
                text,
            },
        });
        socket?.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
    };

    const createFullUpdateEmitter = (update: TypedUpdate) => (updated: TypedUpdate, updates?: TypedUpdate[]) => {
        const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
            ...userIdentity,
            seriesSlug,
            spinachUserId,
            participantId,
            meetingId: liveSeries.currentMeeting.id,
            updates,
            update: {
                ...update,
                ...updated,
            },
        });
        socket?.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
    };

    const createReorderEmitter = (update: TypedUpdate) => (moveToIndex: number) => {
        const payload = createWebsocketPayload<YTBUpdateUpdatedRequest>({
            ...userIdentity,
            seriesSlug,
            spinachUserId,
            participantId,
            meetingId: liveSeries.currentMeeting.id,
            update,
            moveToIndex,
        });
        socket?.emit(ClientSocketEvent.YTBUpdateUpdating, payload);
    };

    const onToggleConfirmation = (update: TypedUpdate) => {
        const payload = createWebsocketPayload<YTBHistoryUpdateRequest>({
            seriesId: seriesSlug,
            seriesSlug,
            spinachUserId,
            meetingId: liveSeries.currentMeeting.id,
            update,
        });
        socket?.emit(ClientSocketEvent.YTBHistoryConfirming, payload);
    };

    return {
        createUpdateEmitter,
        createFullUpdateEmitter,
        createReorderEmitter,
        onToggleConfirmation,
        customUpdates,
        setCustomUpdates,
    };
}
