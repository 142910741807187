import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useState } from 'react';

import { BaseMeetingProps, SpinachSeriesProps } from '@spinach-shared/models';

import { ScrollArea } from '../../../..';
import { lightTheme } from '../../../../..';
import { Notification } from '../../../../stand-up/Notification';
import { StandupAppStandupSummary } from './StandupAppStandupSummary';

export function StandupAppOpenedSummary({
    currentMeeting,
    series,
}: {
    currentMeeting: BaseMeetingProps;
    series: SpinachSeriesProps;
}): JSX.Element {
    const [isNotificationToastOpen, setIsNotificationToastOpen] = useState(false);

    if (!currentMeeting) {
        return <></>;
    }

    return (
        <>
            <ScrollArea
                style={{ height: '10vh', flexGrow: 1, paddingBottom: '20px' }}
                sidePadding={0}
                useGrayShading={true}
            >
                <StandupAppStandupSummary currentMeeting={currentMeeting} spinachSeries={series} />
            </ScrollArea>

            <Notification
                containerStyle={{
                    position: 'relative',
                    top: '-75px',
                    height: '0',
                    margin: 'auto',
                }}
                isOpen={isNotificationToastOpen}
                duration={4000}
                onClose={() => setIsNotificationToastOpen(false)}
                message={'Summary copied!'}
                icon={
                    <CheckCircleIcon
                        style={{ color: lightTheme.neutrals.white }}
                        htmlColor={lightTheme.neutrals.white}
                    />
                }
            />
        </>
    );
}
