import { Modal } from '@material-ui/core';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';
import { getWebAppHost } from '@spinach-shared/utils';

import { URLUtil, postMeetingNotesAccessAsAdmin } from '../../../..';
import { GlobalModal } from '../../../atoms';
import { useExperienceTracking, useGlobalAdmin, useGlobalAuthedUser, useGlobalModal } from '../../../hooks';
import { BodyRegular, ResponsiveModalTitle } from '../../../styles';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

export const ConfirmViewMeetingAsAdmin = () => {
    const [user] = useGlobalAuthedUser();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const { state, setState: setAdminState } = useGlobalAdmin();
    const { searchedHistory, searchedUser } = state;

    const track = useExperienceTracking();

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Confirm View Meeting As Admin',
        });
        setGlobalModal(null);
        setAdminState((updatedState) => ({
            ...updatedState,
            isLoadingMeetingNotesViewInitiation: false,
        }));
    };

    if (!user) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.ConfirmAccessMeetingNotesAsAdmin} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden' }}>
                <Column centered>
                    <Row centered>
                        <ResponsiveModalTitle>Confirm Meeting Notes Access</ResponsiveModalTitle>
                    </Row>
                    <Spacing factor={1 / 3} />
                </Column>

                <Column style={{ alignItems: 'start' }}>
                    <BodyRegular>Are you sure you want to access this user’s meeting notes?</BodyRegular>
                </Column>
                <span style={{ flexGrow: 1 }} />
                <PrimaryButton
                    title="Confirm"
                    isLoading={state.isLoadingMeetingNotesViewInitiation}
                    onClick={async () => {
                        if (!searchedHistory) {
                            return;
                        }

                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Confirm View Meeting Notes as Admin',
                            BotId: searchedHistory.id,
                            SelectedUserId: searchedUser?.spinachUserId,
                            MeetingTitle: searchedHistory.meetingTitle,
                        });

                        setAdminState((updatedState) => ({
                            ...updatedState,
                            isLoadingMeetingNotesViewInitiation: true,
                        }));

                        const response = await postMeetingNotesAccessAsAdmin(searchedHistory.id);

                        if (response?.botId && response.seriesId) {
                            // open deep link to meeting notes
                            URLUtil.openURL(
                                `${getWebAppHost()}/ai/home?${WebUrlQuery.Section}=${DashboardSection.Transcripts}&${
                                    WebUrlQuery.BotId
                                }=${response.botId}&${WebUrlQuery.SeriesId}=${response.seriesId}`
                            );
                        }

                        closeModal();
                    }}
                />
                <Spacing factor={1 / 3} />
                <SecondaryButton
                    title="Cancel"
                    onClick={closeModal}
                    disabled={state.isLoadingMeetingNotesViewInitiation}
                />
            </SpinachModalContent>
        </Modal>
    );
};
