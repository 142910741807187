import axios from 'axios';

import { SpinachAPIPath } from '@spinach-shared/types';
import { getDefaultRequestHeaders } from '@spinach-shared/utils';

import { isChromeExtensionPlatform, isWebPlatform } from '../utils';
import { getHeaderOpts } from '../utils/api';

type UserMetadata = { isZoomIntegrationSetup?: boolean; isExternalUser: boolean };

export async function getUserMetadata(userId: string): Promise<UserMetadata> {
    const response = await axios.get<UserMetadata>(
        `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.UserMetadata}?userId=${userId}`,
        {
            headers: {
                ...getDefaultRequestHeaders(getHeaderOpts()),
                'Cache-Control': 'no-store',
            },
            withCredentials: isWebPlatform() || isChromeExtensionPlatform(),
            params: { ts: new Date().getTime() },
        }
    );

    if (response.status !== 200) {
        throw new Error('Failed to get user metadata');
    }

    return response.data;
}
