import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { ArrowForward } from '@material-ui/icons';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import { useClickTracking } from '../../../../hooks/useExperienceTracking';
import { lightTheme } from '../../../../styles/theme';
import { GlobalSearchDetailedMatch, GlobalSearchMatchType, SearchResult } from './globalSearchTypes';
import { highlightText } from './highlightText';

const Chip = ({ text }: { text: string }) => (
    <Box
        style={{
            backgroundColor: '#A0A0A0',
            borderRadius: 5,
            padding: '2px 7px',
            fontSize: '12px',
            margin: '0 0px',
            display: 'inline-block',
            fontWeight: 'bold',
            color: 'white',
        }}
    >
        {text}
    </Box>
);

const CallToAction = ({ text, onClick }: { text: string; onClick: () => void }) => (
    <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        mt="10px"
        style={{
            fontSize: '14px',
            color: lightTheme.primary.greenLight,
            cursor: 'pointer',
            fontWeight: 'bold',
        }}
        onClick={onClick}
    >
        {text}
        <ArrowForward style={{ fontSize: '18px', marginRight: '10px' }} />
    </Box>
);

export function MatchItem({
    match,
    searchTerm,
    handleMatchClick,
    detailedMatches,
    selectedResult,
}: {
    match: GlobalSearchDetailedMatch;
    searchTerm: string;
    handleMatchClick: (botId: string, section: DashboardSection) => void;
    detailedMatches: GlobalSearchDetailedMatch[];
    selectedResult: SearchResult;
}) {
    const trackClick = useClickTracking();
    return (
        <Box
            style={{
                padding: '10px 0',
                cursor: 'pointer',
            }}
            mb="7px"
        >
            {match.type === GlobalSearchMatchType.Blocker && (
                <Box>
                    <Chip text="Blocker" />
                    <Box fontWeight="bold">{highlightText(match.content, searchTerm)}</Box>
                    <Box>{highlightText(match.context, searchTerm)}</Box>
                    <CallToAction
                        text="Jump to summary"
                        onClick={() => {
                            handleMatchClick(selectedResult.botId, DashboardSection.Summaries);
                            trackClick(ClientEventType.AIDashboardClick, 'search - jump to summary', {
                                totalNumbefOfMatchesForThisMeeting: detailedMatches.length,
                            });
                        }}
                    />
                </Box>
            )}
            {match.type === GlobalSearchMatchType.ActionItem && (
                <Box>
                    <Chip text="Action Item" />
                    <Box fontWeight="bold">{highlightText(match.title, searchTerm)}</Box>
                    <Box>{highlightText(match.context, searchTerm)}</Box>
                    <CallToAction
                        text="Jump to summary"
                        onClick={() => {
                            handleMatchClick(selectedResult.botId, DashboardSection.Summaries);
                            trackClick(ClientEventType.AIDashboardClick, 'search - jump to summary', {
                                totalNumbefOfMatchesForThisMeeting: detailedMatches.length,
                            });
                        }}
                    />
                </Box>
            )}
            {match.type === GlobalSearchMatchType.KeyDecision && (
                <Box>
                    <Chip text="Key Decision" />
                    <Box fontWeight="bold">{highlightText(match.content, searchTerm)}</Box>
                    <Box>{highlightText(match.context, searchTerm)}</Box>
                    <CallToAction
                        text="Jump to summary"
                        onClick={() => {
                            handleMatchClick(selectedResult.botId, DashboardSection.Summaries);
                            trackClick(ClientEventType.AIDashboardClick, 'search - jump to summary', {
                                totalNumbefOfMatchesForThisMeeting: detailedMatches.length,
                            });
                        }}
                    />
                </Box>
            )}
            {match.type === GlobalSearchMatchType.Transcript && (
                <Box>
                    <Chip text="Transcript" />
                    {match.group.map((line, index) => (
                        <Box key={index}>
                            <Typography variant="body1">
                                <strong>{line.speaker}:</strong> {highlightText(line.statement, searchTerm)}
                            </Typography>
                        </Box>
                    ))}
                    <CallToAction
                        text="Jump to transcript"
                        onClick={() => {
                            handleMatchClick(selectedResult.botId, DashboardSection.Transcripts);
                            trackClick(ClientEventType.AIDashboardClick, 'search - jump to transcript', {
                                totalNumbefOfMatchesForThisMeeting: detailedMatches.length,
                            });
                        }}
                    />
                </Box>
            )}
        </Box>
    );
}
