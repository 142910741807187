import { useEffect } from 'react';

import { YTBUpdateProps } from '@spinach-shared/models';
import { TypedUpdate } from '@spinach-shared/types';

import { SetTypedUpdates } from '../types/StandUp';
import { getInFlightUpdates } from '../utils/getInFlightUpdates';
import { usePrevious } from './usePrevious';

// TODO: super expensive hook, needs optimizing
export function useCombinedUpdates(
    savedUpdates: TypedUpdate[],
    localUpdates: TypedUpdate[],
    setLocalUpdates: SetTypedUpdates,
    ytbUpdate: YTBUpdateProps
) {
    const previousYTBUpdate = usePrevious(ytbUpdate);
    const previousUpdates = usePrevious(savedUpdates);

    useEffect(() => {
        const previousContent = previousUpdates.map((u) => JSON.stringify(u)).join('');
        const newContent = savedUpdates.map((u) => JSON.stringify(u)).join('');

        if (previousContent !== newContent || previousYTBUpdate.id !== ytbUpdate.id) {
            const shouldUseInFlight = previousYTBUpdate.id === ytbUpdate.id;

            const removedUpdates = previousUpdates.filter((u) => !savedUpdates.some((su) => su.id === u.id));
            const localUpdatesExceptJustRemoved = localUpdates.filter(
                (u) => !removedUpdates.some((ru) => ru.id === u.id)
            );

            const inFlightUpdates = shouldUseInFlight
                ? (getInFlightUpdates(savedUpdates, localUpdatesExceptJustRemoved) as TypedUpdate[])
                : [];

            const syncedSavedUpdates = getInProgressChangesToSavedUpdated(savedUpdates, previousUpdates, localUpdates);
            setLocalUpdates([...syncedSavedUpdates, ...inFlightUpdates]);
        }
    }, [savedUpdates, setLocalUpdates, localUpdates, previousYTBUpdate, ytbUpdate, previousUpdates]);
}

function getInProgressChangesToSavedUpdated(
    savedUpdates: TypedUpdate[],
    previousUpdates: TypedUpdate[],
    localUpdates: TypedUpdate[]
): TypedUpdate[] {
    return savedUpdates.map((update) => {
        const incomingEntryForUpdate = previousUpdates.find((u) => u.id === update.id);
        const localEntryForUpdate = localUpdates.find((u) => u.id === update.id);

        const syncedSubItems = getInProgressChangesToSavedUpdated(
            update.subItems || [],
            incomingEntryForUpdate?.subItems || [],
            localEntryForUpdate?.subItems || []
        );

        if (
            incomingEntryForUpdate &&
            update.text === incomingEntryForUpdate.text &&
            localEntryForUpdate &&
            localEntryForUpdate.text !== incomingEntryForUpdate.text
        ) {
            return { ...localEntryForUpdate, subItems: syncedSubItems };
        } else {
            return { ...update, subItems: syncedSubItems };
        }
    });
}
