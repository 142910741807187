export const MICROSOFT_CALENDAR_SCOPES: string[] = ['https://graph.microsoft.com/Calendars.ReadWrite'];
export const DEFAULT_MICROSOFT_OAUTH_SCOPES: string[] = [
    'openid',
    'profile',
    'offline_access',
    'email',
    ...MICROSOFT_CALENDAR_SCOPES,
];

export const FRONT_END_MICROSOFT_AUTH_REDIRECT_PATH = '/verify-microsoft-code';
