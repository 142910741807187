import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { DEFAULT_MAX_JIRA_ISSUE_LENGTH } from '@spinach-shared/constants';
import { Ticket } from '@spinach-shared/types';
import { DetailedTicket } from '@spinach-shared/types/src/Jira';

import { fetchDetailedJiraIssues } from '../apis/jira';
import { detailedJiraIssuesAtom, recentJiraIssuesAtom } from '../atoms';
import { SetValue } from '../types';
import { useFetchJiraIssues } from '../utils/fetchJiraIssues';
import { useGlobalAuthedUser } from './useGlobalUser';
import { usePrevious } from './usePrevious';

export const useGlobalJiraIssues = (): [jiraIssues: Ticket[] | null, setJiraIssues: SetValue<Ticket[] | null>] => {
    return useRecoilState(recentJiraIssuesAtom);
};

export const useGlobalDetailedJiraIssues = (): [
    jiraIssues: DetailedTicket[] | null,
    setJiraIssues: SetValue<DetailedTicket[] | null>
] => {
    return useRecoilState(detailedJiraIssuesAtom);
};

/**
 *
 * @returns Jira issues (if a user is authed with jira)
 *
 * NOTE: Should only be used in places where user is garunteed to be defined
 */
export const useJiraIssues = (isLoaded: boolean) => {
    const [jiraIssues, setSuggestedJiraIssues] = useGlobalJiraIssues();
    const { fetchJiraIssues } = useFetchJiraIssues();

    const [user] = useGlobalAuthedUser();

    const prevUser = usePrevious(user);
    useEffect(() => {
        const getJiraIssues = async () => {
            const issues = await fetchJiraIssues({
                currentJQL: `assignee in (currentUser()) OR reporter in (currentUser()) ORDER BY statusCategoryChangedDate, updated, lastViewed DESC`,
            });
            if (!issues.length || !issues[0].id) {
                return;
            }

            setSuggestedJiraIssues(issues);
        };

        if (user.isAuthedForJira && (isLoaded || (user.isAuthedForJira && !prevUser.isAuthedForJira))) {
            getJiraIssues();
        }
    }, [user.isAuthedForJira, isLoaded]);

    return [jiraIssues];
};

export const useDetailedJiraIssues = (opts?: { maxResults: number }) => {
    const { maxResults = DEFAULT_MAX_JIRA_ISSUE_LENGTH } = opts ?? {};
    const [jiraIssues] = useGlobalJiraIssues();
    const [issues, setIssues] = useGlobalDetailedJiraIssues();

    useEffect(() => {
        const fetch = async (issues: Ticket[]) => {
            const issueIds = issues.slice(0, maxResults).map((i) => i.id);
            const responseIssues = await fetchDetailedJiraIssues(issueIds);

            setIssues(responseIssues);
        };

        if (!!jiraIssues) {
            fetch(jiraIssues);
        }
    }, [jiraIssues]);

    return [issues];
};
