import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';

import { postAnonymousUser } from '../../apis';
import { useExperienceTracking, useGlobalRouting, useGlobalUser } from '../../hooks';
import { AnonymousUserTracker } from '../../utils';
import { FYI, FYIState } from '../common';

export function TrySpinachContainer(): JSX.Element {
    const [user, setUser] = useGlobalUser();
    const { routeToVerify, routeToDemoExperience } = useGlobalRouting();
    const track = useExperienceTracking();
    const [params] = useSearchParams();
    const demoForName = params.get(WebUrlQuery.DemoSandboxRecipient);
    // TODO: we may want recaptcha on this sucker

    /**
     * we want to call the create-anon-user api
     * but only if there already isn't a session
     *
     * this should be handled by the routing.
     * if we get null back, then we land on this and create one.
     * if we don't get null back (could be anon or known), we skip this
     */
    useEffect(() => {
        async function execute() {
            // Prevent non-anon users from triggering flow below
            if (user) {
                routeToDemoExperience({ replace: true });
                return;
            }

            const newAnonUser = await postAnonymousUser(params);
            if (!newAnonUser) {
                routeToVerify();

                // is this needed?
                setUser(null);
            } else {
                const anon = new ClientUser(newAnonUser);
                track(ClientEventType.AnonymousUserCreated, anon.toUserIdentityPayload());
                setUser(newAnonUser);
                AnonymousUserTracker.trackUser(newAnonUser._id);
                routeToDemoExperience({ replace: true });

                if (demoForName) {
                    document
                        .querySelector('meta[name="description"]')
                        ?.setAttribute(
                            'content',
                            `${demoForName}'s custom sandbox to experience Spinach AI in 2 minutes`
                        );
                    document.title = 'Spinach AI Sandbox';
                }
            }
        }

        execute();
    }, []);

    return <FYI state={FYIState.Loading} />;
}
