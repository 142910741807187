import { useEffect } from 'react';

import { ClientEventType } from '@spinach-shared/types';

import { patchVideoAgentSession } from '../../apis/video-agent/patchVideoAgentSession';
import { useActivityTracking } from '../useExperienceTracking';
import { useGlobalVideoAgent } from './useGlobalVideoAgent';

export const useSyncPastTriggers = (
    pastTriggers: {
        timestamp: string;
        text: string;
    }[]
) => {
    const trackActivity = useActivityTracking();
    const {
        state: { session },
        setSession,
    } = useGlobalVideoAgent();
    useEffect(() => {
        if (!session) {
            return;
        }
        if (pastTriggers.length > 0 && session.triggers.length !== pastTriggers.length) {
            const updatedSession = session.withTriggersUpdated(pastTriggers);
            patchVideoAgentSession(updatedSession.toJSON());
            setSession(updatedSession);
            trackActivity(ClientEventType.VideoAgentActivity, 'Transcript Trigger', {
                text: pastTriggers[0].text,
            });
        }
    }, [session, setSession, pastTriggers, trackActivity]);
};
