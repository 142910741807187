import { ClientEventType, MeetingSettingsSubview, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import { ReactComponent as AsanaLogo } from '../../../assets/Asana-logo.svg';
import { useExperienceTracking, useGlobalAuthedUser, useIntegrationDetection } from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { URLUtil } from '../../../utils';
import { AppIntegrationRow, IntegrationButtonText } from './AppIntegrationRow';
import { IntegrationRowProps, UseIntegrationRow } from './common';

function useAsanaIntegrationRow(): UseIntegrationRow {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { setSubview } = useGlobalMeetingSettings();

    const startDetection = useIntegrationDetection();

    const isConnectFlow = !user.isAuthedForAsana;

    async function onClick() {
        if (isConnectFlow) {
            track(ClientEventType.UserAuthorizeAsanaClick);
            startDetection();
            URLUtil.openURL(
                `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.AsanaAuth}?${WebUrlQuery.Suid}=${user.spinachUserId}`,
                { redirectThroughBackendWhenOnZoom: true }
            );
        } else {
            setSubview(MeetingSettingsSubview.AsanaSettings);
            track(ClientEventType.OpenAsanaSettingsSubviewClick);
        }
    }

    const subtext = 'Add Asana tasks to your Check-In';

    return {
        subtext,
        onClick,
        isConnected: user.isAuthedForAsana,
        buttonText: user.isAuthedForAsana ? IntegrationButtonText.Configure : IntegrationButtonText.Connect,
    };
}

export function AsanaIntegrationRow({ isConnectViewOnly }: IntegrationRowProps): JSX.Element {
    const { subtext, onClick, isConnected, buttonText } = useAsanaIntegrationRow();

    return (
        <AppIntegrationRow
            onCTAClick={onClick}
            subtext={subtext}
            label={'Asana'}
            icon={<AsanaLogo style={{ width: '24px', height: '24px' }} />}
            isConnected={isConnected}
            buttonText={buttonText}
            isConnectViewOnly={isConnectViewOnly}
        />
    );
}
