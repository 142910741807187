import { Row, Spacing, useGlobalAuthedUser } from '../../../../../..';

export function MixpanelEmbed(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const urls = user.mixpanelAdminEmbedUrls;
    return (
        <>
            {urls.map((url, i) => (
                <Row
                    style={{
                        height: '80vh',
                        marginBottom: '20px',
                    }}
                    key={url}
                >
                    <iframe
                        id={`mixpanel-embed-${i}`}
                        src={url}
                        style={{
                            width: '95%',
                            height: '100%',
                            border: 'none',
                        }}
                    ></iframe>
                </Row>
            ))}
            <Spacing />
        </>
    );
}
