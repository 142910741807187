import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { ActiveAgendaProps, BaseAgendaItemProps, BaseAgendaProps } from '@spinach-shared/models';
import { AuthoredUpdate, TypedUpdate } from '@spinach-shared/types';

import { ElementId } from '../../constants';
import { useElasticLiveInputs, useGlobalAuthedUser, useLiveSyncedFocus, useTypedUpdateWrapper } from '../../hooks';
import { BodyRegular } from '../../styles/typography';
import { Row, SixDot } from '../common';
import { TeamTopicsAuthorIndicator } from '../common/TeamTopicsAuthorIndicator';
import { LiveItem, LiveItemProps } from '../input';
import Draggable from './Draggable';
import { LiveTypedUpdatedProps, LiveUpdateContainer, LiveUpdateSectionProps } from './LiveUpdate';
import { UpdateSectionTitle } from './LiveUpdateSection';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 30px;
    flex-shrink: 0;
`;

const Author = styled(BodyRegular)`
    justify-content: center;
    display: flex;
    position: absolute;
    right: 0px;
    flex-shrink: unset;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: #d4e2df;
    font-size: 12px;
    font-weight: 400;
    top: 0px;
`;

export type TeamTopicsUpdateSectionProps = Omit<LiveUpdateSectionProps, 'typedUpdates'> & {
    id?: string;
    hideAddLine?: boolean;
    agenda: BaseAgendaProps;
    typedUpdates: AuthoredUpdate[] | TypedUpdate[];
    currentAgendaItem?: BaseAgendaItemProps;
    isDisabled?: boolean;
    userId?: string;
};

function TeamTopicsBullet({
    userName,
    isCurrentAgendaItem,
    isMouseIn,
    disabled,
    dragRef,
}: {
    userName: string;
    isCurrentAgendaItem?: boolean;
    isMouseIn?: boolean;
    disabled?: boolean;
    dragRef: any;
}) {
    return (
        <div
            ref={dragRef}
            style={{
                position: 'absolute',
                left: '-26px',
                top: '7px',
                bottom: '0',
                display: 'flex',
                justifyContent: 'center',
                width: '20px',
            }}
        >
            {!isMouseIn || disabled || !dragRef ? (
                <TeamTopicsAuthorIndicator name={userName} isCurrentAgendaItem={isCurrentAgendaItem} />
            ) : (
                <SixDot />
            )}
        </div>
    );
}
// Wrapper for Draggable ref forwarding
export function LiveTeamTopic({
    typedUpdate,
    setTypedUpdate,
    emptyInputRef,
    setFocusedInput,
    saveTypedUpdate,
    saveFullTypedUpdate,
    index,
    isCurrentAgendaItem,
    isFocused,
    isDisabled,
    isMouseIn,
    typeProps,
    autoFocusNewInputOnMountAfter,
    typedUpdateCountOnMount,
    dragRef,
}: Omit<LiveItemProps, 'index' | 'typedUpdate'> &
    Pick<LiveTypedUpdatedProps, 'focusedInputIndex' | 'typeProps' | 'autoFocusNewInputOnMountAfter'> & {
        index: number;
        typedUpdate: AuthoredUpdate | TypedUpdate;
        isDisabled?: boolean;
        typedUpdateCountOnMount: number;
    }) {
    const [isHovering, setIsHovering] = useState<null | Record<number, boolean>>(null);
    const typedUpdateWrapper = useTypedUpdateWrapper(typedUpdate);
    const [user] = useGlobalAuthedUser();

    return (
        <LiveUpdateContainer key={typedUpdate.id} isTeamTopic={true}>
            <Row onMouseEnter={() => setIsHovering({ [index]: true })} onMouseLeave={() => setIsHovering(null)}>
                <TeamTopicsBullet
                    userName={typedUpdateWrapper.author ?? user.preferredName}
                    isCurrentAgendaItem={isCurrentAgendaItem || isDisabled}
                    isMouseIn={isMouseIn}
                    dragRef={dragRef}
                    disabled={isDisabled}
                />
                <LiveItem
                    hideBullet={true}
                    topicTitleInput={true}
                    index={index}
                    key={typedUpdate.id}
                    typedUpdate={typedUpdate}
                    isInNavDrawer={true}
                    setTypedUpdate={setTypedUpdate}
                    setFocusedInput={setFocusedInput}
                    onBlur={() => {
                        saveTypedUpdate(typedUpdate.text);
                        setFocusedInput(null);
                    }}
                    emptyInputRef={emptyInputRef}
                    isFocused={isFocused}
                    autoFocus={isFocused}
                    isAttachable={false}
                    isOmniboxSearchEnabled={false}
                    disabled={isCurrentAgendaItem || isDisabled}
                    isCurrentAgendaItem={isCurrentAgendaItem}
                    saveTypedUpdate={saveTypedUpdate}
                    saveFullTypedUpdate={saveFullTypedUpdate}
                    placeholder={typeProps.placeholder}
                    dragRef={dragRef}
                    isMouseIn={isMouseIn}
                    autoFocusOnMountAfter={
                        typedUpdateCountOnMount - 1 === index ? autoFocusNewInputOnMountAfter : undefined
                    }
                />
            </Row>
        </LiveUpdateContainer>
    );
}

export function TeamTopicsUpdateSection({
    typedUpdates,
    setTypedUpdates,
    agenda,
    updateViewProps,
    typeProps,
    isDisabled,
    currentAgendaItem,
    autoFocusNewInputOnMountAfter,
    hideAddLine,
    userId,
    isInNavDrawer,
}: TeamTopicsUpdateSectionProps): JSX.Element {
    const emptyInputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
    const [typedUpdateCountOnMount] = useState(typedUpdates.length || 1);
    const { createUpdateEmitter, createFullUpdateEmitter, createReorderEmitter } = updateViewProps;

    const moveDraggable = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const reorderedUpdates = typedUpdates.map((u) => u);
            reorderedUpdates.splice(dragIndex, 1);
            reorderedUpdates.splice(hoverIndex, 0, typedUpdates[dragIndex]);
            setTypedUpdates(reorderedUpdates);
        },
        [typedUpdates]
    );

    const [focusedInputIndex, setFocusedInputIndex] = useState<number | null>(null);

    useLiveSyncedFocus(
        typedUpdates,
        focusedInputIndex,
        setFocusedInputIndex,
        updateViewProps.standUpUpdate.getUpdatesForType(typeProps.spinachUpdateType)
    );

    useElasticLiveInputs(
        typedUpdates,
        setTypedUpdates,
        focusedInputIndex,
        typeProps.spinachUpdateType,
        updateViewProps.standUpUpdate.getUpdatesForType(typeProps.spinachUpdateType),
        undefined,
        isInNavDrawer
    );

    return (
        <Container id={ElementId.TeamTopicsDrawerContainer}>
            <Container style={{ marginLeft: '10px', marginRight: '20px', width: '95%', paddingBottom: '15px' }}>
                <UpdateSectionTitle typeProps={typeProps} />
                {typedUpdates.map((typedUpdate, i) => {
                    // Get remote typed update from all standup updates of all users
                    const remoteTypedUpdate = agenda.topicUpdates.find((i) => i.id === typedUpdate.id);

                    const setTypedUpdateByIndex = (index: number) => (typedUpdate: TypedUpdate) => {
                        const oldUpdate = typedUpdates[index];

                        const newUpdate: AuthoredUpdate | TypedUpdate = {
                            ...oldUpdate,
                            ...typedUpdate,
                        };
                        const newTypedUpdates = [...typedUpdates];

                        newTypedUpdates[index] = newUpdate;
                        setTypedUpdates(newTypedUpdates);
                    };

                    const setFocusedInputById = (index: number) => (nullable?: null) => {
                        setFocusedInputIndex(nullable === null ? null : index);
                    };

                    const setTypedUpdate = setTypedUpdateByIndex(i);
                    const setFocusedInput = setFocusedInputById(i);

                    const shouldCreate = !remoteTypedUpdate && typedUpdate.text !== '';

                    const emitTypedUpdateSave = createUpdateEmitter(typedUpdate);

                    const saveTypedUpdate = (text: string) => {
                        const isTextChanged = remoteTypedUpdate?.text !== text;
                        const shouldUpdate = !!remoteTypedUpdate && isTextChanged;

                        if (shouldCreate || shouldUpdate) {
                            emitTypedUpdateSave(text);
                        }
                    };

                    const emitFullTypedUpdateSave = createFullUpdateEmitter(typedUpdate);

                    const saveFullTypedUpdate = (update: TypedUpdate) => {
                        emitFullTypedUpdateSave(update);
                    };

                    const isSaved = i < typedUpdates.length - 1;
                    const isDraggable = typedUpdates.length > 2 && isSaved;
                    const emitOrderedUpdatesSave = createReorderEmitter && createReorderEmitter(typedUpdate);

                    return hideAddLine && i === typedUpdates.length - 1 ? null : (
                        <Draggable
                            key={typedUpdate.id}
                            index={i}
                            id={typedUpdate.id}
                            style={{
                                paddingLeft: 'unset',
                                marginLeft: 'unset',
                            }}
                            moveDraggable={moveDraggable}
                            isDraggable={isDraggable}
                            emitOrderedUpdatesSave={emitOrderedUpdatesSave}
                            type={`${typedUpdate.updateType}-${userId}`}
                            idList={typedUpdates.map((u) => u.id)}
                        >
                            <LiveTeamTopic
                                typedUpdate={typedUpdate}
                                setTypedUpdate={setTypedUpdate}
                                isFocused={focusedInputIndex === i}
                                emptyInputRef={emptyInputRef}
                                isDisabled={isDisabled}
                                setFocusedInput={setFocusedInput}
                                saveTypedUpdate={saveTypedUpdate}
                                saveFullTypedUpdate={saveFullTypedUpdate}
                                placeholder={typeProps.placeholder}
                                index={i}
                                disabled={currentAgendaItem?.id === typedUpdate.id}
                                isCurrentAgendaItem={currentAgendaItem?.id === typedUpdate.id}
                                focusedInputIndex={focusedInputIndex}
                                autoFocusOnMountAfter={
                                    typedUpdateCountOnMount - 1 === i ? autoFocusNewInputOnMountAfter : undefined
                                }
                                typeProps={typeProps}
                                typedUpdateCountOnMount={typedUpdateCountOnMount}
                                isOmniboxSearchEnabled={false}
                                isInNavDrawer={isInNavDrawer}
                            />
                        </Draggable>
                    );
                })}
            </Container>
        </Container>
    );
}
