import { Modal } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { useState } from 'react';

import { ClientEventType } from '@spinach-shared/types';
import { getBotName } from '@spinach-shared/utils';

import { GlobalModal } from '../../../atoms';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalModal } from '../../../hooks';
import { useGlobalSlack } from '../../../hooks/useSlack';
import { BodyRegular, ResponsiveModalTitle, lightTheme } from '../../../styles';
import { copyTextToClipboard } from '../../../utils';
import { Notification, PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BootstrapTooltip } from '../BootstrapTooltip';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

export const AddPrivateChannelInstructionsModal = () => {
    const [user] = useGlobalAuthedUser();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const { fetchSlackChannels } = useGlobalSlack();
    const [openNotification, setOpenNotification] = useState(false);

    const track = useExperienceTracking();

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Add Private Channel Instructions Modal',
        });
        setOpenNotification(false);
        setGlobalModal(null);
    };

    if (!user) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.AddPrivateChannelInstructions} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden' }}>
                <Column centered>
                    <Row centered>
                        <ResponsiveModalTitle>Don’t see your channel?</ResponsiveModalTitle>
                    </Row>
                    <Spacing factor={1 / 3} />
                </Column>

                <Column style={{ alignItems: 'start' }}>
                    <BodyRegular>
                        To enable meeting notes to be sent to a Private Slack Channel, head over to Slack and invite
                        Spinach AI to your private channel.
                    </BodyRegular>
                    <Spacing factor={1 / 3} />
                    <BodyRegular>
                        Type{' '}
                        <BootstrapTooltip title="Copy">
                            <span
                                onClick={() => {
                                    setOpenNotification(true);
                                    copyTextToClipboard(`/invite @${getBotName()}`);
                                }}
                                style={{
                                    cursor: 'pointer',
                                    color: lightTheme.primary.greenDark,
                                    backgroundColor: '#F7F7F7',
                                    padding: '3px',
                                    marginRight: '3px',
                                }}
                            >
                                {`/invite @${getBotName()}`}
                            </span>
                        </BootstrapTooltip>
                        in your private channel
                    </BodyRegular>
                </Column>
                <span style={{ flexGrow: 1 }} />
                <PrimaryButton
                    title="Done"
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Add Private Channel Instructions Modal Done',
                        });
                        fetchSlackChannels(true);
                        closeModal();
                    }}
                />
                <Spacing factor={1 / 3} />
                <SecondaryButton title="Cancel" onClick={closeModal} />
                {openNotification ? (
                    <Notification
                        containerStyle={{ position: 'absolute', top: 'unset' }}
                        isOpen={!!openNotification}
                        onClose={() => setOpenNotification(false)}
                        message={'Copied! Now, paste it in your private channel.'}
                        icon={
                            <CheckCircle
                                style={{ color: lightTheme.neutrals.white }}
                                htmlColor={lightTheme.neutrals.white}
                            />
                        }
                    />
                ) : (
                    <></>
                )}
            </SpinachModalContent>
        </Modal>
    );
};
