import { Socket } from 'socket.io-client';
import styled from 'styled-components';

import {
    challengesUpdateSectionTypeProps,
    icebreakerUpdateSectionTypeProps,
    todaysUpdateSectionTypeProps,
    yesterdaysHighlightsUpdateSectionTypeProps,
} from '@spinach-shared/constants';
import { SentimentPickerLocation } from '@spinach-shared/types';

import { ElementId } from '../../constants';
import { useGlobalLiveSeries, usePersonaDemo } from '../../hooks';
import { Row, Spacing } from '../common';
import { CheckInFeatureCalloutContainer } from '../common/feature-discovery/CheckInFeatureCalloutContainer';
import { CustomUpdates } from './CustomUpdates';
import { LiveReservedUpdateSection } from './LiveReservedUpdateSection';
import { MoodSelectionComponent, MoodSelectionComponentHeader } from './MoodSelectionComponent';

type CurrentUpdateSectionProps = {
    socket: Socket;
    isInNavDrawer?: boolean;
};

const StaticRow = styled(Row)`
    flex: unset;
    flex-shrink: 0;
`;

const CurrentUpdateSectionContainer = styled.div`
    background-color: ${(props) => props.theme.neutrals.offWhite};
`;

export function CurrentUpdateSection({ socket, isInNavDrawer }: CurrentUpdateSectionProps): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();
    const { isIcebreakerLockedIn } = liveSeries.currentMeeting;
    const isPersonaDemo = usePersonaDemo();

    return (
        <CurrentUpdateSectionContainer id={ElementId.CurrentCheckinContainer}>
            <StaticRow id="current-checkin-row"></StaticRow>
            <Spacing factor={0.5} />

            <CheckInFeatureCalloutContainer />

            <LiveReservedUpdateSection
                agendaItem={liveSeries.thisParticipantsYTBAgendaItem}
                socket={socket}
                isInNavDrawer={isInNavDrawer}
                typeProps={yesterdaysHighlightsUpdateSectionTypeProps}
            />

            <LiveReservedUpdateSection
                agendaItem={liveSeries.thisParticipantsYTBAgendaItem}
                socket={socket}
                isInNavDrawer={isInNavDrawer}
                typeProps={todaysUpdateSectionTypeProps}
                id={ElementId.TodaySection}
            />

            <LiveReservedUpdateSection
                agendaItem={liveSeries.thisParticipantsYTBAgendaItem}
                socket={socket}
                isInNavDrawer={isInNavDrawer}
                typeProps={challengesUpdateSectionTypeProps}
            />

            <CustomUpdates
                isInNavDrawer={isInNavDrawer}
                socket={socket}
                agendaItem={liveSeries.thisParticipantsYTBAgendaItem}
            />

            {isIcebreakerLockedIn ? (
                <LiveReservedUpdateSection
                    agendaItem={liveSeries.thisParticipantsYTBAgendaItem}
                    socket={socket}
                    typeProps={icebreakerUpdateSectionTypeProps}
                    id={ElementId.IcebreakerSection}
                    isInNavDrawer={isInNavDrawer}
                />
            ) : null}

            {!isPersonaDemo ? (
                <>
                    <MoodSelectionComponentHeader />
                    <MoodSelectionComponent location={SentimentPickerLocation.TeamMood} />
                </>
            ) : null}
        </CurrentUpdateSectionContainer>
    );
}
