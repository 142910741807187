import { useGlobalAdmin } from '../../../../../..';
import { Hairline, Spacing } from '../../../../common';
import { AdminSelectedHistoryInfo } from './AdminSelectedHistoryInfo';
import { AdminSelectedUserInfo } from './AdminSelectedUserInfo';
import { AdminUserHistorySelection } from './AdminUserHistorySelection';
import { AdminUserSearch } from './AdminUserSearch';

export function UserContentAccess(): JSX.Element {
    const { state } = useGlobalAdmin();
    const { searchedUser, searchedHistory } = state;

    return (
        <>
            <AdminUserSearch />
            <Spacing factor={1 / 2} />

            {searchedUser ? <AdminSelectedUserInfo /> : null}

            {searchedUser ? <AdminUserHistorySelection /> : null}

            {searchedUser && searchedHistory ? <AdminSelectedHistoryInfo /> : null}

            <Hairline />
        </>
    );
}
