function createCopyListener(text: string, withoutHtml?: boolean) {
    return function listener(e: ClipboardEvent) {
        if (!withoutHtml) {
            e.clipboardData?.setData('text/html', text);
        }
        e.clipboardData?.setData('text/plain', text);
        e.preventDefault();
    };
}

/**
 *
 * @param text Text to be copied to clipboard
 * @param customId an element ID to append the copy scratch text area in case body is blocked by a modal or some such element
 */
export function copyTextToClipboard(text: string, customId?: string, withoutHtml?: boolean): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';
    textArea.contentEditable = 'true';
    textArea.readOnly = true;

    if (customId) {
        document.getElementById(customId)!.appendChild(textArea);
    } else {
        document.body.appendChild(textArea);
    }

    textArea.focus();
    textArea.select();

    const listener = createCopyListener(text, withoutHtml);

    try {
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    } catch (_) {}

    if (customId) {
        document.getElementById(customId)!.removeChild(textArea);
    } else {
        document.body.removeChild(textArea);
    }
}

export const doNotCopyAttributeName = 'data-do-not-copy';

export function copyElementContent(elementId: string): void {
    // Get the element by its ID
    const element = document.getElementById(elementId);

    if (!element) {
        return;
    }

    // Create a temporary element to hold the styled content without background
    const tempElement = document.createElement('div');
    tempElement.innerHTML = element.innerHTML;

    const elementsToRemove = tempElement.querySelectorAll(`[${doNotCopyAttributeName}]`);
    elementsToRemove.forEach((el) => el.remove());

    // Copy all computed styles, except background-related ones
    const style = window.getComputedStyle(element);
    for (let i = 0; i < style.length; i++) {
        const prop = style[i];
        if (!prop.startsWith('background')) {
            tempElement.style.setProperty(prop, style.getPropertyValue(prop));
        }
    }

    // Apply the style to all child elements as well
    copyStylesRecursively(element, tempElement);

    // Temporarily add the element to the DOM (it needs to be in the DOM to be selectable)
    tempElement.style.position = 'absolute';
    tempElement.style.left = '-9999px';
    document.body.appendChild(tempElement);

    // Select the temporary element
    const range = document.createRange();
    range.selectNode(tempElement);
    const selection = window.getSelection();
    if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);

        // Copy the selected content
        document.execCommand('copy');

        // Clean up
        selection.removeAllRanges();
    }

    // Remove the temporary element
    document.body.removeChild(tempElement);
}

function copyStylesRecursively(sourceElement: Element, targetElement: Element) {
    const sourceChildren = sourceElement.children;
    const targetChildren = targetElement.children;

    for (let i = 0; i < sourceChildren.length; i++) {
        const sourceChild = sourceChildren[i];
        const targetChild = targetChildren[i];

        if (sourceChild instanceof HTMLElement && targetChild instanceof HTMLElement) {
            const style = window.getComputedStyle(sourceChild);
            for (let j = 0; j < style.length; j++) {
                const prop = style[j];
                if (!prop.startsWith('background')) {
                    targetChild.style.setProperty(prop, style.getPropertyValue(prop));
                }
            }
            copyStylesRecursively(sourceChild, targetChild);
        }
    }
}
