import { DemoModal } from '../atoms';
import { useGlobalAuthedUser, useGlobalDemoState, useGlobalModal, useGlobalRouting } from '../hooks';

export const usePracticeRoundCompletion = () => {
    const [user] = useGlobalAuthedUser();
    const { routeToDirectExperience } = useGlobalRouting();
    const [_, setOpenModal] = useGlobalModal();
    const { setDemoModal } = useGlobalDemoState();

    async function completePracticeRound() {
        if (!user.metadata.isOnboarded) {
            setDemoModal(DemoModal.TransitionToOnboarding);
        } else {
            leavePracticeRound();
        }
    }

    function leavePracticeRound() {
        routeToDirectExperience();

        setOpenModal(null);
    }

    return { leavePracticeRound, completePracticeRound };
};
