import { SpinachUpdateType } from './Series';

export enum SpinachComponentKind {
    List = 'List',
}

export enum CompositionalComponentKind {
    Roundtable = 'Roundtable',
}

export type SpinachComponent = {
    id: string;
    kind: SpinachComponentKind;
};

export type CompositionalComponent = {
    id: string;
    kind: CompositionalComponentKind;
    components: SpinachComponent[];
};

export type ReservedListType = Exclude<SpinachUpdateType, SpinachUpdateType.Custom>;

export type SpinachList = {
    id: string;
    kind: SpinachComponentKind;
    title: string;
    reservedType?: ReservedListType;
    isHidden: boolean;
    placeholder?: string;
};

export type RoundtableComponent = {
    id: string;
    kind: CompositionalComponentKind;
    isHidden: boolean;
    name: string;
    components: SpinachList[];
};

export type SpinachComponents = RoundtableComponent;
