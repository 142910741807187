import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import {
    getCombinedBrandedVideoBackgroundImageUri,
    getCustomBrandedImageUriForUser,
    getSpinachBackgroundImageUri,
    getUser,
} from '../apis';
import {
    atomBrandedImageUri,
    atomSpinachCombinedVideoBackgroundImageUri,
    atomSpinachVideoBackgroundImageUri,
} from '../atoms';
import { useGlobalAuthedUser } from './useGlobalUser';

export function useGlobalBrandedImageUri(): [string | null, (uri: string | null) => void] {
    const [brandedImageUri, setBrandedImageUri] = useRecoilState(atomBrandedImageUri);

    return [brandedImageUri, setBrandedImageUri];
}

export function useGlobalSpinachVideoBackgroundImageUri(): [string | null, (uri: string | null) => void] {
    const [brandedImageUri, setBrandedImageUri] = useRecoilState(atomSpinachVideoBackgroundImageUri);

    return [brandedImageUri, setBrandedImageUri];
}

export function useGlobalCombinedSpinachVideoBackgroundImageUri(): [string | null, (uri: string | null) => void] {
    const [combinedBrandedVideoBackgroundImageUri, setCombinedBrandedVideoBackgroundImageUri] = useRecoilState(
        atomSpinachCombinedVideoBackgroundImageUri
    );

    return [combinedBrandedVideoBackgroundImageUri, setCombinedBrandedVideoBackgroundImageUri];
}

export function useCustomUserBrandedImage() {
    const [hasFetched, setHasFetched] = useState(false);
    const [brandedImageUri, setBrandedImageUri] = useGlobalBrandedImageUri();
    const [spinachVideoBackgroundImageUri, setSpinachVideoBackgroundImageUri] =
        useGlobalSpinachVideoBackgroundImageUri();
    const [combinedBrandedVideoBackgroundImageUri, setCombinedBrandedVideoBackgroundImageUri] =
        useGlobalCombinedSpinachVideoBackgroundImageUri();

    const [user, setGlobalUser] = useGlobalAuthedUser();

    useEffect(() => {
        const fetch = async () => {
            let customBrandedImageUri;
            if (!brandedImageUri) {
                customBrandedImageUri = await getCustomBrandedImageUriForUser();

                setBrandedImageUri(customBrandedImageUri ?? null);
                const { user } = await getUser();
                if (user) {
                    setGlobalUser(user);
                }
            }
            let customBrandedBackgroundImageUri;
            if (!spinachVideoBackgroundImageUri) {
                customBrandedBackgroundImageUri = await getSpinachBackgroundImageUri();
                if (customBrandedBackgroundImageUri) {
                    setSpinachVideoBackgroundImageUri(customBrandedBackgroundImageUri);
                }
            }

            let customCombinedBrandedBackgroundVideoUri;
            if (!combinedBrandedVideoBackgroundImageUri) {
                customCombinedBrandedBackgroundVideoUri = await getCombinedBrandedVideoBackgroundImageUri();
                if (customCombinedBrandedBackgroundVideoUri) {
                    setCombinedBrandedVideoBackgroundImageUri(customCombinedBrandedBackgroundVideoUri);
                }
            }

            setHasFetched(true);
        };

        if (hasFetched) {
            return;
        }

        if (user && user.isCustomBrandedImageEnabled && (user.metadata.brandedImageId || brandedImageUri)) {
            fetch();
        } else if (brandedImageUri || spinachVideoBackgroundImageUri || combinedBrandedVideoBackgroundImageUri) {
            setBrandedImageUri(null);
            setSpinachVideoBackgroundImageUri(null);
            setCombinedBrandedVideoBackgroundImageUri(null);
        }
    }, [
        user,
        brandedImageUri,
        spinachVideoBackgroundImageUri,
        combinedBrandedVideoBackgroundImageUri,
        setBrandedImageUri,
        setCombinedBrandedVideoBackgroundImageUri,
        setSpinachVideoBackgroundImageUri,
        setGlobalUser,
        hasFetched,
    ]);
}
