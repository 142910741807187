import { atom } from 'recoil';

export type LocalStorageState = {
    hasUserSeenProvisioningInstructions: boolean;
};

/**
 * Created this atom so that we can access the install URL wherever we need,
 * without needing to re-fetch the generated URL at time of need.
 */

export const atomLocalStorage = atom<LocalStorageState>({
    key: 'localStorageState',
    default: {
        hasUserSeenProvisioningInstructions: false,
    },
});
