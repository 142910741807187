import { getScribeEmails } from '@spinach-shared/constants';
import { getStage } from '@spinach-shared/utils';

export function getAllScribeEmails(): readonly string[] {
    return getScribeEmails(getStage());
}

export function isScribeEmail(email?: string | null): boolean {
    return !!email && getAllScribeEmails().includes(email.toLowerCase());
}
