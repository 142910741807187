import styled, { css, keyframes } from 'styled-components';

export type FadeInAnimationProps = {
    fadeInDuration?: number;
};

export type PulseAnimationProps = {
    animationDuration: number;
    scale: number;
    numberOfPulses?: number;
};

type PulseKeyframeProps = Pick<PulseAnimationProps, 'scale'>;

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const pulse = (props: PulseKeyframeProps) => keyframes`
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(${props.scale}, ${props.scale});
    }
    100% {
        transform: scale(1, 1);
    }
`;

export const rotate = keyframes`
    0% {
        transform: rotate(0deg)
    }
    50% {
        transform: rotate(180deg)
    }
    100% {
        transform: rotate(360deg)
    }
`;

export const fadeInAnimation = (fadeInDuration: number) => css`
    animation: ${fadeIn} ${fadeInDuration}ms ease-in;
`;

export const loadingTextKeyframes = keyframes`
  0% {margin-top: 0;}
  10% {margin-top: 0;}
  20% {margin-top: -5.62rem;}
  30% {margin-top: -5.62rem;}
  40% {margin-top: -11.24rem;}
  60% {margin-top: -11.24rem;}
  70% {margin-top: -5.62rem;}
  80% {margin-top: -5.62rem;}
  90% {margin-top: 0;}
  100% {margin-top: 0;} 
`;

export const oneWayLoadingTextKeyframes = keyframes`
  0% {margin-top: 0;}
  20% {margin-top: 0;}
  40% {margin-top: -5.62rem;}
  60% {margin-top: -5.62rem;}
  80% {margin-top: -11.24rem;}
  100% {margin-top: -11.24rem;}
`;

const transitionFromRight = keyframes`
  0% {right: -270px;}
  100% {right: 10px;}
`;

export const animateSlackNotification = () => css`
    animation: ${transitionFromRight} 0.3s;
    animation-timing-function: linear;
    animation-delay: 500ms;
`;

export const loadingTextAnimation = () => css`
    animation: ${loadingTextKeyframes} 8s infinite;
`;

export const oneWayLoadingTextAnimation = () => css`
    animation: ${oneWayLoadingTextKeyframes} 4s;
    animation-fill-mode: forwards;
`;

export const AnimatedSection = styled.section`
    height: 50px;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
`;

export const AnimatingText = styled.div`
    ${loadingTextAnimation};
`;

export const AnimatedText = styled.div`
    height: 2.81rem;
    margin-bottom: 2.81rem;
    display: inline-block;
    display: flex;
    align-items: center;
`;

export const rotateAnimation = () => css`
    animation: ${rotate} 1000ms ease-in infinite;
`;

export const pulseAnimation = ({ animationDuration, scale, numberOfPulses = 2 }: PulseAnimationProps) => css`
    animation: ${pulse({ scale })} ${animationDuration}ms ease-in-out;
    animation-iteration-count: ${numberOfPulses};
`;
