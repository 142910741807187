import { ClientEventType } from '@spinach-shared/types';

import GoogleMeetSrc from '../../../assets/google-meet.png';
import { useExperienceTracking } from '../../../hooks';
import { URLUtil, isChromeExtensionPlatform } from '../../../utils';
import { AppIntegrationRow, IntegrationButtonText } from './AppIntegrationRow';

export function GoogleMeetIntegrationRow(): JSX.Element {
    const track = useExperienceTracking();

    return (
        <AppIntegrationRow
            onCTAClick={async () => {
                track(ClientEventType.AddChromeExtentionClick);
                URLUtil.openURL(
                    'https://chrome.google.com/webstore/detail/spinachio-daily-standup-t/eippeohhelpgpnchcfigkjmpepicilig',
                    { redirectThroughBackendWhenOnZoom: true }
                );
            }}
            subtext={'View Spinach directly in Meet'}
            label={'Google Meet'}
            icon={<img src={GoogleMeetSrc} style={{ objectFit: 'contain', width: '24px', height: '25px' }} />}
            buttonText={IntegrationButtonText.Add}
            isConnected={isChromeExtensionPlatform()}
        />
    );
}
