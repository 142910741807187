import { MeetingCompanyAllHandsJSON } from '@spinach-shared/types';

import { SectionComponentProps } from '../shared';
import { GenericSectionEditor, SectionConfiguration } from './GenericSectionEditor';

export function CompanyAllHandsSectionEditor({ summaryJSON, setSummaryJSON }: SectionComponentProps): JSX.Element {
    const sections: SectionConfiguration<MeetingCompanyAllHandsJSON>[] = [
        { title: 'Company Updates', itemType: 'Company Update', key: 'companyUpdates', kind: 'generated-items' },
        {
            title: 'Employee Recognitions',
            itemType: 'Recognition',
            key: 'employeeRecognition',
            kind: 'generated-items',
        },
        { title: 'Q&A Session', itemType: 'Q&A', key: 'qaSession', kind: 'generated-items' },
        { title: 'Future Plans', itemType: 'Plan', key: 'futurePlans', kind: 'generated-items' },
    ];

    return (
        <GenericSectionEditor<MeetingCompanyAllHandsJSON, 'companyAllHandsSummary'>
            summaryJSON={summaryJSON}
            setSummaryJSON={setSummaryJSON}
            sections={sections}
            sectionKey="companyAllHandsSummary"
        />
    );
}
