import { InputAdornment, Modal, TextField } from '@material-ui/core';
import { CheckCircle, InfoOutlined } from '@material-ui/icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { MILLIS_IN_SECONDS, SECONDS_IN_MINUTES } from '@spinach-shared/constants';
import { ClientEventType, SlackHuddleClientConfiguration } from '@spinach-shared/types';
import { isEmailValid } from '@spinach-shared/utils';

import {
    BootstrapTooltip,
    GlobalModal,
    ListItemValue,
    LoadingSquares,
    Notification,
    ScrollArea,
    URLUtil,
    copyTextToClipboard,
    createSlackHuddle,
    useClickTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useSpinachInputStyles,
    validateSlackDomain,
    withAccessibleSubmitProps,
    withContentMasking,
} from '../../../..';
import { fetchSlackHuddleConfiguration } from '../../../apis/slack-huddles/fetchSlackHuddleConfiguration';
import SlackDomainInstructions from '../../../assets/slack/copy-slack-domain-instructions.png';
import HuddleExampleWindowImage from '../../../assets/slack/huddle-example-window.png';
import SlackHuddeExample from '../../../assets/slack/huddle-example.png';
import { useGlobalModal } from '../../../hooks';
import { useGlobalSlackHuddleConfiguration } from '../../../hooks/slack-huddles';
import { BodyRegularOnboard, ButtonSize, ResponsiveModalTitle, lightTheme } from '../../../styles';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { ItemList } from '../ItemList';
import { SpinachModalContent } from '../SpinachModalContent';
import { SpinachSwitch } from '../SpinachSwitch';
import { Row, Spacing } from '../framing';

const WarningSection = styled.span`
    flex-direction: column;
    justify-content: start;
    display: flex;
    background-color: ${() => lightTheme.secondary.orangeLight};
    padding: 12px;
`;

const UnorderedList = styled.ul`
    margin: unset;
`;

enum ConfigureSlackHuddlesStep {
    StepPreview = 'step-preview',
    InputDomainStep = 'input-domain',
    ConfigureOptions = 'configure-options',
    InviteBotUser = 'invite-bot-user',
    WaitForBotInvite = 'wait-for-bot-invite',
    StartHuddleWithSpinachStep = 'start-huddle-with-spinach',
    WaitForHuddleVerificationStep = 'wait-for-huddle-verification',
    Success = 'success',
}

type ConfigureSlackHuddleStepProps<T extends object | undefined = undefined> = {
    nextStep: (options?: T) => void;
    previousStep: () => void;
    isLoading?: boolean;
};

type ConfigureSlackHuddlesSuccessProps = ConfigureSlackHuddleStepProps<undefined> & {
    goToStep: (step: ConfigureSlackHuddlesStep) => void;
};

const useAutoAdvanceOnHuddleChange = ({
    nextStep,
    previousStep,
    propertyToCheckFor,
    failureMessage,
}: ConfigureSlackHuddleStepProps & {
    failureMessage: string;
    propertyToCheckFor: keyof SlackHuddleClientConfiguration;
}) => {
    const { setToastText } = useGlobalAiDashboard();

    useEffect(() => {
        const intervalId = setInterval(() => {
            const fetchLatest = async () => {
                const latestSlackHuddle = await fetchSlackHuddleConfiguration();
                if (!!latestSlackHuddle?.[propertyToCheckFor]) {
                    nextStep();
                }
            };
            fetchLatest();
        }, MILLIS_IN_SECONDS * 2);

        const timeoutId = setTimeout(() => {
            setToastText(failureMessage);
            previousStep();
        }, MILLIS_IN_SECONDS * SECONDS_IN_MINUTES * 5);
        return () => {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
        };
    }, []);
};

/**
 * @NOTE only open this if it's the "first time"
 * This means the user does not actively have a huddle user waiting for connection
 */
const ConfigureSlackHuddlesStepPreview = ({ nextStep }: ConfigureSlackHuddleStepProps) => {
    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Slack Huddles</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 3} />
            <img src={SlackHuddeExample} style={{ maxHeight: '250px', flexShrink: 1 }} />
            <Spacing />
            <WarningSection>
                <BodyRegularOnboard style={{ fontWeight: '700' }}>Requirements before you proceed:</BodyRegularOnboard>
                <BodyRegularOnboard>1. Access to Slack to invite members (typically admin)</BodyRegularOnboard>
                <BodyRegularOnboard>2. Add a paid seat for Spinach to join huddles</BodyRegularOnboard>
            </WarningSection>
            <Spacing />
            <span style={{ flexGrow: 1 }} />
            <PrimaryButton title="Continue" onClick={() => nextStep()} />
            <Spacing factor={1 / 2} />
        </>
    );
};

const ConfigureSlackHuddlesInputDomainStep = ({
    nextStep,
    previousStep,
}: ConfigureSlackHuddleStepProps<{ slackDomain: string }>) => {
    const [isLoading, setIsLoading] = useState(false);
    const [globalSlackHuddleConfiguration] = useGlobalSlackHuddleConfiguration();
    const [slackDomain, setSlackDomain] = useState<string>(globalSlackHuddleConfiguration?.slackDomain ?? '');
    const classes = useSpinachInputStyles({
        value: slackDomain,
        muiInputMultilineOverrides: {
            borderBottom: `1px solid ${lightTheme.neutrals.gray}`,
        },
    });
    const doesDomainStartWithHttp = slackDomain.startsWith('http://');
    const doesDomainStartWithHttps = slackDomain.startsWith('https://');
    const slackDomainUrl = doesDomainStartWithHttp || doesDomainStartWithHttps ? slackDomain : `https://${slackDomain}`;
    const { setToastText } = useGlobalAiDashboard();

    const onNext = async () => {
        setIsLoading(true);
        const isDomainValid = await validateSlackDomain(slackDomain);
        setIsLoading(false);
        if (!isDomainValid) {
            setToastText('Domain must be the same used with your Spinach AI Slack installation');
            return;
        }

        nextStep({ slackDomain });
    };
    /**
     * @NOTE this may not work for customers that have an enterprise teamType,
     * as they may have a custom Slack domain. But, we don't really officially support that anyway
     */
    const isError = !!slackDomain && (!URLUtil.isUrl(slackDomainUrl) || !slackDomainUrl.endsWith('.slack.com'));
    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Your Slack Domain</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 3} />
            <BodyRegularOnboard>
                Enter your Slack worskspace domain. Typically found in the top left corner of the application (ie.
                team.slack.com)
            </BodyRegularOnboard>
            <Spacing factor={1 / 3} />
            <TextField
                autoFocus
                {...withContentMasking(classes.root)}
                style={{ width: '95%' }}
                placeholder="Slack Domain"
                multiline={false}
                value={slackDomain}
                error={isError}
                helperText={isError ? 'Domain must be a valid Slack domain URL' : ''}
                onChange={async (e) => {
                    const domain = e.target.value.replace(/ /g, '');
                    setSlackDomain(domain);
                }}
                onKeyDown={async (e) => {
                    if (!!slackDomain && !isError && e.key === 'Enter') {
                        onNext();
                    }
                }}
            />
            <Spacing />
            <img src={SlackDomainInstructions} style={{ flexShrink: 1, maxHeight: '235px' }} />
            <Spacing />

            <span style={{ flexGrow: 1 }} />
            <PrimaryButton disabled={!slackDomain || isError} isLoading={isLoading} title="Next" onClick={onNext} />
            <Spacing factor={1 / 2} />
            <SecondaryButton title="Go Back" onClick={previousStep} />
        </>
    );
};

const ConfigureSlackHuddlesConfigureOptionsStep = ({
    nextStep,
    previousStep,
    isLoading,
}: ConfigureSlackHuddleStepProps<{
    emails: string[] | null;
    autoJoinPublicHuddles: boolean;
    askToJoinPrivateHuddles: boolean;
}>) => {
    const clickTracking = useClickTracking();

    const [user] = useGlobalAuthedUser();
    const [slackHuddleConfig] = useGlobalSlackHuddleConfiguration();
    const [currentEmail, setCurrentEmail] = useState('');
    const initialEmails = slackHuddleConfig?.filterHuddlesByUserEmails ?? [user.email];
    const [emails, setEmails] = useState<string[]>(initialEmails);
    const [joinAllPublicHuddles, setJoinAllPublicHuddles] = useState(
        !slackHuddleConfig || slackHuddleConfig.filterHuddlesByUserEmails === null
    );
    const [autoJoinPublicHuddles, setAutoJoinPublicHuddles] = useState(
        slackHuddleConfig?.autoJoinPublicHuddles ?? true
    );
    const [askToJoinPrivateHuddles, setAskToJoinPrivateHuddles] = useState(
        slackHuddleConfig?.askToJoinPrivateHuddles ?? true
    );
    const classes = useSpinachInputStyles({ value: currentEmail });
    const listItemValues: ListItemValue<string, JSX.Element>[] = emails.map((email) => {
        return {
            code: email,
            label: (
                <Row style={{ width: 'fit-content' }} vCenter>
                    <BodyRegularOnboard>{email}</BodyRegularOnboard>
                    <Spacing horizontal factor={1 / 3} />
                </Row>
            ),
            postContent:
                // Don't allow the user to be able to remove themself
                email === user.email ? null : (
                    <span
                        key={email}
                        style={{
                            cursor: 'pointer',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        {...withAccessibleSubmitProps(() => {
                            clickTracking(ClientEventType.AIDashboardClick, 'Remove Email From Slack Huddle User List');
                            setEmails(emails.filter((e) => e !== email));
                        })}
                    >
                        <HighlightOffIcon htmlColor="gray" />
                    </span>
                ),
        };
    });
    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Who should Spinach follow?</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 3} />
            <BodyRegularOnboard>
                Identify which users Spinach will follow. If a followed user is in a public channel huddle and public
                channels are enabled, Spinach will automatically join the huddle.
            </BodyRegularOnboard>
            <Spacing />
            <Row>
                <BodyRegularOnboard>
                    Follow <span style={{ textDecoration: 'italic' }}>all</span> users in my workspace:
                </BodyRegularOnboard>
                <SpinachSwitch
                    scale={0.8}
                    checked={joinAllPublicHuddles}
                    onChange={() => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Join All Public Channels Toggle');
                        setJoinAllPublicHuddles(!joinAllPublicHuddles);
                    }}
                />
                <BootstrapTooltip title="Spinach will auto-join all public channel huddles in your workspace.">
                    <InfoOutlined
                        style={{ color: lightTheme.primary.greenLight }}
                        htmlColor={lightTheme.primary.greenLight}
                    />
                </BootstrapTooltip>
            </Row>
            <Spacing factor={1 / 5} />
            {!joinAllPublicHuddles ? (
                <>
                    <TextField
                        autoFocus
                        {...withContentMasking(classes.root)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <PrimaryButton
                                        title={'Add'}
                                        disabled={!currentEmail || !isEmailValid(currentEmail)}
                                        size={ButtonSize.Small}
                                        onClick={() => {
                                            const emailList = [...new Set([...emails, currentEmail])];
                                            clickTracking(
                                                ClientEventType.AIDashboardClick,
                                                'Add Email To Slack Huddle User List',
                                                {
                                                    NumberOfEmails: emailList.length,
                                                    AddMethod: 'Click',
                                                }
                                            );
                                            setEmails(emailList);
                                            setCurrentEmail('');
                                        }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        style={{ width: '95%' }}
                        placeholder="Add email"
                        value={currentEmail}
                        onChange={(e) => {
                            setCurrentEmail(e.target.value.replace(/ /g, ''));
                        }}
                        onKeyDown={async (e) => {
                            if (!currentEmail || !isEmailValid(currentEmail)) {
                                return;
                            }

                            if (e.key !== 'Enter') {
                                return;
                            }

                            const emailList = [...new Set([...emails, currentEmail])];
                            clickTracking(ClientEventType.AIDashboardClick, 'Add Email To Slack Huddle User List', {
                                NumberOfEmails: emailList.length,
                                AddMethod: 'Key Down Enter',
                            });
                            setEmails(emailList);
                            setCurrentEmail('');
                        }}
                    />

                    <Spacing />
                    <BodyRegularOnboard>People Spinach will join in Huddles</BodyRegularOnboard>
                    <ScrollArea style={{ height: '15vh', maxHeight: '250px' }} sidePadding={0}>
                        <ItemList style={{ borderBottom: '1px solid lightgray' }} values={listItemValues} />
                    </ScrollArea>
                </>
            ) : null}
            <Spacing />

            <Row>
                <BodyRegularOnboard>Auto join Public Channel Huddles:</BodyRegularOnboard>
                <SpinachSwitch
                    scale={0.8}
                    checked={autoJoinPublicHuddles}
                    onChange={() => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Auto Join Public Channels Toggle');
                        setAutoJoinPublicHuddles(!autoJoinPublicHuddles);
                    }}
                />
                <BootstrapTooltip title="Spinach will auto-join public channel huddles only if someone from the list above is present.">
                    <InfoOutlined
                        style={{ color: lightTheme.primary.greenLight }}
                        htmlColor={lightTheme.primary.greenLight}
                    />
                </BootstrapTooltip>
            </Row>
            <Spacing factor={1 / 5} />
            <Row>
                <BodyRegularOnboard>Request to join Private Huddles:</BodyRegularOnboard>
                <SpinachSwitch
                    scale={0.8}
                    checked={askToJoinPrivateHuddles}
                    onChange={() => {
                        clickTracking(ClientEventType.AIDashboardClick, 'Ask To Join Private Channels Toggle');
                        setAskToJoinPrivateHuddles(!askToJoinPrivateHuddles);
                    }}
                />
                <BootstrapTooltip title="Spinach cannot automatically join private or direct message huddles. When enabled, you'll get a join request to approve.">
                    <InfoOutlined
                        style={{ color: lightTheme.primary.greenLight }}
                        htmlColor={lightTheme.primary.greenLight}
                    />
                </BootstrapTooltip>
            </Row>
            <Spacing />

            <span style={{ flexGrow: 1 }} />
            <PrimaryButton
                title={slackHuddleConfig?.successfullySetup ? 'Save' : 'Continue'}
                /**
                 * if join all public huddles is false, that means we require at least
                 * one email to be set
                 * */
                disabled={!joinAllPublicHuddles && !!emails && !emails.length ? true : false}
                isLoading={isLoading}
                onClick={() => {
                    nextStep({
                        emails: joinAllPublicHuddles ? null : emails,
                        autoJoinPublicHuddles,
                        askToJoinPrivateHuddles,
                    });
                }}
            />
            <Spacing factor={1 / 2} />
            <SecondaryButton title="Go Back" onClick={previousStep} />
        </>
    );
};

const ConfigureSlackHuddlesInviteBotUserStep = ({ previousStep, nextStep }: ConfigureSlackHuddleStepProps) => {
    const [openNotification, setOpenNotification] = useState(false);
    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Step 1</ResponsiveModalTitle>
            </Row>
            <Notification
                containerStyle={{ position: 'absolute', bottom: 'unset' }}
                isOpen={!!openNotification}
                onClose={() => setOpenNotification(false)}
                message={'Copied!'}
                icon={
                    <CheckCircle style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />
                }
            />
            <Spacing factor={1 / 3} />
            <BodyRegularOnboard>Go to Slack to complete the remaining steps.</BodyRegularOnboard>
            <Spacing factor={1 / 3} />
            <BodyRegularOnboard>
                Invite{' '}
                <BootstrapTooltip title="Copy">
                    <span
                        onClick={() => {
                            setOpenNotification(true);
                            copyTextToClipboard(`bot@slack.spinach.io`);
                        }}
                        style={{
                            cursor: 'pointer',
                            color: lightTheme.primary.greenDark,
                            fontWeight: 'bold',
                            backgroundColor: '#F7F7F7',
                            padding: '3px',
                            marginRight: '3px',
                        }}
                    >
                        bot@slack.spinach.io
                    </span>
                </BootstrapTooltip>{' '}
                as a full member of the workspace
            </BodyRegularOnboard>
            <Spacing />
            <WarningSection>
                <span style={{ fontWeight: 'bold' }}>Note</span>
                <Spacing factor={1 / 3} />
                <UnorderedList>
                    <li>
                        In order to join channels and notify users, we need to be invited as a full member, not a guest
                    </li>
                    <li>Admin may need to approve</li>
                    <li>You may need to add a paid seat for Spinach to join huddles</li>
                </UnorderedList>
            </WarningSection>
            <Spacing />
            <span style={{ flexGrow: 1 }} />
            <PrimaryButton title="I've invited the bot" onClick={() => nextStep()} />
            <Spacing factor={1 / 2} />
            <SecondaryButton title="Go Back" onClick={previousStep} />
        </>
    );
};

const WaitForBotInviteStep = ({ nextStep, previousStep }: ConfigureSlackHuddleStepProps) => {
    useAutoAdvanceOnHuddleChange({
        nextStep,
        previousStep,
        propertyToCheckFor: 'hasReceivedInvite',
        failureMessage: `We didn't receive an invite, please cleck the instructions and try again.`,
    });

    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Step 2</ResponsiveModalTitle>
            </Row>
            <Spacing factor={2} />
            <LoadingSquares />
            <Spacing />
            <BodyRegularOnboard>
                Setting up the bot in your workspace. This may take up to{' '}
                <span style={{ fontWeight: 'bold' }}>2 minutes</span>
            </BodyRegularOnboard>
        </>
    );
};

const StartHuddleWithSpinachStep = ({ nextStep }: ConfigureSlackHuddleStepProps) => {
    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Step 3</ResponsiveModalTitle>
            </Row>
            <Spacing />

            <img src={HuddleExampleWindowImage} style={{ flexShrink: 1, maxHeight: '222px' }} />
            <Spacing />
            <BodyRegularOnboard>
                <span style={{ fontWeight: 'bold' }}>Start a huddle with Spinach (e.g. in Direct messages)</span> to
                check that the setup is successful
            </BodyRegularOnboard>
            <Spacing factor={2} />
            <span style={{ flexGrow: 1 }} />
            <PrimaryButton title="I'm in the huddle" onClick={() => nextStep()} />
        </>
    );
};

const WaitForHuddleVerificationStep = ({ nextStep, previousStep }: ConfigureSlackHuddleStepProps) => {
    useAutoAdvanceOnHuddleChange({
        nextStep,
        previousStep,
        propertyToCheckFor: 'successfullySetup',
        failureMessage: `We weren't able to verify that the bot successfully joined the huddle`,
    });

    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Step 4</ResponsiveModalTitle>
            </Row>
            <Spacing factor={2} />
            <LoadingSquares />
            <Spacing />
            <BodyRegularOnboard>Verifying setup.</BodyRegularOnboard>
        </>
    );
};

const ConfigureSlackHuddlesSuccess = ({ nextStep, goToStep }: ConfigureSlackHuddlesSuccessProps) => {
    return (
        <>
            <Row centered>
                <ResponsiveModalTitle>Success</ResponsiveModalTitle>
            </Row>
            <Spacing factor={1 / 3} />
            <BodyRegularOnboard>Spinach will now take notes and summarize your Huddles!</BodyRegularOnboard>

            <span style={{ flexGrow: 1 }} />
            <PrimaryButton title="Done" onClick={() => nextStep()} />
            <Spacing factor={1 / 2} />
            <SecondaryButton
                title="Edit Configuration"
                onClick={() => goToStep(ConfigureSlackHuddlesStep.ConfigureOptions)}
            />
        </>
    );
};

const ConfigureSlackHuddlesModalContent = ({ closeModal }: { closeModal: () => void }) => {
    const clickTracking = useClickTracking();
    const [isLoading, setIsLoading] = useState(false);

    const [globalHuddleConfiguration, setGlobalHuddleConfiguration] = useGlobalSlackHuddleConfiguration();
    const [huddleConfiguration, setHuddleConfiguration] = useState<Partial<SlackHuddleClientConfiguration>>(
        globalHuddleConfiguration ?? {}
    );
    const doesHuddleConfigurationExist = !!Object.values(huddleConfiguration).length;
    const isHuddleSuccessfullySetup = !!huddleConfiguration?.successfullySetup;
    const hasReceivedInvite = !!huddleConfiguration.hasReceivedInvite;
    const finalHuddleSteps: ConfigureSlackHuddlesStep[] = [];

    const huddleHasRequiredData =
        huddleConfiguration.filterHuddlesByUserEmails?.length &&
        huddleConfiguration.slackDomain &&
        huddleConfiguration.askToJoinMessage;

    if (huddleHasRequiredData) {
        if (isHuddleSuccessfullySetup) {
        } else if (hasReceivedInvite) {
            finalHuddleSteps.push(ConfigureSlackHuddlesStep.StartHuddleWithSpinachStep);
        } else {
            finalHuddleSteps.push(ConfigureSlackHuddlesStep.InviteBotUser);
        }
    }
    const stepsForWhenHuddleConfigExists = [
        ConfigureSlackHuddlesStep.InputDomainStep,
        ConfigureSlackHuddlesStep.ConfigureOptions,
        ...finalHuddleSteps,
    ];
    const initialSteps = [ConfigureSlackHuddlesStep.StepPreview];
    if (doesHuddleConfigurationExist) {
        initialSteps.push(...stepsForWhenHuddleConfigExists);
    }

    const [steps, setSteps] = useState<ConfigureSlackHuddlesStep[]>(initialSteps);
    const { setToastText } = useGlobalAiDashboard();

    const latestStep = steps[steps.length - 1];
    const goBack = () => {
        clickTracking(ClientEventType.AIDashboardClick, 'Configure Slack Huddles Go Back Step', {
            LatestStep: latestStep,
            PreviousStep: steps[steps.length - 2],
        });
        setSteps((prevSteps) => prevSteps.slice(0, -1));
    };

    const advance = (nextStep: ConfigureSlackHuddlesStep) => {
        clickTracking(ClientEventType.AIDashboardClick, 'Configure Slack Huddles', {
            CurrentStep: latestStep,
            NextStep: nextStep,
        });
        setSteps((prevSteps) => [...prevSteps, nextStep]);
    };

    const goToStep = (goToStep: ConfigureSlackHuddlesStep) => {
        setSteps((prevSteps) => {
            const indexOfStep = prevSteps.findIndex((step) => step === goToStep);
            // if it's not found just go to the first step
            if (indexOfStep === -1) {
                return [prevSteps[0]];
            }
            return prevSteps.slice(0, indexOfStep + 1);
        });
    };

    switch (latestStep) {
        case ConfigureSlackHuddlesStep.StepPreview:
            return (
                <ConfigureSlackHuddlesStepPreview
                    nextStep={() => {
                        advance(ConfigureSlackHuddlesStep.InputDomainStep);
                    }}
                    previousStep={closeModal}
                />
            );
        case ConfigureSlackHuddlesStep.InputDomainStep:
            return (
                <ConfigureSlackHuddlesInputDomainStep
                    nextStep={(options) => {
                        const { slackDomain } = options || {};
                        if (slackDomain) {
                            setHuddleConfiguration({
                                ...huddleConfiguration,
                                slackDomain,
                            });
                            advance(ConfigureSlackHuddlesStep.ConfigureOptions);
                        } else {
                            setToastText('Pleast input a valid domain');
                        }
                    }}
                    previousStep={goBack}
                />
            );
        case ConfigureSlackHuddlesStep.ConfigureOptions:
            return (
                <ConfigureSlackHuddlesConfigureOptionsStep
                    isLoading={isLoading}
                    nextStep={async (options) => {
                        try {
                            if (!huddleConfiguration.slackDomain) {
                                // Something got messed up, go back to previous step
                                goBack();
                                return;
                            }
                            const {
                                emails = null,
                                askToJoinPrivateHuddles = true,
                                autoJoinPublicHuddles = true,
                            } = options || {};

                            if (!!emails && !emails.length) {
                                setToastText('You must enter at least one email');
                                return;
                            }

                            const hasHuddleConfigurationChanged =
                                JSON.stringify(huddleConfiguration) !==
                                JSON.stringify({
                                    ...huddleConfiguration,
                                    autoJoinPublicHuddles,
                                    askToJoinPrivateHuddles,
                                    filterHuddlesByUserEmails: emails,
                                });

                            if (!hasHuddleConfigurationChanged) {
                                if (huddleConfiguration?.successfullySetup) {
                                    closeModal();
                                } else if (huddleConfiguration?.hasReceivedInvite) {
                                    advance(ConfigureSlackHuddlesStep.StartHuddleWithSpinachStep);
                                } else {
                                    advance(ConfigureSlackHuddlesStep.InviteBotUser);
                                }
                                return;
                            }

                            setIsLoading(true);
                            const createdHuddle = await createSlackHuddle({
                                filterHuddlesByUserEmails: emails,
                                autoJoinPublicHuddles,
                                askToJoinPrivateHuddles,
                                slackDomain: huddleConfiguration.slackDomain,
                                hasReceivedInvite: false,
                                successfullySetup: false,
                            });

                            setHuddleConfiguration({
                                ...huddleConfiguration,
                                autoJoinPublicHuddles,
                                askToJoinPrivateHuddles,
                                filterHuddlesByUserEmails: emails,
                            });

                            if (createdHuddle) {
                                setGlobalHuddleConfiguration(createdHuddle);
                            }

                            if (createdHuddle?.successfullySetup) {
                                advance(ConfigureSlackHuddlesStep.Success);
                            } else if (createdHuddle?.hasReceivedInvite) {
                                advance(ConfigureSlackHuddlesStep.StartHuddleWithSpinachStep);
                            } else {
                                advance(ConfigureSlackHuddlesStep.InviteBotUser);
                            }
                        } finally {
                            setIsLoading(false);
                        }
                    }}
                    previousStep={goBack}
                />
            );
        case ConfigureSlackHuddlesStep.InviteBotUser:
            return (
                <ConfigureSlackHuddlesInviteBotUserStep
                    nextStep={() => {
                        advance(ConfigureSlackHuddlesStep.WaitForBotInvite);
                    }}
                    previousStep={goBack}
                />
            );
        case ConfigureSlackHuddlesStep.WaitForBotInvite:
            return (
                <WaitForBotInviteStep
                    nextStep={() => {
                        advance(ConfigureSlackHuddlesStep.StartHuddleWithSpinachStep);
                    }}
                    previousStep={goBack}
                />
            );
        case ConfigureSlackHuddlesStep.StartHuddleWithSpinachStep:
            return (
                <StartHuddleWithSpinachStep
                    nextStep={() => {
                        advance(ConfigureSlackHuddlesStep.WaitForHuddleVerificationStep);
                    }}
                    previousStep={goBack}
                />
            );
        case ConfigureSlackHuddlesStep.WaitForHuddleVerificationStep:
            return (
                <WaitForHuddleVerificationStep
                    nextStep={() => {
                        advance(ConfigureSlackHuddlesStep.Success);
                    }}
                    previousStep={goBack}
                />
            );
        case ConfigureSlackHuddlesStep.Success:
            return (
                <ConfigureSlackHuddlesSuccess
                    nextStep={closeModal}
                    goToStep={goToStep}
                    previousStep={() => undefined}
                />
            );
    }
};

export function ConfigureSlackHuddlesModal(): JSX.Element {
    const [globalModal, setGlobalModal] = useGlobalModal();

    const clickTracking = useClickTracking();

    const closeModal = () => {
        clickTracking(ClientEventType.AIDashboardClick, 'Close Slack Huddle Connection Modal');
        setGlobalModal(null);
    };

    if (globalModal !== GlobalModal.ConfigureSlackHuddles) {
        return <></>;
    }
    return (
        <Modal open={globalModal === GlobalModal.ConfigureSlackHuddles} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden', minHeight: '300px' }}>
                <ConfigureSlackHuddlesModalContent closeModal={closeModal} />
            </SpinachModalContent>
        </Modal>
    );
}
