export enum SpinachIntegration {
    Slack = 'slack',
    Jira = 'jira',
    Clickup = 'clickUp',
    Trello = 'trello',
    Confluence = 'confluence',
    MondayDotCom = 'mondayDotCom',
    Linear = 'linear',
    Asana = 'asana',
    Google = 'google',
    GoogleDrive = 'googleDrive',
    GoogleCalendar = 'googleCalendar',
    Notion = 'notion',
    Microsoft = 'microsoft',
    Zoom = 'zoom',
    Zapier = 'zapier',
    HubSpot = 'hubSpot',
    Attio = 'attio',
    Zoho = 'zoho',
    Salesforce = 'salesforce',
}
export type SpinachIntegrationConfiguration = {
    code: SpinachIntegration;
    displayName: string;
};

export const SpinachIntegrations: Record<SpinachIntegration, SpinachIntegrationConfiguration> = {
    [SpinachIntegration.Attio]: {
        code: SpinachIntegration.Attio,
        displayName: 'Attio',
    },
    [SpinachIntegration.Zoho]: {
        code: SpinachIntegration.Zoho,
        displayName: 'Zoho',
    },
    [SpinachIntegration.Salesforce]: {
        code: SpinachIntegration.Salesforce,
        displayName: 'Salesforce',
    },
    [SpinachIntegration.HubSpot]: {
        code: SpinachIntegration.HubSpot,
        displayName: 'HubSpot',
    },
    [SpinachIntegration.Slack]: {
        code: SpinachIntegration.Slack,
        displayName: 'Slack',
    },
    [SpinachIntegration.Zapier]: {
        code: SpinachIntegration.Zapier,
        displayName: 'Zapier',
    },
    [SpinachIntegration.Jira]: {
        code: SpinachIntegration.Jira,
        displayName: 'Jira',
    },
    [SpinachIntegration.Confluence]: {
        code: SpinachIntegration.Confluence,
        displayName: 'Confluence',
    },
    [SpinachIntegration.Clickup]: {
        code: SpinachIntegration.Clickup,
        displayName: 'Clickup',
    },
    [SpinachIntegration.Trello]: {
        code: SpinachIntegration.Trello,
        displayName: 'Trello',
    },
    [SpinachIntegration.Linear]: {
        code: SpinachIntegration.Linear,
        displayName: 'Linear',
    },
    [SpinachIntegration.Asana]: {
        code: SpinachIntegration.Asana,
        displayName: 'Asana',
    },
    [SpinachIntegration.Google]: {
        code: SpinachIntegration.Google,
        displayName: 'Google',
    },
    [SpinachIntegration.GoogleDrive]: {
        code: SpinachIntegration.GoogleDrive,
        displayName: 'Google Drive',
    },
    [SpinachIntegration.GoogleCalendar]: {
        code: SpinachIntegration.GoogleCalendar,
        displayName: 'Google Calendar',
    },
    [SpinachIntegration.Notion]: {
        code: SpinachIntegration.Notion,
        displayName: 'Notion',
    },
    [SpinachIntegration.Microsoft]: {
        code: SpinachIntegration.Microsoft,
        displayName: 'Microsoft',
    },
    [SpinachIntegration.Zoom]: {
        code: SpinachIntegration.Zoom,
        displayName: 'Zoom',
    },
    [SpinachIntegration.MondayDotCom]: {
        code: SpinachIntegration.MondayDotCom,
        displayName: 'Monday.com',
    },
};
