import { JiraPickerOpts, SpinachAPIPath, Ticket } from '@spinach-shared/types';

import { getSpinachAPI } from '../getSpinachAPI';

export const fetchJiraIssues = async (opts?: Partial<JiraPickerOpts>) => {
    const response = await getSpinachAPI<{ issues: Ticket[] }, JiraPickerOpts>(SpinachAPIPath.JiraSuggestions, {
        params: {
            showSubTasks: true,
            showSubTaskParent: true,
            ...opts,
        },
    });

    if (!response?.issues || !response.issues.length || !response.issues[0].id) {
        return [];
    }

    return response.issues;
};
