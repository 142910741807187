import { ClientEventType, NavDrawer, Sentiment, TooltipFeatureDiscoveryKey } from '@spinach-shared/types';

import { ElementClass } from '../../../constants/identifiers';
import {
    useGlobalNavDrawer,
    useLiveItemSentimentDetailsEnabled,
    useRegularItemSentimentEnabled,
    useSeriesReality,
} from '../../../hooks';
import { FeatureTooltipCalloutClickProps, UseCustomTooltipCalloutResults } from '../../common/feature-discovery';
import { CustomTooltipCallout, useCustomTooltipCallout } from './CustomTooltipCallout';

export function useRegularItemSentimentCallout(): UseCustomTooltipCalloutResults & {
    elementId: string;
} {
    const isCalloutEnabledForUser = useRegularItemSentimentEnabled();
    const isSentimentReasonEnabledForUser = useLiveItemSentimentDetailsEnabled();
    const { drawer } = useGlobalNavDrawer();
    // find the first sentiment picker on the page
    const firstSentimentPicker = document.getElementsByClassName(ElementClass.SentimentPicker)?.[0];

    const { isRealSeries } = useSeriesReality();

    const shouldShowThisHint =
        drawer === NavDrawer.EditCheckIn &&
        isCalloutEnabledForUser &&
        // Don't show if sentiemnt reasons is enabled
        !isSentimentReasonEnabledForUser &&
        isRealSeries &&
        !!firstSentimentPicker;

    const calloutProps = useCustomTooltipCallout(
        shouldShowThisHint,
        ClientEventType.OnRegularItemSentimentPickerCalloutClick,
        TooltipFeatureDiscoveryKey.RegularUpdateSentiment
    );

    return {
        ...calloutProps,
        elementId: firstSentimentPicker?.id,
    };
}

export const RegularItemSentimentCallout = ({ onEngageClick }: FeatureTooltipCalloutClickProps): JSX.Element => {
    const { isVisible, elementId } = useRegularItemSentimentCallout();
    return (
        <CustomTooltipCallout
            isVisible={isVisible}
            onEngageClick={onEngageClick}
            calloutText="Let the team know how it's going."
            clientEventType={ClientEventType.RegularItemSentimentPickerCalloutDisplayed}
            tooltipFeatureDiscoveryElementId={`${elementId}-${Sentiment.Good}`}
        />
    );
};
