import type { Identifier, XYCoord } from 'dnd-core';
import React, { CSSProperties, FC, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { hubViewPadding } from '../../constants/styling';

const defaultStyle = {
    width: '100%',
    padding: `0 ${hubViewPadding}px`,
};

export interface CardProps {
    id: any;
    index: number;
    moveDraggable: (dragIndex: number, hoverIndex: number) => void;
    isDraggable: boolean;
    emitOrderedUpdatesSave?: (moveToIndex: number) => void;
    type: string;
    children: any;
    idList: string[];
    style?: CSSProperties;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

export const Card: FC<CardProps> = ({
    id,
    index,
    moveDraggable,
    isDraggable,
    emitOrderedUpdatesSave,
    type,
    style,
    children,
    idList,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: type,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = idList.indexOf(item.id);
            const hoverIndex = idList.indexOf(id);

            // Don't replace items with themselves + don't allow dragging to a different list
            if (dragIndex === hoverIndex || dragIndex === -1 || hoverIndex === -1) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveDraggable(dragIndex, hoverIndex);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: type,
        item: () => {
            return { id, index };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end(item, monitor) {
            emitOrderedUpdatesSave && emitOrderedUpdatesSave(idList.indexOf(id));
        },
    });

    const opacity = isDragging ? 0.4 : 1;

    let dragRef = null;
    if (isDraggable) {
        drop(ref);
        dragRef = drag;
    }

    const [isMouseIn, setIsMouseIn] = useState(false);

    return (
        <div
            ref={ref}
            style={{ ...defaultStyle, ...style, opacity }}
            data-handler-id={handlerId}
            onMouseEnter={() => setIsMouseIn(true)}
            onMouseLeave={(e) => setIsMouseIn(false)}
        >
            {React.cloneElement(children, { isMouseIn, dragRef })}
        </div>
    );
};

export default Card;
