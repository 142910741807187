import { ClientAiHistory, SpinachAPIPath, TranscriptData, ViewableTranscriptLineData } from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function getAiHistories(
    options: { limit?: number; botId?: string } = {}
): Promise<ClientAiHistory[] | undefined> {
    const params: { limit?: number; botId?: string } = {};
    if (options) {
        params.botId = options.botId;
        params.limit = options.limit;
    }

    const response = await getSpinachAPI<{ histories: ClientAiHistory[] }>(SpinachAPIPath.AIHistory, {
        params,
    });

    return response?.histories;
}

export async function getAiTranscript({
    seriesId,
    botId,
}: {
    seriesId: string;
    botId: string;
}): Promise<TranscriptData | undefined | null> {
    const response = await getSpinachAPI<
        { results: ViewableTranscriptLineData[] | null; isTranscriptDisabled: false } | { isTranscriptDisabled: true }
    >(SpinachAPIPath.AITranscript, {
        params: {
            seriesId,
            botId,
        },
    });

    if (response?.isTranscriptDisabled) {
        return response;
    }

    if (!response?.results) {
        return null;
    }

    return { isTranscriptDisabled: response.isTranscriptDisabled, transcriptLineData: response.results };
}

export async function getAiSummaryHtml({
    seriesId,
    botId,
}: {
    seriesId: string;
    botId: string;
}): Promise<string | undefined | null> {
    const response = await getSpinachAPI<{ results: string | null }>(SpinachAPIPath.AiSummaryHtml, {
        params: {
            seriesId,
            botId,
        },
    });

    return response?.results;
}
