import { useEffect } from 'react';
import { v4 } from 'uuid';

import {
    CURRENT_ITEMS_CUSTOM_LIST_ID,
    FINISHED_ITEMS_CUSTOM_LIST_ID,
    PRESENTING_WORKING_ITEMS_CUSTOM_LIST_ID,
} from '@spinach-shared/constants';
import { ClientUser } from '@spinach-shared/models';
import { GoalKind, GoalMetadata, GoalStatus, SpinachUpdateType, TypedUpdate } from '@spinach-shared/types';
import { getNewTypedUpdate } from '@spinach-shared/utils';

import { SetTypedUpdates, useGlobalAuthedUser, useGlobalLiveSeries, useReadOnlyPresentation } from '..';

export function useElasticLiveInputs(
    typedUpdates: TypedUpdate[],
    setTypedUpdates: SetTypedUpdates,
    focusedInputIndex: number | null,
    updateType: SpinachUpdateType,
    savedUpdates: TypedUpdate[],
    customListId?: string,
    isInNavView?: boolean
): void {
    const [user] = useGlobalAuthedUser();
    const shouldDisableElasticity = useElasticDisablement(updateType, customListId, isInNavView);

    useEffect(() => {
        const numberOfEmptyUnsavedUpdates = typedUpdates.reduce((total, curr) => {
            const increment = Boolean(curr.text) || Boolean(savedUpdates.find((u) => u.id === curr.id)) ? 0 : 1;
            return total + increment;
        }, 0);

        const typedUpdatesWithEmptyUnsavedSubItems = typedUpdates.filter((typedUpdate) => {
            const subItems = (typedUpdate.subItems && typedUpdate.subItems.filter((subItem) => !subItem.text)) || []; //|| savedUpdates.filter((u) => u.subItems?.some(item => typedUpdate.subItems?.find(subItem => subItem.id === item.id)))
            return subItems.length > 1;
        });

        if (numberOfEmptyUnsavedUpdates > 1) {
            const tailMostEmptyUpdate = typedUpdates.reverse().find((update) => update.text === '')!;
            const updatesWithoutExcessEmpties = typedUpdates
                .reverse()
                .filter((update) => update.id !== tailMostEmptyUpdate.id);

            setTypedUpdates([...updatesWithoutExcessEmpties]);
        }

        for (const typedUpdateWithUnsavedEmpty of typedUpdatesWithEmptyUnsavedSubItems) {
            const subItems = [...(typedUpdateWithUnsavedEmpty.subItems || [])];
            let subItemUpdatesWithoutExcessEmpties = subItems;

            if (subItems.filter((i) => i.text === '').length > 1) {
                const tailMostEmptySubItemUpdate = subItems.reverse().find((update) => update.text === '')!;
                subItemUpdatesWithoutExcessEmpties =
                    subItems.reverse().filter((update) => update.id !== tailMostEmptySubItemUpdate.id) || [];
            }

            const typedUpdateIndex = typedUpdates.findIndex((item) => item.id === typedUpdateWithUnsavedEmpty.id);

            const typedUpdatesCopy = typedUpdates
                .slice(0, typedUpdateIndex)
                .concat([
                    { ...typedUpdateWithUnsavedEmpty, subItems: [...subItemUpdatesWithoutExcessEmpties] },
                    ...typedUpdates.slice(typedUpdateIndex + 1),
                ]);
            setTypedUpdates([...typedUpdatesCopy]);
        }

        const noEmptyInputs = typedUpdates.every((update) => Boolean(update.text));

        if (noEmptyInputs && !shouldDisableElasticity) {
            const goalMetadata = getNewGoalMetadata(customListId, user);
            setTypedUpdates([
                ...typedUpdates,
                getNewTypedUpdate({
                    updateType,
                    customListId,
                    text: '',
                    goalMetadata,
                    creatorId: user.spinachUserId,
                }),
            ]);
        }
    }, [typedUpdates, setTypedUpdates, focusedInputIndex, updateType, savedUpdates, shouldDisableElasticity]);
}

function getNewGoalMetadata(
    customListId: string | undefined,
    user: ClientUser
): (GoalMetadata & { goalId: string }) | undefined {
    let goalMetadata: (GoalMetadata & { goalId: string }) | undefined = undefined;
    if (customListId === CURRENT_ITEMS_CUSTOM_LIST_ID) {
        goalMetadata = {
            createdAt: new Date(),
            creatorId: user.spinachUserId,
            isArchived: false,
            isDeleted: false,
            kind: GoalKind.User,
            progress: 0,
            sharedInSeries: [],
            status: GoalStatus.InProgress,
            updatedAt: new Date(),
            goalId: v4(),
        };
    }
    return goalMetadata;
}

function useElasticDisablement(updateType: SpinachUpdateType, customListId?: string, isInNavView?: boolean): boolean {
    const isPresentationReadonly = useReadOnlyPresentation();
    const [liveSeries] = useGlobalLiveSeries();
    const { currentAgendaItem } = liveSeries;

    const isSomeonesPresentation = !!currentAgendaItem?.isParticipantAgendaItem && !isInNavView;

    const isPresentingIcebreaker = !isInNavView && updateType === SpinachUpdateType.Icebreaker;

    const isManagedIssueBasedSection =
        isInNavView &&
        (customListId === FINISHED_ITEMS_CUSTOM_LIST_ID || customListId === PRESENTING_WORKING_ITEMS_CUSTOM_LIST_ID);

    const shouldDisableElasticity = isPresentationReadonly && (isSomeonesPresentation || isManagedIssueBasedSection);
    return shouldDisableElasticity || isPresentingIcebreaker;
}
