import { useRecoilState } from 'recoil';

import { NavDrawer } from '@spinach-shared/types';

import { GlobalNavDrawerState, atomNavState } from '../atoms';
import { useGlobalLiveSeries } from './useLiveSeries';

export type UseGlobalNavDrawerProps = {
    drawer: NavDrawer | null;

    openSummaries: () => void;
    openTeamTopics: () => void;
    openCheckIn: () => void;
    openDrawer: (drawer: NavDrawer) => void;
    closeDrawer: () => void;
    setNavDrawerState: (state: GlobalNavDrawerState) => void;
};

/**
 *
 * @returns an object with stateful values and functions.
 * `drawer` is `null` if closed, and set to NavDrawer value if opened.
 * `openDrawer` and `closeDrawer` are generic functions while the rest are
 * here for convenience.
 */
export function useGlobalNavDrawer(): UseGlobalNavDrawerProps {
    const [liveSeries] = useGlobalLiveSeries();
    const [navDrawerState, setDrawerState] = useRecoilState(atomNavState);

    function openSummaries() {
        setDrawerState({ ...navDrawerState, drawer: NavDrawer.Summaries });
    }

    function openTeamTopics() {
        setDrawerState({ ...navDrawerState, drawer: NavDrawer.TeamTopics });
    }

    function openCheckIn() {
        if (!liveSeries.isRoundtableEnabled) {
            return;
        }
        setDrawerState({ ...navDrawerState, drawer: NavDrawer.EditCheckIn });
    }

    function openDrawer(drawer: NavDrawer) {
        if (drawer === NavDrawer.EditCheckIn) {
            if (!liveSeries.isRoundtableEnabled) {
                return;
            }
        }

        setDrawerState({ ...navDrawerState, drawer });
    }

    function closeDrawer() {
        setDrawerState({ ...navDrawerState, drawer: null });
    }
    function setNavDrawerState(state: Partial<GlobalNavDrawerState>) {
        setDrawerState({ ...navDrawerState, ...state });
    }

    return {
        drawer: navDrawerState.drawer,
        openSummaries,
        openTeamTopics,
        openCheckIn,
        openDrawer,
        closeDrawer,
        setNavDrawerState,
    };
}
