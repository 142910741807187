import { useEffect, useState } from 'react';

import { TooltipFeatureDiscoveryKey } from '@spinach-shared/types';

import { useSeriesReality } from '../../../hooks';
import { JiraMagicInputCallout, useJiraMagicInputCallout } from './JiraMagicInputCallout';
import { RegularItemSentimentCallout, useRegularItemSentimentCallout } from './RegularItemSentimentCallout';
import { SentimentReasonsCallout, useSentimentReasonsCallout } from './SentimentReasonsCallout';

/**
 *
 * @returns at most, a single feature callout. If no callouts apply to the user, nothing is rendered.
 * Once a callout is hidden, either for being closed or engaged with, the next available callout should not
 * show until the next lobby mount.
 *
 * To add a callout, follow the pattern for JiraIntegration:
 * 1. Create a wrapper of the FeatureCallout component
 * 2. Create a custom hook to provide the visibility rule and click callbacks
 * 3. Invoke the hook in the section of custom hooks below
 * 4. Add the props response from the hook into the `calloutPropsPrecedence` array
 * 5. Add the callout component into the switch statement at the bottom
 */

export function FeatureCalloutTooltipContainer(): JSX.Element {
    const { isDemoSeries } = useSeriesReality();

    /**
     * STEP 3: invoke the custom hooks for your new callout
     */
    const jiraMagicInputCallout = useJiraMagicInputCallout();
    const regularItemSentimentCallout = useRegularItemSentimentCallout();
    const regularItemSentimentReasonsCallout = useSentimentReasonsCallout();

    /**
     * STEP 4:
     * Set precedence of callouts and pick the first one that is still visible.
     * This selected callout will be the only one that can be visible while this lobby is mounted.
     * If none are visible, or we're in demo mode, return an empty fragment
     */
    const calloutPropsPrecedence = [
        jiraMagicInputCallout,
        regularItemSentimentCallout,
        regularItemSentimentReasonsCallout,
    ];

    const nextVisibleCalloutIfAny = calloutPropsPrecedence.find((props) => props.isVisible === true);
    const [initialCallout, setInitialCallout] = useState(nextVisibleCalloutIfAny);

    /**
     * If the user triggers some action that renders the first callout for this mount
     * We do want to display that, even though we mounted without a callout
     */
    useEffect(() => {
        if (initialCallout === undefined && nextVisibleCalloutIfAny) {
            setInitialCallout(nextVisibleCalloutIfAny);
        }
    }, [nextVisibleCalloutIfAny]);

    /**
     * If the user has interacted with the initial callout and hidden it,
     * we want to hide it without displaying the next one in the precedence
     */

    if (
        isDemoSeries ||
        !initialCallout ||
        initialCallout.feature !== nextVisibleCalloutIfAny?.feature ||
        initialCallout.isVisible !== nextVisibleCalloutIfAny?.isVisible
    ) {
        return <></>;
    }

    switch (initialCallout.feature) {
        case TooltipFeatureDiscoveryKey.JiraMagicInput:
            return <JiraMagicInputCallout onEngageClick={jiraMagicInputCallout.onEngageClick} />;
        case TooltipFeatureDiscoveryKey.RegularUpdateSentiment:
            return <RegularItemSentimentCallout onEngageClick={regularItemSentimentCallout.onEngageClick} />;
        case TooltipFeatureDiscoveryKey.RegularUpdateSentimentReasons:
            return <SentimentReasonsCallout onEngageClick={regularItemSentimentReasonsCallout.onEngageClick} />;
        default:
            return <></>;
    }
}
