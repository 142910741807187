import { z } from 'zod';

export const CreateTicketRequestSchema = z.object({
    title: z.string(),
    description: z.string(),
    projectId: z.string().optional(),
    subProjectId: z.string().optional(),
    issueTypeId: z.string().optional(),
    metadata: z
        .object({
            botId: z.string(),
            heySpinachId: z.string().optional(),
            // TODO we can add action items here
        })
        .optional(),
});

export type CreateTicketRequest = z.infer<typeof CreateTicketRequestSchema>;
