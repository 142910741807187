import styled from 'styled-components';

import { lightTheme } from '../../styles';

export function Bullet({ isCurrentAgendaItem, fill }: { isCurrentAgendaItem?: boolean; fill?: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6">
            <path
                d="M 0 2 C 0 0.895 0.895 0 2 0 L 4 0 C 5.105 0 6 0.895 6 2 L 6 4 C 6 5.105 5.105 6 4 6 L 2 6 C 0.895 6 0 5.105 0 4 Z"
                fill={fill ?? (isCurrentAgendaItem ? lightTheme.secondary.orangeLight : lightTheme.tertiary.midnight)}
            ></path>
        </svg>
    );
}

export const BulletMark = styled.span`
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    line-height: 0;
    padding-right: 4px;
`;

export function SixDot() {
    return (
        <svg width="10" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'row-resize' }}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM0 8C0 6.9 0.9 6 2 6C3.1 6 4 6.9 4 8C4 9.1 3.1 10 2 10C0.9 10 0 9.1 0 8ZM2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16ZM10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16C6.9 16 6 15.1 6 14Z"
                fill="#B8B8C5"
            />
        </svg>
    );
}
