import { Box, Tooltip } from '@material-ui/core';
import styled from 'styled-components';

import { Row } from '../../../common';

export const Container = styled(Row)`
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 23px;
    box-sizing: border-box;
}
`;

export const ContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
`;

export const TooltipContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 17px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.tertiary.midnight};
    background: var(--White, #fff);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
`;

export const Divider = styled.div`
    background: var(--Neutral-1, #ecf1f0);
    height: 1px;
`;

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'transparent',
    },
}));

export const OutlineButton = styled.span<{ isSelected: boolean; isDisabled?: boolean }>`
    border-radius: 4px;
    padding: 6px 17px;
    background-color: ${(props) =>
        props.isDisabled ? '#D2D8D8' : props.isSelected ? props.theme.secondary.green : props.theme.neutrals.white};
    border: ${(props) => (props.isSelected || props.isDisabled ? 'none' : '1px solid var(--Grey-2, #B8B8C5)')};
    margin-right: 10px;
    cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
    display: flex;
    min-width: 30px;
    justify-content: center;
    color: ${(props) =>
        props.isDisabled || props.isSelected ? props.theme.neutrals.white : props.theme.primary.midnight};
    font-size: 14px;
    font-weight: 600;
    &:hover {
        background-color: ${(props) =>
            props.isDisabled
                ? '#D2D8D8'
                : props.isSelected
                ? props.theme.secondary.greenDark
                : props.theme.neutrals.gray};
    }
`;
