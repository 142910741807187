import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
    FIVE_MINUTES_LEFT_ID,
    HALFWAY_TIMECHECK_ID,
    HEY_SPINACH_TIP_INSIGHT_ID,
    INSIGHTS_TIP_INSIGHT_ID,
} from '@spinach-shared/constants';
import { FacilitatedStartStops } from '@spinach-shared/models';
import { ClientEventType, SpinachInsight, SpinachInsightKind } from '@spinach-shared/types';

import { getStoredSeries } from '../../../apis';
import {
    useFacilitationSyncing,
    useFacilitationTracking,
    useGlobalNullableFacilitation,
    useGlobalNullableStoredSeries,
    useGlobalRouting,
    useGlobalStoredSeries,
} from '../../../hooks';
import { FYI, FYIState } from '../../common';
import { AgendaSidebar } from './AgendaSidebar';
import { InsightsSidebar } from './InsightsSidebar';
import { Spotlight } from './Spotlight';
import { getCurrentTimedView } from './common';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    display: flex;
    overflow: hidden;
`;

export function FacilitationContainer(): JSX.Element {
    const [series, setSeries] = useGlobalNullableStoredSeries();
    const { routeToAIDashboard } = useGlobalRouting();
    const params = useParams();

    useEffect(() => {
        async function fetchSeries() {
            if (params.seriesId) {
                const series = await getStoredSeries(params.seriesId);

                if (!series) {
                    routeToAIDashboard({ replace: true });
                } else {
                    setSeries(series);
                }
            } else {
                routeToAIDashboard({ replace: true });
            }
        }
        fetchSeries();
    }, [params.seriesId]);

    if (!series) {
        return <FYI state={FYIState.Loading} />;
    }

    return <Facilitation />;
}

let interval: ReturnType<typeof window.setTimeout>;

function useTimeCheckInsights() {
    const track = useFacilitationTracking();
    const [facilitation, setFacilitation] = useGlobalNullableFacilitation();

    useEffect(() => {
        if (interval) {
            clearInterval(interval);
        }

        if (!facilitation) {
            return;
        }

        interval = setInterval(() => {
            const totalMeetingDurationInSeconds =
                moment(facilitation.projectedMeetingTime.endTime).unix() -
                moment(facilitation.projectedMeetingTime.startTime).unix();

            const secondsIntoMeeting = moment().unix() - moment(facilitation.projectedMeetingTime.startTime).unix();

            const shortestTimeATopicOrPersonGetsTimeChecked = 2 * 60;
            const longestTimeATopicOrPersonGetsTimeChecked = 3 * 60;
            const durationAlottedForGapAnalysis = 5 * 60;

            const timeForUpdatesAndTopics = totalMeetingDurationInSeconds - durationAlottedForGapAnalysis;

            const numeberOfPeopleAndTopics = facilitation.participants.length + facilitation.discussionTopics.length;

            const computedTimePerView = timeForUpdatesAndTopics / numeberOfPeopleAndTopics;
            let timePerView = computedTimePerView;
            if (timePerView > longestTimeATopicOrPersonGetsTimeChecked) {
                timePerView = longestTimeATopicOrPersonGetsTimeChecked;
            } else if (timePerView < shortestTimeATopicOrPersonGetsTimeChecked) {
                timePerView = shortestTimeATopicOrPersonGetsTimeChecked;
            }

            if (secondsIntoMeeting < 0) {
                return;
            }

            /** @NOTE - code for halftime check */
            const hasHalfwayTimecheckFired = facilitation.inMeetingInsights.some(
                (insight) => insight.id === HALFWAY_TIMECHECK_ID
            );
            if (secondsIntoMeeting > totalMeetingDurationInSeconds / 2 && !hasHalfwayTimecheckFired) {
                setFacilitation((facilitation) =>
                    facilitation
                        ? {
                              ...facilitation,
                              inMeetingInsights: [
                                  ...facilitation.inMeetingInsights,
                                  {
                                      actions: [],
                                      context: "Heads up! We're halfway through the meeting.",
                                      title: 'Halftime Check',
                                      id: HALFWAY_TIMECHECK_ID,
                                      kind: SpinachInsightKind.TimeCheck,
                                  },
                              ],
                          }
                        : facilitation
                );
                track(ClientEventType.AIFacilitationActivity, {
                    Activity: 'Halftime Time Check',
                });
            }

            /** @NOTE - code for five minutes left time check */

            const fiveMinutesInSeconds = 5 * 60;
            const hasFiveMinutesLeftTimecheckFired = facilitation.inMeetingInsights.some(
                (insight) => insight.id === FIVE_MINUTES_LEFT_ID
            );
            const isMeetingAtLeastFiveMinutes = totalMeetingDurationInSeconds > fiveMinutesInSeconds;
            const meetingHasUnderFiveMinutesLeft =
                totalMeetingDurationInSeconds - secondsIntoMeeting < fiveMinutesInSeconds;
            if (isMeetingAtLeastFiveMinutes && meetingHasUnderFiveMinutesLeft && !hasFiveMinutesLeftTimecheckFired) {
                setFacilitation((facilitation) =>
                    facilitation
                        ? {
                              ...facilitation,
                              inMeetingInsights: [
                                  ...facilitation.inMeetingInsights,
                                  {
                                      actions: [],
                                      context: 'This could be a good time to start wrapping up.',
                                      title: 'Five minutes left',
                                      id: FIVE_MINUTES_LEFT_ID,
                                      kind: SpinachInsightKind.TimeCheck,
                                  },
                              ],
                          }
                        : facilitation
                );
                track(ClientEventType.AIFacilitationActivity, {
                    Activity: 'Five Minutes Left Time Check',
                });
            }

            /** @NOTE - code for per-person/topic time checks */

            const currentView = getCurrentTimedView(facilitation);

            if (!currentView) {
                return;
            }

            const timingOfCurrentView = new FacilitatedStartStops(currentView.startStops);
            const timeCheckForViewHasFired = facilitation.inMeetingInsights.some(
                (insight) => insight.id === currentView.viewId && insight.kind === SpinachInsightKind.TimeCheck
            );
            const inProgressTiming = timingOfCurrentView.inProgress();
            const currentTurnTime = inProgressTiming
                ? new Date().getTime() / 1000 - new Date(inProgressTiming.startedAt).getTime() / 1000
                : null;

            if (!currentTurnTime) {
                return;
            }

            const timeToFireTimeCheckForView = timingOfCurrentView.totalTime + currentTurnTime > timePerView;

            if (timeToFireTimeCheckForView && !timeCheckForViewHasFired) {
                const isCurrentViewForRoundtable = 'displayName' in currentView;
                setFacilitation((facilitation) =>
                    facilitation
                        ? {
                              ...facilitation,
                              inMeetingInsights: [
                                  ...facilitation.inMeetingInsights,
                                  {
                                      actions: [],
                                      context: `${Math.round(timePerView / 60)} minutes elapsed. ${
                                          isCurrentViewForRoundtable ? '' : 'Consider scheduling a follow-up.'
                                      }`,
                                      title: isCurrentViewForRoundtable ? currentView.displayName : `Let's keep moving`,
                                      id: currentView.viewId,
                                      kind: SpinachInsightKind.TimeCheck,
                                  },
                              ],
                          }
                        : facilitation
                );
                track(ClientEventType.AIFacilitationActivity, {
                    Activity: isCurrentViewForRoundtable ? 'Time Check for Roundtable' : 'Time Check for Topic',
                    AllottedTimePerView: timePerView,
                });
            }
            return () => clearInterval(interval);
        }, 1000);
    }, [facilitation, setFacilitation]);
}

function useInitialInsights() {
    const [facilitation, setFacilitation] = useGlobalNullableFacilitation();

    useEffect(() => {
        if (!facilitation) {
            return;
        }

        const initialInsights: SpinachInsight[] = [];

        if (!facilitation.inMeetingInsights.some((i) => i.id === INSIGHTS_TIP_INSIGHT_ID)) {
            initialInsights.push({
                context: 'Take notes and set reminders by saying "Hey Spinach" in the meeting',
                title: 'Hands-free convenience is enabled',
                kind: SpinachInsightKind.HeySpinach,
                id: INSIGHTS_TIP_INSIGHT_ID,
                actions: [],
            });
        }

        if (!facilitation.inMeetingInsights.some((i) => i.id === HEY_SPINACH_TIP_INSIGHT_ID)) {
            initialInsights.push({
                context:
                    'When each person speaks, Spinach will surface blockers and provide tips to ensure an effective meeting.',
                title: 'No insights yet',
                kind: SpinachInsightKind.MeetingTip,
                id: HEY_SPINACH_TIP_INSIGHT_ID,
                actions: [],
            });
        }

        if (initialInsights.length) {
            setFacilitation((facilitation) =>
                facilitation
                    ? {
                          ...facilitation,
                          inMeetingInsights: [...facilitation.inMeetingInsights, ...initialInsights],
                      }
                    : facilitation
            );
        }
    }, [facilitation, setFacilitation]);
}

export function Facilitation(): JSX.Element {
    const [series] = useGlobalStoredSeries();
    const { facilitation } = useFacilitationSyncing(series.id);

    useTimeCheckInsights();
    useInitialInsights();

    if (!facilitation) {
        return <FYI state={FYIState.Loading} />;
    }

    return (
        <Container>
            <AgendaSidebar />
            <Spotlight />
            <InsightsSidebar />
        </Container>
    );
}
