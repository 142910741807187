import styled from 'styled-components';

import { ReactComponent as SLogoIcon } from '../../../assets/s-logo.svg';
import { SpinachStationary } from '../../common';

export const Stationary = styled(SpinachStationary)`
    min-height: 65%;
`;

export const SLogo = SLogoIcon;

export const SLIDING_DURATION_MS = 350;
